import { Box, Text } from '@chakra-ui/react';
import {
  Activity_PreviousActivity,
  Evaluation,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { IStep, ISteps, newInput, rehydrateStepAnswer, SparxQuestion } from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { MultiStepWrapper } from 'components/multistep/MultiStepWrapper';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSparxQuestionColours } from 'views/task/ActivityDelivery';

interface StepWrapperProps {
  steps: (IStep & { evaluation?: Evaluation })[];
  currentStep: number;
  totalSteps: number;
  children: (step: IStep, i: number) => React.ReactNode;
}

export const MultistepSteppedWrapper = ({
  steps,
  currentStep,
  totalSteps,
  children,
}: StepWrapperProps) => {
  const [lastStep, setLastStep] = useState(currentStep);
  useEffect(() => {
    if (lastStep !== currentStep) {
      setTimeout(() => setLastStep(currentStep), 500);
    }
  }, [setLastStep, lastStep, currentStep]);
  const reverse = lastStep > currentStep;

  return (
    <Box mt={1} width="100%">
      {/* TODO: use wait when this fixed? https://github.com/framer/motion/issues/2023 */}
      <AnimatePresence mode="popLayout" custom={reverse}>
        {steps[currentStep] && (
          <motion.div
            key={currentStep}
            custom={reverse}
            variants={{
              enter: (reverse: boolean) => ({
                opacity: 0,
                transform: reverse ? 'translateY(-20px)' : 'translateY(60px)',
              }),
              exit: (reverse: boolean) => ({
                opacity: 0,
                transform: reverse ? 'translateY(60px)' : 'translateY(-20px)',
              }),
            }}
            initial="enter"
            exit="exit"
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            style={{ display: 'flex' }}
          >
            <MultiStepWrapper
              correct={steps[currentStep]?.evaluation?.status === TaskItem_Status.CORRECT}
              index={currentStep}
              totalSteps={totalSteps}
            >
              {children(steps[currentStep], currentStep)}
            </MultiStepWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export const MultistepContinuousWrapper = ({
  steps,
  currentStep,
  children,
  isSupport,
  totalSteps,
  color = 'orange',
}: StepWrapperProps & { isSupport?: boolean; color?: string }) => {
  // Ensure that when we change to a new step we scroll it into view
  const [wrapperId] = useState(uuid);
  useEffect(() => {
    const ref = document.getElementById(`step-${wrapperId}-${currentStep}`);
    if (ref) {
      setTimeout(
        () =>
          ref.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          }),
        100,
      );
    }
  }, [currentStep, wrapperId]);

  return (
    <Box>
      <AnimatePresence>
        {isSupport && (
          <Text
            as={motion.div}
            initial={{ opacity: 0, transform: 'translateY(60px)' }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            py={3}
            px={4}
            my={5}
            transition="background 0.4s"
            borderRadius="md"
            fontWeight="bold"
            fontSize="lg"
            bg={`${color}.200`}
          >
            Ok, let's learn it...
          </Text>
        )}
        {steps.map((step, i) => (
          <Box
            key={i}
            as={motion.div}
            initial={{ opacity: 0, transform: 'translateY(60px)' }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            id={`step-${wrapperId}-${i}`}
            scrollMarginTop={3}
            display="flex"
          >
            <MultiStepWrapper
              correct={step.evaluation?.status === TaskItem_Status.CORRECT}
              index={i}
              solidLine={i < currentStep}
              totalSteps={totalSteps}
            >
              {children(step, i)}
            </MultiStepWrapper>
          </Box>
        ))}
      </AnimatePresence>
    </Box>
  );
};

export const PreviousActivity = ({ activity }: { activity: Activity_PreviousActivity }) => {
  const colours = useSparxQuestionColours();
  const { sendEvent } = useClientEvent();

  const skillActivity = activity.state?.skillActivity;
  const steps = useMemo(() => {
    const questionJSON = skillActivity?.question?.questionJson;
    const steps = JSON.parse(questionJSON || '') as ISteps;
    return steps.map((step, i) => {
      const ev = skillActivity?.stepEvaluations[i]?.evaluations[0] || skillActivity?.evaluation;
      if (!step.input) step.input = newInput();
      if (ev?.submittedAnswer) {
        // Rehydrate the steps with the submitted answer if we have one
        rehydrateStepAnswer(step.input, ev.submittedAnswer);
      }
      return { ...step, evaluation: ev };
    });
  }, [skillActivity]);

  return (
    <>
      {steps.map((step, i) => (
        <SparxQuestion
          key={i}
          layout={step.layout}
          input={step.input}
          setInput={() => undefined}
          readOnly={true}
          gapEvaluations={step.evaluation?.gapEvaluations}
          shuffleSeed={activity.name}
          colours={colours}
          getUploadedAsset={uploadedAssetProvider}
          getAssetUrl={getAssetUrl}
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action }, labels)
          }
        />
      ))}
    </>
  );
};
