import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { dateInWeek, Week } from 'api/school';
import { useMemo } from 'react';

export interface TopicAssignment {
  delivered: number[];
  planned: number[];
}

export interface WeekWithAssignments {
  week: Week;
  assignments: Assignment[];
}

// TODO: optimise
export const useWeeksWithAssignments = (weeks: Week[] | undefined, assignments: Assignment[]) =>
  useMemo(
    () =>
      weeks
        ?.map(w => ({
          week: w,
          assignments:
            assignments?.filter(
              a => a.startTimestamp && dateInWeek(w, Timestamp.toDate(a.startTimestamp)),
            ) || [],
        }))
        ?.sort((a, b) => a.week.index - b.week.index) || [],
    [assignments, weeks],
  );

export type TopicWeeks = Record<string, TopicAssignment>;

export const useTopicWeeks = (weekWithAssignments: WeekWithAssignments[]): TopicWeeks =>
  useMemo(() => {
    const topicWeeks: Record<string, TopicAssignment> = {};
    for (const { week, assignments } of weekWithAssignments) {
      for (const assignment of assignments) {
        if (assignment.spec?.contents.oneofKind !== 'generatedAssignment') continue;
        const spec = assignment.spec.contents.generatedAssignment;
        for (const { name } of spec.topics) {
          if (!topicWeeks[name]) topicWeeks[name] = { delivered: [], planned: [] };
          if (assignment.generatedTimestamp) {
            topicWeeks[name].delivered.push(week.index);
          } else {
            topicWeeks[name].planned.push(week.index);
          }
        }
      }
    }
    return topicWeeks;
  }, [weekWithAssignments]);
