// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/staff/v1/staff.proto" (package "sparx.teacherportal.staff.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Product } from '../../../types/product';
import { TrainingProgress } from '../../../training/progress/v1/trainingprogress';
/**
 * Request for the GetStaffRoles method.
 *
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.GetStaffRolesRequest
 */
export interface GetStaffRolesRequest {}
/**
 * Response for the GetStaffRoles method.
 *
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.GetStaffRolesResponse
 */
export interface GetStaffRolesResponse {
  /**
   * All the fields of each staff role.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.staff.v1.StaffRole roles = 1;
   */
  roles: StaffRole[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.StaffRole
 */
export interface StaffRole {
  /**
   * The unique id of the role (eg 'teacher').
   *
   * @generated from protobuf field: string role = 1;
   */
  role: string;
  /**
   * The display name of the role (eg 'Teacher').
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.Staff
 */
export interface Staff {
  /**
   * @generated from protobuf field: string staff_id = 1;
   */
  staffId: string;
  /**
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * @generated from protobuf field: string first_name = 4;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string last_name = 5;
   */
  lastName: string;
  /**
   * @generated from protobuf field: string email_address = 6;
   */
  emailAddress: string;
  /**
   * @generated from protobuf field: string username = 7;
   */
  username: string;
  /**
   * Mode that their username should be set on
   *
   * @generated from protobuf field: sparx.teacherportal.staff.v1.UsernameMode username_mode = 8;
   */
  usernameMode: UsernameMode;
  /**
   * @generated from protobuf field: repeated string roles = 9;
   */
  roles: string[];
  /**
   * The progress this staff member has made in any training modules.
   *
   * @generated from protobuf field: sparx.training.progress.v1.TrainingProgress training_progress = 10;
   */
  trainingProgress?: TrainingProgress;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.GetStaffRequest
 */
export interface GetStaffRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string staff_id = 2;
   */
  staffId: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.DeleteStaffRequest
 */
export interface DeleteStaffRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string staff_id = 2;
   */
  staffId: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.staff.v1.ResetStaffPasswordRequest
 */
export interface ResetStaffPasswordRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: repeated string staff_id = 2;
   */
  staffId: string[];
  /**
   * When product is set the final redirect after password reset will be to the
   * application for that product. Defaults to maths when not set.
   *
   * @generated from protobuf field: sparx.types.Product product = 3;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.teacherportal.staff.v1.ResetStaffPasswordResponse
 */
export interface ResetStaffPasswordResponse {
  /**
   * @generated from protobuf field: repeated string successful_staff_ids = 1;
   */
  successfulStaffIds: string[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.GetStaffResponse
 */
export interface GetStaffResponse {
  /**
   * @generated from protobuf field: sparx.teacherportal.staff.v1.Staff staff = 1;
   */
  staff?: Staff;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.ListStaffRequest
 */
export interface ListStaffRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.ListStaffResponse
 */
export interface ListStaffResponse {
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.staff.v1.Staff staff = 1;
   */
  staff: Staff[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.CreateStaffRequest
 */
export interface CreateStaffRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: sparx.teacherportal.staff.v1.Staff staff = 2;
   */
  staff?: Staff;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.teacherportal.staff.v1.UpdateStaffRequest
 */
export interface UpdateStaffRequest {
  /**
   * @generated from protobuf field: sparx.teacherportal.staff.v1.Staff staff = 1;
   */
  staff?: Staff;
  /**
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf enum sparx.teacherportal.staff.v1.UsernameMode
 */
export enum UsernameMode {
  /**
   * @generated from protobuf enum value: USERNAME_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Username will be created in the form `username@school`.
   *
   * @generated from protobuf enum value: USERNAME_MODE_SCHOOL_SUFFIX = 1;
   */
  SCHOOL_SUFFIX = 1,
  /**
   * Username will be created in the form `username` and the username
   * must be an email address.
   *
   * @generated from protobuf enum value: USERNAME_MODE_EMAIL = 2;
   */
  EMAIL = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRolesRequest$Type extends MessageType<GetStaffRolesRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.GetStaffRolesRequest', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.GetStaffRolesRequest
 */
export const GetStaffRolesRequest = new GetStaffRolesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRolesResponse$Type extends MessageType<GetStaffRolesResponse> {
  constructor() {
    super('sparx.teacherportal.staff.v1.GetStaffRolesResponse', [
      {
        no: 1,
        name: 'roles',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffRole,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.GetStaffRolesResponse
 */
export const GetStaffRolesResponse = new GetStaffRolesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffRole$Type extends MessageType<StaffRole> {
  constructor() {
    super('sparx.teacherportal.staff.v1.StaffRole', [
      { no: 1, name: 'role', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.StaffRole
 */
export const StaffRole = new StaffRole$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Staff$Type extends MessageType<Staff> {
  constructor() {
    super('sparx.teacherportal.staff.v1.Staff', [
      { no: 1, name: 'staff_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'username_mode',
        kind: 'enum',
        T: () => [
          'sparx.teacherportal.staff.v1.UsernameMode',
          UsernameMode,
          'USERNAME_MODE_',
        ],
      },
      {
        no: 9,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'training_progress',
        kind: 'message',
        T: () => TrainingProgress,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.Staff
 */
export const Staff = new Staff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRequest$Type extends MessageType<GetStaffRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.GetStaffRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'staff_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.GetStaffRequest
 */
export const GetStaffRequest = new GetStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteStaffRequest$Type extends MessageType<DeleteStaffRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.DeleteStaffRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'staff_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.DeleteStaffRequest
 */
export const DeleteStaffRequest = new DeleteStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetStaffPasswordRequest$Type extends MessageType<ResetStaffPasswordRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.ResetStaffPasswordRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'staff_id',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.ResetStaffPasswordRequest
 */
export const ResetStaffPasswordRequest = new ResetStaffPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetStaffPasswordResponse$Type extends MessageType<ResetStaffPasswordResponse> {
  constructor() {
    super('sparx.teacherportal.staff.v1.ResetStaffPasswordResponse', [
      {
        no: 1,
        name: 'successful_staff_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.ResetStaffPasswordResponse
 */
export const ResetStaffPasswordResponse = new ResetStaffPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffResponse$Type extends MessageType<GetStaffResponse> {
  constructor() {
    super('sparx.teacherportal.staff.v1.GetStaffResponse', [
      { no: 1, name: 'staff', kind: 'message', T: () => Staff },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.GetStaffResponse
 */
export const GetStaffResponse = new GetStaffResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffRequest$Type extends MessageType<ListStaffRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.ListStaffRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.ListStaffRequest
 */
export const ListStaffRequest = new ListStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffResponse$Type extends MessageType<ListStaffResponse> {
  constructor() {
    super('sparx.teacherportal.staff.v1.ListStaffResponse', [
      {
        no: 1,
        name: 'staff',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Staff,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.ListStaffResponse
 */
export const ListStaffResponse = new ListStaffResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStaffRequest$Type extends MessageType<CreateStaffRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.CreateStaffRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'staff', kind: 'message', T: () => Staff },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.CreateStaffRequest
 */
export const CreateStaffRequest = new CreateStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffRequest$Type extends MessageType<UpdateStaffRequest> {
  constructor() {
    super('sparx.teacherportal.staff.v1.UpdateStaffRequest', [
      { no: 1, name: 'staff', kind: 'message', T: () => Staff },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.teacherportal.staff.v1.UpdateStaffRequest
 */
export const UpdateStaffRequest = new UpdateStaffRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.teacherportal.staff.v1.StaffAPI
 */
export const StaffAPI = new ServiceType(
  'sparx.teacherportal.staff.v1.StaffAPI',
  [
    {
      name: 'GetStaffRoles',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: 'tpcommon',
        },
      },
      I: GetStaffRolesRequest,
      O: GetStaffRolesResponse,
    },
    {
      name: 'GetStaff',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: 'schools/{school_id}',
        },
      },
      I: GetStaffRequest,
      O: GetStaffResponse,
    },
    {
      name: 'ListStaff',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: 'schools/{school_id}',
        },
      },
      I: ListStaffRequest,
      O: ListStaffResponse,
    },
    {
      name: 'DeleteStaff',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: 'schools/{school_id}',
        },
      },
      I: DeleteStaffRequest,
      O: Empty,
    },
    {
      name: 'CreateStaff',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: 'schools/{school_id}',
        },
      },
      I: Staff,
      O: Staff,
    },
    {
      name: 'UpdateStaff',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: 'schools/{staff.school_id}',
        },
      },
      I: UpdateStaffRequest,
      O: Staff,
    },
    {
      name: 'ResetStaffPassword',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: 'schools/{school_id}',
        },
      },
      I: ResetStaffPasswordRequest,
      O: ResetStaffPasswordResponse,
    },
  ]
);
