import { handleKeyPress } from '@sparx/react-utils/keyboard';
import classNames from 'classnames';
import { useRef, useState } from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { ISlotElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { useEnterInputBehaviour } from '../utils/use-enter-input-behaviour';
import { CardContent } from './CardContent';

export const InlineSlotElement = ({ element }: LayoutElementProps<ISlotElement>) => {
  const context = useSparxQuestionContext();
  const [focussed, setFocussed] = useState(false);

  let slotContent;
  const slotCardRef = context.input.slots?.[element.ref]?.card_ref;
  const card = context.input.cards?.[slotCardRef || ''];
  if (slotCardRef && card) {
    // We have a card in this slot - so render it in the slot
    const { show: showCorrect, correct } = isGapCorrect(
      element.ref,
      context.gapEvaluations,
      context.questionMarkingMode,
    );

    slotContent = (
      <CardContent
        readOnly={context.readOnly}
        draggable={false}
        element={{
          ref: slotCardRef,
          element: 'card',
          content: card.content,
        }}
        inSlot={true}
        data-ref={element.ref}
      >
        {showCorrect && <CorrectIcon correct={correct} />}
      </CardContent>
    );
  }

  const open = context.openElementRef === element.ref;

  const handleClick = () => {
    context.setOpenElementRef(open ? '' : element.ref);
  };

  const ref = useRef<HTMLDivElement>(null);
  useEnterInputBehaviour(ref.current, {
    nextInputAction: 'focus',
    waitForAnim: true,
    disabled: !open,
  });

  return (
    <div
      className={classNames(styles.InlineSlot, styles.Tile, {
        [styles.CardSlotActive]: focussed,
        [styles.TileSelected]: !focussed && open,
      })}
      ref={ref}
      onMouseDown={handleClick}
      onKeyUp={handleKeyPress({ Enter: () => handleClick() })}
      onFocus={() => setFocussed(true)}
      onBlur={() => setFocussed(false)}
      data-ref={slotContent === undefined ? element.ref : undefined}
      data-slot={element.ref}
      tabIndex={slotContent === undefined ? 0 : -1}
    >
      {
        // If the slot has not been filled, fill it with some invisible dummy text
        // to make sure it appears to be the right shape
        slotContent || <div className={styles.CardSlotEmpty}>-</div>
      }
    </div>
  );
};
