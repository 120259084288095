// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/notifications.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { TaskItem_Status } from '../../packages/v1/package';
import { Any } from '../../../../../google/protobuf/any';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonStudentNotification
 */
export interface LessonStudentNotification {
  /**
   * Unique ID of this notification
   *
   * @generated from protobuf field: string notification_id = 1;
   */
  notificationId: string;
  /**
   * Timestamp the notification was created
   *
   * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 2;
   */
  creationTimestamp?: Timestamp;
  /**
   * The notification content.
   *
   * @generated from protobuf field: google.protobuf.Any notification = 3;
   */
  notification?: Any;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.MarkingNotification
 */
export interface MarkingNotification {
  /**
   * Name of the task item that was marked.
   *
   * @generated from protobuf field: string task_item_name = 1;
   */
  taskItemName: string;
  /**
   * Activity that was marked.
   *
   * @generated from protobuf field: string activity_name = 2;
   */
  activityName: string;
  /**
   * The new status of the task item.
   *
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Status status = 3;
   */
  status: TaskItem_Status;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.HandsDownNotification
 */
export interface HandsDownNotification {}
// @generated message type with reflection information, may provide speed optimized methods
class LessonStudentNotification$Type extends MessageType<LessonStudentNotification> {
  constructor() {
    super('sparx.science.lessons.v1.LessonStudentNotification', [
      {
        no: 1,
        name: 'notification_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'creation_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 3, name: 'notification', kind: 'message', T: () => Any },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonStudentNotification
 */
export const LessonStudentNotification = new LessonStudentNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkingNotification$Type extends MessageType<MarkingNotification> {
  constructor() {
    super('sparx.science.lessons.v1.MarkingNotification', [
      {
        no: 1,
        name: 'task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'activity_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.science.packages.v1.TaskItem.Status', TaskItem_Status],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.MarkingNotification
 */
export const MarkingNotification = new MarkingNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandsDownNotification$Type extends MessageType<HandsDownNotification> {
  constructor() {
    super('sparx.science.lessons.v1.HandsDownNotification', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.HandsDownNotification
 */
export const HandsDownNotification = new HandsDownNotification$Type();
