import { useEffect, useState } from 'react';

/**
 * Debounce the updating of a value by a specified delay. The hook will
 * return the debounced value.
 * Adapted from: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 * @param value the value to debounce
 * @param delay the debounce delay
 */
export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time useEffect
      // is re-called. useEffect will only be re-called if value changes
      // (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is
      // changed within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // Could also add the "delay" var to inputs array if you need to be able to
    // change that dynamically.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  return delay === 0 ? value : debouncedValue;
};
