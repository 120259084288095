import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { isComplete } from 'components/CompletionBadge';
import React from 'react';

interface ResultsProps {
  task: Task | undefined;
  onContinue?: () => void;
  onFinish?: () => void;
}

export const Results = ({ task, onContinue, onFinish }: ResultsProps) => {
  const complete = isComplete(task?.state?.completion);
  const completion = task?.state?.completion;
  const completeItems = (completion?.progress?.['C'] || 0) + (completion?.progress?.['SK'] || 0);
  const cftItems = completion?.progress?.['CFT'] || 0;
  const totalItems = completion?.size || 0;

  if (!complete) {
    return (
      <Box py={14}>
        <VStack spacing={2}>
          <Heading size="lg">Keep going!</Heading>
          <Text color="gray.500" fontWeight="bold">
            {completeItems}/{totalItems} complete
          </Text>
          <Box height={2} />
          <Button
            size={['sm', 'md']}
            colorScheme="buttonTeal"
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={onContinue}
          >
            Keep going
          </Button>
        </VStack>
      </Box>
    );
  }

  return (
    <Box py={14}>
      <VStack spacing={2}>
        <Box
          width={100}
          height={100}
          color="green.500"
          fontSize="5xl"
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="gray.100"
          borderRadius="full"
          mb={4}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Box>
        <Heading size="lg">
          <Text color="green.500" as="span">
            Task complete!
          </Text>{' '}
          Well done.
        </Heading>
        <Text color="gray.500" fontWeight="bold">
          You got {cftItems}/{totalItems} correct first time.
        </Text>
        <Box height={2} />
        <Button
          size={['sm', 'md']}
          colorScheme="buttonTeal"
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={onFinish}
        >
          Finish
        </Button>
      </VStack>
    </Box>
  );
};
