// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/v2/curriculum_enums.proto" (package "sparx.content.v2", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * Deprecated. TopicType allows topics to be included or excluded from certain contexts.
 *
 * @generated from protobuf enum sparx.content.v2.TopicType
 */
export enum TopicType {
  /**
   * Default enum value. Equivalent to TOPIC_TYPE_STANDARD
   *
   * @generated from protobuf enum value: TOPIC_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Topic is a regular topic.
   *
   * @generated from protobuf enum value: TOPIC_TYPE_STANDARD = 1;
   */
  STANDARD = 1,
  /**
   * Topic is a revision topic.
   *
   * @generated from protobuf enum value: TOPIC_TYPE_REVISION = 2;
   */
  REVISION = 2,
  /**
   * Deprecated. Intro topics are now specified using CurriculumToTopicLinks.
   *
   * @deprecated
   * @generated from protobuf enum value: TOPIC_TYPE_INTRO = 3 [deprecated = true];
   */
  INTRO = 3,
  /**
   * Topic is starter content.
   *
   * @generated from protobuf enum value: TOPIC_TYPE_STARTER = 4;
   */
  STARTER = 4,
  /**
   * Topic is practice content (i.e. for use in "Practice using Sparx")
   *
   * @generated from protobuf enum value: TOPIC_TYPE_PRACTICE = 5;
   */
  PRACTICE = 5,
}
/**
 * TopicLinkType is the different types of links that exist between topics.
 *
 * @generated from protobuf enum sparx.content.v2.TopicLinkType
 */
export enum TopicLinkType {
  /**
   * Default enum value.
   *
   * @generated from protobuf enum value: TOPIC_LINK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Easier Replacement links specify alternate topics that can be used to find
   * suitable content for weaker learners.
   *
   * @generated from protobuf enum value: TOPIC_LINK_TYPE_EASIER_REPLACEMENT = 1;
   */
  EASIER_REPLACEMENT = 1,
  /**
   * Harder Replacement links specify alternate topics that can be used to find
   * suitable content for stronger learners.
   *
   * @generated from protobuf enum value: TOPIC_LINK_TYPE_HARDER_REPLACEMENT = 2;
   */
  HARDER_REPLACEMENT = 2,
  /**
   * Prerequisite links specify topics that learners should complete before
   * attempting this topic.
   *
   * @generated from protobuf enum value: TOPIC_LINK_TYPE_PREREQUISITE = 3;
   */
  PREREQUISITE = 3,
  /**
   * Subsequent links specify topics that are intended to be delivered next,
   * after this topic.
   *
   * @generated from protobuf enum value: TOPIC_LINK_TYPE_SUBSEQUENT = 4;
   */
  SUBSEQUENT = 4,
}
/**
 * CurriculumToTopicLinkType is the different types of links that exist between a curriculum and a
 * topic.
 *
 * @generated from protobuf enum sparx.content.v2.CurriculumToTopicLinkType
 */
export enum CurriculumToTopicLinkType {
  /**
   * Default enum value.
   *
   * @generated from protobuf enum value: CURRICULUM_TO_TOPIC_LINK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Intro content topics for the curriculum
   *
   * @generated from protobuf enum value: CURRICULUM_TO_TOPIC_LINK_TYPE_INTRO_CONTENT = 1;
   */
  INTRO_CONTENT = 1,
}
