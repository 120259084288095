import { useSlideyGroupContext } from '../question/SlideyContext';
import { LayoutElementProps } from '../question/SparxQuestionContext';
import { ISlotElement } from '../question/types';
import { InlineSlotElement } from './InlineSlotElement';
import { SlotElement } from './SlotElement';

export const SlotOrInlineSlotElement = ({ element }: LayoutElementProps<ISlotElement>) => {
  const isWithinSlidey = useSlideyGroupContext().slidey;
  if (element.accept && !isWithinSlidey) {
    return <InlineSlotElement element={element} />;
  }
  return <SlotElement element={element} />;
};
