// Import the functions you need from the SDKs you need
import * as Sentry from '@sentry/react';
import { GetCurrentSessionResponse } from '@sparx/api/apis/sparx/science/sessions/v1/session';
import { scienceApiURL } from 'api';
import {
  Analytics,
  CustomParams,
  getAnalytics,
  isSupported,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = (() => {
  switch (window.settings?.ENV_NAME) {
    case 'prod':
      return {
        apiKey: 'AIzaSyDJb-YQqAlyceMUqM06J_6rfEaXst4d6zk',
        authDomain: 'sparx-science.firebaseapp.com',
        projectId: 'sparx-science',
        storageBucket: 'sparx-science.appspot.com',
        messagingSenderId: '1054246435475',
        appId: '1:1054246435475:web:74574ad2929100be00ab66',
        measurementId: 'G-Q2JHNW9PEW',
      };
    case 'test1':
      return {
        apiKey: 'AIzaSyCTC4FBnerBBNxcunLdWabYXVlqsotZVX0',
        authDomain: 'sparx-science-test.firebaseapp.com',
        projectId: 'sparx-science-test',
        storageBucket: 'sparx-science-test.appspot.com',
        messagingSenderId: '1093172981459',
        appId: '1:1093172981459:web:941a7c983e5f7680512e26',
        measurementId: 'G-KYT7GEL3KL',
      };
    default:
      // test2 / dev config -> default
      return {
        apiKey: 'AIzaSyCTC4FBnerBBNxcunLdWabYXVlqsotZVX0',
        authDomain: 'sparx-science-test.firebaseapp.com',
        projectId: 'sparx-science-test',
        storageBucket: 'sparx-science-test.appspot.com',
        messagingSenderId: '1093172981459',
        appId: '1:1093172981459:web:4e823ff802e1abeb512e26',
        measurementId: 'G-6SQ4JBBJLW',
      };
  }
})();

// Initialize Firebase
const appAnalytics = (async (): Promise<Analytics> => {
  if (!(await isSupported())) {
    throw new Error('not supported');
  }
  const app = initializeApp(firebaseConfig);
  const analytics = await getAnalytics(app);
  if (!analytics) {
    throw new Error('not initialized');
  }
  return analytics;
})().catch(err => console.info('ga: analytics', err));

export const setAnalyticsUserProperties = (properties: CustomParams) => {
  appAnalytics
    .then(async analytics => {
      // Set the user ID and properties
      if (analytics) {
        setUserId(analytics, properties.user_id as string);
        setUserProperties(analytics, properties);
      }
    })
    .catch(err => console.info('Analytics not supported setting user properties:', err));
};

// Initialize Sentry
const tracingAPIURL = scienceApiURL.replace(/https?:\/\//, '');
Sentry.init({
  dsn: 'https://6ed5d3e691d64e7ebc2b175955f6060a@o1086305.ingest.sentry.io/4504610580463616',
  tracePropagationTargets: ['localhost', /^\//, tracingAPIURL],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: ['localhost', /^\//, tracingAPIURL, /https?:\/\/.*\.sparxmaths\.uk/],
      networkRequestHeaders: ['Content-Type', 'X-Grpc-Web'],
      networkResponseHeaders: [
        'Content-Type',
        'Cf-Cache-Status',
        'Cf-Ray',
        'Grpc-Message',
        'Grpc-Status',
      ],
    }),
  ],

  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0, // Will be triggered manually in configureUserProperties
  replaysOnErrorSampleRate: 0, // Disable error replays for now

  release: import.meta.env.VITE_RELEASE_VERSION,
  environment: window.settings?.ENV_NAME,
  enabled: Boolean(window.settings?.ENV_NAME),

  // Exclude 'ResizeObserver' errors as these are safe to ignore
  // Exclude 'LoadFailed' errors as we think that they're out of our control, e.g. network issues.
  ignoreErrors: ['ResizeObserver', 'Load failed'],
});

let configuredProperties = false;
export const configureUserProperties = (data: GetCurrentSessionResponse) => {
  // Ensure done once per load
  if (configuredProperties) return;
  configuredProperties = true;

  console.info('Configuring user properties');

  // Set analytics settings
  setAnalyticsUserProperties({
    user_id: data.userId,
    sparx_user_id: data.sparxUserId || '',
    user_type: data.userType,
    school_id: data.schoolId,
  });
  Sentry.setUser({ id: data.userId });
  Sentry.setTags({
    userType: data.userType,
    schoolId: data.schoolId,
    sparxUserId: data.sparxUserId || '',
  });

  // Hotjar Identify
  if (window.hj) {
    window.hj('identify', data.userId, {
      user_id: data.userId,
      sparx_user_id: data.sparxUserId || '',
      user_type: data.userType,
      school_id: data.schoolId,
    });
  }

  // Start sentry session replay if flag is enabled
  const flag = data.featureFlags?.fields['session-replay'];
  const enableSessionReplay = flag?.kind?.oneofKind === 'boolValue' && flag.kind.boolValue;
  if (enableSessionReplay) {
    console.log('Starting session replay...');
    const replay = Sentry.getCurrentHub().getIntegration(Sentry.Replay);
    replay?.start();
  }

  // Store some ID's on the window, so we can access it easily
  window.SCIENCE_SCHOOL_ID = data.schoolId;
  window.SCIENCE_USER_ID = data.userId;
  window.SPARX_USER_ID = data.sparxUserId || '';
};

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
