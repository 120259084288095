// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/planner.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Planner } from './planner';
import type { UpdateConsolidationTopicActionsRequest } from './planner';
import type { ConsolidationTopicActions } from './planner';
import type { ListConsolidationTopicActionsRequest } from './planner';
import type { GetAssignmentSummariesResponse } from './planner';
import type { GetAssignmentSummariesRequest } from './planner';
import type { ListAssignmentPackagesResponse } from './planner';
import type { ListAssignmentPackagesRequest } from './planner';
import type { GetPackagePreviewResponse } from './planner';
import type { GetPackagePreviewRequest } from './planner';
import type { DeleteAssignmentResponse } from './planner';
import type { DeleteAssignmentRequest } from './planner';
import type { UpdateGeneratedAssignmentRequest } from './planner';
import type { UpdateAssignmentRequest } from './planner';
import type { Assignment } from './planner';
import type { CreateAssignmentRequest } from './planner';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListAssignmentsResponse } from './planner';
import type { ListAssignmentsRequest } from './planner';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.Planner
 */
export interface IPlannerClient {
  /**
   * @generated from protobuf rpc: ListAssignments(sparx.science.packages.v1.ListAssignmentsRequest) returns (sparx.science.packages.v1.ListAssignmentsResponse);
   */
  listAssignments(
    input: ListAssignmentsRequest,
    options?: RpcOptions
  ): UnaryCall<ListAssignmentsRequest, ListAssignmentsResponse>;
  /**
   * @generated from protobuf rpc: CreateAssignment(sparx.science.packages.v1.CreateAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  createAssignment(
    input: CreateAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<CreateAssignmentRequest, Assignment>;
  /**
   * @generated from protobuf rpc: UpdateAssignment(sparx.science.packages.v1.UpdateAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  updateAssignment(
    input: UpdateAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateAssignmentRequest, Assignment>;
  /**
   * @generated from protobuf rpc: UpdateGeneratedAssignment(sparx.science.packages.v1.UpdateGeneratedAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  updateGeneratedAssignment(
    input: UpdateGeneratedAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateGeneratedAssignmentRequest, Assignment>;
  /**
   * @generated from protobuf rpc: DeleteAssignment(sparx.science.packages.v1.DeleteAssignmentRequest) returns (sparx.science.packages.v1.DeleteAssignmentResponse);
   */
  deleteAssignment(
    input: DeleteAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteAssignmentRequest, DeleteAssignmentResponse>;
  /**
   * @generated from protobuf rpc: GetPackagePreview(sparx.science.packages.v1.GetPackagePreviewRequest) returns (sparx.science.packages.v1.GetPackagePreviewResponse);
   */
  getPackagePreview(
    input: GetPackagePreviewRequest,
    options?: RpcOptions
  ): UnaryCall<GetPackagePreviewRequest, GetPackagePreviewResponse>;
  /**
   * @generated from protobuf rpc: ListAssignmentPackages(sparx.science.packages.v1.ListAssignmentPackagesRequest) returns (sparx.science.packages.v1.ListAssignmentPackagesResponse);
   */
  listAssignmentPackages(
    input: ListAssignmentPackagesRequest,
    options?: RpcOptions
  ): UnaryCall<ListAssignmentPackagesRequest, ListAssignmentPackagesResponse>;
  /**
   * @generated from protobuf rpc: GetAssignmentSummaries(sparx.science.packages.v1.GetAssignmentSummariesRequest) returns (sparx.science.packages.v1.GetAssignmentSummariesResponse);
   */
  getAssignmentSummaries(
    input: GetAssignmentSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<GetAssignmentSummariesRequest, GetAssignmentSummariesResponse>;
  /**
   * @generated from protobuf rpc: ListConsolidationTopicActions(sparx.science.packages.v1.ListConsolidationTopicActionsRequest) returns (sparx.science.packages.v1.ConsolidationTopicActions);
   */
  listConsolidationTopicActions(
    input: ListConsolidationTopicActionsRequest,
    options?: RpcOptions
  ): UnaryCall<ListConsolidationTopicActionsRequest, ConsolidationTopicActions>;
  /**
   * @generated from protobuf rpc: UpdateConsolidationTopicActions(sparx.science.packages.v1.UpdateConsolidationTopicActionsRequest) returns (sparx.science.packages.v1.ConsolidationTopicActions);
   */
  updateConsolidationTopicActions(
    input: UpdateConsolidationTopicActionsRequest,
    options?: RpcOptions
  ): UnaryCall<
    UpdateConsolidationTopicActionsRequest,
    ConsolidationTopicActions
  >;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.Planner
 */
export class PlannerClient implements IPlannerClient, ServiceInfo {
  typeName = Planner.typeName;
  methods = Planner.methods;
  options = Planner.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListAssignments(sparx.science.packages.v1.ListAssignmentsRequest) returns (sparx.science.packages.v1.ListAssignmentsResponse);
   */
  listAssignments(
    input: ListAssignmentsRequest,
    options?: RpcOptions
  ): UnaryCall<ListAssignmentsRequest, ListAssignmentsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListAssignmentsRequest, ListAssignmentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: CreateAssignment(sparx.science.packages.v1.CreateAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  createAssignment(
    input: CreateAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<CreateAssignmentRequest, Assignment> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateAssignmentRequest, Assignment>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateAssignment(sparx.science.packages.v1.UpdateAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  updateAssignment(
    input: UpdateAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateAssignmentRequest, Assignment> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateAssignmentRequest, Assignment>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateGeneratedAssignment(sparx.science.packages.v1.UpdateGeneratedAssignmentRequest) returns (sparx.science.packages.v1.Assignment);
   */
  updateGeneratedAssignment(
    input: UpdateGeneratedAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateGeneratedAssignmentRequest, Assignment> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateGeneratedAssignmentRequest, Assignment>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: DeleteAssignment(sparx.science.packages.v1.DeleteAssignmentRequest) returns (sparx.science.packages.v1.DeleteAssignmentResponse);
   */
  deleteAssignment(
    input: DeleteAssignmentRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteAssignmentRequest, DeleteAssignmentResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteAssignmentRequest, DeleteAssignmentResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetPackagePreview(sparx.science.packages.v1.GetPackagePreviewRequest) returns (sparx.science.packages.v1.GetPackagePreviewResponse);
   */
  getPackagePreview(
    input: GetPackagePreviewRequest,
    options?: RpcOptions
  ): UnaryCall<GetPackagePreviewRequest, GetPackagePreviewResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetPackagePreviewRequest, GetPackagePreviewResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ListAssignmentPackages(sparx.science.packages.v1.ListAssignmentPackagesRequest) returns (sparx.science.packages.v1.ListAssignmentPackagesResponse);
   */
  listAssignmentPackages(
    input: ListAssignmentPackagesRequest,
    options?: RpcOptions
  ): UnaryCall<ListAssignmentPackagesRequest, ListAssignmentPackagesResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssignmentPackagesRequest,
      ListAssignmentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetAssignmentSummaries(sparx.science.packages.v1.GetAssignmentSummariesRequest) returns (sparx.science.packages.v1.GetAssignmentSummariesResponse);
   */
  getAssignmentSummaries(
    input: GetAssignmentSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<GetAssignmentSummariesRequest, GetAssignmentSummariesResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssignmentSummariesRequest,
      GetAssignmentSummariesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListConsolidationTopicActions(sparx.science.packages.v1.ListConsolidationTopicActionsRequest) returns (sparx.science.packages.v1.ConsolidationTopicActions);
   */
  listConsolidationTopicActions(
    input: ListConsolidationTopicActionsRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListConsolidationTopicActionsRequest,
    ConsolidationTopicActions
  > {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListConsolidationTopicActionsRequest,
      ConsolidationTopicActions
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateConsolidationTopicActions(sparx.science.packages.v1.UpdateConsolidationTopicActionsRequest) returns (sparx.science.packages.v1.ConsolidationTopicActions);
   */
  updateConsolidationTopicActions(
    input: UpdateConsolidationTopicActionsRequest,
    options?: RpcOptions
  ): UnaryCall<
    UpdateConsolidationTopicActionsRequest,
    ConsolidationTopicActions
  > {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateConsolidationTopicActionsRequest,
      ConsolidationTopicActions
    >('unary', this._transport, method, opt, input);
  }
}
