// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/training/progress/v1/trainingprogress.proto" (package "sparx.training.progress.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { TrainingProgressService } from './trainingprogress';
import type { BatchGetTrainingProgressResponse } from './trainingprogress';
import type { BatchGetTrainingProgressRequest } from './trainingprogress';
import type { AliasTrainingProgressRequest } from './trainingprogress';
import type { DeleteTrainingProgressRequest } from './trainingprogress';
import type { ListTrainingProgressesResponse } from './trainingprogress';
import type { ListTrainingProgressesRequest } from './trainingprogress';
import type { WatchTrainingProgressRequest } from './trainingprogress';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { UpdateTrainingProgressForCurrentUserRequest } from './trainingprogress';
import type { UpdateTrainingProgressRequest } from './trainingprogress';
import type { GetTrainingProgressForCurrentUserRequest } from './trainingprogress';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { TrainingProgress } from './trainingprogress';
import type { GetTrainingProgressRequest } from './trainingprogress';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.training.progress.v1.TrainingProgressService
 */
export interface ITrainingProgressServiceClient {
  /**
   * Gets training progress for a user. Default permissions require
   * global read, but certain aliases will allow different checks.
   * Will always return a result. If an alias is used that doesnt
   * currently exist the name will be blank.
   *
   * @generated from protobuf rpc: GetTrainingProgress(sparx.training.progress.v1.GetTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  getTrainingProgress(
    input: GetTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<GetTrainingProgressRequest, TrainingProgress>;
  /**
   * Gets training progress for the current user, as specified by
   * supplied authentication.
   *
   * @generated from protobuf rpc: GetTrainingProgressForCurrentUser(sparx.training.progress.v1.GetTrainingProgressForCurrentUserRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  getTrainingProgressForCurrentUser(
    input: GetTrainingProgressForCurrentUserRequest,
    options?: RpcOptions
  ): UnaryCall<GetTrainingProgressForCurrentUserRequest, TrainingProgress>;
  /**
   * Updates training progress for a user. Default permissions require
   * global write, but certain aliases will allow different checks.
   *
   * @generated from protobuf rpc: UpdateTrainingProgress(sparx.training.progress.v1.UpdateTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  updateTrainingProgress(
    input: UpdateTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateTrainingProgressRequest, TrainingProgress>;
  /**
   * Updates training progress for the current user, as specified by
   * supplied authentication.
   *
   * @generated from protobuf rpc: UpdateTrainingProgressForCurrentUser(sparx.training.progress.v1.UpdateTrainingProgressForCurrentUserRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  updateTrainingProgressForCurrentUser(
    input: UpdateTrainingProgressForCurrentUserRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateTrainingProgressForCurrentUserRequest, TrainingProgress>;
  /**
   * Gets training progress for a user. Default permissions require
   * global read, but certain aliases will allow different checks.
   *
   * @generated from protobuf rpc: WatchTrainingProgress(sparx.training.progress.v1.WatchTrainingProgressRequest) returns (stream sparx.training.progress.v1.TrainingProgress);
   */
  watchTrainingProgress(
    input: WatchTrainingProgressRequest,
    options?: RpcOptions
  ): ServerStreamingCall<WatchTrainingProgressRequest, TrainingProgress>;
  // Additional standard methods for developer aids

  /**
   * Lists all training progress records.
   *
   * @generated from protobuf rpc: ListTrainingProgresses(sparx.training.progress.v1.ListTrainingProgressesRequest) returns (sparx.training.progress.v1.ListTrainingProgressesResponse);
   */
  listTrainingProgresses(
    input: ListTrainingProgressesRequest,
    options?: RpcOptions
  ): UnaryCall<ListTrainingProgressesRequest, ListTrainingProgressesResponse>;
  /**
   * Deletes progress in a training record.
   *
   * @generated from protobuf rpc: DeleteTrainingProgress(sparx.training.progress.v1.DeleteTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  deleteTrainingProgress(
    input: DeleteTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteTrainingProgressRequest, TrainingProgress>;
  /**
   * Adds an additional alias to a training record. Returns an error if
   * the alias is already attached to a training record.
   *
   * @generated from protobuf rpc: AliasTrainingProgress(sparx.training.progress.v1.AliasTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  aliasTrainingProgress(
    input: AliasTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<AliasTrainingProgressRequest, TrainingProgress>;
  /**
   * Batch gets training progress for a list of names of aliases. Requires general read permissions. Returns an
   * InvalidArgument error if more than 1000 are provided.
   *
   * @generated from protobuf rpc: BatchGetTrainingProgress(sparx.training.progress.v1.BatchGetTrainingProgressRequest) returns (sparx.training.progress.v1.BatchGetTrainingProgressResponse);
   */
  batchGetTrainingProgress(
    input: BatchGetTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<
    BatchGetTrainingProgressRequest,
    BatchGetTrainingProgressResponse
  >;
}
/**
 * @generated from protobuf service sparx.training.progress.v1.TrainingProgressService
 */
export class TrainingProgressServiceClient
  implements ITrainingProgressServiceClient, ServiceInfo
{
  typeName = TrainingProgressService.typeName;
  methods = TrainingProgressService.methods;
  options = TrainingProgressService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Gets training progress for a user. Default permissions require
   * global read, but certain aliases will allow different checks.
   * Will always return a result. If an alias is used that doesnt
   * currently exist the name will be blank.
   *
   * @generated from protobuf rpc: GetTrainingProgress(sparx.training.progress.v1.GetTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  getTrainingProgress(
    input: GetTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<GetTrainingProgressRequest, TrainingProgress> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTrainingProgressRequest, TrainingProgress>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Gets training progress for the current user, as specified by
   * supplied authentication.
   *
   * @generated from protobuf rpc: GetTrainingProgressForCurrentUser(sparx.training.progress.v1.GetTrainingProgressForCurrentUserRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  getTrainingProgressForCurrentUser(
    input: GetTrainingProgressForCurrentUserRequest,
    options?: RpcOptions
  ): UnaryCall<GetTrainingProgressForCurrentUserRequest, TrainingProgress> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetTrainingProgressForCurrentUserRequest,
      TrainingProgress
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Updates training progress for a user. Default permissions require
   * global write, but certain aliases will allow different checks.
   *
   * @generated from protobuf rpc: UpdateTrainingProgress(sparx.training.progress.v1.UpdateTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  updateTrainingProgress(
    input: UpdateTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateTrainingProgressRequest, TrainingProgress> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateTrainingProgressRequest, TrainingProgress>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Updates training progress for the current user, as specified by
   * supplied authentication.
   *
   * @generated from protobuf rpc: UpdateTrainingProgressForCurrentUser(sparx.training.progress.v1.UpdateTrainingProgressForCurrentUserRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  updateTrainingProgressForCurrentUser(
    input: UpdateTrainingProgressForCurrentUserRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateTrainingProgressForCurrentUserRequest, TrainingProgress> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateTrainingProgressForCurrentUserRequest,
      TrainingProgress
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Gets training progress for a user. Default permissions require
   * global read, but certain aliases will allow different checks.
   *
   * @generated from protobuf rpc: WatchTrainingProgress(sparx.training.progress.v1.WatchTrainingProgressRequest) returns (stream sparx.training.progress.v1.TrainingProgress);
   */
  watchTrainingProgress(
    input: WatchTrainingProgressRequest,
    options?: RpcOptions
  ): ServerStreamingCall<WatchTrainingProgressRequest, TrainingProgress> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<WatchTrainingProgressRequest, TrainingProgress>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input
    );
  }
  // Additional standard methods for developer aids

  /**
   * Lists all training progress records.
   *
   * @generated from protobuf rpc: ListTrainingProgresses(sparx.training.progress.v1.ListTrainingProgressesRequest) returns (sparx.training.progress.v1.ListTrainingProgressesResponse);
   */
  listTrainingProgresses(
    input: ListTrainingProgressesRequest,
    options?: RpcOptions
  ): UnaryCall<ListTrainingProgressesRequest, ListTrainingProgressesResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListTrainingProgressesRequest,
      ListTrainingProgressesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Deletes progress in a training record.
   *
   * @generated from protobuf rpc: DeleteTrainingProgress(sparx.training.progress.v1.DeleteTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  deleteTrainingProgress(
    input: DeleteTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteTrainingProgressRequest, TrainingProgress> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteTrainingProgressRequest, TrainingProgress>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Adds an additional alias to a training record. Returns an error if
   * the alias is already attached to a training record.
   *
   * @generated from protobuf rpc: AliasTrainingProgress(sparx.training.progress.v1.AliasTrainingProgressRequest) returns (sparx.training.progress.v1.TrainingProgress);
   */
  aliasTrainingProgress(
    input: AliasTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<AliasTrainingProgressRequest, TrainingProgress> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AliasTrainingProgressRequest, TrainingProgress>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Batch gets training progress for a list of names of aliases. Requires general read permissions. Returns an
   * InvalidArgument error if more than 1000 are provided.
   *
   * @generated from protobuf rpc: BatchGetTrainingProgress(sparx.training.progress.v1.BatchGetTrainingProgressRequest) returns (sparx.training.progress.v1.BatchGetTrainingProgressResponse);
   */
  batchGetTrainingProgress(
    input: BatchGetTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<
    BatchGetTrainingProgressRequest,
    BatchGetTrainingProgressResponse
  > {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetTrainingProgressRequest,
      BatchGetTrainingProgressResponse
    >('unary', this._transport, method, opt, input);
  }
}
