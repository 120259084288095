import { Button, Center, HStack, Text } from '@chakra-ui/react';
import { faCircleExclamation, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface UnknownErrorProps {
  reset?: () => void;
}

const defaultMessage = 'Sorry, there was an error loading this page. Please try again.';

export const UnknownError = ({
  children = defaultMessage,
  reset,
}: PropsWithChildren<UnknownErrorProps>) => (
  <Center py={16} px={2} display="flex" flexDirection="column">
    <Text fontSize={80} color="blue.800">
      <FontAwesomeIcon icon={faCircleExclamation} />
    </Text>
    <Text fontSize="xl" fontWeight="bold" pt={2} pb={1}>
      Error
    </Text>
    <Text textAlign="center">{children}</Text>
    <HStack spacing={2} mt={8}>
      <Button as={Link} to="/" variant="outline" colorScheme="buttonTeal">
        Go home
      </Button>
      <Button onClick={reset} colorScheme="buttonTeal" leftIcon={<FontAwesomeIcon icon={faRedo} />}>
        Reload
      </Button>
    </HStack>
  </Center>
);
