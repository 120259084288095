import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleKeyPress, KeyMappings } from '@sparx/react-utils/keyboard';
import { Checkbox } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import { useCallback, useMemo, useRef } from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { findChoiceGroupForRef } from '../question/input';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IChoiceElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { useScaleElementsToFit } from '../utils/useScaleElementsToFit';
import { useChoiceGroupContext } from './ChoiceGroupContext';
import { LayoutElements } from './LayoutElement';

export const ChoiceElement = ({ element }: LayoutElementProps<IChoiceElement>) => {
  if (element.variant === 'checkbox') {
    return <ChoiceCheckbox element={element} />;
  }
  return <ChoiceDefault element={element} />;
};

const ChoiceDefault = ({ element }: LayoutElementProps<IChoiceElement>) => {
  const context = useSparxQuestionContext();
  const selected = context.input.choices?.[element.ref]?.selected;

  const click = () =>
    !context.readOnly &&
    context.sendAction({
      ref: element.ref,
      action: 'set_choice',
    });

  // Choice elements actually determine their correctness from the choice group
  // instead of the choice themselves.
  const { show: showCorrect, correct } = useChoiceGroupContext();
  const showIfCorrect = selected && showCorrect;

  const keymaps: KeyMappings = {
    ' ': () => click(),
  };

  if (context.keyboardMode) {
    keymaps['Enter'] = () => click();
  }

  const choiceRef = useRef<HTMLDivElement>(null);
  const getParentWidth = useCallback(
    () => context.questionElement?.offsetWidth || 0,
    [context.questionElement?.offsetWidth],
  );

  useScaleElementsToFit(getParentWidth, () => [choiceRef.current]);

  // If we weren't filtering these out server side it would be possible
  // to show the exact evaluation here:
  // const { show: showCorrect, correct } = isCorrect(element.ref, context.gapEvaluations);
  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.Option, {
        [styles.OptionDisabled]: context.readOnly,
        [styles.OptionSelected]: selected,
        [styles.OptionCorrect]: showIfCorrect && correct,
        [styles.OptionIncorrect]: showIfCorrect && !correct,
      })}
      onClick={click}
      onKeyDown={handleKeyPress(keymaps)}
      data-ref={element.ref}
      ref={choiceRef}
    >
      <LayoutElements element={element} />
      {showIfCorrect && <CorrectIcon correct={correct} />}
    </div>
  );
};

const ChoiceCheckbox = ({ element }: LayoutElementProps<IChoiceElement>) => {
  const context = useSparxQuestionContext();
  const selected = context.input.choices?.[element.ref]?.selected;

  const click = () =>
    !context.readOnly &&
    context.sendAction({
      ref: element.ref,
      action: 'set_choice',
    });

  // Choice elements actually determine their correctness from the choice group
  // instead of the choice themselves.
  // We don't use the choice group context here as checkboxes are used in the
  // templated content element for grid fill which doesn't wrap with the ChoiceGroup.
  // It would be better if both choice versions used the same method of determining correctness.
  const {
    show: showCorrect,
    correct,
    hasMultipleAnswers: multiSelect,
  } = useChoiceGroupEval(element);

  // TODO: keymaps?

  // TODO: is data-ref in the right place?
  return (
    <div className={styles.CheckboxOptionWrapper}>
      <label
        // Wrap the checkbox in a label to increase the area that can be clicked to toggle it
        className={classNames(styles.CheckboxOption, {
          [styles.CheckboxOptionDisabled]: context.readOnly,
        })}
        data-ref={element.ref}
      >
        <Checkbox
          className={classNames(styles.CheckboxCheckBox, {
            [styles.Correct]: showCorrect && correct,
            [styles.Incorrect]: showCorrect && !multiSelect && !correct,
          })}
          checked={selected}
          onCheckedChange={click}
          disabled={context.readOnly}
          checkIcon={
            <FontAwesomeIcon
              icon={faCheck}
              fixedWidth={true}
              className={styles.CheckboxCheckIcon}
            />
          }
        />
      </label>
      {showCorrect && <CorrectIcon correct={correct} className={styles.CheckboxOptionResultIcon} />}
    </div>
  );
};

const useChoiceGroupEval = (element: IChoiceElement) => {
  const context = useSparxQuestionContext();
  return useMemo(() => {
    const [groupRef, group] = findChoiceGroupForRef(context.input, element.ref) || [];
    const hasMultipleAnswers =
      group &&
      (typeof group.max_choices === 'string' ? parseInt(group.max_choices) : group.max_choices) > 1;

    const groupEval = isGapCorrect(
      groupRef || '',
      context.gapEvaluations,
      context.questionMarkingMode,
    );
    return { ...groupEval, hasMultipleAnswers };
  }, [element, context.input, context.questionMarkingMode, context.gapEvaluations]);
};
