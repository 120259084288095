import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';

import { CorrectIcon } from '../components/CorrectIcon';
import { useSlideyGroupContext } from '../question/SlideyContext';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { ISlotElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { CardElement } from './CardElement';

export const SlotElement = ({ element }: LayoutElementProps<ISlotElement>) => {
  const context = useSparxQuestionContext();
  const { selectedCardRef, setSelectedCardRef } = useSlideyGroupContext();

  const { show: showCorrect, correct } = isGapCorrect(
    element.ref,
    context.gapEvaluations,
    context.questionMarkingMode,
  );

  let slotContent;
  const slot = context.input.slots?.[element.ref];
  const slotCardRef = context.input.slots?.[element.ref]?.card_ref;
  const card = context.input.cards?.[slotCardRef || ''];
  if (slotCardRef && card) {
    // We have a card in this slot - so render it in the slot
    slotContent = (
      <CardElement
        key={card.slot_ref}
        element={{
          ref: slotCardRef,
          element: 'card',
          content: card.content,
        }}
        locked={slot?.locked}
      />
    );
  }

  const { isOver, setNodeRef } = useDroppable({
    id: element.ref,
    disabled: slot?.locked || context.readOnly,
    data: {
      accept: element.accept,
    },
  });

  // handle click to drop a selected card in this slot
  const handleClick = () => {
    if (selectedCardRef !== undefined) {
      context.sendAction({
        action: 'drop_card',
        cardRef: selectedCardRef,
        slotRef: element.ref,
      });
      setSelectedCardRef(undefined);
    }
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.Slot, {
        [styles.SlotActive]: isOver,
        [styles.SlotClickable]: selectedCardRef !== undefined,
      })}
      onClick={handleClick}
      data-ref={element.ref}
      data-scale-target="slot"
    >
      {showCorrect && <CorrectIcon correct={correct} />}
      {slotContent}
    </div>
  );
};
