// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/student/demographic/v1/demographic.proto" (package "sparx.school.student.demographic.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * DemographicData is the additional data we fetch about a user. All fields
 * are marked as optional to allow distinguishing between no access to field
 * and zero values.
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.DemographicData
 */
export interface DemographicData {
  /**
   * Is english not the user's first language.
   *
   * @generated from protobuf field: optional bool english_as_additional_language = 1;
   */
  englishAsAdditionalLanguage?: boolean;
  /**
   * Is the user eligible for the pupil premium.
   *
   * @generated from protobuf field: optional bool pupil_premium = 2;
   */
  pupilPremium?: boolean;
  /**
   * Is the user eligible for free school meals.
   *
   * @generated from protobuf field: optional bool free_school_meals = 3;
   */
  freeSchoolMeals?: boolean;
  /**
   * Does the user have special educational needs.
   *
   * Not currently written to. Please get a Data Protection review before
   * writing to.
   *
   * @generated from protobuf field: optional string sen_status = 4;
   */
  senStatus?: string;
}
/**
 * StudentDemographicData is the additional data we store against a student.
 * The nature of the data is very sensitive, so extra care must be taken when
 * using it.
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.StudentDemographicData
 */
export interface StudentDemographicData {
  /**
   * Demographic data is keyed by the student's resource name.
   * `schools/{school_id}/students/{student_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The time the data was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;
  /**
   * The time the data was requested to be deleted. When deleted, the data
   * should be set to null. Update methods will fail when delete_time is set.
   * Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp delete_time = 3;
   */
  deleteTime?: Timestamp;
  /**
   * The data we store about a student
   *
   * @generated from protobuf field: sparx.school.student.demographic.v1.DemographicData data = 4;
   */
  data?: DemographicData;
}
/**
 * Request message for GetStudentDemographicData
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.GetStudentDemographicDataRequest
 */
export interface GetStudentDemographicDataRequest {
  /**
   * The name of the student to request data for
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for UpdateStudentDemographicData
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.UpdateStudentDemographicDataRequest
 */
export interface UpdateStudentDemographicDataRequest {
  /**
   * The data to update
   *
   * @generated from protobuf field: sparx.school.student.demographic.v1.StudentDemographicData data = 1;
   */
  data?: StudentDemographicData;
  /**
   * Required. The fields to update. For more info see
   * https://developers.google.com/protocol-buffers/docs/reference/google.protobuf#fieldmask
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * Request message for ListStudentDemographicData
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.ListStudentDemographicDataRequest
 */
export interface ListStudentDemographicDataRequest {
  /**
   * The school to request student data for. School names have the form
   * `schools/{id}`. Fetching by the wildcard school (`schools/-`) is forbidden.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Typically the value of
   * [ListStudentDemographicDataResponse.next_page_token] from a previous
   * `ListStudentDemographicData` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * Response message for ListStudentDemographicData
 *
 * @generated from protobuf message sparx.school.student.demographic.v1.ListStudentDemographicDataResponse
 */
export interface ListStudentDemographicDataResponse {
  /**
   * The list of StudentDemographicData
   *
   * @generated from protobuf field: repeated sparx.school.student.demographic.v1.StudentDemographicData data = 1;
   */
  data: StudentDemographicData[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListStudentDemographicDataRequest.page_token` in a
   * subsequent call to `ListStudentDemographicData` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class DemographicData$Type extends MessageType<DemographicData> {
  constructor() {
    super('sparx.school.student.demographic.v1.DemographicData', [
      {
        no: 1,
        name: 'english_as_additional_language',
        kind: 'scalar',
        opt: true,
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'pupil_premium',
        kind: 'scalar',
        opt: true,
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'free_school_meals',
        kind: 'scalar',
        opt: true,
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'sen_status',
        kind: 'scalar',
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.DemographicData
 */
export const DemographicData = new DemographicData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentDemographicData$Type extends MessageType<StudentDemographicData> {
  constructor() {
    super(
      'sparx.school.student.demographic.v1.StudentDemographicData',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: {
            'sparx.api.bigquery.v1.column': {
              key: true,
              columnName: 'resource_name',
            },
          },
        },
        {
          no: 2,
          name: 'update_time',
          kind: 'message',
          T: () => Timestamp,
          options: {
            'sparx.api.bigquery.v1.column': { columnName: 'timestamp' },
          },
        },
        { no: 3, name: 'delete_time', kind: 'message', T: () => Timestamp },
        { no: 4, name: 'data', kind: 'message', T: () => DemographicData },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'student_demographic_data',
          partitionColumn: 'timestamp',
          additionalColumns: [
            {
              name: 'student_id',
              mode: 'KEY',
              doc: 'The student id as parsed from the name',
            },
          ],
        },
      }
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.StudentDemographicData
 */
export const StudentDemographicData = new StudentDemographicData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentDemographicDataRequest$Type extends MessageType<GetStudentDemographicDataRequest> {
  constructor() {
    super(
      'sparx.school.student.demographic.v1.GetStudentDemographicDataRequest',
      [{ no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.GetStudentDemographicDataRequest
 */
export const GetStudentDemographicDataRequest =
  new GetStudentDemographicDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentDemographicDataRequest$Type extends MessageType<UpdateStudentDemographicDataRequest> {
  constructor() {
    super(
      'sparx.school.student.demographic.v1.UpdateStudentDemographicDataRequest',
      [
        {
          no: 1,
          name: 'data',
          kind: 'message',
          T: () => StudentDemographicData,
        },
        { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.UpdateStudentDemographicDataRequest
 */
export const UpdateStudentDemographicDataRequest =
  new UpdateStudentDemographicDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentDemographicDataRequest$Type extends MessageType<ListStudentDemographicDataRequest> {
  constructor() {
    super(
      'sparx.school.student.demographic.v1.ListStudentDemographicDataRequest',
      [
        { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
        {
          no: 3,
          name: 'page_token',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.ListStudentDemographicDataRequest
 */
export const ListStudentDemographicDataRequest =
  new ListStudentDemographicDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentDemographicDataResponse$Type extends MessageType<ListStudentDemographicDataResponse> {
  constructor() {
    super(
      'sparx.school.student.demographic.v1.ListStudentDemographicDataResponse',
      [
        {
          no: 1,
          name: 'data',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentDemographicData,
        },
        {
          no: 2,
          name: 'next_page_token',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.student.demographic.v1.ListStudentDemographicDataResponse
 */
export const ListStudentDemographicDataResponse =
  new ListStudentDemographicDataResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.student.demographic.v1.StudentDemographicDataService
 */
export const StudentDemographicDataService = new ServiceType(
  'sparx.school.student.demographic.v1.StudentDemographicDataService',
  [
    {
      name: 'GetStudentDemographicData',
      options: {
        'google.api.http': { get: '/v1/{name=schools/*/students/*}' },
      },
      I: GetStudentDemographicDataRequest,
      O: StudentDemographicData,
    },
    {
      name: 'ListStudentDemographicData',
      options: {
        'google.api.http': { get: '/v1/{parent=schools/*}/students' },
      },
      I: ListStudentDemographicDataRequest,
      O: ListStudentDemographicDataResponse,
    },
    {
      name: 'UpdateStudentDemographicData',
      options: {
        'google.api.http': { patch: '/v1/{data.name=schools/*/students/*}' },
      },
      I: UpdateStudentDemographicDataRequest,
      O: StudentDemographicData,
    },
  ]
);
