// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/package.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { PackageService } from './package';
import type { CreateDemoPackageResponse } from './package';
import type { CreateDemoPackageRequest } from './package';
import type { ListPackageSummariesResponse } from './package';
import type { ListPackageSummariesRequest } from './package';
import type { GetPackageResponse } from './package';
import type { GetPackageRequest } from './package';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListPackagesResponse } from './package';
import type { ListPackagesRequest } from './package';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.PackageService
 */
export interface IPackageServiceClient {
  /**
   * @generated from protobuf rpc: ListPackages(sparx.science.packages.v1.ListPackagesRequest) returns (sparx.science.packages.v1.ListPackagesResponse);
   */
  listPackages(
    input: ListPackagesRequest,
    options?: RpcOptions
  ): UnaryCall<ListPackagesRequest, ListPackagesResponse>;
  /**
   * @generated from protobuf rpc: GetPackage(sparx.science.packages.v1.GetPackageRequest) returns (sparx.science.packages.v1.GetPackageResponse);
   */
  getPackage(
    input: GetPackageRequest,
    options?: RpcOptions
  ): UnaryCall<GetPackageRequest, GetPackageResponse>;
  /**
   * @generated from protobuf rpc: ListPackageSummaries(sparx.science.packages.v1.ListPackageSummariesRequest) returns (sparx.science.packages.v1.ListPackageSummariesResponse);
   */
  listPackageSummaries(
    input: ListPackageSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<ListPackageSummariesRequest, ListPackageSummariesResponse>;
  /**
   * @generated from protobuf rpc: CreateDemoPackage(sparx.science.packages.v1.CreateDemoPackageRequest) returns (sparx.science.packages.v1.CreateDemoPackageResponse);
   */
  createDemoPackage(
    input: CreateDemoPackageRequest,
    options?: RpcOptions
  ): UnaryCall<CreateDemoPackageRequest, CreateDemoPackageResponse>;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.PackageService
 */
export class PackageServiceClient
  implements IPackageServiceClient, ServiceInfo
{
  typeName = PackageService.typeName;
  methods = PackageService.methods;
  options = PackageService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListPackages(sparx.science.packages.v1.ListPackagesRequest) returns (sparx.science.packages.v1.ListPackagesResponse);
   */
  listPackages(
    input: ListPackagesRequest,
    options?: RpcOptions
  ): UnaryCall<ListPackagesRequest, ListPackagesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListPackagesRequest, ListPackagesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetPackage(sparx.science.packages.v1.GetPackageRequest) returns (sparx.science.packages.v1.GetPackageResponse);
   */
  getPackage(
    input: GetPackageRequest,
    options?: RpcOptions
  ): UnaryCall<GetPackageRequest, GetPackageResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetPackageRequest, GetPackageResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ListPackageSummaries(sparx.science.packages.v1.ListPackageSummariesRequest) returns (sparx.science.packages.v1.ListPackageSummariesResponse);
   */
  listPackageSummaries(
    input: ListPackageSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<ListPackageSummariesRequest, ListPackageSummariesResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListPackageSummariesRequest,
      ListPackageSummariesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: CreateDemoPackage(sparx.science.packages.v1.CreateDemoPackageRequest) returns (sparx.science.packages.v1.CreateDemoPackageResponse);
   */
  createDemoPackage(
    input: CreateDemoPackageRequest,
    options?: RpcOptions
  ): UnaryCall<CreateDemoPackageRequest, CreateDemoPackageResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateDemoPackageRequest, CreateDemoPackageResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
