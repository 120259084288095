import { Button, ButtonGroup } from '@chakra-ui/react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateLessonState } from 'api/lessons';
import React from 'react';
import { useLessonViewContext } from 'views/lessons/LessonView';

export const EphemeralActivityControl = () => {
  const { lessonState, lessonID, onSelectActivity, setPanel } = useLessonViewContext();
  const { mutate: update, isLoading: isUpdating } = useUpdateLessonState(lessonID);
  if (!lessonState.setEphemeralLessonActivityId) {
    return null;
  }

  const displayActivity = () => {
    const activity = lessonState.activities.find(
      a => a.lessonActivityId === lessonState.setEphemeralLessonActivityId,
    );
    if (activity) {
      onSelectActivity(activity?.lessonActivityId);
      if (activity.content?.assignment?.assignmentId) {
        setPanel({
          type: 'surveytaskitem',
          taskItemIndex: 1,
          taskIndex: 0,
          assignmentId: activity.content.assignment.assignmentId,
        });
      }
    }
  };

  return (
    <ButtonGroup isAttached={true} colorScheme="buttonTeal">
      <Button
        variant="solid"
        onClick={displayActivity}
        leftIcon={<FontAwesomeIcon icon={faList} />}
      >
        Survey question
      </Button>
      <Button
        variant="outline"
        isLoading={isUpdating}
        onClick={() => update({ setEphemeralLessonActivityId: '' })}
      >
        Clear
      </Button>
    </ButtonGroup>
  );
};
