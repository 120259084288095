import { Box, Spinner, Td, Text } from '@chakra-ui/react';
import { StudentState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import {
  Package,
  TaskItem,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Completion } from '@sparx/api/sparxweb/swmsg/progress';
import { useLessonAssignments } from 'api/lessons';
import { useAssignmentPackages } from 'api/planner';
import React, { memo, useMemo } from 'react';
import {
  ActivityTableWrapper,
  LessonActivityTableWrapperProps,
  SortableHeaderCell,
  useTableSort,
} from 'views/lessons/activities/ActivityTable';
import { useLessonViewContext } from 'views/lessons/LessonView';
import { StudentAndState } from 'views/lessons/StudentActivity';

import styles from './PackageActivity.module.css';

export const PackageTableWrapper = ({
  lessonID,
  activity,
  children,
}: LessonActivityTableWrapperProps) => {
  const { panel, setPanel } = useLessonViewContext();
  const { sortColumn, flipSort } = useTableSort();

  const assignmentId = activity?.content?.assignment?.assignmentId;
  const { data: packageData, isLoading: isLoadingPackages } = useAssignmentPackages(assignmentId, {
    suspense: false,
  });

  // Find the assignment for this package
  const { data: assignments = [], isFetching } = useLessonAssignments(lessonID, {
    suspense: false,
  });
  const { tasks: assignmentTasks, generated: generatedAssignment } = useMemo(() => {
    const assignment = assignments.find(a => a.name === `assignments/${assignmentId}`);
    if (assignment?.spec?.contents?.oneofKind === 'staticAssignment') {
      return { tasks: assignment.spec.contents.staticAssignment.tasks };
    } else if (assignment?.spec?.contents.oneofKind === 'generatedAssignment') {
      return { generated: true };
    }
    return {};
  }, [assignments, assignmentId]);

  const { studentPackages, maxTasks } = useMemo(() => {
    const studentPackages: Dictionary<string, Package> = {};
    let maxTasks = assignmentTasks?.length || 1;
    for (const pkg of packageData?.packages || []) {
      if (pkg.package) {
        studentPackages[pkg.studentId] = pkg.package;
        maxTasks = Math.max(maxTasks, pkg.package.contents?.tasks.length || 0);
      }
    }
    return { studentPackages, maxTasks };
  }, [assignmentTasks, generatedAssignment, packageData]);

  const taskKeys = Array.from(Array(maxTasks).keys());

  const getStudentRow = (state?: StudentState, student?: Student) => {
    const pkg = studentPackages[student?.studentId || state?.studentId || ''];
    if (generatedAssignment && !pkg) {
      return (
        <Td colSpan={maxTasks}>
          <Text fontStyle="italic" color="gray.400">
            Student has not started this activity.
          </Text>
        </Td>
      );
    }
    if (!pkg && (isFetching || isLoadingPackages)) {
      return (
        <Td colSpan={maxTasks}>
          <Spinner size="xs" color="blue.200" />
        </Td>
      );
    }

    return taskKeys.map(taskIndex => {
      const task = pkg?.contents?.tasks[taskIndex];
      if (!task && assignmentTasks && assignmentTasks.length > 0) {
        // Show the fake items
        const numberItems = assignmentTasks[taskIndex]?.items.length || 1;
        return (
          <Td key={taskIndex}>
            <Box display="flex">
              {Array(numberItems)
                .fill(0)
                .map((_, i) => (
                  <TaskItemBox key={i} />
                ))}
            </Box>
          </Td>
        );
      } else if (!task) {
        return <Td key={taskIndex} />; // ??
      }

      return (
        <Td key={taskIndex}>
          <Box display="flex">
            {task.contents?.taskItems.map((taskItem, index) => (
              <TaskItemBox
                key={taskItem.name || index}
                taskItem={taskItem}
                current={state?.position?.taskItemName === taskItem.name}
                taskItemFocus={
                  (panel?.type === 'taskitem' && taskItem.name === panel.taskItem) ||
                  ((panel?.type === 'assignmenttaskitem' || panel?.type === 'surveytaskitem') &&
                    panel.assignmentId === assignmentId &&
                    panel.taskItemIndex === index + 1 &&
                    panel.taskIndex === taskIndex)
                }
                onClick={() =>
                  taskItem.annotations['survey'] && assignmentId
                    ? setPanel({
                        type: 'surveytaskitem',
                        taskItemIndex: index + 1,
                        taskIndex: task?.taskIndex || 0,
                        assignmentId,
                      })
                    : setPanel({
                        type: 'taskitem',
                        taskItem: taskItem.name,
                        taskIndex: task?.taskIndex || 0,
                        studentId: state?.studentId || '',
                        assignmentId,
                      })
                }
              />
            ))}
          </Box>
        </Td>
      );
    });
  };

  const getHeading = () =>
    taskKeys.map((_, i) => {
      const columnId = `${assignmentId}/${i}`;
      return (
        <SortableHeaderCell key={i} sortName={columnId}>
          {assignmentTasks?.[i] ? `${i + 1}. ${assignmentTasks[i].title}` : `Task ${i + 1}`}
        </SortableHeaderCell>
      );
    });

  const sortFunc = (a: StudentAndState, b: StudentAndState) => {
    if (!sortColumn) return 0;

    const aPkg = studentPackages[a.studentId];
    const bPkg = studentPackages[b.studentId];

    // This code will select the task completion instead of the package completion:
    // ---
    // const taskIndex = parseInt(sortColumn.split('/')[1]);
    // const aComp = Completion.create(aPkg?.contents?.tasks[taskIndex]?.state?.completion || {});
    // const bComp = Completion.create(bPkg?.contents?.tasks[taskIndex]?.state?.completion || {});
    // ---

    // Use the package completion
    const aComp = Completion.create(aPkg?.state?.completion || {});
    const bComp = Completion.create(bPkg?.state?.completion || {});

    aComp.progress.NS = aComp.size - (aComp.progress.C || 0) - (aComp.progress.W || 0);
    bComp.progress.NS = bComp.size - (bComp.progress.C || 0) - (bComp.progress.W || 0);

    // order:
    // - green any - correct
    // - green # - correct first time
    // - blue - right, not yet marked
    // - red - incorrect
    // - white - not submitted
    //
    for (const key of ['C', 'CFT', 'PC', 'W', 'NS']) {
      const aVal = aComp.progress[key] || 0;
      const bVal = bComp.progress[key] || 0;
      if (aVal !== bVal) {
        return (aVal < bVal ? 1 : -1) * (flipSort ? -1 : 1);
      }
    }
    return 0;
  };

  return (
    <ActivityTableWrapper
      heading={getHeading}
      row={getStudentRow}
      sortFunc={sortColumn && sortColumn.startsWith(`${assignmentId}/`) ? sortFunc : undefined}
    >
      {children}
    </ActivityTableWrapper>
  );
};

interface TaskItemBoxProps {
  taskItem?: TaskItem;
  current?: boolean;
  taskItemFocus?: boolean;
  onClick?: () => void;
}

const taskItemClasses: Partial<Record<TaskItem_Status, string>> = {
  [TaskItem_Status.CORRECT]: styles.TaskItemCorrect,
  [TaskItem_Status.PENDING_CORRECT]: styles.TaskItemPendingCorrect,
  [TaskItem_Status.SKIPPED]: styles.TaskItemSkipped,
  [TaskItem_Status.INCORRECT]: styles.TaskItemIncorrect,
};

const TaskItemBox = memo(({ taskItem, current, taskItemFocus, onClick }: TaskItemBoxProps) => {
  const state = taskItem?.state;

  const style = useMemo(() => {
    let classes =
      taskItemClasses[state?.status || TaskItem_Status.TASK_ITEM_STATUS_UNSPECIFIED] ||
      styles.TaskItem;
    if (current) classes += ` ${styles.TaskItemActive}`;
    if (taskItemFocus) classes += ` ${styles.TaskItemFocus}`;

    if (state?.teacherSetStatus) {
      if (state?.status === TaskItem_Status.CORRECT) {
        classes += ` ${styles.TaskItemTeacherCorrect}`;
      } else if (state?.status === TaskItem_Status.INCORRECT) {
        classes += ` ${styles.TaskItemTeacherIncorrect}`;
      }
    }
    return classes;
  }, [current, taskItemFocus, state?.status, state?.teacherSetStatus]);

  // We want to display the number of wrong answers, but for older lessons before we
  // started to record the number of wrong answers show the number of activities instead.
  const wrongAnswers = state ? state.incorrectAnswers : 0;
  const attempts = state ? state.correctActivities + state.incorrectActivities : 0;
  const usedHelp = taskItem?.annotations?.['help'] === 'viewed';

  // Intentionally only is made of a simple component for speed.
  return (
    <div onClick={onClick} className={style}>
      {usedHelp && <span className={styles.VideoIcon} />}
      <span className={styles.TaskItemAttempts}>
        {wrongAnswers > 0 ? wrongAnswers : attempts > 1 ? attempts : ''}
      </span>
    </div>
  );
});
