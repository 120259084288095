import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LessonFeature,
  LessonState,
  StudentState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import {
  StudentStateWithHandupIndex,
  useEndLesson,
  useStudentLessonStates,
  useWatchLesson,
} from 'api/lessons';
import { useBackLink } from 'app/BackLink';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SimpleAlert } from 'components/simplealert/SimpleAlert';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ClearStudentSelectionButton } from 'views/lessons/components/ClearStudentSelectionButton';
import { PauseButton } from 'views/lessons/components/PauseControl';
import { EphemeralActivityControl } from 'views/lessons/EphemeralActivityControl';
import { ManageActivitiesDrawer } from 'views/lessons/ManageActivitiesDrawer';
import { LessonPanel } from 'views/lessons/panel/Panel';
import { StudentActivity } from 'views/lessons/StudentActivity';

interface LessonViewContext {
  lessonName: string;
  lessonID: string;
  selectedActivities: Set<string>;
  onSelectActivity: (id: string, setOnly?: boolean) => void;
  panel: LessonPanel;
  setPanel: (panel: LessonPanel) => void;
  teamTeaching: boolean;
  readonly: boolean;
  selectedStudents: Set<string>;
  toggleSelectedStudent: (studentID: string | string[], set?: boolean, clear?: boolean) => void;
  clearSelectedStudents: () => void;
  lessonState: LessonState;
  studentStates: StudentStateWithHandupIndex[];
  onClickStudent?: (studentID: string) => void;
}

const LessonViewContext = React.createContext<LessonViewContext>({} as LessonViewContext);

export const useLessonViewContext = () => useContext(LessonViewContext);

export const LessonView = () => (
  <SuspenseRoute>
    <SuspenseLessonView />
  </SuspenseRoute>
);

const SuspenseLessonView = () => {
  const { lessonID = '' } = useParams();

  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(lessonID);
  const { data: studentStates = [], isLoading: isLoadingStudents } =
    useStudentLessonStates(lessonID);

  const { state } = useLocation();
  const groupQuery = state?.group ? `?group=${state?.group}` : '';
  useBackLink(`/teacher/lessons${groupQuery}`);

  if (isLoadingLesson || isLoadingStudents) {
    return <LargeLoading />;
  }
  if (!lessonState) {
    return <NotFound />;
  }
  return <Lesson lessonID={lessonID} lessonState={lessonState} studentStates={studentStates} />;
};

interface LessonProps {
  lessonID: string;
  lessonState: LessonState;
  studentStates: StudentState[];
  titleChildren?: React.ReactNode;
  studentSelectionComponent?: React.ReactNode;
  onClickStudent?: (studentID: string) => void;
}

export const Lesson = ({
  lessonID,
  lessonState,
  studentStates,
  titleChildren,
  studentSelectionComponent,
  children,
  onClickStudent,
}: PropsWithChildren<LessonProps>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedActivities = useMemo(
    () => new Set(searchParams.getAll('activity') || []),
    [searchParams],
  );

  const teamTeaching = lessonState.features.includes(LessonFeature.TEAM_TEACHING);

  const [selectedStudents, setSelectedStudents] = useState(new Set<string>());
  const toggleSelectedStudent = (studentID: string | string[], set?: boolean, clear?: boolean) => {
    const selected = clear ? new Set<string>() : new Set(selectedStudents);
    const students = Array.isArray(studentID) ? studentID : [studentID];
    for (const student of students) {
      if ((!selected.has(student) && set !== false) || set) {
        selected.add(student);
      } else {
        selected.delete(student);
      }
    }
    setSelectedStudents(selected);
  };
  const clearSelectedStudents = () => setSelectedStudents(new Set<string>());

  const setSelectedActivities = (activity: string[]) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('activity');
    activity.forEach(a => newParams.append('activity', a));
    setSearchParams(newParams);
  };

  const onSelectActivity = (id: string, setOnly?: boolean) => {
    if (setOnly) {
      setSelectedActivities([id]);
      return;
    }

    const selected = new Set(selectedActivities);
    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }
    setSelectedActivities([...selected.keys()]);
  };

  const [panel, setPanel] = useState<LessonPanel>();

  const readonly = Boolean(lessonState?.endedTimestamp);

  return (
    <LessonViewContext.Provider
      value={{
        lessonName: lessonState.lessonName || '',
        lessonID,
        panel,
        setPanel,
        selectedActivities,
        onSelectActivity,
        teamTeaching,
        readonly,
        selectedStudents,
        toggleSelectedStudent,
        clearSelectedStudents,
        lessonState,
        studentStates,
        onClickStudent,
      }}
    >
      <ProductMismatchWarning teamTeaching={teamTeaching} />
      {children}
      <Box height="100%" width="100%" display="flex" position="absolute" overflowY="auto">
        <Box flex={1} display="flex" flexDirection="column">
          <Box pt={4} px={5}>
            <PageTitle
              pageTitleOverride={lessonState?.displayName || 'Untitled lesson'}
              title={
                <HStack spacing={4}>
                  {titleChildren}
                  {!readonly && <LessonCode code={lessonState?.lessonCode || ''} />}
                  <Text>
                    {lessonState?.displayName || 'Untitled lesson'}
                    {readonly && (
                      <Text as="span" color="gray.400" ml={4}>
                        (ended)
                      </Text>
                    )}
                  </Text>
                </HStack>
              }
            >
              {!readonly && lessonState ? (
                <HStack spacing={2}>
                  <EphemeralActivityControl />
                  <ClearStudentSelectionButton />
                  {studentSelectionComponent}
                  <PauseButton lessonState={lessonState} selectedStudents={selectedStudents} />
                </HStack>
              ) : (
                <Text color="gray.500" fontStyle="italic">
                  Lesson ended <PrettyTimestamp>{lessonState?.endedTimestamp}</PrettyTimestamp>
                </Text>
              )}
            </PageTitle>
          </Box>
          <Box flex={1} position="relative" overflowX="hidden">
            <StudentActivity
              lessonID={lessonID}
              studentGroupNames={lessonState?.groupNames || []}
            />
          </Box>
        </Box>
        <Box
          w="300px"
          boxShadow="elevationMedium"
          backgroundColor="white"
          flexShrink={0}
          display="flex"
          flexDirection="column"
          zIndex={3}
        >
          <Box mb={4} flex={1} overflowY="auto">
            {lessonState && <ManageActivitiesDrawer onSelectActivity={onSelectActivity} />}
          </Box>
          {!readonly && lessonState && <EndLessonButton lessonName={lessonState.lessonName} />}
        </Box>
      </Box>
    </LessonViewContext.Provider>
  );
};

const ProductMismatchWarning = ({ teamTeaching }: { teamTeaching?: boolean }) => {
  const isSparxTeaching = import.meta.env.VITE_SPARX_PRODUCT === 'SPARX_TEACHING';
  const productMismatch = teamTeaching !== isSparxTeaching;

  let productSwitch;
  if (teamTeaching && productMismatch) {
    const link = window.location.href.replace('.sparxscience.com', '.sparxteaching.com');
    productSwitch = ['Sparx Teaching', link];
  } else if (!teamTeaching && productMismatch) {
    const link = window.location.href.replace('.sparxteaching.com', '.sparxscience.com');
    productSwitch = ['Sparx Science', link];
  }

  if (productSwitch) {
    return (
      <Box bg="yellow.100" color="yellow.900" px={5} py={3}>
        <Text>
          This lesson is a <strong>{productSwitch[0]}</strong> lesson. For full functionality please{' '}
          <Button
            as="a"
            href={productSwitch[1]}
            variant="link"
            colorScheme="blue"
            rightIcon={<FontAwesomeIcon icon={faExternalLink} />}
          >
            go to {productSwitch[0]}
          </Button>
        </Text>
      </Box>
    );
  }
  return null;
};

const EndLessonButton = ({ lessonName }: { lessonName: string }) => {
  const navigate = useNavigate();
  const { mutateAsync: endLesson } = useEndLesson();

  return (
    <Box px={5} pb={5} display="flex" flexDirection="column">
      <SimpleAlert
        header="End lesson"
        body="Are you sure you want to end this lesson?"
        onConfirm={async () => await endLesson(lessonName).then(() => navigate('/teacher/lessons'))}
      >
        {onOpen => (
          <Button colorScheme="buttonTeal" variant="outline" onClick={onOpen}>
            End lesson
          </Button>
        )}
      </SimpleAlert>
    </Box>
  );
};

export const LessonCode = ({ code, inactive }: { code: string; inactive?: boolean }) => (
  <Box
    bg={inactive ? 'gray.100' : 'blue.100'}
    color={inactive ? 'gray.700' : 'blue.700'}
    borderRadius="md"
    py={1}
    display="inline"
    fontSize="lg"
    letterSpacing="0.5px"
    width={16}
    textAlign="center"
    fontWeight="bold"
  >
    {code}
  </Box>
);
