import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { LiveKitRoom } from '@livekit/components-react';
import { LessonFeature, LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Role } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import {
  StudentCallLayout,
  StudentCallTrack,
} from '@sparx/api/apis/sparx/science/lessons/v1/teamteaching';
import { getSchoolID, useSession } from '@sparx/science/api/sessions';
import { useMutation, useQuery } from '@tanstack/react-query';
import { meetClient } from 'api/service';
import {
  AudioTrackForParticipant,
  LocalTrackByName,
  MultiTrackSelect,
  TrackByName,
} from 'components/stream/StreamComponents';
import { LayoutBox } from 'components/stream/StreamLayouts';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

interface TeamTeachingStudentControlsProps {
  lessonState: LessonState | undefined;
}

export const StudentView = ({ lessonState }: TeamTeachingStudentControlsProps) => {
  const { data: user } = useSession();
  const [ready, setReady] = useState(false);

  if (!lessonState || !lessonState?.features.includes(LessonFeature.TEAM_TEACHING)) {
    return null; // nothing
  }

  const focusStudentId = lessonState?.conferenceState?.studentCall?.studentName?.split('/')[1];
  const studentIsActive = focusStudentId === user?.userId;
  const studentShouldSnoop =
    lessonState?.conferenceState?.studentAudioState?.studentNames?.includes(
      'students/' + user?.userId,
    );

  return (
    <>
      <AnimatePresence>
        {studentIsActive && ready && <OneToOneCall lessonState={lessonState} />}
        {studentShouldSnoop && ready && <Snoop lessonState={lessonState} />}
      </AnimatePresence>
      <MicrophoneModal onDone={() => setReady(true)} />
    </>
  );
};

const Snoop = ({ lessonState }: { lessonState: LessonState }) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { data: { livekitToken: token } = {} } = useQuery({
    queryKey: ['snoop'],
    queryFn: async () =>
      meetClient.getRoomToken({
        schoolName: `schools/${await getSchoolID()}`,
        collection: lessonID,
        role: Role.STUDENT_AUDIO,
      }).response,
  });

  if (!token) {
    return <></>;
  }

  return (
    <LiveKitRoom audio={true} token={token.token} serverUrl={token.serverUrl} connect={true} />
  );
};

const OneToOneCall = ({ lessonState }: { lessonState: LessonState }) => {
  const livekitToken = lessonState?.conferenceState?.studentCall?.livekitToken;
  const layout = lessonState.conferenceState?.studentCall?.layout;

  const teacherTrackName =
    lessonState?.conferenceState?.studentCall?.visibleTrack === StudentCallTrack.VISUALISER
      ? 'visualiser'
      : 'webcam';

  if (!livekitToken) {
    console.error('No session ID for audio streamer');
    return <></>;
  }

  return (
    <LiveKitRoom
      video={false}
      audio={true}
      token={livekitToken.token}
      serverUrl={livekitToken.serverUrl}
      connect={true}
    >
      <AudioTrackForParticipant identity="teacher" />
      <Box
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        position={layout === StudentCallLayout.SIDEBAR ? 'relative' : 'fixed'}
        height={layout === StudentCallLayout.SIDEBAR ? '40vh' : undefined}
        inset={layout === StudentCallLayout.FULLSCREEN ? '0 0 0 0' : undefined}
        zIndex={100}
        bg="blackAlpha.800"
      >
        <LayoutBox layout={{ height: 100, width: 100, y: 0, x: 0, zIndex: 2 }}>
          <TrackByName name={teacherTrackName} identity="teacher" />
        </LayoutBox>
        <LayoutBox layout={{ height: 25, width: 25, y: 75, x: 75, zIndex: 4 }}>
          <LocalTrackByName name="webcam" />
          <VStack position="absolute" alignItems="flex-end" right="100%" bottom={2} mr={2}>
            <MultiTrackSelect name="webcam" preferredVideo="front camera" />
          </VStack>
        </LayoutBox>
      </Box>
    </LiveKitRoom>
  );
};

const MicrophoneModal = ({ onDone }: { onDone: () => void }) => {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: onDone,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      onClose();
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      isCentered={true}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>Please give permission</ModalHeader>
        <ModalBody>
          <Text>
            Your browser will ask you for permission to use your microphone and camera. Please
            accept this before continuing.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => mutate()} isLoading={isLoading} colorScheme="buttonTeal">
            Give access
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StudentView;
