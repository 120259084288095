import { LayoutElementProps } from '../question/SparxQuestionContext';
import { IElement } from '../question/types';
import { AssetUploadElement } from './AssetUploadElement';
import { ChoiceElement } from './ChoiceElement';
import { ExpressionElement } from './ExpressionElement';
import { GroupElement } from './GroupElement';
import { FigureElement, ImageElement } from './ImageElement';
import { MediaInputElement } from './MediaInputElement';
import { NumberFieldElement } from './NumberFieldElement';
import { PartGroupElement } from './PartGroupElement';
import { SlotOrInlineSlotElement } from './SlotOrInlineSlotElement';
import { TemplatedContentElement } from './TemplatedContent';
import { TextElement } from './TextElement';
import { TextFieldElement } from './TextFieldElement';

export const LayoutElement = ({ element, parent, answerPartIndex }: LayoutElementProps) => {
  switch (element.element) {
    case 'group':
      return <GroupElement element={element} answerPartIndex={answerPartIndex} />;
    case 'part-group':
      return <PartGroupElement element={element} />;
    case 'text':
      return <TextElement element={element} parent={parent} />;
    case 'figure-ref':
      return <FigureElement element={element} />;
    case 'image':
      return <ImageElement element={element} />;
    case 'number-field':
      return <NumberFieldElement element={element} />;
    case 'text-field':
      return <TextFieldElement element={element} />;
    case 'expression-field':
      return <ExpressionElement element={element} />;
    case 'choice':
      return <ChoiceElement element={element} />;
    case 'slot':
      return <SlotOrInlineSlotElement element={element} />;
    case 'asset-upload':
      return <AssetUploadElement element={element} />;
    case 'media-input':
      return <MediaInputElement element={element} />;
    case 'objectives':
      return null; // don't render objectives
    case 'templated-content':
      return <TemplatedContentElement element={element} />;
  }

  return (
    <span style={{ background: 'red', padding: 5, margin: 2 }}>
      unknown layout element: {element.element}
    </span>
  );
};

export const LayoutElements = ({
  element,
  parent,
}: LayoutElementProps<{ content: IElement[] }>) => (
  <>
    {element.content?.map((e, i) => (
      <LayoutElement element={e} key={i} parent={parent} answerPartIndex={i} />
    ))}
  </>
);
