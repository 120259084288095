import { Button } from '@chakra-ui/react';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import { faHand as faHandSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  JoinLessonResponse,
  StudentActiveState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import { lessonsClient } from '@sparx/science/api';
import { queryClient } from '@sparx/science/api/client';
import {
  LessonDeliveryPage,
  usePositionFromPage,
} from '@sparx/science/views/lessondelivery/lessonStatePusher';
import { useQuery } from '@tanstack/react-query';
import lifecycle, { StateChangeEvent } from 'page-lifecycle';
import React, { useEffect, useState } from 'react';

export const useLessonStatePusher = (
  currentLesson: JoinLessonResponse | null | undefined,
  page: LessonDeliveryPage,
  lastUploadedWhiteboardFilename: string,
) => {
  const [activeState, setActiveState] = useState<StudentActiveState>(StudentActiveState.ACTIVE);
  const debouncedActiveState = useDebounce(activeState, 250);
  const position = usePositionFromPage(page);

  const { data: handUpTime = false } = useQuery({
    queryKey: ['handsup'],
    queryFn: (): Date | null => null,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  const toggleHandsUp = () => queryClient.setQueryData(['handsup'], handsUp ? null : new Date());

  const state = {
    lessonName: currentLesson?.lessonName || '',
    position,
    activeState: debouncedActiveState,
    handsUp: Boolean(handUpTime),
    handUpTime: handUpTime ? Timestamp.fromDate(handUpTime) : undefined,
    lastUploadedWhiteboardFilename,
  };

  useQuery({
    queryKey: ['lesson', state, 'state'],
    queryFn: async () => lessonsClient.updateStudentLessonState(state),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    enabled: Boolean(currentLesson?.lessonName && currentLesson?.sessionId),
    refetchInterval: 15000,
    cacheTime: 0,
  });

  useEffect(() => {
    const handler = (e: StateChangeEvent) => {
      if (e.newState !== e.oldState) {
        setActiveState(
          {
            active: StudentActiveState.ACTIVE,
            passive: StudentActiveState.PASSIVE,
            hidden: StudentActiveState.HIDDEN,
          }[e.newState],
        );
      }
    };
    lifecycle.addEventListener('statechange', handler);
    return () => lifecycle.removeEventListener('statechange', handler);
  }, [setActiveState]);

  const handsUp = Boolean(handUpTime);
  return (
    <Button
      mr={3}
      variant={handsUp ? 'solid' : 'outline'}
      leftIcon={
        handsUp ? (
          <FontAwesomeIcon icon={faHandSolid} bounce={true} />
        ) : (
          <FontAwesomeIcon icon={faHand} />
        )
      }
      colorScheme={handsUp ? 'orange' : 'whiteAlpha'}
      onClick={toggleHandsUp}
    >
      {handsUp ? 'Hand is up' : 'Raise hand'}
    </Button>
  );
};
