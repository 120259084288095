import { RpcInterceptor } from '@protobuf-ts/runtime-rpc';

/**
 * Extension interceptor is a GRPC interceptor that enables GRPC Web requests to be displayed in the Chrome GRPC Web
 * extension.
 */
export const extensionInterceptor: RpcInterceptor = {
  interceptUnary(next, method, input, options) {
    const call = next(method, input, options);
    const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
    const methodType = 'unary';

    call.then(
      finishedUnaryCall => {
        window.postMessage(
          {
            type: '__GRPCWEB_DEVTOOLS__',
            method: methodFullPath,
            methodType,
            request: finishedUnaryCall.request,
            response: finishedUnaryCall.response,
          },
          '*',
        );

        return finishedUnaryCall;
      },
      error => {
        window.postMessage(
          {
            type: '__GRPCWEB_DEVTOOLS__',
            method: methodFullPath,
            methodType,
            request: call.request,
            error: {
              ...error,
              message: error.message,
            },
          },
          '*',
        );
      },
    );

    return call;
  },
};
