import { Box, Flex, HTMLChakraProps, Td, Text, Th } from '@chakra-ui/react';
import {
  faBackward,
  faChevronDown,
  faChevronUp,
  faCircle,
  faForward,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LessonActivity, StudentState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import React, { PropsWithChildren, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PackageTableWrapper } from 'views/lessons/activities/PackageActivity';
import { useLessonViewContext } from 'views/lessons/LessonView';
import { isStudentActive, StudentAndState } from 'views/lessons/StudentActivity';

interface ActivityTableContext {
  heading: () => React.ReactNode;
  row: (state?: StudentState, student?: Student) => React.ReactNode;
  sortFunc?: (a: StudentAndState, b: StudentAndState) => number;
}

const ActivityTableContext = React.createContext<ActivityTableContext>({
  heading: () => <></>,
  row: () => <></>,
});

export const useActivityTableContext = () => useContext(ActivityTableContext);

interface ActivityTableWrapperProps {
  heading?: () => React.ReactNode;
  row?: (state?: StudentState, student?: Student) => React.ReactNode;
  sortFunc?: (a: StudentAndState, b: StudentAndState) => number;
}

export const ActivityTableWrapper = ({
  children,
  heading,
  row,
  sortFunc,
}: PropsWithChildren<ActivityTableWrapperProps>) => {
  const parent = useContext(ActivityTableContext);
  return (
    <ActivityTableContext.Provider
      value={{
        heading: () => (
          <>
            {heading?.()}
            {parent.heading()}
          </>
        ),
        row: (state, student) => (
          <>
            {row?.(state, student)}
            {parent.row(state, student)}
          </>
        ),
        sortFunc: sortFunc || parent.sortFunc,
      }}
    >
      {children}
    </ActivityTableContext.Provider>
  );
};

export interface LessonActivityTableWrapperProps {
  lessonID: string;
  activity?: LessonActivity;
  children: React.ReactNode;
}

export const LessonActivityTableWrapper = ({
  lessonID,
  activity,
  children,
}: LessonActivityTableWrapperProps) => {
  if (activity?.content?.assignment) {
    return (
      <PackageTableWrapper activity={activity} lessonID={lessonID} key={activity.lessonActivityId}>
        {children}
      </PackageTableWrapper>
    );
  }
  return <>{children}</>;
};

interface LessonActivityPositionWrapperProps {
  ids: string[];
  typ: StudentRelativePosition;
  children: React.ReactNode;
}

type StudentRelativePosition = 'before' | 'current' | 'after';

export const LessonActivityPositionWrapper = ({
  ids,
  typ,
  children,
}: LessonActivityPositionWrapperProps) => {
  const { onSelectActivity } = useLessonViewContext();

  const getPosition = (activityId?: string): StudentRelativePosition | undefined => {
    if (!activityId) return undefined;
    if (ids.indexOf(activityId) !== -1) return typ;
    return undefined;
  };

  const border = {
    bg: 'gray.50',
    borderLeftColor: 'gray.100',
    borderRightColor: 'gray.100',
    borderLeftWidth: 1,
    borderRightWidth: 1,
  };

  return (
    <ActivityTableWrapper
      heading={() => <Th px={2} w={6} {...border} />}
      row={(state?: StudentState) => {
        const position = getPosition(state?.position?.lessonActivityId);
        const { active } = isStudentActive(state);
        return (
          <Td px={2} {...border}>
            <Direction
              active={active}
              position={position}
              onClick={
                position
                  ? () =>
                      state?.position?.lessonActivityId &&
                      onSelectActivity(state.position.lessonActivityId)
                  : undefined
              }
            />
          </Td>
        );
      }}
    >
      {children}
    </ActivityTableWrapper>
  );
};

const Direction = ({
  active,
  position,
  onClick,
}: {
  active?: boolean;
  position?: StudentRelativePosition;
  onClick?: () => void;
}) => (
  <Box
    bg="gray.200"
    w={6}
    h={6}
    borderRadius="md"
    color="gray.500"
    display="flex"
    alignItems="center"
    justifyContent="center"
    opacity={position ? 1 : 0}
    transition="opacity 0.25s ease-out"
    _hover={
      onClick && position
        ? {
            cursor: 'pointer',
            bg: 'gray.300',
          }
        : undefined
    }
    onClick={onClick}
    outline={active ? '4px solid var(--chakra-colors-blue-300)' : undefined}
  >
    <FontAwesomeIcon
      icon={position === 'before' ? faBackward : position === 'after' ? faForward : faCircle}
    />
  </Box>
);

export const SortableHeaderCell = ({
  children,
  sortName,
  ...thProps
}: PropsWithChildren<{ sortName: string }> & HTMLChakraProps<'th'>) => {
  const { sortColumn, flipSort, setSortColumn } = useTableSort();
  const selected = sortColumn === sortName;

  return (
    <Th
      position="relative"
      bg={selected ? 'blue.100' : 'white'}
      cursor="pointer"
      onClick={() => setSortColumn(sortName)}
      _hover={{ bg: selected ? 'blue.100' : 'blue.50' }}
      {...thProps}
    >
      <Flex
        position="absolute"
        left={0}
        right={selected ? 4 : 0}
        top={1}
        bottom={1}
        pr={4}
        pl={4}
        alignItems="center"
      >
        <Text width="100%" overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {children}
        </Text>
      </Flex>
      {selected && (
        <Flex position="absolute" right={3} top={1} bottom={1} alignItems="center">
          <FontAwesomeIcon icon={flipSort ? faChevronUp : faChevronDown} />
        </Flex>
      )}
    </Th>
  );
};

export const useTableSort = () => {
  const [query, setQuery] = useSearchParams();
  const sortColumn = query.get('sort');
  const flipSort = Boolean(query.get('flip'));
  const setSortColumn = (newColumn: string) => {
    setQuery(v => {
      if (sortColumn !== newColumn) {
        v.set('sort', newColumn);
        v.set('flip', '');
      } else if (flipSort) {
        v.set('sort', '');
        v.set('flip', '');
      } else {
        v.set('flip', 'true');
      }
      return v;
    });
  };
  return { sortColumn, flipSort, setSortColumn };
};
