import { Text, TextProps, Tooltip } from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface InfoTooltipProps extends TextProps {
  text: React.ReactNode;
}

export const InfoTooltip = ({ text, ...props }: InfoTooltipProps) => (
  <Tooltip
    label={text}
    hasArrow={true}
    placement="top"
    fontSize="md"
    p={2}
    textAlign="center"
    lineHeight="1.3em"
    maxWidth="500px"
  >
    <Text as="span" color="blue.300" display="inline-block" ml={2} cursor="help" {...props}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </Text>
  </Tooltip>
);
