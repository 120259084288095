import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Stack } from '../components/Stack';
import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IElement } from '../question/types';
import { debounceCallback } from '../utils/debounce';
import { LayoutElements } from './LayoutElement';

export const LeftImageWrapper = ({ content }: { content: IElement[] }) => {
  const { questionElement } = useSparxQuestionContext();
  const [width, setWidth] = useState(questionElement?.offsetWidth || 0);

  useEffect(() => {
    questionElement && setWidth(questionElement.offsetWidth);
    const aborter = new AbortController();
    window.addEventListener(
      'resize',
      debounceCallback(() => {
        if (questionElement) setWidth(questionElement.offsetWidth);
      }, 200),
      {
        signal: aborter.signal,
      },
    );
    return () => aborter.abort();
  }, [questionElement, setWidth]);

  return (
    <div className={classNames(styles.LeftImage, { [styles.Active]: width >= 900 })}>
      <LayoutElements element={{ content: [content[0]] }} />
      <Stack dir="vertical">
        <LayoutElements element={{ content: content.slice(1) }} />
      </Stack>
    </div>
  );
};
