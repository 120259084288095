// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/calendar/v3/calendar.proto" (package "sparx.school.calendar.v3", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * This is the school period resource
 *
 * @generated from protobuf message sparx.school.calendar.v3.SchoolPeriod
 */
export interface SchoolPeriod {
  /**
   * Name of the school period, of the form
   * "schools/{school_id}/periods/{period_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * School year (e.g. 2017 for the 2017-18 academic year)
   *
   * @generated from protobuf field: int32 school_year = 2;
   */
  schoolYear: number;
  /**
   * User readable name
   *
   * @generated from protobuf field: string display_name = 3;
   */
  displayName: string;
  /**
   * Type of period
   *
   * @generated from protobuf field: sparx.school.calendar.v3.SchoolPeriodType period_type = 4;
   */
  periodType: SchoolPeriodType;
  /**
   * First day of the period
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 5;
   */
  startDate?: Timestamp;
  /**
   * Last day of the period
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 6;
   */
  endDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.ListSchoolPeriodsRequest
 */
export interface ListSchoolPeriodsRequest {
  /**
   * Name of the parent resource of the form "schools/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * If not 0, school year to filter by
   *
   * @generated from protobuf field: int32 school_year = 2;
   */
  schoolYear: number;
  /**
   * If present, only include periods starting on or before this date
   *
   * @generated from protobuf field: google.protobuf.Timestamp starts_before_date = 3;
   */
  startsBeforeDate?: Timestamp;
  /**
   * If present, only include periods ending on or after this date
   *
   * @generated from protobuf field: google.protobuf.Timestamp ends_after_date = 4;
   */
  endsAfterDate?: Timestamp;
  /**
   * If present, only include periods starting on of after this date
   *
   * @generated from protobuf field: google.protobuf.Timestamp starts_after_date = 5;
   */
  startsAfterDate?: Timestamp;
  /**
   * If present, only include periods ending on of before this date
   *
   * @generated from protobuf field: google.protobuf.Timestamp ends_before_date = 6;
   */
  endsBeforeDate?: Timestamp;
  /**
   * If not UNKNOWN_PERIOD_TYPE, only include periods of this type
   *
   * @generated from protobuf field: sparx.school.calendar.v3.SchoolPeriodType period_type = 7;
   */
  periodType: SchoolPeriodType;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.CreateSchoolPeriodRequest
 */
export interface CreateSchoolPeriodRequest {
  /**
   * Name of the parent resource of the form "schools/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Payload of the school period (name should be left empty)
   *
   * @generated from protobuf field: sparx.school.calendar.v3.SchoolPeriod period = 2;
   */
  period?: SchoolPeriod;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.ListSchoolPeriodsResponse
 */
export interface ListSchoolPeriodsResponse {
  /**
   * List of school periods matching the request (ordered by start_date)
   *
   * @generated from protobuf field: repeated sparx.school.calendar.v3.SchoolPeriod periods = 1;
   */
  periods: SchoolPeriod[];
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.GetSchoolPeriodRequest
 */
export interface GetSchoolPeriodRequest {
  /**
   * Name of the school period requested, of the form
   * "schools/{school_id}/periods/{period_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.UpdateSchoolPeriodRequest
 */
export interface UpdateSchoolPeriodRequest {
  /**
   * @generated from protobuf field: sparx.school.calendar.v3.SchoolPeriod period = 1;
   */
  period?: SchoolPeriod;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.DeleteSchoolPeriodRequest
 */
export interface DeleteSchoolPeriodRequest {
  /**
   * Name of the school period to delete,
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request for the GetSchoolYear method.
 *
 * @generated from protobuf message sparx.school.calendar.v3.GetSchoolYearRequest
 */
export interface GetSchoolYearRequest {
  /**
   * Name of the resource of the form "schools/{school_id}/years/{year_index}"
   * where year_index is an incrementing integer ID.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.school.calendar.v3.SchoolYearWeek
 */
export interface SchoolYearWeek {
  /**
   * The index of the week relative to the start of the year.
   *
   * @generated from protobuf field: int32 index = 1;
   */
  index: number;
  /**
   * Timestamp for the Monday of the week.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
}
/**
 * Response for the GetSchoolYear method.
 *
 * @generated from protobuf message sparx.school.calendar.v3.SchoolYear
 */
export interface SchoolYear {
  /**
   * Name of the resource of the form "schools/{school_id}/years/{year_index}"
   * where year_index is an incrementing integer ID.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The start date of the year.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * The end date of the year.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
  /**
   * Data about each week between the start_date and end_date.
   *
   * @generated from protobuf field: repeated sparx.school.calendar.v3.SchoolYearWeek weeks = 4;
   */
  weeks: SchoolYearWeek[];
}
/**
 * Request for the ListSchoolYears method.
 *
 * @generated from protobuf message sparx.school.calendar.v3.ListSchoolYearsRequest
 */
export interface ListSchoolYearsRequest {
  /**
   * Name of the parent resource of the form "schools/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
}
/**
 * Response for the ListSchoolYears method.
 *
 * @generated from protobuf message sparx.school.calendar.v3.ListSchoolYearsResponse
 */
export interface ListSchoolYearsResponse {
  /**
   * All the school years for the requested school.
   *
   * @generated from protobuf field: repeated sparx.school.calendar.v3.SchoolYear school_years = 1;
   */
  schoolYears: SchoolYear[];
}
/**
 * Possible types for school periods
 *
 * @generated from protobuf enum sparx.school.calendar.v3.SchoolPeriodType
 */
export enum SchoolPeriodType {
  /**
   * @generated from protobuf enum value: UNKNOWN_PERIOD_TYPE = 0;
   */
  UNKNOWN_PERIOD_TYPE = 0,
  /**
   * @generated from protobuf enum value: TERM = 1;
   */
  TERM = 1,
  /**
   * @generated from protobuf enum value: HOLIDAY = 2;
   */
  HOLIDAY = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolPeriod$Type extends MessageType<SchoolPeriod> {
  constructor() {
    super('sparx.school.calendar.v3.SchoolPeriod', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'period_type',
        kind: 'enum',
        T: () => [
          'sparx.school.calendar.v3.SchoolPeriodType',
          SchoolPeriodType,
        ],
      },
      { no: 5, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'end_date', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.SchoolPeriod
 */
export const SchoolPeriod = new SchoolPeriod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolPeriodsRequest$Type extends MessageType<ListSchoolPeriodsRequest> {
  constructor() {
    super('sparx.school.calendar.v3.ListSchoolPeriodsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'starts_before_date',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 4, name: 'ends_after_date', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'starts_after_date', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'ends_before_date', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'period_type',
        kind: 'enum',
        T: () => [
          'sparx.school.calendar.v3.SchoolPeriodType',
          SchoolPeriodType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.ListSchoolPeriodsRequest
 */
export const ListSchoolPeriodsRequest = new ListSchoolPeriodsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchoolPeriodRequest$Type extends MessageType<CreateSchoolPeriodRequest> {
  constructor() {
    super('sparx.school.calendar.v3.CreateSchoolPeriodRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'period', kind: 'message', T: () => SchoolPeriod },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.CreateSchoolPeriodRequest
 */
export const CreateSchoolPeriodRequest = new CreateSchoolPeriodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolPeriodsResponse$Type extends MessageType<ListSchoolPeriodsResponse> {
  constructor() {
    super('sparx.school.calendar.v3.ListSchoolPeriodsResponse', [
      {
        no: 1,
        name: 'periods',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolPeriod,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.ListSchoolPeriodsResponse
 */
export const ListSchoolPeriodsResponse = new ListSchoolPeriodsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolPeriodRequest$Type extends MessageType<GetSchoolPeriodRequest> {
  constructor() {
    super('sparx.school.calendar.v3.GetSchoolPeriodRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.GetSchoolPeriodRequest
 */
export const GetSchoolPeriodRequest = new GetSchoolPeriodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolPeriodRequest$Type extends MessageType<UpdateSchoolPeriodRequest> {
  constructor() {
    super('sparx.school.calendar.v3.UpdateSchoolPeriodRequest', [
      { no: 1, name: 'period', kind: 'message', T: () => SchoolPeriod },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.UpdateSchoolPeriodRequest
 */
export const UpdateSchoolPeriodRequest = new UpdateSchoolPeriodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchoolPeriodRequest$Type extends MessageType<DeleteSchoolPeriodRequest> {
  constructor() {
    super('sparx.school.calendar.v3.DeleteSchoolPeriodRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.DeleteSchoolPeriodRequest
 */
export const DeleteSchoolPeriodRequest = new DeleteSchoolPeriodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearRequest$Type extends MessageType<GetSchoolYearRequest> {
  constructor() {
    super('sparx.school.calendar.v3.GetSchoolYearRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.GetSchoolYearRequest
 */
export const GetSchoolYearRequest = new GetSchoolYearRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYearWeek$Type extends MessageType<SchoolYearWeek> {
  constructor() {
    super('sparx.school.calendar.v3.SchoolYearWeek', [
      { no: 1, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.SchoolYearWeek
 */
export const SchoolYearWeek = new SchoolYearWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYear$Type extends MessageType<SchoolYear> {
  constructor() {
    super('sparx.school.calendar.v3.SchoolYear', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'end_date', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYearWeek,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.SchoolYear
 */
export const SchoolYear = new SchoolYear$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolYearsRequest$Type extends MessageType<ListSchoolYearsRequest> {
  constructor() {
    super('sparx.school.calendar.v3.ListSchoolYearsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.ListSchoolYearsRequest
 */
export const ListSchoolYearsRequest = new ListSchoolYearsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolYearsResponse$Type extends MessageType<ListSchoolYearsResponse> {
  constructor() {
    super('sparx.school.calendar.v3.ListSchoolYearsResponse', [
      {
        no: 1,
        name: 'school_years',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYear,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v3.ListSchoolYearsResponse
 */
export const ListSchoolYearsResponse = new ListSchoolYearsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.calendar.v3.CalendarAPI
 */
export const CalendarAPI = new ServiceType(
  'sparx.school.calendar.v3.CalendarAPI',
  [
    {
      name: 'ListSchoolPeriods',
      options: {
        'google.api.http': { get: '/v3/{parent=schools/*}/periods' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'school:period',
          domain: '{parent}',
        },
      },
      I: ListSchoolPeriodsRequest,
      O: ListSchoolPeriodsResponse,
    },
    {
      name: 'GetSchoolPeriod',
      options: {
        'google.api.http': { get: '/v3/{name=schools/*/periods/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'school:period',
          domain: '{name|parent}',
        },
      },
      I: GetSchoolPeriodRequest,
      O: SchoolPeriod,
    },
    {
      name: 'CreateSchoolPeriod',
      options: {
        'google.api.http': {
          post: '/v3/{parent=schools/*}/periods',
          body: 'period',
        },
        'sparx.api.auth': {
          action: 'create',
          resource: 'school:period',
          domain: '{parent}',
        },
      },
      I: CreateSchoolPeriodRequest,
      O: SchoolPeriod,
    },
    {
      name: 'UpdateSchoolPeriod',
      options: {
        'google.api.http': {
          put: '/v3/{period.name=schools/*/periods/*}',
          body: 'period',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'school:period',
          domain: '{period.name|parent}',
        },
      },
      I: UpdateSchoolPeriodRequest,
      O: SchoolPeriod,
    },
    {
      name: 'DeleteSchoolPeriod',
      options: {
        'google.api.http': { delete: '/v3/{name=schools/*/periods/*}' },
        'sparx.api.auth': {
          action: 'delete',
          resource: 'school:period',
          domain: '{name|parent}',
        },
      },
      I: DeleteSchoolPeriodRequest,
      O: Empty,
    },
    {
      name: 'GetSchoolYear',
      options: {
        'google.api.http': { get: '/v3/{name=schools/*/years/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'school:year',
          domain: '{name|parent}',
        },
      },
      I: GetSchoolYearRequest,
      O: SchoolYear,
    },
    {
      name: 'ListSchoolYears',
      options: {
        'google.api.http': { get: '/v3/{parent=schools/*}/years' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'school:year',
          domain: '{parent}',
        },
      },
      I: ListSchoolYearsRequest,
      O: ListSchoolYearsResponse,
    },
  ]
);
