import _unsafeMerge from 'ts-deepmerge';

import { IChoiceGroupInput, IChoiceInput, IInput, IUploadField } from './types';

export type QuestionAction =
  | SetNumericAnswerAction
  | SetTextAnswerAction
  | SetExpressionAnswerAction
  | SetChoiceAnswerAction
  | DropCardToSlotAction
  | UploadImageAction
  | SetMediaCompleteAction;

export interface SetNumericAnswerAction {
  action: 'set_numeric';
  ref: string;
  value: string;
}

export interface SetTextAnswerAction {
  action: 'set_text';
  ref: string;
  value: string;
}

export interface SetExpressionAnswerAction {
  action: 'set_expression';
  ref: string;
  value: string;
}

export interface SetChoiceAnswerAction {
  action: 'set_choice';
  ref: string;
}

export interface DropCardToSlotAction {
  action: 'drop_card';
  cardRef: string;
  slotRef: string;
}

export interface UploadImageAction {
  action: 'upload_image';
  ref: string;
  image: IUploadField | undefined;
}

export interface SetMediaCompleteAction {
  action: 'media_complete';
  ref: string;
  value: string;
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const safeMerge = <T extends object>(obj: T, ...patch: Array<DeepPartial<T>>): T =>
  _unsafeMerge(obj as object, ...(patch as Array<object>)) as T;

export const updateInputWithAction = (input: IInput, action: QuestionAction): IInput => {
  switch (action.action) {
    case 'set_numeric':
      return updateInputWithNumericAction(input, action);
    case 'set_text':
      return updateInputWithTextAction(input, action);
    case 'set_expression':
      return updateInputWithExpressionAction(input, action);
    case 'set_choice':
      return updateInputWithChoiceAction(input, action);
    case 'drop_card':
      return updateInputWithDropCardAction(input, action);
    case 'upload_image':
      return updateInputWithImageAction(input, action);
    case 'media_complete':
      return updateInputWithMediaAction(input, action);
    default:
      console.warn('Unknown input action:', action);
      return input;
  }
};

export const updateInputWithNumericAction = (
  input: IInput,
  action: SetNumericAnswerAction,
): IInput =>
  safeMerge(input, {
    number_fields: {
      [action.ref]: {
        value: action.value,
      },
    },
  });

export const updateInputWithTextAction = (input: IInput, action: SetTextAnswerAction): IInput =>
  safeMerge(input, {
    text_fields: {
      [action.ref]: {
        value: action.value,
      },
    },
  });

export const updateInputWithExpressionAction = (
  input: IInput,
  action: SetExpressionAnswerAction,
): IInput =>
  safeMerge(input, {
    expression_fields: {
      [action.ref]: {
        value: action.value,
      },
    },
  });

export const findChoiceGroupForRef = (
  input: IInput,
  ref: string | undefined,
): [string, IChoiceGroupInput] | undefined =>
  Object.entries(input.choice_groups || {}).find(([_, group]) =>
    group.choice_refs.includes(ref || ''),
  );

export const updateInputWithChoiceAction = (
  input: IInput,
  action: SetChoiceAnswerAction,
): IInput => {
  // Find the choice group for this ref
  const group = findChoiceGroupForRef(input, action.ref);
  if (!group) {
    return input; // TODO
  }

  const patch: DeepPartial<Record<string, IChoiceInput>> = {};
  if (input.choices?.[action.ref]?.selected) {
    patch[action.ref] = { selected: false };
  } else {
    const [_, groupData] = group;
    const selChoices = groupData.choice_refs.filter(ref => input.choices?.[ref]?.selected).length;
    const maxChoices = parseInt(groupData.max_choices.toString());
    if (maxChoices === 1) {
      // Unselect the other choices and select the one we just clicked
      for (const ref of groupData.choice_refs) {
        patch[ref] = { selected: false };
      }
      patch[action.ref] = { selected: true };
    } else if (selChoices < maxChoices) {
      // Add the action choice to the patch
      patch[action.ref] = { selected: true };
    }
  }

  return safeMerge(input, { choices: patch });
};

export const updateInputWithDropCardAction = (
  input: IInput,
  action: DropCardToSlotAction,
): IInput => {
  const patches: Array<DeepPartial<IInput>> = [];

  const cardRefData = input.cards?.[action.cardRef];
  const slotRefData = input.slots?.[action.slotRef];

  const existingSlotCard = slotRefData?.card_ref;
  if (existingSlotCard) {
    // If this slot already has a card, remove it
    patches.push({
      slots: { [action.slotRef]: { card_ref: undefined } },
      cards: { [existingSlotCard]: { slot_ref: undefined } },
    });
  }

  const existingCardSlot = cardRefData?.slot_ref;
  if (existingCardSlot) {
    // If this slot already has a card, remove it
    patches.push({
      slots: { [existingCardSlot]: { card_ref: undefined } },
      cards: { [action.cardRef]: { slot_ref: undefined } },
    });
  }

  if (!action.slotRef.startsWith('return/')) {
    patches.push({
      cards: { [action.cardRef]: { slot_ref: action.slotRef } },
      slots: { [action.slotRef]: { card_ref: action.cardRef } },
    });
  }

  return safeMerge(input, ...patches);
};

const updateInputWithImageAction = (input: IInput, action: UploadImageAction): IInput =>
  safeMerge(input, {
    upload_fields: {
      [action.ref]: action.image,
    },
  });

const updateInputWithMediaAction = (input: IInput, action: SetMediaCompleteAction): IInput =>
  safeMerge(input, {
    media_fields: {
      [action.ref]: {
        value: action.value,
      },
    },
  });

// Given an input object and an array of (content) refs for correct answers, return
// a deep clone of the input object with only the correct answers retained.
export const onlyKeepCorrectAnswers = (
  storedInput: IInput,
  correctPartGapRefs: Set<string>,
): IInput => {
  const incorrectChoiceRefs = Object.entries(storedInput.choices || {}).filter(
    ([ref, _]) => !correctPartGapRefs.has(ref),
  );
  const incorrectNumberFieldRefs = Object.entries(storedInput.number_fields || {}).filter(
    ([ref, _]) => !correctPartGapRefs.has(ref),
  );
  const incorrectSlotRefs = Object.entries(storedInput.slots || {}).filter(
    ([card_ref, slotInfo]) => !correctPartGapRefs.has(card_ref) && !slotInfo.locked,
  );

  // Create a deep clone on the input object
  const newInput: IInput = JSON.parse(JSON.stringify(storedInput));

  for (const [ref, _] of incorrectChoiceRefs) {
    if (newInput.choices) {
      newInput.choices[ref].selected = false;
    }
  }
  for (const [ref, _] of incorrectNumberFieldRefs) {
    if (newInput.number_fields) {
      newInput.number_fields[ref].value = undefined;
    }
  }
  for (const [slotRef, _] of incorrectSlotRefs) {
    if (newInput.slots && newInput.cards) {
      const slotCardRef = newInput.slots[slotRef].card_ref;
      if (slotCardRef) {
        newInput.cards[slotCardRef].slot_ref = undefined;
      }
      newInput.slots[slotRef].card_ref = undefined;
    }
  }
  return newInput;
};
