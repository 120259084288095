// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/planning/v1/sol.proto" (package "sparx.planning.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { SchemeOfLearningTemplates } from './sol';
import type { ResetDraftSchemeTemplateRequest } from './sol';
import type { PublishSchemeTemplateRequest } from './sol';
import type { DeleteSchemeTemplateRequest } from './sol';
import type { UpdateSchemeTemplateRequest } from './sol';
import type { CreateSchemeTemplateRequest } from './sol';
import type { SchemeOfLearningTemplate } from './sol';
import type { GetSchemeTemplateRequest } from './sol';
import type { ListSchemeTemplatesResponse } from './sol';
import type { ListSchemeTemplatesRequest } from './sol';
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchemesOfLearning } from './sol';
import type { BatchSendUpdateEventsResponse } from './sol';
import type { BatchSendUpdateEventsRequest } from './sol';
import type { ResetDraftSchemeRequest } from './sol';
import type { UnpublishSchemeRequest } from './sol';
import type { PublishSchemeRequest } from './sol';
import type { UndeleteSchemeRequest } from './sol';
import type { Empty } from '../../../../google/protobuf/empty';
import type { DeleteSchemeRequest } from './sol';
import type { UpdateSchemeRequest } from './sol';
import type { CreateSchemeRequest } from './sol';
import type { SchemeOfLearning } from './sol';
import type { GetSchemeRequest } from './sol';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListSchemesResponse } from './sol';
import type { ListSchemesRequest } from './sol';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * The SchemesOfLearning service implements a set of operations to manage schemes
 * of learning.
 *
 * @generated from protobuf service sparx.planning.v1.SchemesOfLearning
 */
export interface ISchemesOfLearningClient {
  /**
   * Return a list of SOLs for a given school
   *
   * @generated from protobuf rpc: ListSchemes(sparx.planning.v1.ListSchemesRequest) returns (sparx.planning.v1.ListSchemesResponse);
   */
  listSchemes(
    input: ListSchemesRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchemesRequest, ListSchemesResponse>;
  /**
   * Return a single SOL given its resource name
   *
   * @generated from protobuf rpc: GetScheme(sparx.planning.v1.GetSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  getScheme(
    input: GetSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchemeRequest, SchemeOfLearning>;
  /**
   * Create a new SOL and returns it
   *
   * @generated from protobuf rpc: CreateScheme(sparx.planning.v1.CreateSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  createScheme(
    input: CreateSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchemeRequest, SchemeOfLearning>;
  /**
   * Update a SOL.  The value in the version field of the
   * request must match the version of the existing SOL.  It the SOL returned
   * will have its version increased by 1.  This prevents conflicting updates
   * of a SOL.
   *
   * @generated from protobuf rpc: UpdateScheme(sparx.planning.v1.UpdateSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  updateScheme(
    input: UpdateSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchemeRequest, SchemeOfLearning>;
  /**
   * Delete a SOL (with the same constraint as UpdateScheme)
   *
   * @generated from protobuf rpc: DeleteScheme(sparx.planning.v1.DeleteSchemeRequest) returns (google.protobuf.Empty);
   */
  deleteScheme(
    input: DeleteSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchemeRequest, Empty>;
  /**
   * Undelete a SOL (with the same constraint as UpdateScheme)
   *
   * @generated from protobuf rpc: UndeleteScheme(sparx.planning.v1.UndeleteSchemeRequest) returns (google.protobuf.Empty);
   */
  undeleteScheme(
    input: UndeleteSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UndeleteSchemeRequest, Empty>;
  /**
   * Publish a particular version of a SOL (typically the latest draft
   * version).  If the SOL is already published then this has no effect.
   *
   * @generated from protobuf rpc: PublishScheme(sparx.planning.v1.PublishSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  publishScheme(
    input: PublishSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<PublishSchemeRequest, SchemeOfLearning>;
  /**
   * Sets the given version of a SOL back to a draft
   *
   * @generated from protobuf rpc: UnpublishScheme(sparx.planning.v1.UnpublishSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  unpublishScheme(
    input: UnpublishSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UnpublishSchemeRequest, SchemeOfLearning>;
  /**
   * Reset a draft scheme back to it's most recent published version, creates
   * a new identical row or the most recent published SOL with an updated version.
   * Old drafts are not removed but will not be returned by the server. Returns
   * the published SOL with an updated version.
   *
   * @generated from protobuf rpc: ResetDraftScheme(sparx.planning.v1.ResetDraftSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  resetDraftScheme(
    input: ResetDraftSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<ResetDraftSchemeRequest, SchemeOfLearning>;
  /**
   * Send update events to pubsub (for msgelephant/BigQuery) for the latest published and draft SOL for all schools,
   * to ensure the information in BigQuery is fully up-to-date. This method is for internal use only, and so does
   * not have a HTTP endpoint or authentication
   *
   * @generated from protobuf rpc: BatchSendUpdateEvents(sparx.planning.v1.BatchSendUpdateEventsRequest) returns (sparx.planning.v1.BatchSendUpdateEventsResponse);
   */
  batchSendUpdateEvents(
    input: BatchSendUpdateEventsRequest,
    options?: RpcOptions
  ): UnaryCall<BatchSendUpdateEventsRequest, BatchSendUpdateEventsResponse>;
}
/**
 * The SchemesOfLearning service implements a set of operations to manage schemes
 * of learning.
 *
 * @generated from protobuf service sparx.planning.v1.SchemesOfLearning
 */
export class SchemesOfLearningClient
  implements ISchemesOfLearningClient, ServiceInfo
{
  typeName = SchemesOfLearning.typeName;
  methods = SchemesOfLearning.methods;
  options = SchemesOfLearning.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Return a list of SOLs for a given school
   *
   * @generated from protobuf rpc: ListSchemes(sparx.planning.v1.ListSchemesRequest) returns (sparx.planning.v1.ListSchemesResponse);
   */
  listSchemes(
    input: ListSchemesRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchemesRequest, ListSchemesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSchemesRequest, ListSchemesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Return a single SOL given its resource name
   *
   * @generated from protobuf rpc: GetScheme(sparx.planning.v1.GetSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  getScheme(
    input: GetSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchemeRequest, SchemeOfLearning> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Create a new SOL and returns it
   *
   * @generated from protobuf rpc: CreateScheme(sparx.planning.v1.CreateSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  createScheme(
    input: CreateSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchemeRequest, SchemeOfLearning> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Update a SOL.  The value in the version field of the
   * request must match the version of the existing SOL.  It the SOL returned
   * will have its version increased by 1.  This prevents conflicting updates
   * of a SOL.
   *
   * @generated from protobuf rpc: UpdateScheme(sparx.planning.v1.UpdateSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  updateScheme(
    input: UpdateSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchemeRequest, SchemeOfLearning> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Delete a SOL (with the same constraint as UpdateScheme)
   *
   * @generated from protobuf rpc: DeleteScheme(sparx.planning.v1.DeleteSchemeRequest) returns (google.protobuf.Empty);
   */
  deleteScheme(
    input: DeleteSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchemeRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchemeRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Undelete a SOL (with the same constraint as UpdateScheme)
   *
   * @generated from protobuf rpc: UndeleteScheme(sparx.planning.v1.UndeleteSchemeRequest) returns (google.protobuf.Empty);
   */
  undeleteScheme(
    input: UndeleteSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UndeleteSchemeRequest, Empty> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UndeleteSchemeRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Publish a particular version of a SOL (typically the latest draft
   * version).  If the SOL is already published then this has no effect.
   *
   * @generated from protobuf rpc: PublishScheme(sparx.planning.v1.PublishSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  publishScheme(
    input: PublishSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<PublishSchemeRequest, SchemeOfLearning> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<PublishSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Sets the given version of a SOL back to a draft
   *
   * @generated from protobuf rpc: UnpublishScheme(sparx.planning.v1.UnpublishSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  unpublishScheme(
    input: UnpublishSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<UnpublishSchemeRequest, SchemeOfLearning> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UnpublishSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Reset a draft scheme back to it's most recent published version, creates
   * a new identical row or the most recent published SOL with an updated version.
   * Old drafts are not removed but will not be returned by the server. Returns
   * the published SOL with an updated version.
   *
   * @generated from protobuf rpc: ResetDraftScheme(sparx.planning.v1.ResetDraftSchemeRequest) returns (sparx.planning.v1.SchemeOfLearning);
   */
  resetDraftScheme(
    input: ResetDraftSchemeRequest,
    options?: RpcOptions
  ): UnaryCall<ResetDraftSchemeRequest, SchemeOfLearning> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ResetDraftSchemeRequest, SchemeOfLearning>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Send update events to pubsub (for msgelephant/BigQuery) for the latest published and draft SOL for all schools,
   * to ensure the information in BigQuery is fully up-to-date. This method is for internal use only, and so does
   * not have a HTTP endpoint or authentication
   *
   * @generated from protobuf rpc: BatchSendUpdateEvents(sparx.planning.v1.BatchSendUpdateEventsRequest) returns (sparx.planning.v1.BatchSendUpdateEventsResponse);
   */
  batchSendUpdateEvents(
    input: BatchSendUpdateEventsRequest,
    options?: RpcOptions
  ): UnaryCall<BatchSendUpdateEventsRequest, BatchSendUpdateEventsResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchSendUpdateEventsRequest,
      BatchSendUpdateEventsResponse
    >('unary', this._transport, method, opt, input);
  }
}
/**
 * The SchemeOfLearningTemplate service implements a set of operations to manage
 * schemes of learning.
 *
 * @generated from protobuf service sparx.planning.v1.SchemeOfLearningTemplates
 */
export interface ISchemeOfLearningTemplatesClient {
  /**
   * Return a list of SOL templates for a given school group
   *
   * @generated from protobuf rpc: ListSchemeTemplates(sparx.planning.v1.ListSchemeTemplatesRequest) returns (sparx.planning.v1.ListSchemeTemplatesResponse);
   */
  listSchemeTemplates(
    input: ListSchemeTemplatesRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchemeTemplatesRequest, ListSchemeTemplatesResponse>;
  /**
   * Return a single SOL template given its resource name
   *
   * @generated from protobuf rpc: GetSchemeTemplate(sparx.planning.v1.GetSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  getSchemeTemplate(
    input: GetSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchemeTemplateRequest, SchemeOfLearningTemplate>;
  /**
   * Create a new SOL template and return it
   *
   * @generated from protobuf rpc: CreateSchemeTemplate(sparx.planning.v1.CreateSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  createSchemeTemplate(
    input: CreateSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchemeTemplateRequest, SchemeOfLearningTemplate>;
  /**
   * Update a SOL template.  The value in the version field of the
   * request must match the version of the existing SOL template.  It the SOL template returned
   * will have its version increased by 1.  This prevents conflicting updates
   * of a SOL.
   *
   * @generated from protobuf rpc: UpdateSchemeTemplate(sparx.planning.v1.UpdateSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  updateSchemeTemplate(
    input: UpdateSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchemeTemplateRequest, SchemeOfLearningTemplate>;
  /**
   * Delete a SOL template (with the same constraint as UpdateSchemeTemplate)
   *
   * @generated from protobuf rpc: DeleteSchemeTemplate(sparx.planning.v1.DeleteSchemeTemplateRequest) returns (google.protobuf.Empty);
   */
  deleteSchemeTemplate(
    input: DeleteSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchemeTemplateRequest, Empty>;
  /**
   * Publish a particular version of a SOL template (typically the latest draft
   * version).  If the SOL is already published then this has no effect.
   *
   * @generated from protobuf rpc: PublishSchemeTemplate(sparx.planning.v1.PublishSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  publishSchemeTemplate(
    input: PublishSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<PublishSchemeTemplateRequest, SchemeOfLearningTemplate>;
  /**
   * Reset a draft template back to it's most recent published version, creates
   * a new identical row or the most recent published template with an updated
   * version. Old drafts are not removed but will not be returned by the server.
   * Returns the published template with an updated version
   *
   * @generated from protobuf rpc: ResetDraftSchemeTemplate(sparx.planning.v1.ResetDraftSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  resetDraftSchemeTemplate(
    input: ResetDraftSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<ResetDraftSchemeTemplateRequest, SchemeOfLearningTemplate>;
}
/**
 * The SchemeOfLearningTemplate service implements a set of operations to manage
 * schemes of learning.
 *
 * @generated from protobuf service sparx.planning.v1.SchemeOfLearningTemplates
 */
export class SchemeOfLearningTemplatesClient
  implements ISchemeOfLearningTemplatesClient, ServiceInfo
{
  typeName = SchemeOfLearningTemplates.typeName;
  methods = SchemeOfLearningTemplates.methods;
  options = SchemeOfLearningTemplates.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Return a list of SOL templates for a given school group
   *
   * @generated from protobuf rpc: ListSchemeTemplates(sparx.planning.v1.ListSchemeTemplatesRequest) returns (sparx.planning.v1.ListSchemeTemplatesResponse);
   */
  listSchemeTemplates(
    input: ListSchemeTemplatesRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchemeTemplatesRequest, ListSchemeTemplatesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSchemeTemplatesRequest,
      ListSchemeTemplatesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Return a single SOL template given its resource name
   *
   * @generated from protobuf rpc: GetSchemeTemplate(sparx.planning.v1.GetSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  getSchemeTemplate(
    input: GetSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchemeTemplateRequest, SchemeOfLearningTemplate> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchemeTemplateRequest, SchemeOfLearningTemplate>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Create a new SOL template and return it
   *
   * @generated from protobuf rpc: CreateSchemeTemplate(sparx.planning.v1.CreateSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  createSchemeTemplate(
    input: CreateSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchemeTemplateRequest, SchemeOfLearningTemplate> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateSchemeTemplateRequest,
      SchemeOfLearningTemplate
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Update a SOL template.  The value in the version field of the
   * request must match the version of the existing SOL template.  It the SOL template returned
   * will have its version increased by 1.  This prevents conflicting updates
   * of a SOL.
   *
   * @generated from protobuf rpc: UpdateSchemeTemplate(sparx.planning.v1.UpdateSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  updateSchemeTemplate(
    input: UpdateSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchemeTemplateRequest, SchemeOfLearningTemplate> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateSchemeTemplateRequest,
      SchemeOfLearningTemplate
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Delete a SOL template (with the same constraint as UpdateSchemeTemplate)
   *
   * @generated from protobuf rpc: DeleteSchemeTemplate(sparx.planning.v1.DeleteSchemeTemplateRequest) returns (google.protobuf.Empty);
   */
  deleteSchemeTemplate(
    input: DeleteSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchemeTemplateRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchemeTemplateRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Publish a particular version of a SOL template (typically the latest draft
   * version).  If the SOL is already published then this has no effect.
   *
   * @generated from protobuf rpc: PublishSchemeTemplate(sparx.planning.v1.PublishSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  publishSchemeTemplate(
    input: PublishSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<PublishSchemeTemplateRequest, SchemeOfLearningTemplate> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      PublishSchemeTemplateRequest,
      SchemeOfLearningTemplate
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Reset a draft template back to it's most recent published version, creates
   * a new identical row or the most recent published template with an updated
   * version. Old drafts are not removed but will not be returned by the server.
   * Returns the published template with an updated version
   *
   * @generated from protobuf rpc: ResetDraftSchemeTemplate(sparx.planning.v1.ResetDraftSchemeTemplateRequest) returns (sparx.planning.v1.SchemeOfLearningTemplate);
   */
  resetDraftSchemeTemplate(
    input: ResetDraftSchemeTemplateRequest,
    options?: RpcOptions
  ): UnaryCall<ResetDraftSchemeTemplateRequest, SchemeOfLearningTemplate> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ResetDraftSchemeTemplateRequest,
      SchemeOfLearningTemplate
    >('unary', this._transport, method, opt, input);
  }
}
