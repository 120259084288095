import { ModalBody, Text, Textarea } from '@chakra-ui/react';
import { ImageUpload } from '@sparx/question';
import { useMutation } from '@tanstack/react-query';
import { scienceContentClient } from 'api';
import { getSchoolID } from 'api/sessions';
import { uploadFile } from 'app/storage';
import React, { useState } from 'react';
import {
  ActivityPreparationProps,
  createBasicLessonActivityBlueprint,
} from 'views/lessons/createactivity/activitytypes';

export const CreatePhotoActivity = ({ doSubmit, getFooter }: ActivityPreparationProps) => {
  const [questionText, setQuestionText] = useState('');
  const [imageData, setImage] = useState<File | undefined>();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      const uploadedAssetName = imageData ? await uploadFile(imageData) : '';
      return scienceContentClient.createCustomSkill({
        schoolName: `schools/${await getSchoolID()}`,
        question: {
          questionText,
          uploadedAssetName,
          answer: {
            oneofKind: 'camera',
            camera: {},
          },
        },
      }).response;
    },
  });

  const onSubmit = async () => {
    const skill = await mutateAsync();
    doSubmit(
      createBasicLessonActivityBlueprint({
        name: 'Photograph your book',
        skills: [
          {
            skillId: skill.skillName,
            questionId: '',
            annotations: {
              pend_correct: 'true',
            },
          },
        ],
      }),
    );
  };

  return (
    <>
      <ModalBody pt={0}>
        <Text mb={1}>Question text:</Text>
        <Textarea value={questionText} onChange={e => setQuestionText(e.target.value)} />
        <ImageUpload onImage={setImage} hasImage={Boolean(imageData)} prompt="Upload an image" />
        {imageData && <img src={URL.createObjectURL(imageData)} alt="uploaded image" />}
      </ModalBody>
      {getFooter(onSubmit, isLoading)}
    </>
  );
};
