// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "genproto/apis/uievents/uievents.proto" (package "uievents", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../google/protobuf/timestamp';
/**
 * Details of a user's interaction in a Sparx Web, for data analytics
 *
 * @generated from protobuf message uievents.UserInteractionEvent
 */
export interface UserInteractionEvent {
  /**
   * Timestamp of the event, corrected (by client) with server-offset
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
  /**
   * Which web app - "sparxweb", "parentportal" or "newuser"
   *
   * @generated from protobuf field: string application = 2;
   */
  application: string;
  /**
   * The school id - a UUID
   *
   * @generated from protobuf field: string schoolId = 3;
   */
  schoolId: string;
  /**
   * // in SparxWeb & parentWeb studentId if available - a UUID
   *
   * @generated from protobuf field: string userId = 4;
   */
  userId: string;
  /**
   * The session - e.g. the SparxWeb sessionID - a UUID
   *
   * @generated from protobuf field: string sessionId = 5;
   */
  sessionId: string;
  /**
   * Ordering of events within the session, incremented by client
   *
   * @generated from protobuf field: int32 eventIndex = 6;
   */
  eventIndex: number;
  /**
   * Name of the page the user was on when they made the interaction - e.g. "faqs"
   *
   * @generated from protobuf field: string page = 7;
   */
  page: string;
  /**
   * Category of event - e.g. "nav menu" or "change page"
   *
   * @generated from protobuf field: string category = 8;
   */
  category: string;
  /**
   * Detail of event - what the user chose - e.g. "logout" or "packagelist"
   *
   * @generated from protobuf field: string action = 9;
   */
  action: string;
  /**
   * Key value pairs containing event-specific details
   *
   * @generated from protobuf field: map<string, string> labels = 10;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * The user's connectionId - this is a unique id which changes each time
   * they reload or refresh the app
   *
   * @generated from protobuf field: string connectionId = 11;
   */
  connectionId: string;
  /**
   * Version of the web application - e.g. 2.62.3
   *
   * @generated from protobuf field: string version = 12;
   */
  version: string;
  /**
   * The timestamp that the event was received on the server
   *
   * @generated from protobuf field: google.protobuf.Timestamp serverTimestamp = 13;
   */
  serverTimestamp?: Timestamp;
}
/**
 * A batch of user interactions as sent from the client
 *
 * @generated from protobuf message uievents.UserInteractionClientMessage
 */
export interface UserInteractionClientMessage {
  /**
   * All the events that have been sent in this batch
   *
   * @generated from protobuf field: repeated uievents.UserInteractionEvent metrics = 1;
   */
  metrics: UserInteractionEvent[];
  /**
   * The time at which the batch was sent, used to calculate the actual time each event occurred
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class UserInteractionEvent$Type extends MessageType<UserInteractionEvent> {
  constructor() {
    super('uievents.UserInteractionEvent', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'application',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'schoolId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'userId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'sessionId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'eventIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'page', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'category', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'action', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 10,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 11,
        name: 'connectionId',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 12, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 13, name: 'serverTimestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message uievents.UserInteractionEvent
 */
export const UserInteractionEvent = new UserInteractionEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInteractionClientMessage$Type extends MessageType<UserInteractionClientMessage> {
  constructor() {
    super('uievents.UserInteractionClientMessage', [
      {
        no: 1,
        name: 'metrics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserInteractionEvent,
      },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message uievents.UserInteractionClientMessage
 */
export const UserInteractionClientMessage =
  new UserInteractionClientMessage$Type();
