// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/content/v1/content.proto" (package "sparx.science.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Content } from './content';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { CreateCustomSkillResponse } from './content';
import type { CreateCustomSkillRequest } from './content';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.content.v1.Content
 */
export interface IContentClient {
  /**
   * @generated from protobuf rpc: CreateCustomSkill(sparx.science.content.v1.CreateCustomSkillRequest) returns (sparx.science.content.v1.CreateCustomSkillResponse);
   */
  createCustomSkill(
    input: CreateCustomSkillRequest,
    options?: RpcOptions
  ): UnaryCall<CreateCustomSkillRequest, CreateCustomSkillResponse>;
}
/**
 * @generated from protobuf service sparx.science.content.v1.Content
 */
export class ContentClient implements IContentClient, ServiceInfo {
  typeName = Content.typeName;
  methods = Content.methods;
  options = Content.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: CreateCustomSkill(sparx.science.content.v1.CreateCustomSkillRequest) returns (sparx.science.content.v1.CreateCustomSkillResponse);
   */
  createCustomSkill(
    input: CreateCustomSkillRequest,
    options?: RpcOptions
  ): UnaryCall<CreateCustomSkillRequest, CreateCustomSkillResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateCustomSkillRequest, CreateCustomSkillResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
