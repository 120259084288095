import { LayoutElementProps } from '../question/SparxQuestionContext';
import { IExpressionFieldElement } from '../question/types';
// import { EditableMathField, MathField } from 'react-mathquill';

export const ExpressionElement =
  // eslint-disable-next-line no-empty-pattern
  ({}: LayoutElementProps<IExpressionFieldElement>) => {
    return <div>...</div>;
    // const context = useSparxQuestionContext();
    // const value = context.input.expression_fields?.[element.ref]?.value;
    //
    // const [mq, setMq] = useState<MathField>();
    //
    // const [showKeypad, setShowKeypad] = useState(true);
    // const [focus, setFocus] = useState(false);
    // useClickAwayListener(setFocus, focus, (ev: MouseEvent) => {
    //   const clickInSidebar =
    //     ev.target instanceof Element && ev.target.closest(`[data-alg="${element.ref}"]`);
    //   return !clickInSidebar;
    // });
    //
    // const onFocus = () => {
    //   setFocus(true);
    //   mq && mq.select();
    // };
    //
    // return (
    //   <div className={styles.TextFieldWrapper} onFocus={onFocus} data-alg={element.ref}>
    //     <div className={styles.TextFieldComponent}>
    //       <EditableMathField
    //         latex={value || ''}
    //         onChange={e =>
    //           context.sendAction({
    //             action: 'set_expression',
    //             ref: element.ref,
    //             value: e.latex(),
    //           })
    //         }
    //         mathquillDidMount={setMq}
    //         placeholder="Enter a value..."
    //         className={styles.TextField}
    //       />
    //     </div>
    //     <CheckboxWithLabel isDisabled={context.readOnly} checked={showKeypad} onCheck={setShowKeypad}>
    //       Show keypad
    //     </CheckboxWithLabel>
    //     {mq && focus && showKeypad && <PortalAlgebraicKeypad id={element.ref} mq={mq} />}
    //   </div>
    // );
  };
