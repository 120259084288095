import {
  GroupSettings,
  StudentSettings,
} from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import {
  ListGroupSettingsResponse,
  ListStudentSettingsResponse,
  StudentScienceState,
} from '@sparx/api/apis/sparx/science/schools/v1/school';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { FetchQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { scienceSchoolsClient } from 'api';
import { queryClient } from 'api/client';
import { invalidateAssignments } from 'api/planner';
import { getSchoolID } from 'api/sessions';
import { useMemo } from 'react';

import { Options, useGroups } from './school';

const studentSettingsQuery: FetchQueryOptions<ListStudentSettingsResponse> = {
  queryKey: ['school', 'studentsettings'],
  queryFn: async () =>
    scienceSchoolsClient.listStudentSettings({
      schoolName: 'schools/' + (await getSchoolID()),
    }).response,
  staleTime: 1000 * 30, // 30 seconds
};

const groupSettingsQuery: FetchQueryOptions<ListGroupSettingsResponse> = {
  queryKey: ['school', 'groupsettings'],
  queryFn: async () =>
    scienceSchoolsClient.listGroupSettings({
      schoolName: 'schools/' + (await getSchoolID()),
    }).response,
  staleTime: 1000 * 30, // 30 seconds
};

export const useStudentSettings = (opts: Options) =>
  useQuery({
    ...studentSettingsQuery,
    ...opts,
  });

export const useStudentScienceStates = (opts: Options) =>
  useQuery({
    ...studentSettingsQuery,
    ...opts,
    select: data => data.studentStates,
  });

export const useStudentScienceState = (studentID: string, opts: Options) =>
  useQuery({
    ...studentSettingsQuery,
    ...opts,
    select: data => data.studentStates.find(s => s.name === `students/${studentID}`),
  });

export const useGroupSettings = (opts: Options) =>
  useQuery({
    ...groupSettingsQuery,
    ...opts,
  });

const updateArray = <T extends { name: string }>(original: T[], updates: T[]) => {
  let newArray = [...original];
  for (const update of updates) {
    newArray = newArray.filter(s => s.name !== update.name);
  }
  newArray = newArray.concat(updates);
  return newArray;
};

const updateStudentSettingsInQuery = (settings: StudentSettings[]) => {
  queryClient.setQueryData(
    ['school', 'studentsettings'],
    (data: ListStudentSettingsResponse | undefined) => {
      if (data) {
        const students = updateArray(data.students, settings);
        return { ...data, students };
      }
      return data;
    },
  );
};

const updateGroupSettingsInQuery = (settings: GroupSettings[]) => {
  queryClient.setQueryData(
    ['school', 'groupsettings'],
    (data: ListGroupSettingsResponse | undefined) => {
      if (data) {
        const groups = updateArray(data.groups, settings);
        return { ...data, groups };
      }
      return data;
    },
  );
};

interface UpdateStudentSettingsRequest {
  students: Omit<Partial<StudentSettings>, 'internal'> & { name: string }[];
  paths: string[];
}

export const useUpdateStudentSettings = () =>
  useMutation({
    mutationFn: async (request: UpdateStudentSettingsRequest) => {
      console.log(request);
      return scienceSchoolsClient.updateStudentSettings({
        schoolName: 'schools/' + (await getSchoolID()),
        settings: request.students.map(s => StudentSettings.create(s)),
        updateMask: {
          paths: request.paths,
        },
      }).response;
    },
    onSuccess: data => updateStudentSettingsInQuery(data.updatedSettings),
  });

export const useUpdateGroupSettings = (opts?: { forceSchemeUpdate?: boolean }) =>
  useMutation({
    mutationFn: async (settings: GroupSettings[]) =>
      scienceSchoolsClient.updateGroupSettings({
        schoolName: 'schools/' + (await getSchoolID()),
        settings,
        preview: false,
        useDraftScheme: false,
        forceUpdateScheme: opts?.forceSchemeUpdate || false,
      }).response,
    onSuccess: data => {
      updateGroupSettingsInQuery(data.updatedSettings);
      invalidateAssignments();
    },
  });

export interface StudentWithSettings extends Student {
  scienceSettings: StudentSettings;
  actualLevel: string;
  scienceState: StudentScienceState | null;
}

export const useStudentsWithSettings = (
  students: Student[] | undefined,
  settings: ListStudentSettingsResponse | undefined,
): StudentWithSettings[] =>
  useMemo(() => {
    const studentSettingLookup =
      settings?.students.reduce<Record<string, StudentSettings>>((p, v) => {
        p[v.name] = v;
        return p;
      }, {}) || {};
    const studentStateLookup =
      settings?.studentStates.reduce<Record<string, StudentScienceState>>((p, v) => {
        p[v.name] = v;
        return p;
      }, {}) || {};

    return (
      students?.map(s => {
        const scienceSettings =
          studentSettingLookup[`students/${s.studentId}`] || settings?.defaultSettings;
        const scienceState = studentStateLookup[`students/${s.studentId}`] || null;
        return {
          ...s,
          scienceSettings,
          scienceState,
          actualLevel:
            // Order preference matches server logic:
            // science/server/pkg/launcher/studentsettings.go
            scienceSettings.levelOverride ||
            scienceSettings.levelAutomatic ||
            scienceSettings.level,
        };
      }) || []
    );
  }, [students, settings]);

export interface GroupWithSettings extends Group {
  scienceSettings: GroupSettings;
}

export const useSuspenseGroupsWithSettings = () => {
  const { data: groupSchoolman } = useGroups({ suspense: true });
  const { data: groupSettings } = useGroupSettings({ suspense: true });
  return useGroupsWithSettings(groupSchoolman, groupSettings);
};

export const useGroupsWithSettings = (
  groups: Group[] | undefined,
  settings: ListGroupSettingsResponse | undefined,
): GroupWithSettings[] =>
  useMemo(() => {
    const groupSettingLookup =
      settings?.groups.reduce<Record<string, GroupSettings>>((p, v) => {
        p[v.name] = v;
        return p;
      }, {}) || {};

    return (
      groups?.map(g => ({
        ...g,
        scienceSettings: groupSettingLookup[g.name] || settings?.defaultSettings,
      })) || []
    );
  }, [groups, settings]);

export const useGroupsUsingSol = (groups: GroupWithSettings[], solName: string) =>
  useMemo(() => filterGroupsUsingSol(groups, solName), [groups, solName]);

export const filterGroupsUsingSol = (groups: GroupWithSettings[], solName: string) =>
  groups
    .filter(g => g.scienceSettings.solName === solName)
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
