import classNames from 'classnames';
import { FC } from 'react';
import * as React from 'react';

import styles from '../question/SparxQuestion.module.css';

export const TextFieldWarning: FC<{
  children?: React.ReactNode;
  visible?: boolean;
}> = ({ children, visible }) => (
  <div
    className={classNames(styles.TextFieldWarningTarget, {
      [styles.TextFieldWarningTargetVisible]: visible,
    })}
  >
    <div className={styles.TextFieldWrapperWarning}>{children}</div>
  </div>
);
