import { useCallback, useEffect, useState } from 'react';

/**
 * useKeepElementWithinElement calls setOffset with a reasonable estimate of what it should be.
 * It attempts to keep the offset as close to baseOffset as possible, while keeping the element
 * within the specified bounding element.
 * If the element is bigger than the bounding element it just uses the base position.
 * It is only interested in horizontal positioning.
 * It returns a function that can be called to force a recalculation.
 * This function assumes that the offset will be used as the 'left' property of the child, which is
 * positioned relatively to the parent element.
 * In the case that the element we want to bound the child within is not the one
 * it is left-offset against, the optional parentElement parameter can
 * be used.
 * @param element The element to be positioned
 * @param boundingElement The element to be bounded within
 * @param getBaseOffset A function that returns the base offset of the element
 * @param scale The scale-factor of the element
 * @param enable Whether to enable positioning
 * @param parentElement The element to be offset against (if not the bounding element)
 */
export const useKeepElementWithinElement = (
  element: HTMLElement | null,
  boundingElement: HTMLElement | null,
  getBaseOffset: () => number,
  scale: number,
  enable: boolean,
  parentElement?: HTMLElement | null,
) => {
  const [recalculateState, setRecalculateState] = useState(false);
  const [offset, setOffset] = useState<number>();
  const setScaledOffset = useCallback((newOffset: number) => setOffset(newOffset / scale), [scale]);

  const recalculate = useCallback(() => setRecalculateState(v => !v), [setRecalculateState]);

  const updateElementPosition = useCallback(
    (baseOffset: number) => {
      // once this has been run once, we can mark it as positioned
      if (!element || !boundingElement) {
        setScaledOffset(baseOffset);
        return;
      }

      const elementRect = element.getBoundingClientRect();
      const boundingRect = boundingElement.getBoundingClientRect();
      const parentRect = parentElement?.getBoundingClientRect();

      const parentBoundingGap = parentRect ? parentRect.x - boundingRect.x : 0;

      // if the element is too wide, or wants to be positioned outside the bounding elements to the left then
      // position at the left hand edge of the parent element
      if (elementRect.width > boundingRect.width || baseOffset + parentBoundingGap < 0) {
        setScaledOffset(-parentBoundingGap);
        return;
      }
      // if the element's right edge will go past the bounding elements's right edge then position at the
      // right hand edge of the bounding element
      if (baseOffset + elementRect.width + parentBoundingGap > boundingRect.width) {
        setScaledOffset(boundingRect.width - elementRect.width - parentBoundingGap);
        return;
      }

      // position as requested
      setScaledOffset(baseOffset);
      return;
    },
    [boundingElement, element, parentElement, setScaledOffset],
  );

  useEffect(() => {
    if (!enable) {
      return;
    }
    updateElementPosition(getBaseOffset());
    const onResize = () => updateElementPosition(getBaseOffset());
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [getBaseOffset, updateElementPosition, recalculateState, enable]);

  return { offset, recalculate };
};

/** 
useGetBaseOffset returns a callback which is used to return a left-offset value
(with respect to its parentElement) for an elementToPosition, such that it will 
be center aligned with a given fixedElement
@param fixedElement The element to be centered with respect to
@param elementToPosition The element to be centered
@param parentElement The element to measure the offset against
*/
export const useGetBaseOffset = (
  fixedElement: Element | null,
  elementToPosition: HTMLElement | null,
  parentElement?: HTMLElement | null,
) => {
  return useCallback(() => {
    const parentRect = parentElement?.getBoundingClientRect();
    const parentX = parentRect?.x || 0;
    const inputFieldRect = fixedElement?.getBoundingClientRect();
    const inputFieldWidth = inputFieldRect?.width || 0;
    const inputFieldX = inputFieldRect?.x || 0;
    const keypadRect = elementToPosition?.getBoundingClientRect();
    const keypadWidth = keypadRect?.width || 0;
    return inputFieldX - parentX + (inputFieldWidth - keypadWidth) / 2;
  }, [elementToPosition, fixedElement, parentElement]);
};
