import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ChangeEvent, useMemo, useRef, useState } from 'react';

import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IAssetUploadElement } from '../question/types';
import styles from './AssetUploadElement.module.css';

export const AssetUploadElement = ({ element }: LayoutElementProps<IAssetUploadElement>) => {
  const context = useSparxQuestionContext();
  const uploadedImage = context.input.upload_fields?.[element.ref];

  const fileUrl = useMemo(
    () => (uploadedImage?.file ? URL.createObjectURL(uploadedImage.file) : undefined),
    [uploadedImage?.file],
  );

  return (
    <div
      className={classNames(styles.FileUploadContainer, {
        // There's a bug where the containing box doesn't resize to show the full image (only really happens in prod though)
        // This is a temp fix to force the height, the proper fix would be to look at triggering some kind of resize once the image has loaded
        [styles.FileUploadContainerFixHeight]: context.readOnly,
      })}
    >
      {fileUrl && <img src={fileUrl} alt="uploaded image" />}
      {uploadedImage?.uploadedAssetName &&
        context.getUploadedAsset?.(uploadedImage.uploadedAssetName)}
      {!context.readOnly ? (
        <ImageUpload
          hasImage={Boolean(fileUrl)}
          onImage={file =>
            context.sendAction({
              action: 'upload_image',
              ref: element.ref,
              image: file ? { file } : undefined,
            })
          }
        />
      ) : (
        <div className={styles.FileUploadReadonly}>Uploaded image</div>
      )}
    </div>
  );
};

interface ImageUploadProps {
  onImage: (data: File | undefined) => void;
  hasImage?: boolean;
  prompt?: string;
}

export const ImageUpload = ({ onImage, hasImage, prompt }: ImageUploadProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<Error | undefined>();
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    // Check the file size
    setError(undefined);
    const file = e.target.files[0];
    if (file.size > 8 * 1024 * 1024) {
      setError(new Error('file too large'));
      return;
    }
    // Check the file type
    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
      setError(new Error('incorrect file type'));
      return;
    }
    onImage(file);
  };

  const handleUploadClick = () => {
    if (hasImage) {
      onImage(undefined);
    } else {
      ref.current?.click();
    }
  };

  return (
    <>
      {error && (
        <div className={styles.FileUploadError}>
          <strong>Error: </strong>
          {error.message}
        </div>
      )}
      <button onClick={handleUploadClick} className={styles.Button}>
        <FontAwesomeIcon icon={hasImage ? faTimes : faCamera} />
        {hasImage ? `Clear image` : prompt || 'Take a photo'}
      </button>
      <input
        type="file"
        capture="environment"
        accept="image/*"
        ref={ref}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
