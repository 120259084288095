import { KioskData } from '@sparx/api/apis/sparx/teaching/kiosk/v1/kiosk';
import { tokenMetadata } from '@sparx/science/api';
import { queryClient } from '@sparx/science/api/client';
import { anySignal } from '@sparx/science/utils/aborts';
import { sleep } from '@sparx/science/utils/interval';
import { useQuery } from '@tanstack/react-query';
import { kioskClient } from 'api/service';
import { differenceInSeconds } from 'date-fns';

const maxSecondsWithoutMessage = 10;
const abortLoopInterval = 4; // secs

export const useWatchKioskSettings = (onError?: (err: Error) => void) => {
  useQuery({
    queryKey: ['kiosk', 'watch'],
    queryFn: async ({ signal: querySignal }) => {
      // Setup an abort controller for the message interval loop to use to cancel
      const controller = new AbortController();
      const signal = anySignal(querySignal, controller.signal);

      console.log('[kiosk stream] starting');
      const call = kioskClient.watchKiosk(
        {
          watchState: false,
        },
        {
          ...(await tokenMetadata()),
          abort: signal,
        },
      );

      let lastMessage = new Date();
      (async () => {
        while (!signal?.aborted) {
          // If we have not had a message in the last 20 seconds, we abort the stream
          if (differenceInSeconds(new Date(), lastMessage) > maxSecondsWithoutMessage) {
            console.error(
              '[kiosk stream] aborting due to no message in ' +
                maxSecondsWithoutMessage +
                ' seconds',
            );
            controller.abort('No message in ' + maxSecondsWithoutMessage + ' seconds');
            return;
          }
          // TODO: decide if we want the auto abort behaviour
          // const autoResetConnectionTime = 60; // secs
          // if (ticks >= autoResetConnectionTime / abortLoopInterval) {
          //   controller.abort(`Auto reset after ${autoResetConnectionTime}s`);
          //   return;
          // }
          await sleep(abortLoopInterval);
        }
      })().catch(err => {
        console.error('[kiosk stream] Error in timeout loop:', err);
      });

      call.responses.onMessage(message => {
        lastMessage = new Date();
        console.debug('[kiosk stream] message:', message);
        if (message.kiosk) {
          queryClient.setQueryData(['kiosk', 'watch', 'settings'], message.kiosk);
        }
      });

      call.responses.onError(err => {
        console.warn('[kiosk stream] errored:', err);
        controller.abort('Stream errored'); // TODO: add this to lesson stream?
        onError?.(err);
      });

      const { status, trailers } = await call;
      console.warn('[kiosk stream] ended:', { status, trailers });
      throw new Error('Stream ended'); // Ensures that react-query will retry
    },
    retry: true,
    // Ramp up from 1s delay to 10s
    retryDelay: i => 1000 + Math.min(i * 500, 9000),
    refetchIntervalInBackground: true,
  });

  return useQuery({
    queryKey: ['kiosk', 'watch', 'settings'],
    queryFn: () => new Promise<KioskData>(() => undefined), // never resolve
    refetchInterval: false,
  });
};
