import { Box, Button, ButtonGroup, HStack } from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getLessonServerOffset, useUpdateLessonState } from '@sparx/science/api/lessons';
import { useInterval } from '@sparx/science/utils/interval';
import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';

export const LessonTimer = ({ lessonState }: { lessonState: LessonState }) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { mutate, isLoading } = useUpdateLessonState(lessonID);

  const createTimer = (duration: number) => {
    mutate({
      timer: {
        durationSeconds: duration,
        title: 'Test timer',
      },
    });
  };

  const [customTimer, setCustomTimer] = useState(1);

  return (
    <HStack spacing={2}>
      <ButtonGroup isAttached={true}>
        <Button isLoading={isLoading} onClick={() => createTimer(10)} size="sm">
          10
        </Button>
        <Button isLoading={isLoading} onClick={() => createTimer(30)} size="sm">
          30
        </Button>
      </ButtonGroup>
      <ButtonGroup isAttached={true} size="sm">
        <Button colorScheme="blue" onClick={() => setCustomTimer(c => Math.max(1, c - 1))}>
          -
        </Button>
        <Button colorScheme="blue" onClick={() => setCustomTimer(c => Math.min(30, c + 1))}>
          +
        </Button>
        <Button
          onClick={() => createTimer(customTimer * 60)}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          {customTimer} min
        </Button>
      </ButtonGroup>
      <Box flex={1} />
      {lessonState.timer && <TimerCountdown lessonState={lessonState} playSounds={true} />}
      {lessonState.timer && (
        <Button
          isLoading={isLoading}
          onClick={() => createTimer(0)}
          colorScheme="red"
          isDisabled={!lessonState.timer}
          size="sm"
        >
          Cancel
        </Button>
      )}
    </HStack>
  );
};

interface TimerCountdownProps {
  lessonState: LessonState;
  // Number of seconds to flash the timer when finished
  flashSeconds?: number;
  playSounds?: boolean;
}

export const TimerCountdown = ({ lessonState, playSounds, flashSeconds }: TimerCountdownProps) => {
  const calculateTimer = () => {
    if (lessonState.timer?.endTime) {
      const end = { ...lessonState.timer.endTime };
      end.seconds += getLessonServerOffset();
      const endDate = Timestamp.toDate(end);
      return differenceInSeconds(endDate, new Date()) || 0;
    }
    return 0;
  };

  const [timer, setTimer] = useState(calculateTimer());
  const [flash, setFlash] = useState(false);

  useInterval(() => {
    const timer = calculateTimer();
    setTimer(timer);
    setFlash(f => (timer <= 0 ? !f : false));
  }, 500);

  useAudioCues(timer, playSounds);

  if (flashSeconds !== undefined && timer - 1 <= -flashSeconds) {
    return null; // hide after flashSeconds
  }

  return (
    <Box
      textAlign="center"
      fontWeight="bold"
      fontFamily="mono"
      px={3}
      py={1}
      color="blue.700"
      bg={timer <= 0 ? (flash ? 'red.200' : 'red.100') : 'gray.100'}
    >
      {formatTimer(timer)}
    </Box>
  );
};

const useAudioCues = (timer: number, active?: boolean) =>
  useEffect(() => {
    if (!active) return;

    const playSound = (src: string) => {
      new Audio(src).play().catch(e => console.error('Failed to play audio', e));
    };

    switch (timer) {
      case 60:
        playSound('/sounds/timer_warn.wav');
        break;
      case 10:
        playSound('/sounds/timer_warn2.mp3');
        break;
      case 0:
        playSound('/sounds/timer_finish.mp3');
        break;
    }
  }, [active, timer]);

const formatTimer = (seconds: number) => {
  if (seconds < 0) seconds = 0; // clamp

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
