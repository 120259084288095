import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { memo, ReactNode, useCallback, useLayoutEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import markdownGFM from 'remark-gfm';
import markdownMath from 'remark-math';

import { useSlideyGroupContext } from '../question/SlideyContext';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { ITextElement } from '../question/types';
import { useScaleElementsToFit } from '../utils/useScaleElementsToFit';
import { Conversation } from './custom/Conversation';
import { QuestionImage } from './ImageElement';

export const TextElement = ({ element, parent = null }: LayoutElementProps<ITextElement>) => {
  const { mode, questionElement } = useSparxQuestionContext();
  const { slidey: isInSlidey } = useSlideyGroupContext();
  const textRef = useRef<HTMLDivElement>(null);

  // scale all span.katex elements within the text (unless we're in a slidey,
  // in which case, just scale the whole element)
  const getElementsToScale = useCallback(
    () =>
      isInSlidey
        ? [textRef.current]
        : Array.from<HTMLElement>(textRef.current?.querySelectorAll('span.katex') || []).filter(e =>
            isSpanElement(e),
          ),
    [isInSlidey],
  );

  // scale based on the span.katex-html element that is a child of the span.katex element
  const getElementsToMeasure = useCallback((element: HTMLElement) => {
    const elementToMeasure = element.querySelector<HTMLElement>('span.katex-html');
    return isSpanElement(elementToMeasure) ? [elementToMeasure] : [];
  }, []);

  const getParentWidth = useCallback(
    () => (parent ? parent.offsetWidth : questionElement?.offsetWidth || 0),
    [parent, questionElement?.offsetWidth],
  );

  const { scaleElementsToFit } = useScaleElementsToFit(
    getParentWidth,
    getElementsToScale,
    getElementsToMeasure,
    undefined,
    !parent, // set max width for full width text so element isn't wider than the question bounds
    20,
    parent ? 'center' : 'left', // scale down max width text to the left,
    false,
  );

  useLayoutEffect(() => {
    scaleElementsToFit();
    const aborter = new AbortController();
    window.addEventListener('resize', scaleElementsToFit, {
      signal: aborter.signal,
    });
    return () => aborter.abort();
  }, [element, scaleElementsToFit, parent?.offsetWidth]);

  // Ignore blank content
  if (!element.text.trim()) {
    return null;
  }

  if (element.type?.includes('markdown')) {
    // Render the markdown version
    return (
      <div ref={textRef} id={element.labelId}>
        <MarkdownNode
          className={classNames({
            [styles.TextElement]: true,
            [styles.AnswerTextElement]: mode === 'answer' && !element.text.includes('\\n'),
          })}
        >
          {element.text}
        </MarkdownNode>
      </div>
    );
  } else {
    // Legacy version
    return (
      <div ref={textRef} id={element.labelId}>
        <TextWithMaths
          className={classNames({
            [styles.TextElement]: true,
            [styles.AnswerTextElement]: mode === 'answer' && !element.text.includes('\\n'),
          })}
          text={element.text}
        />
      </div>
    );
  }
};

const isSpanElement = (element: Element | null): element is HTMLSpanElement => {
  return !!(element && element.tagName.toLowerCase() === 'span');
};

interface MarkdownNodeProps {
  children: string;
  className?: string;
  options?: {
    additionalRemarkPlugins?: PluggableList;
    additionalRehypePlugins?: PluggableList;
    additionalComponents?: Record<string, (p: ReactMarkdownProps) => ReactNode>;
  };
}

const preprocessMarkdownText = (text: string): string => {
  text = text.replace(/\\n/g, '\n').replace(/(^|[^\n])\n(?!\n)/g, '$1  \n');

  // this uses the same splitting / iterating code as renderMixedTextToString
  const bits = text.match(/\$|(?:\\.|[^$])+/g);
  if (bits === null) {
    return text;
  }
  let isMath = false;
  for (let i = 0; i < bits.length; i++) {
    if (bits[i] === '$') {
      isMath = !isMath;
    } else if (!isMath) {
      bits[i] = bits[i].replace(/\\textbf{(.+)}/, '**$1**').replace(/\\textit{(.+)}/, '*$1*');
    }
  }

  return bits.join('');
};

export const MarkdownNode = memo(({ children, className, options }: MarkdownNodeProps) => (
  <ReactMarkdown
    className={classNames(styles.MarkdownNode, className)}
    remarkPlugins={[markdownGFM, markdownMath, ...(options?.additionalRemarkPlugins || [])]}
    rehypePlugins={options?.additionalRehypePlugins}
    key={children}
    components={{
      img: args => {
        return <QuestionImage src={args.src || ''} alt={args.alt} />;
      },
      code: args => {
        const classes = Array.isArray(args.node.properties?.className)
          ? args.node.properties?.className
          : [];

        if (classes.includes('language-math')) {
          // TODO: science may prefer KatexMath instead of TextWithMath
          return <TextWithMaths text={`$${args.children.toString()} $`} />;
        }
        if (classes.includes('language-conversation')) {
          return <Conversation>{args.children.toString()}</Conversation>;
        }
        if (args.inline) {
          return <CodeLink>{args.children.toString()}</CodeLink>;
        }
        return <pre>{args.children}</pre>;
      },
      pre: args =>
        args.node.children.length > 0 &&
        (args.node.children[0] as { tagName?: string }).tagName === 'code' ? (
          // If the child is a code block then don't wrap with pre
          args.children
        ) : (
          <pre>{args.children}</pre>
        ),
      ...options?.additionalComponents,
    }}
  >
    {preprocessMarkdownText(children)}
  </ReactMarkdown>
));

const CodeLink = ({ children }: { children: string }) => {
  const readOnly = useSparxQuestionContext().readOnly;

  const match = children.match(/(.{1,3}\))\s?_{1,20}/);
  if (match && !readOnly) {
    const key = match[1].trim();
    const onClick = () => {
      const element = document.querySelector(`[data-part-name="${key}"]`);
      if (element) {
        const focusable = [
          ...element.querySelectorAll('input, textarea, [tabindex]:not([tabindex="-1"])'),
        ];
        const enabled = focusable.filter(el => !el.hasAttribute('disabled'));
        (enabled[0] as HTMLElement)?.focus?.();
      }
    };

    return (
      <code className={styles.LinkedCode} onClick={onClick}>
        {children}
      </code>
    );
  }
  return <code>{children}</code>;
};
