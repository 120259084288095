import * as React from 'react';

import { SparxQuestionContext } from '../question/SparxQuestionContext';

export const pasteHandler = (
  { ref }: { ref: string },
  inputType: string,
  context: SparxQuestionContext,
) => ({
  onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    context.onPaste?.({
      text: e.clipboardData.getData('text'),
      ref,
      pasteType: 'paste',
      inputType,
    }),
  onDrop: (e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const transfer = e.dataTransfer;
    if (transfer.types.includes('text/html')) {
      const text = transfer.getData('text/plain') || transfer.getData('text');
      if (text) {
        context.onPaste?.({ text, ref, pasteType: 'drag', inputType });
      }
    }
  },
});
