import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonCircle,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { IChoiceElement, IElement, ISteps, SparxQuestion } from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading, LargeLoadingWithText } from 'components/loading/LargeLoading';
import React, { useMemo, useState } from 'react';
import { useLessonViewContext } from 'views/lessons/LessonView';
import {
  ActivitySummary,
  addAnswerToActivities,
  useAnswerLookup,
  useListActivities,
} from 'views/lessons/panel/ActivityPanel';
import { Panel } from 'views/lessons/panel/Panel';

interface SurveyTaskItemPanelProps {
  assignmentId: string;
  taskIndex: number;
  taskItemIndex: number;
  onClose: () => void;
}

export const SurveyTaskItemPanel = ({
  assignmentId,
  taskIndex,
  taskItemIndex,
  onClose,
}: SurveyTaskItemPanelProps) => {
  const selectedTaskItem = `assignments/${assignmentId}/tasks/${taskIndex}/items/${taskItemIndex}`;
  const { data, isLoading } = useListActivities(selectedTaskItem);

  const [mode, setMode] = useState<'first' | 'last' | 'all'>('first');

  const answerLookup = useAnswerLookup(data);
  const relevantActivities = useMemo(() => {
    const acts =
      data?.activities?.sort(
        (a, b) =>
          (mode === 'last' ? -1 : 1) *
          ((a.activity?.endTimestamp?.seconds || 0) - (b.activity?.endTimestamp?.seconds || 0)),
      ) || [];

    const seenStudent = new Set<string>();

    const actsWithAnswer = addAnswerToActivities(acts, answerLookup);
    const actsByQuestion: Record<string, ActivitySummary[]> = {};
    for (const a of actsWithAnswer) {
      // Track the seen students
      if (mode === 'first' || mode === 'last') {
        if (seenStudent.has(a.studentId || '')) continue;
        seenStudent.add(a.studentId || '');
      }

      if (!actsByQuestion[a.questionName]) actsByQuestion[a.questionName] = [a];
      else actsByQuestion[a.questionName].push(a);
    }
    return Object.values(actsByQuestion).sort((a, b) => a.length - b.length);
  }, [mode, data?.activities, answerLookup]);

  if (isLoading) {
    return (
      <Panel onClose={onClose}>
        <LargeLoading />
      </Panel>
    );
  }

  return (
    <Panel
      title={
        <HStack spacing={3} ml={1}>
          <SkeletonCircle startColor="red.100" endColor="red.200" size="3" flexShrink={0} />
          <Text>Answers for question</Text>
        </HStack>
      }
      right={
        <Menu>
          <MenuButton
            mr={4}
            colorScheme="white"
            size="sm"
            as={Button}
            variant="link"
            textTransform="capitalize"
          >
            {mode}
          </MenuButton>
          <MenuList color="gray.800">
            <MenuItem onClick={() => setMode('first')}>First answer</MenuItem>
            <MenuItem onClick={() => setMode('last')}>Last answer</MenuItem>
            <MenuItem onClick={() => setMode('all')}>All answers</MenuItem>
          </MenuList>
        </Menu>
      }
      onClose={onClose}
    >
      {relevantActivities.length === 0 && (
        <LargeLoadingWithText>Waiting for answers...</LargeLoadingWithText>
      )}
      {relevantActivities.map((acts, i) => (
        <QuestionSummary key={i} activities={acts} />
      ))}
    </Panel>
  );
};

const QuestionSummary = ({ activities }: { activities: ActivitySummary[] }) => {
  const questionLayout = useMemo(() => {
    try {
      const json = activities[0]?.activity?.state?.skillActivity?.question?.questionJson;
      return JSON.parse(json || '[]') as ISteps;
    } catch (e) {
      console.error('Failed to parse question json', e);
      return undefined;
    }
  }, [activities]);
  const { sendEvent } = useClientEvent();

  const keys = useMemo(() => orderInputs(questionLayout || []), [questionLayout]);

  return (
    <Box p={4}>
      {questionLayout?.map((step, i) => (
        <SparxQuestion
          key={i}
          layout={step.layout}
          input={{}}
          setInput={() => undefined}
          readOnly={true}
          fontSize="12"
          mode="question"
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action }, labels)
          }
        />
      ))}
      {keys.map((key, i) =>
        key.type === 'input' ? (
          <InputAnswerChart activities={activities} key={key.ref} answerKey={key.ref} />
        ) : (
          <ChoiceAnswerChart activities={activities} key={i} choices={key.refs} />
        ),
      )}
    </Box>
  );
};

const InputAnswerChart = ({
  activities,
  answerKey,
}: {
  activities: ActivitySummary[];
  answerKey: string;
}) => {
  const sorted: OptionStats[] = useMemo(() => {
    const answerCounts: Record<string, { value: string; students: string[]; correct: boolean }> =
      {};
    for (const act of activities) {
      const evaluation = act.activity?.state?.skillActivity?.evaluation;
      const ans = evaluation?.submittedAnswer?.[answerKey];
      if (evaluation && ans !== undefined) {
        const correct = Boolean(evaluation.gapEvaluations[answerKey]?.correct);
        if (!answerCounts[ans])
          answerCounts[ans] = {
            value: ans,
            students: [],
            correct: correct,
          };
        answerCounts[ans].students.push(act.studentId);
      }
    }

    return Object.entries(answerCounts)
      .map(([k, v]) => ({ key: k, ...v, count: v.students.length }))
      .sort((a, b) => b.count - a.count);
  }, [activities, answerKey]);

  return <AnswerChart options={sorted} />;
};

const ChoiceAnswerChart = ({
  activities,
  choices,
}: {
  activities: ActivitySummary[];
  choices: ChoiceWithRef[];
}) => {
  const sorted: OptionStats[] = useMemo(() => {
    const answerCounts: Record<string, { value: string; students: string[]; correct: boolean }> =
      {};
    for (const choice of choices) {
      answerCounts[choice.ref + '/correct'] = {
        value: choice.value,
        students: [],
        correct: true,
      };
      answerCounts[choice.ref + '/wrong'] = {
        value: choice.value,
        students: [],
        correct: false,
      };
    }

    for (const act of activities) {
      const evaluation = act.activity?.state?.skillActivity?.evaluation;
      const ans = evaluation?.submittedAnswer;
      if (evaluation && ans !== undefined) {
        for (const selected of Object.keys(ans)) {
          const key =
            selected + '/' + (evaluation.status === TaskItem_Status.CORRECT ? 'correct' : 'wrong');
          if (answerCounts[key]) {
            answerCounts[key].students.push(act.studentId);
          }
        }
      }
    }

    return Object.entries(answerCounts)
      .filter(([_, v]) => v.students.length > 0)
      .map(([k, v]) => ({ key: k, ...v, count: v.students.length }))
      .sort((a, b) => b.count - a.count);
  }, [activities, choices]);

  return <AnswerChart options={sorted} />;
};

interface OptionStats {
  key: string;
  value: string;
  correct: boolean;
  count: number;
  students: string[];
}

const AnswerChart = ({ options }: { options: OptionStats[] }) => {
  const { toggleSelectedStudent, studentStates } = useLessonViewContext();

  const [total, max] = useMemo(() => {
    let total = 0;
    let max = 0;
    for (const opt of options) {
      total += opt.count;
      max = Math.max(max, opt.count);
    }
    return [total, max];
  }, [options]);

  const { correct, incorrect, unattempted, incorrectAndUnattempted } = useMemo(() => {
    const correct = new Set(
      options
        .filter(a => a.correct)
        .map(a => a.students)
        .flat(),
    );
    const incorrect = new Set(
      options
        .filter(a => !a.correct)
        .map(a => a.students)
        .flat(),
    );

    const unattempted = new Set(studentStates.map(s => s.studentId));
    for (const id of correct) unattempted.delete(id);
    for (const id of incorrect) unattempted.delete(id);
    const incorrectAndUnattempted = new Set([...incorrect, ...unattempted]);
    return { correct, incorrect, unattempted, incorrectAndUnattempted };
  }, [options, studentStates]);

  const correctOption = (ids: Set<string>, title: string, icon: IconDefinition, color: string) => (
    <Tooltip label={title} hasArrow={true} fontSize="sm" placement="top" px={3} py={2}>
      <Link
        opacity={ids.size === 0 ? 0.5 : 1}
        onClick={() => toggleSelectedStudent([...ids], true, true)}
      >
        <Text as="span" color={color} mr={2}>
          <FontAwesomeIcon icon={icon} />
        </Text>
        {ids.size}
      </Link>
    </Tooltip>
  );

  return (
    <Box pt={4} mt={4} borderTop="1px solid #efefef">
      <HStack alignItems="center" fontSize="sm" color="gray.600" spacing={4}>
        <Text mb={2}>
          {total} answer{total === 1 ? '' : 's'}
        </Text>
        <Spacer />
        {correctOption(correct, 'Correct', faCheck, 'green.500')}
        {correctOption(incorrect, 'Incorrect', faTimes, 'red.500')}
        {correctOption(incorrectAndUnattempted, 'Incorrect or unattempted', faTimes, 'orange.300')}
        {correctOption(unattempted, 'Unattempted', faTimes, 'grey.500')}
      </HStack>
      <Grid gridTemplateColumns="auto 5fr" gap={1} pt={1}>
        {options.map((a, i) => (
          <React.Fragment key={i}>
            <Tooltip label={a.value} hasArrow={true} fontSize="sm" placement="top" px={3} py={2}>
              <GridItem
                py={1}
                px={2}
                textAlign="right"
                maxWidth="120px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {a.value}
              </GridItem>
            </Tooltip>
            <GridItem
              bg="gray.50"
              position="relative"
              borderRadius="md"
              onClick={() => toggleSelectedStudent(a.students, true, true)}
              cursor="pointer"
              _hover={{ opacity: 0.9 }}
            >
              <Box
                borderRadius="md"
                position="absolute"
                bg={a.correct ? 'green.100' : 'red.100'}
                boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1) inset"
                top="0"
                bottom="0"
                left="0"
                width={`${(a.count / max) * 100}%`}
                fontSize="xs"
                fontWeight="bold"
                color="blackAlpha.500"
                pr={4}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                transition="width ease-out 0.2s"
              >
                {a.count}
              </Box>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

interface ChoiceWithRef {
  ref: string;
  value: string;
}

type InputEntry =
  | {
      type: 'input';
      ref: string;
    }
  | {
      type: 'choices';
      refs: ChoiceWithRef[];
    };

const orderInputs = (steps: ISteps) => {
  const found: InputEntry[] = [];
  for (const step of steps) {
    const iterateElement = (el: IElement): any => {
      switch (el.element) {
        case 'group':
          if (el.type?.indexOf('choices') !== -1) {
            const choices = el.content.filter(c => c.element === 'choice') as IChoiceElement[];
            found.push({
              type: 'choices',
              refs: choices.map(c => ({
                ref: c.ref,
                value: step.input.choices?.[c.ref]?.content?.map(extractTextContent).join('') || '',
              })),
            });
            return;
          }
          return el.content.forEach(child => iterateElement(child));
        case 'part-group':
          return el.content.forEach(child => iterateElement(child));
        case 'number-field':
        case 'text-field':
        case 'expression-field':
          found.push({ type: 'input', ref: el.ref });
          return;
      }
    };
    iterateElement(step.layout);
  }
  return found;
};

const extractTextContent = (el: IElement): string => {
  switch (el.element) {
    case 'text':
      return el.text;
    case 'group':
      return el.content.map(extractTextContent).join('');
    default:
      return '';
  }
};
