import { GapEvaluation, QuestionMarkingMode } from '../question/SparxQuestionContext';

export const isGapCorrect = (
  ref: string,
  gapEvaluations?: Record<string, GapEvaluation>,
  markingMode?: QuestionMarkingMode,
) => {
  if (!gapEvaluations || markingMode === 'part') {
    return { show: false, correct: false };
  }
  return {
    evaluation: gapEvaluations[ref],
    show: gapEvaluations[ref] !== undefined,
    correct: gapEvaluations[ref]?.correct,
    correction: gapEvaluations[ref]?.correction,
    hasDetail: (gapEvaluations[ref]?.detail?.length || 0) > 0,
  };
};
