import { getKeyPressed } from '@sparx/react-utils/keyboard';
import { useEffect, useState } from 'react';

import { useSparxQuestionContext } from '../question/SparxQuestionContext';

type EnterInputBehaviourProps = {
  /**
   * Forces preventDefault and stopPropagation to be called on the event.
   */
  preventBubble?: boolean;
  /**
   * If set, opens / focusses the next input when enter is pressed.
   */
  nextInputAction?: 'open' | 'focus';
  /**
   * If set, waits for an option close animation to finish before performing the next input action.
   */
  waitForAnim?: boolean;
  /**
   * If set, doesn't handle any keydown events.
   */
  disabled?: boolean;
};

/**
 * Attaches a keydown event listener to the provided element which handles some
 * common behaviour when pressing Enter for an answer input. This should be used to
 * keep consistent keyboard behaviour for interactive answer elements.
 */
export const useEnterInputBehaviour = (
  element: HTMLElement | null,
  { preventBubble, nextInputAction, waitForAnim, disabled }: EnterInputBehaviourProps,
) => {
  const { nextInputRef, setOpenElementRef, setIsWaitingForAnimation, isWaitingForAnimation } =
    useSparxQuestionContext();

  const [moveToNextInputRef, setMoveToNextInputRef] = useState('');

  // register a handler for enter pressed and set move to next input state accordingly
  useEffect(() => {
    const aborter = new AbortController();
    if (!element) return;
    if (!preventBubble && !nextInputAction) return;
    if (disabled) return;

    const handler = (event: Event) => {
      if (event instanceof KeyboardEvent && getKeyPressed(event) === 'Enter') {
        const nextRef = nextInputRef();
        if (preventBubble) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (nextInputAction && nextRef) {
          if (waitForAnim) {
            setIsWaitingForAnimation(true);
          }
          setMoveToNextInputRef(nextRef);
        }
      }
    };
    element.addEventListener('keydown', handler, { signal: aborter.signal });
    return () => aborter.abort();
  }, [
    preventBubble,
    element,
    disabled,
    nextInputAction,
    nextInputRef,
    waitForAnim,
    setIsWaitingForAnimation,
    setMoveToNextInputRef,
  ]);

  // move to next input when triggered from handler in previous useEffect
  useEffect(() => {
    if (moveToNextInputRef !== '' && (!waitForAnim || !isWaitingForAnimation)) {
      switch (nextInputAction) {
        case 'open':
          setOpenElementRef(moveToNextInputRef);
          break;
        case 'focus':
          document.querySelector<HTMLElement>(`[data-ref="${moveToNextInputRef}"]`)?.focus();
          break;
      }
      setMoveToNextInputRef('');
    }
  }, [
    moveToNextInputRef,
    waitForAnim,
    isWaitingForAnimation,
    setMoveToNextInputRef,
    nextInputAction,
    setOpenElementRef,
  ]);
};
