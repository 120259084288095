import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Stack } from '../components/Stack';
import { SlideyGroupContextProvider } from '../question/SlideyContext';
import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';
import { useScaleElementsToFit } from '../utils/useScaleElementsToFit';

interface SlideyProps {
  children?: ReactNode | undefined;
}

export const Slidey = ({ children }: SlideyProps) => {
  const { questionElement, setScale } = useSparxQuestionContext();
  const elementToScaleRef = useRef<HTMLDivElement>(null);

  const [selectedCardRef, setSelectedCardRef] = useState<string | undefined>(undefined);
  const [allImagesHaveLoaded, setAllImagesHaveLoaded] = useState(true);

  // scale the div wrapping the whole slidey
  const getElementsToScale = useCallback(() => [elementToScaleRef.current], []);

  // scale width based on the width of the slots group
  const getElementsToMeasure = useCallback((element: HTMLElement) => {
    const slots = element.querySelector<HTMLElement>(`[data-stack="slots"]`);
    return slots ? [slots] : [];
  }, []);

  // Get vertical stack to set height of container
  const getHeightOfVerticalStack = useCallback(() => {
    if (elementToScaleRef.current === null) {
      return 0;
    }
    const verticalStack = elementToScaleRef.current.querySelector<HTMLElement>(
      `[data-stack="vertical-stack"]`,
    );
    return verticalStack?.getBoundingClientRect().height || 0;
  }, [elementToScaleRef]);

  const getSlots = useCallback(
    () => [...document.querySelectorAll<HTMLElement>('[data-scale-target="slot"]')],
    [],
  );

  const getCards = useCallback(
    () => [...document.querySelectorAll<HTMLElement>('[data-scale-target="card-content"]')],
    [],
  );

  const getQuestionWidth = useCallback(
    () => questionElement?.offsetWidth || 0,
    [questionElement?.offsetWidth],
  );

  const { scale, scaleElementsToFit } = useScaleElementsToFit(
    getQuestionWidth,
    getElementsToScale,
    getElementsToMeasure,
    getHeightOfVerticalStack,
    true,
    10,
    'top center',
    false,
  );

  useEffect(() => {
    setScale?.(scale);
  }, [scale, setScale]);

  /**
   * Sets the width of the card Stack to fill the screen on downscaling so that
   * wrapping behaves as expected
   */
  const setWidthOfCardStack = useCallback(() => {
    const cardStack = document.querySelector<HTMLElement>('[data-stack="cards"]');
    if (!cardStack) {
      return;
    }

    if (scale < 1) {
      // Note: 16px comes from var(--question-spacing)
      const newWidth = (getQuestionWidth() + 16) / scale;
      cardStack.style.width = `${newWidth}px`;
      return;
    }

    cardStack.style.width = 'auto';
  }, [getQuestionWidth, scale]);

  /**
   * Sets the dimensions for cards and slots
   */
  const setCardAndSlotDimensions = useCallback(() => {
    if (!allImagesHaveLoaded) return;
    const cards = getCards();
    const slots = getSlots();
    const cardsAndSlots = [...cards, ...slots];

    // Set width on all slots to be auto
    slots.forEach(slot => (slot.style.width = 'auto'));
    const slotWidth = slots[0]?.offsetWidth;
    // set width on cards and slots to be the auto-width of slots
    cardsAndSlots.forEach(card => {
      card.style.width = `${slotWidth}px`;
    });
    // Find tallest card’s "auto" height with the given width and set all cards
    // & slots to this height.
    let tallestCardHeight = 0;
    cards.forEach(card => {
      card.style.height = 'auto';
    });
    cards.forEach(card => (tallestCardHeight = Math.max(tallestCardHeight, card?.offsetHeight)));
    cardsAndSlots.forEach(el => (el.style.height = `${tallestCardHeight}px`));
  }, [allImagesHaveLoaded, getCards, getSlots]);

  // Perform all the scaling logic
  useEffect(() => {
    setWidthOfCardStack();
    setCardAndSlotDimensions();
    scaleElementsToFit();
    const aborter = new AbortController();
    window.addEventListener(
      'resize',
      () => {
        setWidthOfCardStack();
        setCardAndSlotDimensions();
        scaleElementsToFit();
      },
      { signal: aborter.signal },
    );
    return () => aborter.abort();
  }, [allImagesHaveLoaded, scaleElementsToFit, setCardAndSlotDimensions, setWidthOfCardStack]);

  // Detect when all (if any) images have loaded
  const imagesToLoadCount = useRef(0);
  useLayoutEffect(() => {
    const handleMutation: MutationCallback = mutationList => {
      for (const mutation of mutationList) {
        // Check if any img tags have been added

        const imageNode = [...mutation.addedNodes].find(
          (n: Node): n is HTMLImageElement => n instanceof HTMLImageElement,
        );

        if (!imageNode) {
          continue;
        }

        // If the image is already loaded, decrement count and check if we're
        // waiting for more image loads
        if (imageNode.complete) {
          imagesToLoadCount.current--;
          if (imagesToLoadCount.current === 0) {
            setAllImagesHaveLoaded(true);
          }
          break;
        }

        // If the image isn't yet loaded, add an onload event listener to: tell
        // us when it does and check if we're waiting for more image loads
        imageNode.onload = () => {
          imagesToLoadCount.current--;
          if (imagesToLoadCount.current === 0) {
            setAllImagesHaveLoaded(true);
          }
        };
        break;
      }
    };

    const mutationObserver = new MutationObserver(handleMutation);

    const cards = getCards();
    cards.forEach(card => {
      // Check if there is an image-loading placeholder div (see ImageNode
      // component)
      if (card.querySelector('[data-image-loading]')) {
        imagesToLoadCount.current++;
        setAllImagesHaveLoaded(false);
        // Observe for image loading
        mutationObserver.observe(card, { childList: true });
      }
    });

    return () => mutationObserver.disconnect();
  }, [getCards]);

  return (
    <SlideyGroupContextProvider
      value={{
        slidey: true,
        scale: scale,
        selectedCardRef: selectedCardRef,
        setSelectedCardRef: setSelectedCardRef,
      }}
    >
      <div ref={elementToScaleRef}>
        <Stack dir="vertical" className={styles.Slidey} dataTag="vertical-stack">
          {children}
        </Stack>
      </div>
    </SlideyGroupContextProvider>
  );
};
