// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.proto" (package "sparx.teacherportal.schoolstatus.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolStatusService } from './schoolstatus';
import type { GetSchoolHasHomeworkResponse } from './schoolstatus';
import type { GetSchoolHasHomeworkRequest } from './schoolstatus';
import type { GetMISStatusResponse } from './schoolstatus';
import type { GetMISStatusRequest } from './schoolstatus';
import type { StudentDomainCheckResponse } from './schoolstatus';
import type { StudentDomainCheckRequest } from './schoolstatus';
import type { GetSchoolTeacherOnboardingStatusResponse } from './schoolstatus';
import type { GetSchoolTeacherOnboardingStatusRequest } from './schoolstatus';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSchoolOnboardingStatusResponse } from './schoolstatus';
import type { GetSchoolOnboardingStatusRequest } from './schoolstatus';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.teacherportal.schoolstatus.v1.SchoolStatusService
 */
export interface ISchoolStatusServiceClient {
  /**
   * @generated from protobuf rpc: GetSchoolOnboardingStatus(sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusResponse);
   */
  getSchoolOnboardingStatus(
    input: GetSchoolOnboardingStatusRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetSchoolOnboardingStatusRequest,
    GetSchoolOnboardingStatusResponse
  >;
  /**
   * @generated from protobuf rpc: GetSchoolTeacherOnboardingStatus(sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusResponse);
   */
  getSchoolTeacherOnboardingStatus(
    input: GetSchoolTeacherOnboardingStatusRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetSchoolTeacherOnboardingStatusRequest,
    GetSchoolTeacherOnboardingStatusResponse
  >;
  /**
   * StudentDomainCheck allows the client to check whether student email addresses conform
   * with an alternative SSO domain
   *
   * @generated from protobuf rpc: StudentDomainCheck(sparx.teacherportal.schoolstatus.v1.StudentDomainCheckRequest) returns (sparx.teacherportal.schoolstatus.v1.StudentDomainCheckResponse);
   */
  studentDomainCheck(
    input: StudentDomainCheckRequest,
    options?: RpcOptions
  ): UnaryCall<StudentDomainCheckRequest, StudentDomainCheckResponse>;
  /**
   * @generated from protobuf rpc: GetMISStatus(sparx.teacherportal.schoolstatus.v1.GetMISStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetMISStatusResponse);
   */
  getMISStatus(
    input: GetMISStatusRequest,
    options?: RpcOptions
  ): UnaryCall<GetMISStatusRequest, GetMISStatusResponse>;
  /**
   * GetSchoolHasHomework describes whether the school has ever set a homework.
   *
   * @generated from protobuf rpc: GetSchoolHasHomework(sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkResponse);
   */
  getSchoolHasHomework(
    input: GetSchoolHasHomeworkRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolHasHomeworkRequest, GetSchoolHasHomeworkResponse>;
}
/**
 * @generated from protobuf service sparx.teacherportal.schoolstatus.v1.SchoolStatusService
 */
export class SchoolStatusServiceClient
  implements ISchoolStatusServiceClient, ServiceInfo
{
  typeName = SchoolStatusService.typeName;
  methods = SchoolStatusService.methods;
  options = SchoolStatusService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSchoolOnboardingStatus(sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusResponse);
   */
  getSchoolOnboardingStatus(
    input: GetSchoolOnboardingStatusRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetSchoolOnboardingStatusRequest,
    GetSchoolOnboardingStatusResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSchoolOnboardingStatusRequest,
      GetSchoolOnboardingStatusResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetSchoolTeacherOnboardingStatus(sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusResponse);
   */
  getSchoolTeacherOnboardingStatus(
    input: GetSchoolTeacherOnboardingStatusRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetSchoolTeacherOnboardingStatusRequest,
    GetSchoolTeacherOnboardingStatusResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSchoolTeacherOnboardingStatusRequest,
      GetSchoolTeacherOnboardingStatusResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * StudentDomainCheck allows the client to check whether student email addresses conform
   * with an alternative SSO domain
   *
   * @generated from protobuf rpc: StudentDomainCheck(sparx.teacherportal.schoolstatus.v1.StudentDomainCheckRequest) returns (sparx.teacherportal.schoolstatus.v1.StudentDomainCheckResponse);
   */
  studentDomainCheck(
    input: StudentDomainCheckRequest,
    options?: RpcOptions
  ): UnaryCall<StudentDomainCheckRequest, StudentDomainCheckResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      StudentDomainCheckRequest,
      StudentDomainCheckResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetMISStatus(sparx.teacherportal.schoolstatus.v1.GetMISStatusRequest) returns (sparx.teacherportal.schoolstatus.v1.GetMISStatusResponse);
   */
  getMISStatus(
    input: GetMISStatusRequest,
    options?: RpcOptions
  ): UnaryCall<GetMISStatusRequest, GetMISStatusResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetMISStatusRequest, GetMISStatusResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * GetSchoolHasHomework describes whether the school has ever set a homework.
   *
   * @generated from protobuf rpc: GetSchoolHasHomework(sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkRequest) returns (sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkResponse);
   */
  getSchoolHasHomework(
    input: GetSchoolHasHomeworkRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolHasHomeworkRequest, GetSchoolHasHomeworkResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSchoolHasHomeworkRequest,
      GetSchoolHasHomeworkResponse
    >('unary', this._transport, method, opt, input);
  }
}
