import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { useInterval } from 'utils/interval';

interface ITalkAndLearnTimerProps {
  time: number;
  onContinue: () => void;
}

export const TalkAndLearnTimer = ({ time, onContinue }: ITalkAndLearnTimerProps) => {
  const [startSeconds] = useState(new Date().getTime() / 1000);
  const [currentSeconds, setCurrentSeconds] = useState(0);

  useInterval(() => {
    const currentSeconds = new Date().getTime() / 1000;
    const newSeconds = currentSeconds - startSeconds;
    if (newSeconds >= time) {
      onContinue();
    }
    setCurrentSeconds(newSeconds);
  }, 250);

  const dots = Math.min(Math.ceil(currentSeconds), time);

  const dotNodes = [];
  for (let i = 0; i < time; i++) {
    dotNodes.push(
      <div
        key={i}
        style={{
          width: 15,
          height: 15,
          background: i < dots ? 'rgb(46, 56, 77)' : '#cdcdcd',
          display: 'inline-block',
          marginRight: 8,
          marginLeft: 1,
          borderRadius: '50%',
        }}
      />,
    );
  }

  return <Box mt={2}>{dotNodes}</Box>;
};
