// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/progress/v1/completion.proto" (package "sparx.progress.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * *
 * This wants to be Completion from apis/progress.proto, but can't be because
 * that .proto file is beyond Bazel's reach.
 * TODO: Either avoid duplicating the Completion message, or make peace with doing so
 *
 * Completion tracks information about the size and progress
 * of a task or package.
 *
 * The progress map will contain key value mapping of
 * different progress types. The primary progress indicator
 * is the Complete type ("C"). When this value is at
 * least >= size, then the package or task is deemed complete.
 *
 * @generated from protobuf message sparx.progress.v1.Completion
 */
export interface Completion {
  /**
   * Size is how big the thing is.  The size of a task should be equal to sum
   * of item.weight*item.size for all items in the task.  The size of a
   * package should be equal to sum of task.weight*task.size for all tasks in
   * the package.
   *
   * @generated from protobuf field: double size = 1;
   */
  size: number;
  /**
   * Weight is how much it counts toward the size of its container.  It's a
   * multiplier for size.
   *
   * @generated from protobuf field: double weight = 2;
   */
  weight: number;
  /**
   * Progress is a map of ProgressType to non-negative floating point value.
   * These values do not need to sum to Size, however a completion is complete
   * if the Complete progress value is at least Size.
   *
   * The description of these types can be found in the progress go package.
   *
   * @generated from protobuf field: map<string, double> progress = 3;
   */
  progress: {
    [key: string]: number;
  };
}
// @generated message type with reflection information, may provide speed optimized methods
class Completion$Type extends MessageType<Completion> {
  constructor() {
    super('sparx.progress.v1.Completion', [
      { no: 1, name: 'size', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'weight', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      {
        no: 3,
        name: 'progress',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.progress.v1.Completion
 */
export const Completion = new Completion$Type();
