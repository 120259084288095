import { createContext, useContext } from 'react';

export interface SlideyGroupContext {
  slidey: boolean;
  scale: number;
  selectedCardRef: string | undefined;
  setSelectedCardRef: (ref: string | undefined) => void;
}

const SlideyGroupContext = createContext<SlideyGroupContext>({
  slidey: false,
  scale: 1,
  selectedCardRef: undefined,
  setSelectedCardRef: (_: string | undefined) => undefined,
});

export const useSlideyGroupContext = () => useContext(SlideyGroupContext);

export const SlideyGroupContextProvider = SlideyGroupContext.Provider;
