import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { faLock, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Course,
  CourseTier,
  HomeworkLength,
} from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { StudentLogin } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { createColumnHelper } from '@tanstack/react-table';
import { useStudents } from 'api/school';
import {
  StudentWithSettings,
  useStudentSettings,
  useStudentsWithSettings,
} from 'api/scienceschool';
import { useClassSelection } from 'app/ClassSelection';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { DataTable } from 'components/table/DataTable';
import { getTableEditColumns } from 'components/table/TableColumns';
import { formatDistanceToNowStrict } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudentBulkEdit } from 'views/students/StudentBulkEdit';

export const StudentListView = () => (
  <SuspenseRoute>
    <SuspenseStudentListView />
  </SuspenseRoute>
);

const levelColours: Dictionary<string, [string, string]> = {
  '1': ['cyan.50', 'cyan.600'],
  '2': ['blue.50', 'blue.600'],
  '3': ['teal.50', 'teal.600'],
  '4': ['purple.50', 'purple.600'],
};

const SuspenseStudentListView = () => {
  const { data: studentsData } = useStudents({ suspense: true });
  const { data: studentSettings } = useStudentSettings({ suspense: true });

  const students = useStudentsWithSettings(studentsData || [], studentSettings);

  const location = useLocation();
  const query = queryString.parse(location.search);
  const groupID = query.group?.toString() || '';

  const group = useClassSelection({
    current: groupID,
    link: (groupID: string) => {
      const query = queryString.stringify({
        group: groupID,
      });
      return `/teacher/student?${query}`;
    },
  });

  const [selectedStudents, setSelectedStudents] = useState(new Set<string>());
  const toggleStudent = (checked: boolean, ...ids: string[]) =>
    setSelectedStudents(s => {
      const newSet = new Set(s);
      if (checked) ids.forEach(id => newSet.add(id));
      else ids.forEach(id => newSet.delete(id));
      return newSet;
    });

  // Clear the selected students when a new group is selected
  useEffect(() => setSelectedStudents(new Set<string>()), [groupID]);

  const filteredStudents = useMemo(
    () =>
      students
        ?.filter(s => s.studentGroupIds.includes(groupID))
        .map(s => ({ ...s, selected: selectedStudents.has(s.studentId) })) || [],
    [students, groupID, selectedStudents],
  );

  const columnHelper = createColumnHelper<StudentWithSettings & { selected?: boolean }>();
  const columns = useMemo(
    () => [
      ...getTableEditColumns(columnHelper, g => g.studentId, toggleStudent),
      columnHelper.accessor('givenName', {
        header: 'First Name',
        cell: info => (
          <Text color="blue.900" fontWeight="bold">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('familyName', {
        header: 'Last Name',
        cell: info => (
          <Text color="blue.900" fontWeight="bold">
            {info.getValue()}
          </Text>
        ),
      }),

      columnHelper.accessor('actualLevel', {
        header: 'Level',
        cell: info => (
          <HStack spacing={2}>
            <Box
              textAlign="center"
              width={7}
              py={1}
              my={-1}
              borderRadius="sm"
              backgroundColor={levelColours[info.getValue()]?.[0] || 'gray.50'}
              color={levelColours[info.getValue()]?.[1] || 'gray.500'}
            >
              {info.getValue()}
            </Box>
            {info.row.original.scienceSettings.levelAutomatic &&
              !info.row.original.scienceSettings.levelOverride && <AutomaticLevelIcon />}
            {info.row.original.scienceSettings.levelOverride && <OverrideLevelIcon />}
          </HStack>
        ),
      }),
      columnHelper.accessor(`scienceSettings.course`, {
        header: 'Course',
        cell: info => courseNames[info.row.original.scienceSettings.course],
      }),

      columnHelper.accessor(`scienceSettings.tier`, {
        header: 'Tier',
        cell: info => courseTierNames[info.row.original.scienceSettings.tier],
      }),

      columnHelper.accessor('scienceSettings.homeworkLength', {
        header: 'Homework Type',
        cell: info => (
          <Text color={warnHomeworkLength(info.row.original) ? 'red.500' : undefined}>
            {homeworkTypeNames[info.row.original.scienceSettings.homeworkLength]}
          </Text>
        ),
        meta: {
          width: '170px',
        },
      }),

      columnHelper.display({
        header: 'Login status',
        cell: info => (
          <StudentPasswordResetStatus
            login={info.row.original.login}
            lastLoginTimestamp={info.row.original.scienceState?.lastLoginTimestamp}
          />
        ),
        enableSorting: false,
        meta: {
          width: '160px',
        },
      }),
    ],
    [],
  );

  const navigate = useNavigate();
  const onClickRow = (row: StudentWithSettings) =>
    navigate(`/teacher/student/${row.studentId}/details`);

  const [stickyOffset, setStickyOffset] = useState(0);

  if (!group) {
    return null;
  }

  return (
    <PageContainer>
      <PageTitle
        title="Student Manager"
        pageTitleOverride={group ? `${group.displayName} Student Manager` : ''}
      />
      <DataTable
        data={filteredStudents}
        columns={columns}
        defaultSort={[
          { id: `familyName`, desc: false },
          { id: `givenName`, desc: false },
        ]}
        onRowClick={onClickRow}
        rowIsHighlighted={s => (s.selected ? 'gray.50' : 'white')}
        noDataRow={<>No students to display</>}
        extraHeaderHeight={stickyOffset}
        extraHeader={
          <StudentBulkEdit
            students={filteredStudents.filter(s => s.selected)}
            setHeight={setStickyOffset}
          />
        }
      />
    </PageContainer>
  );
};

const warnHomeworkLength = (student: StudentWithSettings) =>
  student.scienceSettings.homeworkLength === HomeworkLength.OFF;

export const courseSelectionOptions = [Course.COMBINED, Course.SEPARATE];
export const courseNames: Record<Course, string> = {
  [Course.UNSPECIFIED]: 'Unknown',
  [Course.COMBINED]: 'Combined',
  [Course.SEPARATE]: 'Separate',
};

export const courseTierOptions = [CourseTier.LEVEL_FOUNDATION, CourseTier.LEVEL_HIGHER];
export const courseTierNames: Record<CourseTier, string> = {
  [CourseTier.LEVEL_UNSPECIFIED]: 'Unknown',
  [CourseTier.LEVEL_FOUNDATION]: 'Foundation',
  [CourseTier.LEVEL_HIGHER]: 'Higher',
};

export const homeworkTypeOptions = [HomeworkLength.FULL, HomeworkLength.HALF, HomeworkLength.OFF];
export const homeworkTypeNames: Record<HomeworkLength, string> = {
  [HomeworkLength.UNKNOWN]: 'Unknown',
  [HomeworkLength.FULL]: 'On',
  [HomeworkLength.HALF]: 'Half-length',
  [HomeworkLength.OFF]: 'Off',
};

export const initialLevelOptions = ['1', '2', '3', '4'];
export const levelOptionsWithoutAutomatic = ['1', '2', '3', '4'];
export const levelOptions = ['', ...levelOptionsWithoutAutomatic];

export const levelOptionNamesWithoutAutomatic = {
  '1': 'Level 1 (easiest)',
  '2': 'Level 2',
  '3': 'Level 3',
  '4': 'Level 4 (hardest)',
};

export const levelOptionNamesWithAutomatic = (autLevel?: string): Record<string, string> => ({
  '': `Automatic${autLevel ? ` - Level ${autLevel}` : ''}`,
  ...levelOptionNamesWithoutAutomatic,
});

export const levelOptionNames = levelOptionNamesWithAutomatic('');

export const getOptionList = <T extends string | number>(
  opts: T[],
  names?: Dictionary<T, string>,
) =>
  opts.map(c => (
    <option value={c} key={c}>
      {names ? names[c] : c}
    </option>
  ));

const StudentPasswordResetStatus = ({
  login,
  lastLoginTimestamp,
}: {
  login: StudentLogin | undefined;
  lastLoginTimestamp: Timestamp | undefined;
}) => {
  const [message, colour] = (() => {
    if (login?.passwordResetGrantedAt) {
      return ['Password reset triggered', 'blue'];
    } else if (login?.passwordResetRequestedAt) {
      return ['Forgotten password', 'orange'];
    } else if (lastLoginTimestamp) {
      return [
        `Logged in ${formatDistanceToNowStrict(Timestamp.toDate(lastLoginTimestamp), {
          addSuffix: true,
        })}`,
        'green',
      ];
    } else if (!login?.passwordSetAt) {
      return ['Pending set up', 'blue'];
    } else {
      return ['Account set up', 'green'];
    }
  })();

  return (
    <Box
      fontSize="xs"
      py={0.5}
      fontWeight="bold"
      textColor={colour + '.700'}
      background={colour + '.50'}
      textAlign="center"
      whiteSpace="nowrap"
      borderRadius="md"
      width="170px"
      borderColor={colour + '.100'}
      borderWidth="1px"
      my={-1}
    >
      {message}
    </Box>
  );
};

const AutomaticLevelIcon = () => (
  <Tooltip
    label="Sparx has set this level automatically based on their recent activity"
    hasArrow={true}
    placement="top"
    fontSize="md"
    p={2}
    textAlign="center"
    lineHeight="1.3em"
  >
    <Text color="gray.300">
      <FontAwesomeIcon icon={faWandMagicSparkles} />
    </Text>
  </Tooltip>
);

const OverrideLevelIcon = () => (
  <Tooltip
    label="This students level has been manually set"
    hasArrow={true}
    placement="top"
    fontSize="md"
    p={2}
    textAlign="center"
    lineHeight="1.3em"
  >
    <Text color="gray.300">
      <FontAwesomeIcon icon={faLock} />
    </Text>
  </Tooltip>
);
