import { useQuery } from '@tanstack/react-query';
import { queryClient, setQueryStateValue } from 'api/client';
import { getPackageQuery, useLessonPackage } from 'api/packages';
import { LargeLoadingWithText } from 'components/loading/LargeLoading';
import React from 'react';
import { sleepMs } from 'utils/sleep';
import { useLessonDeliveryContext } from 'views/lessondelivery/LessonDeliveryView';

interface SetToActivityProps {
  lessonName: string | undefined;
  activityId: string;
}

export const SetToActivity = ({ lessonName, activityId }: SetToActivityProps) => {
  const { mutateAsync: getLessonPackage } = useLessonPackage(lessonName || '', activityId, {
    suspense: true,
    enabled: Boolean(lessonName),
  });

  const ldc = useLessonDeliveryContext();
  useQuery({
    queryKey: ['lesson', 'setto', activityId],
    queryFn: async () => {
      const pkg = await getLessonPackage();
      if (pkg) {
        const packageId = pkg.name.split('/')[1];
        const fullData = await queryClient.fetchQuery(getPackageQuery(packageId));

        await sleepMs(1000); // Sleep for second so it's not super jumpy

        // Goto first unstarted task - or package view if all done
        for (const task of fullData.package?.contents?.tasks || []) {
          if (
            (task?.state?.completion?.progress?.['C'] || 0) < (task?.state?.completion?.size || 1)
          ) {
            // Go to this task
            const taskParts = task.name.split('/');
            ldc.navigate({
              page: 'task',
              lessonActivityID: activityId,
              taskID: taskParts[3],
              packageID: taskParts[1],
            });

            // Ensure that the box is open when they next view the package list
            setQueryStateValue(`lessonactivity-open-${activityId}`, true);

            return {};
          }
        }
      }
      ldc.navigate({ page: 'landing' });
      return {};
    },
    staleTime: Infinity, // never refresh
    cacheTime: 0, // instantly remove when not needed
  });

  return <LargeLoadingWithText>Setting to task...</LargeLoadingWithText>;
};
