import { Box, Button, Select, Spinner, Text } from '@chakra-ui/react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWatchLesson } from 'api/lessons';
import { useAssignmentInsights } from 'api/packages';
import { useGroupAssignments } from 'api/planner';
import { useWeeks } from 'api/school';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useWeeksWithAssignments } from 'utils/data/assignments';
import { subjects } from 'utils/subjects';
import { ActivityPreparationProps } from 'views/lessons/createactivity/activitytypes';

export const CreateFollowUpFiveActivity = ({
  lessonID,
  doSubmit,
  getFooter,
}: ActivityPreparationProps) => {
  const { data: lessonState } = useWatchLesson(lessonID);
  const groupID = lessonState?.groupNames[0]?.split('/')[3];

  const { data: weeks } = useWeeks({ suspense: true });
  const assignments = useGroupAssignments(groupID, { suspense: true }).filter(
    a => a.generatedTimestamp,
  );
  const weeksWithAssignments = useWeeksWithAssignments(weeks, assignments)
    .filter(a => a.assignments.length > 0)
    .reverse();

  const [selectedAssignment, setSelectedAssignment] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('combined');
  const assignmentID = selectedAssignment.split('/')[1];
  const { data, isLoading, error } = useAssignmentInsights(assignmentID, selectedSubject, {
    enabled: !!assignmentID,
    suspense: false,
  });
  const hasSkills = data && data.skills.length > 0;

  const submit = () => {
    if (hasSkills) {
      doSubmit({
        content: {
          oneofKind: 'insights',
          insights: {
            assignmentName: selectedAssignment,
            subject: selectedSubject,
          },
        },
      });
    }
  };

  if (!groupID) {
    return (
      <Box mx={6} mb={4}>
        There is no group assigned to this lesson
      </Box>
    );
  }

  return (
    <>
      <Box px={6}>
        <Text mb={2}>Select a homework:</Text>
        <Select value={selectedAssignment} onChange={e => setSelectedAssignment(e.target.value)}>
          <option disabled={true} value="">
            Select a homework
          </option>
          {weeksWithAssignments.map(week =>
            week.assignments.map((a, i) => (
              <option key={a.name} value={a.name}>
                Week {week.week.index}
                {week.assignments.length > 1 ? ` (${i + 1})` : ''}
              </option>
            )),
          )}
        </Select>
        <Text mb={2} mt={3}>
          Subject:
        </Text>
        <Select value={selectedSubject} onChange={e => setSelectedSubject(e.target.value)}>
          {subjects.map(subject => (
            <option key={subject.key} value={subject.key}>
              {subject.name}
            </option>
          ))}
        </Select>
        {selectedAssignment && (
          <>
            {isLoading ? (
              <Spinner size="md" color="blue.200" mt={3} />
            ) : error ? (
              <Text pt={3}>Error loading.</Text>
            ) : !hasSkills ? (
              <Text pt={3}>There are no follow up five skills for this homework.</Text>
            ) : (
              <Button
                pt={3}
                variant="link"
                colorScheme="buttonTeal"
                rightIcon={<FontAwesomeIcon icon={faExternalLink} />}
                as={Link}
                target="_blank"
                to={`/teacher/insights/display?${queryString.stringify({
                  assignment: assignmentID,
                  group: groupID,
                  subject: selectedSubject,
                })}`}
              >
                Preview questions
              </Button>
            )}
          </>
        )}
      </Box>
      {getFooter(hasSkills ? submit : undefined)}
    </>
  );
};
