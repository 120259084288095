import {
  faArrowUpRightFromSquare,
  faBars,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlassPlus,
  faMinus,
  faPlay,
  faPlus,
  faStar,
  faTimes,
  faTriangleExclamation,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ReactComponent as CrossComponent } from '../images/Cross.svg';
import { ReactComponent as FlagComponent } from '../images/Flag.svg';
import { ReactComponent as InfoComponent } from '../images/Info_Solid.svg';
import { ReactComponent as PopperComponent } from '../images/Popper.svg';
import { ReactComponent as SkipComponent } from '../images/Skip.svg';
import { ReactComponent as TickComponent } from '../images/Tick.svg';
import { ReactComponent as UserComponent } from '../images/User.svg';
import styles from './Icons.module.css';
import { LoadingSpinner } from './LoadingSpinner';

interface IconProps {
  className?: string;
}

export const TimesIcon = (props: IconProps) => <FontAwesomeIcon icon={faTimes} {...props} />;

export const PlusIcon = (props: IconProps) => <FontAwesomeIcon icon={faPlus} {...props} />;

export const MinusIcon = (props: IconProps) => <FontAwesomeIcon icon={faMinus} {...props} />;

export const VideoIcon = (props: IconProps) => <FontAwesomeIcon icon={faVideo} {...props} />;

export const PlayIcon = (props: IconProps) => <FontAwesomeIcon icon={faPlay} {...props} />;

export const ChevronLeft = (props: IconProps) => (
  <FontAwesomeIcon icon={faChevronLeft} {...props} />
);

export const ZoomInIcon = (props: IconProps) => (
  <FontAwesomeIcon icon={faMagnifyingGlassPlus} {...props} />
);

export const ExternalLinkIcon = (props: IconProps) => (
  <FontAwesomeIcon icon={faArrowUpRightFromSquare} {...props} />
);

export const TriangleExclamationIcon = (props: IconProps) => (
  <FontAwesomeIcon icon={faTriangleExclamation} {...props} />
);

export const LargeLoading = () => (
  <div className={styles.LargeLoading} aria-label="Loading" role="progressbar">
    <LoadingSpinner />
  </div>
);

export const ChevronDown = (props: IconProps) => (
  <FontAwesomeIcon icon={faChevronDown} {...props} />
);

export const ChevronRight = (props: IconProps) => (
  <FontAwesomeIcon icon={faChevronRight} {...props} />
);

export const Bars = (props: IconProps) => <FontAwesomeIcon icon={faBars} {...props} />;

export type TickVariant = 'White' | 'Green' | 'DarkGreen' | 'None' | 'Grey';

export const Tick = ({ variant, className }: { variant: TickVariant; className?: string }) => {
  return <TickComponent className={classNames(styles[variant], className)} />;
};

export type CrossVariant = 'White' | 'Red' | 'None';

export const Cross = ({ variant, className }: { variant: CrossVariant; className?: string }) => {
  return <CrossComponent className={classNames(styles[variant], className)} />;
};

export type FlagVariant = 'White' | 'Purple' | 'None';

export const Flag = ({ variant, className }: { variant: FlagVariant; className?: string }) => {
  return <FlagComponent className={classNames(styles[variant], className)} />;
};

export const CaretRight = (props: IconProps) => <FontAwesomeIcon icon={faCaretRight} {...props} />;

export type StarVariant = 'Grey' | 'GoldHalf' | 'Gold' | 'White';

export const Star = ({ variant }: { variant: StarVariant }) => (
  <FontAwesomeIcon icon={faStar} className={styles[variant]} />
);

export const User = ({ className }: { className?: string }) => {
  return <UserComponent className={className} />;
};

export type InfoVariant = 'Blue';

export const Info = ({ variant, className }: { variant: InfoVariant; className?: string }) => {
  return <InfoComponent className={classNames(styles[variant], className)} />;
};

export const Skip = ({ className }: { className?: string }) => {
  return <SkipComponent className={className} />;
};

export const Popper = ({ className }: { className?: string }) => {
  return <PopperComponent className={className} />;
};
