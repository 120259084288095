import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import {
  faCaretDown,
  faCheck,
  faClipboard,
  faRightFromBracket,
  faShuffle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout, selectSchoolURL } from '@sparx/science/api';
import { useIsInLesson, useLeaveLesson } from '@sparx/science/api/lessons';
import { useSchool } from '@sparx/science/api/school';
import {
  isAnonymousMode,
  setSparxFeatures,
  useSession,
  useUserType,
} from '@sparx/science/api/sessions';
import { BackLink, useNavigationStyle } from '@sparx/science/app/BackLink';
import { ClassSelection } from '@sparx/science/app/ClassSelection';
import { useDebugInfo } from '@sparx/science/app/Debug';
import { Copyable, useCopyableValue } from '@sparx/science/components/copyable/Copyable';
import { SimpleAlert } from '@sparx/science/components/simplealert/SimpleAlert';
import { getUserLandingPage } from '@sparx/science/views/landing/LandingView';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import logo from './sparx_logo.svg';

export const Header = () => {
  const { data: user, isLoading } = useSession();
  const { isTeacher, isActuallySparxStaff } = useUserType();

  const isInLesson = useIsInLesson();
  const debugButtonProps = useDebugInfo();

  const navigationStyle = useNavigationStyle();
  if (navigationStyle === 'hidden') {
    return null;
  }

  return (
    <Flex
      bgGradient="linear(to-r, #f27a2c, #e78e4c, #f7b74b)"
      h={['50px', '60px']}
      display="flex"
      flexShrink={0}
      alignItems="center"
    >
      <BackLink />
      <Box px={[4, 5]}>
        <Link to={isInLesson ? '/lesson' : getUserLandingPage(user)}>
          <Heading color="white" fontSize={23} as="h1">
            <Image src={logo} alt="Sparx Teaching" height={['20px', '27px']} mt={['3px', '4px']} />
          </Heading>
        </Link>
      </Box>
      {isTeacher && <ClassSelection />}
      <Spacer />
      <Box px={2} display="flex" alignItems="center">
        <div id="header-portal" />
        <Menu>
          <MenuButton
            {...debugButtonProps}
            as={Button}
            colorScheme="blackAlpha"
            rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
            isLoading={isLoading}
            borderWidth={2}
            borderColor="blackAlpha.200"
            size={['sm', 'md']}
            whiteSpace="nowrap"
            position="relative"
            data-sentry-mask
          >
            <Text as="span" display={['inline', 'none']} mr={1}>
              <FontAwesomeIcon icon={faUser} />
            </Text>
            <Text as="span" display={['none', 'inline']}>
              {user?.givenName}
            </Text>{' '}
            <Text as="span" display={['none', 'none', 'inline']}>
              {user?.familyName}
            </Text>
          </MenuButton>
          <MenuList zIndex={20}>
            {isTeacher && <CurrentSchool />}
            {isInLesson && <LessonLeaveMenuItem />}
            {isActuallySparxStaff && <CopyLinkMenuItem />}
            {isActuallySparxStaff && <SelectSchoolMenuItem />}
            <SignOutMenuItem />
            {isActuallySparxStaff && <SparxFeatures />}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

const SparxFeatures = () => {
  const { sparxFeaturesEnabled } = useUserType();

  return (
    <Box pl={4} mr={3} mb={1} mt={2}>
      <Text fontSize="sm" color="gray.500">
        Sparx Features
      </Text>
      <Flex mt={1}>
        <Switch
          isChecked={sparxFeaturesEnabled}
          onChange={() => setSparxFeatures(!sparxFeaturesEnabled)}
        />
        <Spacer />
        <Copyable
          value={import.meta.env.VITE_RELEASE_VERSION || 'Unknown'}
          displayValue={(import.meta.env.VITE_RELEASE_VERSION || 'Unknown').slice(0, 7)}
        />
      </Flex>
    </Box>
  );
};

const CurrentSchool = () => {
  const { data: school, isLoading: isLoadingSchool } = useSchool({ suspense: false });

  return (
    <Box px={4} mb={4} mt={1}>
      <Text fontSize="sm" color="gray.500">
        Logged into
      </Text>
      <Skeleton isLoaded={!isLoadingSchool}>
        <Text fontWeight="bold" color="gray.600" lineHeight="1em" maxWidth="200px">
          {isAnonymousMode() ? 'Demo School' : school?.displayName}
        </Text>
      </Skeleton>
    </Box>
  );
};

const LessonLeaveMenuItem = () => {
  const { mutateAsync } = useLeaveLesson();

  return (
    <>
      <SimpleAlert
        header="Leave lesson"
        body="Are you sure you want to leave this lesson?"
        onConfirm={mutateAsync}
        confirmText="Leave lesson"
      >
        {onOpen => (
          <MenuItem
            onClick={onOpen}
            icon={
              <Text color="red.500">
                <FontAwesomeIcon icon={faRightFromBracket} fixedWidth={true} />
              </Text>
            }
          >
            Leave lesson
          </MenuItem>
        )}
      </SimpleAlert>
      <MenuDivider />
    </>
  );
};

const SignOutMenuItem = () => (
  <SimpleAlert
    header="Sign out"
    body="Are you sure you want to sign out of Sparx Teaching?"
    onConfirm={logout}
    confirmText="Sign out"
  >
    {onOpen => (
      <MenuItem
        onClick={onOpen}
        icon={
          <Text color="orange.600">
            <FontAwesomeIcon icon={faRightFromBracket} fixedWidth={true} />
          </Text>
        }
      >
        Sign out
      </MenuItem>
    )}
  </SimpleAlert>
);

const SelectSchoolMenuItem = () => (
  <MenuItem
    as={Link}
    to={`${selectSchoolURL}/?app=sparx_teaching&noredirect=1`}
    closeOnSelect={false}
    icon={
      <Text color="orange.600">
        <FontAwesomeIcon icon={faShuffle} fixedWidth={true} />
      </Text>
    }
  >
    Switch school
  </MenuItem>
);

const CopyLinkMenuItem = () => {
  const { data: school } = useSchool({ suspense: false });
  const { copied, onCopy } = useCopyableValue(() => {
    const url = new URL(window.location.href);
    url.searchParams.append('school', school?.name?.replace('schools/', '') ?? '');
    return url.toString();
  });

  return (
    <MenuItem
      onClick={onCopy}
      closeOnSelect={false}
      icon={
        <Text color={copied ? 'green.500' : 'orange.600'}>
          <FontAwesomeIcon icon={copied ? faCheck : faClipboard} fixedWidth={true} />
        </Text>
      }
    >
      Copy link in school
    </MenuItem>
  );
};

const getPortalContainer = () => document.createElement('div');

export const HeaderPortal = ({ children }: PropsWithChildren) => {
  const [container] = useState(getPortalContainer());
  useEffect(() => {
    // Find the root element in your DOM
    const modalRoot = document.getElementById('header-portal');
    if (!modalRoot) {
      return; // can't do anything, header is not mounted
    }
    // Append container to root
    modalRoot.prepend(container);
    return () => {
      // On cleanup remove the container
      if (modalRoot) {
        modalRoot.removeChild(container);
      }
    };
  }, [container]);

  return createPortal(children, container);
};
