// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/teamteaching.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { LiveKitToken } from './meet';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonConferenceState
 */
export interface LessonConferenceState {
  /**
   * @generated from protobuf field: string view = 1;
   */
  view: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LessonStudentCall student_call = 2;
   */
  studentCall?: LessonStudentCall;
  /**
   * @generated from protobuf field: bool require_microphone = 3;
   */
  requireMicrophone: boolean;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.WhiteboardState whiteboard_state = 5;
   */
  whiteboardState?: WhiteboardState;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentAudioState student_audio_state = 6;
   */
  studentAudioState?: StudentAudioState;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.WhiteboardState
 */
export interface WhiteboardState {
  /**
   * When not empty string then use this as the whiteboard activity id and show the whiteboard.
   *
   * @generated from protobuf field: string whiteboard_activity_id = 1;
   */
  whiteboardActivityId: string;
  /**
   * ID of the asset to use in the background image.
   *
   * @generated from protobuf field: string background_asset_id = 2;
   */
  backgroundAssetId: string;
  /**
   * When there are values in this list, then the whiteboard will only be delivered to the
   * students in the list.
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
  /**
   * Latest rating of the whiteboard. We only store one here as the main advantage is
   * it being on the event. TODO: probably move this into a separate event in the future
   *
   * @generated from protobuf field: sparx.science.lessons.v1.WhiteboardRating latest_rating = 4;
   */
  latestRating?: WhiteboardRating;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.WhiteboardRating
 */
export interface WhiteboardRating {
  /**
   * User that created the rating.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Rating value between 0-100.
   *
   * @generated from protobuf field: int32 rating = 2;
   */
  rating: number;
  /**
   * Timestamp the rating was given.
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentAudioState
 */
export interface StudentAudioState {
  /**
   * @generated from protobuf field: repeated string student_names = 1;
   */
  studentNames: string[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonStudentCall
 */
export interface LessonStudentCall {
  /**
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentCallLayout layout = 3;
   */
  layout: StudentCallLayout;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LiveKitToken livekit_token = 4;
   */
  livekitToken?: LiveKitToken;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentCallTrack visible_track = 5;
   */
  visibleTrack: StudentCallTrack;
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.StudentCallLayout
 */
export enum StudentCallLayout {
  /**
   * @generated from protobuf enum value: STUDENT_CALL_LAYOUT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_CALL_LAYOUT_FULLSCREEN = 1;
   */
  FULLSCREEN = 1,
  /**
   * @generated from protobuf enum value: STUDENT_CALL_LAYOUT_SIDEBAR = 2;
   */
  SIDEBAR = 2,
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.StudentCallTrack
 */
export enum StudentCallTrack {
  /**
   * @generated from protobuf enum value: STUDENT_CALL_TRACK_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_CALL_TRACK_WEBCAM = 1;
   */
  WEBCAM = 1,
  /**
   * @generated from protobuf enum value: STUDENT_CALL_TRACK_VISUALISER = 2;
   */
  VISUALISER = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class LessonConferenceState$Type extends MessageType<LessonConferenceState> {
  constructor() {
    super('sparx.science.lessons.v1.LessonConferenceState', [
      { no: 1, name: 'view', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_call',
        kind: 'message',
        T: () => LessonStudentCall,
      },
      {
        no: 3,
        name: 'require_microphone',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'whiteboard_state',
        kind: 'message',
        T: () => WhiteboardState,
      },
      {
        no: 6,
        name: 'student_audio_state',
        kind: 'message',
        T: () => StudentAudioState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonConferenceState
 */
export const LessonConferenceState = new LessonConferenceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WhiteboardState$Type extends MessageType<WhiteboardState> {
  constructor() {
    super('sparx.science.lessons.v1.WhiteboardState', [
      {
        no: 1,
        name: 'whiteboard_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'background_asset_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'latest_rating',
        kind: 'message',
        T: () => WhiteboardRating,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.WhiteboardState
 */
export const WhiteboardState = new WhiteboardState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WhiteboardRating$Type extends MessageType<WhiteboardRating> {
  constructor() {
    super('sparx.science.lessons.v1.WhiteboardRating', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'rating', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.WhiteboardRating
 */
export const WhiteboardRating = new WhiteboardRating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentAudioState$Type extends MessageType<StudentAudioState> {
  constructor() {
    super('sparx.science.lessons.v1.StudentAudioState', [
      {
        no: 1,
        name: 'student_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentAudioState
 */
export const StudentAudioState = new StudentAudioState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonStudentCall$Type extends MessageType<LessonStudentCall> {
  constructor() {
    super('sparx.science.lessons.v1.LessonStudentCall', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'layout',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.StudentCallLayout',
          StudentCallLayout,
          'STUDENT_CALL_LAYOUT_',
        ],
      },
      { no: 4, name: 'livekit_token', kind: 'message', T: () => LiveKitToken },
      {
        no: 5,
        name: 'visible_track',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.StudentCallTrack',
          StudentCallTrack,
          'STUDENT_CALL_TRACK_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonStudentCall
 */
export const LessonStudentCall = new LessonStudentCall$Type();
