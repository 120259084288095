import { useEffect, useState } from 'react';

export const useMovedMouse = (timeoutMs = 5000) => {
  const [lastMoved, setLastMoved] = useState(Date.now());
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHidden(true);
    }, timeoutMs);

    return () => {
      clearTimeout(timeout);
      setHidden(false);
    };
  }, [timeoutMs, setHidden, lastMoved]);
  const onMouseMove = () => Date.now() - lastMoved > 1000 && setLastMoved(Date.now());

  return { onMouseMove, movedRecently: !hidden };
};
