import { Box, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

export type LessonPanel =
  | {
      type: 'taskitem';
      taskItem: string;
      taskIndex: number;
      studentId: string;
      assignmentId?: string;
    }
  | {
      type: 'assignmenttaskitem';
      assignmentId: string;
      taskIndex: number;
      taskItemIndex: number;
    }
  | {
      type: 'surveytaskitem';
      assignmentId: string;
      taskIndex: number;
      taskItemIndex: number;
    }
  | undefined;

interface PanelProps {
  title?: React.ReactNode;
  right?: React.ReactNode;
  onClose: () => void;
}

export const Panel = ({ title, children, right, onClose }: PropsWithChildren<PanelProps>) => (
  <Box
    as={motion.div}
    initial={{ width: 0, opacity: 0 }}
    animate={{ width: 'auto', opacity: 1 }}
    exit={{ width: 0, opacity: 0 }}
    bg="white"
    boxShadow="md"
    borderRadius="md"
    alignSelf="stretch"
    p={1}
    mr={4}
    display="flex"
    flexDirection="column"
    overflow="hidden"
  >
    <Flex bg="blue.700" p={1} pl={3} mb={1} borderRadius="md" color="white" alignItems="center">
      {title}
      <Spacer />
      {right}
      <IconButton aria-label="close" size="sm" colorScheme="blackAlpha" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
    </Flex>
    <Box width="30vw" overflowY="auto" height="100%">
      {children}
    </Box>
  </Box>
);
