import {
  UserInteractionClientMessage,
  UserInteractionEvent,
} from '@sparx/api/genproto/apis/uievents/uievents';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'api/client';
import { useSession } from 'api/sessions';
import React, { createContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sleepMs } from 'utils/sleep';
import { v4 as uuid } from 'uuid';

type DefaultFields =
  | 'application'
  | 'schoolId'
  | 'userId'
  | 'sessionId'
  | 'connectionId'
  | 'version'
  | 'serverTimestamp';

type AutomaticFields = 'timestamp' | 'eventIndex' | 'page';

type UserInteractionEventDefaults = Pick<UserInteractionEvent, DefaultFields>;
type UserInteractionEventFields = Omit<UserInteractionEvent, DefaultFields | AutomaticFields>;

interface SendEventOptions {
  immediate?: boolean;
}

interface EventContextValues {
  sendEvent: (
    event: { action: string; category: string },
    labels?: {
      [key: string]: string;
    },
    opts?: SendEventOptions,
  ) => void;
  ready: boolean;
}

const EventContext = createContext<EventContextValues>({
  sendEvent: (event, labels) => {
    const logger = window.settings?.ENV_NAME === 'dev' ? console.debug : console.error;
    logger('Attempting to send client event without context provider', event, labels);
  },
  ready: false,
});

interface ClientEventProviderProps {
  children: React.ReactNode;
  pushURL: string | undefined;
}

export const ClientEventProvider = ({ children, pushURL }: ClientEventProviderProps) => {
  const { data: session } = useSession();
  const location = useLocation();

  useQuery({
    queryKey: ['eventpump'],
    queryFn: async () => {
      await sleepMs(5000);
      const request: UserInteractionClientMessage = {
        metrics: loadClientEvents(),
        timestamp: Timestamp.now(),
      };
      if (request.metrics.length > 0) {
        const response = await fetch(pushURL || '', {
          body: UserInteractionClientMessage.toJsonString(request),
          method: 'POST',
        });
        if (response.status !== 500) {
          setClientEvents([]);
        }
        if (!response.ok) {
          throw response;
        }
      }
      return true;
    },
    onSuccess: () => queryClient.invalidateQueries(['eventpump']),
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: Boolean(pushURL),
  });

  const connectionId = useMemo(() => uuid(), []);
  const sessionId = useMemo(() => getSessionId(), []);
  const [eventIndex, setEventIndex] = useState(0);

  const defaultFields: UserInteractionEventDefaults = useMemo(
    () => ({
      application: 'science_' + (session?.userType === 'student' ? 'student' : 'teacher'),
      schoolId: session?.schoolId || '',
      userId: session?.sparxUserId || session?.userId || '',
      sessionId: sessionId,
      serverTimestamp: undefined, // unused
      connectionId,
      version: import.meta.env.VITE_RELEASE_VERSION || '',
    }),
    [session, connectionId],
  );

  const sendImmediateEvent = (event: UserInteractionEventFields) => {
    fetch(pushURL || '', {
      body: UserInteractionClientMessage.toJsonString({
        metrics: [buildUserInteractionEvent(event)],
        timestamp: Timestamp.now(),
      }),
      method: 'POST',
      keepalive: true, // ensure send as page navigate away
    }).catch(e => {
      console.error('Failed to send page event', e);
    });
  };

  const buildUserInteractionEvent = (event: UserInteractionEventFields): UserInteractionEvent => {
    // Increment the event index
    const index = eventIndex;
    setEventIndex(i => i + 1);

    return {
      ...defaultFields,
      timestamp: Timestamp.now(),
      eventIndex: index,
      page: location.pathname,
      ...event,
    };
  };

  const sendEvent = (event: UserInteractionEventFields, opts?: SendEventOptions) => {
    if (opts?.immediate) {
      sendImmediateEvent(event);
      return;
    }
    if (!defaultFields.userId) return; // not initialised

    // Store the event
    const clientEvents = loadClientEvents();
    setClientEvents(clientEvents.concat([buildUserInteractionEvent(event)]));
  };

  // No provider if no push url provided
  if (!pushURL) return <>{children}</>;

  return (
    <EventContext.Provider
      value={{
        sendEvent: (event, labels = {}, opts) => sendEvent({ ...event, labels }, opts),
        ready: true,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useClientEvent = () => React.useContext(EventContext);

const clientEventsLocalStorageKey = 'sci/clientevents';
const clientEventsMaxLocal = 30;

const loadClientEvents = (): UserInteractionEvent[] => {
  const store = localStorage.getItem(clientEventsLocalStorageKey);
  let events = [];
  try {
    const parsed = JSON.parse(store || '[]');
    events = parsed || [];
    if (events.length > clientEventsMaxLocal) {
      throw new Error('local storage events exceeded maximum, discarding');
    }
  } catch (e) {
    console.error('Failed to parse client events, clearing', e);
    localStorage.removeItem(clientEventsLocalStorageKey);
  }
  return events;
};

const setClientEvents = (events: UserInteractionEvent[]) => {
  try {
    localStorage.setItem(clientEventsLocalStorageKey, JSON.stringify(events));
  } catch (e) {
    console.error('Failed to store client events', e);
  }
};

const localStorageSessionIdKey = 'sci/sessionid';

const getSessionId = () => {
  if (!localStorage) return 'no-local-storage'; // exit early just in case

  let sessionId = localStorage.getItem(localStorageSessionIdKey);
  if (!sessionId) {
    sessionId = uuid();
    localStorage.setItem(localStorageSessionIdKey, sessionId);
  }
  return sessionId;
};
