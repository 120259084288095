import { createContext, PropsWithChildren, useContext, useState } from 'react';

interface Context {
  showCorrect: boolean;
  setShowCorrect: (show: boolean) => void;
}

const context = createContext<Context>({
  showCorrect: false,
  setShowCorrect: () => {
    /* empty */
  },
});

export const useTaskItemPanelContext = () => useContext(context);

export const TaskItemPanelContext = ({ children }: PropsWithChildren) => {
  const [showCorrect, setShowCorrect] = useState(false);

  return <context.Provider value={{ showCorrect, setShowCorrect }}>{children}</context.Provider>;
};
