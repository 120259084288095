import { Button } from '@chakra-ui/react';
import React from 'react';
import { useLessonViewContext } from 'views/lessons/LessonView';

export const ClearStudentSelectionButton = () => {
  const { selectedStudents, clearSelectedStudents } = useLessonViewContext();
  const selectedStudentCount = selectedStudents.size;
  if (selectedStudentCount > 0) {
    return (
      <Button colorScheme="buttonTeal" variant="outline" onClick={clearSelectedStudents}>
        Clear selection
      </Button>
    );
  }
  return null;
};
