import { createContext, useContext } from 'react';

import { FilterFields } from './TopicSearch';

interface Context {
  filter: FilterFields;
  setFilter: (filter: Partial<FilterFields>) => void;
}

const context = createContext<Context | null>(null);

export const useTopicSearchContext = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw new Error('useTopicSearchContext must be used within a TopicSearchContextProvider');
  }
  return ctx;
};

export const TopicSearchContextProvider = context.Provider;
