import { AnimatePresence, AnimationDefinition, motion } from 'framer-motion';
import { forwardRef, PropsWithChildren } from 'react';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';

const isAnimationToFullOpacity = (def: AnimationDefinition) =>
  typeof def === 'object' && !Array.isArray(def) && def.opacity === 1;

/**
 * VerticalSwipeEntryExit wraps the passed children with a vertical swipe animation on enter and exit
 * If height is provided, the element is animated to that height (in px), otherwise, it is animated
 * to 'auto'
 */
export const VerticalSwipeEntryExit = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    transitionKey: string;
    height?: number | 'auto';
    onAnimInComplete?: () => void;
  }>
>(({ transitionKey, children, height = 'auto', onAnimInComplete }, ref) => {
  const { setRecalculateScaleTrigger, setIsWaitingForAnimation } = useSparxQuestionContext();
  const onAnimationComplete = (def: AnimationDefinition) => {
    setRecalculateScaleTrigger && setRecalculateScaleTrigger();

    const isAnimateIn = isAnimationToFullOpacity(def);
    if (onAnimInComplete && isAnimateIn) {
      onAnimInComplete();
    }

    setIsWaitingForAnimation(false);
  };

  return (
    <AnimatePresence>
      {children && (
        <motion.div
          ref={ref}
          key={transitionKey}
          initial={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
          animate={{ height: height, opacity: 1, marginTop: '1em', marginBottom: '1em' }}
          exit={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
          transition={{ duration: 0.2 }}
          className={styles.VerticalSwipeEntryExit}
          onAnimationComplete={onAnimationComplete}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});

/**
 * HorizontalSwipeEntryExit wraps the passed children with a vertical swipe animation on enter and exit
 * If height or width are provided, the element is animated to that height or width (in px), otherwise,
 * it is animated to 'auto'
 */
export const HorizontalSwipeEntryExit = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    transitionKey: string;
    width?: number | 'auto';
    height?: number | 'auto';
    onAnimInComplete?: () => void;
  }>
>(({ transitionKey, children, width = 'auto', height = 'auto', onAnimInComplete }, ref) => {
  const { setRecalculateScaleTrigger, setIsWaitingForAnimation } = useSparxQuestionContext();
  const onAnimationComplete = (def: AnimationDefinition) => {
    setRecalculateScaleTrigger && setRecalculateScaleTrigger();

    const isAnimateIn = isAnimationToFullOpacity(def);
    if (onAnimInComplete && isAnimateIn) {
      onAnimInComplete();
    }

    setIsWaitingForAnimation(false);
  };

  return (
    <AnimatePresence>
      {children && (
        <motion.div
          ref={ref}
          key={transitionKey}
          initial={{ width: 0, height: 0, opacity: 0, marginLeft: 0 }}
          animate={{ width: width, height: height, opacity: 1, marginLeft: '1em' }}
          exit={{ width: 0, height: 0, opacity: 0, marginLeft: 0 }}
          transition={{ duration: 0.2 }}
          className={styles.HorizontalSwipeEntryExit}
          onAnimationComplete={onAnimationComplete}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});
