import { Box, Container, Portal } from '@chakra-ui/react';
import React, { MutableRefObject, PropsWithChildren, useRef } from 'react';

interface PageContainerContextValues {
  scrollContainer?: HTMLDivElement;
  outerRef?: MutableRefObject<HTMLDivElement | null>;
  isSubContainer?: boolean;
}

export const PageContainerContext = React.createContext<PageContainerContextValues>({});

export const usePageContainerContext = () => React.useContext(PageContainerContext);

interface PageContainerProps {
  maxWidth?: number;
}

export const PageContainer = ({
  children,
  maxWidth = 1200,
}: PropsWithChildren<PageContainerProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box
      flexDirection="column"
      display="flex"
      ref={portalRef}
      position="absolute"
      height="100%"
      width="100%"
    >
      <Box position="relative" flex={1}>
        <Box
          ref={ref}
          height="100%"
          width="100%"
          flexDirection="column"
          display="flex"
          position="absolute"
          overflowY="auto"
        >
          <PageContainerContext.Provider
            value={{ scrollContainer: ref.current ?? undefined, outerRef: portalRef }}
          >
            <Container maxW={maxWidth} flex={1} py={[2, 4]} px={[4, 6]}>
              {children}
            </Container>
          </PageContainerContext.Provider>
        </Box>
      </Box>
    </Box>
  );
};

export const PageFooter = ({ children, height = 20 }: PropsWithChildren<{ height?: number }>) => {
  const { outerRef } = usePageContainerContext();
  const footer = (
    <Box
      backgroundColor="white"
      h={height}
      width="100%"
      position="relative"
      display="flex"
      alignItems="center"
    >
      <Box
        position="absolute"
        height={3}
        top={-3}
        left="0"
        right="0"
        bgGradient="linear(to-b, transparent, blackAlpha.100)"
      />
      <Container maxW={1200} flex={1} display="flex" justifyContent="flex-end">
        {children}
      </Container>
    </Box>
  );

  if (outerRef) {
    return <Portal containerRef={outerRef}>{footer}</Portal>;
  }
  return footer;
};
