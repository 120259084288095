import { RpcError } from '@protobuf-ts/runtime-rpc';
import { QueryClient, useQuery } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Default retry handler for queries which will not retry if there
      // is a NOT_FOUND or INVALID_ARGUMENT error returned.
      retry: (retry, error) => {
        if (error instanceof RpcError) {
          if (error.code === 'NOT_FOUND' || error.code === 'INVALID_ARGUMENT') {
            return false; // don't retry not found or invalid argument
          }
        }
        return retry < 3; // allow 3 retries
      },
    },
  },
});

/**
 * Small utility that acts like useState but stores the value in the QueryClient
 * so that it will persist across page navigations. The values are cached indefinitely.
 * @param key The key to store the value under
 * @param defaultValue The default value to use if the key is not found
 */
export const useQueryState = <T>(key: string, defaultValue: T): [T, (v: T) => void] => {
  const { data } = useQuery({
    queryKey: ['_state', key],
    queryFn: () => defaultValue,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  return [data ?? defaultValue, (value: T) => queryClient.setQueryData(['_state', key], value)];
};

export const setQueryStateValue = (key: string, value: any) =>
  queryClient.setQueryData(['_state', key], value);
