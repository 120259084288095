import { useQuery } from '@tanstack/react-query';
import { validate as validateUUID } from 'uuid';

import { useSparxQuestionContext } from '../question/SparxQuestionContext';

export const useAssetUrl = (src: string) => {
  const ctx = useSparxQuestionContext();

  const {
    data: url,
    isLoading,
    isError,
  } = useQuery(['@sparx/question', src], () => (ctx.getAssetUrl || defaultAssetUrlGetter)(src), {
    staleTime: Infinity,
    cacheTime: 0,
    retry: false,
    onError: err => {
      console.error('Failed to get asset url:', src, err);
    },
  });

  return { url, isLoading, isError };
};

const defaultAssetUrlGetter = (src: string) => {
  if (validateUUID(src)) {
    return 'https://assets.sparxhomework.uk/' + src;
  }
  return src;
};
