// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/groupsapi/v1/groupsapi.proto" (package "sparx.teacherportal.groupsapi.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { YearGroup } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { HomeworkPlan } from '../../../planning/v1/plan';
import { StaffClassMembership } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { AttainmentLevel } from '../../../personalisation/attainmentlevel/v1/attainmentlevel';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { StudentGroupType } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.Group
 */
export interface Group {
  /**
   * Resource name for the group -
   * schools/{school_id}/studentGroups/{student_group_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Display name for the group
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * MIS ID for the group
   *
   * @generated from protobuf field: string mis_teaching_group_id = 3;
   */
  misTeachingGroupId: string;
  /**
   * Year group ID
   *
   * @generated from protobuf field: string year_group_id = 4;
   */
  yearGroupId: string;
  /**
   * External ID
   *
   * @generated from protobuf field: string external_id = 5;
   */
  externalId: string;
  /**
   * Type of group, e.g Class
   *
   * @generated from protobuf field: schoolman.StudentGroupType type = 6;
   */
  type: StudentGroupType;
  /**
   * Read only. When the group is locked it cannot be interacted with, and
   * homework will not be set. Student's can still log in to a locked group.
   *
   * @generated from protobuf field: bool locked = 13;
   */
  locked: boolean;
  /**
   * Group start date
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 7;
   */
  startDate?: Timestamp;
  /**
   * Group end date
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 8;
   */
  endDate?: Timestamp;
  /**
   * Attainment level for the group
   *
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel attainment_level = 9;
   */
  attainmentLevel: AttainmentLevel;
  /**
   * The attainment level of the class, used in classroom for
   * suggested objectives when building a lesson
   *
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel classroom_attainment_level = 10;
   */
  classroomAttainmentLevel: AttainmentLevel;
  /**
   * Staff associated with the group
   *
   * @generated from protobuf field: repeated schoolman.StaffClassMembership staff = 11;
   */
  staff: StaffClassMembership[];
  /**
   * Homework plan associated with the group
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlan homework_plan = 12;
   */
  homeworkPlan?: HomeworkPlan;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.GetGroupRequest
 */
export interface GetGroupRequest {
  /**
   * Resource name for the group -
   * schools/{school_id}/studentGroups/{student_group_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Include the homework plan in the response
   *
   * @generated from protobuf field: bool include_homework_plan = 2;
   */
  includeHomeworkPlan: boolean;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.ListGroupsRequest
 */
export interface ListGroupsRequest {
  /**
   * schools/{school_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Include the homework plan in the response
   *
   * @generated from protobuf field: bool include_homework_plans = 2;
   */
  includeHomeworkPlans: boolean;
  /**
   * Optional IDs for the groups to be listed
   *
   * @generated from protobuf field: repeated string group_ids = 3;
   */
  groupIds: string[];
  /**
   * Include archived groups in the response
   *
   * @generated from protobuf field: bool include_archived = 4;
   */
  includeArchived: boolean;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.ListGroupsResponse
 */
export interface ListGroupsResponse {
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.groupsapi.v1.Group groups = 1;
   */
  groups: Group[];
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.CreateGroupRequest
 */
export interface CreateGroupRequest {
  /**
   * schools/{school_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: sparx.teacherportal.groupsapi.v1.Group group = 2;
   */
  group?: Group;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.UpdateGroupRequest
 */
export interface UpdateGroupRequest {
  /**
   * @generated from protobuf field: sparx.teacherportal.groupsapi.v1.Group group = 1;
   */
  group?: Group;
  /**
   * @generated from protobuf field: google.protobuf.FieldMask field_mask = 2;
   */
  fieldMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsRequest
 */
export interface BatchUpdateGroupsRequest {
  /**
   * schools/{school_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.groupsapi.v1.UpdateGroupRequest requests = 2;
   */
  requests: UpdateGroupRequest[];
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsResponse
 */
export interface BatchUpdateGroupsResponse {
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.groupsapi.v1.Group groups = 1;
   */
  groups: Group[];
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.DeleteGroupRequest
 */
export interface DeleteGroupRequest {
  /**
   * Resource name for the group -
   * schools/{school_id}/studentGroups/{student_group_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.ListYearGroupsRequest
 */
export interface ListYearGroupsRequest {
  /**
   * The school name to get the year group for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.groupsapi.v1.ListYearGroupsResponse
 */
export interface ListYearGroupsResponse {
  /**
   * The year groups for the given school name.
   *
   * @generated from protobuf field: repeated schoolman.YearGroup year_groups = 1;
   */
  yearGroups: YearGroup[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Group$Type extends MessageType<Group> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.Group', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'mis_teaching_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'year_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'external_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.StudentGroupType', StudentGroupType],
      },
      { no: 13, name: 'locked', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'end_date', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'attainment_level',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 10,
        name: 'classroom_attainment_level',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 11,
        name: 'staff',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffClassMembership,
      },
      { no: 12, name: 'homework_plan', kind: 'message', T: () => HomeworkPlan },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.Group
 */
export const Group = new Group$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGroupRequest$Type extends MessageType<GetGroupRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.GetGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'include_homework_plan',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.GetGroupRequest
 */
export const GetGroupRequest = new GetGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupsRequest$Type extends MessageType<ListGroupsRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.ListGroupsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'include_homework_plans',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'include_archived',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.ListGroupsRequest
 */
export const ListGroupsRequest = new ListGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupsResponse$Type extends MessageType<ListGroupsResponse> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.ListGroupsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Group,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.ListGroupsResponse
 */
export const ListGroupsResponse = new ListGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateGroupRequest$Type extends MessageType<CreateGroupRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.CreateGroupRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'group', kind: 'message', T: () => Group },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.CreateGroupRequest
 */
export const CreateGroupRequest = new CreateGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGroupRequest$Type extends MessageType<UpdateGroupRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.UpdateGroupRequest', [
      { no: 1, name: 'group', kind: 'message', T: () => Group },
      { no: 2, name: 'field_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.UpdateGroupRequest
 */
export const UpdateGroupRequest = new UpdateGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateGroupsRequest$Type extends MessageType<BatchUpdateGroupsRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UpdateGroupRequest,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsRequest
 */
export const BatchUpdateGroupsRequest = new BatchUpdateGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateGroupsResponse$Type extends MessageType<BatchUpdateGroupsResponse> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Group,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsResponse
 */
export const BatchUpdateGroupsResponse = new BatchUpdateGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteGroupRequest$Type extends MessageType<DeleteGroupRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.DeleteGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.DeleteGroupRequest
 */
export const DeleteGroupRequest = new DeleteGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListYearGroupsRequest$Type extends MessageType<ListYearGroupsRequest> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.ListYearGroupsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.ListYearGroupsRequest
 */
export const ListYearGroupsRequest = new ListYearGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListYearGroupsResponse$Type extends MessageType<ListYearGroupsResponse> {
  constructor() {
    super('sparx.teacherportal.groupsapi.v1.ListYearGroupsResponse', [
      {
        no: 1,
        name: 'year_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => YearGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.groupsapi.v1.ListYearGroupsResponse
 */
export const ListYearGroupsResponse = new ListYearGroupsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.teacherportal.groupsapi.v1.GroupsAPI
 */
export const GroupsAPI = new ServiceType(
  'sparx.teacherportal.groupsapi.v1.GroupsAPI',
  [
    {
      name: 'CreateGroup',
      options: {
        'sparx.api.auth': {
          action: 'create',
          resource: 'tp:studentgroup',
          domain: '{parent}',
        },
      },
      I: CreateGroupRequest,
      O: Group,
    },
    {
      name: 'GetGroup',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:studentgroup',
          domain: '{name|parent}',
        },
      },
      I: GetGroupRequest,
      O: Group,
    },
    {
      name: 'UpdateGroup',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'tp:studentgroup',
          domain: '{group.name|parent}',
        },
      },
      I: UpdateGroupRequest,
      O: Group,
    },
    {
      name: 'ListGroups',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:studentgroup',
          domain: '{parent}',
        },
      },
      I: ListGroupsRequest,
      O: ListGroupsResponse,
    },
    {
      name: 'BatchUpdateGroups',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'tp:studentgroup',
          domain: '{parent}',
        },
      },
      I: BatchUpdateGroupsRequest,
      O: BatchUpdateGroupsResponse,
    },
    {
      name: 'DeleteGroup',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'tp:studentgroup',
          domain: '{name|parent}',
        },
      },
      I: DeleteGroupRequest,
      O: Empty,
    },
    {
      name: 'ListYearGroups',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:studentgroup',
          domain: '{school_name}',
        },
      },
      I: ListYearGroupsRequest,
      O: ListYearGroupsResponse,
    },
  ]
);
