// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teaching/kiosk/v1/kiosk.proto" (package "sparx.teaching.kiosk.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Kiosk } from './kiosk';
import type { RemoveKioskResponse } from './kiosk';
import type { RemoveKioskRequest } from './kiosk';
import type { UpdateKioskSettingsResponse } from './kiosk';
import type { UpdateKioskSettingsRequest } from './kiosk';
import type { WatchKioskResponse } from './kiosk';
import type { WatchKioskRequest } from './kiosk';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { UpdateKioskStateResponse } from './kiosk';
import type { UpdateKioskStateRequest } from './kiosk';
import type { AuthenticateKioskResponse } from './kiosk';
import type { AuthenticateKioskRequest } from './kiosk';
import type { ListKiosksResponse } from './kiosk';
import type { ListKiosksRequest } from './kiosk';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { CreateNewKioskResponse } from './kiosk';
import type { CreateNewKioskRequest } from './kiosk';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.teaching.kiosk.v1.Kiosk
 */
export interface IKioskClient {
  /**
   * CreateNewKiosk creates a new token that the kiosk can use to authenticate itself.
   * The request will block until the token is used.
   *
   * @generated from protobuf rpc: CreateNewKiosk(sparx.teaching.kiosk.v1.CreateNewKioskRequest) returns (sparx.teaching.kiosk.v1.CreateNewKioskResponse);
   */
  createNewKiosk(
    input: CreateNewKioskRequest,
    options?: RpcOptions
  ): UnaryCall<CreateNewKioskRequest, CreateNewKioskResponse>;
  /**
   * @generated from protobuf rpc: ListKiosks(sparx.teaching.kiosk.v1.ListKiosksRequest) returns (sparx.teaching.kiosk.v1.ListKiosksResponse);
   */
  listKiosks(
    input: ListKiosksRequest,
    options?: RpcOptions
  ): UnaryCall<ListKiosksRequest, ListKiosksResponse>;
  /**
   * AuthenticateKiosk authenticates the kiosk with the given token.
   *
   * @generated from protobuf rpc: AuthenticateKiosk(sparx.teaching.kiosk.v1.AuthenticateKioskRequest) returns (sparx.teaching.kiosk.v1.AuthenticateKioskResponse);
   */
  authenticateKiosk(
    input: AuthenticateKioskRequest,
    options?: RpcOptions
  ): UnaryCall<AuthenticateKioskRequest, AuthenticateKioskResponse>;
  /**
   * UpdateKioskState is called by an authenticated kiosk user
   *
   * @generated from protobuf rpc: UpdateKioskState(sparx.teaching.kiosk.v1.UpdateKioskStateRequest) returns (sparx.teaching.kiosk.v1.UpdateKioskStateResponse);
   */
  updateKioskState(
    input: UpdateKioskStateRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateKioskStateRequest, UpdateKioskStateResponse>;
  /**
   * @generated from protobuf rpc: WatchKiosk(sparx.teaching.kiosk.v1.WatchKioskRequest) returns (stream sparx.teaching.kiosk.v1.WatchKioskResponse);
   */
  watchKiosk(
    input: WatchKioskRequest,
    options?: RpcOptions
  ): ServerStreamingCall<WatchKioskRequest, WatchKioskResponse>;
  /**
   * @generated from protobuf rpc: UpdateKioskSettings(sparx.teaching.kiosk.v1.UpdateKioskSettingsRequest) returns (sparx.teaching.kiosk.v1.UpdateKioskSettingsResponse);
   */
  updateKioskSettings(
    input: UpdateKioskSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateKioskSettingsRequest, UpdateKioskSettingsResponse>;
  /**
   * @generated from protobuf rpc: RemoveKiosk(sparx.teaching.kiosk.v1.RemoveKioskRequest) returns (sparx.teaching.kiosk.v1.RemoveKioskResponse);
   */
  removeKiosk(
    input: RemoveKioskRequest,
    options?: RpcOptions
  ): UnaryCall<RemoveKioskRequest, RemoveKioskResponse>;
}
/**
 * @generated from protobuf service sparx.teaching.kiosk.v1.Kiosk
 */
export class KioskClient implements IKioskClient, ServiceInfo {
  typeName = Kiosk.typeName;
  methods = Kiosk.methods;
  options = Kiosk.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * CreateNewKiosk creates a new token that the kiosk can use to authenticate itself.
   * The request will block until the token is used.
   *
   * @generated from protobuf rpc: CreateNewKiosk(sparx.teaching.kiosk.v1.CreateNewKioskRequest) returns (sparx.teaching.kiosk.v1.CreateNewKioskResponse);
   */
  createNewKiosk(
    input: CreateNewKioskRequest,
    options?: RpcOptions
  ): UnaryCall<CreateNewKioskRequest, CreateNewKioskResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateNewKioskRequest, CreateNewKioskResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ListKiosks(sparx.teaching.kiosk.v1.ListKiosksRequest) returns (sparx.teaching.kiosk.v1.ListKiosksResponse);
   */
  listKiosks(
    input: ListKiosksRequest,
    options?: RpcOptions
  ): UnaryCall<ListKiosksRequest, ListKiosksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListKiosksRequest, ListKiosksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * AuthenticateKiosk authenticates the kiosk with the given token.
   *
   * @generated from protobuf rpc: AuthenticateKiosk(sparx.teaching.kiosk.v1.AuthenticateKioskRequest) returns (sparx.teaching.kiosk.v1.AuthenticateKioskResponse);
   */
  authenticateKiosk(
    input: AuthenticateKioskRequest,
    options?: RpcOptions
  ): UnaryCall<AuthenticateKioskRequest, AuthenticateKioskResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AuthenticateKioskRequest, AuthenticateKioskResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * UpdateKioskState is called by an authenticated kiosk user
   *
   * @generated from protobuf rpc: UpdateKioskState(sparx.teaching.kiosk.v1.UpdateKioskStateRequest) returns (sparx.teaching.kiosk.v1.UpdateKioskStateResponse);
   */
  updateKioskState(
    input: UpdateKioskStateRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateKioskStateRequest, UpdateKioskStateResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateKioskStateRequest, UpdateKioskStateResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: WatchKiosk(sparx.teaching.kiosk.v1.WatchKioskRequest) returns (stream sparx.teaching.kiosk.v1.WatchKioskResponse);
   */
  watchKiosk(
    input: WatchKioskRequest,
    options?: RpcOptions
  ): ServerStreamingCall<WatchKioskRequest, WatchKioskResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<WatchKioskRequest, WatchKioskResponse>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateKioskSettings(sparx.teaching.kiosk.v1.UpdateKioskSettingsRequest) returns (sparx.teaching.kiosk.v1.UpdateKioskSettingsResponse);
   */
  updateKioskSettings(
    input: UpdateKioskSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateKioskSettingsRequest, UpdateKioskSettingsResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateKioskSettingsRequest,
      UpdateKioskSettingsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: RemoveKiosk(sparx.teaching.kiosk.v1.RemoveKioskRequest) returns (sparx.teaching.kiosk.v1.RemoveKioskResponse);
   */
  removeKiosk(
    input: RemoveKioskRequest,
    options?: RpcOptions
  ): UnaryCall<RemoveKioskRequest, RemoveKioskResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<RemoveKioskRequest, RemoveKioskResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
