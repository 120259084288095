import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Activity } from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { useActivityNotify } from 'api/packages';
import { useClientEvent } from 'components/ClientEventProvider';
import React from 'react';

interface VideoModalProps {
  activity: Activity;
  videoID: string;
}

export const VideoModal = ({ activity, videoID }: VideoModalProps) => {
  const activityAction = useActivityNotify();
  const { sendEvent } = useClientEvent();

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      sendEvent({ category: 'video', action: 'open' }, { activityName: activity.name, videoID });
      activityAction.mutate({
        activityName: activity.name,
        action: { oneofKind: 'help', help: { viewing: true } },
      });
    },
    onClose: () => {
      sendEvent({ category: 'video', action: 'close' }, { activityName: activity.name, videoID });
      activityAction.mutate({
        activityName: activity.name,
        action: { oneofKind: 'help', help: { viewing: false } },
      });
    },
  });

  const urls = useQuestionVideoUrls(videoID);

  let subtitleTrack: React.ReactNode | null = null;
  if (urls.subtitleUrl) {
    subtitleTrack = (
      <track label="English" kind="subtitles" srcLang="en" src={urls.subtitleUrl} default={true} />
    );
  }

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faVideo} />}
        size={['sm', 'md']}
      >
        Watch video
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom={true} isCentered={true}>
        <ModalOverlay />
        <ModalContent maxWidth="2000px" maxHeight="100%">
          <ModalHeader>Support video</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <video
              src={urls.videoUrl}
              controls={true}
              poster={urls.posterUrl}
              style={{
                width: '100%',
                maxHeight: '70vh',
                background: 'gray',
              }}
            >
              {subtitleTrack}
            </video>
          </ModalBody>
          <ModalFooter justifyContent="center" display="flex">
            <Button colorScheme="buttonTeal" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const useQuestionVideoUrls = (videoId: string) => {
  const contentAssetsURL = 'https://assets.sparxhomework.uk';
  return {
    posterUrl: `${contentAssetsURL}/${videoId}/poster`,
    subtitleUrl: `${contentAssetsURL}/${videoId}/subtitles`,
    videoUrl: `${contentAssetsURL}/${videoId}`,
  };
};
