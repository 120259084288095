// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/packages/v1/spxpkg.proto" (package "sparx.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Any } from '../../../../google/protobuf/any';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * A package has a number of dates associated with it
 * These are primarily of interest to homework
 *
 * @generated from protobuf message sparx.packages.v1.PackageDates
 */
export interface PackageDates {
  /**
   * The date it became available for the student to work on
   *
   * @generated from protobuf field: google.protobuf.Timestamp start = 1;
   */
  start?: Timestamp;
  /**
   * The date by which the student is expected to compete it
   *
   * @generated from protobuf field: google.protobuf.Timestamp end = 2;
   */
  end?: Timestamp;
  /**
   * The data at which it will be hidden from the student so they may no longer work on it
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry = 3;
   */
  expiry?: Timestamp;
}
/**
 * @generated from protobuf message sparx.packages.v1.PackageDetails
 */
export interface PackageDetails {
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType type = 1;
   */
  type: PackageType;
  /**
   * @generated from protobuf field: string packageID = 2;
   */
  packageID: string;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageDates dates = 3;
   */
  dates?: PackageDates;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageStatus status = 4;
   */
  status: PackageStatus;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageContents contents = 5;
   */
  contents?: PackageContents;
  /**
   * Override the name of the task. If not set, a default will be generated.
   *
   * @generated from protobuf field: string title = 6;
   */
  title: string;
  /**
   * Whether the package should be delivered as a diagnostic homework.
   *
   * @generated from protobuf field: bool isDiagnosticPackage = 7;
   */
  isDiagnosticPackage: boolean;
  /**
   * If the package is part of a diagnostic homework this field contains the number of tasks
   * that the student needs to answer for it to be completed.
   *
   * @generated from protobuf field: int64 numDiagnosticTasks = 8;
   */
  numDiagnosticTasks: number;
  /**
   * If the package is part of a diagnostic homework, this defines another package which is
   * unlocked when this package is completed. Set to "" if there is no next package to unlock.
   *
   * @generated from protobuf field: string followOnPackageID = 9;
   */
  followOnPackageID: string;
  /**
   * The diagnostic level to start the package on. In the case of 0, level 1 will be chosen for
   * compulsory homework and no packages will be unlocked for optional homework.
   *
   * @generated from protobuf field: int64 startingDiagnosticLevel = 10;
   */
  startingDiagnosticLevel: number;
  /**
   * @generated from protobuf field: map<string, string> labels = 12;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.packages.v1.PackageContents
 */
export interface PackageContents {
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.Task tasks = 1;
   */
  tasks: Task[];
}
/**
 * DiagnosticTaskInfo contains information about the level of a task within a diagnostic homework.
 * It contains the information that decides whether a student goes up or down a level when the task
 * is completed.
 *
 * @generated from protobuf message sparx.packages.v1.DiagnosticTaskInfo
 */
export interface DiagnosticTaskInfo {
  /**
   * The level of difficulty of this task. 1 is the first and easiest difficulty.
   *
   * @generated from protobuf field: int64 diagnosticLevel = 9;
   */
  diagnosticLevel: number;
  /**
   * The index of this task within the difficulty level. 1 is the first task in the level.
   *
   * @generated from protobuf field: int64 diagnosticLevelTaskIndex = 10;
   */
  diagnosticLevelTaskIndex: number;
  /**
   * The maximum time taken in seconds on this task to be able to move up to the next
   * diagnostic level.
   *
   * @generated from protobuf field: float moveUpMaxTimeTaken = 11;
   */
  moveUpMaxTimeTaken: number;
  /**
   * The minimum proportion of task items answered correctly first time to
   * be able to move up to the next diagnostic level for the next task.
   * Must be between 0 and 1 inclusive.
   *
   * @generated from protobuf field: float moveUpMinPropCorrectFirstTime = 12;
   */
  moveUpMinPropCorrectFirstTime: number;
  /**
   * The proportion of task items answered correctly first time, above which
   * the student would stay at their current diagnostic level for the next task.
   * Must be between 0 and 1 inclusive.
   *
   * @generated from protobuf field: float sameLevelMinPropCorrectFirstTime = 13;
   */
  sameLevelMinPropCorrectFirstTime: number;
}
/**
 * @generated from protobuf message sparx.packages.v1.Task
 */
export interface Task {
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItem taskItems = 1;
   */
  taskItems: TaskItem[];
  /**
   * How much XP the student receives for completing the task. The amount is
   * overridden by swworker's XPointsManager for tasks in packages of type
   * homework, optional-homework, targets or lesson. If rewardXPoints is
   * 0 the task will never award XP or a minigame play (even if it's one of
   * the overridden types). When XP should be awarded but is expected to be
   * overridden, rewardXPoints should be set to 1.
   *
   * @generated from protobuf field: int64 rewardXPoints = 2;
   */
  rewardXPoints: number;
  /**
   * Completion type of the task. Can be empty string for default behaviour.
   *
   * @generated from protobuf field: string taskType = 3;
   */
  taskType: string;
  /**
   * Prevent WAC's on this task. Deprecated, use TaskDeliveryFlags instead.
   *
   * @deprecated
   * @generated from protobuf field: bool doNotWAC = 4 [deprecated = true];
   */
  doNotWAC: boolean;
  /**
   * Override the name of the task. If not set, a default will be generated.
   *
   * @generated from protobuf field: string title = 5;
   */
  title: string;
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.TaskDeliveryFlags flags = 6;
   */
  flags: TaskDeliveryFlags[];
  /**
   * Optional message with custom values for the completion calculations. A nil message here will
   * use the default values based on the task.
   *
   * @generated from protobuf field: sparx.packages.v1.CustomCompletion completion = 7;
   */
  completion?: CustomCompletion;
  /**
   * JSON blob for default completion state.
   *
   * @generated from protobuf field: string completionStore = 8;
   */
  completionStore: string;
  /**
   * Information specific to diagnostic homework. A nil message here indicates that the task is
   * not part of a diagnostic homework package.
   *
   * @generated from protobuf field: sparx.packages.v1.DiagnosticTaskInfo diagnosticTaskInfo = 9;
   */
  diagnosticTaskInfo?: DiagnosticTaskInfo;
  /**
   * The section that the task corresponds to e.g. OBJ1. These are currently only used in lesson
   * tasks
   *
   * @generated from protobuf field: string section = 10;
   */
  section: string;
  /**
   * The stage that the task corresponds to e.g. CORE. This is currently only used in lesson
   * tasks
   *
   * @generated from protobuf field: string stage = 11;
   */
  stage: string;
  /**
   * @generated from protobuf field: map<string, string> labels = 12;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.packages.v1.CustomCompletion
 */
export interface CustomCompletion {
  /**
   * Size is how big the thing is. The default value for this when not including a CustomCompletion
   * on the task is for it to be the number of task items. Changing this value for a normal task item
   * task will change the number of questions required for the task to be deemed complete.
   *
   * If this weight is negative, then the size will default to the number of task items in the task.
   *
   * @generated from protobuf field: double size = 1;
   */
  size: number;
  /**
   * Weight is how much this task counts towards the size of its container. The default value
   * for this when not including a CustomCompletion is weight 1.
   *
   * @generated from protobuf field: double weight = 2;
   */
  weight: number;
}
/**
 * @generated from protobuf message sparx.packages.v1.TaskItem
 */
export interface TaskItem {
  /**
   * Skill to set for this task item.
   *
   * @generated from protobuf field: string skillID = 1;
   */
  skillID: string;
  /**
   * Learning unit that this skill is set for.
   *
   * @generated from protobuf field: string learningUnitID = 2;
   */
  learningUnitID: string;
  /**
   * Whether to enable repeat question delivery. The same question will be delivered until
   * the question is answered correctly. If you always want the same question then the
   * questionID must be provided and this should not be set.
   *
   * @generated from protobuf field: bool repeatQuestion = 3;
   */
  repeatQuestion: boolean;
  /**
   * Optional questionID for single question delivery.
   *
   * @generated from protobuf field: string questionID = 4;
   */
  questionID: string;
  /**
   * Custom question spec (optional)
   *
   * @generated from protobuf field: string customQuestion = 5;
   */
  customQuestion: string;
  /**
   * Custom name for the task item (optional - default will be letter based on index of item)
   *
   * @generated from protobuf field: string name = 6;
   */
  name: string;
  /**
   * List of flags which describe additional features or annotations on this task item.
   *
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItemFlag flags = 7;
   */
  flags: TaskItemFlag[];
  /**
   * Map of labels about this task item containing additional metadata to be used elsewhere.
   * An example includes 'ftq_item' used by fast track quiz tasks to identify different types of item.
   *
   * @generated from protobuf field: map<string, string> labels = 8;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Name of the topic that this task item was set for, ideally in the form:
   *   curriculums/<id>/topic/<id>/learningpaths/<id>
   * Note: this would probably be better if it was named learningPathName
   *
   * @generated from protobuf field: string curriculumTopicName = 9;
   */
  curriculumTopicName: string;
  /**
   * If this task item was swapped in for another task item, this will be the index of the task
   * item it was swapped in for. 0 implies it wasn't swapped in.
   *
   * @generated from protobuf field: int32 swappedInForIndex = 10;
   */
  swappedInForIndex: number;
}
/**
 * PackageRecipe defines a specification for package(s). It specifies a packager
 * that can build package(s) from it through the TypeUrl field on the packagerRequest.
 * It does not have a packageID field as it is not necessarily specific to a single
 * package. It is possible that the package_request object will contain one
 * of more pacakge IDs.
 *
 * @generated from protobuf message sparx.packages.v1.PackageRecipe
 */
export interface PackageRecipe {
  /**
   * packagerRequest is the request that should be passed to the packager
   * to generate the package(s). It can be Any protobuf type but it must be one
   * where the TypeUrl is supported by one of the packagers and known to the
   * implementation of any proxies/routers which will propagate a build request
   * to a packager.
   *
   * @generated from protobuf field: google.protobuf.Any packager_request = 1;
   */
  packagerRequest?: Any;
}
/**
 * *
 * This message contains data about packages, tasks and task items.
 * It is used as a response to a GetPackageData request to the server
 * or as the payload of and UpdatePackageData request to the client.
 *
 * @generated from protobuf message sparx.packages.v1.PackageData
 */
export interface PackageData {
  /**
   * Data about packages including progress
   *
   * @generated from protobuf field: repeated sparx.packages.v1.PackageCompletion packages = 1;
   */
  packages: PackageCompletion[];
  /**
   * Data about tasks including progress
   *
   * @generated from protobuf field: repeated sparx.packages.v1.TaskCompletion tasks = 2;
   */
  tasks: TaskCompletion[];
  /**
   * Data about task items including progress
   *
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItemCompletion taskItems = 3;
   */
  taskItems: TaskItemCompletion[];
}
/**
 * *
 * Data about a package
 *
 * @generated from protobuf message sparx.packages.v1.PackageCompletion
 */
export interface PackageCompletion {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * First day the package is active
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
  /**
   * Last day the package is active
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 3;
   */
  endDate?: Timestamp;
  /**
   * Package title (to be displayed)
   *
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * Package type (e.g. "homework", "lesson" or "revision")
   *
   * @generated from protobuf field: string packageType = 5;
   */
  packageType: string;
  /**
   * Number of tasks in the package
   *
   * @generated from protobuf field: int32 numTasks = 6;
   */
  numTasks: number;
  /**
   * Total number of task items in the package
   *
   * @generated from protobuf field: int32 numTaskItems = 7;
   */
  numTaskItems: number;
  /**
   * Total number of task items which have been done for this package
   *
   * @generated from protobuf field: int32 numTaskItemsDone = 8;
   */
  numTaskItemsDone: number;
  /**
   * This is true if the package has been complete in the past
   * (i.e. numTaskItemsDone was equal to numTaskItems)
   *
   * @generated from protobuf field: bool hasBeenComplete = 9;
   */
  hasBeenComplete: boolean;
  /**
   * @generated from protobuf field: int32 numTasksComplete = 10;
   */
  numTasksComplete: number;
  /**
   * @generated from protobuf field: int32 numTasksInProgress = 11;
   */
  numTasksInProgress: number;
  /**
   * The status of the package generation
   * It can be PLACEHOLDER, CONTENTS_GENERATED or GENERATION_FAILED
   * (see PackageStatus in homeworkgen.proto)
   *
   * @generated from protobuf field: string status = 12;
   */
  status: string;
  /**
   * The number of xPoints student was awarded for this package
   *
   * @generated from protobuf field: int32 xPoints = 13;
   */
  xPoints: number;
  /**
   * The completion on this package
   *
   * @generated from protobuf field: sparx.packages.v1.Completion completion = 14;
   */
  completion?: Completion;
  /**
   * A list of the task IDs in the package. This is set in diagnostic homework
   * where the task IDs are not necessarily consecutive.
   *
   * @generated from protobuf field: repeated string taskIds = 15;
   */
  taskIds: string[];
  /**
   * Package labels
   *
   * @generated from protobuf field: map<string, string> labels = 16;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * The time that the homework was deleted, or null if hasn't been deleted
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTime = 17;
   */
  deletedTime?: Timestamp;
  /**
   * The ID of the homework that this package is part of
   *
   * @generated from protobuf field: string homeworkID = 18;
   */
  homeworkID: string;
  /**
   * The count of swaps made in this package
   *
   * @generated from protobuf field: int32 numSwapsMade = 19;
   */
  numSwapsMade: number;
}
/**
 *
 * Data about a task
 *
 * @generated from protobuf message sparx.packages.v1.TaskCompletion
 */
export interface TaskCompletion {
  /**
   * ID of the package the task belongs to
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Position of the task in the package
   *
   * @generated from protobuf field: int32 taskIndex = 2;
   */
  taskIndex: number;
  /**
   * Task title (to be displayed)
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * Task type (e.g. "CORE", "EXTENSION", "ACTIVITY")
   *
   * @generated from protobuf field: string taskType = 4;
   */
  taskType: string;
  /**
   * Total number of task items in the task
   *
   * @generated from protobuf field: int32 numTaskItems = 6;
   */
  numTaskItems: number;
  /**
   * Number of task items which are done in this task
   *
   * @generated from protobuf field: int32 numTaskItemsDone = 7;
   */
  numTaskItemsDone: number;
  /**
   * True if the task has been complete in the past
   * (i.e. numTaskItemsDone was equal to numTaskItems)
   *
   * @generated from protobuf field: bool hasBeenComplete = 8;
   */
  hasBeenComplete: boolean;
  /**
   * @generated from protobuf field: int32 numTaskItemsWrong = 9;
   */
  numTaskItemsWrong: number;
  /**
   * Prevent WACs on this task.
   *
   * @deprecated
   * @generated from protobuf field: bool doNotWAC = 10 [deprecated = true];
   */
  doNotWAC: boolean;
  /**
   * The completion on this task
   *
   * @generated from protobuf field: sparx.packages.v1.Completion completion = 11;
   */
  completion?: Completion;
  /**
   * The completion store on this task in a json blob
   *
   * @generated from protobuf field: string completionStore = 12;
   */
  completionStore: string;
  /**
   * Task labels
   *
   * @generated from protobuf field: map<string, string> labels = 13;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Fixed Question Delivery, task items in this task are delivered with FQD
   * meaning we need to use different wording around WACs.
   *
   * @generated from protobuf field: bool fixedQuestionDelivery = 14;
   */
  fixedQuestionDelivery: boolean;
  /**
   * Second Chance Delivery, task items in the task are delivered with SCD
   * this enables different wording around WACs and other tutorials.
   *
   * @generated from protobuf field: bool secondChanceDelivery = 15;
   */
  secondChanceDelivery: boolean;
}
/**
 * *
 * Data about a task item
 *
 * @generated from protobuf message sparx.packages.v1.TaskItemCompletion
 */
export interface TaskItemCompletion {
  /**
   * ID of the package the task item belongs to
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Position of the task in the package the task item belongs to
   *
   * @generated from protobuf field: int32 taskIndex = 2;
   */
  taskIndex: number;
  /**
   * Position of the task item in its task
   *
   * @generated from protobuf field: int32 taskItemIndex = 3;
   */
  taskItemIndex: number;
  /**
   * Current status of the task item
   *
   * @generated from protobuf field: sparx.packages.v1.TaskItemStatus status = 4;
   */
  status: TaskItemStatus;
  /**
   * Number of times this task item has been answered correctly
   *
   * @generated from protobuf field: int32 numCorrectAnswers = 5;
   */
  numCorrectAnswers: number;
  /**
   * Number of times this task item has been answered incorrectly
   *
   * @generated from protobuf field: int32 numWrongAnswers = 6;
   */
  numWrongAnswers: number;
  /**
   * Number of times the video for this task item has been watched
   *
   * @generated from protobuf field: int32 numVideoWatched = 7;
   */
  numVideoWatched: number;
  /**
   * When this task item was last answered
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastAnsweredAt = 8;
   */
  lastAnsweredAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 9;
   */
  name: string;
  /**
   * TODO: Deprecate once all servers send SEEKHELP status.
   * Identifies to the client that the version of swworker
   * it is connected to can calculate the seek help status.
   *
   * @generated from protobuf field: bool useStatus = 15;
   */
  useStatus: boolean;
  /**
   * Any submitted rating on this task item.
   *
   * @generated from protobuf field: sparx.packages.v1.Rating rating = 16;
   */
  rating?: Rating;
  /**
   * Number of times this task item has been answered incorrectly in a row
   *
   * @generated from protobuf field: int32 numConsecutiveWrongAnswers = 17;
   */
  numConsecutiveWrongAnswers: number;
  /**
   * Map of labels about this task item containing additional metadata.
   * An example includes 'ftq_item' used by fast track quiz tasks to identify different types of item.
   *
   * @generated from protobuf field: map<string, string> labels = 18;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Number of questions in the task item skill
   *
   * @generated from protobuf field: int32 numSkillQuestions = 19;
   */
  numSkillQuestions: number;
  /**
   * If this task item was swapped in for another task item, this will be the index of the task
   * item it was swapped in for. 0 implies it wasn't swapped in.
   *
   * @generated from protobuf field: int32 swappedInForIndex = 20;
   */
  swappedInForIndex: number;
  /**
   * Whether the video has been played for this task item
   * (0 or 1 so that it can be more easily aggregated at the
   * task and package level)
   *
   * @generated from protobuf field: int32 videoPlayed = 21;
   */
  videoPlayed: number;
  /**
   * The cumulative time that the video modal has been open for this task item
   *
   * @generated from protobuf field: float videoOpenDuration = 22;
   */
  videoOpenDuration: number;
}
/**
 * @generated from protobuf message sparx.packages.v1.Rating
 */
export interface Rating {
  /**
   * @generated from protobuf field: int32 confidence = 1;
   */
  confidence: number;
  /**
   * Second confidence rating (first is original)
   *
   * @generated from protobuf field: int32 confidenceAfter = 2;
   */
  confidenceAfter: number;
}
/**
 * *
 * Completion tracks information about the size and progress
 * of a task or package.
 *
 * The progress map will contain key value mapping of
 * different progress types. The primary progress indicator
 * is the Complete type ("C"). When this value is at
 * least >= size, then the package or task is deemed complete.
 *
 * @generated from protobuf message sparx.packages.v1.Completion
 */
export interface Completion {
  /**
   * Size is how big the thing is.  The size of a task should be equal to sum
   * of item.weight*item.size for all items in the task.  The size of a
   * package should be equal to sum of task.weight*task.size for all tasks in
   * the package.
   *
   * @generated from protobuf field: double size = 1;
   */
  size: number;
  /**
   * Weight is how much it counts toward the size of its container.  It's a
   * multiplier for size.
   *
   * @generated from protobuf field: double weight = 2;
   */
  weight: number;
  /**
   * Progress is a map of ProgressType to non-negative floating point value.
   * These values do not need to sum to Size, however a completion is complete
   * if the Complete progress value is at least Size.
   *
   * The description of these types can be found in the progress go package.
   *
   * @generated from protobuf field: map<string, double> progress = 3;
   */
  progress: {
    [key: string]: number;
  };
}
/**
 * PackageType is an enum of types of packages that we can serve.
 * When adding a new one, you will need to also update
 * PackageNameMap in sparxweb/pkgtools/helpers.go
 *
 * @generated from protobuf enum sparx.packages.v1.PackageType
 */
export enum PackageType {
  /**
   * Homework packages
   *
   * @generated from protobuf enum value: COMPULSORY = 0;
   */
  COMPULSORY = 0,
  /**
   * @generated from protobuf enum value: OPTIONAL = 1;
   */
  OPTIONAL = 1,
  /**
   * @generated from protobuf enum value: TARGETS = 2;
   */
  TARGETS = 2,
  /**
   * @generated from protobuf enum value: PRACTICE = 6;
   */
  PRACTICE = 6,
  /**
   * @generated from protobuf enum value: ONBOARDING = 11;
   */
  ONBOARDING = 11,
  /**
   * @generated from protobuf enum value: LESSON = 3;
   */
  LESSON = 3,
  /**
   * @generated from protobuf enum value: SOLO = 4;
   */
  SOLO = 4,
  /**
   * @generated from protobuf enum value: REVISION = 5;
   */
  REVISION = 5,
  /**
   * Training homework packages
   *
   * @generated from protobuf enum value: COMPULSORY_TRAINING = 7;
   */
  COMPULSORY_TRAINING = 7,
  /**
   * @generated from protobuf enum value: OPTIONAL_TRAINING = 8;
   */
  OPTIONAL_TRAINING = 8,
  /**
   * @generated from protobuf enum value: TARGETS_TRAINING = 9;
   */
  TARGETS_TRAINING = 9,
  /**
   * Assessment packages
   *
   * @generated from protobuf enum value: ASSESSMENT_FIXUP = 10;
   */
  ASSESSMENT_FIXUP = 10,
  /**
   * @generated from protobuf enum value: ASSESSMENT_REVISION = 12;
   */
  ASSESSMENT_REVISION = 12,
  /**
   * @generated from protobuf enum value: UNUSED = 100;
   */
  UNUSED = 100,
}
/**
 * @generated from protobuf enum sparx.packages.v1.PackageStatus
 */
export enum PackageStatus {
  /**
   * A package exists but no content has yet been created for it
   *
   * @generated from protobuf enum value: PLACEHOLDER = 0;
   */
  PLACEHOLDER = 0,
  /**
   * The package has contents selected for the student
   *
   * @generated from protobuf enum value: CONTENTS_GENERATED = 1;
   */
  CONTENTS_GENERATED = 1,
  /**
   * We were unable to generate content for the student
   * In practice this shows a message in SW and TP
   *
   * @generated from protobuf enum value: GENERATION_FAILED = 2;
   */
  GENERATION_FAILED = 2,
}
/**
 * @generated from protobuf enum sparx.packages.v1.TaskDeliveryFlags
 */
export enum TaskDeliveryFlags {
  /**
   * @generated from protobuf enum value: None = 0;
   */
  None = 0,
  /**
   * DoNotWAC will prevent questions in this task from having WAC's issued on them.
   *
   * @generated from protobuf enum value: DoNotWAC = 1;
   */
  DoNotWAC = 1,
  /**
   * WACsNotAllowed will prevent WAC's from being issues when starting questions in this task.
   *
   * @generated from protobuf enum value: WACsNotAllowed = 2;
   */
  WACsNotAllowed = 2,
  /**
   * FixedQuestionDelivery will cause a number of effects for task items in this task:
   * - task items will fix the question id of the skill when they started which will
   *   persist across correct and incorrect answers on that task item.
   * - the bookwork code for the task item will be fixed once it is started which will
   *   persist across correct and incorrect answers on that task item.
   * - only correct answers will be added as WAC candidates.
   *
   * @generated from protobuf enum value: FixedQuestionDelivery = 3;
   */
  FixedQuestionDelivery = 3,
  /**
   * SecondChanceDelivery will cause a number of effects for task items in this task:
   * - task items will allow a second attempt of the fill (question id) when answered
   *   incorrectly. After the seccond attempt/if answered correctly the fill will change.
   * - the bookwork code for the task item will be fixed once it is started  which will
   *   persist across correct and incorrect answers on that task item.
   * - only correct answers will be added as WAC candidates
   * - task items with more than one correct answer will not be WAC candidates
   *
   * @generated from protobuf enum value: SecondChanceDelivery = 4;
   */
  SecondChanceDelivery = 4,
}
/**
 * @generated from protobuf enum sparx.packages.v1.TaskItemFlag
 */
export enum TaskItemFlag {
  /**
   * @generated from protobuf enum value: TASK_ITEM_FLAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Task item skill is a marking skill.
   *
   * @generated from protobuf enum value: TASK_ITEM_FLAG_MARKING = 1;
   */
  MARKING = 1,
  /**
   * Task item should have the status of being hidden when it is initally
   * created.
   *
   * @generated from protobuf enum value: TASK_ITEM_FLAG_INITIALLY_HIDDEN = 2;
   */
  INITIALLY_HIDDEN = 2,
  /**
   * Task item should be used to determine the learning units state if it is
   * answered correctly. This is used for OMH where task items in the quiz task
   * can set the learning unit to be learnt without them completing all the core.
   * Deprecated, as is OMH, use TASK_ITEM_FLAG_FTQ_TASK_QUIZ_SKILL instead.
   *
   * @deprecated
   * @generated from protobuf enum value: TASK_ITEM_FLAG_FAST_TRACK_SKILL = 3 [deprecated = true];
   */
  FAST_TRACK_SKILL = 3,
  /**
   * Task item should be used to determine the learning units state if answered
   * correctly in the fast track quiz. This is used for fast track quiz tasks
   * where task items in the quiz part of the task can set the learning unit
   * to be core learnt without them completing all the core.
   * Although similar to the FAST TRACK SKILL flag, differences in how the
   * quizzes are delivered, required the flags to be separate, to correctly
   * determine whether the core should be considered learnt.
   *
   * @generated from protobuf enum value: TASK_ITEM_FLAG_FTQ_TASK_QUIZ_SKILL = 4;
   */
  FTQ_TASK_QUIZ_SKILL = 4,
  /**
   * Task item is a video that must be watched to complete the item.
   *
   * @generated from protobuf enum value: TASK_ITEM_FLAG_VIDEO = 5;
   */
  VIDEO = 5,
}
/**
 * @generated from protobuf enum sparx.packages.v1.TaskItemStatus
 */
export enum TaskItemStatus {
  /**
   * Hasn't been attempted yet
   *
   * @generated from protobuf enum value: UNATTEMPTED = 0;
   */
  UNATTEMPTED = 0,
  /**
   * Done correctly last
   *
   * @generated from protobuf enum value: DONE = 1;
   */
  DONE = 1,
  /**
   * Done incorrectly last
   *
   * @generated from protobuf enum value: WRONG = 2;
   */
  WRONG = 2,
  /**
   * Done incorrectly too many times
   *
   * @generated from protobuf enum value: WARNING = 3;
   */
  WARNING = 3,
  /**
   * Done incorrectly enough times to require assistance
   *
   * @generated from protobuf enum value: SEEKHELP = 4;
   */
  SEEKHELP = 4,
  /**
   * May not have been attempted, but badged correct (used for FTQ)
   *
   * @generated from protobuf enum value: BADGED = 5;
   */
  BADGED = 5,
  /**
   * Task item should be hidden from student view
   *
   * @generated from protobuf enum value: HIDDEN = 6;
   */
  HIDDEN = 6,
  /**
   * Task item is incorrect, but we should nudge to watch the video
   *
   * @generated from protobuf enum value: WRONGWITHVIDEONUDGE = 7;
   */
  WRONGWITHVIDEONUDGE = 7,
  /**
   * Task item has been swapped out for an easier one
   *
   * @generated from protobuf enum value: SWAPPEDOUT = 8;
   */
  SWAPPEDOUT = 8,
}
// @generated message type with reflection information, may provide speed optimized methods
class PackageDates$Type extends MessageType<PackageDates> {
  constructor() {
    super('sparx.packages.v1.PackageDates', [
      { no: 1, name: 'start', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'expiry', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageDates
 */
export const PackageDates = new PackageDates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageDetails$Type extends MessageType<PackageDetails> {
  constructor() {
    super('sparx.packages.v1.PackageDetails', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 2, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'dates', kind: 'message', T: () => PackageDates },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageStatus', PackageStatus],
      },
      { no: 5, name: 'contents', kind: 'message', T: () => PackageContents },
      { no: 6, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'isDiagnosticPackage',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 8,
        name: 'numDiagnosticTasks',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 9,
        name: 'followOnPackageID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'startingDiagnosticLevel',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 12,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageDetails
 */
export const PackageDetails = new PackageDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageContents$Type extends MessageType<PackageContents> {
  constructor() {
    super('sparx.packages.v1.PackageContents', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageContents
 */
export const PackageContents = new PackageContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiagnosticTaskInfo$Type extends MessageType<DiagnosticTaskInfo> {
  constructor() {
    super('sparx.packages.v1.DiagnosticTaskInfo', [
      {
        no: 9,
        name: 'diagnosticLevel',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 10,
        name: 'diagnosticLevelTaskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 11,
        name: 'moveUpMaxTimeTaken',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 12,
        name: 'moveUpMinPropCorrectFirstTime',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 13,
        name: 'sameLevelMinPropCorrectFirstTime',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.DiagnosticTaskInfo
 */
export const DiagnosticTaskInfo = new DiagnosticTaskInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType<Task> {
  constructor() {
    super('sparx.packages.v1.Task', [
      {
        no: 1,
        name: 'taskItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskItem,
      },
      {
        no: 2,
        name: 'rewardXPoints',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: 'taskType', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'doNotWAC', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.packages.v1.TaskDeliveryFlags', TaskDeliveryFlags],
      },
      { no: 7, name: 'completion', kind: 'message', T: () => CustomCompletion },
      {
        no: 8,
        name: 'completionStore',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'diagnosticTaskInfo',
        kind: 'message',
        T: () => DiagnosticTaskInfo,
      },
      { no: 10, name: 'section', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 11, name: 'stage', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 12,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomCompletion$Type extends MessageType<CustomCompletion> {
  constructor() {
    super('sparx.packages.v1.CustomCompletion', [
      { no: 1, name: 'size', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'weight', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.CustomCompletion
 */
export const CustomCompletion = new CustomCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem$Type extends MessageType<TaskItem> {
  constructor() {
    super('sparx.packages.v1.TaskItem', [
      { no: 1, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'learningUnitID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'repeatQuestion',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 4, name: 'questionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'customQuestion',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.v1.TaskItemFlag',
          TaskItemFlag,
          'TASK_ITEM_FLAG_',
        ],
      },
      {
        no: 8,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 9,
        name: 'curriculumTopicName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'swappedInForIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.TaskItem
 */
export const TaskItem = new TaskItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageRecipe$Type extends MessageType<PackageRecipe> {
  constructor() {
    super('sparx.packages.v1.PackageRecipe', [
      { no: 1, name: 'packager_request', kind: 'message', T: () => Any },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageRecipe
 */
export const PackageRecipe = new PackageRecipe$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageData$Type extends MessageType<PackageData> {
  constructor() {
    super('sparx.packages.v1.PackageData', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackageCompletion,
      },
      {
        no: 2,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskCompletion,
      },
      {
        no: 3,
        name: 'taskItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskItemCompletion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageData
 */
export const PackageData = new PackageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageCompletion$Type extends MessageType<PackageCompletion> {
  constructor() {
    super('sparx.packages.v1.PackageCompletion', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'packageType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'numTasks', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'numTaskItems',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'numTaskItemsDone',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'hasBeenComplete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'numTasksComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'numTasksInProgress',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 12, name: 'status', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 13, name: 'xPoints', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 14, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 15,
        name: 'taskIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 17, name: 'deletedTime', kind: 'message', T: () => Timestamp },
      {
        no: 18,
        name: 'homeworkID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: 'numSwapsMade',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.PackageCompletion
 */
export const PackageCompletion = new PackageCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskCompletion$Type extends MessageType<TaskCompletion> {
  constructor() {
    super('sparx.packages.v1.TaskCompletion', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'taskIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'taskType', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'numTaskItems',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'numTaskItemsDone',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'hasBeenComplete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'numTaskItemsWrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 10, name: 'doNotWAC', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 12,
        name: 'completionStore',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 14,
        name: 'fixedQuestionDelivery',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 15,
        name: 'secondChanceDelivery',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.TaskCompletion
 */
export const TaskCompletion = new TaskCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItemCompletion$Type extends MessageType<TaskItemCompletion> {
  constructor() {
    super('sparx.packages.v1.TaskItemCompletion', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'taskIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.packages.v1.TaskItemStatus', TaskItemStatus],
      },
      {
        no: 5,
        name: 'numCorrectAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'numWrongAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'numVideoWatched',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 8, name: 'lastAnsweredAt', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 15, name: 'useStatus', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 16, name: 'rating', kind: 'message', T: () => Rating },
      {
        no: 17,
        name: 'numConsecutiveWrongAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 18,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 19,
        name: 'numSkillQuestions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 20,
        name: 'swappedInForIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 21,
        name: 'videoPlayed',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 22,
        name: 'videoOpenDuration',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.TaskItemCompletion
 */
export const TaskItemCompletion = new TaskItemCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rating$Type extends MessageType<Rating> {
  constructor() {
    super('sparx.packages.v1.Rating', [
      { no: 1, name: 'confidence', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'confidenceAfter',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.Rating
 */
export const Rating = new Rating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Completion$Type extends MessageType<Completion> {
  constructor() {
    super('sparx.packages.v1.Completion', [
      { no: 1, name: 'size', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'weight', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      {
        no: 3,
        name: 'progress',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.v1.Completion
 */
export const Completion = new Completion$Type();
