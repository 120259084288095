// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/insights.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { GapEvaluation } from './activity';
import { TaskItem_Contents_Skill_Section } from './package';
import { Subject } from '../../v1/subject';
/**
 * @generated from protobuf message sparx.science.packages.v1.FocusSkill
 */
export interface FocusSkill {
  /**
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.FocusQuestion questions = 2;
   */
  questions: FocusQuestion[];
  /**
   * @generated from protobuf field: sparx.science.v1.Subject subject = 3;
   */
  subject: Subject;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Contents.Skill.Section section = 4;
   */
  section: TaskItem_Contents_Skill_Section;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.FocusQuestion
 */
export interface FocusQuestion {
  /**
   * @generated from protobuf field: string layout_json = 1;
   */
  layoutJson: string;
  /**
   * @generated from protobuf field: map<string, string> correct_answer = 2;
   */
  correctAnswer: {
    [key: string]: string;
  };
  /**
   * @generated from protobuf field: map<string, sparx.science.packages.v1.GapEvaluation> correct_answer_gap_evaluations = 3;
   */
  correctAnswerGapEvaluations: {
    [key: string]: GapEvaluation;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListFocusSkillsRequest
 */
export interface ListFocusSkillsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string assignment_name = 2;
   */
  assignmentName: string;
  /**
   * Subject to select the skills for. Options are: combined, biology, chemistry, physics.
   * Not requesting a subject will return the combined skills.
   *
   * @generated from protobuf field: string subject = 3;
   */
  subject: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListFocusSkillsResponse
 */
export interface ListFocusSkillsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.FocusSkill skills = 1;
   */
  skills: FocusSkill[];
}
// @generated message type with reflection information, may provide speed optimized methods
class FocusSkill$Type extends MessageType<FocusSkill> {
  constructor() {
    super('sparx.science.packages.v1.FocusSkill', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FocusQuestion,
      },
      {
        no: 3,
        name: 'subject',
        kind: 'enum',
        T: () => ['sparx.science.v1.Subject', Subject],
      },
      {
        no: 4,
        name: 'section',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.TaskItem.Contents.Skill.Section',
          TaskItem_Contents_Skill_Section,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.FocusSkill
 */
export const FocusSkill = new FocusSkill$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FocusQuestion$Type extends MessageType<FocusQuestion> {
  constructor() {
    super('sparx.science.packages.v1.FocusQuestion', [
      {
        no: 1,
        name: 'layout_json',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'correct_answer',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 3,
        name: 'correct_answer_gap_evaluations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GapEvaluation },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.FocusQuestion
 */
export const FocusQuestion = new FocusQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFocusSkillsRequest$Type extends MessageType<ListFocusSkillsRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListFocusSkillsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListFocusSkillsRequest
 */
export const ListFocusSkillsRequest = new ListFocusSkillsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFocusSkillsResponse$Type extends MessageType<ListFocusSkillsResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListFocusSkillsResponse', [
      {
        no: 1,
        name: 'skills',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FocusSkill,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListFocusSkillsResponse
 */
export const ListFocusSkillsResponse = new ListFocusSkillsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.Insights
 */
export const Insights = new ServiceType('sparx.science.packages.v1.Insights', [
  {
    name: 'ListFocusSkills',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: ListFocusSkillsRequest,
    O: ListFocusSkillsResponse,
  },
]);
