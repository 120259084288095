import { useEffect } from 'react';

export interface KeyPressCallbacks {
  [targetKey: string]: () => void;
}

export const useKeyPress = (callbacks: KeyPressCallbacks) => {
  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler(e: KeyboardEvent) {
      if (callbacks[e.key]) {
        e.stopPropagation();
        callbacks[e.key]();
      }
    }

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => window.removeEventListener('keydown', downHandler);
  }, [callbacks]); // Empty array ensures that effect is only run on mount and unmount
};
