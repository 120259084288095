import { Alert, AlertIcon, Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { useIsInLesson, useJoinLesson } from '@sparx/science/api/lessons';
import { useUserType } from '@sparx/science/api/sessions';
import { useBackLink } from '@sparx/science/app/BackLink';
import { PageTitle } from '@sparx/science/components/pagetitle/PageTitle';
import { SuspenseRoute } from '@sparx/science/components/suspenseroute/SuspenseRoute';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

export const StudentView = () => {
  // Show a back button to the teacher interface if the user is a teacher
  const { isTeacher } = useUserType();
  useBackLink(isTeacher ? '/teacher' : undefined);

  // Fix into lessons page if in lessons mode
  const isInLesson = useIsInLesson();
  if (isInLesson && location.pathname !== '/lesson') {
    return <Navigate to="/lesson" />;
  } else if (!isInLesson && location.pathname === '/lesson') {
    return <Navigate to="/" />;
  }

  return (
    <SuspenseRoute>
      <JoinLesson />
    </SuspenseRoute>
  );
};

const JoinLesson = () => {
  const [joinCode, setJoinCode] = useState('');

  const { mutateAsync, isLoading, error, reset } = useJoinLesson();
  const valid = joinCode.trim().length >= 4;
  const submit = () => {
    if (valid) {
      mutateAsync(joinCode.trim());
    }
  };

  return (
    <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
      <Box
        as="form"
        onSubmit={e => e.preventDefault()}
        maxWidth="600px"
        width="100%"
        px={8}
        py={6}
        marginX="auto"
        bg="white"
        boxShadow="elevationMedium"
        borderRadius="md"
      >
        <PageTitle title="Join Lesson" />
        <Text mb={3}>Enter the lesson code:</Text>
        {Boolean(error) && (
          <Alert status="error" mb={3} borderRadius="md">
            <AlertIcon />
            <Text>
              <Text fontWeight="bold" color="red.700" display="inline-block" mr={2}>
                Error:
              </Text>
              {translateErrorMessage(error)}
            </Text>
          </Alert>
        )}
        <Input
          mb={4}
          value={joinCode}
          onChange={e => {
            if (error) reset();
            setJoinCode(e.target.value);
          }}
          isDisabled={isLoading}
          fontSize="3xl"
          height={20}
          inputMode="decimal"
          textAlign="center"
          placeholder="0000"
        />
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            onClick={submit}
            colorScheme="buttonTeal"
            isDisabled={!valid}
            isLoading={isLoading}
          >
            Join lesson
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

const translateErrorMessage = (err: unknown) => {
  if (err instanceof RpcError) {
    if (err.code === 'NOT_FOUND') return 'Lesson not found';
    return err.message;
  }
  return 'Unknown error';
};
