// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/v1/subject.proto" (package "sparx.science.v1", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum sparx.science.v1.Subject
 */
export enum Subject {
  /**
   * @generated from protobuf enum value: SUBJECT_UNDEFINED = 0;
   */
  SUBJECT_UNDEFINED = 0,
  /**
   * @generated from protobuf enum value: BIOLOGY = 1;
   */
  BIOLOGY = 1,
  /**
   * @generated from protobuf enum value: CHEMISTRY = 2;
   */
  CHEMISTRY = 2,
  /**
   * @generated from protobuf enum value: PHYSICS = 3;
   */
  PHYSICS = 3,
}
