import { Button, ModalBody, VStack } from '@chakra-ui/react';
import { CustomQuestion } from '@sparx/api/apis/sparx/science/content/v1/content';
import { useMutation } from '@tanstack/react-query';
import { scienceContentClient } from 'api';
import { getSchoolID } from 'api/sessions';
import React, { useState } from 'react';
import {
  ActivityPreparationProps,
  createBasicLessonActivityBlueprint,
} from 'views/lessons/createactivity/activitytypes';
import { useLessonViewContext } from 'views/lessons/LessonView';

export const CreateSurveyActivity = ({ doSubmit, getFooter }: ActivityPreparationProps) => {
  const { onSelectActivity, setPanel } = useLessonViewContext();
  const [selectedTemplate, setSelectedTemplate] = useState<number | undefined>();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (question: CustomQuestion) => {
      return scienceContentClient.createCustomSkill({
        schoolName: `schools/${await getSchoolID()}`,
        question,
      }).response;
    },
  });

  const onSubmit = async () => {
    if (selectedTemplate === undefined) return;

    const template = templates[selectedTemplate];
    let skillName = template.skillId ?? '';
    if (template.template) {
      const skill = await mutateAsync(template.template);
      skillName = skill.skillName;
    }
    const activityContent = await doSubmit(
      createBasicLessonActivityBlueprint({
        name: 'Answer this question',
        skills: [
          {
            skillId: skillName,
            questionId: '',
            annotations: {
              survey: 'true',
            },
          },
        ],
      }),
    );
    if (activityContent) {
      onSelectActivity(activityContent?.lessonActivityId);
      if (activityContent.content?.assignment?.assignmentId) {
        setPanel({
          type: 'surveytaskitem',
          taskItemIndex: 1,
          taskIndex: 0,
          assignmentId: activityContent.content.assignment.assignmentId,
        });
      }
    }
  };

  return (
    <>
      <ModalBody pt={0}>
        <VStack spacing={2}>
          {templates.map((tmpl, i) => (
            <Button
              width="100%"
              key={i}
              onClick={() => setSelectedTemplate(i)}
              colorScheme="buttonTeal"
              variant={selectedTemplate === i ? 'solid' : 'outline'}
            >
              {tmpl.name}
            </Button>
          ))}
        </VStack>
      </ModalBody>
      {getFooter(selectedTemplate !== undefined ? onSubmit : undefined, isLoading)}
    </>
  );
};

interface Template {
  name: string;
  template?: CustomQuestion;
  skillId?: string;
}

const optStrings = (vals: string[]) => vals.map(v => ({ value: v, correct: true }));

const templates: Template[] = [
  {
    name: "Yes I'm ready",
    template: CustomQuestion.create({
      questionText: 'Select an answer',
      answer: {
        oneofKind: 'multipleChoice',
        multipleChoice: {
          shuffle: false,
          options: optStrings(["Yes I'm ready", "No I'm not ready"]),
        },
      },
    }),
  },
  {
    name: 'ABCD',
    template: CustomQuestion.create({
      questionText: 'Select an answer',
      answer: {
        oneofKind: 'multipleChoice',
        multipleChoice: {
          shuffle: false,
          options: optStrings(['A', 'B', 'C', 'D']),
        },
      },
    }),
  },
  {
    name: 'Numeric',
    skillId: 'f51483c0-d30c-4e4b-b7f5-a7167ea4daee',
  },
];
