// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/meet.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Meet } from './meet';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetRoomTokenResponse } from './meet';
import type { GetRoomTokenRequest } from './meet';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.lessons.v1.Meet
 */
export interface IMeetClient {
  /**
   * @generated from protobuf rpc: GetRoomToken(sparx.science.lessons.v1.GetRoomTokenRequest) returns (sparx.science.lessons.v1.GetRoomTokenResponse);
   */
  getRoomToken(
    input: GetRoomTokenRequest,
    options?: RpcOptions
  ): UnaryCall<GetRoomTokenRequest, GetRoomTokenResponse>;
}
/**
 * @generated from protobuf service sparx.science.lessons.v1.Meet
 */
export class MeetClient implements IMeetClient, ServiceInfo {
  typeName = Meet.typeName;
  methods = Meet.methods;
  options = Meet.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetRoomToken(sparx.science.lessons.v1.GetRoomTokenRequest) returns (sparx.science.lessons.v1.GetRoomTokenResponse);
   */
  getRoomToken(
    input: GetRoomTokenRequest,
    options?: RpcOptions
  ): UnaryCall<GetRoomTokenRequest, GetRoomTokenResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRoomTokenRequest, GetRoomTokenResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
