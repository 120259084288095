import React from '@chakra-ui/react';
import { LiveKitRoom } from '@livekit/components-react';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { LiveKitToken } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import { OneToOnePanel } from 'components/stream/OneToOnePanel';

export const OneToOneObserverView = ({
  token,
  lessonState,
}: {
  token: LiveKitToken;
  lessonState: LessonState;
}) => {
  return (
    <LiveKitRoom
      video={false}
      audio={false}
      token={token.token}
      serverUrl={token.serverUrl}
      connect={true}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      <OneToOnePanel lessonState={lessonState} />
    </LiveKitRoom>
  );
};
