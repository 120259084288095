import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { useSparxQuestionContext } from '../../question/SparxQuestionContext';
import { INumberFieldElement } from '../../question/types';
import { addKeydownListener } from '../../utils/keydownlistener';
import styles from './NumericKeypad.module.css';

/**
 * KeypadButtons renders the buttons for the keypad, and handles the logic of updating the answer in
 * response to clicks or key presses from the user.
 */
export const KeypadButtons = ({ element }: { element: INumberFieldElement }) => {
  const context = useSparxQuestionContext();
  const state = context.input.number_fields?.[element.ref];
  const value = state?.value || '';
  const { allowNegative, enforceNegative } = parseSignRestricted(state?.sign || '');

  const setAnswer = useCallback(
    (val: string) => {
      context.sendAction({
        action: 'set_numeric',
        ref: element.ref,
        value: val,
      });
    },
    [context, element.ref],
  );

  // if enforce negative is true, and the value is not negative, set it to negative
  useEffect(() => {
    if (enforceNegative && !value.startsWith('-')) {
      setAnswer(`-${value}`);
    }
  }, [value, enforceNegative, setAnswer]);

  const addToAnswer = (s: string) => {
    value.length < 12 && setAnswer(value + s);
  };

  // create the props for each button
  const buttonProps: Omit<KeypadButtonProps, 'animateButtonPress'>[] = digitNames.map(name => {
    const digit = digitFromName(name);
    return {
      key: digit,
      display: digit,
      area: name,
      onButtonPress: () => addToAnswer(digit),
      eventKey: digit,
    };
  });

  buttonProps.push(
    {
      display: '.',
      area: 'point',
      onButtonPress: () => addToAnswer('.'),
      eventKey: '.',
      disabled: value.length === 0 || value === '-' || value.includes('.'),
    },
    {
      display: <Backspace />,
      area: 'back',
      onButtonPress: () => setAnswer(value.slice(0, -1)),
      eventKey: 'Backspace',
      disabled: value.length === 0 || (value === '-' && enforceNegative),
    },
    {
      display: <FontAwesomeIcon title="negate" icon={faMinus} />,
      area: 'minus',
      onButtonPress: () => setAnswer(value.startsWith('-') ? value.slice(1) : `-${value}`),
      eventKey: '-',
      disabled: !allowNegative || enforceNegative,
    },
  );

  /**
   * buttonPressStatus contains a record of button ids and the timeout id for any animation currently
   * running on that button. This is used to cancel the animation before applying a new one if the
   * button is presses/clicked repeatedly
   */
  const buttonPressStatuses = useRef<Record<string, number>>({});

  /**
   * animateButtonPress handles animating the given button element to show it has been pressed.
   * This is used for both listening to key presses and clicks.
   */
  const animateButtonPress = useCallback((element: HTMLElement | null) => {
    if (element) {
      element.classList.add(styles.ButtonPress);

      const existingId = buttonPressStatuses.current[element.id];
      if (existingId) {
        window.clearTimeout(existingId);
      }
      const id = window.setTimeout(() => element.classList.remove(styles.ButtonPress), 200);
      buttonPressStatuses.current[element.id] = id;
    }
  }, []);

  // animate the buttons when the relevant key is pressed
  useEffect(() => {
    if (!context.readOnly) {
      return addKeydownListener(e => {
        buttonProps.forEach(button => {
          if (e.key === button.eventKey) {
            e.preventDefault();
            button.onButtonPress();
            animateButtonPress(document.getElementById(`button-${button.area}`) || null);
          }
        });
      });
    }
  }, [context.readOnly, animateButtonPress, buttonProps]);

  return (
    <div className={styles.ButtonsContainer}>
      {buttonProps.map(props => (
        <KeypadButton
          key={props.eventKey}
          readOnly={context.readOnly}
          {...props}
          animateButtonPress={animateButtonPress}
        />
      ))}
    </div>
  );
};

export type KeypadButtonProps = {
  display: ReactNode;
  area: string;
  onButtonPress: () => void;
  eventKey: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  animateButtonPress: (element: HTMLElement | null) => void;
};

const KeypadButton = ({
  display,
  area,
  onButtonPress,
  disabled,
  readOnly,
  className,
  animateButtonPress,
}: KeypadButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const onButtonPressWithAnimation = useCallback(() => {
    onButtonPress();
    animateButtonPress(buttonRef.current);
  }, [onButtonPress, animateButtonPress]);

  return (
    <button
      id={`button-${area}`}
      ref={buttonRef}
      style={{ gridArea: area }}
      className={classNames(
        styles.Button,
        disabled && styles.Disabled,
        readOnly && styles.Readonly,
        className,
      )}
      onClick={disabled ? () => undefined : onButtonPressWithAnimation}
      tabIndex={-1}
    >
      {display}
    </button>
  );
};

/**
 * digitFromName returns a string containing the named digit.
 * Must be lower case.
 * Returns 🤷‍♂️ if not recognized
 */
const digitFromName = (name: string) => {
  switch (name) {
    case 'zero':
      return '0';
    case 'one':
      return '1';
    case 'two':
      return '2';
    case 'three':
      return '3';
    case 'four':
      return '4';
    case 'five':
      return '5';
    case 'six':
      return '6';
    case 'seven':
      return '7';
    case 'eight':
      return '8';
    case 'nine':
      return '9';
    default:
      return '🤷‍♂️';
  }
};

const digitNames = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'zero'];

const Backspace = () => (
  <svg
    className={styles.BackspaceIcon}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46 24"
  >
    <title>backspace</title>
    <path
      d="M14.3759 1.2131C14.552 1.07503 14.7693 1 14.993 1H44C44.5523 1 45 1.44771 45 2V22C45 22.5523 44.5523 23 44 23H14.993C14.7693 23 14.552 22.925 14.3759 22.7869L1.62398 12.7869C1.11342 12.3865 1.11342 11.6135 1.62398 11.2131L14.3759 1.2131Z"
      strokeWidth="2"
    />
    <path d="M22 8L30.5 16.5" strokeWidth="2" strokeLinecap="round" />
    <path d="M30.5 8L22 16.5" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

/**
 * function to determine keypad sign properties based on the numeric question
 * sign restriction. This is called by components which use KeypadNumberInput.
 * @param sign: a restriction on the sign of a numeric answer. Can be either
 * 'positive', 'negative' or undefined.
 * allowNegative specifically means that negative is allowed, but not required,
 * and it mutually exclusive with enforceNegative
 */
const parseSignRestricted = (sign: string | undefined) => {
  const allowNegative = sign !== 'positive' && sign !== 'negative';
  const enforceNegative = sign === 'negative';
  return { allowNegative, enforceNegative };
};
