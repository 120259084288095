// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "dataanalysis/datamsg/schooldata.proto" (package "datamsg", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Int32Value } from '../../google/protobuf/wrappers';
import { HomeworkPlanType } from '../../apis/sparx/homeworkman/v1/homeworkman';
import { Weekday } from '../../apis/sparx/homeworkman/v1/homeworkman';
import { AttainmentLevel } from '../../apis/sparx/personalisation/attainmentlevel/v1/attainmentlevel';
import { Timestamp } from '../../google/protobuf/timestamp';
/**
 * @deprecated
 * @generated from protobuf message datamsg.SchoolUpdate
 */
export interface SchoolUpdate {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
}
/**
 * @generated from protobuf message datamsg.GroupUpdate
 */
export interface GroupUpdate {
  /**
   * @generated from protobuf field: string groupID = 1;
   */
  groupID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: int32 yeargroup = 4;
   */
  yeargroup: number;
  /**
   * @generated from protobuf field: string type = 5;
   */
  type: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTimestamp = 6;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endTimestamp = 7;
   */
  endTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 8;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: bool homeworkEnabled = 9;
   */
  homeworkEnabled: boolean;
  /**
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel attainmentLevel = 10;
   */
  attainmentLevel: AttainmentLevel;
  /**
   * @generated from protobuf field: string yearGroupID = 11;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel classroomAttainmentLevel = 12;
   */
  classroomAttainmentLevel: AttainmentLevel;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp homeworkEnabledAt = 13;
   */
  homeworkEnabledAt?: Timestamp;
  /**
   * @generated from protobuf field: bool locked = 14;
   */
  locked: boolean;
}
/**
 * @generated from protobuf message datamsg.GroupHomeworkPlanUpdate
 */
export interface GroupHomeworkPlanUpdate {
  /**
   * @generated from protobuf field: string groupID = 1;
   */
  groupID: string;
  /**
   * @generated from protobuf field: string sowID = 2;
   */
  sowID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
  /**
   * Default set day homeworks will be created for
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Weekday defaultSetDay = 4;
   */
  defaultSetDay: Weekday;
  /**
   * Default due day homeworks will be created for
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Weekday defaultDueDay = 5;
   */
  defaultDueDay: Weekday;
  /**
   * The type of the plan
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkPlanType planType = 6;
   */
  planType: HomeworkPlanType;
  /**
   * The hours component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeHours = 7;
   */
  setTimeHours: number;
  /**
   * The minutes component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeMinutes = 8;
   */
  setTimeMinutes: number;
  /**
   * The length in minutes of the compulsory packages for homeworks on this
   * plan.
   *
   * @generated from protobuf field: google.protobuf.Int32Value compulsoryMinutes = 9;
   */
  compulsoryMinutes?: Int32Value;
  /**
   * The hours component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeHours = 10;
   */
  dueTimeHours: number;
  /**
   * The minutes component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeMinutes = 11;
   */
  dueTimeMinutes: number;
  /**
   * If true, a times tables task should be added to homeworks on this plan
   *
   * @generated from protobuf field: bool hasTablesTask = 12;
   */
  hasTablesTask: boolean;
}
/**
 * @generated from protobuf message datamsg.AssessmentResult
 */
export interface AssessmentResult {
  /**
   * @generated from protobuf field: datamsg.AssessmentResult.AssessmentType assessmentType = 1;
   */
  assessmentType: AssessmentResult_AssessmentType;
  /**
   * @generated from protobuf field: string result = 2;
   */
  result: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp assessmentDate = 3;
   */
  assessmentDate?: Timestamp;
}
/**
 * @generated from protobuf enum datamsg.AssessmentResult.AssessmentType
 */
export enum AssessmentResult_AssessmentType {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: KS2_MATHS_SCALED_SCORE = 1;
   */
  KS2_MATHS_SCALED_SCORE = 1,
}
/**
 * @generated from protobuf message datamsg.StudentUpdate
 */
export interface StudentUpdate {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string code = 6;
   */
  code: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTimestamp = 3;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endTimestamp = 4;
   */
  endTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;
  /**
   * assessmentResults returns a list of known AssessmentResults for the
   * student. Note that an assessment result may be repeated in future
   * StudentUpdate messages but the lack of an assessment result in a future
   * message does not signal its deletion.
   *
   * @generated from protobuf field: repeated datamsg.AssessmentResult assessmentResults = 7;
   */
  assessmentResults: AssessmentResult[];
  /**
   * This would be values of the schoolman.Student.Type enum
   * (STUDENT/GUEST/TEMPLATE/CLASSROOM_GUEST),
   * but that would be a recursive import so instead it's the string
   * representation which is suitable for persisting directly
   * (e.g. 'STUDENT')
   *
   * @generated from protobuf field: string type = 8;
   */
  type: string;
  /**
   * These three flags give contextual information about the student. This
   * information comes from the school's MIS and is not stored in schoolman.
   *
   * @generated from protobuf field: bool fsmEligible = 9;
   */
  fsmEligible: boolean;
  /**
   * @generated from protobuf field: bool isEAL = 10;
   */
  isEAL: boolean;
  /**
   * @generated from protobuf field: bool pupilPremium = 11;
   */
  pupilPremium: boolean;
  /**
   * Flag to indicate whether the update came from an MIS Import. If so, we can
   * expect more fields, such as assessmentResults and the flags above, to be
   * set.
   *
   * @generated from protobuf field: bool updateFromMISImport = 12;
   */
  updateFromMISImport: boolean;
  /**
   * Further restricted data required for data analysis
   *
   * @generated from protobuf field: int32 monthOfBirth = 13;
   */
  monthOfBirth: number;
  /**
   * @generated from protobuf field: int32 yearOfBirth = 14;
   */
  yearOfBirth: number;
  /**
   * @generated from protobuf field: string gender = 15;
   */
  gender: string;
  /**
   * Flag to indicate that the student has been deleted
   *
   * @generated from protobuf field: bool deleted = 16;
   */
  deleted: boolean;
  /**
   * Further restricted data required to export into the restricted BQ data
   * analysis project
   *
   * @generated from protobuf field: string firstName = 17;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 18;
   */
  lastName: string;
  /**
   * A learning path spec name, in the format
   * `curriculums/{cu_id}/learningpathspecs/{lus_id}` or the string 'none'. By
   * default, we determine the difficulty of work that goes into a student's
   * homework based on our idea of their ability. This field allows the teacher
   * to override this decision for a student.
   *
   * @generated from protobuf field: string learningPathSpecOverride = 19;
   */
  learningPathSpecOverride: string;
  /**
   * The student's homework length setting.
   *
   * @generated from protobuf field: string homeworkLength = 20;
   */
  homeworkLength: string;
  /**
   * Whether higher only topics are excluded from the student's homework.
   *
   * @generated from protobuf field: bool excludeHigherOnlyTopics = 21;
   */
  excludeHigherOnlyTopics: boolean;
  /**
   * Whether the tables games timer is disabled for the student.
   *
   * @generated from protobuf field: bool disableTablesGamesTimer = 22;
   */
  disableTablesGamesTimer: boolean;
  /**
   * Whether the times tables enabled setting is overridden for this student
   * Includes the group of the student when the setting was changed, if they are
   * no longer in that group then they should use the groups setting
   *
   * @generated from protobuf field: datamsg.TablesTaskEnabledOverride tablesTaskEnabledOverride = 23;
   */
  tablesTaskEnabledOverride?: TablesTaskEnabledOverride;
  /**
   * Whether bookwork checks are disabled for the student.
   *
   * @generated from protobuf field: bool disableBookworkChecks = 24;
   */
  disableBookworkChecks: boolean;
}
/**
 * There is a similar message in schoolman.proto but we can't use it due to it
 * already using a type from this file
 *
 * @generated from protobuf message datamsg.TablesTaskEnabledOverride
 */
export interface TablesTaskEnabledOverride {
  /**
   * @generated from protobuf field: bool enabled = 1;
   */
  enabled: boolean;
  /**
   * @generated from protobuf field: string group_id = 2;
   */
  groupId: string;
}
/**
 * @generated from protobuf message datamsg.GroupMembershipUpdate
 */
export interface GroupMembershipUpdate {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string groupID = 2;
   */
  groupID: string;
  /**
   * @generated from protobuf field: bool active = 3;
   */
  active: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
}
/**
 * Deprecated: This event is no longer stored in BigQuery by MsgElephant
 *
 *
 * @generated from protobuf message datamsg.StudentHomeworkStatusUpdate
 */
export interface StudentHomeworkStatusUpdate {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string homeworkID = 2;
   */
  homeworkID: string;
  /**
   * @generated from protobuf field: datamsg.HomeworkStatus status = 3;
   */
  status: HomeworkStatus;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message datamsg.StaffUpdate
 */
export interface StaffUpdate {
  /**
   * @generated from protobuf field: string staffID = 1;
   */
  staffID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: repeated string roles = 3;
   */
  roles: string[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: string title = 5;
   */
  title: string;
  /**
   * @generated from protobuf field: string firstName = 6;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 7;
   */
  lastName: string;
  /**
   * Flag to indicate that the staff member has been deleted
   *
   * @generated from protobuf field: bool deleted = 8;
   */
  deleted: boolean;
  /**
   * @generated from protobuf field: string email_address = 9;
   */
  emailAddress: string;
}
/**
 * @generated from protobuf message datamsg.StaffGroupMembershipUpdate
 */
export interface StaffGroupMembershipUpdate {
  /**
   * @generated from protobuf field: string staffID = 1;
   */
  staffID: string;
  /**
   * @generated from protobuf field: string groupID = 2;
   */
  groupID: string;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: bool feedbackEnabled = 4;
   */
  feedbackEnabled: boolean;
  /**
   * @generated from protobuf field: bool active = 5;
   */
  active: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 6;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message datamsg.SchoolYearPeriodUpdate
 */
export interface SchoolYearPeriodUpdate {
  /**
   * @generated from protobuf field: string schoolYearPeriodID = 1;
   */
  schoolYearPeriodID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: int32 year = 3;
   */
  year: number;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string type = 5;
   */
  type: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTimestamp = 6;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endTimestamp = 7;
   */
  endTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: bool active = 8;
   */
  active: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 9;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message datamsg.SchoolYearUpdate
 */
export interface SchoolYearUpdate {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: int32 year = 2;
   */
  year: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 3;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 4;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp homeworkStartDate = 5;
   */
  homeworkStartDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp homeworkEndDate = 6;
   */
  homeworkEndDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 7;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message datamsg.YeargroupUpdate
 */
export interface YeargroupUpdate {
  /**
   * @generated from protobuf field: string yeargroupID = 1;
   */
  yeargroupID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: int32 legacyYearGroup = 4;
   */
  legacyYearGroup: number;
  /**
   * @generated from protobuf field: bool lessonsEnabled = 5;
   */
  lessonsEnabled: boolean;
  /**
   * After this time, the year group should no longer be visible on TP.
   * If null, the Year Group will never expire.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiryTime = 6;
   */
  expiryTime?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 7;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: repeated string products = 8;
   */
  products: string[];
}
/**
 * @generated from protobuf message datamsg.StudentExternalIDUpdate
 */
export interface StudentExternalIDUpdate {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string external_id = 3;
   */
  externalId: string;
  /**
   * This would be values of the schoolman.StudentExternalIDType enum
   * (SXIDTYPE_UNSPECIFIED/SXIDTYPE_UPN),
   * but that would be a recursive import so instead it's the string
   * representation which is suitable for persisting directly
   * (e.g. 'SXIDTYPE_UPN')
   *
   * @generated from protobuf field: string sxid_type = 4;
   */
  sxidType: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;
  /**
   * if the students external ID has been removed.
   *
   * @generated from protobuf field: bool deleted = 6;
   */
  deleted: boolean;
}
/**
 * @generated from protobuf message datamsg.StudentResetPasswordRequestMessage
 */
export interface StudentResetPasswordRequestMessage {
  /**
   * The student who requested their password to be reset
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The last time they successfully logged in
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login_at = 2;
   */
  lastLoginAt?: Timestamp;
  /**
   * The timestamp of the request
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf enum datamsg.HomeworkStatus
 */
export enum HomeworkStatus {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: DETENTION = 1;
   */
  DETENTION = 1,
  /**
   * @generated from protobuf enum value: EXTENSION = 2;
   */
  EXTENSION = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolUpdate$Type extends MessageType<SchoolUpdate> {
  constructor() {
    super('datamsg.SchoolUpdate', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message datamsg.SchoolUpdate
 */
export const SchoolUpdate = new SchoolUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupUpdate$Type extends MessageType<GroupUpdate> {
  constructor() {
    super('datamsg.GroupUpdate', [
      { no: 1, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'yeargroup', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'startTimestamp', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'endTimestamp', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'homeworkEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'attainmentLevel',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 11,
        name: 'yearGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'classroomAttainmentLevel',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 13,
        name: 'homeworkEnabledAt',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 14, name: 'locked', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.GroupUpdate
 */
export const GroupUpdate = new GroupUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupHomeworkPlanUpdate$Type extends MessageType<GroupHomeworkPlanUpdate> {
  constructor() {
    super('datamsg.GroupHomeworkPlanUpdate', [
      { no: 1, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'sowID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'defaultSetDay',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.Weekday', Weekday],
      },
      {
        no: 5,
        name: 'defaultDueDay',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.Weekday', Weekday],
      },
      {
        no: 6,
        name: 'planType',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkPlanType', HomeworkPlanType],
      },
      {
        no: 7,
        name: 'setTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'setTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'compulsoryMinutes',
        kind: 'message',
        T: () => Int32Value,
      },
      {
        no: 10,
        name: 'dueTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'dueTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'hasTablesTask',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.GroupHomeworkPlanUpdate
 */
export const GroupHomeworkPlanUpdate = new GroupHomeworkPlanUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentResult$Type extends MessageType<AssessmentResult> {
  constructor() {
    super('datamsg.AssessmentResult', [
      {
        no: 1,
        name: 'assessmentType',
        kind: 'enum',
        T: () => [
          'datamsg.AssessmentResult.AssessmentType',
          AssessmentResult_AssessmentType,
        ],
      },
      { no: 2, name: 'result', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'assessmentDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.AssessmentResult
 */
export const AssessmentResult = new AssessmentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentUpdate$Type extends MessageType<StudentUpdate> {
  constructor() {
    super('datamsg.StudentUpdate', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'startTimestamp', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'endTimestamp', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'assessmentResults',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentResult,
      },
      { no: 8, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'fsmEligible', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'isEAL', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 11,
        name: 'pupilPremium',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'updateFromMISImport',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 13,
        name: 'monthOfBirth',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 14,
        name: 'yearOfBirth',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 15, name: 'gender', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 16, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 17, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 18, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 19,
        name: 'learningPathSpecOverride',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: 'homeworkLength',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 21,
        name: 'excludeHigherOnlyTopics',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 22,
        name: 'disableTablesGamesTimer',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 23,
        name: 'tablesTaskEnabledOverride',
        kind: 'message',
        T: () => TablesTaskEnabledOverride,
      },
      {
        no: 24,
        name: 'disableBookworkChecks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StudentUpdate
 */
export const StudentUpdate = new StudentUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TablesTaskEnabledOverride$Type extends MessageType<TablesTaskEnabledOverride> {
  constructor() {
    super('datamsg.TablesTaskEnabledOverride', [
      { no: 1, name: 'enabled', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.TablesTaskEnabledOverride
 */
export const TablesTaskEnabledOverride = new TablesTaskEnabledOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupMembershipUpdate$Type extends MessageType<GroupMembershipUpdate> {
  constructor() {
    super('datamsg.GroupMembershipUpdate', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.GroupMembershipUpdate
 */
export const GroupMembershipUpdate = new GroupMembershipUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomeworkStatusUpdate$Type extends MessageType<StudentHomeworkStatusUpdate> {
  constructor() {
    super('datamsg.StudentHomeworkStatusUpdate', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'status',
        kind: 'enum',
        T: () => ['datamsg.HomeworkStatus', HomeworkStatus],
      },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StudentHomeworkStatusUpdate
 */
export const StudentHomeworkStatusUpdate =
  new StudentHomeworkStatusUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffUpdate$Type extends MessageType<StaffUpdate> {
  constructor() {
    super('datamsg.StaffUpdate', [
      { no: 1, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 9,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StaffUpdate
 */
export const StaffUpdate = new StaffUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffGroupMembershipUpdate$Type extends MessageType<StaffGroupMembershipUpdate> {
  constructor() {
    super('datamsg.StaffGroupMembershipUpdate', [
      { no: 1, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'feedbackEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 5, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StaffGroupMembershipUpdate
 */
export const StaffGroupMembershipUpdate = new StaffGroupMembershipUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYearPeriodUpdate$Type extends MessageType<SchoolYearPeriodUpdate> {
  constructor() {
    super('datamsg.SchoolYearPeriodUpdate', [
      {
        no: 1,
        name: 'schoolYearPeriodID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'startTimestamp', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'endTimestamp', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.SchoolYearPeriodUpdate
 */
export const SchoolYearPeriodUpdate = new SchoolYearPeriodUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYearUpdate$Type extends MessageType<SchoolYearUpdate> {
  constructor() {
    super('datamsg.SchoolYearUpdate', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'homeworkStartDate', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'homeworkEndDate', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.SchoolYearUpdate
 */
export const SchoolYearUpdate = new SchoolYearUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class YeargroupUpdate$Type extends MessageType<YeargroupUpdate> {
  constructor() {
    super('datamsg.YeargroupUpdate', [
      {
        no: 1,
        name: 'yeargroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'legacyYearGroup',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'lessonsEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 6, name: 'expiryTime', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 8,
        name: 'products',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.YeargroupUpdate
 */
export const YeargroupUpdate = new YeargroupUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentExternalIDUpdate$Type extends MessageType<StudentExternalIDUpdate> {
  constructor() {
    super('datamsg.StudentExternalIDUpdate', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'external_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'sxid_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StudentExternalIDUpdate
 */
export const StudentExternalIDUpdate = new StudentExternalIDUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentResetPasswordRequestMessage$Type extends MessageType<StudentResetPasswordRequestMessage> {
  constructor() {
    super('datamsg.StudentResetPasswordRequestMessage', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_login_at', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message datamsg.StudentResetPasswordRequestMessage
 */
export const StudentResetPasswordRequestMessage =
  new StudentResetPasswordRequestMessage$Type();
