import { motion } from 'framer-motion';
import { useMemo } from 'react';

import styles from '../../question/SparxQuestion.module.css';

interface Message {
  speaker: string;
  lines: string[];
}

export const Conversation = ({ children }: { children: string }) => {
  const split = useMemo(
    () => ([undefined] as (Message | undefined)[]).concat(splitConversation(children)),
    [children],
  );

  return (
    <div>
      {split.map((r, i) => (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: i * 1.5 }}
          key={i}
        >
          {r && (
            <div className={styles.SpeechBox}>
              <div className={styles.SpeechBoxSpeaker}>{r.speaker}</div>
              {r.lines.map((l, j) => (
                <p key={j}>{l}</p>
              ))}
            </div>
          )}
          {split[i + 1] && (
            <motion.div
              className={styles.SpeechBoxTyping}
              initial={{ display: 'block' }}
              animate={{ display: 'none' }}
              transition={{ delay: (i + 1) * 1.5 }}
            >
              {split[i + 1]?.speaker} is typing...
            </motion.div>
          )}
        </motion.div>
      ))}
    </div>
  );
};

const splitConversation = (text: string): Message[] =>
  text
    .trim()
    .split(/\n\n/g)
    .map(s => {
      const speech = s.trim();
      const lines = speech.split(/\n/g);
      const speaker = lines[0].trim().replace(/:$/, '');
      return {
        speaker,
        lines: lines.slice(1),
      };
    });
