import { Box } from '@chakra-ui/react';
import { ensureTokenFetcherStarted } from '@sparx/science/api';
import { ClassSelectionProvider } from '@sparx/science/app/ClassSelection';
import { Header } from 'components/header/Header';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const Content = () => {
  // Start the token fetcher
  useEffect(() => {
    ensureTokenFetcherStarted();
  }, []);

  return (
    <ClassSelectionProvider>
      <Header />
      <Box flex={1} bg="#fffcf7" position="relative">
        <Outlet />
      </Box>
    </ClassSelectionProvider>
  );
};
