import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  CreateLessonActivityRequest,
  JoinLessonResponse,
  LessonActivity,
  LessonFeature,
  LessonState,
  StudentState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { StudentNotes } from '@sparx/api/apis/sparx/science/lessons/v1/notes';
import {
  HandsDownNotification,
  MarkingNotification,
} from '@sparx/api/apis/sparx/science/lessons/v1/notifications';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Any } from '@sparx/api/google/protobuf/any';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  lessonsClient,
  lessonsClientWithKeepalive,
  lessonsNotesClient,
  plannerClient,
  tokenMetadata,
} from 'api';
import { queryClient } from 'api/client';
import { clearPackages } from 'api/packages';
import { Options } from 'api/school';
import { getSchoolID } from 'api/sessions';
import { differenceInSeconds } from 'date-fns';
import { useMemo } from 'react';
import { anySignal } from 'utils/aborts';

export const useCreateLesson = () =>
  useMutation({
    mutationFn: async (args: {
      displayName: string;
      groupNames: string[];
      features: LessonFeature[];
    }) =>
      lessonsClient.createLesson({ schoolName: `schools/${await getSchoolID()}`, ...args })
        .response,
    onSuccess: nl => {
      queryClient.setQueryData(['lessons', 'list'], (data: LessonState[] | undefined) => {
        if (!data || !nl.lesson) return data;
        return data.filter(d => d.lessonName === nl.lesson?.lessonName).concat([nl.lesson]);
      });
    },
  });

export const useEndLesson = () =>
  useMutation({
    mutationFn: async (lessonName: string) => lessonsClient.endLesson({ lessonName }).response,
    onSuccess: (_, deletedName) => {
      queryClient.setQueryData(['lessons', 'list'], (data: LessonState[] | undefined) => {
        if (!data) return data;
        return data.filter(d => d.lessonName !== deletedName);
      });
    },
  });

let lessonServerOffset = 0;
export const getLessonServerOffset = () => lessonServerOffset;
const setLessonServerOffset = (now: Timestamp) => {
  lessonServerOffset = new Date().getUTCSeconds() - Timestamp.toDate(now).getUTCSeconds();
};

const maxSecondsWithoutMessage = 10;
const abortLoopInterval = 4; // secs
const autoResetConnectionTime = 60; // secs

export const useWatchLesson = (lesson: string | undefined, sessionId = '', schoolId?: string) => {
  // Ensure that the lesson id is an id and not a resouce name
  const lessonId = useMemo(
    () => (lesson?.startsWith('schools/') ? lesson.split('/')[3] : lesson),
    [lesson],
  );

  useQuery({
    queryKey: ['lesson', lessonId, 'watch'],
    queryFn: async ({ signal: querySignal }) => {
      // Setup an abort controller for the message interval loop to use to cancel
      const controller = new AbortController();
      const signal = anySignal(querySignal, controller.signal);

      const lessonName = `schools/${schoolId || (await getSchoolID())}/lessons/${lessonId}`;

      console.log('[event stream] starting');
      const call = lessonsClient.watchLessonState(
        { lessonName, sessionId: sessionId },
        {
          ...(await tokenMetadata()),
          abort: signal,
        },
      );

      let lastMessage = new Date();
      (async () => {
        let ticks = 0;
        while (!signal?.aborted) {
          // If we have not had a message in the last 20 seconds, we abort the stream
          if (differenceInSeconds(new Date(), lastMessage) > maxSecondsWithoutMessage) {
            console.error(
              '[event stream] aborting due to no message in ' +
                maxSecondsWithoutMessage +
                ' seconds',
            );
            controller.abort('No message in ' + maxSecondsWithoutMessage + ' seconds');
            return;
          }

          // Auto abort checker
          {
            let useAutoAbort = 'true';
            try {
              useAutoAbort = localStorage.getItem('sci/lessons/reset') || 'true';
            } catch {
              /* ignore */
            }
            if (useAutoAbort === 'true' && ticks >= autoResetConnectionTime / abortLoopInterval) {
              controller.abort(`Auto reset after ${autoResetConnectionTime}s`);
              return;
            }
          }

          ticks++;
          await sleep(abortLoopInterval);
        }
      })().catch(err => {
        console.error('[event stream] Error in timeout loop:', err);
      });

      let previousActivities = -1;
      call.responses.onMessage(message => {
        lastMessage = new Date();
        console.debug('[event stream] message:', message);
        if (message.lessonState) {
          queryClient.setQueryData(['lesson', lessonId, 'state'], message.lessonState);
          if (message.serverTime) {
            setLessonServerOffset(message.serverTime);
          }

          // If the number of activities has changed then we should refetch them for this lesson
          if (previousActivities !== -1) {
            if (message.lessonState.activities.length !== previousActivities) {
              queryClient.invalidateQueries(['lesson', lessonId, 'assignments']);
            }
          }
          previousActivities = message.lessonState.activities.length;
        }
        if (message.studentStates) {
          queryClient.setQueryData(['lesson', lessonId, 'students'], message.studentStates.states);
        }

        const dismissNotifications: string[] = [];
        for (const notification of message.notifications) {
          if (notification.notification) {
            if (Any.contains(notification.notification, MarkingNotification)) {
              const content = Any.unpack(notification.notification, MarkingNotification);

              queryClient.invalidateQueries(['activity', content.taskItemName]);
              queryClient.invalidateQueries(['packages', content.taskItemName.split('/')[1]]);
              queryClient.invalidateQueries(['packages']);

              // If they got it wrong - instantly switch to it
              if (content.status === TaskItem_Status.INCORRECT) {
                queryClient.setQueryData(['lesson', lessonName, 'forced'], content.taskItemName);
              }
            } else if (Any.contains(notification.notification, HandsDownNotification)) {
              queryClient.setQueryData(['handsup'], false);
            }
          }
          dismissNotifications.push(notification.notificationId);
        }

        // Ack all of the notifications that we have processed
        if (dismissNotifications.length > 0) {
          (async () =>
            lessonsClient.acknowledgeLessonNotification({
              lessonName,
              notificationIds: dismissNotifications,
            }))();
        }
      });

      call.responses.onError(err => {
        console.warn('[event stream] errored:', err);
        if (sessionId && err instanceof RpcError) {
          if (err.code === 'NOT_FOUND' || err.code === 'INVALID_ARGUMENT') {
            clearCurrentLesson();
          }
        }
      });

      const { status, trailers } = await call;
      console.warn('[event stream] ended:', { status, trailers });
      throw new Error('Stream ended'); // Ensures that react-query will retry
    },
    retry: true,
    retryDelay: 1000,
    refetchIntervalInBackground: true,
    enabled: !!lessonId,
  });

  return useQuery({
    queryKey: ['lesson', lessonId, 'state'],
    queryFn: () => new Promise<LessonState>(() => undefined), // never resolve
    refetchInterval: false,
    enabled: !!lessonId,
  });
};

const sleep = async (secs: number) => {
  return new Promise(resolve => setTimeout(resolve, secs * 1000));
};

export interface StudentStateWithHandupIndex extends StudentState {
  handUpIndex?: number;
}

export const useStudentLessonStates = (lessonId: string) =>
  useQuery({
    queryKey: ['lesson', lessonId, 'students'],
    queryFn: () => new Promise<StudentState[]>(() => undefined), // never resolve
    refetchInterval: false,
    enabled: lessonId !== undefined,
    select: (data: StudentStateWithHandupIndex[]) => {
      const sorted = data.sort(handsUpSort);
      return sorted.map((d, i) => ({ ...d, handUpIndex: d.handUpTime ? i : undefined }));
    },
  });

export const handsUpSort = (a: StudentStateWithHandupIndex, b: StudentStateWithHandupIndex) => {
  if (a.handUpTime && !b.handUpTime) return -1;
  if (!a.handUpTime && b.handUpTime) return 1;
  if (a.handUpTime && b.handUpTime)
    return (a.handUpTime.seconds || 0) - (b.handUpTime.seconds || 0);
  return 0;
};

export const useLessonAssignments = (lessonId: string, opts: Options) =>
  useQuery({
    queryKey: ['lesson', lessonId, 'assignments'],
    queryFn: async () =>
      plannerClient
        .listAssignments({
          schoolName: `schools/${await getSchoolID()}`,
          query: {
            oneofKind: 'lessonId',
            lessonId,
          },
        })
        .response.then(r => r.assignments),
    staleTime: Infinity, // never refresh
    ...opts,
  });

export const useUpdateLessonState = (lessonId: string) =>
  useMutation({
    mutationFn: async (patch: Partial<Omit<LessonState, 'lessonId' | 'lessonCode'>>) =>
      lessonsClientWithKeepalive.updateLessonState({
        lessonState: LessonState.create({
          lessonName: `schools/${await getSchoolID()}/lessons/${lessonId}`,
          ...patch,
        }),
        updateMask: {
          paths: Object.keys(patch),
        },
        clearHandsUpStudentNames: [],
      }),
  });

export const useUpdateLessonActivityState = (lessonId: string) =>
  useMutation({
    mutationFn: async (patch: Partial<LessonActivity> & { lessonActivityId: string }) =>
      lessonsClient.updateLessonState({
        lessonState: LessonState.create({
          lessonName: `schools/${await getSchoolID()}/lessons/${lessonId}`,
          activities: [LessonActivity.create(patch)],
        }),
        updateMask: {
          paths: Object.keys(patch)
            .filter(p => p !== 'lessonActivityId')
            .map(a => `activities.${patch.lessonActivityId}.${a}`),
        },
        clearHandsUpStudentNames: [],
      }),
  });

export const useClearHandsUp = (lessonId: string) =>
  useMutation({
    mutationFn: async (clearHandsUpStudentNames: string[]) => {
      const response = await lessonsClient.updateLessonState({
        lessonState: LessonState.create({
          lessonName: `schools/${await getSchoolID()}/lessons/${lessonId}`,
        }),
        clearHandsUpStudentNames,
      }).response;

      // The update sends a student notification which causes the students hands up to automatically
      // dismiss. We add a slight delay here so that there is time for the notification to reach the student.
      await sleep(1);

      return response;
    },
  });

export const useCreateLessonActivity = (lessonId: string) =>
  useMutation({
    mutationFn: async (activity: Omit<CreateLessonActivityRequest, 'lessonName'>) =>
      lessonsClient.createLessonActivity({
        lessonName: `schools/${await getSchoolID()}/lessons/${lessonId}`,
        ...activity,
      }).response,
  });

export const useJoinLesson = () =>
  useMutation({
    mutationFn: async (lessonCode: string) => lessonsClient.joinLesson({ lessonCode }).response,
    onSuccess: setCurrentLesson,
  });

export const useLeaveLesson = () => {
  const { data: currentLesson } = useCurrentLesson();
  return useMutation({
    mutationFn: async () =>
      lessonsClient.leaveLesson({
        lessonName: currentLesson?.lessonName || '',
        sessionId: currentLesson?.sessionId || '',
      }).response,
    onSuccess: clearCurrentLesson,
    onError: err => {
      if (err instanceof RpcError && err.code === 'NOT_FOUND') {
        clearCurrentLesson();
      }
    },
  });
};

export const useCurrentLesson = () =>
  useQuery({
    queryKey: ['lesson'],
    queryFn: () => {
      try {
        const data = localStorage.getItem('sci/lesson');
        if (data) {
          return JoinLessonResponse.fromJsonString(data);
        }
      } catch {
        /* ignore */
      }
      return null;
    },
    staleTime: Infinity,
  });

export const useStudentLessonNotes = (studentID: string) =>
  useQuery({
    queryKey: ['lesson', 'notes', studentID],
    queryFn: async () =>
      lessonsNotesClient.getStudentNotes({
        studentName: `schools/${await getSchoolID()}/students/${studentID}`,
      }).response,
    refetchInterval: 20 * 1000, // 20 seconds
    select: data => data.notes || StudentNotes.create(),
  });

export const useUpdateStudentLessonNotes = (studentID: string) =>
  useMutation({
    mutationFn: async (notes: string) =>
      lessonsNotesClient.updateStudentNotes({
        studentName: `schools/${await getSchoolID()}/students/${studentID}`,
        text: notes,
      }).response,
    onSuccess: notes => {
      notes.notes && queryClient.setQueryData(['lesson', 'notes', studentID], notes);
    },
  });

export const useIsInLesson = () => Boolean(useCurrentLesson().data?.lessonName);

export const setCurrentLesson = (data: JoinLessonResponse) => {
  clearPackages();
  localStorage.setItem('sci/lesson', JoinLessonResponse.toJsonString(data));
  queryClient.invalidateQueries(['lesson']);
};

export const clearCurrentLesson = () => {
  clearPackages();
  localStorage.removeItem('sci/lesson');
  queryClient.invalidateQueries(['lesson']);
};
