// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/planning/v1/sol.proto" (package "sparx.planning.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * This is the SOL resource message
 *
 * @generated from protobuf message sparx.planning.v1.SchemeOfLearning
 */
export interface SchemeOfLearning {
  /**
   * Name of the scheme of learning in the form "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: sparx.planning.v1.ResourceMetadata metadata = 2;
   */
  metadata?: ResourceMetadata;
  /**
   * Name of the template this SOL is derived from originally in the form
   * "schoolGroups/{schoolgroup_id}/soltemplates/{soltemplate_id}".  It can be
   * empty if the SOL is not derived from a template.
   *
   * @generated from protobuf field: string original_template = 3;
   */
  originalTemplate: string;
  /**
   * Name of the curriculum that forms the base for this SOL, in the form
   * "curriculums/{curriculum_id}"
   *
   * @generated from protobuf field: string base_curriculum = 4;
   */
  baseCurriculum: string;
  /**
   * School year that this scheme applies to, in the form
   * "schools/{school_id}/years/{year_id}".  For now year_id can be an integer
   * representing the calendar year.
   *
   * @generated from protobuf field: string school_year = 5;
   */
  schoolYear: string;
  /**
   * Name of the scheme to display to users
   *
   * @generated from protobuf field: string display_name = 6;
   */
  displayName: string;
  /**
   * Payload of the scheme of the learning
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearningSchedule schedule = 7;
   */
  schedule?: SchemeOfLearningSchedule;
  /**
   * Whether the schedule of the SOL has any no homework weeks
   *
   * @generated from protobuf field: bool has_no_homework_weeks = 8;
   */
  hasNoHomeworkWeeks: boolean;
}
/**
 * This is the SOL Template resource message
 *
 * @generated from protobuf message sparx.planning.v1.SchemeOfLearningTemplate
 */
export interface SchemeOfLearningTemplate {
  /**
   * Name of the template in the form
   * "schoolGroups/{schoolgroup_id}/solTemplates/{soltemplate_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: sparx.planning.v1.ResourceMetadata metadata = 2;
   */
  metadata?: ResourceMetadata;
  /**
   * Name of the curriculum that forms the base for this SOL, in the form
   * "curriculums/{curriculum_id}"
   *
   * @generated from protobuf field: string base_curriculum = 3;
   */
  baseCurriculum: string;
  /**
   * Name of the scheme to display to users
   *
   * @generated from protobuf field: string display_name = 4;
   */
  displayName: string;
  /**
   * Name of the syllabus this belongs to
   *
   * @generated from protobuf field: string syllabus = 6;
   */
  syllabus: string;
  /**
   * Payload of the scheme of the learning
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearningSchedule schedule = 5;
   */
  schedule?: SchemeOfLearningSchedule;
}
/**
 * @generated from protobuf message sparx.planning.v1.ResourceMetadata
 */
export interface ResourceMetadata {
  /**
   * Version of the resource. A version should be incremented by 1 in each update.
   * This is to prevent conflicting changes.
   *
   * @generated from protobuf field: int32 version = 1;
   */
  version: number;
  /**
   * Time created
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;
  /**
   * User id (name?) of the creator of the resource.
   *
   * @generated from protobuf field: string created_by = 3;
   */
  createdBy: string;
  /**
   * Time last modified.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 4;
   */
  updateTime?: Timestamp;
  /**
   * User id (name?) of the user updating of this resource
   *
   * @generated from protobuf field: string updated_by = 5;
   */
  updatedBy: string;
  /**
   * Status of the resource (draft/published)
   *
   * @generated from protobuf field: sparx.planning.v1.ResourceStatus status = 6;
   */
  status: ResourceStatus;
  /**
   * Tags attached to the resource
   *
   * @generated from protobuf field: repeated string tags = 7;
   */
  tags: string[];
}
/**
 * The specification of all that happens in a SOL
 *
 * @generated from protobuf message sparx.planning.v1.SchemeOfLearningSchedule
 */
export interface SchemeOfLearningSchedule {
  /**
   * List of weeks in the SOL
   *
   * @generated from protobuf field: repeated sparx.planning.v1.SchemeOfLearningWeek weeks = 1;
   */
  weeks: SchemeOfLearningWeek[];
}
/**
 * The specification of a single week in the SOL
 *
 * @generated from protobuf message sparx.planning.v1.SchemeOfLearningWeek
 */
export interface SchemeOfLearningWeek {
  /**
   * Week number from the start of the school year (starting at 1)
   *
   * @generated from protobuf field: int32 week_index = 1;
   */
  weekIndex: number;
  /**
   * Type of homework that goes into this week
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearningWeekType week_type = 2;
   */
  weekType: SchemeOfLearningWeekType;
  /**
   * Units of work that go into this week
   *
   * @generated from protobuf field: repeated sparx.planning.v1.WorkUnit work_units = 3;
   */
  workUnits: WorkUnit[];
}
/**
 * A single unit of work that can go in a SOL week
 *
 * @generated from protobuf message sparx.planning.v1.WorkUnit
 */
export interface WorkUnit {
  /**
   * ID that should be unique to the work unit within the scheme of learning.
   * As long as we are using legacy homework planning, those IDs have to be
   * globally unique.  In particular if a SOL is cloned from another (or a
   * template), its work units should not share the same IDs.
   *
   * If this is blank, a unique SolUnitId will be generated for the legacy
   * API.
   *
   * @generated from protobuf field: string work_unit_id = 2;
   */
  workUnitId: string;
  /**
   * @generated from protobuf oneof: payload
   */
  payload:
    | {
        oneofKind: 'topic';
        /**
         * Topic name in the form "curriculums/{curriculum_id}/topics/{topic_id}"
         *
         * @generated from protobuf field: string topic = 1;
         */
        topic: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSchemesRequest
 */
export interface ListSchemesRequest {
  /**
   * Name of the school in the form "schools/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * School year that this scheme applies to, in the form
   * "schools/{school_id}/years/{year_id}".  For now year_id can be an integer
   * representing the calendar year.
   *
   * @generated from protobuf field: string school_year = 2;
   */
  schoolYear: string;
  /**
   * Unless this is set to true, the returned SOLs will not have their
   * schedule populated
   *
   * @generated from protobuf field: bool include_schedules = 3;
   */
  includeSchedules: boolean;
  /**
   * If this is set to true, draft SOLs are returned as well
   *
   * @generated from protobuf field: bool include_drafts = 5;
   */
  includeDrafts: boolean;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSchemesResponse
 */
export interface ListSchemesResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.SchemeOfLearning schemes = 1;
   */
  schemes: SchemeOfLearning[];
}
/**
 * @generated from protobuf message sparx.planning.v1.GetSchemeRequest
 */
export interface GetSchemeRequest {
  /**
   * Name of the SOL to get, in the form "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * If present, get the working version for this user (defaults to PUBLISHED)
   *
   * @generated from protobuf field: sparx.planning.v1.ResourceStatus status = 2;
   */
  status: ResourceStatus;
}
/**
 * @generated from protobuf message sparx.planning.v1.CreateSchemeRequest
 */
export interface CreateSchemeRequest {
  /**
   * Name of the school this scheme is for in the form "schools/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * The SOL to create
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearning scheme = 2;
   */
  scheme?: SchemeOfLearning;
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdateSchemeRequest
 */
export interface UpdateSchemeRequest {
  /**
   * The SOL to update
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearning scheme = 1;
   */
  scheme?: SchemeOfLearning;
  /**
   * List of fields to update
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.planning.v1.DeleteSchemeRequest
 */
export interface DeleteSchemeRequest {
  /**
   * Name of SOL to delete, in the form "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.UndeleteSchemeRequest
 */
export interface UndeleteSchemeRequest {
  /**
   * Name of SOL to undelete, in the form "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.PublishSchemeRequest
 */
export interface PublishSchemeRequest {
  /**
   * Name of the scheme to publish, in the form
   * "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Version of the scheme to publish.
   *
   * @generated from protobuf field: int32 version = 2;
   */
  version: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.ResetDraftSchemeRequest
 */
export interface ResetDraftSchemeRequest {
  /**
   * Name of the scheme to reset the draft of, in the form
   * "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.UnpublishSchemeRequest
 */
export interface UnpublishSchemeRequest {
  /**
   * Name of the published scheme to mark as a draft, in the form
   * "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Version to be set as a draft
   *
   * @generated from protobuf field: int32 version = 2;
   */
  version: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSchemeTemplatesRequest
 */
export interface ListSchemeTemplatesRequest {
  /**
   * Name of the school group in the form "schoolGroups/{school_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Unless this is set to true, the returned SOLs will not have their
   * schedule populated
   *
   * @generated from protobuf field: bool include_schedules = 3;
   */
  includeSchedules: boolean;
  /**
   * If this is set to true, draft SOLs are returned as well
   *
   * @generated from protobuf field: bool include_drafts = 5;
   */
  includeDrafts: boolean;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSchemeTemplatesResponse
 */
export interface ListSchemeTemplatesResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.SchemeOfLearningTemplate templates = 1;
   */
  templates: SchemeOfLearningTemplate[];
}
/**
 * @generated from protobuf message sparx.planning.v1.GetSchemeTemplateRequest
 */
export interface GetSchemeTemplateRequest {
  /**
   * Name of the SOL template to get, in the form "schools/{school_id}/sols/{sol_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Set to DRAFT to get the draft version (defaults to PUBLISHED)
   *
   * @generated from protobuf field: sparx.planning.v1.ResourceStatus status = 2;
   */
  status: ResourceStatus;
}
/**
 * @generated from protobuf message sparx.planning.v1.CreateSchemeTemplateRequest
 */
export interface CreateSchemeTemplateRequest {
  /**
   * Name of the school group this template is for in the form "schoolGroups/{schoolgroup_id}"
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * The SOL to create
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearningTemplate template = 2;
   */
  template?: SchemeOfLearningTemplate;
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdateSchemeTemplateRequest
 */
export interface UpdateSchemeTemplateRequest {
  /**
   * The SOL to update
   *
   * @generated from protobuf field: sparx.planning.v1.SchemeOfLearningTemplate template = 1;
   */
  template?: SchemeOfLearningTemplate;
  /**
   * List of fields to update
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.planning.v1.DeleteSchemeTemplateRequest
 */
export interface DeleteSchemeTemplateRequest {
  /**
   * Name of SOL template to delete, in the form
   * "schoolGroups/{schoolgroup_id}/solTemplates/{soltemplate_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.PublishSchemeTemplateRequest
 */
export interface PublishSchemeTemplateRequest {
  /**
   * Name of the template to publish, in the form "schoolGroups/{schoolgroup_id}/solTemplates/{soltemplate_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Version of the template to publish.
   *
   * @generated from protobuf field: int32 version = 2;
   */
  version: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.ResetDraftSchemeTemplateRequest
 */
export interface ResetDraftSchemeTemplateRequest {
  /**
   * Name of the template to reset the draft of, in the form "schoolGroups/{schoolgroup_id}/solTemplates/{soltemplate_id}"
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchSendUpdateEventsRequest
 */
export interface BatchSendUpdateEventsRequest {}
/**
 * @generated from protobuf message sparx.planning.v1.BatchSendUpdateEventsResponse
 */
export interface BatchSendUpdateEventsResponse {
  /**
   * @generated from protobuf field: int32 numSchoolsDone = 1;
   */
  numSchoolsDone: number;
  /**
   * @generated from protobuf field: int32 numSOLsDone = 2;
   */
  numSOLsDone: number;
}
/**
 * @generated from protobuf enum sparx.planning.v1.ResourceStatus
 */
export enum ResourceStatus {
  /**
   * @generated from protobuf enum value: UNKNOWN_STATUS = 0;
   */
  UNKNOWN_STATUS = 0,
  /**
   * Draft version of a SOL
   *
   * @generated from protobuf enum value: DRAFT = 1;
   */
  DRAFT = 1,
  /**
   * Published version of a SOL
   *
   * @generated from protobuf enum value: PUBLISHED = 2;
   */
  PUBLISHED = 2,
}
/**
 * The type of a SOL week (this informs what type of homework should be
 * delivered that week in the homework plan)
 *
 * @generated from protobuf enum sparx.planning.v1.SchemeOfLearningWeekType
 */
export enum SchemeOfLearningWeekType {
  /**
   * @generated from protobuf enum value: UNKNOWN_WEEK_TYPE = 0;
   */
  UNKNOWN_WEEK_TYPE = 0,
  /**
   * @generated from protobuf enum value: FULL_HOMEWORK = 1;
   */
  FULL_HOMEWORK = 1,
  /**
   * @generated from protobuf enum value: HALF_HOMEWORK = 2;
   */
  HALF_HOMEWORK = 2,
  /**
   * @generated from protobuf enum value: OPTIONAL_ONLY = 3;
   */
  OPTIONAL_ONLY = 3,
  /**
   * @generated from protobuf enum value: NO_HOMEWORK = 4;
   */
  NO_HOMEWORK = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class SchemeOfLearning$Type extends MessageType<SchemeOfLearning> {
  constructor() {
    super('sparx.planning.v1.SchemeOfLearning', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'metadata', kind: 'message', T: () => ResourceMetadata },
      {
        no: 3,
        name: 'original_template',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'base_curriculum',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'school_year',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'schedule',
        kind: 'message',
        T: () => SchemeOfLearningSchedule,
      },
      {
        no: 8,
        name: 'has_no_homework_weeks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SchemeOfLearning
 */
export const SchemeOfLearning = new SchemeOfLearning$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchemeOfLearningTemplate$Type extends MessageType<SchemeOfLearningTemplate> {
  constructor() {
    super('sparx.planning.v1.SchemeOfLearningTemplate', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'metadata', kind: 'message', T: () => ResourceMetadata },
      {
        no: 3,
        name: 'base_curriculum',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'syllabus', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'schedule',
        kind: 'message',
        T: () => SchemeOfLearningSchedule,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SchemeOfLearningTemplate
 */
export const SchemeOfLearningTemplate = new SchemeOfLearningTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceMetadata$Type extends MessageType<ResourceMetadata> {
  constructor() {
    super('sparx.planning.v1.ResourceMetadata', [
      { no: 1, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'created_by', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'update_time', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'updated_by', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.planning.v1.ResourceStatus', ResourceStatus],
      },
      {
        no: 7,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ResourceMetadata
 */
export const ResourceMetadata = new ResourceMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchemeOfLearningSchedule$Type extends MessageType<SchemeOfLearningSchedule> {
  constructor() {
    super('sparx.planning.v1.SchemeOfLearningSchedule', [
      {
        no: 1,
        name: 'weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchemeOfLearningWeek,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SchemeOfLearningSchedule
 */
export const SchemeOfLearningSchedule = new SchemeOfLearningSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchemeOfLearningWeek$Type extends MessageType<SchemeOfLearningWeek> {
  constructor() {
    super('sparx.planning.v1.SchemeOfLearningWeek', [
      { no: 1, name: 'week_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'week_type',
        kind: 'enum',
        T: () => [
          'sparx.planning.v1.SchemeOfLearningWeekType',
          SchemeOfLearningWeekType,
        ],
      },
      {
        no: 3,
        name: 'work_units',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => WorkUnit,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SchemeOfLearningWeek
 */
export const SchemeOfLearningWeek = new SchemeOfLearningWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkUnit$Type extends MessageType<WorkUnit> {
  constructor() {
    super('sparx.planning.v1.WorkUnit', [
      {
        no: 2,
        name: 'work_unit_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 1,
        name: 'topic',
        kind: 'scalar',
        oneof: 'payload',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.WorkUnit
 */
export const WorkUnit = new WorkUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchemesRequest$Type extends MessageType<ListSchemesRequest> {
  constructor() {
    super('sparx.planning.v1.ListSchemesRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_year',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'include_schedules',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'include_drafts',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSchemesRequest
 */
export const ListSchemesRequest = new ListSchemesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchemesResponse$Type extends MessageType<ListSchemesResponse> {
  constructor() {
    super('sparx.planning.v1.ListSchemesResponse', [
      {
        no: 1,
        name: 'schemes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchemeOfLearning,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSchemesResponse
 */
export const ListSchemesResponse = new ListSchemesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemeRequest$Type extends MessageType<GetSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.GetSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.planning.v1.ResourceStatus', ResourceStatus],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetSchemeRequest
 */
export const GetSchemeRequest = new GetSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchemeRequest$Type extends MessageType<CreateSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.CreateSchemeRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'scheme', kind: 'message', T: () => SchemeOfLearning },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.CreateSchemeRequest
 */
export const CreateSchemeRequest = new CreateSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchemeRequest$Type extends MessageType<UpdateSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.UpdateSchemeRequest', [
      { no: 1, name: 'scheme', kind: 'message', T: () => SchemeOfLearning },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdateSchemeRequest
 */
export const UpdateSchemeRequest = new UpdateSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchemeRequest$Type extends MessageType<DeleteSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.DeleteSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.DeleteSchemeRequest
 */
export const DeleteSchemeRequest = new DeleteSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UndeleteSchemeRequest$Type extends MessageType<UndeleteSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.UndeleteSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UndeleteSchemeRequest
 */
export const UndeleteSchemeRequest = new UndeleteSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishSchemeRequest$Type extends MessageType<PublishSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.PublishSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.PublishSchemeRequest
 */
export const PublishSchemeRequest = new PublishSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetDraftSchemeRequest$Type extends MessageType<ResetDraftSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.ResetDraftSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ResetDraftSchemeRequest
 */
export const ResetDraftSchemeRequest = new ResetDraftSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnpublishSchemeRequest$Type extends MessageType<UnpublishSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.UnpublishSchemeRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UnpublishSchemeRequest
 */
export const UnpublishSchemeRequest = new UnpublishSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchemeTemplatesRequest$Type extends MessageType<ListSchemeTemplatesRequest> {
  constructor() {
    super('sparx.planning.v1.ListSchemeTemplatesRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'include_schedules',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'include_drafts',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSchemeTemplatesRequest
 */
export const ListSchemeTemplatesRequest = new ListSchemeTemplatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchemeTemplatesResponse$Type extends MessageType<ListSchemeTemplatesResponse> {
  constructor() {
    super('sparx.planning.v1.ListSchemeTemplatesResponse', [
      {
        no: 1,
        name: 'templates',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchemeOfLearningTemplate,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSchemeTemplatesResponse
 */
export const ListSchemeTemplatesResponse =
  new ListSchemeTemplatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemeTemplateRequest$Type extends MessageType<GetSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.GetSchemeTemplateRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.planning.v1.ResourceStatus', ResourceStatus],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetSchemeTemplateRequest
 */
export const GetSchemeTemplateRequest = new GetSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchemeTemplateRequest$Type extends MessageType<CreateSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.CreateSchemeTemplateRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'template',
        kind: 'message',
        T: () => SchemeOfLearningTemplate,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.CreateSchemeTemplateRequest
 */
export const CreateSchemeTemplateRequest =
  new CreateSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchemeTemplateRequest$Type extends MessageType<UpdateSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.UpdateSchemeTemplateRequest', [
      {
        no: 1,
        name: 'template',
        kind: 'message',
        T: () => SchemeOfLearningTemplate,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdateSchemeTemplateRequest
 */
export const UpdateSchemeTemplateRequest =
  new UpdateSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchemeTemplateRequest$Type extends MessageType<DeleteSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.DeleteSchemeTemplateRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.DeleteSchemeTemplateRequest
 */
export const DeleteSchemeTemplateRequest =
  new DeleteSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishSchemeTemplateRequest$Type extends MessageType<PublishSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.PublishSchemeTemplateRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.PublishSchemeTemplateRequest
 */
export const PublishSchemeTemplateRequest =
  new PublishSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetDraftSchemeTemplateRequest$Type extends MessageType<ResetDraftSchemeTemplateRequest> {
  constructor() {
    super('sparx.planning.v1.ResetDraftSchemeTemplateRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ResetDraftSchemeTemplateRequest
 */
export const ResetDraftSchemeTemplateRequest =
  new ResetDraftSchemeTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchSendUpdateEventsRequest$Type extends MessageType<BatchSendUpdateEventsRequest> {
  constructor() {
    super('sparx.planning.v1.BatchSendUpdateEventsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchSendUpdateEventsRequest
 */
export const BatchSendUpdateEventsRequest =
  new BatchSendUpdateEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchSendUpdateEventsResponse$Type extends MessageType<BatchSendUpdateEventsResponse> {
  constructor() {
    super('sparx.planning.v1.BatchSendUpdateEventsResponse', [
      {
        no: 1,
        name: 'numSchoolsDone',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'numSOLsDone', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchSendUpdateEventsResponse
 */
export const BatchSendUpdateEventsResponse =
  new BatchSendUpdateEventsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.SchemesOfLearning
 */
export const SchemesOfLearning = new ServiceType(
  'sparx.planning.v1.SchemesOfLearning',
  [
    {
      name: 'ListSchemes',
      options: {
        'google.api.http': { get: '/v1/{parent=schools/*}/sols' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'sol',
          domain: '{parent}',
        },
      },
      I: ListSchemesRequest,
      O: ListSchemesResponse,
    },
    {
      name: 'GetScheme',
      options: {
        'google.api.http': { get: '/v1/{name=schools/*/sols/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: GetSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'CreateScheme',
      options: {
        'google.api.http': { post: '/v1/sols', body: 'scheme' },
        'sparx.api.auth': {
          action: 'create',
          resource: 'sol',
          domain: '{parent}',
        },
      },
      I: CreateSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'UpdateScheme',
      options: {
        'google.api.http': {
          put: '/v1/{scheme.name=schools/*/sols/*}',
          body: 'scheme',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'sol',
          domain: '{scheme.name|parent}',
        },
      },
      I: UpdateSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'DeleteScheme',
      options: {
        'google.api.http': { delete: '/v1/{name=schools/*/sols/*}' },
        'sparx.api.auth': {
          action: 'delete',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: DeleteSchemeRequest,
      O: Empty,
    },
    {
      name: 'UndeleteScheme',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: UndeleteSchemeRequest,
      O: Empty,
    },
    {
      name: 'PublishScheme',
      options: {
        'google.api.http': {
          post: '/v1/{name=schools/*/sols/*}:publish',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'publish',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: PublishSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'UnpublishScheme',
      options: {
        'google.api.http': {
          post: '/v1/{name=schools/*/sols/*}:set_as_draft',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'publish',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: UnpublishSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'ResetDraftScheme',
      options: {
        'google.api.http': {
          post: '/v1/{name=schools/*/sols/*}:resetdraft',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'sol',
          domain: '{name|parent}',
        },
      },
      I: ResetDraftSchemeRequest,
      O: SchemeOfLearning,
    },
    {
      name: 'BatchSendUpdateEvents',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'sol:analytics',
          domain: 'sol',
        },
      },
      I: BatchSendUpdateEventsRequest,
      O: BatchSendUpdateEventsResponse,
    },
  ]
);
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.SchemeOfLearningTemplates
 */
export const SchemeOfLearningTemplates = new ServiceType(
  'sparx.planning.v1.SchemeOfLearningTemplates',
  [
    {
      name: 'ListSchemeTemplates',
      options: {
        'google.api.http': { get: '/v1/{parent=schoolGroups/*}/solTemplates' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'soltemplate',
          domain: '{parent}/sharedSchoolResources',
        },
      },
      I: ListSchemeTemplatesRequest,
      O: ListSchemeTemplatesResponse,
    },
    {
      name: 'GetSchemeTemplate',
      options: {
        'google.api.http': { get: '/v1/{name=schoolGroups/*/solTemplates/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'soltemplate',
          domain: '{name|parent}/sharedSchoolResources',
        },
      },
      I: GetSchemeTemplateRequest,
      O: SchemeOfLearningTemplate,
    },
    {
      name: 'CreateSchemeTemplate',
      options: {
        'google.api.http': {
          post: '/v1/{parent=schoolGroups/*}/sols',
          body: 'template',
        },
        'sparx.api.auth': {
          action: 'create',
          resource: 'soltemplate',
          domain: '{parent}/sharedSchoolResources',
        },
      },
      I: CreateSchemeTemplateRequest,
      O: SchemeOfLearningTemplate,
    },
    {
      name: 'UpdateSchemeTemplate',
      options: {
        'google.api.http': {
          put: '/v1/{template.name=schoolGroups/*/solTemplates/*}',
          body: 'template',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'soltemplate',
          domain: '{template.name|parent}/sharedSchoolResources',
        },
      },
      I: UpdateSchemeTemplateRequest,
      O: SchemeOfLearningTemplate,
    },
    {
      name: 'DeleteSchemeTemplate',
      options: {
        'google.api.http': {
          delete: '/v1/{name=schoolGroups/*/solTemplates/*}',
        },
        'sparx.api.auth': {
          action: 'delete',
          resource: 'soltemplate',
          domain: '{name|parent}/sharedSchoolResources',
        },
      },
      I: DeleteSchemeTemplateRequest,
      O: Empty,
    },
    {
      name: 'PublishSchemeTemplate',
      options: {
        'google.api.http': {
          post: '/v1/{name=schoolGroups/*/solTemplates/*}:publish',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'publish',
          resource: 'soltemplate',
          domain: '{name|parent}/sharedSchoolResources',
        },
      },
      I: PublishSchemeTemplateRequest,
      O: SchemeOfLearningTemplate,
    },
    {
      name: 'ResetDraftSchemeTemplate',
      options: {
        'google.api.http': {
          post: '/v1/{name=schoolGroups/*/solTemplates/*}:resetdraft',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'soltemplate',
          domain: '{name|parent}/sharedSchoolResources',
        },
      },
      I: ResetDraftSchemeTemplateRequest,
      O: SchemeOfLearningTemplate,
    },
  ]
);
