/**
 * debounceCallback returns a debounced function which will call the given callback after the given
 * debounceTime, canceling the callback call it the debounced function is called again within the
 * debounceTime.
 */
export const debounceCallback = (fn: () => void, debounceTime: number) => {
  let timerID: number | undefined;
  return () => {
    clearTimeout(timerID);
    (timerID = window.setTimeout(() => fn(), debounceTime)), debounceTime;
  };
};
