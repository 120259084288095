import { ReactNode, useCallback, useEffect, useRef } from 'react';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';
import { useScaleElementsToFit } from '../utils/useScaleElementsToFit';

interface AnswerScreenProps {
  children?: ReactNode | undefined;
}

export const AnswerScreen = ({ children }: AnswerScreenProps) => {
  const { input, setScale, questionElement } = useSparxQuestionContext();
  const elementToScaleRef = useRef<HTMLDivElement>(null);
  const getElementsToScale = useCallback(() => [elementToScaleRef.current], []);
  const getElementsToMeasure = useCallback((element: HTMLElement) => {
    const htmlElements: HTMLElement[] = [];
    Array.from(element.children).forEach((element: Element) => {
      if (element instanceof HTMLElement) {
        htmlElements.push(element);
      }
    });
    Array.from(element.querySelectorAll<HTMLElement>(`[data-stack="answer-content"]`)).forEach(
      (element: HTMLElement) => htmlElements.push(element),
    );
    return htmlElements;
  }, []);

  const getParentWidth = useCallback(
    () => questionElement?.offsetWidth || 0,
    [questionElement?.offsetWidth],
  );

  const { scale, scaleElementsToFit } = useScaleElementsToFit(
    getParentWidth,
    getElementsToScale,
    getElementsToMeasure,
  );

  useEffect(() => {
    setScale?.(scale);
  }, [scale, setScale]);

  useEffect(() => {
    scaleElementsToFit();
  }, [input, scaleElementsToFit]);

  return (
    <div ref={elementToScaleRef} className={styles.ScaledContainer}>
      <div className={styles.AnswerScreenAlignContainer}>
        <div className={styles.AnswerScreen}>{children}</div>
      </div>
    </div>
  );
};
