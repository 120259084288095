import { Text } from '@chakra-ui/react';
import { useCurrentLesson, useIsInLesson, useWatchLesson } from '@sparx/science/api/lessons';
import { useSession } from '@sparx/science/api/sessions';
import { LargeLoadingWithText } from '@sparx/science/components/loading/LargeLoading';
import { whiteboardActiveForUser } from '@sparx/science/utils/whiteboards';
import { LessonDelivery } from '@sparx/science/views/lessondelivery/LessonDeliveryView';
import { LessonDeliveryPage } from '@sparx/science/views/lessondelivery/lessonStatePusher';
import { isStudentPaused } from '@sparx/science/views/lessons/utils';
import { HeaderPortal } from 'components/header/Header';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Whiteboard from 'views/lessondeliveryview/Whiteboard';
import StudentView from 'views/streamerview/subviews/StudentView';

import { useLessonStatePusher } from './lessonStatePusher';

export const LessonDeliveryView = () => {
  // Fix into lessons page if in lessons mode
  const isInLesson = useIsInLesson();
  if (!isInLesson && location.pathname === '/lesson') {
    return <Navigate to="/join" />;
  }

  return <LessonDeliveryViewImpl />;
};

const LessonDeliveryViewImpl = () => {
  const [page, setPage] = useState<LessonDeliveryPage>({ page: 'landing' });
  const [lastWhiteboardImage, setLastWhiteboardImage] = useState('');

  const { data: currentLesson } = useCurrentLesson();
  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(
    currentLesson?.lessonName,
    currentLesson?.sessionId,
  );

  const { data: user } = useSession();
  const paused =
    isStudentPaused(lessonState, user?.userId) && !location.pathname.startsWith('/teacher');

  // Pushes the current student state to the server when it changes
  const handsUpButton = useLessonStatePusher(currentLesson, page, lastWhiteboardImage);

  const whiteboardState = lessonState?.conferenceState?.whiteboardState;

  if (isLoadingLesson) {
    return <LargeLoadingWithText>Joining lesson...</LargeLoadingWithText>;
  }
  if (!lessonState) {
    return <Text>Lesson not found</Text>;
  }

  const { active } = whiteboardActiveForUser(whiteboardState, user?.userId || '-');

  return (
    <LessonDelivery lessonState={lessonState} page={page} setPage={setPage} paused={paused}>
      {whiteboardState && active && (
        <React.Suspense>
          <Whiteboard
            key={whiteboardState.whiteboardActivityId}
            sessionId={whiteboardState.whiteboardActivityId}
            backgroundAsset={whiteboardState.backgroundAssetId}
            paused={paused}
            setLastWhiteboardImage={setLastWhiteboardImage}
          />
        </React.Suspense>
      )}
      <HeaderPortal>{handsUpButton}</HeaderPortal>
      <React.Suspense>
        <StudentView lessonState={lessonState} />
      </React.Suspense>
    </LessonDelivery>
  );
};
