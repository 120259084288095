import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';

import { getSeededRand, shuffleArray } from '../utils/shuffle';
import { QuestionAction } from './input';
import { QuestionPasteEvent } from './SparxQuestion';
import { IElement, IInput, newInput } from './types';

export type QuestionMode = 'question' | 'answer' | 'combined';
export type QuestionMarkingMode = 'part' | 'gap';
export type InsightsMode = 'presentation' | 'print';
export type ImageLoadingState = 'loading' | 'loaded' | 'error';

export interface SparxQuestionContext {
  input: IInput;
  sendAction: (action: QuestionAction) => void;
  setOpenElementRef: (action: SetStateAction<string>) => void;
  openElementRef: string;
  setIsWaitingForAnimation: (action: SetStateAction<boolean>) => void;
  isWaitingForAnimation: boolean;
  nextInputRef: () => string | undefined;
  isSingleNumericInput: boolean;
  gapEvaluations?: Record<string, GapEvaluation>;
  readOnly?: boolean;
  insightsMode?: InsightsMode;
  shuffleSeed?: string;
  annotations?: Record<string, string>;
  onPaste?: (e: QuestionPasteEvent) => void;
  mode?: QuestionMode;
  dragInProgress: boolean;
  scale: number;
  setScale?: (action: SetStateAction<number>) => void;
  recalculateScaleTrigger: boolean;
  setRecalculateScaleTrigger?: () => void;
  questionElement: HTMLElement | null;
  getUploadedAsset?: (name: string) => React.ReactNode;
  getAssetUrl?: (value: string) => Promise<string>;
  keyboardMode?: boolean;
  questionMarkingMode?: QuestionMarkingMode;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  firstChancePartRefsCorrectRef?: React.MutableRefObject<Set<string>>;
  sendAnalyticEvent: (action: string, labels?: Record<string, string>) => void;
  imageLoadingCallback?: (src: string, state: ImageLoadingState) => void;
}

export interface GapEvaluationDetail {
  availableMarks: number;
  awardedMarks: number;
  feedback: string;
  errorMarking?: boolean;
}

export interface GapEvaluation {
  correct: boolean;
  correction?: string;
  detail?: GapEvaluationDetail[];
  additionalData?: Record<string, string>;
}

export const SparxQuestionContextDefaultValues: SparxQuestionContext = {
  sendAction: () => undefined,
  setOpenElementRef: () => undefined,
  openElementRef: '',
  setIsWaitingForAnimation: () => undefined,
  isWaitingForAnimation: false,
  nextInputRef: () => undefined,
  isSingleNumericInput: false,
  input: newInput(),
  readOnly: false,
  insightsMode: undefined,
  annotations: {},
  dragInProgress: false,
  scale: 1,
  recalculateScaleTrigger: false,
  questionElement: null,
  sendAnalyticEvent: () => undefined,
};

export const context = createContext<SparxQuestionContext>(SparxQuestionContextDefaultValues);

export const useSparxQuestionContext = () => useContext(context);

export const SparxQuestionContextProvider = context.Provider;

export interface LayoutElementProps<T = IElement> {
  element: T;
  // Parent is an HTMLElement which this layout element should be fitted to
  parent?: HTMLElement | null;
  answerPartIndex?: number;
}

export const doShuffle = <T>(content: T[], shuffle?: boolean, shuffleSeed?: string) => {
  if (shuffle) {
    const seed = shuffleSeed ? getSeededRand(shuffleSeed) : Math.random;
    return shuffleArray([...content], seed);
  }
  return content;
};

export const usePredictableShuffleContent = <T>(
  content: T[],
  shuffle?: boolean,
  seedModifier = '',
): T[] => {
  const context = useSparxQuestionContext();
  const shuffleSeed = context.shuffleSeed + seedModifier;
  const [shuffledArray, setShuffledArray] = useState<T[]>(() =>
    doShuffle(content, shuffle, shuffleSeed),
  );
  useEffect(
    () => setShuffledArray(doShuffle(content, shuffle, shuffleSeed)),
    [content, shuffle, shuffleSeed, setShuffledArray],
  );
  return shuffledArray;
};
