import {
  Box,
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  CreateLessonActivityResponse,
  LessonActivityBlueprint,
  LessonFeature,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useCreateLessonActivity } from 'api/lessons';
import { useUserType } from 'api/sessions';
import { LargeLoading } from 'components/loading/LargeLoading';
import { BackButton } from 'components/pagetitle/PageTitle';
import { Warning } from 'components/warning';
import React, { useState } from 'react';
import { activityTemplateCreators } from 'views/lessons/createactivity/activitytypes';
import { useLessonViewContext } from 'views/lessons/LessonView';

export const CreateActivityModal = () => {
  const { lessonID, lessonState } = useLessonViewContext();
  const numberActivities = lessonState.activities.length || 0;

  const [name, setName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [setImmediately, setSetImmediately] = useState(false);
  const [keepAddingChecked, setKeepAdding] = useState(false);

  const { isSparxStaff } = useUserType();
  const teamTeaching = lessonState.features.includes(LessonFeature.TEAM_TEACHING);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setName('');
      setSelectedTemplate('');
      setSetImmediately(false);
    },
  });
  const { mutateAsync, isLoading, isError, error } = useCreateLessonActivity(lessonID);

  const selTmpl = activityTemplateCreators.find(t => t.id === selectedTemplate);
  const submit = async (blueprint: LessonActivityBlueprint, keepAdding?: boolean) => {
    if (selTmpl && !isLoading && blueprint) {
      const response = await mutateAsync({
        displayName: name || selTmpl.name,
        blueprint,
        setImmediately: setImmediately || Boolean(selTmpl.isEphemeral),
        ephemeral: Boolean(selTmpl.isEphemeral),
      });
      if (keepAdding) {
        setName('');
        setSetImmediately(false);
      } else {
        onClose();
      }
      return response;
    }
    return undefined;
  };

  const submitMulti = async (activities: ({ title: string } & LessonActivityBlueprint)[]) => {
    if (selTmpl && !isLoading) {
      const responses: CreateLessonActivityResponse[] = [];
      for (const activity of activities) {
        const response = await mutateAsync({
          displayName: name || activity.title,
          blueprint: activity,
          setImmediately: false,
          ephemeral: false,
        });
        responses.push(response);
      }
      onClose();
      return responses;
    }
    return undefined;
  };

  let content = (
    <>
      <ModalBody>
        <VStack spacing={2} alignItems="stretch">
          {activityTemplateCreators
            .filter(t => !t.sparxStaffOnly || isSparxStaff)
            .filter(t => !t.teamTeachingOnly || teamTeaching)
            .map(tmpl => (
              <Box
                key={tmpl.id}
                color={'gray.900'}
                borderWidth={1}
                borderRadius="md"
                display="flex"
                alignItems="center"
                overflow="hidden"
                px={4}
                py={3}
                onClick={() => setSelectedTemplate(tmpl.id)}
                bg={selectedTemplate === tmpl.id ? 'gray.50' : 'white'}
                _hover={{ cursor: 'pointer', bg: 'gray.50' }}
              >
                {tmpl.icon && (
                  <Text display="inline-block" color="gray.400" fontSize="2xl" mr={4} ml={1}>
                    <FontAwesomeIcon fixedWidth={true} icon={tmpl.icon} />
                  </Text>
                )}
                <Box flex={1}>
                  <Text fontWeight="bold">{tmpl.name}</Text>
                  {tmpl.descr && <Text>{tmpl.descr}</Text>}
                </Box>
                <Text fontSize="lg" color="gray.500">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Text>
              </Box>
            ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outline" isDisabled={isLoading}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
  if (selTmpl?.additionalStep) {
    const Component = selTmpl.additionalStep;
    content = (
      <>
        <Box px={6} pb={4} borderBottomWidth={1} mb={4}>
          <Text mt={2} mb={1}>
            Task name:
          </Text>
          <Input
            value={name}
            placeholder={selTmpl?.name || `Activity ${numberActivities + 1}`}
            onChange={e => setName(e.target.value)}
          />
          {!selTmpl.isEphemeral && (
            <Checkbox
              mt={2}
              isChecked={setImmediately}
              onChange={e => setSetImmediately(e.target.checked)}
            >
              Set students to activity immediately
            </Checkbox>
          )}
        </Box>
        <React.Suspense fallback={<LargeLoading />}>
          <Component
            lessonID={lessonID}
            doSubmit={submit}
            doSubmitMulti={submitMulti}
            setTaskName={(name, force) => setName(n => (force ? name : n || name))}
            getFooter={(onSubmit, isLoading2, allowKeepAdding) => (
              <>
                {isError && (
                  <Box mx={3} mt={2}>
                    <Warning status="error">
                      {(error as RpcError)?.code === 'NOT_FOUND'
                        ? 'There were no questions available for the selected options.'
                        : 'Error creating activity'}
                    </Warning>
                  </Box>
                )}
                <ModalFooter>
                  {allowKeepAdding && (
                    <>
                      <Checkbox
                        mt={2}
                        isChecked={keepAddingChecked}
                        onChange={e => setKeepAdding(e.target.checked)}
                      >
                        Stay open after creation
                      </Checkbox>
                      <Box flex={1} />
                    </>
                  )}
                  <Button
                    onClick={() => setSelectedTemplate('')}
                    mr={2}
                    variant="outline"
                    isDisabled={isLoading || isLoading2}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => onSubmit?.(allowKeepAdding && keepAddingChecked)}
                    colorScheme="buttonTeal"
                    isLoading={isLoading || isLoading2}
                    isDisabled={!onSubmit}
                  >
                    Create activity
                  </Button>
                </ModalFooter>
              </>
            )}
          />
        </React.Suspense>
      </>
    );
  }

  return (
    <>
      <Button colorScheme="buttonTeal" onClick={onOpen}>
        Create activity
      </Button>
      <Modal isOpen={isOpen} size="2xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={e => e.preventDefault()}>
          <ModalHeader pb={1} display="flex" alignItems="center">
            {selectedTemplate && <BackButton link={() => setSelectedTemplate('')} />}
            {selectedTemplate && selTmpl ? selTmpl.name : 'Create new lesson activity'}
          </ModalHeader>
          {content}
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
