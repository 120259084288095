import {
  ListStaffResponse,
  Staff,
  StaffRole,
} from '@sparx/api/apis/sparx/teacherportal/staff/v1/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useListStaff } from '@sparx/staff-manager/src/queries';
import { filterUserRolesForProduct } from '@sparx/staff-manager/src/utils';
import { FetchQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { staffClient } from 'api';
import { queryClient } from 'api/client';
import { Options } from 'api/school';
import { getSchoolID, useSession, useUserType } from 'api/sessions';
import { v5 as v5uuid } from 'uuid';

export const useStaffRoles = (options: Options) =>
  useQuery({
    queryKey: ['staff', 'roles'],
    queryFn: async () => staffClient.getStaffRoles({}).response,
    select: data => orderStaffRoles(data.roles),
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  });

const readerRoles = ['readerTeacher', 'sparxReaderLeader', 'headOfEnglish'];
const scienceRoles = ['headOfScience', 'sparxScienceLeader', 'scienceTeacher'];

// Unfortunately we have to duplicate the list of roles loaded from the
// server so we can order them.
const orderOfRoles: string[] = [
  ...scienceRoles,
  'headOfSchool',
  'networkManager',
  'trustDirector',
  'headOfDepartment',
  'sparxLeader',
  'teacher',
  ...readerRoles,
  'other',
];

export const orderStaffRoles = (staffRoles?: StaffRole[]): StaffRole[] =>
  [...(staffRoles || [])].sort((a, b) => {
    const aI = orderOfRoles.indexOf(a.role);
    const bI = orderOfRoles.indexOf(b.role);
    // Do not perform any sorting if both roles are unknown, but move all
    // unknown roles to the end of the ordering.
    if (aI === -1 && bI === -1) {
      return 0;
    } else if (aI === -1) {
      return 1;
    } else if (bI === -1) {
      return -1;
    }
    return aI - bI;
  });

export const staffQuery: FetchQueryOptions<ListStaffResponse> = {
  queryKey: ['staff'],
  queryFn: async () =>
    staffClient.listStaff({
      schoolId: await getSchoolID(),
    }).response,
};

export const useStaff = (options: Options) =>
  useQuery({
    ...staffQuery,
    select: data => data.staff,
    ...options,
  });

const updateStaffInQueryState = (staff: Staff) =>
  queryClient.setQueryData(['staff'], (data: ListStaffResponse | undefined) => {
    // Update the staff member in the query data
    if (data?.staff) {
      return {
        staff: data.staff.filter(d => d.staffId !== staff.staffId).concat([staff]),
      };
    }
    return data;
  });

// Root namespace UUID used to generate science staff IDs from sparx staff IDs
// This matches the on used server side in 'science/server/pkg/api/accesscheck.go'
const staffRootUUID = '8a0e9cb7-6a4a-4cad-a6a7-f47495dd9e81';

// Staff have a ID specific to science, this function calculates it from the staff object
export const getSciStaffID = (s: Staff) =>
  v5uuid(`staff/${s.staffId}/${s.schoolId}`, staffRootUUID);

export const useStaffLookup = (options: Options) => {
  return useQuery({
    ...staffQuery,
    select: data => {
      return data.staff.reduce<Record<string, Staff | undefined>>((p, s) => {
        // Staff have a sparx staff ID and a science staff ID, add both to
        // the record so call sites don't need to worry about using the right one.
        p[s.staffId] = s;
        p[getSciStaffID(s)] = s;
        return p;
      }, {});
    },
    ...options,
  });
};

export const useCurrentStaffUser = () => {
  const { data: user } = useSession();
  const { isTeacher } = useUserType();
  const { data: staffMap } = useStaffLookup({
    suspense: false,
    enabled: isTeacher,
  });

  return staffMap?.[user?.userId || ''];
};

export const useUpsertStaff = () =>
  useMutation({
    mutationFn: async (update: { staff: Staff; updateMask: string[] }) =>
      update.staff.staffId
        ? // Do update if we have a staff id
          staffClient.updateStaff({ staff: update.staff, updateMask: { paths: update.updateMask } })
            .response
        : // Do create if we don't have a staff id
          staffClient
            .createStaff({
              ...update.staff,
              schoolId: await getSchoolID(),
            })
            .response.then(async staff => {
              // Send a welcome email
              await staffClient.resetStaffPassword({
                staffId: [staff.staffId],
                schoolId: await getSchoolID(),
                product: Product.SPARX_SCIENCE,
              });
              return staff;
            }),
    onSuccess: updateStaffInQueryState,
  });

export const useScienceStaffv2 = (options: Options) => {
  return useListStaff({
    select: staff =>
      staff.filter(
        s =>
          s.productAccess && filterUserRolesForProduct(s.roles, Product.SPARX_SCIENCE).length > 0,
      ),
    ...options,
  });
};
