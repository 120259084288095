import { Box, Button, HStack, Input, Text } from '@chakra-ui/react';
import { queryClient } from '@sparx/science/api/client';
import { useMutation } from '@tanstack/react-query';
import { kioskClientNoAuth } from 'api/service';
import React, { PropsWithChildren, useState } from 'react';
import { KioskContainer } from 'views/kioskview/KioskContainer';

const resetKioskView = () => queryClient.resetQueries(['kiosk', 'token']);

export const KioskRegister = () => {
  const [value, setValue] = useState('');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (token: string) =>
      kioskClientNoAuth.authenticateKiosk({
        token,
      }),
    onSuccess: () => resetKioskView(),
  });

  return (
    <KioskContainer>
      <BoxContainer>
        <Text mb={3} color="gray.700">
          Enter the join code to setup this device:
        </Text>
        <HStack spacing={4} as="form" onSubmit={e => e.preventDefault()}>
          <Input
            isDisabled={isLoading}
            value={value}
            onChange={e => setValue(e.target.value)}
            w="200px"
            placeholder="Join code"
          />
          <Button
            isDisabled={!value}
            isLoading={isLoading}
            type="submit"
            colorScheme="buttonTeal"
            onClick={() => mutate(value)}
          >
            Connect
          </Button>
        </HStack>
      </BoxContainer>
    </KioskContainer>
  );
};

const BoxContainer = ({ children }: PropsWithChildren) => (
  <Box bg="white" px={8} py={5} textAlign="center" boxShadow="elevationLow" borderRadius="md">
    {children}
  </Box>
);
