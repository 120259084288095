import { WhiteboardState } from '@sparx/api/apis/sparx/science/lessons/v1/teamteaching';

export const whiteboardActiveForUser = (
  whiteboardState: WhiteboardState | undefined,
  studentId: string,
) => {
  const selected = whiteboardState?.studentIds.includes(studentId);
  const active =
    whiteboardState?.whiteboardActivityId && (whiteboardState.studentIds.length === 0 || selected);

  return { active, selected };
};
