// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/staff/v1/staff.proto" (package "sparx.teacherportal.staff.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { StaffAPI } from './staff';
import type { ResetStaffPasswordResponse } from './staff';
import type { ResetStaffPasswordRequest } from './staff';
import type { UpdateStaffRequest } from './staff';
import type { Staff } from './staff';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { DeleteStaffRequest } from './staff';
import type { ListStaffResponse } from './staff';
import type { ListStaffRequest } from './staff';
import type { GetStaffResponse } from './staff';
import type { GetStaffRequest } from './staff';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetStaffRolesResponse } from './staff';
import type { GetStaffRolesRequest } from './staff';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.teacherportal.staff.v1.StaffAPI
 */
export interface IStaffAPIClient {
  /**
   * TODO: should just return StaffRoles
   * per https://cloud.google.com/apis/design/standard_methods#get
   *
   * @deprecated
   * @generated from protobuf rpc: GetStaffRoles(sparx.teacherportal.staff.v1.GetStaffRolesRequest) returns (sparx.teacherportal.staff.v1.GetStaffRolesResponse);
   */
  getStaffRoles(
    input: GetStaffRolesRequest,
    options?: RpcOptions
  ): UnaryCall<GetStaffRolesRequest, GetStaffRolesResponse>;
  /**
   * TODO: should just return Staff
   * per https://cloud.google.com/apis/design/standard_methods#get
   *
   * @deprecated
   * @generated from protobuf rpc: GetStaff(sparx.teacherportal.staff.v1.GetStaffRequest) returns (sparx.teacherportal.staff.v1.GetStaffResponse);
   */
  getStaff(
    input: GetStaffRequest,
    options?: RpcOptions
  ): UnaryCall<GetStaffRequest, GetStaffResponse>;
  /**
   * @deprecated
   * @generated from protobuf rpc: ListStaff(sparx.teacherportal.staff.v1.ListStaffRequest) returns (sparx.teacherportal.staff.v1.ListStaffResponse);
   */
  listStaff(
    input: ListStaffRequest,
    options?: RpcOptions
  ): UnaryCall<ListStaffRequest, ListStaffResponse>;
  /**
   * @deprecated
   * @generated from protobuf rpc: DeleteStaff(sparx.teacherportal.staff.v1.DeleteStaffRequest) returns (google.protobuf.Empty);
   */
  deleteStaff(
    input: DeleteStaffRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteStaffRequest, Empty>;
  /**
   * @deprecated
   * @generated from protobuf rpc: CreateStaff(sparx.teacherportal.staff.v1.Staff) returns (sparx.teacherportal.staff.v1.Staff);
   */
  createStaff(input: Staff, options?: RpcOptions): UnaryCall<Staff, Staff>;
  /**
   * @deprecated
   * @generated from protobuf rpc: UpdateStaff(sparx.teacherportal.staff.v1.UpdateStaffRequest) returns (sparx.teacherportal.staff.v1.Staff);
   */
  updateStaff(
    input: UpdateStaffRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStaffRequest, Staff>;
  /**
   * @generated from protobuf rpc: ResetStaffPassword(sparx.teacherportal.staff.v1.ResetStaffPasswordRequest) returns (sparx.teacherportal.staff.v1.ResetStaffPasswordResponse);
   */
  resetStaffPassword(
    input: ResetStaffPasswordRequest,
    options?: RpcOptions
  ): UnaryCall<ResetStaffPasswordRequest, ResetStaffPasswordResponse>;
}
/**
 * @generated from protobuf service sparx.teacherportal.staff.v1.StaffAPI
 */
export class StaffAPIClient implements IStaffAPIClient, ServiceInfo {
  typeName = StaffAPI.typeName;
  methods = StaffAPI.methods;
  options = StaffAPI.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * TODO: should just return StaffRoles
   * per https://cloud.google.com/apis/design/standard_methods#get
   *
   * @deprecated
   * @generated from protobuf rpc: GetStaffRoles(sparx.teacherportal.staff.v1.GetStaffRolesRequest) returns (sparx.teacherportal.staff.v1.GetStaffRolesResponse);
   */
  getStaffRoles(
    input: GetStaffRolesRequest,
    options?: RpcOptions
  ): UnaryCall<GetStaffRolesRequest, GetStaffRolesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStaffRolesRequest, GetStaffRolesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * TODO: should just return Staff
   * per https://cloud.google.com/apis/design/standard_methods#get
   *
   * @deprecated
   * @generated from protobuf rpc: GetStaff(sparx.teacherportal.staff.v1.GetStaffRequest) returns (sparx.teacherportal.staff.v1.GetStaffResponse);
   */
  getStaff(
    input: GetStaffRequest,
    options?: RpcOptions
  ): UnaryCall<GetStaffRequest, GetStaffResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStaffRequest, GetStaffResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @deprecated
   * @generated from protobuf rpc: ListStaff(sparx.teacherportal.staff.v1.ListStaffRequest) returns (sparx.teacherportal.staff.v1.ListStaffResponse);
   */
  listStaff(
    input: ListStaffRequest,
    options?: RpcOptions
  ): UnaryCall<ListStaffRequest, ListStaffResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListStaffRequest, ListStaffResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @deprecated
   * @generated from protobuf rpc: DeleteStaff(sparx.teacherportal.staff.v1.DeleteStaffRequest) returns (google.protobuf.Empty);
   */
  deleteStaff(
    input: DeleteStaffRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteStaffRequest, Empty> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteStaffRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @deprecated
   * @generated from protobuf rpc: CreateStaff(sparx.teacherportal.staff.v1.Staff) returns (sparx.teacherportal.staff.v1.Staff);
   */
  createStaff(input: Staff, options?: RpcOptions): UnaryCall<Staff, Staff> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<Staff, Staff>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @deprecated
   * @generated from protobuf rpc: UpdateStaff(sparx.teacherportal.staff.v1.UpdateStaffRequest) returns (sparx.teacherportal.staff.v1.Staff);
   */
  updateStaff(
    input: UpdateStaffRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStaffRequest, Staff> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateStaffRequest, Staff>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ResetStaffPassword(sparx.teacherportal.staff.v1.ResetStaffPasswordRequest) returns (sparx.teacherportal.staff.v1.ResetStaffPasswordResponse);
   */
  resetStaffPassword(
    input: ResetStaffPasswordRequest,
    options?: RpcOptions
  ): UnaryCall<ResetStaffPasswordRequest, ResetStaffPasswordResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ResetStaffPasswordRequest,
      ResetStaffPasswordResponse
    >('unary', this._transport, method, opt, input);
  }
}
