// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/training/progress/v1/trainingprogress.proto" (package "sparx.training.progress.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * TrainingProgress tracks training for a given user.
 *
 * @generated from protobuf message sparx.training.progress.v1.TrainingProgress
 */
export interface TrainingProgress {
  /**
   * The canonical resource name for this training progress record.
   * `trainingProgress/{id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Additional names that can be used to fetch this record. Will be
   * other resource names with a `trainingProgress`suffix` e.g.
   * `sparxuser/{id}/trainingProgress`. Read only.
   *
   * @generated from protobuf field: repeated string aliases = 2;
   */
  aliases: string[];
  /**
   * The time the record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
  /**
   * Data stored against different training modules including their completion
   * times. On update, keys not supplied are unchanged. The key is the name
   * of the training module.
   *
   * @generated from protobuf field: map<string, sparx.training.progress.v1.TrainingModuleState> module_data = 4;
   */
  moduleData: {
    [key: string]: TrainingModuleState;
  };
  /**
   * Arbitrary data stored against this training progress.
   *
   * @generated from protobuf field: map<string, string> annotations = 5;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * TrainingModuleState tracks a user's state in a given training module
 *
 * @generated from protobuf message sparx.training.progress.v1.TrainingModuleState
 */
export interface TrainingModuleState {
  /**
   * The name of the module. Read only (set from the key on create).
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The time this training module was last completed. Can be reset.
   *
   * @generated from protobuf field: google.protobuf.Timestamp completed_time = 2;
   */
  completedTime?: Timestamp;
  /**
   * The time this training module was first completed. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp first_completed_time = 3;
   */
  firstCompletedTime?: Timestamp;
  /**
   * The time this training module was last dismissed by the user.
   * Modules that have been dismissed should generally be treated as completed.
   *
   * @generated from protobuf field: google.protobuf.Timestamp dismiss_time = 4;
   */
  dismissTime?: Timestamp;
  /**
   * The ids of any steps that the user has completed.
   *
   * @generated from protobuf field: repeated string completed_steps = 5;
   */
  completedSteps: string[];
  /**
   * Arbitrary data stored against this training module.
   * sparx.schools/name is a required annotation for the schools resource name.
   * This facilitates sending updates to HubSpot.
   *
   * @generated from protobuf field: map<string, string> annotations = 6;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.training.progress.v1.GetTrainingProgressRequest
 */
export interface GetTrainingProgressRequest {
  /**
   * The name or an alias for a training progress.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Empty
 *
 * @generated from protobuf message sparx.training.progress.v1.GetTrainingProgressForCurrentUserRequest
 */
export interface GetTrainingProgressForCurrentUserRequest {}
/**
 * @generated from protobuf message sparx.training.progress.v1.UpdateTrainingProgressRequest
 */
export interface UpdateTrainingProgressRequest {
  /**
   * The training progress to update. The name field may be set to any alias
   * (the returned object will always have the real name.)
   *
   * @generated from protobuf field: sparx.training.progress.v1.TrainingProgress training_progress = 1;
   */
  trainingProgress?: TrainingProgress;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.UpdateTrainingProgressForCurrentUserRequest
 */
export interface UpdateTrainingProgressForCurrentUserRequest {
  /**
   * The training progress to update. The name field is ignored
   *
   * @generated from protobuf field: sparx.training.progress.v1.TrainingProgress training_progress = 1;
   */
  trainingProgress?: TrainingProgress;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.WatchTrainingProgressRequest
 */
export interface WatchTrainingProgressRequest {
  /**
   * The name or an alias for a training progress.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.AliasTrainingProgressRequest
 */
export interface AliasTrainingProgressRequest {
  /**
   * The name of a training progress to add an alias to.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The new alias to add.
   *
   * @generated from protobuf field: string alias = 2;
   */
  alias: string;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.ListTrainingProgressesRequest
 */
export interface ListTrainingProgressesRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Should be the
   * value of [ListTrainingProgressesResponse.next_page_token] from a previous
   * request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.ListTrainingProgressesResponse
 */
export interface ListTrainingProgressesResponse {
  /**
   * The progress records
   *
   * @generated from protobuf field: repeated sparx.training.progress.v1.TrainingProgress training_progresses = 1;
   */
  trainingProgresses: TrainingProgress[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.DeleteTrainingProgressRequest
 */
export interface DeleteTrainingProgressRequest {
  /**
   * The name or alias of a training progress to delete.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.training.progress.v1.BatchGetTrainingProgressRequest
 */
export interface BatchGetTrainingProgressRequest {
  /**
   * The names or aliases of training progress records to get (max of 1000)
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * @generated from protobuf message sparx.training.progress.v1.BatchGetTrainingProgressResponse
 */
export interface BatchGetTrainingProgressResponse {
  /**
   * A list of training progresses - note, paging will not be implemented unless needed
   *
   * @generated from protobuf field: repeated sparx.training.progress.v1.TrainingProgress training_progresses = 1;
   */
  trainingProgresses: TrainingProgress[];
}
// @generated message type with reflection information, may provide speed optimized methods
class TrainingProgress$Type extends MessageType<TrainingProgress> {
  constructor() {
    super('sparx.training.progress.v1.TrainingProgress', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'aliases',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'module_data',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => TrainingModuleState },
      },
      {
        no: 5,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.TrainingProgress
 */
export const TrainingProgress = new TrainingProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrainingModuleState$Type extends MessageType<TrainingModuleState> {
  constructor() {
    super('sparx.training.progress.v1.TrainingModuleState', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'completed_time', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'first_completed_time',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 4, name: 'dismiss_time', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'completed_steps',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.TrainingModuleState
 */
export const TrainingModuleState = new TrainingModuleState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrainingProgressRequest$Type extends MessageType<GetTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.GetTrainingProgressRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.GetTrainingProgressRequest
 */
export const GetTrainingProgressRequest = new GetTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrainingProgressForCurrentUserRequest$Type extends MessageType<GetTrainingProgressForCurrentUserRequest> {
  constructor() {
    super(
      'sparx.training.progress.v1.GetTrainingProgressForCurrentUserRequest',
      []
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.GetTrainingProgressForCurrentUserRequest
 */
export const GetTrainingProgressForCurrentUserRequest =
  new GetTrainingProgressForCurrentUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTrainingProgressRequest$Type extends MessageType<UpdateTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.UpdateTrainingProgressRequest', [
      {
        no: 1,
        name: 'training_progress',
        kind: 'message',
        T: () => TrainingProgress,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.UpdateTrainingProgressRequest
 */
export const UpdateTrainingProgressRequest =
  new UpdateTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTrainingProgressForCurrentUserRequest$Type extends MessageType<UpdateTrainingProgressForCurrentUserRequest> {
  constructor() {
    super(
      'sparx.training.progress.v1.UpdateTrainingProgressForCurrentUserRequest',
      [
        {
          no: 1,
          name: 'training_progress',
          kind: 'message',
          T: () => TrainingProgress,
        },
        { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.UpdateTrainingProgressForCurrentUserRequest
 */
export const UpdateTrainingProgressForCurrentUserRequest =
  new UpdateTrainingProgressForCurrentUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchTrainingProgressRequest$Type extends MessageType<WatchTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.WatchTrainingProgressRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.WatchTrainingProgressRequest
 */
export const WatchTrainingProgressRequest =
  new WatchTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AliasTrainingProgressRequest$Type extends MessageType<AliasTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.AliasTrainingProgressRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'alias', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.AliasTrainingProgressRequest
 */
export const AliasTrainingProgressRequest =
  new AliasTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTrainingProgressesRequest$Type extends MessageType<ListTrainingProgressesRequest> {
  constructor() {
    super('sparx.training.progress.v1.ListTrainingProgressesRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.ListTrainingProgressesRequest
 */
export const ListTrainingProgressesRequest =
  new ListTrainingProgressesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTrainingProgressesResponse$Type extends MessageType<ListTrainingProgressesResponse> {
  constructor() {
    super('sparx.training.progress.v1.ListTrainingProgressesResponse', [
      {
        no: 1,
        name: 'training_progresses',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrainingProgress,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.ListTrainingProgressesResponse
 */
export const ListTrainingProgressesResponse =
  new ListTrainingProgressesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTrainingProgressRequest$Type extends MessageType<DeleteTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.DeleteTrainingProgressRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.DeleteTrainingProgressRequest
 */
export const DeleteTrainingProgressRequest =
  new DeleteTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTrainingProgressRequest$Type extends MessageType<BatchGetTrainingProgressRequest> {
  constructor() {
    super('sparx.training.progress.v1.BatchGetTrainingProgressRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.BatchGetTrainingProgressRequest
 */
export const BatchGetTrainingProgressRequest =
  new BatchGetTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTrainingProgressResponse$Type extends MessageType<BatchGetTrainingProgressResponse> {
  constructor() {
    super('sparx.training.progress.v1.BatchGetTrainingProgressResponse', [
      {
        no: 1,
        name: 'training_progresses',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TrainingProgress,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.training.progress.v1.BatchGetTrainingProgressResponse
 */
export const BatchGetTrainingProgressResponse =
  new BatchGetTrainingProgressResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.training.progress.v1.TrainingProgressService
 */
export const TrainingProgressService = new ServiceType(
  'sparx.training.progress.v1.TrainingProgressService',
  [
    {
      name: 'GetTrainingProgress',
      options: {
        'google.api.http': {
          get: '/v1/{name=trainingProgress/*}',
          additionalBindings: [{ get: '/v1/{name=*/*}/trainingProgress' }],
        },
        'sparx.api.auth': {
          action: 'get',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: GetTrainingProgressRequest,
      O: TrainingProgress,
    },
    {
      name: 'GetTrainingProgressForCurrentUser',
      options: {
        'google.api.http': { get: '/v1/trainingProgress:currentUser' },
        'sparx.api.auth': {
          action: 'get',
          resource: 'trainingProgress',
          domain: 'trainingProgress/own',
        },
      },
      I: GetTrainingProgressForCurrentUserRequest,
      O: TrainingProgress,
    },
    {
      name: 'UpdateTrainingProgress',
      options: {
        'google.api.http': {
          patch: '/v1/{name=trainingProgress/*}',
          body: 'training_progress',
          additionalBindings: [
            {
              patch: '/v1/{name=*/*}/trainingProgress',
              body: 'training_progress',
            },
          ],
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: UpdateTrainingProgressRequest,
      O: TrainingProgress,
    },
    {
      name: 'UpdateTrainingProgressForCurrentUser',
      options: {
        'google.api.http': {
          patch: '/v1/trainingProgress:currentUser',
          body: 'training_progress',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'trainingProgress',
          domain: 'trainingProgress/own',
        },
      },
      I: UpdateTrainingProgressForCurrentUserRequest,
      O: TrainingProgress,
    },
    {
      name: 'WatchTrainingProgress',
      serverStreaming: true,
      options: {
        'sparx.api.auth': {
          action: 'watch',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: WatchTrainingProgressRequest,
      O: TrainingProgress,
    },
    {
      name: 'ListTrainingProgresses',
      options: {
        'google.api.http': { get: '/v1/trainingProgress' },
        'sparx.api.auth': {
          action: 'list',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: ListTrainingProgressesRequest,
      O: ListTrainingProgressesResponse,
    },
    {
      name: 'DeleteTrainingProgress',
      options: {
        'google.api.http': {
          delete: '/v1/{name=trainingProgress/*}',
          additionalBindings: [{ delete: '/v1/{name=*/*}/trainingProgress' }],
        },
        'sparx.api.auth': {
          action: 'delete',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: DeleteTrainingProgressRequest,
      O: TrainingProgress,
    },
    {
      name: 'AliasTrainingProgress',
      options: {
        'google.api.http': {
          post: '/v1/{name=trainingProgress/*}:alias',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'alias',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: AliasTrainingProgressRequest,
      O: TrainingProgress,
    },
    {
      name: 'BatchGetTrainingProgress',
      options: {
        'sparx.api.auth': {
          action: 'get',
          resource: 'trainingProgress',
          domain: 'trainingProgress',
        },
      },
      I: BatchGetTrainingProgressRequest,
      O: BatchGetTrainingProgressResponse,
    },
  ]
);
