import React, { Box, Button, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChalkboard,
  faChalkboardTeacher,
  faTowerObservation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePreviewDevice } from '@livekit/components-react';
import { DefaultInputSelect, MultiTrackSelect } from 'components/stream/StreamComponents';
import { Role } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

export const Prejoin = ({ role, onJoin }: { role: Role; onJoin: () => void }) => {
  const [selectedDevices, setSelectedDevices] = useState<Dictionary<string, string>>({});
  const updateSelectedDevice = (name: string) => (deviceId: string) => {
    setSelectedDevices(d => ({ ...d, [name]: deviceId }));
  };

  return (
    <Box
      bgGradient="linear(to-tr, teal.700, teal.800)"
      height="100vh"
      display="flex"
      flexDirection="column"
      boxShadow="elevationHigh"
      justifyContent="flex-start"
      p={4}
    >
      <Grid
        alignItems="center"
        gridTemplateColumns={role !== Role.OBSERVER ? '1fr 1fr' : '1fr'}
        p={4}
        gap={4}
        borderRadius="md"
        margin="auto"
        bg="rgba(255, 255, 255, 0.9)"
      >
        {role !== Role.OBSERVER && (
          <VStack spacing={4}>
            <PreviewContainer name="Webcam">
              <TrackPreview deviceId={selectedDevices['webcam'] || ''} />
              <MultiTrackSelect name="webcam" onDeviceChange={updateSelectedDevice('webcam')} />
            </PreviewContainer>
            {role === Role.TEACHER && (
              <PreviewContainer name="Visualiser">
                <TrackPreview deviceId={selectedDevices['visualiser'] || ''} />
                <MultiTrackSelect
                  name="visualiser"
                  onDeviceChange={updateSelectedDevice('visualiser')}
                />
              </PreviewContainer>
            )}
            <HStack spacing={2}>
              <DefaultInputSelect kind="audiooutput" />
              <DefaultInputSelect kind="audioinput" />
            </HStack>
          </VStack>
        )}
        <VStack spacing={6} textAlign="center" justifyContent="center" p={4}>
          <Text fontSize="2xl" fontWeight="bold" color="gray.700">
            Join Sparx Teaching Session
          </Text>

          {role === Role.TEACHER ? (
            <RolePreview name="Teacher" color="orange" icon={faChalkboardTeacher} showWarning />
          ) : role === Role.CLASS ? (
            <RolePreview name="Class" color="purple" icon={faChalkboard} showWarning />
          ) : role === Role.OBSERVER ? (
            <RolePreview name="Observer" color="green" icon={faTowerObservation} />
          ) : null}
          <Button colorScheme="buttonTeal" onClick={onJoin}>
            Join now
          </Button>
        </VStack>
      </Grid>
    </Box>
  );
};

const RolePreview = ({
  name,
  color,
  icon,
  showWarning,
}: {
  name: string;
  color: string;
  icon: IconDefinition;
  showWarning?: boolean;
}) => (
  <Box width="100%">
    <Text fontSize="lg" p={2} bg={color + '.300'} color={color + '.900'} borderRadius="md">
      <FontAwesomeIcon icon={icon} />
      &nbsp;&nbsp;Joining as <strong>{name}</strong>
    </Text>
    {showWarning && (
      <Text mt={3}>You will replace any existing {name} for this lesson by joining</Text>
    )}
  </Box>
);

const PreviewContainer = ({ name, children }: PropsWithChildren<{ name: string }>) => (
  <Box
    position="relative"
    borderRadius="md"
    boxShadow="elevationLow"
    overflow="hidden"
    flex={1}
    __css={{ '> button': { maxWidth: 'none', width: '100%', borderRadius: 0, marginTop: -1 } }}
  >
    <Box
      position="absolute"
      pointerEvents="none"
      color="white"
      fontWeight="bold"
      fontSize="sm"
      textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
      bgGradient="linear(to-b, blackAlpha.200, transparent)"
      top="0"
      left="0"
      right="0"
      px={4}
      py={3}
    >
      {name}
    </Box>
    {children}
  </Box>
);

const TrackPreview = ({ deviceId }: { deviceId: string }) => {
  const { localTrack } = usePreviewDevice(true, deviceId, 'videoinput');
  const videoEl = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoEl.current && localTrack) {
      localTrack.attach(videoEl.current);
      return () => {
        localTrack.detach();
      };
    }
  }, [videoEl, localTrack]);

  return (
    <video
      ref={videoEl}
      width="500"
      style={{
        background: 'rgba(0, 0, 0, 0.25)',
      }}
    />
  );
};
