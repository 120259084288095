import * as C from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Checkbox.module.css';

export { type CheckedState } from '@radix-ui/react-checkbox';

type CheckboxProps = Pick<
  C.CheckboxProps,
  'id' | 'name' | 'checked' | 'onCheckedChange' | 'disabled'
> & {
  label?: ReactNode;
  labelPosition?: 'left' | 'right';
  variant?: 'default' | 'inverted';
  className?: string;
  checkIcon?: ReactNode;
};

export const Checkbox = ({
  id,
  label,
  name,
  labelPosition = 'right',
  checked,
  disabled,
  onCheckedChange,
  variant = 'default',
  className,
  checkIcon = <CheckIcon className={styles.Icon} />,
}: CheckboxProps) => {
  const hasLabel = !!label;
  const labelPositionClassMap = {
    [styles.LabelLeft]: hasLabel && labelPosition === 'left',
    [styles.LabelRight]: hasLabel && labelPosition === 'right',
  };
  return (
    <div className={classNames(styles.Container, labelPositionClassMap, className)}>
      <C.Root
        id={id}
        className={classNames(styles.Root, labelPositionClassMap, {
          [styles.Inverted]: variant === 'inverted',
        })}
        checked={checked}
        onCheckedChange={onCheckedChange}
        name={name}
        disabled={disabled}
      >
        <C.Indicator>{checkIcon}</C.Indicator>
      </C.Root>
      {label && (
        <label className={styles.Label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
