// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/independentlearning.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Interval } from '../../../../../google/type/interval';
import { Package } from './package';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { CourseTier } from '../../schools/settings/v1/settings';
import { Course } from '../../schools/settings/v1/settings';
/**
 * @generated from protobuf message sparx.science.packages.v1.GetIndependentLearningStateRequest
 */
export interface GetIndependentLearningStateRequest {}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetIndependentLearningStateResponse
 */
export interface GetIndependentLearningStateResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.PreviousTopic previous_topics = 1;
   */
  previousTopics: PreviousTopic[];
  /**
   * @generated from protobuf field: string curriculum_name = 2;
   */
  curriculumName: string;
  /**
   * @generated from protobuf field: sparx.science.schools.settings.v1.Course course = 3;
   */
  course: Course;
  /**
   * @generated from protobuf field: sparx.science.schools.settings.v1.CourseTier tier = 4;
   */
  tier: CourseTier;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.PreviousTopic
 */
export interface PreviousTopic {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_assigned = 2;
   */
  lastAssigned?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateIndependentLearningPackageRequest
 */
export interface CreateIndependentLearningPackageRequest {
  /**
   * Strand, SubStrand or topic to generate content for
   * If blank will generate using topics the student has been set before
   * When set will pick from topics the student has been set before unless there
   * are none, in which case it will use any from the strand/substrand
   *
   * @generated from protobuf field: string resource_name = 1;
   */
  resourceName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateIndependentLearningPackageResponse
 */
export interface CreateIndependentLearningPackageResponse {
  /**
   * List of packages that were created for this practice. Usually there will only be one.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package package = 1;
   */
  package: Package[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesRequest
 */
export interface ListIndependentLearningSummariesRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf oneof: query
   */
  query:
    | {
        oneofKind: 'assignmentName';
        /**
         * @generated from protobuf field: string assignment_name = 2;
         */
        assignmentName: string;
      }
    | {
        oneofKind: 'students';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ListIndependentLearningSummariesRequest.StudentQuery students = 4;
         */
        students: ListIndependentLearningSummariesRequest_StudentQuery;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesRequest.StudentQuery
 */
export interface ListIndependentLearningSummariesRequest_StudentQuery {
  /**
   * @generated from protobuf field: repeated string student_names = 1;
   */
  studentNames: string[];
  /**
   * @generated from protobuf field: google.type.Interval dates = 2;
   */
  dates?: Interval;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesResponse
 */
export interface ListIndependentLearningSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.ListIndependentLearningSummariesResponse.UserSummary summaries = 1;
   */
  summaries: ListIndependentLearningSummariesResponse_UserSummary[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesResponse.UserSummary
 */
export interface ListIndependentLearningSummariesResponse_UserSummary {
  /**
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * @generated from protobuf field: int32 total_seconds = 2;
   */
  totalSeconds: number;
  /**
   * @generated from protobuf field: int32 correct_activities = 3;
   */
  correctActivities: number;
  /**
   * @generated from protobuf field: int32 total_activities = 4;
   */
  totalActivities: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetIndependentLearningStateRequest$Type extends MessageType<GetIndependentLearningStateRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetIndependentLearningStateRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetIndependentLearningStateRequest
 */
export const GetIndependentLearningStateRequest =
  new GetIndependentLearningStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndependentLearningStateResponse$Type extends MessageType<GetIndependentLearningStateResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetIndependentLearningStateResponse', [
      {
        no: 1,
        name: 'previous_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviousTopic,
      },
      {
        no: 2,
        name: 'curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'course',
        kind: 'enum',
        T: () => [
          'sparx.science.schools.settings.v1.Course',
          Course,
          'COURSE_',
        ],
      },
      {
        no: 4,
        name: 'tier',
        kind: 'enum',
        T: () => ['sparx.science.schools.settings.v1.CourseTier', CourseTier],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetIndependentLearningStateResponse
 */
export const GetIndependentLearningStateResponse =
  new GetIndependentLearningStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousTopic$Type extends MessageType<PreviousTopic> {
  constructor() {
    super('sparx.science.packages.v1.PreviousTopic', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_assigned', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.PreviousTopic
 */
export const PreviousTopic = new PreviousTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIndependentLearningPackageRequest$Type extends MessageType<CreateIndependentLearningPackageRequest> {
  constructor() {
    super('sparx.science.packages.v1.CreateIndependentLearningPackageRequest', [
      {
        no: 1,
        name: 'resource_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateIndependentLearningPackageRequest
 */
export const CreateIndependentLearningPackageRequest =
  new CreateIndependentLearningPackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIndependentLearningPackageResponse$Type extends MessageType<CreateIndependentLearningPackageResponse> {
  constructor() {
    super(
      'sparx.science.packages.v1.CreateIndependentLearningPackageResponse',
      [
        {
          no: 1,
          name: 'package',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Package,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateIndependentLearningPackageResponse
 */
export const CreateIndependentLearningPackageResponse =
  new CreateIndependentLearningPackageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIndependentLearningSummariesRequest$Type extends MessageType<ListIndependentLearningSummariesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListIndependentLearningSummariesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        oneof: 'query',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'students',
        kind: 'message',
        oneof: 'query',
        T: () => ListIndependentLearningSummariesRequest_StudentQuery,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesRequest
 */
export const ListIndependentLearningSummariesRequest =
  new ListIndependentLearningSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIndependentLearningSummariesRequest_StudentQuery$Type extends MessageType<ListIndependentLearningSummariesRequest_StudentQuery> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListIndependentLearningSummariesRequest.StudentQuery',
      [
        {
          no: 1,
          name: 'student_names',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'dates', kind: 'message', T: () => Interval },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesRequest.StudentQuery
 */
export const ListIndependentLearningSummariesRequest_StudentQuery =
  new ListIndependentLearningSummariesRequest_StudentQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIndependentLearningSummariesResponse$Type extends MessageType<ListIndependentLearningSummariesResponse> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListIndependentLearningSummariesResponse',
      [
        {
          no: 1,
          name: 'summaries',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ListIndependentLearningSummariesResponse_UserSummary,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesResponse
 */
export const ListIndependentLearningSummariesResponse =
  new ListIndependentLearningSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIndependentLearningSummariesResponse_UserSummary$Type extends MessageType<ListIndependentLearningSummariesResponse_UserSummary> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListIndependentLearningSummariesResponse.UserSummary',
      [
        {
          no: 1,
          name: 'student_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'total_seconds',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: 'correct_activities',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 4,
          name: 'total_activities',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListIndependentLearningSummariesResponse.UserSummary
 */
export const ListIndependentLearningSummariesResponse_UserSummary =
  new ListIndependentLearningSummariesResponse_UserSummary$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.IndependentLearning
 */
export const IndependentLearning = new ServiceType(
  'sparx.science.packages.v1.IndependentLearning',
  [
    {
      name: 'GetIndependentLearningState',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: GetIndependentLearningStateRequest,
      O: GetIndependentLearningStateResponse,
    },
    {
      name: 'CreateIndependentLearningPackage',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: CreateIndependentLearningPackageRequest,
      O: CreateIndependentLearningPackageResponse,
    },
    {
      name: 'ListIndependentLearningSummaries',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: ListIndependentLearningSummariesRequest,
      O: ListIndependentLearningSummariesResponse,
    },
  ]
);
