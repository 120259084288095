import { getTrackReferenceId, isLocal } from '@livekit/components-core';
import { AudioTrack, useTracks } from '@livekit/components-react';
import { RemoteTrackPublication, Track } from 'livekit-client';
import { useEffect } from 'react';

export interface RoomAudioRendererProps {
  /** Sets the volume for all audio tracks rendered by this component. By default, the range is between `0.0` and `1.0`. */
  volume?: number;
  /**
   * If set to `true`, mutes all audio tracks rendered by the component.
   * @remarks
   * If set to `true`, the server will stop sending audio track data to the client.
   * @alpha
   */
  muted?: boolean;
  identityMute?: Record<string, boolean>;
  filter?: (identity: string) => boolean;
}

export function RoomAudioRendererWithMutedIdentities({
  volume,
  muted,
  identityMute,
  filter,
}: RoomAudioRendererProps) {
  const tracks = useTracks(
    [Track.Source.Microphone, Track.Source.ScreenShareAudio, Track.Source.Unknown],
    {
      updateOnlyOn: [],
      onlySubscribed: false,
    },
  ).filter(
    ref =>
      !isLocal(ref.participant) &&
      ref.publication.kind === Track.Kind.Audio &&
      (!filter || filter(ref.participant.identity)),
  );

  useEffect(() => {
    for (const track of tracks) {
      (track.publication as RemoteTrackPublication).setSubscribed(true);
    }
  }, [tracks]);

  return (
    <div style={{ display: 'none' }}>
      {tracks.map(trackRef => (
        <AudioTrack
          key={getTrackReferenceId(trackRef)}
          trackRef={trackRef}
          // Set volume to 0 if muted - setting mute prop stops audio being sent
          volume={(identityMute?.[trackRef.participant.identity] || muted ? 0 : 1) * (volume || 1)}
        />
      ))}
    </div>
  );
}
