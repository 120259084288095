import { LargeLoadingProps } from 'components/loading/LargeLoading';
import { createContext, useContext } from 'react';

interface SparxTheme {
  loadingComponent?: (props: LargeLoadingProps) => JSX.Element;
}

const sparxThemeContext = createContext<SparxTheme | undefined>(undefined);

export const SparxThemeProvider = sparxThemeContext.Provider;

export const useSparxTheme = () => useContext(sparxThemeContext);
