import {
  SchoolPeriod,
  SchoolPeriodType,
  SchoolYear,
} from '@sparx/api/apis/sparx/school/calendar/v3/calendar';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import {
  Group,
  ListGroupsRequest,
  ListYearGroupsRequest,
  UpdateGroupRequest,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { FetchQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { calendarClient, groupsClient, schoolsClient, studentsClient } from 'api';
import { queryClient } from 'api/client';
import { getSchoolID, isAnonymousMode } from 'api/sessions';
import { addDays, format, isAfter, isBefore, startOfDay } from 'date-fns';
import { useMemo } from 'react';

export const studentsQueryKey = ['school', 'students'];
export const studentsQuery: FetchQueryOptions<Student[]> = {
  queryKey: studentsQueryKey,
  queryFn: async () => {
    // If the fake names is set, we replace the student names with fake names
    let mapStudent = (s: Student) => s;
    if (isAnonymousMode()) {
      const { randomName } = await import('api/fakenames/names');
      mapStudent = (s: Student) => ({ ...s, ...randomName(s.studentId) });
    }

    return studentsClient
      .listStudents({ schoolId: await getSchoolID() })
      .response.then(data => data.students.map(mapStudent));
  },
  staleTime: 1000 * 60 * 15, // 15 minutes
};

export const groupsQueryKey = ['school', 'groups'];
export const groupsQuery: FetchQueryOptions<Group[]> = {
  queryKey: groupsQueryKey,
  queryFn: async () =>
    groupsClient
      .listGroups(
        ListGroupsRequest.create({
          groupIds: [],
          includeHomeworkPlans: false,
          parent: `schools/${await getSchoolID()}`,
        }),
      )
      .response.then(data => data.groups),
  staleTime: 1000 * 60 * 15, // 15 minutes
};

const schoolQuery: FetchQueryOptions<School> = {
  queryKey: ['school', 'data'],
  queryFn: async () =>
    schoolsClient.getSchool({
      name: 'schools/' + (await getSchoolID()),
    }).response,
  cacheTime: Infinity,
  staleTime: Infinity,
};

export interface SchoolYearWithDates extends SchoolYear {
  start: Date;
  end: Date;
  current?: boolean;
  next?: boolean;
}

export const schoolYearQuery: FetchQueryOptions<SchoolYearWithDates[]> = {
  queryKey: ['school', 'year'],
  queryFn: async () =>
    await calendarClient
      .listSchoolYears({
        parent: `schools/${await getSchoolID()}`,
      })
      .response.then(d => {
        const yearsWithStartDate: SchoolYearWithDates[] = d.schoolYears.map(year => ({
          ...year,
          start: Timestamp.toDate(year.startDate!),
          end: Timestamp.toDate(year.endDate!),
        }));
        yearsWithStartDate.sort((a, b) => (isBefore(a.start, b.start) ? -1 : 1));
        for (const i in yearsWithStartDate) {
          if (isAfter(yearsWithStartDate[i].start, new Date())) {
            yearsWithStartDate[parseInt(i) - 1].current = true;
            yearsWithStartDate[i].next = true;
            break;
          }
        }
        return yearsWithStartDate;
      }),
  cacheTime: Infinity,
  staleTime: Infinity,
};

// listSchoolPeriods with a specific year index doesn't always return all periods
// intersecting that school year, for some reason the periods may not be associated
// with the right year index.
// Instead we get all periods and then filter ased on dates instead.
const allSchoolYearPeriodsQuery: FetchQueryOptions<SchoolPeriod[]> = {
  queryKey: ['school', 'year', 'all', 'periods'],
  queryFn: async () =>
    await calendarClient
      .listSchoolPeriods({
        parent: `schools/${await getSchoolID()}`,
        schoolYear: 0,
        periodType: SchoolPeriodType.HOLIDAY,
      })
      .response.then(r => r.periods),
  cacheTime: Infinity,
  staleTime: Infinity,
};

const schoolYearPeriodQuery = (yearIndex: string): FetchQueryOptions<SchoolPeriod[]> => ({
  queryKey: ['school', 'year', yearIndex, 'periods'],
  queryFn: async () => {
    const [schoolYears, periods] = await Promise.all([
      queryClient.fetchQuery(schoolYearQuery),
      // listSchoolPeriods with a specific year index doesn't always return all periods
      // intersecting that school year, for some reason the periods may not be associated
      // with the right year index.
      // Instead we get all periods and then filter ased on dates instead.
      queryClient.fetchQuery(allSchoolYearPeriodsQuery),
    ]);

    // Find the current school year or the one with the index if provided
    let schoolYear: SchoolYearWithDates | undefined;
    if (yearIndex === '0') {
      schoolYear = findMostRecentSchoolYear(schoolYears);
    } else {
      schoolYear = schoolYears.find(y => y.name.endsWith(`/years/${yearIndex}`));
    }

    return periods.filter(period => {
      if (!schoolYear || !period.startDate || !period.endDate) {
        return false;
      }
      const periodStart = Timestamp.toDate(period.startDate);
      const periodEnd = Timestamp.toDate(period.endDate);
      // Include the period if it starts before the end of the year, and ends after the start of the year
      return isBefore(periodStart, schoolYear.end) && isBefore(schoolYear.start, periodEnd);
    });
  },
  cacheTime: Infinity,
  staleTime: Infinity,
});

export interface Options {
  suspense: boolean;
  enabled?: boolean;
}

export const useStudents = (opts: Options) =>
  useQuery({
    ...studentsQuery,
    ...opts,
  });

export const useGroups = (opts: Options) =>
  useQuery({
    ...groupsQuery,
    ...opts,
  });

export const useSortedGroups = (groups: Group[], includeAll?: boolean) =>
  useMemo(
    () =>
      groups
        ?.filter(g => includeAll || g.type === StudentGroupType.CLASS_SCIENCE)
        .sort(
          (a, b) =>
            parseInt(a.displayName) - parseInt(b.displayName) ||
            a.displayName.localeCompare(b.displayName),
        ) || [],
    [groups],
  );

export const useGroupOfType = (
  student: Student | undefined,
  groups: Group[] | undefined,
  typ: StudentGroupType,
) => useMemo(() => findGroupOfType(student, groups || [], typ), [student, groups, typ]);

export const findGroupOfType = (
  student: Student | undefined,
  groups: Group[],
  typ: StudentGroupType,
) =>
  groups.find(
    g => g.type === typ && student?.studentGroupIds.includes(g.name.split('studentGroups/')[1]),
  );

export const useGroup = (groupID: string, opts: Options) =>
  useQuery({
    ...groupsQuery,
    ...opts,
    select: d => d.find(g => groupID && g.name.endsWith(groupID)),
  });

export const useSchool = (opts: Options) =>
  useQuery({
    ...schoolQuery,
    ...opts,
  });

export const useSchoolYears = (opts: Options, onlyActive?: boolean) =>
  useQuery({
    ...schoolYearQuery,
    ...opts,
    select: d => (onlyActive ? d.filter(y => y.current || y.next) : d),
  });

export const useCurrentSchoolYear = (opts: Options) =>
  useQuery({
    ...schoolYearQuery,
    ...opts,
    select: d => findMostRecentSchoolYear(d),
  });

export const usePasswordReset = ({ onSuccess }: { onSuccess?: () => void }) =>
  useMutation({
    mutationFn: async (studentIds: string[]) =>
      studentsClient
        .grantPasswordReset({
          studentIds: studentIds,
          schoolId: await getSchoolID(),
        })
        .response.then(r => r.studentLogins),
    onSuccess: resp => {
      studentsQuery.queryKey &&
        queryClient.setQueryData(studentsQuery.queryKey, (data: Student[] | undefined) => {
          if (!data) return data;
          // Overwrite any existing login with the new ones
          return data.map(d => (resp[d.studentId] ? { ...d, login: resp[d.studentId] } : d));
        });
      onSuccess?.();
    },
  });

export interface Week {
  index: number;
  startDate: Date;
  endDate: Date;
  lastDate: Date; // lastDate is the last day of the week
  past: boolean;
  current: boolean;
  next: boolean;
  dates: WeekDate[];
}

interface WeekDate {
  date: Date;
  holiday: boolean;
}

export const dateInWeek = (w: Week, date: Date): boolean =>
  isBefore(w.startDate, date) && isAfter(w.endDate, date);

export const findMostRecentSchoolYear = (
  years: SchoolYearWithDates[],
): SchoolYearWithDates | undefined => years.find(y => y.current);

export const useWeeks = (opts: Options) => useWeeksForYear('0', opts);

export const useHolidaysForSchoolYear = (yearIndex: string, opts: Options) =>
  useQuery({
    ...schoolYearPeriodQuery(yearIndex),
    ...opts,
  });

export const useWeeksForYear = (yearIndex: string, opts: Options) =>
  useQuery({
    queryKey: ['school', 'weeks', yearIndex],
    queryFn: async () => {
      const [schoolYears, periods] = await Promise.all([
        queryClient.fetchQuery(schoolYearQuery),
        queryClient.fetchQuery(schoolYearPeriodQuery(yearIndex)),
      ]);
      const now = new Date();

      // Find the current school year or the one with the index if provided
      let schoolYear: SchoolYearWithDates | undefined;
      if (yearIndex === '0') {
        schoolYear = findMostRecentSchoolYear(schoolYears);
      } else {
        schoolYear = schoolYears.find(y => y.name.endsWith(`/years/${yearIndex}`));
      }

      const holidays = new Set<string>();
      for (const period of periods) {
        if (!period.startDate || !period.endDate) continue;
        const periodStart = Timestamp.toDate(period.startDate);
        const periodEnd = Timestamp.toDate(period.endDate);
        for (
          let start = startOfDay(periodStart);
          isBefore(start, addDays(periodEnd, 1));
          start = addDays(start, 1)
        ) {
          holidays.add(format(start, 'yyyy-MM-dd'));
        }
      }

      let isPast = true;
      let isNext = false;
      const weeks: Week[] = [];
      for (const week of schoolYear?.weeks || []) {
        if (!week.startDate || !week.index) continue;

        const startDate = Timestamp.toDate(week.startDate);
        const endDate = addDays(startDate, 7);
        const lastDate = addDays(startDate, 6);

        const dates = [];
        for (let start = startDate; isBefore(start, endDate); start = addDays(start, 1)) {
          dates.push({
            date: start,
            holiday: holidays.has(format(start, 'yyyy-MM-dd')),
          });
        }

        const current = isBefore(startDate, now) && isAfter(endDate, now);
        if (current) isPast = false;
        weeks.push({
          index: week.index,
          startDate,
          endDate,
          lastDate,
          past: isPast,
          current,
          next: isNext,
          dates,
        });
        isNext = current;
      }
      return weeks;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    ...opts,
  });

export const useWeekForDate = (date: Timestamp | undefined, opts: Options) => {
  const { data: weeks } = useWeeks(opts);
  return useMemo(
    () => weeks?.find(w => date && dateInWeek(w, Timestamp.toDate(date))),
    [weeks, date],
  );
};

const ANNOTATION_ROLLOVER_INTERIM_TIME = 'sparx.schools.rollover/interim-time';

export const useIsInRolloverInterim = (opts: Options) => {
  const { data: school } = useSchool(opts);
  return Boolean(school?.annotations[ANNOTATION_ROLLOVER_INTERIM_TIME]);
};

export const useUpdateGroup = () =>
  useMutation({
    mutationFn: async (req: UpdateGroupRequest) => groupsClient.updateGroup(req).response,
    onSuccess: () => {
      queryClient.invalidateQueries(groupsQuery.queryKey);
    },
  });

export const yearGroupsQuery: FetchQueryOptions<YearGroup[]> = {
  queryKey: ['school', 'yeargroups'],
  queryFn: async () =>
    groupsClient
      .listYearGroups(
        ListYearGroupsRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
        }),
      )
      .response.then(data => data.yearGroups),
  staleTime: 1000 * 60 * 15, // 15 minutes
};

export const useYearGroups = (opts: Options) =>
  useQuery({
    ...yearGroupsQuery,
    ...opts,
  });
