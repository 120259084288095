// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/interaction/feedback/v1/feedback.proto" (package "sparx.interaction.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { StudentFeedbackService } from './feedback';
import type { SubmitStudentSentimentFeedbackResponse } from './feedback';
import type { SubmitStudentSentimentFeedbackRequest } from './feedback';
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { TeacherFeedbackService } from './feedback';
import type { SubmitUserSentimentFeedbackResponse } from './feedback';
import type { SubmitUserSentimentFeedbackRequest } from './feedback';
import type { SubmitGeneralFeedbackResponse } from './feedback';
import type { SubmitGeneralFeedbackRequest } from './feedback';
import type { SubmitUserRatingFeedbackResponse } from './feedback';
import type { SubmitUserRatingFeedbackRequest } from './feedback';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SubmitContentFeedbackResponse } from './feedback';
import type { SubmitContentFeedbackRequest } from './feedback';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.interaction.feedback.v1.TeacherFeedbackService
 */
export interface ITeacherFeedbackServiceClient {
  /**
   * @generated from protobuf rpc: SubmitContentFeedback(sparx.interaction.feedback.v1.SubmitContentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitContentFeedbackResponse);
   */
  submitContentFeedback(
    input: SubmitContentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<SubmitContentFeedbackRequest, SubmitContentFeedbackResponse>;
  /**
   * @generated from protobuf rpc: SubmitUserRatingFeedback(sparx.interaction.feedback.v1.SubmitUserRatingFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitUserRatingFeedbackResponse);
   */
  submitUserRatingFeedback(
    input: SubmitUserRatingFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitUserRatingFeedbackRequest,
    SubmitUserRatingFeedbackResponse
  >;
  /**
   * @generated from protobuf rpc: SubmitGeneralFeedback(sparx.interaction.feedback.v1.SubmitGeneralFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitGeneralFeedbackResponse);
   */
  submitGeneralFeedback(
    input: SubmitGeneralFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<SubmitGeneralFeedbackRequest, SubmitGeneralFeedbackResponse>;
  /**
   * @generated from protobuf rpc: SubmitUserSentimentFeedback(sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackResponse);
   */
  submitUserSentimentFeedback(
    input: SubmitUserSentimentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitUserSentimentFeedbackRequest,
    SubmitUserSentimentFeedbackResponse
  >;
}
/**
 * @generated from protobuf service sparx.interaction.feedback.v1.TeacherFeedbackService
 */
export class TeacherFeedbackServiceClient
  implements ITeacherFeedbackServiceClient, ServiceInfo
{
  typeName = TeacherFeedbackService.typeName;
  methods = TeacherFeedbackService.methods;
  options = TeacherFeedbackService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: SubmitContentFeedback(sparx.interaction.feedback.v1.SubmitContentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitContentFeedbackResponse);
   */
  submitContentFeedback(
    input: SubmitContentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<SubmitContentFeedbackRequest, SubmitContentFeedbackResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitContentFeedbackRequest,
      SubmitContentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SubmitUserRatingFeedback(sparx.interaction.feedback.v1.SubmitUserRatingFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitUserRatingFeedbackResponse);
   */
  submitUserRatingFeedback(
    input: SubmitUserRatingFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitUserRatingFeedbackRequest,
    SubmitUserRatingFeedbackResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitUserRatingFeedbackRequest,
      SubmitUserRatingFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SubmitGeneralFeedback(sparx.interaction.feedback.v1.SubmitGeneralFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitGeneralFeedbackResponse);
   */
  submitGeneralFeedback(
    input: SubmitGeneralFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<SubmitGeneralFeedbackRequest, SubmitGeneralFeedbackResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitGeneralFeedbackRequest,
      SubmitGeneralFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SubmitUserSentimentFeedback(sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackResponse);
   */
  submitUserSentimentFeedback(
    input: SubmitUserSentimentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitUserSentimentFeedbackRequest,
    SubmitUserSentimentFeedbackResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitUserSentimentFeedbackRequest,
      SubmitUserSentimentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
}
/**
 * @generated from protobuf service sparx.interaction.feedback.v1.StudentFeedbackService
 */
export interface IStudentFeedbackServiceClient {
  /**
   * @generated from protobuf rpc: SubmitStudentSentimentFeedback(sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse);
   */
  submitStudentSentimentFeedback(
    input: SubmitStudentSentimentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitStudentSentimentFeedbackRequest,
    SubmitStudentSentimentFeedbackResponse
  >;
}
/**
 * @generated from protobuf service sparx.interaction.feedback.v1.StudentFeedbackService
 */
export class StudentFeedbackServiceClient
  implements IStudentFeedbackServiceClient, ServiceInfo
{
  typeName = StudentFeedbackService.typeName;
  methods = StudentFeedbackService.methods;
  options = StudentFeedbackService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: SubmitStudentSentimentFeedback(sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse);
   */
  submitStudentSentimentFeedback(
    input: SubmitStudentSentimentFeedbackRequest,
    options?: RpcOptions
  ): UnaryCall<
    SubmitStudentSentimentFeedbackRequest,
    SubmitStudentSentimentFeedbackResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitStudentSentimentFeedbackRequest,
      SubmitStudentSentimentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
}
