import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { uploadFile } from '@sparx/science/app/storage';
import { useMutation } from '@tanstack/react-query';
import { FirebaseAssetPreview } from 'components/asset/FirebaseAssetPreview';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { useUpdateConferenceState } from 'views/streamerview/utils';

export const WhiteboardTrackCapture = ({
  name,
  lessonState,
}: {
  name: string;
  lessonState: LessonState;
}) => {
  const { mutate: update } = useUpdateConferenceState(lessonState);
  const setWhiteboardAsset = (backgroundAssetId: string) => {
    update({
      whiteboardState: {
        studentNames: [],
        ...lessonState.conferenceState?.whiteboardState,
        whiteboardActivityId: uuid(), // Start a new session
        backgroundAssetId,
      },
    });
  };

  const {
    mutate: capture,
    data,
    reset,
    isLoading,
  } = useMutation({
    mutationFn: async () => {
      // Find the video track from LocalTrackByName element.
      const video = document.getElementById(`localtrack-${name}`) as HTMLVideoElement | undefined;
      if (!video) {
        throw new Error('No video element found');
      }

      // Setup a hidden canvas to draw too
      const canvas = document.createElement('canvas');
      canvas.style.display = 'none';
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('Could not get 2d context');
      }
      // Draw the video to the canvas
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      // Alter the contrast of the image
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const contrastedImaged = contrastImage(imageData);
      context.putImageData(contrastedImaged, 0, 0);

      const data = canvas.toDataURL('image/png');
      return await uploadFile(data);
    },
    onError: err => {
      alert(`Capturing video stream: ${err}`);
    },
  });

  const submit = () => {
    if (data) {
      setWhiteboardAsset(data);
      reset();
    }
  };

  return (
    <>
      <Button
        position="absolute"
        bottom="1"
        left="1"
        onClick={() => capture()}
        isLoading={isLoading}
        size="xs"
        colorScheme="blackAlpha"
      >
        Capture
      </Button>
      <Modal isOpen={Boolean(data)} size="xl" onClose={reset}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={e => e.preventDefault()}>
          <ModalHeader pb={1}>Use this image for whiteboards?</ModalHeader>
          <ModalBody>
            <Text mb={3}>Whiteboards will open immediately with this image:</Text>
            <FirebaseAssetPreview path={data} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={reset} mr={2} variant="outline">
              Cancel
            </Button>
            <Button type="submit" onClick={submit} colorScheme="buttonTeal" isLoading={isLoading}>
              Use image
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

function contrastImage(imgData: ImageData) {
  const d = imgData.data;

  // Find the max combined rgb brightness
  let maxBrightness = 0;
  for (let i = 0; i < d.length; i += 4) {
    const brightness = (d[i] + d[i + 1] + d[i + 2]) / 3;
    maxBrightness = Math.max(maxBrightness, brightness);
  }

  const fixedOffset = 25;
  const contrast = 255 / (maxBrightness - fixedOffset);
  const intercept = 0;

  for (let i = 0; i < d.length; i += 4) {
    d[i] = d[i] * contrast + intercept;
    d[i + 1] = d[i + 1] * contrast + intercept;
    d[i + 2] = d[i + 2] * contrast + intercept;
  }
  return imgData;
}
