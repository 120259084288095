import { Button, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCheck,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AudioTrack,
  useConnectionQualityIndicator,
  useConnectionState,
  useDisconnectButton,
  useIsMuted,
  useIsSpeaking,
  useLocalParticipant,
  useMaybeRoomContext,
  useMediaDeviceSelect,
  useRemoteParticipant,
  useTrackByName,
  useTracks,
  useTrackToggle,
  VideoTrack,
} from '@livekit/components-react';
import { useLocalStorage } from '@sparx/science/utils/hooks/localstorage';
import { ConnectionQuality, LocalTrackPublication, Participant, Track } from 'livekit-client';
import { useEffect, useMemo, useState } from 'react';

export const MultiTrackSelect = ({
  name,
  preferredVideo,
  onDeviceChange,
  fixedVideo,
}: {
  name: string;
  preferredVideo?: string;
  onDeviceChange?: (deviceId: string) => void;
  fixedVideo?: string;
}) => {
  const connectionState = useConnectionState();
  const shouldPublish = connectionState === 'connected';
  const { localParticipant } = useLocalParticipant();

  // Persist the value in local storage
  const [lastSelectedDeviceId, setLastSelectedDeviceId] = useLocalStorage(
    `lastdevice/video/${name}`,
  );
  const [activeDeviceId, setCurrentDeviceId] = useState<string>(lastSelectedDeviceId || '');

  const [publish, setPublish] = useState(false);
  const [track, setTrack] = useState<LocalTrackPublication | undefined>();

  const [published, setPublished] = useState(false);
  useEffect(() => {
    if (publish && !published) {
      setPublished(true);
      (async () => {
        const tracks = await localParticipant.createTracks({
          video: {
            deviceId: activeDeviceId,
            facingMode: 'user',
          },
        });
        if (tracks.length === 0) return;
        const publishedTrack = await localParticipant.publishTrack(tracks[0], {
          name,
        });
        setTrack(publishedTrack);
      })();
    }
  }, [name, localParticipant, publish, activeDeviceId, published, setPublished, setTrack]);

  // Ensure that the track is cleaned up if the component is unmounted
  useEffect(() => {
    const trackToRemove = track?.track;
    if (trackToRemove) {
      return () => {
        trackToRemove.stop();
        localParticipant.unpublishTrack(trackToRemove);
      };
    }
  }, [localParticipant, track]);

  const room = useMaybeRoomContext();
  const { devices } = useMediaDeviceSelect({
    kind: 'videoinput',
    track: track?.videoTrack,
    room,
  });

  const handleActiveDeviceChange = async (deviceId: string) => {
    if (shouldPublish) {
      setPublish(true);
    }
    setCurrentDeviceId(deviceId);
    setLastSelectedDeviceId(deviceId);
    onDeviceChange?.(deviceId);
    await track?.videoTrack?.setDeviceId(deviceId);
  };

  useEffect(() => {
    if (!publish) {
      if (preferredVideo) {
        for (const d of devices) {
          if (d.label.toLowerCase().includes(preferredVideo || '')) {
            handleActiveDeviceChange(d.deviceId);
            return;
          }
        }
      }
      const exists = devices.find(d => d.deviceId === lastSelectedDeviceId) || devices[0];
      if (exists) {
        handleActiveDeviceChange(exists.deviceId);
        return;
      }
    }
  }, [publish, lastSelectedDeviceId, preferredVideo, devices, handleActiveDeviceChange]);

  useEffect(() => {
    if (fixedVideo && activeDeviceId !== fixedVideo) {
      for (const d of devices) {
        if (d.deviceId === fixedVideo) {
          console.log('setting fixed device');
          handleActiveDeviceChange(d.deviceId);
          return;
        }
      }
    }
  }, [devices, activeDeviceId, fixedVideo, handleActiveDeviceChange]);

  return (
    <DeviceList
      kind="videoinput"
      devices={devices}
      activeDeviceId={activeDeviceId}
      onSelectDevice={handleActiveDeviceChange}
    />
  );
};

const deviceIcon: Record<MediaDeviceKind, [string, IconDefinition]> = {
  audiooutput: ['Speaker', faVolumeUp],
  videoinput: ['Camera', faVideo],
  audioinput: ['Microphone', faMicrophone],
};

export const DefaultInputSelect = ({ kind }: { kind: 'audioinput' | 'audiooutput' }) => {
  const { devices, activeDeviceId, setActiveMediaDevice } = useMediaDeviceSelect({ kind });

  const [lastSelectedDeviceId, setLastSelectedDeviceId] = useLocalStorage(
    `lastdevice/audio/${kind}`,
  );
  useEffect(() => {
    if (devices.length > 0 && lastSelectedDeviceId && lastSelectedDeviceId !== activeDeviceId) {
      const exists = devices.find(d => d.deviceId === lastSelectedDeviceId);
      if (exists) {
        setActiveMediaDevice(lastSelectedDeviceId);
      } else {
        setLastSelectedDeviceId('');
      }
    }
  }, [
    devices,
    lastSelectedDeviceId,
    activeDeviceId,
    setActiveMediaDevice,
    setLastSelectedDeviceId,
  ]);

  return (
    <DeviceList
      kind={kind}
      devices={devices}
      activeDeviceId={activeDeviceId}
      onSelectDevice={setLastSelectedDeviceId}
    />
  );
};

export const ControlledInputSelect = ({
  kind,
  value,
}: {
  kind: 'audioinput' | 'audiooutput';
  value: string;
}) => {
  const { devices, activeDeviceId, setActiveMediaDevice } = useMediaDeviceSelect({ kind });

  const lastSelectedDeviceId = value;
  useEffect(() => {
    if (devices.length > 0 && lastSelectedDeviceId && lastSelectedDeviceId !== activeDeviceId) {
      const exists = devices.find(d => d.deviceId === lastSelectedDeviceId);
      if (exists) {
        setActiveMediaDevice(lastSelectedDeviceId);
      }
    }
  }, [devices, lastSelectedDeviceId, activeDeviceId, setActiveMediaDevice]);

  return <DeviceList kind={kind} devices={devices} activeDeviceId={activeDeviceId} />;
};

interface DeviceListProps {
  kind: 'audioinput' | 'audiooutput' | 'videoinput';
  devices: { deviceId: string; label: string }[];
  activeDeviceId?: string;
  onSelectDevice?: (deviceId: string) => void;
}

const isDeviceActive = (deviceId: string, activeDeviceId: string | undefined, index: number) => {
  return deviceId === activeDeviceId || (index === 0 && activeDeviceId === '');
};

export const DeviceList = ({ kind, devices, activeDeviceId, onSelectDevice }: DeviceListProps) => {
  const [defaultName, icon] = deviceIcon[kind];
  const activeDevice = devices.find((d, i) => isDeviceActive(d.deviceId, activeDeviceId, i));

  return (
    <Menu>
      <MenuButton
        size="sm"
        colorScheme="blue"
        as={Button}
        maxWidth="230px"
        leftIcon={<FontAwesomeIcon icon={icon} />}
        rightIcon={onSelectDevice ? <FontAwesomeIcon icon={faCaretDown} /> : undefined}
      >
        <Text overflow="hidden" textOverflow="ellipsis">
          {activeDevice?.label || `Select ${defaultName}`}
        </Text>
      </MenuButton>
      <MenuList>
        {devices.map((device, index) => (
          <MenuItem
            key={device.deviceId}
            onClick={onSelectDevice ? () => onSelectDevice(device.deviceId) : undefined}
            icon={
              isDeviceActive(device.deviceId, activeDeviceId, index) ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : undefined
            }
          >
            {device.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const TrackByName = ({ identity, name }: { identity: string; name: string }) => {
  const tracks = useTracks([{ source: Track.Source.Camera, withPlaceholder: true }]);
  const track = useMemo(
    () =>
      tracks.find(t => t.participant.identity === identity && t.publication?.trackName === name),
    [tracks, identity, name],
  );
  return track?.publication ? <VideoTrack width="100%" trackRef={track} /> : <>No track found</>;
};

export const AudioTrackForParticipant = ({ identity }: { identity: string }) => {
  const tracks = useTracks([{ source: Track.Source.Microphone, withPlaceholder: true }]);
  const track = useMemo(
    () => tracks.filter(t => t.participant.identity === identity),
    [tracks, identity],
  );
  return (
    <>
      {track.map(t =>
        t?.publication ? (
          <AudioTrack
            trackRef={{
              participant: t.participant,
              publication: t.publication,
              source: Track.Source.Microphone,
            }}
          />
        ) : null,
      )}
    </>
  );
};

export const LocalTrackByName = ({ name }: { name: string }) => {
  const { localParticipant: participant } = useLocalParticipant();
  const { publication } = useTrackByName(name, participant);
  return publication ? (
    <VideoTrack
      // ID is used by WhiteboardTrackCapture to get HTMLVideoElement. There is no
      // way to pass ref through to the library here.
      id={`localtrack-${name}`}
      width="100%"
      trackRef={{ publication, source: Track.Source.Camera, participant }}
    />
  ) : (
    <></>
  );
};

export const LocalScreenShareTrack = () => {
  const { localParticipant: participant } = useLocalParticipant();
  const tracks = useTracks([{ source: Track.Source.ScreenShare, withPlaceholder: true }]);
  const track = useMemo(
    () => tracks.find(t => t.participant.identity === participant.identity),
    [tracks, participant],
  );
  return track?.publication ? <VideoTrack width="100%" trackRef={track} /> : <></>;
};

export const RemoteScreenShareTrack = ({ identity }: { identity: string }) => {
  const participant = useRemoteParticipant(identity);
  const tracks = useTracks([{ source: Track.Source.ScreenShare, withPlaceholder: true }]);
  const track = useMemo(
    () => tracks.find(t => t.participant.identity === participant?.identity),
    [tracks, participant],
  );
  return track?.publication ? <VideoTrack width="100%" trackRef={track} /> : <></>;
};

export const MutedButton = () => {
  const { pending, enabled, toggle } = useTrackToggle({ source: Track.Source.Microphone });
  const forcedMuted = false; // TODO: FORCE MUTED
  return (
    <Button
      pointerEvents={forcedMuted ? 'none' : undefined}
      opacity={forcedMuted ? 0.5 : 1}
      colorScheme={!enabled ? 'red' : 'gray'}
      leftIcon={<FontAwesomeIcon icon={!enabled ? faMicrophoneSlash : faMicrophone} />}
      onClick={() => toggle()}
      w={32}
      disabled={pending}
    >
      {!enabled ? 'Unmute' : 'Mute'}
    </Button>
  );
};

export const useIsSelfMuted = () => {
  const { localParticipant } = useLocalParticipant();
  return useIsMuted({ participant: localParticipant, source: Track.Source.Microphone });
};

export const ParticipantConnectionQuality = ({ identity }: { identity: string }) => {
  const participant = useRemoteParticipant(identity);
  if (!participant) {
    return (
      <Text fontSize="xs" opacity={0.8}>
        Not connected
      </Text>
    );
  }
  return <ConnectionQualityIndicator participant={participant} />;
};

export const ConnectionQualityIndicator = ({ participant }: { participant: Participant }) => {
  const { quality } = useConnectionQualityIndicator({
    participant,
  });

  return (
    <VStack spacing={-1} alignItems="flex-end">
      <Text fontSize="2xs" opacity={0.8} color="white">
        Connection:
      </Text>
      <Text fontSize="sm" fontWeight="bold">
        {connectionStrengthToText(quality)}
      </Text>
    </VStack>
  );
};

const connectionStrengthToText = (quality: ConnectionQuality) => {
  switch (quality) {
    case ConnectionQuality.Excellent:
      return <Text color="green.500">Excellent</Text>;
    case ConnectionQuality.Good:
      return <Text color="yellow.500">Good</Text>;
    case ConnectionQuality.Poor:
      return <Text color="red.500">Poor</Text>;
    case ConnectionQuality.Lost:
      return <Text color="red.500">Connection lost</Text>;
    default:
      return <Text color="gray.500">Unknown</Text>;
  }
};

export const useParticipantSpeakingIndicator = (participant: Participant) => {
  const isSpeaking = useIsSpeaking(participant);
  return {
    style: {
      outline: isSpeaking ? '2px solid rgba(211, 228, 248, 1)' : '0 solid rgba(211, 228, 248, 0)',
      transition: 'outline 0.2s ease-in-out',
    },
  };
};

export const DisconnectButton = () => {
  const {
    buttonProps: { onClick, disabled },
  } = useDisconnectButton({ stopTracks: true });
  return (
    <Button disabled={disabled} onClick={onClick} colorScheme="red">
      Leave session
    </Button>
  );
};
