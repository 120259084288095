// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/personalisation/bespokepackager/v1/bespokepackager.proto" (package "sparx.personalisation.bespokepackager", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { PackageDetails } from '../../../packages/v1/spxpkg';
import { AttainmentLevel } from '../../attainmentlevel/v1/attainmentlevel';
import { PackageType } from '../../../packages/v1/spxpkg';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.GenerateBespokePackagesRequest
 */
export interface GenerateBespokePackagesRequest {
  /**
   * Formerly known as homeworkID, this is a unique ID to link this request to other objects in the system
   *
   * @generated from protobuf field: string generation_id = 1;
   */
  generationId: string;
  /**
   * ID of the student for whom this package should be personalised
   *
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * A list of packages that need populating
   *
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.PackageRequest packages = 3;
   */
  packages: PackageRequest[];
  /**
   * A list of packages which share the generation_id, but that are not contained within the packages
   * requested above. All packages for the generation_id should be present in precisely one of these
   * two repeated lists.
   *
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.PackageInfo related_packages = 9;
   */
  relatedPackages: PackageInfo[];
  /**
   * Information about the student and the content/plan used to inform the personalisation
   *
   * @generated from protobuf field: sparx.personalisation.bespokepackager.StudentInfo student_info = 4;
   */
  studentInfo?: StudentInfo;
  /**
   * @generated from protobuf field: sparx.personalisation.bespokepackager.ContentInfo content_info = 5;
   */
  contentInfo?: ContentInfo;
  /**
   * Set to true to simulate the homework but don't persist anything
   *
   * @generated from protobuf field: bool dry_run = 6;
   */
  dryRun: boolean;
  /**
   * @generated from protobuf field: string tag = 7;
   */
  tag: string; // Tag for the annotations (must be empty for real runs)
  /**
   * @generated from protobuf field: google.protobuf.Timestamp generation_time_override = 8;
   */
  generationTimeOverride?: Timestamp; // Time override for modelling etc. when dry-running
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.PackageRequest
 */
export interface PackageRequest {
  /**
   * E.g. compulsory, optional etc.
   *
   * @generated from protobuf field: sparx.packages.v1.PackageType type = 1;
   */
  type: PackageType;
  /**
   * Unique ID for the package
   *
   * @generated from protobuf field: string package_id = 2;
   */
  packageId: string;
  /**
   * How many tasks to put in the package
   *
   * @generated from protobuf field: int64 number_of_tasks = 3;
   */
  numberOfTasks: number;
  /**
   * Specify one of the following:
   * total desired length of the package
   *
   * @generated from protobuf field: float length_in_minutes = 4;
   */
  lengthInMinutes: number;
  /**
   * exact number of required items in each task
   *
   * @generated from protobuf field: int64 items_per_task = 5;
   */
  itemsPerTask: number;
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.PackageInfo
 */
export interface PackageInfo {
  /**
   * E.g. compulsory, optional etc.
   *
   * @generated from protobuf field: sparx.packages.v1.PackageType type = 1;
   */
  type: PackageType;
  /**
   * Unique ID for the package
   *
   * @generated from protobuf field: string package_id = 2;
   */
  packageId: string;
}
/**
 * This is a mixture of stuff used for feature flags and stuff that's important - is that a problem?
 *
 * @generated from protobuf message sparx.personalisation.bespokepackager.StudentInfo
 */
export interface StudentInfo {
  /**
   * Set for a student on their first ever homework in Sparx.
   * Used to trigger diagnostic
   * We plan to deprecate this flag when we move diagnostic out of this service
   *
   * @generated from protobuf field: bool is_first_homework = 1;
   */
  isFirstHomework: boolean;
  /**
   * Attainment level set by the teacher and used to baseline the student's ability
   *
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel attainment_level = 2;
   */
  attainmentLevel: AttainmentLevel;
  /**
   * Year (yeargroup) is used to baseline the time model
   *
   * @generated from protobuf field: int64 year = 3;
   */
  year: number;
  /**
   * Properties for use in feature flagging
   *
   * @generated from protobuf field: string group_id = 4;
   */
  groupId: string;
  /**
   * @generated from protobuf field: string school_id = 5;
   */
  schoolId: string;
  /**
   * Deprecated: A display name for the stream, used for Numerise revision packages
   *
   * @deprecated
   * @generated from protobuf field: string fixed_stream_name = 6 [deprecated = true];
   */
  fixedStreamName: string;
  /**
   * Number of recent completed homeworks
   *
   * @generated from protobuf field: int32 complete_homework_streak = 7;
   */
  completeHomeworkStreak: number;
  /**
   * A learning path spec, as selected by the teacher for the student, to override stream selections
   *
   * @generated from protobuf field: string learning_path_spec_name_override = 8;
   */
  learningPathSpecNameOverride: string;
  /**
   * Whether higher only topics should be excluded from homework for the student.
   *
   * @generated from protobuf field: bool exclude_higher_only_topics = 9;
   */
  excludeHigherOnlyTopics: boolean;
}
/**
 * The assigned topics for a week of homework.
 *
 * @generated from protobuf message sparx.personalisation.bespokepackager.AssignedCurriculumTopicWeek
 */
export interface AssignedCurriculumTopicWeek {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp assignment_time = 1;
   */
  assignmentTime?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.AssignedCurriculumTopic assigned_curriculum_topics = 2;
   */
  assignedCurriculumTopics: AssignedCurriculumTopic[];
}
/**
 * A topic which has been assigned to a homework in planner. Topics can be marked as removed
 * from consolidation.
 *
 * @generated from protobuf message sparx.personalisation.bespokepackager.AssignedCurriculumTopic
 */
export interface AssignedCurriculumTopic {
  /**
   * Deprecated: Use curriculum_topic_name instead. The topic id
   *
   * @deprecated
   * @generated from protobuf field: string curriculum_topic_id = 1 [deprecated = true];
   */
  curriculumTopicId: string;
  /**
   * Whether the topic has been removed from appearing in consolidation
   *
   * @generated from protobuf field: bool removed_from_consolidation = 2;
   */
  removedFromConsolidation: boolean;
  /**
   * Resource name of the topic
   *
   * @generated from protobuf field: string curriculum_topic_name = 3;
   */
  curriculumTopicName: string;
}
/**
 * Some information about the content that should be candidate for inclusion in the generated package(s)
 *
 * @generated from protobuf message sparx.personalisation.bespokepackager.ContentInfo
 */
export interface ContentInfo {
  /**
   * When was the package (and therefore this content) assigned to the student
   *
   * @generated from protobuf field: google.protobuf.Timestamp assignment_time = 1;
   */
  assignmentTime?: Timestamp;
  /**
   * Topics to be "in focus" for the generation
   * Will also be added to the student's topic history
   *
   * @deprecated
   * @generated from protobuf field: repeated string in_focus_curriculum_topic_ids = 2 [deprecated = true];
   */
  inFocusCurriculumTopicIds: string[];
  /**
   * Topics to be available in consolidation
   * They will be merged with those from the student's personal topic history
   * if they have one
   *
   * @deprecated
   * @generated from protobuf field: repeated string consolidation_curriculum_topic_ids = 5 [deprecated = true];
   */
  consolidationCurriculumTopicIds: string[];
  /**
   * Topics to be available for catchup
   *
   * @deprecated
   * @generated from protobuf field: repeated string catchup_curriculum_topic_ids = 3 [deprecated = true];
   */
  catchupCurriculumTopicIds: string[];
  /**
   * Topics which have been assigned
   *
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.AssignedCurriculumTopicWeek assigned_curriculum_topic_weeks = 6;
   */
  assignedCurriculumTopicWeeks: AssignedCurriculumTopicWeek[];
  /**
   * Which curriculum is the student following
   *
   * @generated from protobuf field: string curriculum = 4;
   */
  curriculum: string;
  /**
   * Information about including a times tables task in the homework
   *
   * @generated from protobuf field: sparx.personalisation.bespokepackager.TimesTablesInfo times_tables_info = 7;
   */
  timesTablesInfo?: TimesTablesInfo;
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.TimesTablesInfo
 */
export interface TimesTablesInfo {
  /**
   * If true, a times tables task will be added to the compulsory package
   *
   * @generated from protobuf field: bool add_task_to_compulsory = 1;
   */
  addTaskToCompulsory: boolean;
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.GenerateBespokePackagesResponse
 */
export interface GenerateBespokePackagesResponse {
  /**
   * Details of the context for which the package was created
   *
   * @generated from protobuf field: string generation_id = 1;
   */
  generationId: string;
  /**
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * Details of the package contents
   *
   * @generated from protobuf field: repeated sparx.packages.v1.PackageDetails packages = 3;
   */
  packages: PackageDetails[];
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.SingleStreamSelectionRequest
 */
export interface SingleStreamSelectionRequest {
  /**
   * ID of the student to get the single stream selection for
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * Properties for use in feature flagging
   *
   * @generated from protobuf field: string group_id = 2;
   */
  groupId: string;
  /**
   * @generated from protobuf field: string school_id = 3;
   */
  schoolId: string;
  /**
   * Curriculum to return the single stream selection for
   *
   * @generated from protobuf field: string curriculum_name = 4;
   */
  curriculumName: string;
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionRequest
 */
export interface BatchGetSingleStreamSelectionRequest {
  /**
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.SingleStreamSelectionRequest single_stream_requests = 1;
   */
  singleStreamRequests: SingleStreamSelectionRequest[];
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.SingleStreamSelection
 */
export interface SingleStreamSelection {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string learning_path_spec_name = 2;
   */
  learningPathSpecName: string;
  /**
   * @generated from protobuf field: sparx.personalisation.bespokepackager.StreamNotReturnedReason not_returned_reason = 3;
   */
  notReturnedReason: StreamNotReturnedReason;
}
/**
 * @generated from protobuf message sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionResponse
 */
export interface BatchGetSingleStreamSelectionResponse {
  /**
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.SingleStreamSelection single_stream_selections = 1;
   */
  singleStreamSelections: SingleStreamSelection[];
}
/**
 * @generated from protobuf enum sparx.personalisation.bespokepackager.StreamNotReturnedReason
 */
export enum StreamNotReturnedReason {
  /**
   * @generated from protobuf enum value: STREAM_NOT_RETURNED_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STREAM_NOT_RETURNED_REASON_NOT_ENOUGH_DATA = 1;
   */
  NOT_ENOUGH_DATA = 1,
  /**
   * @generated from protobuf enum value: STREAM_NOT_RETURNED_REASON_SINGLE_STREAM_OFF = 2;
   */
  SINGLE_STREAM_OFF = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class GenerateBespokePackagesRequest$Type extends MessageType<GenerateBespokePackagesRequest> {
  constructor() {
    super(
      'sparx.personalisation.bespokepackager.GenerateBespokePackagesRequest',
      [
        {
          no: 1,
          name: 'generation_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: 'packages',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PackageRequest,
        },
        {
          no: 9,
          name: 'related_packages',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PackageInfo,
        },
        { no: 4, name: 'student_info', kind: 'message', T: () => StudentInfo },
        { no: 5, name: 'content_info', kind: 'message', T: () => ContentInfo },
        { no: 6, name: 'dry_run', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
        { no: 7, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 8,
          name: 'generation_time_override',
          kind: 'message',
          T: () => Timestamp,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.GenerateBespokePackagesRequest
 */
export const GenerateBespokePackagesRequest =
  new GenerateBespokePackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageRequest$Type extends MessageType<PackageRequest> {
  constructor() {
    super('sparx.personalisation.bespokepackager.PackageRequest', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 2, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'number_of_tasks',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 4,
        name: 'length_in_minutes',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 5,
        name: 'items_per_task',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.PackageRequest
 */
export const PackageRequest = new PackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageInfo$Type extends MessageType<PackageInfo> {
  constructor() {
    super('sparx.personalisation.bespokepackager.PackageInfo', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 2, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.PackageInfo
 */
export const PackageInfo = new PackageInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentInfo$Type extends MessageType<StudentInfo> {
  constructor() {
    super('sparx.personalisation.bespokepackager.StudentInfo', [
      {
        no: 1,
        name: 'is_first_homework',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'attainment_level',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 3,
        name: 'year',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 4, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'fixed_stream_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'complete_homework_streak',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'learning_path_spec_name_override',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'exclude_higher_only_topics',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.StudentInfo
 */
export const StudentInfo = new StudentInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignedCurriculumTopicWeek$Type extends MessageType<AssignedCurriculumTopicWeek> {
  constructor() {
    super('sparx.personalisation.bespokepackager.AssignedCurriculumTopicWeek', [
      { no: 1, name: 'assignment_time', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'assigned_curriculum_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssignedCurriculumTopic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.AssignedCurriculumTopicWeek
 */
export const AssignedCurriculumTopicWeek =
  new AssignedCurriculumTopicWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignedCurriculumTopic$Type extends MessageType<AssignedCurriculumTopic> {
  constructor() {
    super('sparx.personalisation.bespokepackager.AssignedCurriculumTopic', [
      {
        no: 1,
        name: 'curriculum_topic_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'removed_from_consolidation',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'curriculum_topic_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.AssignedCurriculumTopic
 */
export const AssignedCurriculumTopic = new AssignedCurriculumTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentInfo$Type extends MessageType<ContentInfo> {
  constructor() {
    super('sparx.personalisation.bespokepackager.ContentInfo', [
      { no: 1, name: 'assignment_time', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'in_focus_curriculum_topic_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'consolidation_curriculum_topic_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'catchup_curriculum_topic_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'assigned_curriculum_topic_weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssignedCurriculumTopicWeek,
      },
      { no: 4, name: 'curriculum', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'times_tables_info',
        kind: 'message',
        T: () => TimesTablesInfo,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.ContentInfo
 */
export const ContentInfo = new ContentInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesTablesInfo$Type extends MessageType<TimesTablesInfo> {
  constructor() {
    super('sparx.personalisation.bespokepackager.TimesTablesInfo', [
      {
        no: 1,
        name: 'add_task_to_compulsory',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.TimesTablesInfo
 */
export const TimesTablesInfo = new TimesTablesInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateBespokePackagesResponse$Type extends MessageType<GenerateBespokePackagesResponse> {
  constructor() {
    super(
      'sparx.personalisation.bespokepackager.GenerateBespokePackagesResponse',
      [
        {
          no: 1,
          name: 'generation_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: 'packages',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PackageDetails,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.GenerateBespokePackagesResponse
 */
export const GenerateBespokePackagesResponse =
  new GenerateBespokePackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleStreamSelectionRequest$Type extends MessageType<SingleStreamSelectionRequest> {
  constructor() {
    super(
      'sparx.personalisation.bespokepackager.SingleStreamSelectionRequest',
      [
        {
          no: 1,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: 'curriculum_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.SingleStreamSelectionRequest
 */
export const SingleStreamSelectionRequest =
  new SingleStreamSelectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSingleStreamSelectionRequest$Type extends MessageType<BatchGetSingleStreamSelectionRequest> {
  constructor() {
    super(
      'sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionRequest',
      [
        {
          no: 1,
          name: 'single_stream_requests',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SingleStreamSelectionRequest,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionRequest
 */
export const BatchGetSingleStreamSelectionRequest =
  new BatchGetSingleStreamSelectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleStreamSelection$Type extends MessageType<SingleStreamSelection> {
  constructor() {
    super('sparx.personalisation.bespokepackager.SingleStreamSelection', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'learning_path_spec_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'not_returned_reason',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.bespokepackager.StreamNotReturnedReason',
          StreamNotReturnedReason,
          'STREAM_NOT_RETURNED_REASON_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.SingleStreamSelection
 */
export const SingleStreamSelection = new SingleStreamSelection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSingleStreamSelectionResponse$Type extends MessageType<BatchGetSingleStreamSelectionResponse> {
  constructor() {
    super(
      'sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionResponse',
      [
        {
          no: 1,
          name: 'single_stream_selections',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SingleStreamSelection,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.bespokepackager.BatchGetSingleStreamSelectionResponse
 */
export const BatchGetSingleStreamSelectionResponse =
  new BatchGetSingleStreamSelectionResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.personalisation.bespokepackager.BespokePackager
 */
export const BespokePackager = new ServiceType(
  'sparx.personalisation.bespokepackager.BespokePackager',
  [
    {
      name: 'GenerateBespokePackages',
      options: {
        'google.api.http': {
          post: '/v1/student/{student_id}/bespokepackages',
          body: '*',
        },
      },
      I: GenerateBespokePackagesRequest,
      O: GenerateBespokePackagesResponse,
    },
    {
      name: 'BatchGetSingleStreamSelection',
      options: {
        'google.api.http': {
          get: '/v1/student/-/singlestreamselection:batchGet',
        },
      },
      I: BatchGetSingleStreamSelectionRequest,
      O: BatchGetSingleStreamSelectionResponse,
    },
  ]
);
