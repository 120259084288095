import {
  CurriculumSummary,
  GetTopicSummaryRequest,
  TopicSummary,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { Curriculum, Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { curriculumSummariesClient, topicSummariesClient } from 'api';
import { queryClient } from 'api/client';
import { Options } from 'api/school';
import { useMemo } from 'react';

type CurriculumSummarySafe = CurriculumSummary & { curriculum: Curriculum };

const sortCurriculums = (a: CurriculumSummarySafe, b: CurriculumSummarySafe) => {
  const aKS3 = a.curriculum.displayName.startsWith('KS3');
  const bKS3 = b.curriculum.displayName.startsWith('KS3');
  if (aKS3 && !bKS3) return -1;
  if (!aKS3 && bKS3) return 1;
  return a.curriculum.displayName.localeCompare(b.curriculum.displayName);
};

const curriculumsQuery = (
  subject = 'subjects/science',
): FetchQueryOptions<CurriculumSummarySafe[]> => ({
  queryKey: ['content', 'curriculums', subject],
  queryFn: async () =>
    curriculumSummariesClient
      .listCurriculumSummaries({
        includeHidden: false,
        subjectName: subject,
      })
      .response.then(r =>
        (r.curriculumSummaries.filter(c => c.curriculum) as CurriculumSummarySafe[]).sort(
          sortCurriculums,
        ),
      ),
  staleTime: Infinity,
  cacheTime: Infinity,
});

export const useCurriculums = (options: Options & { subject?: string }) =>
  useQuery({
    ...curriculumsQuery(options.subject),
    ...options,
  });

export const useCurriculumName = (name: string | undefined) => {
  const { data: curriculums } = useCurriculums({ suspense: false });
  return useMemo(() => {
    const curr = curriculums?.find(c => name?.startsWith(c.curriculum.name));
    return curr?.curriculum.displayName || '-';
  }, [curriculums, name]);
};

export const useCurriculum = (name: string, options: Options & { subject?: string }) =>
  useQuery({
    ...curriculumsQuery(options.subject),
    ...options,
    select: data => data.find(c => name === c.curriculum.name),
  });

interface CurriculumData {
  id: string;
  name: string;
  topics: TopicSummary[];
}

const curriculumTopicQuery = (
  subject = 'subjects/science',
): FetchQueryOptions<Record<string, CurriculumData>> => ({
  queryKey: ['content', 'curriculums', 'topics', subject],
  queryFn: async () => {
    const curriculums = await queryClient.fetchQuery(curriculumsQuery(subject));
    return Promise.all(
      curriculums.map(async curriculum =>
        topicSummariesClient
          .listTopicSummaries({
            topicParent: curriculum.curriculum?.name || '',
          })
          .response.then(r => ({
            id: curriculum.curriculum.name.split('/')[1],
            name: curriculum.curriculum.name,
            topics: r.topicSummaries,
          })),
      ),
    ).then(data =>
      data.reduce<Record<string, CurriculumData>>((p, v) => {
        p[v.id] = v;
        return p;
      }, {}),
    );
  },
  staleTime: Infinity,
  cacheTime: Infinity,
});

export interface TopicSummarySafe extends TopicSummary {
  topic: Topic;
}

export const useTopicLookup = (options: Options & { subject?: string }) =>
  useQuery({
    queryKey: ['content', 'curriculums', 'lookup', options.subject],
    queryFn: async () => {
      const curriculumTopics = await queryClient.fetchQuery(curriculumTopicQuery(options.subject));
      return Object.values(curriculumTopics).reduce<Record<string, TopicSummarySafe>>((p, v) => {
        v.topics.forEach(t => {
          if (t.topic && t.topic.name) {
            p[t.topic.name] = { ...t, topic: t.topic };
          }
        });
        return p;
      }, {});
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

export type TopicStrandLookup = Record<string, { strand: string; substrand: string }>;

export const useTopicStrandLookup = (options: Options & { subject?: string }) =>
  useQuery({
    queryKey: ['content', 'curriculums', 'strands', 'lookup', options.subject],
    queryFn: async () => {
      const curriculums = await queryClient.fetchQuery(curriculumsQuery(options.subject));
      return curriculums.reduce<TopicStrandLookup>((p, v) => {
        for (const strand of v.strandSummaries) {
          if (strand.strand?.name) {
            p[strand.strand.name] = { strand: strand.strand.displayName, substrand: '' };
            for (const substrand of strand.substrandSummaries) {
              if (substrand.substrand?.name) {
                for (const topic of substrand.substrand.topicNames || []) {
                  p[topic] = {
                    strand: strand.strand.displayName,
                    substrand: substrand.substrand.displayName,
                  };
                }
              }
            }
          }
        }
        return p;
      }, {});
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

export interface TopicWithStrand extends TopicSummary {
  topic: Topic; // stops the topic from possibiliy being undefined
  strand: string;
  substrand: string;
}

export const useTopicWithStrandsLookup = (
  options: Options & { subject?: string },
): Record<string, TopicWithStrand> => {
  const { data: topicSummaries } = useTopicLookup(options);
  const { data: strandLookup } = useTopicStrandLookup(options);
  return useMemo(() => {
    const topics: Record<string, TopicWithStrand> = {};
    for (const topic of Object.values(topicSummaries || {})) {
      const strand = strandLookup?.[topic.topic?.name || '']?.strand || '';
      const substrand = strandLookup?.[topic.topic?.name || '']?.substrand || '';
      topics[topic.topic?.name || ''] = { ...topic, strand, substrand };
    }
    return topics;
  }, [topicSummaries, strandLookup]);
};

export const useTopicSummary = (name: string, options?: Options) =>
  useQuery({
    queryKey: ['content', 'topic', name],
    queryFn: async () =>
      topicSummariesClient.getTopicSummary(
        GetTopicSummaryRequest.create({
          topicName: name,
          options: {
            includeLearningPaths: true,
            includeAllQuestions: true,
            includeQuestionLayoutJson: true,
            includeSkillFlagsAndTags: true,
          },
        }),
      ).response,
    enabled: Boolean(name),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
