// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/keycontacts/v1/keycontacts.proto" (package "sparx.school.keycontacts.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.SchoolKeyContacts
 */
export interface SchoolKeyContacts {
  /**
   * The resource name for the school key contacts.
   * `schools/{school_id}/keyContacts`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The time the school key contacts record was first created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;
  /**
   * The time the school key contacts record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
  /**
   * The finance key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact finance = 4;
   */
  finance?: KeyContact;
  /**
   * The data protection officer key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact data_protection_officer = 5;
   */
  dataProtectionOfficer?: KeyContact;
  /**
   * The network manager key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact network_manager = 6;
   */
  networkManager?: KeyContact;
  /**
   * The sparx lead key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact sparx_lead = 7;
   */
  sparxLead?: KeyContact;
  /**
   * The senior leader key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact senior_leader = 8;
   */
  seniorLeader?: KeyContact;
  /**
   * The Head of Maths key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact head_of_maths = 9;
   */
  headOfMaths?: KeyContact;
  /**
   * The Reader lead key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact reader_lead = 10;
   */
  readerLead?: KeyContact;
  /**
   * The Head of English key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact head_of_english = 11;
   */
  headOfEnglish?: KeyContact;
  /**
   * The Reader senior leader key contact.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact reader_senior_leader = 12;
   */
  readerSeniorLeader?: KeyContact;
}
/**
 * The details for a single contact.
 *
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.KeyContact
 */
export interface KeyContact {
  /**
   * The given (i.e. first) name of the contact.
   *
   * @generated from protobuf field: string given_name = 1;
   */
  givenName: string;
  /**
   * The family (i.e. last) name of the contact.
   *
   * @generated from protobuf field: string family_name = 2;
   */
  familyName: string;
  /**
   * The email address of the contact.
   *
   * @generated from protobuf field: string email = 3;
   */
  email: string;
  /**
   * The timestamp of the last time a welcome email was sent to this contact.
   * Will be set to null if contact email changed.
   *
   * @generated from protobuf field: google.protobuf.Timestamp email_send_time = 4;
   */
  emailSendTime?: Timestamp;
  /**
   * Annotations for services to store arbitrary data against the key contact.
   * Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 5;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.ListSchoolKeyContactsRequest
 */
export interface ListSchoolKeyContactsRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Should be the
   * value of [ListSchoolKeyContactsResponse.next_page_token] from a previous
   * request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.ListSchoolKeyContactsResponse
 */
export interface ListSchoolKeyContactsResponse {
  /**
   * The school key contacts
   *
   * @generated from protobuf field: repeated sparx.school.keycontacts.v1.SchoolKeyContacts school_key_contacts = 1;
   */
  schoolKeyContacts: SchoolKeyContacts[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for GetSchoolKeyContacts.
 *
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.GetSchoolKeyContactsRequest
 */
export interface GetSchoolKeyContactsRequest {
  /**
   * The name of the school key contacts.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for UpdateSchoolKeyContacts.
 *
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.UpdateSchoolKeyContactsRequest
 */
export interface UpdateSchoolKeyContactsRequest {
  /**
   * The school key contacts.
   * The name field is used to identify the school to update.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.SchoolKeyContacts school_key_contacts = 1;
   */
  schoolKeyContacts?: SchoolKeyContacts;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
  /**
   * List of KeyContactTypes in the SchoolKeyContacts that require a welcome
   * email
   *
   * @generated from protobuf field: repeated sparx.school.keycontacts.v1.KeyContactType send_welcome_emails = 3;
   */
  sendWelcomeEmails: KeyContactType[];
}
/**
 * KeyContactType is an enum representation of the different types of
 * KeyContact. It is not used by the service itself but can be used by
 * implementers to reference a specific KeyContact in the SchoolKeyContacts model
 *
 * @deprecated
 * @generated from protobuf enum sparx.school.keycontacts.v1.KeyContactType
 */
export enum KeyContactType {
  /**
   * Unknown key contact type
   *
   * @generated from protobuf enum value: KEYCONTACTTYPE_UNKNOWN = 0;
   */
  KEYCONTACTTYPE_UNKNOWN = 0,
  /**
   * Used to reference the finance contact for a school
   *
   * @generated from protobuf enum value: FINANCE = 1;
   */
  FINANCE = 1,
  /**
   * Used to reference the data protection officer for a school
   *
   * @generated from protobuf enum value: DATA_PROTECTION_OFFICER = 2;
   */
  DATA_PROTECTION_OFFICER = 2,
  /**
   * Used to reference the network manager for a school
   *
   * @generated from protobuf enum value: NETWORK_MANAGER = 3;
   */
  NETWORK_MANAGER = 3,
  /**
   * Used to reference the sparx lead for a school
   *
   * @generated from protobuf enum value: SPARX_LEAD = 4;
   */
  SPARX_LEAD = 4,
  /**
   * Used to reference the senior leader for a school
   *
   * @generated from protobuf enum value: SENIOR_LEADER = 5;
   */
  SENIOR_LEADER = 5,
  /**
   * Used to reference the Head of Maths for a school
   *
   * @generated from protobuf enum value: HEAD_OF_MATHS = 6;
   */
  HEAD_OF_MATHS = 6,
  /**
   * Used to reference the Reader lead for a school
   *
   * @generated from protobuf enum value: READER_LEAD = 7;
   */
  READER_LEAD = 7,
  /**
   * Used to reference the Head of English for a school
   *
   * @generated from protobuf enum value: HEAD_OF_ENGLISH = 8;
   */
  HEAD_OF_ENGLISH = 8,
  /**
   * Used to reference the Reader senior leader a school
   *
   * @generated from protobuf enum value: READER_SENIOR_LEADER = 9;
   */
  READER_SENIOR_LEADER = 9,
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolKeyContacts$Type extends MessageType<SchoolKeyContacts> {
  constructor() {
    super('sparx.school.keycontacts.v1.SchoolKeyContacts', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'update_time', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'finance', kind: 'message', T: () => KeyContact },
      {
        no: 5,
        name: 'data_protection_officer',
        kind: 'message',
        T: () => KeyContact,
      },
      { no: 6, name: 'network_manager', kind: 'message', T: () => KeyContact },
      { no: 7, name: 'sparx_lead', kind: 'message', T: () => KeyContact },
      { no: 8, name: 'senior_leader', kind: 'message', T: () => KeyContact },
      { no: 9, name: 'head_of_maths', kind: 'message', T: () => KeyContact },
      { no: 10, name: 'reader_lead', kind: 'message', T: () => KeyContact },
      { no: 11, name: 'head_of_english', kind: 'message', T: () => KeyContact },
      {
        no: 12,
        name: 'reader_senior_leader',
        kind: 'message',
        T: () => KeyContact,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.SchoolKeyContacts
 */
export const SchoolKeyContacts = new SchoolKeyContacts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KeyContact$Type extends MessageType<KeyContact> {
  constructor() {
    super('sparx.school.keycontacts.v1.KeyContact', [
      { no: 1, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'email_send_time', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.KeyContact
 */
export const KeyContact = new KeyContact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolKeyContactsRequest$Type extends MessageType<ListSchoolKeyContactsRequest> {
  constructor() {
    super('sparx.school.keycontacts.v1.ListSchoolKeyContactsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.ListSchoolKeyContactsRequest
 */
export const ListSchoolKeyContactsRequest =
  new ListSchoolKeyContactsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolKeyContactsResponse$Type extends MessageType<ListSchoolKeyContactsResponse> {
  constructor() {
    super('sparx.school.keycontacts.v1.ListSchoolKeyContactsResponse', [
      {
        no: 1,
        name: 'school_key_contacts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolKeyContacts,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.ListSchoolKeyContactsResponse
 */
export const ListSchoolKeyContactsResponse =
  new ListSchoolKeyContactsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolKeyContactsRequest$Type extends MessageType<GetSchoolKeyContactsRequest> {
  constructor() {
    super('sparx.school.keycontacts.v1.GetSchoolKeyContactsRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.GetSchoolKeyContactsRequest
 */
export const GetSchoolKeyContactsRequest =
  new GetSchoolKeyContactsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolKeyContactsRequest$Type extends MessageType<UpdateSchoolKeyContactsRequest> {
  constructor() {
    super('sparx.school.keycontacts.v1.UpdateSchoolKeyContactsRequest', [
      {
        no: 1,
        name: 'school_key_contacts',
        kind: 'message',
        T: () => SchoolKeyContacts,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'send_welcome_emails',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.school.keycontacts.v1.KeyContactType', KeyContactType],
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.UpdateSchoolKeyContactsRequest
 */
export const UpdateSchoolKeyContactsRequest =
  new UpdateSchoolKeyContactsRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.keycontacts.v1.SchoolKeyContactsService
 */
export const SchoolKeyContactsService = new ServiceType(
  'sparx.school.keycontacts.v1.SchoolKeyContactsService',
  [
    {
      name: 'GetSchoolKeyContacts',
      options: {
        'google.api.http': { get: '/v1/{name=schools/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'keycontacts',
          domain: '{name|singleton}',
        },
      },
      I: GetSchoolKeyContactsRequest,
      O: SchoolKeyContacts,
    },
    {
      name: 'ListSchoolKeyContacts',
      options: {
        'google.api.http': { get: '/v1/schools/-/keyContacts' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'keycontacts',
          domain: 'schools',
        },
      },
      I: ListSchoolKeyContactsRequest,
      O: ListSchoolKeyContactsResponse,
    },
    {
      name: 'UpdateSchoolKeyContacts',
      options: {
        'google.api.http': {
          patch: '/v1/{school_key_contacts.name=schools/*}',
          body: 'school_key_contacts',
        },
        'sparx.api.auth': {
          action: 'write',
          resource: 'keycontacts',
          domain: '{school_key_contacts.name|singleton}',
        },
      },
      I: UpdateSchoolKeyContactsRequest,
      O: SchoolKeyContacts,
    },
  ]
);
