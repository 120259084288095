import { Box, Button, HStack } from '@chakra-ui/react';
import { faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from 'views/task/Navigator.module.css';

interface NavigatorProps {
  task: Task | undefined;
  selected: number | 'results';
  setSelected: (value: number | 'results') => void;
}

export const Navigator = ({ task, selected, setSelected }: NavigatorProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const scrollIntoView = (id: number | 'results', instant?: boolean) => {
    const element = document.getElementById('nav-' + id);
    if (element) {
      element.scrollIntoView({
        inline: instant ? 'center' : 'center',
        block: 'nearest',
        behavior: instant ? 'auto' : 'smooth',
      });
    }
  };

  const items = task?.contents?.taskItems || [];
  useEffect(() => {
    if (items) {
      scrollIntoView(selected, false);
    }
  }, [items, selected]);

  return (
    <HStack
      px={[0, 3]}
      spacing={[0, '2px']}
      alignItems="flex-end"
      as={ScrollContainer}
      hideScrollbars={true}
      py={2}
      my={-2}
      innerRef={containerRef}
      vertical={false}
      overflowY="hidden"
      zIndex={3}
      position="relative"
    >
      {items.map((item, index) => (
        <TaskItemButton
          selected={selected === index + 1}
          index={index + 1}
          status={item.state?.status}
          key={item.name}
          onClick={() => setSelected(index + 1)}
        />
      ))}
      <NavigatorButton
        width={80}
        selected={selected === 'results'}
        onClick={() => setSelected('results')}
        id={`nav-results`}
      >
        Results
      </NavigatorButton>
    </HStack>
  );
};

interface TaskItemButtonProps {
  index: number;
  status?: TaskItem_Status;
  selected?: boolean;
  onClick?: () => void;
}

const TaskItemButton = ({ index, status, selected, onClick }: TaskItemButtonProps) => {
  let icon;
  let backgroundColour;
  let textColour;
  let iconColour = 'white';
  switch (status) {
    case TaskItem_Status.CORRECT:
    case TaskItem_Status.PENDING_CORRECT:
      backgroundColour = 'green.500';
      textColour = 'white';
      icon = <FontAwesomeIcon icon={faCheck} />;
      break;
    case TaskItem_Status.SKIPPED:
      icon = <FontAwesomeIcon icon={faArrowRight} />;
      iconColour = 'gray.500';
      break;
    case TaskItem_Status.INCORRECT:
      icon = <FontAwesomeIcon icon={faTimes} />;
      iconColour = 'red.500';
      break;
  }

  return (
    <NavigatorButton
      selected={selected}
      onClick={onClick}
      backgroundColour={backgroundColour}
      textColour={textColour}
      id={`nav-${index}`}
    >
      <span translate="no">Q{index}</span>
      {icon && (
        <Box pl={2} mb={-1} fontSize="lg" color={selected ? 'white' : iconColour}>
          {icon}
        </Box>
      )}
    </NavigatorButton>
  );
};

interface NavigatorButtonProps {
  children: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  backgroundColour?: string;
  textColour?: string;
  width?: number;
  id?: string;
}

const NavigatorButton = ({
  children,
  selected,
  onClick,
  backgroundColour = 'gray.200',
  textColour = 'gray.800',
  width = 75,
  id,
}: NavigatorButtonProps) => (
  <Button
    bg={selected ? 'blue.800' : backgroundColour}
    color={selected ? 'white' : textColour}
    _hover={selected ? { bg: 'blue.800' } : {}}
    _focus={selected ? { bg: 'blue.800' } : {}}
    w={[`${width * 0.9}px`, `${width}px`]}
    h={selected ? [9, 10] : [9, 9]}
    borderTopRadius={['none', 'md']}
    borderBottomRadius={0}
    className={styles.Button}
    onClick={onClick}
    flexShrink={0}
    id={id}
    fontSize={['sm', 'md']}
  >
    {children}
    <Box className={classNames(styles.ChevronContainer, selected && styles.ChevronContainerActive)}>
      <Box borderColor="blue.800" className={styles.Chevron} />
    </Box>
  </Button>
);
