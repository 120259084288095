import { StyleProps, SystemStyleObject } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { QueryClient } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

export interface SortOptions {
  upIcon: IconDefinition;
  downIcon: IconDefinition;
  hoverStyle: SystemStyleObject;
}

/** TODO: A more sustainable way of customising this module to each product is required */
interface StaffManagerStyles {
  linkColor: StyleProps['color'];
  editIconColor: StyleProps['color'];
  headerBackgroundColor: StyleProps['backgroundColor'];
  tableSortOptions: SortOptions;
}

/**
 * On completion of an operation, navigate and then optionally display a
 * success toast with a given message
 */
interface NavigationAction {
  (toastMessage?: string): void;
}

export interface NavigationState {
  toastMessage?: string;
}

interface Context {
  school: School | undefined;
  currentUserID: string | undefined;
  queryClient: QueryClient;
  staffClient: SchoolStaffServiceClient;
  defaultProduct: Product;

  knowledgeBaseArticleURL: string;

  // include training progress in the table?
  // We could remove this once both science and reader have training?
  showTrainingProgress?: boolean;

  getSchoolID: () => Promise<string>; // Probably don't need this?
  onSelectStaff: (staffName: string, args?: { replace?: boolean }) => void;
  onStaffDeleted: NavigationAction;
  onStaffCreated: NavigationAction;
  getInfoTooltip: (text: React.ReactNode) => React.ReactNode;
  onSwitchTab: (tabIndex: number) => void;
  styles: StaffManagerStyles;
  useNavigationMessage: () => NavigationState['toastMessage'];
}

const context = createContext<Context | null>(null);

export const useStaffContext = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw new Error('useStaffContext must be used within a StaffContextProvider');
  }
  return ctx;
};

export const StaffContextProvider = context.Provider;
