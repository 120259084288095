// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/lessons.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { LessonStudentNotification } from './notifications';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Package } from '../../packages/v1/package';
import { Assignment_Spec } from '../../packages/v1/planner';
import { Completion } from '../../../progress/v1/completion';
import { LessonConferenceState } from './teamteaching';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonState
 */
export interface LessonState {
  /**
   * Lesson name in the format `schools/{school_id}/lessons/{lesson_id}`.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * Code to join the lesson.
   *
   * @generated from protobuf field: string lesson_code = 2;
   */
  lessonCode: string;
  /**
   * User who created the lesson.
   *
   * @generated from protobuf field: string creator_user_id = 3;
   */
  creatorUserId: string;
  /**
   * The groups that this lesson is assigned to.
   *
   * @generated from protobuf field: repeated string group_names = 4;
   */
  groupNames: string[];
  /**
   * Display name of the lesson
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * Current pause state of the lesson.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.PauseState pause_state = 6;
   */
  pauseState: PauseState;
  /**
   * List of individual students that are paused in the lesson.
   * This will override the pause_state value for the whole
   * lesson unless the value is PAUSE_STATE_UNSPECIFIED.
   *
   * @generated from protobuf field: map<string, sparx.science.lessons.v1.PauseState> student_pause_states = 12;
   */
  studentPauseStates: {
    [key: string]: PauseState;
  };
  /**
   * List of activities associated with this lesson.
   *
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonActivity activities = 7;
   */
  activities: LessonActivity[];
  /**
   * Timestamp that the lesson was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 8;
   */
  createdTimestamp?: Timestamp;
  /**
   * Timestamp when the lesson was ended. If empty then the lesson is still active.
   *
   * @generated from protobuf field: google.protobuf.Timestamp ended_timestamp = 10;
   */
  endedTimestamp?: Timestamp;
  /**
   * Timestamp when the lesson was last 'progressed'. This could be through a
   * task being set or the class being unpaused.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_progress_timestamp = 15;
   */
  lastProgressTimestamp?: Timestamp;
  /**
   * Activity that the students are currently set to. If empty then the students
   * can do any activity.
   * Setting this value will clear student_set_lesson_activity_ids.
   *
   * @generated from protobuf field: string set_lesson_activity_id = 9;
   */
  setLessonActivityId: string;
  /**
   * List of individual students that are set to a specific activity.
   * This will override the set_lesson_activity_id value for the whole
   * lesson unless the value is empty.
   *
   * @generated from protobuf field: map<string, string> student_set_lesson_activity_ids = 16;
   */
  studentSetLessonActivityIds: {
    [key: string]: string;
  };
  /**
   * Ephemeral activity that the students are currently set to.
   *
   * @generated from protobuf field: string set_ephemeral_lesson_activity_id = 14;
   */
  setEphemeralLessonActivityId: string;
  /**
   * List of features that are available in this lesson.
   *
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonFeature features = 11;
   */
  features: LessonFeature[];
  /**
   * Timer for the lesson. If empty then there is no timer.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonTimer timer = 13;
   */
  timer?: LessonTimer;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LessonConferenceState conference_state = 50;
   */
  conferenceState?: LessonConferenceState;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonTimer
 */
export interface LessonTimer {
  /**
   * Length of the timer in seconds. Required value
   * when updating the timer.
   *
   * @generated from protobuf field: int32 duration_seconds = 1;
   */
  durationSeconds: number;
  /**
   * Title for the timer, if any.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * Time that the timer was started. Cannot be set.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;
  /**
   * Time that the timer should end. Cannot be set.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentState
 */
export interface StudentState {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.JoinState join_state = 3;
   */
  joinState: JoinState;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentActiveState active_state = 4;
   */
  activeState: StudentActiveState;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_seen = 5;
   */
  lastSeen?: Timestamp;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentPosition position = 6;
   */
  position?: StudentPosition;
  /**
   * @generated from protobuf field: map<string, sparx.science.lessons.v1.StudentLessonActivityState> activity_states = 7;
   */
  activityStates: {
    [key: string]: StudentLessonActivityState;
  };
  /**
   * @deprecated
   * @generated from protobuf field: bool hands_up = 8 [deprecated = true];
   */
  handsUp: boolean;
  /**
   * Timestamp that the student raised their hand. If this is
   * nil then the hand is not up.
   *
   * @generated from protobuf field: google.protobuf.Timestamp hand_up_time = 11;
   */
  handUpTime?: Timestamp;
  /**
   * Timestamp that the student joined the lesson.
   *
   * @generated from protobuf field: google.protobuf.Timestamp join_time = 9;
   */
  joinTime?: Timestamp;
  /**
   * Timestamp when the student last progressed on an activity.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_progressed = 10;
   */
  lastProgressed?: Timestamp;
  /**
   * The pathname of the last uploaded whiteboard image for the student. Will contain
   * the whiteboard_activity_id.
   *
   * @generated from protobuf field: string last_uploaded_whiteboard_filename = 12;
   */
  lastUploadedWhiteboardFilename: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentPosition
 */
export interface StudentPosition {
  /**
   * Current activity this student is on.
   *
   * @generated from protobuf field: string lesson_activity_id = 1;
   */
  lessonActivityId: string;
  /**
   * Position in a task in the format `packages/{package_id}/tasks/{task_id}/items/{item}`.
   *
   * @generated from protobuf field: string task_item_name = 2;
   */
  taskItemName: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentLessonActivityState
 */
export interface StudentLessonActivityState {
  /**
   * Completion store describing the percentages of progress on this activity.
   *
   * @generated from protobuf field: sparx.progress.v1.Completion completion = 1;
   */
  completion?: Completion;
  /**
   * Time student spent on this lesson activity.
   *
   * @generated from protobuf field: double time_spent = 2;
   */
  timeSpent: number;
  /**
   * Timestamp that the activity was completed.
   *
   * @generated from protobuf field: google.protobuf.Timestamp completion_timestamp = 3;
   */
  completionTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonActivity
 */
export interface LessonActivity {
  /**
   * @generated from protobuf field: string lesson_activity_id = 1;
   */
  lessonActivityId: string;
  /**
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LessonActivityLockState lock_state = 3;
   */
  lockState: LessonActivityLockState;
  /**
   * Timestamp that the activity was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 4;
   */
  creationTimestamp?: Timestamp;
  /**
   * The content of the lesson activity
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonActivityContent content = 5;
   */
  content?: LessonActivityContent;
  /**
   * Additional arbitrary annotations on a lesson activity.
   *
   * @generated from protobuf field: map<string, string> annotations = 6;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * Whether this activity is ephemeral and shouldn't be available to the student
   * in the lesson activity list.
   *
   * @generated from protobuf field: bool ephemeral = 7;
   */
  ephemeral: boolean;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonActivityContent
 */
export interface LessonActivityContent {
  /**
   * Will be one of the following, rest will be nil.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonActivityContent.Assignment assignment = 1;
   */
  assignment?: LessonActivityContent_Assignment;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonActivityContent.Assignment
 */
export interface LessonActivityContent_Assignment {
  /**
   * @generated from protobuf field: string assignment_id = 1;
   */
  assignmentId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.TopicBlueprint
 */
export interface TopicBlueprint {
  /**
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * @generated from protobuf field: string level = 2;
   */
  level: string;
  /**
   * @generated from protobuf field: string variant = 3;
   */
  variant: string;
  /**
   * @generated from protobuf field: bool include_higher = 4;
   */
  includeHigher: boolean;
  /**
   * @generated from protobuf field: bool include_separate = 5;
   */
  includeSeparate: boolean;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.InsightsBlueprint
 */
export interface InsightsBlueprint {
  /**
   * @generated from protobuf field: string assignment_name = 1;
   */
  assignmentName: string;
  /**
   * Subject to select the skills for. Options are: combined, biology, chemistry, physics.
   * Not requesting a subject will return the combined skills.
   *
   * @generated from protobuf field: string subject = 2;
   */
  subject: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LessonActivityBlueprint
 */
export interface LessonActivityBlueprint {
  /**
   * @generated from protobuf oneof: content
   */
  content:
    | {
        oneofKind: 'assignment';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.Assignment.Spec assignment = 1;
         */
        assignment: Assignment_Spec;
      }
    | {
        oneofKind: 'topic';
        /**
         * @generated from protobuf field: sparx.science.lessons.v1.TopicBlueprint topic = 2;
         */
        topic: TopicBlueprint;
      }
    | {
        oneofKind: 'insights';
        /**
         * @generated from protobuf field: sparx.science.lessons.v1.InsightsBlueprint insights = 3;
         */
        insights: InsightsBlueprint;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.ListLessonPageToken
 */
export interface ListLessonPageToken {
  /**
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * @generated from protobuf field: int32 page = 2;
   */
  page: number;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.ListLessonsRequest
 */
export interface ListLessonsRequest {
  /**
   * School to list the lessons for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Optional list of group names to filter the lessons by.
   *
   * @generated from protobuf field: repeated string group_names = 2;
   */
  groupNames: string[];
  /**
   * Optional filter to only show team teaching lessons.
   *
   * @generated from protobuf field: bool team_teaching_lessons = 3;
   */
  teamTeachingLessons: boolean;
  /**
   * Optional page token to filter the lessons by.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.ListLessonPageToken page_token = 4;
   */
  pageToken?: ListLessonPageToken;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.ListLessonsResponse
 */
export interface ListLessonsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonState lessons = 1;
   */
  lessons: LessonState[];
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.ListLessonPageToken next_page_token = 2;
   */
  nextPageToken?: ListLessonPageToken;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.ListArchivedLessonsRequest
 */
export interface ListArchivedLessonsRequest {
  /**
   * School to list the lessons for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Optional list of group names to filter the lessons by.
   *
   * @generated from protobuf field: repeated string group_names = 2;
   */
  groupNames: string[];
  /**
   * Optional filter to only show team teaching lessons.
   *
   * @generated from protobuf field: bool team_teaching_lessons = 3;
   */
  teamTeachingLessons: boolean;
  /**
   * Optional page token to filter the lessons by.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.ListLessonPageToken page_token = 4;
   */
  pageToken?: ListLessonPageToken;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.ListArchivedLessonsResponse
 */
export interface ListArchivedLessonsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonState lessons = 1;
   */
  lessons: LessonState[];
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.ListLessonPageToken next_page_token = 2;
   */
  nextPageToken?: ListLessonPageToken;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.CreateLessonRequest
 */
export interface CreateLessonRequest {
  /**
   * School to create the lesson in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Display name of the lesson.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * The groups that this lesson is assigned to.
   *
   * @generated from protobuf field: repeated string group_names = 3;
   */
  groupNames: string[];
  /**
   * Optional list of features that are available in this lesson.
   *
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonFeature features = 4;
   */
  features: LessonFeature[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.CreateLessonResponse
 */
export interface CreateLessonResponse {
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LessonState lesson = 1;
   */
  lesson?: LessonState;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.EndLessonRequest
 */
export interface EndLessonRequest {
  /**
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.EndLessonResponse
 */
export interface EndLessonResponse {}
/**
 * @generated from protobuf message sparx.science.lessons.v1.JoinLessonRequest
 */
export interface JoinLessonRequest {
  /**
   * @generated from protobuf field: string lesson_code = 1;
   */
  lessonCode: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.JoinLessonResponse
 */
export interface JoinLessonResponse {
  /**
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LeaveLessonRequest
 */
export interface LeaveLessonRequest {
  /**
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.LeaveLessonResponse
 */
export interface LeaveLessonResponse {}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GenerateLessonActivityPackageRequest
 */
export interface GenerateLessonActivityPackageRequest {
  /**
   * Lesson to generate the activity package for.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * Lesson activity to generate the package for.
   *
   * @generated from protobuf field: string lesson_activity_id = 2;
   */
  lessonActivityId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GenerateLessonActivityPackageResponse
 */
export interface GenerateLessonActivityPackageResponse {
  /**
   * Generated package for the student in this lesson activity.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Package package = 1;
   */
  package?: Package;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateLessonStateRequest
 */
export interface UpdateLessonStateRequest {
  /**
   * New state of the lesson. Not all fields are supported and a field
   * mask must be provided to indicate which fields are to be updated.
   * The lesson_name field is required on all updates.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonState lesson_state = 1;
   */
  lessonState?: LessonState;
  /**
   * The list of fields to be updated.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
  /**
   * List of student names who should put their hands down.
   *
   * @generated from protobuf field: repeated string clear_hands_up_student_names = 3;
   */
  clearHandsUpStudentNames: string[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentStateUpdate
 */
export interface StudentStateUpdate {
  /**
   * @generated from protobuf field: repeated sparx.science.lessons.v1.StudentState states = 1;
   */
  states: StudentState[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateLessonStateResponse
 */
export interface UpdateLessonStateResponse {}
/**
 * @generated from protobuf message sparx.science.lessons.v1.WatchLessonStateRequest
 */
export interface WatchLessonStateRequest {
  /**
   * Lesson to watch.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * Session id is required for students to watch the lesson state.
   * The student must have joined the lesson previously else this will return an error.
   *
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.WatchLessonStateResponse
 */
export interface WatchLessonStateResponse {
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LessonState lesson_state = 1;
   */
  lessonState?: LessonState;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentStateUpdate student_states = 2;
   */
  studentStates?: StudentStateUpdate;
  /**
   * @generated from protobuf field: repeated sparx.science.lessons.v1.LessonStudentNotification notifications = 3;
   */
  notifications: LessonStudentNotification[];
  /**
   * Empty ping to keep the stream alive.
   *
   * @generated from protobuf field: string ping = 20;
   */
  ping: string;
  /**
   * The current time on the server.
   *
   * @generated from protobuf field: google.protobuf.Timestamp server_time = 21;
   */
  serverTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetArchivedLessonRequest
 */
export interface GetArchivedLessonRequest {
  /**
   * The name of the lesson.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetArchivedLessonResponse
 */
export interface GetArchivedLessonResponse {
  /**
   * The state of the lesson.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonState lesson_state = 1;
   */
  lessonState?: LessonState;
  /**
   * The student states in the lesson.
   *
   * @generated from protobuf field: repeated sparx.science.lessons.v1.StudentState student_states = 2;
   */
  studentStates: StudentState[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.CreateLessonActivityRequest
 */
export interface CreateLessonActivityRequest {
  /**
   * Lesson to add the activity to.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * Display name of the activity.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * Blueprint of the activity.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonActivityBlueprint blueprint = 3;
   */
  blueprint?: LessonActivityBlueprint;
  /**
   * Set the activity immediately after creation.
   *
   * @generated from protobuf field: bool set_immediately = 5;
   */
  setImmediately: boolean;
  /**
   * Set the activity as ephemeral.
   *
   * @generated from protobuf field: bool ephemeral = 6;
   */
  ephemeral: boolean;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.CreateLessonActivityResponse
 */
export interface CreateLessonActivityResponse {
  /**
   * ID of the generated lesson activity.
   *
   * @generated from protobuf field: string lesson_activity_id = 1;
   */
  lessonActivityId: string;
  /**
   * The content of the lesson activity.
   *
   * @generated from protobuf field: sparx.science.lessons.v1.LessonActivityContent content = 2;
   */
  content?: LessonActivityContent;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateStudentLessonStateRequest
 */
export interface UpdateStudentLessonStateRequest {
  /**
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentActiveState active_state = 2;
   */
  activeState: StudentActiveState;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentPosition position = 3;
   */
  position?: StudentPosition;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp hand_up_time = 5;
   */
  handUpTime?: Timestamp;
  /**
   * @generated from protobuf field: string last_uploaded_whiteboard_filename = 6;
   */
  lastUploadedWhiteboardFilename: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateStudentLessonStateResponse
 */
export interface UpdateStudentLessonStateResponse {}
/**
 * @generated from protobuf message sparx.science.lessons.v1.AcknowledgeLessonNotificationRequest
 */
export interface AcknowledgeLessonNotificationRequest {
  /**
   * Lesson the notification was issued in.
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
  /**
   * Notification ids to acknowledge.
   *
   * @generated from protobuf field: repeated string notification_ids = 2;
   */
  notificationIds: string[];
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.AcknowledgeLessonNotificationResponse
 */
export interface AcknowledgeLessonNotificationResponse {}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.PauseState
 */
export enum PauseState {
  /**
   * @generated from protobuf enum value: PAUSE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: PAUSE_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,
  /**
   * @generated from protobuf enum value: PAUSE_STATE_PAUSED = 2;
   */
  PAUSED = 2,
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.LessonFeature
 */
export enum LessonFeature {
  /**
   * @generated from protobuf enum value: LESSON_FEATURE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * TODO: Don't add more features unless you change how the team teaching filters
   * TODO: on the list lesson endpoints work, as they assume that non team teaching
   * TODO: lessons have no features.
   *
   * @generated from protobuf enum value: LESSON_FEATURE_TEAM_TEACHING = 1;
   */
  TEAM_TEACHING = 1,
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.JoinState
 */
export enum JoinState {
  /**
   * @generated from protobuf enum value: STUDENT_STATE_UNSPECIFIED = 0;
   */
  STUDENT_STATE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_STATE_JOINED = 1;
   */
  STUDENT_STATE_JOINED = 1,
  /**
   * @generated from protobuf enum value: STUDENT_STATE_LEFT = 2;
   */
  STUDENT_STATE_LEFT = 2,
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.StudentActiveState
 */
export enum StudentActiveState {
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_PASSIVE = 2;
   */
  PASSIVE = 2,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_HIDDEN = 3;
   */
  HIDDEN = 3,
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.LessonActivityLockState
 */
export enum LessonActivityLockState {
  /**
   * @generated from protobuf enum value: LOCK_STATE_UNSPECIFIED = 0;
   */
  LOCK_STATE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: LOCK_STATE_UNLOCKED = 1;
   */
  LOCK_STATE_UNLOCKED = 1,
  /**
   * @generated from protobuf enum value: LOCK_STATE_LOCKED = 2;
   */
  LOCK_STATE_LOCKED = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class LessonState$Type extends MessageType<LessonState> {
  constructor() {
    super('sparx.science.lessons.v1.LessonState', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'lesson_code',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'creator_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'pause_state',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.PauseState',
          PauseState,
          'PAUSE_STATE_',
        ],
      },
      {
        no: 12,
        name: 'student_pause_states',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: {
          kind: 'enum',
          T: () => [
            'sparx.science.lessons.v1.PauseState',
            PauseState,
            'PAUSE_STATE_',
          ],
        },
      },
      {
        no: 7,
        name: 'activities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LessonActivity,
      },
      { no: 8, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
      { no: 10, name: 'ended_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 15,
        name: 'last_progress_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 9,
        name: 'set_lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'student_set_lesson_activity_ids',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 14,
        name: 'set_ephemeral_lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'features',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.science.lessons.v1.LessonFeature',
          LessonFeature,
          'LESSON_FEATURE_',
        ],
      },
      { no: 13, name: 'timer', kind: 'message', T: () => LessonTimer },
      {
        no: 50,
        name: 'conference_state',
        kind: 'message',
        T: () => LessonConferenceState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonState
 */
export const LessonState = new LessonState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonTimer$Type extends MessageType<LessonTimer> {
  constructor() {
    super('sparx.science.lessons.v1.LessonTimer', [
      {
        no: 1,
        name: 'duration_seconds',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'start_time', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'end_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonTimer
 */
export const LessonTimer = new LessonTimer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentState$Type extends MessageType<StudentState> {
  constructor() {
    super('sparx.science.lessons.v1.StudentState', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'join_state',
        kind: 'enum',
        T: () => ['sparx.science.lessons.v1.JoinState', JoinState],
      },
      {
        no: 4,
        name: 'active_state',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.StudentActiveState',
          StudentActiveState,
          'STUDENT_ACTIVE_STATE_',
        ],
      },
      { no: 5, name: 'last_seen', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'position', kind: 'message', T: () => StudentPosition },
      {
        no: 7,
        name: 'activity_states',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => StudentLessonActivityState },
      },
      { no: 8, name: 'hands_up', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'hand_up_time', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'join_time', kind: 'message', T: () => Timestamp },
      { no: 10, name: 'last_progressed', kind: 'message', T: () => Timestamp },
      {
        no: 12,
        name: 'last_uploaded_whiteboard_filename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentState
 */
export const StudentState = new StudentState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentPosition$Type extends MessageType<StudentPosition> {
  constructor() {
    super('sparx.science.lessons.v1.StudentPosition', [
      {
        no: 1,
        name: 'lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentPosition
 */
export const StudentPosition = new StudentPosition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentLessonActivityState$Type extends MessageType<StudentLessonActivityState> {
  constructor() {
    super('sparx.science.lessons.v1.StudentLessonActivityState', [
      { no: 1, name: 'completion', kind: 'message', T: () => Completion },
      { no: 2, name: 'time_spent', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      {
        no: 3,
        name: 'completion_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentLessonActivityState
 */
export const StudentLessonActivityState = new StudentLessonActivityState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonActivity$Type extends MessageType<LessonActivity> {
  constructor() {
    super('sparx.science.lessons.v1.LessonActivity', [
      {
        no: 1,
        name: 'lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'lock_state',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.LessonActivityLockState',
          LessonActivityLockState,
        ],
      },
      {
        no: 4,
        name: 'creation_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 5,
        name: 'content',
        kind: 'message',
        T: () => LessonActivityContent,
      },
      {
        no: 6,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 7, name: 'ephemeral', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonActivity
 */
export const LessonActivity = new LessonActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonActivityContent$Type extends MessageType<LessonActivityContent> {
  constructor() {
    super('sparx.science.lessons.v1.LessonActivityContent', [
      {
        no: 1,
        name: 'assignment',
        kind: 'message',
        T: () => LessonActivityContent_Assignment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonActivityContent
 */
export const LessonActivityContent = new LessonActivityContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonActivityContent_Assignment$Type extends MessageType<LessonActivityContent_Assignment> {
  constructor() {
    super('sparx.science.lessons.v1.LessonActivityContent.Assignment', [
      {
        no: 1,
        name: 'assignment_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonActivityContent.Assignment
 */
export const LessonActivityContent_Assignment =
  new LessonActivityContent_Assignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicBlueprint$Type extends MessageType<TopicBlueprint> {
  constructor() {
    super('sparx.science.lessons.v1.TopicBlueprint', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'level', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'variant', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'include_higher',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'include_separate',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.TopicBlueprint
 */
export const TopicBlueprint = new TopicBlueprint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsightsBlueprint$Type extends MessageType<InsightsBlueprint> {
  constructor() {
    super('sparx.science.lessons.v1.InsightsBlueprint', [
      {
        no: 1,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.InsightsBlueprint
 */
export const InsightsBlueprint = new InsightsBlueprint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonActivityBlueprint$Type extends MessageType<LessonActivityBlueprint> {
  constructor() {
    super('sparx.science.lessons.v1.LessonActivityBlueprint', [
      {
        no: 1,
        name: 'assignment',
        kind: 'message',
        oneof: 'content',
        T: () => Assignment_Spec,
      },
      {
        no: 2,
        name: 'topic',
        kind: 'message',
        oneof: 'content',
        T: () => TopicBlueprint,
      },
      {
        no: 3,
        name: 'insights',
        kind: 'message',
        oneof: 'content',
        T: () => InsightsBlueprint,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LessonActivityBlueprint
 */
export const LessonActivityBlueprint = new LessonActivityBlueprint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLessonPageToken$Type extends MessageType<ListLessonPageToken> {
  constructor() {
    super('sparx.science.lessons.v1.ListLessonPageToken', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.ListLessonPageToken
 */
export const ListLessonPageToken = new ListLessonPageToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLessonsRequest$Type extends MessageType<ListLessonsRequest> {
  constructor() {
    super('sparx.science.lessons.v1.ListLessonsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'team_teaching_lessons',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'page_token',
        kind: 'message',
        T: () => ListLessonPageToken,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.ListLessonsRequest
 */
export const ListLessonsRequest = new ListLessonsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLessonsResponse$Type extends MessageType<ListLessonsResponse> {
  constructor() {
    super('sparx.science.lessons.v1.ListLessonsResponse', [
      {
        no: 1,
        name: 'lessons',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LessonState,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'message',
        T: () => ListLessonPageToken,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.ListLessonsResponse
 */
export const ListLessonsResponse = new ListLessonsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListArchivedLessonsRequest$Type extends MessageType<ListArchivedLessonsRequest> {
  constructor() {
    super('sparx.science.lessons.v1.ListArchivedLessonsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'team_teaching_lessons',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'page_token',
        kind: 'message',
        T: () => ListLessonPageToken,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.ListArchivedLessonsRequest
 */
export const ListArchivedLessonsRequest = new ListArchivedLessonsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListArchivedLessonsResponse$Type extends MessageType<ListArchivedLessonsResponse> {
  constructor() {
    super('sparx.science.lessons.v1.ListArchivedLessonsResponse', [
      {
        no: 1,
        name: 'lessons',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LessonState,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'message',
        T: () => ListLessonPageToken,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.ListArchivedLessonsResponse
 */
export const ListArchivedLessonsResponse =
  new ListArchivedLessonsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLessonRequest$Type extends MessageType<CreateLessonRequest> {
  constructor() {
    super('sparx.science.lessons.v1.CreateLessonRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'features',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.science.lessons.v1.LessonFeature',
          LessonFeature,
          'LESSON_FEATURE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.CreateLessonRequest
 */
export const CreateLessonRequest = new CreateLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLessonResponse$Type extends MessageType<CreateLessonResponse> {
  constructor() {
    super('sparx.science.lessons.v1.CreateLessonResponse', [
      { no: 1, name: 'lesson', kind: 'message', T: () => LessonState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.CreateLessonResponse
 */
export const CreateLessonResponse = new CreateLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndLessonRequest$Type extends MessageType<EndLessonRequest> {
  constructor() {
    super('sparx.science.lessons.v1.EndLessonRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.EndLessonRequest
 */
export const EndLessonRequest = new EndLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndLessonResponse$Type extends MessageType<EndLessonResponse> {
  constructor() {
    super('sparx.science.lessons.v1.EndLessonResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.EndLessonResponse
 */
export const EndLessonResponse = new EndLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinLessonRequest$Type extends MessageType<JoinLessonRequest> {
  constructor() {
    super('sparx.science.lessons.v1.JoinLessonRequest', [
      {
        no: 1,
        name: 'lesson_code',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.JoinLessonRequest
 */
export const JoinLessonRequest = new JoinLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinLessonResponse$Type extends MessageType<JoinLessonResponse> {
  constructor() {
    super('sparx.science.lessons.v1.JoinLessonResponse', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.JoinLessonResponse
 */
export const JoinLessonResponse = new JoinLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLessonRequest$Type extends MessageType<LeaveLessonRequest> {
  constructor() {
    super('sparx.science.lessons.v1.LeaveLessonRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LeaveLessonRequest
 */
export const LeaveLessonRequest = new LeaveLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLessonResponse$Type extends MessageType<LeaveLessonResponse> {
  constructor() {
    super('sparx.science.lessons.v1.LeaveLessonResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LeaveLessonResponse
 */
export const LeaveLessonResponse = new LeaveLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateLessonActivityPackageRequest$Type extends MessageType<GenerateLessonActivityPackageRequest> {
  constructor() {
    super('sparx.science.lessons.v1.GenerateLessonActivityPackageRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GenerateLessonActivityPackageRequest
 */
export const GenerateLessonActivityPackageRequest =
  new GenerateLessonActivityPackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateLessonActivityPackageResponse$Type extends MessageType<GenerateLessonActivityPackageResponse> {
  constructor() {
    super('sparx.science.lessons.v1.GenerateLessonActivityPackageResponse', [
      { no: 1, name: 'package', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GenerateLessonActivityPackageResponse
 */
export const GenerateLessonActivityPackageResponse =
  new GenerateLessonActivityPackageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLessonStateRequest$Type extends MessageType<UpdateLessonStateRequest> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateLessonStateRequest', [
      { no: 1, name: 'lesson_state', kind: 'message', T: () => LessonState },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'clear_hands_up_student_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateLessonStateRequest
 */
export const UpdateLessonStateRequest = new UpdateLessonStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentStateUpdate$Type extends MessageType<StudentStateUpdate> {
  constructor() {
    super('sparx.science.lessons.v1.StudentStateUpdate', [
      {
        no: 1,
        name: 'states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentStateUpdate
 */
export const StudentStateUpdate = new StudentStateUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLessonStateResponse$Type extends MessageType<UpdateLessonStateResponse> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateLessonStateResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateLessonStateResponse
 */
export const UpdateLessonStateResponse = new UpdateLessonStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchLessonStateRequest$Type extends MessageType<WatchLessonStateRequest> {
  constructor() {
    super('sparx.science.lessons.v1.WatchLessonStateRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.WatchLessonStateRequest
 */
export const WatchLessonStateRequest = new WatchLessonStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchLessonStateResponse$Type extends MessageType<WatchLessonStateResponse> {
  constructor() {
    super('sparx.science.lessons.v1.WatchLessonStateResponse', [
      { no: 1, name: 'lesson_state', kind: 'message', T: () => LessonState },
      {
        no: 2,
        name: 'student_states',
        kind: 'message',
        T: () => StudentStateUpdate,
      },
      {
        no: 3,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LessonStudentNotification,
      },
      { no: 20, name: 'ping', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 21, name: 'server_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.WatchLessonStateResponse
 */
export const WatchLessonStateResponse = new WatchLessonStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetArchivedLessonRequest$Type extends MessageType<GetArchivedLessonRequest> {
  constructor() {
    super('sparx.science.lessons.v1.GetArchivedLessonRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetArchivedLessonRequest
 */
export const GetArchivedLessonRequest = new GetArchivedLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetArchivedLessonResponse$Type extends MessageType<GetArchivedLessonResponse> {
  constructor() {
    super('sparx.science.lessons.v1.GetArchivedLessonResponse', [
      { no: 1, name: 'lesson_state', kind: 'message', T: () => LessonState },
      {
        no: 2,
        name: 'student_states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetArchivedLessonResponse
 */
export const GetArchivedLessonResponse = new GetArchivedLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLessonActivityRequest$Type extends MessageType<CreateLessonActivityRequest> {
  constructor() {
    super('sparx.science.lessons.v1.CreateLessonActivityRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'blueprint',
        kind: 'message',
        T: () => LessonActivityBlueprint,
      },
      {
        no: 5,
        name: 'set_immediately',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 6, name: 'ephemeral', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.CreateLessonActivityRequest
 */
export const CreateLessonActivityRequest =
  new CreateLessonActivityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLessonActivityResponse$Type extends MessageType<CreateLessonActivityResponse> {
  constructor() {
    super('sparx.science.lessons.v1.CreateLessonActivityResponse', [
      {
        no: 1,
        name: 'lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'content',
        kind: 'message',
        T: () => LessonActivityContent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.CreateLessonActivityResponse
 */
export const CreateLessonActivityResponse =
  new CreateLessonActivityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentLessonStateRequest$Type extends MessageType<UpdateStudentLessonStateRequest> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateStudentLessonStateRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'active_state',
        kind: 'enum',
        T: () => [
          'sparx.science.lessons.v1.StudentActiveState',
          StudentActiveState,
          'STUDENT_ACTIVE_STATE_',
        ],
      },
      { no: 3, name: 'position', kind: 'message', T: () => StudentPosition },
      { no: 5, name: 'hand_up_time', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'last_uploaded_whiteboard_filename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateStudentLessonStateRequest
 */
export const UpdateStudentLessonStateRequest =
  new UpdateStudentLessonStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentLessonStateResponse$Type extends MessageType<UpdateStudentLessonStateResponse> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateStudentLessonStateResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateStudentLessonStateResponse
 */
export const UpdateStudentLessonStateResponse =
  new UpdateStudentLessonStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcknowledgeLessonNotificationRequest$Type extends MessageType<AcknowledgeLessonNotificationRequest> {
  constructor() {
    super('sparx.science.lessons.v1.AcknowledgeLessonNotificationRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'notification_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.AcknowledgeLessonNotificationRequest
 */
export const AcknowledgeLessonNotificationRequest =
  new AcknowledgeLessonNotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcknowledgeLessonNotificationResponse$Type extends MessageType<AcknowledgeLessonNotificationResponse> {
  constructor() {
    super('sparx.science.lessons.v1.AcknowledgeLessonNotificationResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.AcknowledgeLessonNotificationResponse
 */
export const AcknowledgeLessonNotificationResponse =
  new AcknowledgeLessonNotificationResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.lessons.v1.Lessons
 */
export const Lessons = new ServiceType('sparx.science.lessons.v1.Lessons', [
  {
    name: 'ListLessons',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'science:lesson',
        domain: '{school_name}',
      },
    },
    I: ListLessonsRequest,
    O: ListLessonsResponse,
  },
  {
    name: 'ListArchivedLessons',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'science:lesson',
        domain: '{school_name}',
      },
    },
    I: ListArchivedLessonsRequest,
    O: ListArchivedLessonsResponse,
  },
  {
    name: 'CreateLesson',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lesson',
        domain: '{school_name}',
      },
    },
    I: CreateLessonRequest,
    O: CreateLessonResponse,
  },
  {
    name: 'EndLesson',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lesson',
        domain: '{lesson_name|parent}',
      },
    },
    I: EndLessonRequest,
    O: EndLessonResponse,
  },
  {
    name: 'JoinLesson',
    options: {
      'sparx.api.auth': {
        action: 'create',
        resource: 'science:lessonstudentstate',
        domain: 'sw',
      },
    },
    I: JoinLessonRequest,
    O: JoinLessonResponse,
  },
  {
    name: 'LeaveLesson',
    options: {
      'sparx.api.auth': {
        action: 'delete',
        resource: 'science:lessonstudentstate',
        domain: 'sw',
      },
    },
    I: LeaveLessonRequest,
    O: LeaveLessonResponse,
  },
  {
    name: 'WatchLessonState',
    serverStreaming: true,
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'science:lesson',
        domain: 'sw',
      },
    },
    I: WatchLessonStateRequest,
    O: WatchLessonStateResponse,
  },
  {
    name: 'GetArchivedLesson',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{lesson_name|parent}',
      },
    },
    I: GetArchivedLessonRequest,
    O: GetArchivedLessonResponse,
  },
  {
    name: 'GenerateLessonActivityPackage',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lessonstudentstate',
        domain: 'sw',
      },
    },
    I: GenerateLessonActivityPackageRequest,
    O: GenerateLessonActivityPackageResponse,
  },
  {
    name: 'UpdateLessonState',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lesson',
        domain: '{lesson_state.lesson_name|parent}',
      },
    },
    I: UpdateLessonStateRequest,
    O: UpdateLessonStateResponse,
  },
  {
    name: 'CreateLessonActivity',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lesson',
        domain: '{lesson_name|parent}',
      },
    },
    I: CreateLessonActivityRequest,
    O: CreateLessonActivityResponse,
  },
  {
    name: 'UpdateStudentLessonState',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lessonstudentstate',
        domain: 'sw',
      },
    },
    I: UpdateStudentLessonStateRequest,
    O: UpdateStudentLessonStateResponse,
  },
  {
    name: 'AcknowledgeLessonNotification',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'science:lessonstudentstate',
        domain: 'sw',
      },
    },
    I: AcknowledgeLessonNotificationRequest,
    O: AcknowledgeLessonNotificationResponse,
  },
]);
