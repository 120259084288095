// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teaching/kiosk/v1/kiosk.proto" (package "sparx.teaching.kiosk.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.KioskData
 */
export interface KioskData {
  /**
   * @generated from protobuf field: string kiosk_id = 1;
   */
  kioskId: string;
  /**
   * @generated from protobuf field: sparx.teaching.kiosk.v1.KioskState state = 2;
   */
  state?: KioskState;
  /**
   * @generated from protobuf field: sparx.teaching.kiosk.v1.KioskSettings settings = 3;
   */
  settings?: KioskSettings;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.KioskState
 */
export interface KioskState {
  /**
   * Timestamp that the kiosk was last seen.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_seen = 1;
   */
  lastSeen?: Timestamp;
  /**
   * Map of device type to available devices
   *
   * @generated from protobuf field: map<string, sparx.teaching.kiosk.v1.DeviceList> devices = 2;
   */
  devices: {
    [key: string]: DeviceList;
  };
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.DeviceList
 */
export interface DeviceList {
  /**
   * @generated from protobuf field: repeated sparx.teaching.kiosk.v1.DeviceList.Device devices = 1;
   */
  devices: DeviceList_Device[];
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.DeviceList.Device
 */
export interface DeviceList_Device {
  /**
   * @generated from protobuf field: string device_id = 1;
   */
  deviceId: string;
  /**
   * @generated from protobuf field: string label = 2;
   */
  label: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.KioskSettings
 */
export interface KioskSettings {
  /**
   * The ID of the lesson that this kiosk should be running.
   *
   * @generated from protobuf field: string lesson_id = 1;
   */
  lessonId: string;
  /**
   * Whether to show the preview of the webcam output on the kiosk display.
   *
   * @generated from protobuf field: bool show_preview = 2;
   */
  showPreview: boolean;
  /**
   * The name of the kiosk to help identify.
   *
   * @generated from protobuf field: string kiosk_title = 3;
   */
  kioskTitle: string;
  /**
   * The devices that are selected for the kiosk.
   *
   * @generated from protobuf field: map<string, string> selected_devices = 4;
   */
  selectedDevices: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.CreateNewKioskRequest
 */
export interface CreateNewKioskRequest {
  /**
   * The name of the school that the kiosk is in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Token that the kiosk should use to authenticate itself.
   *
   * @generated from protobuf field: string token = 2;
   */
  token: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.CreateNewKioskResponse
 */
export interface CreateNewKioskResponse {
  /**
   * The ID of the kiosk that was created from the requested token.
   *
   * @generated from protobuf field: string kiosk_id = 1;
   */
  kioskId: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.AuthenticateKioskRequest
 */
export interface AuthenticateKioskRequest {
  /**
   * The token that the kiosk should use to authenticate itself.
   *
   * @generated from protobuf field: string token = 1;
   */
  token: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.AuthenticateKioskResponse
 */
export interface AuthenticateKioskResponse {
  /**
   * The ID of the kiosk that was authenticated.
   *
   * @generated from protobuf field: string kiosk_id = 1;
   */
  kioskId: string;
  /**
   * The ID of the school that the kiosk is in.
   *
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.UpdateKioskStateRequest
 */
export interface UpdateKioskStateRequest {
  /**
   * The new state of the kiosk
   *
   * @generated from protobuf field: sparx.teaching.kiosk.v1.KioskState state = 1;
   */
  state?: KioskState;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.UpdateKioskStateResponse
 */
export interface UpdateKioskStateResponse {}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.WatchKioskRequest
 */
export interface WatchKioskRequest {}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.WatchKioskResponse
 */
export interface WatchKioskResponse {
  /**
   * @generated from protobuf field: string ping = 1;
   */
  ping: string;
  /**
   * The current state and settings of the kiosk
   *
   * @generated from protobuf field: sparx.teaching.kiosk.v1.KioskData kiosk = 2;
   */
  kiosk?: KioskData;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.UpdateKioskSettingsRequest
 */
export interface UpdateKioskSettingsRequest {
  /**
   * Kiosk name in the format: `schools/{school_id}/kiosks/{kiosk_id}`
   *
   * @generated from protobuf field: string kiosk_name = 1;
   */
  kioskName: string;
  /**
   * New settings for the kiosk
   *
   * @generated from protobuf field: sparx.teaching.kiosk.v1.KioskSettings settings = 2;
   */
  settings?: KioskSettings;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.UpdateKioskSettingsResponse
 */
export interface UpdateKioskSettingsResponse {}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.ListKiosksRequest
 */
export interface ListKiosksRequest {
  /**
   * The name of the school that the kiosks are in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.ListKiosksResponse
 */
export interface ListKiosksResponse {
  /**
   * List of kiosks in the school
   *
   * @generated from protobuf field: repeated sparx.teaching.kiosk.v1.KioskData kiosks = 1;
   */
  kiosks: KioskData[];
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.RemoveKioskRequest
 */
export interface RemoveKioskRequest {
  /**
   * Kiosk name in the format: `schools/{school_id}/kiosks/{kiosk_id}`
   *
   * @generated from protobuf field: string kiosk_name = 1;
   */
  kioskName: string;
}
/**
 * @generated from protobuf message sparx.teaching.kiosk.v1.RemoveKioskResponse
 */
export interface RemoveKioskResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class KioskData$Type extends MessageType<KioskData> {
  constructor() {
    super('sparx.teaching.kiosk.v1.KioskData', [
      { no: 1, name: 'kiosk_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'state', kind: 'message', T: () => KioskState },
      { no: 3, name: 'settings', kind: 'message', T: () => KioskSettings },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.KioskData
 */
export const KioskData = new KioskData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskState$Type extends MessageType<KioskState> {
  constructor() {
    super('sparx.teaching.kiosk.v1.KioskState', [
      { no: 1, name: 'last_seen', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'devices',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => DeviceList },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.KioskState
 */
export const KioskState = new KioskState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceList$Type extends MessageType<DeviceList> {
  constructor() {
    super('sparx.teaching.kiosk.v1.DeviceList', [
      {
        no: 1,
        name: 'devices',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DeviceList_Device,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.DeviceList
 */
export const DeviceList = new DeviceList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceList_Device$Type extends MessageType<DeviceList_Device> {
  constructor() {
    super('sparx.teaching.kiosk.v1.DeviceList.Device', [
      { no: 1, name: 'device_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'label', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.DeviceList.Device
 */
export const DeviceList_Device = new DeviceList_Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskSettings$Type extends MessageType<KioskSettings> {
  constructor() {
    super('sparx.teaching.kiosk.v1.KioskSettings', [
      { no: 1, name: 'lesson_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'show_preview', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'kiosk_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'selected_devices',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.KioskSettings
 */
export const KioskSettings = new KioskSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNewKioskRequest$Type extends MessageType<CreateNewKioskRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.CreateNewKioskRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.CreateNewKioskRequest
 */
export const CreateNewKioskRequest = new CreateNewKioskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNewKioskResponse$Type extends MessageType<CreateNewKioskResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.CreateNewKioskResponse', [
      { no: 1, name: 'kiosk_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.CreateNewKioskResponse
 */
export const CreateNewKioskResponse = new CreateNewKioskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateKioskRequest$Type extends MessageType<AuthenticateKioskRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.AuthenticateKioskRequest', [
      { no: 1, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.AuthenticateKioskRequest
 */
export const AuthenticateKioskRequest = new AuthenticateKioskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateKioskResponse$Type extends MessageType<AuthenticateKioskResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.AuthenticateKioskResponse', [
      { no: 1, name: 'kiosk_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.AuthenticateKioskResponse
 */
export const AuthenticateKioskResponse = new AuthenticateKioskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateKioskStateRequest$Type extends MessageType<UpdateKioskStateRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.UpdateKioskStateRequest', [
      { no: 1, name: 'state', kind: 'message', T: () => KioskState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.UpdateKioskStateRequest
 */
export const UpdateKioskStateRequest = new UpdateKioskStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateKioskStateResponse$Type extends MessageType<UpdateKioskStateResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.UpdateKioskStateResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.UpdateKioskStateResponse
 */
export const UpdateKioskStateResponse = new UpdateKioskStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchKioskRequest$Type extends MessageType<WatchKioskRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.WatchKioskRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.WatchKioskRequest
 */
export const WatchKioskRequest = new WatchKioskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchKioskResponse$Type extends MessageType<WatchKioskResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.WatchKioskResponse', [
      { no: 1, name: 'ping', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'kiosk', kind: 'message', T: () => KioskData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.WatchKioskResponse
 */
export const WatchKioskResponse = new WatchKioskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateKioskSettingsRequest$Type extends MessageType<UpdateKioskSettingsRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.UpdateKioskSettingsRequest', [
      { no: 1, name: 'kiosk_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'settings', kind: 'message', T: () => KioskSettings },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.UpdateKioskSettingsRequest
 */
export const UpdateKioskSettingsRequest = new UpdateKioskSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateKioskSettingsResponse$Type extends MessageType<UpdateKioskSettingsResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.UpdateKioskSettingsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.UpdateKioskSettingsResponse
 */
export const UpdateKioskSettingsResponse =
  new UpdateKioskSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListKiosksRequest$Type extends MessageType<ListKiosksRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.ListKiosksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.ListKiosksRequest
 */
export const ListKiosksRequest = new ListKiosksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListKiosksResponse$Type extends MessageType<ListKiosksResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.ListKiosksResponse', [
      {
        no: 1,
        name: 'kiosks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => KioskData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.ListKiosksResponse
 */
export const ListKiosksResponse = new ListKiosksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveKioskRequest$Type extends MessageType<RemoveKioskRequest> {
  constructor() {
    super('sparx.teaching.kiosk.v1.RemoveKioskRequest', [
      { no: 1, name: 'kiosk_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.RemoveKioskRequest
 */
export const RemoveKioskRequest = new RemoveKioskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveKioskResponse$Type extends MessageType<RemoveKioskResponse> {
  constructor() {
    super('sparx.teaching.kiosk.v1.RemoveKioskResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teaching.kiosk.v1.RemoveKioskResponse
 */
export const RemoveKioskResponse = new RemoveKioskResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.teaching.kiosk.v1.Kiosk
 */
export const Kiosk = new ServiceType('sparx.teaching.kiosk.v1.Kiosk', [
  {
    name: 'CreateNewKiosk',
    options: {
      'sparx.api.auth': {
        action: 'create',
        resource: 'teaching:kiosk',
        domain: '{school_name}',
      },
    },
    I: CreateNewKioskRequest,
    O: CreateNewKioskResponse,
  },
  {
    name: 'ListKiosks',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'teaching:kiosk',
        domain: '{school_name}',
      },
    },
    I: ListKiosksRequest,
    O: ListKiosksResponse,
  },
  {
    name: 'AuthenticateKiosk',
    options: {},
    I: AuthenticateKioskRequest,
    O: AuthenticateKioskResponse,
  },
  {
    name: 'UpdateKioskState',
    options: {
      'sparx.api.auth': {
        action: 'update',
        resource: 'teaching:kiosk',
        domain: 'kiosks',
      },
    },
    I: UpdateKioskStateRequest,
    O: UpdateKioskStateResponse,
  },
  {
    name: 'WatchKiosk',
    serverStreaming: true,
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'teaching:kiosk',
        domain: 'kiosks',
      },
    },
    I: WatchKioskRequest,
    O: WatchKioskResponse,
  },
  {
    name: 'UpdateKioskSettings',
    options: {
      'sparx.api.auth': {
        action: 'update',
        resource: 'teaching:kiosk',
        domain: '{kiosk_name|parent}',
      },
    },
    I: UpdateKioskSettingsRequest,
    O: UpdateKioskSettingsResponse,
  },
  {
    name: 'RemoveKiosk',
    options: {
      'sparx.api.auth': {
        action: 'delete',
        resource: 'teaching:kiosk',
        domain: '{kiosk_name|parent}',
      },
    },
    I: RemoveKioskRequest,
    O: RemoveKioskResponse,
  },
]);
