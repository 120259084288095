import { extendTheme } from '@chakra-ui/react';

const shadowColour = `24deg 13% 68%`;

export const theme = extendTheme({
  initialColorMode: 'light',
  fonts: {
    body: "'DM Sans', sans-serif",
    heading: "'DM Sans', sans-serif",
  },
  styles: {
    global: {
      body: {
        letterSpacing: '-0.005em',
      },
      button: {
        letterSpacing: '-0.005em',
      },
    },
  },
  colors: {
    buttonTeal: {
      // Button teal is actually orange
      50: '#FFFAF0',
      100: '#FEEBC8',
      200: '#FBD38D',
      300: '#F6AD55',
      400: '#ED8936',
      500: '#DD6B20',
      600: '#C05621',
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    gray: {
      50: 'rgb(248, 248, 248)',
      100: 'rgb(240, 240, 240)',
      200: 'rgb(225, 225, 224)',
      300: 'rgb(205, 204, 203)',
      400: 'rgb(174, 173, 171)',
      500: 'rgb(145, 143, 141)',
      600: 'rgb(119, 117, 115)',
      700: 'rgb(94, 93, 91)',
      800: 'rgb(74, 73, 72)',
      900: 'rgb(61, 60, 59)',
    },
    buttonBlue: {
      '50': '#A6C3E3',
      '100': '#83ACD8',
      '200': '#6094CD',
      '300': '#3D7DC2',
      '400': '#31649B',
      '500': '#254B74',
      '600': '#19324D',
      '700': '#0C1927',
    },
  },
  shadows: {
    // From https://www.joshwcomeau.com/shadow-palette/
    elevationLow: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 1.3px 2px hsl(${shadowColour} / 0.18),
    0px 2.5px 3.8px hsl(${shadowColour} / 0.37)`,
    elevationMedium: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 3.1px 4.7px hsl(${shadowColour} / 0.15),
    0px 6.3px 9.5px hsl(${shadowColour} / 0.29)`,
    elevationHigh: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 6.8px 10.2px hsl(${shadowColour} / 0.09),
    0.1px 12.1px 18.2px hsl(${shadowColour} / 0.18)
    0.1px 18.2px 27.3px hsl(${shadowColour} / 0.26)
    0.1px 26.7px 40.1px hsl(${shadowColour} / 0.35),
    0.2px 39px 58.5px hsl(${shadowColour} / 0.44),
    0.3px 56.8px 85.2px hsl(${shadowColour} / 0.53)`,
  },
  components: {
    Heading: {
      baseStyle: {
        color: 'blue.900',
      },
    },
  },
});
