import { Button, ButtonGroup, HStack } from '@chakra-ui/react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LessonState, PauseState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useUpdateLessonState } from 'api/lessons';
import React from 'react';
import { isStudentPaused } from 'views/lessons/utils';

export const PauseButton = ({
  lessonState,
  selectedStudents,
  hideUnselectedControl,
}: {
  lessonState: LessonState;
  selectedStudents: Set<string>;
  hideUnselectedControl?: boolean;
}) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { mutate: update, isLoading: isUpdating } = useUpdateLessonState(lessonID);

  const studentsPaused = Object.values(lessonState.studentPauseStates).filter(
    s => s === PauseState.PAUSED,
  ).length;
  const studentsActive = Object.values(lessonState.studentPauseStates).filter(
    s => s === PauseState.ACTIVE,
  ).length;

  const selectedStudentCount = selectedStudents.size;
  if (selectedStudentCount > 0) {
    const allPaused = [...selectedStudents].every(s => isStudentPaused(lessonState, s));
    const allActive = [...selectedStudents].every(s => !isStudentPaused(lessonState, s));

    const getNextPauseStates = (set: boolean, next = PauseState.PAUSED) => {
      const existing = { ...lessonState.studentPauseStates };
      selectedStudents.forEach(studentID => {
        if (set) {
          if (lessonState.pauseState === next) {
            existing[studentID] =
              next === PauseState.PAUSED ? PauseState.ACTIVE : PauseState.PAUSED;
          } else {
            delete existing[studentID];
          }
        } else {
          existing[studentID] = next;
        }
      });
      return existing;
    };

    return (
      <HStack spacing={2}>
        {lessonState.pauseState === PauseState.ACTIVE ? (
          <Button
            colorScheme={allPaused ? 'green' : 'orange'}
            isLoading={isUpdating}
            onClick={() => update({ studentPauseStates: getNextPauseStates(allPaused) })}
            leftIcon={<FontAwesomeIcon fixedWidth={true} icon={allPaused ? faPlay : faPause} />}
          >
            {allPaused ? 'Resume' : 'Pause'} {selectedStudentCount}{' '}
            {selectedStudentCount === 1 ? 'student' : 'students'}
          </Button>
        ) : (
          <Button
            colorScheme={allActive ? 'orange' : 'green'}
            isLoading={isUpdating}
            onClick={() =>
              update({ studentPauseStates: getNextPauseStates(allActive, PauseState.ACTIVE) })
            }
            leftIcon={<FontAwesomeIcon fixedWidth={true} icon={allActive ? faPause : faPlay} />}
          >
            {allActive ? 'Pause' : 'Resume'} {selectedStudentCount}{' '}
            {selectedStudentCount === 1 ? 'student' : 'students'}
          </Button>
        )}
      </HStack>
    );
  }

  const classPaused = lessonState?.pauseState === PauseState.PAUSED;
  return (
    <ButtonGroup isAttached={true}>
      {!hideUnselectedControl && classPaused && studentsActive > 0 && (
        <Button
          colorScheme="orange"
          variant="outline"
          isLoading={isUpdating}
          onClick={() => update({ studentPauseStates: {} })}
          leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlay} />}
        >
          Pause {studentsActive}
        </Button>
      )}
      {!hideUnselectedControl && !classPaused && studentsPaused > 0 && (
        <Button
          colorScheme="green"
          variant="outline"
          isLoading={isUpdating}
          onClick={() => update({ studentPauseStates: {} })}
          leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlay} />}
        >
          Resume {studentsPaused}
        </Button>
      )}
      <ClassPauseButton lessonState={lessonState} />
    </ButtonGroup>
  );
};

const ClassPauseButton = ({ lessonState }: { lessonState: LessonState }) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { mutate: update, isLoading: isUpdating } = useUpdateLessonState(lessonID);
  const classPaused = lessonState?.pauseState === PauseState.PAUSED;

  return (
    <Button
      key="pause"
      colorScheme={classPaused ? 'green' : 'orange'}
      isLoading={isUpdating}
      onClick={() =>
        update({
          studentPauseStates: {}, // Clear these
          pauseState: classPaused ? PauseState.ACTIVE : PauseState.PAUSED,
        })
      }
      width={135}
      leftIcon={<FontAwesomeIcon fixedWidth={true} icon={classPaused ? faPlay : faPause} />}
    >
      {classPaused ? 'Resume' : 'Pause'} all
    </Button>
  );
};
