import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTrackReferenceId } from '@livekit/components-core';
import { AudioTrack, useParticipantTracks } from '@livekit/components-react';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { HandsUpIconWithNumber } from '@sparx/science/views/lessons/StudentActivity';
import { type RemoteTrackPublication, Track } from 'livekit-client';
import React, { useEffect, useState } from 'react';
import { useSortedStudentsAndStates, useUpdateConferenceState } from 'views/streamerview/utils';

export const StudentAudioControl = ({ lessonState }: { lessonState: LessonState }) => {
  const { studentLookup, studentList } = useSortedStudentsAndStates(lessonState, true);
  const { mutate: update, isLoading: isUpdating } = useUpdateConferenceState(lessonState);

  const studentNames = lessonState.conferenceState?.studentAudioState?.studentNames || [];
  const addStudent = (id: string) => {
    const studentName = 'students/' + id;
    update({
      studentAudioState: {
        studentNames: studentNames.filter(s => s !== studentName).concat([studentName]),
      },
    });
  };
  const removeStudent = (name: string) => {
    update({
      studentAudioState: {
        studentNames: studentNames.filter(s => s !== name),
      },
    });
  };

  const addDropdown = (
    <Menu>
      <MenuButton size="xs" as={Button} isLoading={isUpdating}>
        Add student
      </MenuButton>
      <MenuList zIndex={100} maxHeight="80vh" overflowY="auto">
        {studentList.map(s => {
          const student = studentLookup[s.studentId];
          if (studentNames.includes('students/' + s.studentId)) {
            return <></>; // Skip
          }
          return (
            <MenuItem key={s.studentId} onClick={() => addStudent(s.studentId)}>
              {student?.givenName} {student?.familyName}
              <Spacer />
              {s.handUpTime && <HandsUpIconWithNumber index={s.handUpIndex} />}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );

  return (
    <Box width="100%" bg="whiteAlpha.200" borderRadius="md" p={3}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Text mb={2} flex={1} color="white" fontSize="small" fontWeight="bold">
          Student Audio:
        </Text>
        {addDropdown}
      </Box>
      <VStack spacing={2} mt={2}>
        {studentNames.map(s => (
          <StudentAudioTrack
            key={s}
            student={studentLookup[s.split('/')[1]]}
            onRemove={() => removeStudent(s)}
          />
        ))}
      </VStack>
    </Box>
  );
};

const StudentAudioTrack = ({
  student,
  onRemove,
}: {
  student: Student | undefined;
  onRemove: () => void;
}) => {
  const [volume, setVolume] = useState(100);
  const tracks = useParticipantTracks(
    [Track.Source.Microphone],
    'studentmic-' + student?.studentId,
  );

  useEffect(() => {
    for (const track of tracks) {
      (track.publication as RemoteTrackPublication).setSubscribed(true);
    }
  }, [tracks]);

  if (!student) {
    return <></>;
  }

  return (
    <HStack
      spacing={4}
      width="100%"
      alignItems="center"
      pl={3}
      bg="whiteAlpha.50"
      borderRadius="md"
    >
      <Text color="white" flex={1} fontSize="sm">
        {student.givenName} {student.familyName}
      </Text>
      {tracks.length === 0 ? (
        <Box fontSize="xs" color="white" py={0.5} px={2} borderRadius="sm">
          Connecting...
        </Box>
      ) : (
        <Slider
          width="100px"
          aria-label="slider-ex-1"
          defaultValue={volume}
          value={volume}
          max={100}
          min={0}
          onChange={setVolume}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      )}
      <IconButton aria-label="Remove" onClick={onRemove} size="sm">
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>

      {tracks.map(track => (
        <AudioTrack key={getTrackReferenceId(track)} trackRef={track} volume={volume / 100} />
      ))}
    </HStack>
  );
};
