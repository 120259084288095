import { useCallback, useEffect, useState } from 'react';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';
import { debounceCallback } from './debounce';

// useScaleElementsToFit takes a function that returns an array of elements and scales each down to
// fit the specified parent element.
//
// The scale factor for each element to scale is determined by comparing the width of the widest
// element to measure to the width of the specified parent element, taking into account any
// specified padding. The list of elements to measure for each element to scale is found by calling
// the optional getElementsToMeasure function if provided, or is otherwise just the element to scale
//
// A CSS class is applied to any scaled elements to ensure the that once scaled it appears in the
// correct place.
//
// The last scale factor to be determined is returned (this is only really useful if only one
// element to scale is specified)
//
// There is an option to add a resize listener, so the callback gets called
// on resize. If your component wants to do other things during the same resize event,
// (e.g. things which will affect the layout of the page/scaling or if you want
// to add extra dependancies), you may want to disable this and call the returned
// function in a separate eventListener in your component alongside other logic.
export const useScaleElementsToFit = (
  getParentWidth: () => number,
  getElementsToScale: () => Array<HTMLElement | null>,
  getElementsToMeasure?: (elementToScale: HTMLElement) => HTMLElement[],
  getHeightToSet?: () => number,
  constrainWidth = false,
  padding = 0,
  transformOrigin = 'left top',
  addResizeEventListener = true,
): { scale: number; scaleElementsToFit: () => void } => {
  const { recalculateScaleTrigger } = useSparxQuestionContext();
  const [scale, setScale] = useState(1);

  const scaleElementsToFit = useCallback(() => {
    if (getParentWidth() === 0) {
      return;
    }
    const elementsToScale = getElementsToScale();
    for (const elementToScale of elementsToScale) {
      if (!elementToScale) {
        continue;
      }
      const elementsToMeasure =
        getElementsToMeasure && getElementsToMeasure(elementToScale).length > 0
          ? getElementsToMeasure(elementToScale)
          : [elementToScale];

      let newScale = 1;
      elementsToMeasure.forEach((elementToMeasure: HTMLElement) => {
        newScale = Math.min((getParentWidth() - padding) / elementToMeasure.offsetWidth, newScale);
      });
      setScale(newScale);
      elementToScale.style.transform = `scale(${newScale})`;
      elementToScale.style.transformOrigin = transformOrigin;

      // If we are given a getHeightToSet function, set the elementToScale's height using this,
      // to help with vertical positioning
      if (getHeightToSet) {
        elementToScale.style.height = `${getHeightToSet()}px`;
      }

      if (constrainWidth) {
        elementToScale.style.maxWidth = `${getParentWidth() - padding}px`;
      } else {
        elementToScale.style.maxWidth = 'none';
      }
      if (newScale === 1) {
        elementToScale.classList.remove(styles.ScaledContainer);
      } else {
        elementToScale.classList.add(styles.ScaledContainer);
      }
    }
  }, [
    getParentWidth,
    getElementsToScale,
    getElementsToMeasure,
    getHeightToSet,
    constrainWidth,
    padding,
    transformOrigin,
  ]);

  useEffect(() => {
    if (!addResizeEventListener) {
      return;
    }
    scaleElementsToFit();
    const aborter = new AbortController();
    window.addEventListener('resize', debounceCallback(scaleElementsToFit, 200), {
      signal: aborter.signal,
    });
    return () => aborter.abort();
  }, [
    scaleElementsToFit,
    recalculateScaleTrigger,
    getHeightToSet,
    getParentWidth,
    addResizeEventListener,
  ]);

  return { scale, scaleElementsToFit };
};
