import { Button, Center, Text } from '@chakra-ui/react';
import { faMicroscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = ({ back = '/' }: { back?: string }) => (
  <Center py={16} display="flex" flexDirection="column">
    <Text fontSize={80} color="blue.800">
      <FontAwesomeIcon icon={faMicroscope} />
    </Text>
    <Text fontSize="xl" fontWeight="bold" pt={2}>
      Not found
    </Text>
    <Text>Sorry, we couldn't find that page.</Text>
    <Button as={Link} to={back} colorScheme="buttonTeal" mt={8}>
      Go home
    </Button>
  </Center>
);
