import { LessonState, PauseState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';

export const isStudentPaused = (
  lessonState: LessonState | undefined,
  studentID: string | undefined,
) => {
  const studentIsPaused = lessonState?.studentPauseStates[studentID || ''] === PauseState.PAUSED;
  const studentIsActive = lessonState?.studentPauseStates[studentID || ''] === PauseState.ACTIVE;
  const classIsPaused = lessonState?.pauseState === PauseState.PAUSED;
  return studentIsPaused || (classIsPaused && !studentIsActive);
};
