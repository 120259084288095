// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/v2/curriculum.proto" (package "sparx.content.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { CurriculumToTopicLinkType } from './curriculum_enums';
import { TopicLinkType } from './curriculum_enums';
import { TopicType } from './curriculum_enums';
/**
 * A Curriculum is a collection Topics that are relevant to a course
 * of study. Curriculums can map to qualifications (e.g. UK Maths GCSE), or could
 * be more general (e.g. UK Primary Science).
 *
 * @generated from protobuf message sparx.content.v2.Curriculum
 */
export interface Curriculum {
  /**
   * The resource name of the Curriculum.
   * Curriculum names have the form `curriculums/{cu_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;

  /**
   * A user-visible name for this Curriculum.
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * A hidden curriculum is not included in list views, and should not be
   * displayed to users. It typically contains some internals.
   *
   * @generated from protobuf field: bool is_hidden = 6;
   */
  isHidden: boolean;
  /**
   * The `name` of the curriculum that student's would have completed before this
   * one. For example students following GCSE are expected to have seen all content
   * within KS3. This field can be empty.
   *
   * @generated from protobuf field: string previous_curriculum_name = 7;
   */
  previousCurriculumName: string;
  /**
   * The learning path specs used by topics in this curriculum. The learning paths are
   * ordered from easiest to hardest.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningPathSpec learning_path_specs = 8;
   */
  learningPathSpecs: LearningPathSpec[];
  /**
   * Tags are any strings associated with the curriculum.  It's up to the
   * consumer to give them meaning.
   *
   * @generated from protobuf field: repeated string tags = 11;
   */
  tags: string[];
  /**
   * A curriculum can be linked to topics, for example prerequisite intro content topics. These
   * linked topics can be from this curriculum or from other curriculums. These links are ordered as
   * for some link types the order is important.
   *
   * @generated from protobuf field: repeated sparx.content.v2.CurriculumToTopicLink topic_links = 12;
   */
  topicLinks: CurriculumToTopicLink[];
  /**
   * Key of the subject that this curriculum is for.
   *
   * @generated from protobuf field: string subject_key = 13;
   */
  subjectKey: string;
}
/**
 * @generated from protobuf message sparx.content.v2.LearningPathSpec
 */
export interface LearningPathSpec {
  /**
   * The resource name of the Learning Path Spec.
   * Learning Path Specs names have the form `curriculums/{cu_id}/learningpathspecs/{lus_id}`
   *
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * The name of the learning path indicating what level it's aimed at
   *
   * @generated from protobuf field: string display_name = 1;
   */
  displayName: string;
  /**
   * A description of the learning path aimed at teachers.
   * Does not contain LaTeX markup.
   *
   * @generated from protobuf field: string teacher_description = 2;
   */
  teacherDescription: string;
  /**
   * A description of the learning path aimed at students
   * Does not contain LaTeX markup.
   *
   * @generated from protobuf field: string student_description = 3;
   */
  studentDescription: string;
}
/**
 * A Strand is a high level breakdown of a Curriculum intended to assist
 * with finding Topics within it.
 *
 * @generated from protobuf message sparx.content.v2.Strand
 */
export interface Strand {
  /**
   * The resource name of the Strand.
   * Strand names have the form `curriculums/{cu_id}/strands/{st_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;

  /**
   * A user-visible name for the Strand.
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * Order helps order Strands when displayed as a list within a Curriculum.
   *
   * @generated from protobuf field: int32 order = 6;
   */
  order: number;
}
/**
 * A Substrand is a further breakdown of a Strand.
 *
 * @generated from protobuf message sparx.content.v2.Substrand
 */
export interface Substrand {
  /**
   * The resource name of the Substrand.
   * Substrand names have the form `curriculums/{cu_id}/strands/{st_id}/substrands/{sst_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;

  /**
   * A user-visible name for the Substrand.
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * Order helps order Substrands when displayed as a list within a Strand or Curriculum.
   *
   * @generated from protobuf field: int32 order = 6;
   */
  order: number;
  /**
   * The names of the topics in this Substrand. The order of the topics is
   * to assist with navigation, and is not necessarily a progression.
   *
   * @generated from protobuf field: repeated string topic_names = 7;
   */
  topicNames: string[];
}
/**
 * A Topic is a broad learning aim that exists at various difficulty levels.
 * Topics are broken down into different Learning Paths, which each teach the
 * learning aims to the appropriate level.
 *
 * NOTE: In Go package content/summaries, ContentSummariser.fillTopicSummary
 * makes a shallow copy of this message.  If new fields are added to this
 * message, the function needs to be updated.
 *
 * @generated from protobuf message sparx.content.v2.Topic
 */
export interface Topic {
  /**
   * The resource name of the Topic.
   * Topic names have the form `curriculums/{cu_id}/topics/{t_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Whether the Topic has been deleted
   *
   * @generated from protobuf field: bool deleted = 2;
   */
  deleted: boolean; // Fields reserved for future use - likely to be used in some form.
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;
  /**
   * A user-visible name for the Topic.
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * A user-visible description of the content in this Topic.
   *
   * @generated from protobuf field: string description = 6;
   */
  description: string;
  /**
   * User-visible notes describing teaching methods used in this Topic.
   *
   * @generated from protobuf field: string teaching_notes = 7;
   */
  teachingNotes: string;
  /**
   * User-visible image describing teaching methods used in this Topic.
   *
   * @generated from protobuf field: string teaching_image_id = 14;
   */
  teachingImageId: string;
  /**
   * Specification Tags link Topics to external qualification descriptions, and
   * can be used to find Topics that teach certain content.
   *
   * @generated from protobuf field: repeated string specification_tags = 8;
   */
  specificationTags: string[];
  /**
   * Additional links this Topic has with other Topics, which may exist in
   * other curricula. This is in addition to implicit links defined by the
   * ordering of topics within the Strands.
   * This contains links this topic is both the source and target of.
   *
   * @generated from protobuf field: repeated sparx.content.v2.TopicLink topic_links = 9;
   */
  topicLinks: TopicLink[];
  /**
   * Deprecated. The type of the topic. Used to exclude topics from certain
   * processes.
   *
   * @generated from protobuf field: sparx.content.v2.TopicType type = 10;
   */
  type: TopicType;
  /**
   * Tags are any strings associated with the topic.  It's up to the consumer to
   * give them meaning.
   *
   * @generated from protobuf field: repeated string tags = 11;
   */
  tags: string[];
  /**
   * Deprecated. The name of an example objective for this topic.
   *
   * @generated from protobuf field: string example_objective_name = 12;
   */
  exampleObjectiveName: string;
  /**
   * The unique code identifying the topic (empty string means no code).
   *
   * @generated from protobuf field: string code = 13;
   */
  code: string;
}
/**
 * A Learning Path is an ordered collection of Learning Units, designed to break
 * down the Topic for a given level.
 *
 * @generated from protobuf message sparx.content.v2.LearningPath
 */
export interface LearningPath {
  /**
   * The resource name of the Learning Path.
   * Learning Path names have the form `curriculums/{cu_id}/topics/{t_id}/learningpaths/{lp_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The resource name of the Learning Path Spec this Learning Path implements.
   *
   * @generated from protobuf field: string spec_name = 8;
   */
  specName: string;
  /**
   * Whether the Learning Path has been deleted
   *
   * @generated from protobuf field: bool deleted = 2;
   */
  deleted: boolean; // Fields reserved for future use - likely to be used in some form.
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;
  /**
   * The names of the Learning Units in this Learning Path. The order of the
   * learning units is important.
   *
   * @generated from protobuf field: repeated string learning_unit_names = 5;
   */
  learningUnitNames: string[];
  /**
   * The level this Learning Path is aimed at.
   *
   * @generated from protobuf field: string level = 6;
   */
  level: string;
  /**
   * The ID for any teaching video for this learning path.
   * This may later be replaced with a video resource name.
   *
   * @generated from protobuf field: string video_id = 107;
   */
  videoId: string; // Reserved for future video resource name use.
  // string video_name = 7;
}
/**
 * A TopicLink is a link between two topics. These topics do not have to exist
 * in the same curriculum.
 *
 * @generated from protobuf message sparx.content.v2.TopicLink
 */
export interface TopicLink {
  /**
   * The type for this link.
   *
   * @generated from protobuf field: sparx.content.v2.TopicLinkType type = 1;
   */
  type: TopicLinkType;
  /**
   * The source topic of this link.
   *
   * @generated from protobuf field: string source_topic_name = 2;
   */
  sourceTopicName: string;
  /**
   * The target topic of this link.
   *
   * @generated from protobuf field: string target_topic_name = 3;
   */
  targetTopicName: string;
}
/**
 * A CurriculumToTopicLink is a link between a curriculum and a topic. These topics do not have to
 * exist in the same curriculum.
 *
 * @generated from protobuf message sparx.content.v2.CurriculumToTopicLink
 */
export interface CurriculumToTopicLink {
  /**
   * The type for this link.
   *
   * @generated from protobuf field: sparx.content.v2.CurriculumToTopicLinkType type = 1;
   */
  type: CurriculumToTopicLinkType;
  /**
   * The topic linked to.
   *
   * @generated from protobuf field: string topic_name = 2;
   */
  topicName: string;
}
// Begin Service message descriptions.

/**
 * Request message for Curriculums.ListCurriculums.
 *
 * @generated from protobuf message sparx.content.v2.ListCurriculumsRequest
 */
export interface ListCurriculumsRequest {
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListCurriculumsResponse.next_page_token] from
   * a previous `ListCurriculums` request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
  /**
   * Whether to include hidden curriculums in the response.
   *
   * @generated from protobuf field: bool include_hidden = 3;
   */
  includeHidden: boolean;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum. If the field is not set it defaults to 'maths'.
   * Subject "-" matches all subjects.
   *
   * @generated from protobuf field: string subject_name = 5;
   */
  subjectName: string;
}
/**
 * Response message for Curriculums.ListCurriculums.
 *
 * @generated from protobuf message sparx.content.v2.ListCurriculumsResponse
 */
export interface ListCurriculumsResponse {
  /**
   * The list of Curriculums.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Curriculum curriculums = 1;
   */
  curriculums: Curriculum[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListCurriculumsRequest.page_token` in a
   * subsequent call to `ListCurriculums` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Curriculums.GetCurriculum.
 *
 * @generated from protobuf message sparx.content.v2.GetCurriculumRequest
 */
export interface GetCurriculumRequest {
  /**
   * Required. The name of the Curriculum to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for Curriculums.ListStrands.
 *
 * @generated from protobuf message sparx.content.v2.ListStrandsRequest
 */
export interface ListStrandsRequest {
  /**
   * Required. The parent (Curriculum) to list the Strands for. Specified in the
   * format `curriculums/*`. Curriculum "-" matches all curricula. Wildcard usage
   * will cause the result to be paged.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListStrandsResponse.next_page_token] from
   * a previous `ListStrands` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum. If the field is not set it defaults to 'maths'.
   * Subject "-" matches all subjects.
   *
   * @generated from protobuf field: string subject_name = 4;
   */
  subjectName: string;
}
/**
 * Response message for Curriculums.ListStrands.
 *
 * @generated from protobuf message sparx.content.v2.ListStrandsResponse
 */
export interface ListStrandsResponse {
  /**
   * The list of Strands.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Strand strands = 1;
   */
  strands: Strand[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListStrandsRequest.page_token` in a
   * subsequent call to `ListStrands` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Curriculums.GetStrands.
 *
 * @generated from protobuf message sparx.content.v2.GetStrandRequest
 */
export interface GetStrandRequest {
  /**
   * Required. The name of the Strand to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for Curriculums.ListSubstrands.
 *
 * @generated from protobuf message sparx.content.v2.ListSubstrandsRequest
 */
export interface ListSubstrandsRequest {
  /**
   * Required. The parent (Strand) to list the Substrands for. Specified in the
   * format `curriculums/*\/strands/*`. Curriculum "-" matches all curricula. Strand
   * "-" matches all strands. Wildcard usage will cause the result to be paged.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListSubstrandsResponse.next_page_token] from
   * a previous `ListSubstrands` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum. If the field is not set it defaults to 'maths'.
   * Subject "-" matches all subjects.
   *
   * @generated from protobuf field: string subject_name = 4;
   */
  subjectName: string;
}
/**
 * Response message for Curriculums.ListSubstrands.
 *
 * @generated from protobuf message sparx.content.v2.ListSubstrandsResponse
 */
export interface ListSubstrandsResponse {
  /**
   * The list of Substrands.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Substrand substrands = 1;
   */
  substrands: Substrand[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListSubstrandsRequest.page_token` in a
   * subsequent call to `ListSubstrands` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Curriculums.GetSubstrands.
 *
 * @generated from protobuf message sparx.content.v2.GetSubstrandRequest
 */
export interface GetSubstrandRequest {
  /**
   * Required. The name of the Substrand to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for Topics.ListTopics.
 *
 * @generated from protobuf message sparx.content.v2.ListTopicsRequest
 */
export interface ListTopicsRequest {
  /**
   * Required. The parent (Curriculum) to list the Topics for. Specified in the
   * format `curriculums/*`. Curriculum "-" matches all curricula for the given
   * subject. Wildcard usage will cause the result to be paged.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListTopicsResponse.next_page_token] from
   * a previous `ListTopics` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
  /**
   * Whether to include the deleted Topics in the response
   *
   * @generated from protobuf field: bool show_deleted = 4;
   */
  showDeleted: boolean;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum. If the field is not set it defaults to
   * 'subjects/maths'. Subject "-" matches all subjects.
   *
   * @generated from protobuf field: string subject_name = 5;
   */
  subjectName: string;
}
/**
 * Response message for Topics.ListTopics.
 *
 * @generated from protobuf message sparx.content.v2.ListTopicsResponse
 */
export interface ListTopicsResponse {
  /**
   * The list of Topics.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Topic topics = 1;
   */
  topics: Topic[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListTopicsRequest.page_token` in a
   * subsequent call to `ListTopics` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Topics.GetTopic.
 *
 * @generated from protobuf message sparx.content.v2.GetTopicRequest
 */
export interface GetTopicRequest {
  /**
   * Required. The name of the Topic to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message fpr Topics.GetLearningPath.
 *
 * @generated from protobuf message sparx.content.v2.ListLearningPathsRequest
 */
export interface ListLearningPathsRequest {
  /**
   * Required. The parent (Topic) to list the Learning Paths for. Specified in the
   * format `curriculums/*\/topics/*`. Curriculum "-" matches all curricula.
   * Topic "-" matches all topics. Wildcard usage will cause the result to be paged.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListLearningPathsResponse.next_page_token] from
   * a previous `ListLearningPaths` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
  /**
   * Whether to include the deleted Learning Paths in the response
   *
   * @generated from protobuf field: bool show_deleted = 4;
   */
  showDeleted: boolean;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum. If the field is not set it defaults to 'maths'.
   * Subject "-" matches all subjects.
   *
   * @generated from protobuf field: string subject_name = 5;
   */
  subjectName: string;
}
/**
 * Response message for Topics.ListLearningPaths.
 *
 * @generated from protobuf message sparx.content.v2.ListLearningPathsResponse
 */
export interface ListLearningPathsResponse {
  /**
   * The list of Learning Paths.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningPath learning_paths = 1;
   */
  learningPaths: LearningPath[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListLearningPathsRequest.page_token` in a
   * subsequent call to `ListLearningPaths` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Topics.GetLearningPath.
 *
 * @generated from protobuf message sparx.content.v2.GetLearningPathRequest
 */
export interface GetLearningPathRequest {
  /**
   * Required. The name of the Learning Path to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for Topics.BatchGetTopics.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetTopicsRequest
 */
export interface BatchGetTopicsRequest {
  /**
   * Required. The names of the Topics to retrieve. The server may refuse to respond if
   * the list is too long. Topics that do not exist will not cause an error.
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * Response message for Topics.BatchGetTopics.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetTopicsResponse
 */
export interface BatchGetTopicsResponse {
  /**
   * The list of Topics. May be different order and length to request.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Topic topics = 1;
   */
  topics: Topic[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Curriculum$Type extends MessageType<Curriculum> {
  constructor() {
    super('sparx.content.v2.Curriculum', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'is_hidden', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: 'previous_curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'learning_path_specs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningPathSpec,
      },
      {
        no: 11,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'topic_links',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CurriculumToTopicLink,
      },
      {
        no: 13,
        name: 'subject_key',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Curriculum
 */
export const Curriculum = new Curriculum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LearningPathSpec$Type extends MessageType<LearningPathSpec> {
  constructor() {
    super('sparx.content.v2.LearningPathSpec', [
      { no: 4, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 1,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'teacher_description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningPathSpec
 */
export const LearningPathSpec = new LearningPathSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Strand$Type extends MessageType<Strand> {
  constructor() {
    super('sparx.content.v2.Strand', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'order', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Strand
 */
export const Strand = new Strand$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Substrand$Type extends MessageType<Substrand> {
  constructor() {
    super('sparx.content.v2.Substrand', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'order', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'topic_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Substrand
 */
export const Substrand = new Substrand$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Topic$Type extends MessageType<Topic> {
  constructor() {
    super('sparx.content.v2.Topic', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'teaching_notes',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: 'teaching_image_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'specification_tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'topic_links',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TopicLink,
      },
      {
        no: 10,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.content.v2.TopicType', TopicType, 'TOPIC_TYPE_'],
      },
      {
        no: 11,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'example_objective_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 13, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Topic
 */
export const Topic = new Topic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LearningPath$Type extends MessageType<LearningPath> {
  constructor() {
    super('sparx.content.v2.LearningPath', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'spec_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'learning_unit_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'level', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 107, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningPath
 */
export const LearningPath = new LearningPath$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicLink$Type extends MessageType<TopicLink> {
  constructor() {
    super('sparx.content.v2.TopicLink', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.TopicLinkType',
          TopicLinkType,
          'TOPIC_LINK_TYPE_',
        ],
      },
      {
        no: 2,
        name: 'source_topic_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'target_topic_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.TopicLink
 */
export const TopicLink = new TopicLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurriculumToTopicLink$Type extends MessageType<CurriculumToTopicLink> {
  constructor() {
    super('sparx.content.v2.CurriculumToTopicLink', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.CurriculumToTopicLinkType',
          CurriculumToTopicLinkType,
          'CURRICULUM_TO_TOPIC_LINK_TYPE_',
        ],
      },
      { no: 2, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.CurriculumToTopicLink
 */
export const CurriculumToTopicLink = new CurriculumToTopicLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurriculumsRequest$Type extends MessageType<ListCurriculumsRequest> {
  constructor() {
    super('sparx.content.v2.ListCurriculumsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'include_hidden',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListCurriculumsRequest
 */
export const ListCurriculumsRequest = new ListCurriculumsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurriculumsResponse$Type extends MessageType<ListCurriculumsResponse> {
  constructor() {
    super('sparx.content.v2.ListCurriculumsResponse', [
      {
        no: 1,
        name: 'curriculums',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Curriculum,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListCurriculumsResponse
 */
export const ListCurriculumsResponse = new ListCurriculumsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurriculumRequest$Type extends MessageType<GetCurriculumRequest> {
  constructor() {
    super('sparx.content.v2.GetCurriculumRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetCurriculumRequest
 */
export const GetCurriculumRequest = new GetCurriculumRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStrandsRequest$Type extends MessageType<ListStrandsRequest> {
  constructor() {
    super('sparx.content.v2.ListStrandsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListStrandsRequest
 */
export const ListStrandsRequest = new ListStrandsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStrandsResponse$Type extends MessageType<ListStrandsResponse> {
  constructor() {
    super('sparx.content.v2.ListStrandsResponse', [
      {
        no: 1,
        name: 'strands',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Strand,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListStrandsResponse
 */
export const ListStrandsResponse = new ListStrandsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStrandRequest$Type extends MessageType<GetStrandRequest> {
  constructor() {
    super('sparx.content.v2.GetStrandRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetStrandRequest
 */
export const GetStrandRequest = new GetStrandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubstrandsRequest$Type extends MessageType<ListSubstrandsRequest> {
  constructor() {
    super('sparx.content.v2.ListSubstrandsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListSubstrandsRequest
 */
export const ListSubstrandsRequest = new ListSubstrandsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubstrandsResponse$Type extends MessageType<ListSubstrandsResponse> {
  constructor() {
    super('sparx.content.v2.ListSubstrandsResponse', [
      {
        no: 1,
        name: 'substrands',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Substrand,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListSubstrandsResponse
 */
export const ListSubstrandsResponse = new ListSubstrandsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubstrandRequest$Type extends MessageType<GetSubstrandRequest> {
  constructor() {
    super('sparx.content.v2.GetSubstrandRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetSubstrandRequest
 */
export const GetSubstrandRequest = new GetSubstrandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTopicsRequest$Type extends MessageType<ListTopicsRequest> {
  constructor() {
    super('sparx.content.v2.ListTopicsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'show_deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListTopicsRequest
 */
export const ListTopicsRequest = new ListTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTopicsResponse$Type extends MessageType<ListTopicsResponse> {
  constructor() {
    super('sparx.content.v2.ListTopicsResponse', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Topic,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListTopicsResponse
 */
export const ListTopicsResponse = new ListTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTopicRequest$Type extends MessageType<GetTopicRequest> {
  constructor() {
    super('sparx.content.v2.GetTopicRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetTopicRequest
 */
export const GetTopicRequest = new GetTopicRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLearningPathsRequest$Type extends MessageType<ListLearningPathsRequest> {
  constructor() {
    super('sparx.content.v2.ListLearningPathsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'show_deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListLearningPathsRequest
 */
export const ListLearningPathsRequest = new ListLearningPathsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLearningPathsResponse$Type extends MessageType<ListLearningPathsResponse> {
  constructor() {
    super('sparx.content.v2.ListLearningPathsResponse', [
      {
        no: 1,
        name: 'learning_paths',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningPath,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListLearningPathsResponse
 */
export const ListLearningPathsResponse = new ListLearningPathsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLearningPathRequest$Type extends MessageType<GetLearningPathRequest> {
  constructor() {
    super('sparx.content.v2.GetLearningPathRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetLearningPathRequest
 */
export const GetLearningPathRequest = new GetLearningPathRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTopicsRequest$Type extends MessageType<BatchGetTopicsRequest> {
  constructor() {
    super('sparx.content.v2.BatchGetTopicsRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetTopicsRequest
 */
export const BatchGetTopicsRequest = new BatchGetTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTopicsResponse$Type extends MessageType<BatchGetTopicsResponse> {
  constructor() {
    super('sparx.content.v2.BatchGetTopicsResponse', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Topic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetTopicsResponse
 */
export const BatchGetTopicsResponse = new BatchGetTopicsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.content.v2.Curriculums
 */
export const Curriculums = new ServiceType('sparx.content.v2.Curriculums', [
  {
    name: 'ListCurriculums',
    options: { 'google.api.http': { get: '/v2/curriculums' } },
    I: ListCurriculumsRequest,
    O: ListCurriculumsResponse,
  },
  {
    name: 'GetCurriculum',
    options: { 'google.api.http': { get: '/v2/{name=curriculums/*}' } },
    I: GetCurriculumRequest,
    O: Curriculum,
  },
  {
    name: 'ListStrands',
    options: {
      'google.api.http': { get: '/v2/{parent=curriculums/*}/strands' },
    },
    I: ListStrandsRequest,
    O: ListStrandsResponse,
  },
  {
    name: 'GetStrand',
    options: {
      'google.api.http': { get: '/v2/{name=curriculums/*/strands/*}' },
    },
    I: GetStrandRequest,
    O: Strand,
  },
  {
    name: 'ListSubstrands',
    options: {
      'google.api.http': {
        get: '/v2/{parent=curriculums/*/strands/*}/substrands',
      },
    },
    I: ListSubstrandsRequest,
    O: ListSubstrandsResponse,
  },
  {
    name: 'GetSubstrand',
    options: {
      'google.api.http': {
        get: '/v2/{name=curriculums/*/strands/*/substrands/*}',
      },
    },
    I: GetSubstrandRequest,
    O: Substrand,
  },
]);
/**
 * @generated ServiceType for protobuf service sparx.content.v2.Topics
 */
export const Topics = new ServiceType('sparx.content.v2.Topics', [
  {
    name: 'ListTopics',
    options: {
      'google.api.http': { get: '/v2/{parent=curriculums/*}/topics' },
    },
    I: ListTopicsRequest,
    O: ListTopicsResponse,
  },
  {
    name: 'BatchGetTopics',
    options: {
      'google.api.http': { get: '/v2/curriculums/-/topics:batchGet' },
    },
    I: BatchGetTopicsRequest,
    O: BatchGetTopicsResponse,
  },
  {
    name: 'GetTopic',
    options: {
      'google.api.http': { get: '/v2/{name=curriculums/*/topics/*}' },
    },
    I: GetTopicRequest,
    O: Topic,
  },
  {
    name: 'ListLearningPaths',
    options: {
      'google.api.http': {
        get: '/v2/{parent=curriculums/*/topics/*}/learningpaths',
      },
    },
    I: ListLearningPathsRequest,
    O: ListLearningPathsResponse,
  },
  {
    name: 'GetLearningPath',
    options: {
      'google.api.http': {
        get: '/v2/{name=curriculums/*/topics/*/learningpaths/*}',
      },
    },
    I: GetLearningPathRequest,
    O: LearningPath,
  },
]);
