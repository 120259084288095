import { Box, Button, HStack, Spacer, Text } from '@chakra-ui/react';
import { faChevronUp, faDesktop, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTrackReferenceId } from '@livekit/components-core';
import {
  AudioTrack,
  useIsMuted,
  useLocalParticipant,
  useParticipantTracks,
  useRemoteParticipant,
  useTrackToggle,
} from '@livekit/components-react';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { TimerCountdown } from 'components/stream/LessonTimer';
import {
  DefaultInputSelect,
  DisconnectButton,
  LocalTrackByName,
  MultiTrackSelect,
  MutedButton,
  ParticipantConnectionQuality,
  RemoteScreenShareTrack,
  TrackByName,
  useParticipantSpeakingIndicator,
} from 'components/stream/StreamComponents';
import { CombinedLayout, Layout, LayoutBox, layouts } from 'components/stream/StreamLayouts';
import { RemoteParticipant, type RemoteTrackPublication, Track } from 'livekit-client';
import { PropsWithChildren, useEffect, useState } from 'react';

export const ClassView = ({
  lessonState,
  controls,
}: {
  lessonState: LessonState;
  controls?: React.ReactNode;
}) => {
  const selectedStudentId = lessonState.conferenceState?.studentCall?.studentName?.split('/')[1];

  const [showBottom, setShowBottom] = useState(true);
  const setActive = () => setShowBottom(true);
  const setInactive = () => setShowBottom(false);

  const layout = layouts[lessonState.conferenceState?.view || 'default'] || layouts['default'];

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="gray.900"
      display="flex"
      flexDirection="column"
    >
      <TeacherAudio muted={Boolean(selectedStudentId)} />
      <Box flex={1} position="relative">
        <Box
          borderRadius="md"
          position="absolute"
          top={2}
          left={2}
          zIndex={10}
          bg="blackAlpha.400"
          color="white"
          letterSpacing="1px"
          fontSize="5xl"
          borderWidth={2}
          borderColor="blackAlpha.700"
          boxShadow="lg"
          overflow="hidden"
        >
          <Box px={7} py={1}>
            {lessonState.lessonCode}
          </Box>
          {lessonState.timer && <TimerCountdown lessonState={lessonState} flashSeconds={5} />}
        </Box>
        <TeacherWebcamTrack selectedStudentId={selectedStudentId} layout={layout} />
        {layout.streams['visualiser'] && (
          <LayoutBox layout={layout.streams['visualiser']}>
            <TrackByName name="visualiser" identity="teacher" />
          </LayoutBox>
        )}
        {layout.streams['screen'] && (
          <LayoutBox layout={layout.streams['screen']}>
            <RemoteScreenShareTrack identity="teacher" />
          </LayoutBox>
        )}
      </Box>
      {controls || (
        <Box
          bg="gray.800"
          position="relative"
          onMouseOver={setActive}
          onMouseLeave={setInactive}
          transition="all 0.2s ease-in-out"
          height={showBottom ? 16 : 10}
        >
          <Text
            position="absolute"
            zIndex={1}
            inset="-100px 0 0 0"
            paddingTop="110px"
            textAlign="center"
            color="white"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Text>
          <HStack
            spacing={3}
            p={3}
            bg="gray.800"
            transition="all 0.2s ease-in-out"
            zIndex={2}
            position="relative"
            opacity={showBottom ? 1 : 0}
          >
            <DisconnectButton />
            <Spacer />
            <ParticipantConnectionQuality identity="teacher" />
            <ScreenShareSelect />
            <DefaultInputSelect kind="audiooutput" />
            <DefaultInputSelect kind="audioinput" />
            <MultiTrackSelect name="webcam" />
            <MutedButton />
          </HStack>
          <SelfWebcamTrack visible={showBottom} />
        </Box>
      )}
    </Box>
  );
};

const ScreenShareSelect = () => {
  const { buttonProps, enabled } = useTrackToggle({
    source: Track.Source.ScreenShare,
  });

  return (
    <Button
      size="sm"
      {...buttonProps}
      colorScheme={enabled ? 'green' : undefined}
      leftIcon={<FontAwesomeIcon icon={faDesktop} />}
    >
      {enabled ? 'Stop' : 'Start'} sharing
    </Button>
  );
};

export const SelfWebcamTrack = ({ visible }: { visible: boolean }) => {
  const { localParticipant } = useLocalParticipant();
  const { style } = useParticipantSpeakingIndicator(localParticipant);

  return (
    <Box
      position="absolute"
      bottom="100%"
      left={0}
      mb={2}
      ml={2}
      zIndex={10}
      width="33vw"
      borderRadius="md"
      overflow="hidden"
      boxShadow="lg"
      borderWidth={1}
      borderColor="whiteAlpha.300"
      transition="opacity 0.2s ease-in-out"
      opacity={visible ? 1 : 0}
      style={style}
    >
      <Box
        position="absolute"
        bottom={2}
        left={3}
        textShadow="0 0 4px black"
        color="white"
        fontWeight="bold"
      >
        You
      </Box>
      <LocalTrackByName name="webcam" />
    </Box>
  );
};

const TeacherWebcamTrack = ({
  selectedStudentId,
  layout,
}: {
  selectedStudentId?: string;
  layout: CombinedLayout;
}) => {
  // Force mute the parent context if we have a student selected
  const participant = useRemoteParticipant('teacher');

  const children = (
    <>
      {participant && <AudioMutedBox participant={participant} />}
      {selectedStudentId && (
        <HStack
          spacing={2}
          position="absolute"
          top={2}
          right={2}
          py={1}
          px={2}
          borderRadius="md"
          bg="blackAlpha.700"
        >
          <Text color="red.200">
            <FontAwesomeIcon icon={faMicrophoneSlash} />
          </Text>
          <Text color="white" fontSize="small" fontWeight="bold">
            Talking to student...
          </Text>
        </HStack>
      )}
      <TrackByName name="webcam" identity="teacher" />
    </>
  );

  if (participant) {
    return (
      <LayoutBoxWithSpeaking layout={layout.streams['webcam']} participant={participant}>
        {children}
      </LayoutBoxWithSpeaking>
    );
  }
  return <LayoutBox layout={layout.streams['webcam']}>{children}</LayoutBox>;
};

const LayoutBoxWithSpeaking = ({
  layout,
  participant,
  children,
}: PropsWithChildren<{ layout: Layout; participant: RemoteParticipant }>) => {
  const { style } = useParticipantSpeakingIndicator(participant);
  return (
    <LayoutBox layout={layout} style={style}>
      {children}
    </LayoutBox>
  );
};

const AudioMutedBox = ({ participant }: { participant: RemoteParticipant }) => {
  const isAudioMuted = useIsMuted({
    participant,
    source: Track.Source.Microphone,
  });
  return isAudioMuted ? (
    <Box
      position="absolute"
      bottom="0"
      right="0"
      p={2}
      borderRadius="lg"
      bg="blackAlpha.700"
      color="red"
    >
      <FontAwesomeIcon icon={faMicrophoneSlash} />
    </Box>
  ) : null;
};

const TeacherAudio = ({ muted }: { muted?: boolean }) => {
  const tracks = useParticipantTracks(
    [Track.Source.Microphone, Track.Source.ScreenShareAudio],
    'teacher',
  );

  useEffect(() => {
    for (const track of tracks) {
      (track.publication as RemoteTrackPublication).setSubscribed(true);
    }
  }, [tracks]);

  return (
    <>
      {tracks.map(track => (
        <AudioTrack key={getTrackReferenceId(track)} trackRef={track} muted={muted} />
      ))}
    </>
  );
};
