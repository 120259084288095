import { Box, Text } from '@chakra-ui/react';
import { Completion } from '@sparx/api/apis/sparx/progress/v1/completion';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { isAfter } from 'date-fns';
import React, { useMemo } from 'react';

export enum HandinStatus {
  NotStarted,
  NotStartedLate,
  InProgress,
  InProgressLate,
  Complete,
}

interface HandinStatusStyling {
  color: string;
  background: string;
  text?: string;
  statusText: string;
}

const handinStatusStyling: Record<HandinStatus, HandinStatusStyling> = {
  [HandinStatus.NotStarted]: {
    color: 'orange.500',
    background: 'orange.100',
    text: 'New',
    statusText: 'New',
  },
  [HandinStatus.NotStartedLate]: {
    color: 'red.500',
    background: 'red.100',
    statusText: 'Late',
  },
  [HandinStatus.InProgress]: {
    color: 'orange.500',
    background: 'orange.100',
    statusText: 'Started',
  },
  [HandinStatus.InProgressLate]: {
    color: 'red.500',
    background: 'red.100',
    statusText: 'Late',
  },
  [HandinStatus.Complete]: {
    color: 'green.500',
    background: 'green.100',
    statusText: 'Completed',
  },
};

export const getHandinStatusStyling = (value: HandinStatus) => handinStatusStyling[value];

export const getHandinStatusName = (value: HandinStatus) =>
  handinStatusStyling[value].statusText || 'Unknown';

export const useHandInStatus = (completion: Completion | undefined, due?: Timestamp) =>
  useMemo(() => getCompletionSummary(completion, due), [completion, due]);

export type CompletionStatus = 'C' | 'W' | 'U';

export interface CompletionPercentage {
  roundedPercentage: number;
}

export interface CompletionSummary {
  status: HandinStatus;
  percentages: { [K in CompletionStatus]: CompletionPercentage };
  late: boolean;
}

// Use this one
export const getCompletionSummary = (
  completion: Completion | undefined,
  due?: Timestamp,
): CompletionSummary => {
  const completed = isComplete(completion);
  const started = isStarted(completion);
  const late = isLate(due);

  const correct = completionPercentage(completion, 'C') * 100;
  const wrong = completionPercentage(completion, 'W') * 100;
  const unattempted = 100 - correct - wrong;

  const percentages: Record<CompletionStatus, CompletionPercentage> = {
    C: { roundedPercentage: 0 },
    W: { roundedPercentage: 0 },
    U: { roundedPercentage: 0 },
  };

  // Took some ideas from here for accumulation: https://stackoverflow.com/a/13483486
  // Goal is to always add to 100%, but don't show complete/wrong at 0% if it has been
  // started, or 100% if it's not actually complete.
  let cumulative = 0;
  let previous = 0;
  for (const [key, val] of [
    ['C', correct],
    ['W', wrong],
    ['U', unattempted],
  ] as [CompletionStatus, number][]) {
    // Round correct up or down to nearest integer if it's close to 0 or 100
    let value = val;
    if (value > 0 && value < 1) value = Math.ceil(value);
    else if (value > 99 && value < 100) value = Math.floor(value);

    cumulative += value;
    if (cumulative > 100) cumulative = 100;

    const rounded = Math.round(cumulative);
    const diff = rounded - previous;
    previous = rounded;

    percentages[key].roundedPercentage = diff;
  }

  let status = HandinStatus.NotStarted;
  if (started && !completed) {
    status = HandinStatus.InProgress;
    if (late) status = HandinStatus.InProgressLate;
  } else if (completed) {
    status = HandinStatus.Complete;
  } else if (late) {
    status = HandinStatus.NotStartedLate;
  }

  return { percentages, status, late };
};

interface CompletionBadgeProps {
  completion: Completion | undefined;
  due?: Timestamp;
  hideIf?: HandinStatus[];
}

export const CompletionBadge = ({ completion, due, hideIf }: CompletionBadgeProps) => {
  const { percentages, status } = useHandInStatus(completion, due);
  const percentagePretty = percentages['C'].roundedPercentage;
  const styles = handinStatusStyling[status];

  if (hideIf?.includes(status)) return null;

  return (
    <Box
      background={styles.background}
      borderColor={styles.background}
      width={16}
      borderWidth={2}
      textAlign="center"
      py={1}
      borderRadius="md"
    >
      <Text fontSize={['sm', 'md']} fontWeight="bold" color={styles.color}>
        {styles.text ? styles.text : <>{percentagePretty}%</>}
      </Text>
    </Box>
  );
};

export const StatusBadge = ({ completion, due }: CompletionBadgeProps) => {
  const { status } = useHandInStatus(completion, due);
  const styles = handinStatusStyling[status];

  return (
    <Box
      py={1}
      borderRadius="md"
      borderWidth={2}
      borderColor={styles.color}
      w={[24, 28]}
      textAlign="center"
    >
      <Text fontSize={['sm', 'md']} color={styles.color} fontWeight="bold">
        {styles.statusText}
      </Text>
    </Box>
  );
};

const completionPercentage = (completion: Completion | undefined, key = 'C') =>
  completion ? (completion.progress[key] || 0) / completion.size : 0;

export const isLate = (due: Timestamp | undefined) =>
  Boolean(due && isAfter(new Date(), Timestamp.toDate(due)));

export const isStarted = (completion: Completion | undefined) =>
  Object.values(completion?.progress || {}).some(v => v > 0);

export const isComplete = (completion: Completion | undefined) =>
  completion && (completion.progress['C'] || 0) >= completion.size;
