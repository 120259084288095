import { useCallback, useEffect } from 'react';

export const useClickAwayListener = (
  setOpen: (open: boolean) => void,
  open: boolean,
  filter?: (ev: MouseEvent) => boolean,
  eventType: 'mousedown' | 'mouseup' | 'click' = 'click',
) => {
  const listener = useCallback(
    (ev: MouseEvent) => {
      if (!filter || filter(ev)) {
        setOpen(false);
      }
    },
    [setOpen, filter],
  );
  useEffect(() => {
    if (open) {
      document.addEventListener(eventType, listener);
      return () => document.removeEventListener(eventType, listener);
    } else {
      document.removeEventListener(eventType, listener);
    }
  }, [open, listener, eventType]);
};
