// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/meet.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.science.lessons.v1.LiveKitToken
 */
export interface LiveKitToken {
  /**
   * @generated from protobuf field: string server_url = 1;
   */
  serverUrl: string;
  /**
   * @generated from protobuf field: string token = 2;
   */
  token: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetRoomTokenRequest
 */
export interface GetRoomTokenRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string collection = 2;
   */
  collection: string;
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.Role role = 3;
   */
  role: Role;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetRoomTokenResponse
 */
export interface GetRoomTokenResponse {
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.LiveKitToken livekit_token = 1;
   */
  livekitToken?: LiveKitToken;
}
/**
 * @generated from protobuf enum sparx.science.lessons.v1.Role
 */
export enum Role {
  /**
   * @generated from protobuf enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ROLE_TEACHER = 1;
   */
  TEACHER = 1,
  /**
   * @generated from protobuf enum value: ROLE_CLASS = 2;
   */
  CLASS = 2,
  /**
   * @generated from protobuf enum value: ROLE_OBSERVER = 3;
   */
  OBSERVER = 3,
  /**
   * @generated from protobuf enum value: ROLE_STUDENT = 4;
   */
  STUDENT = 4,
  /**
   * @generated from protobuf enum value: ROLE_STUDENT_AUDIO = 5;
   */
  STUDENT_AUDIO = 5,
}
// @generated message type with reflection information, may provide speed optimized methods
class LiveKitToken$Type extends MessageType<LiveKitToken> {
  constructor() {
    super('sparx.science.lessons.v1.LiveKitToken', [
      { no: 1, name: 'server_url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.LiveKitToken
 */
export const LiveKitToken = new LiveKitToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomTokenRequest$Type extends MessageType<GetRoomTokenRequest> {
  constructor() {
    super('sparx.science.lessons.v1.GetRoomTokenRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'collection', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'role',
        kind: 'enum',
        T: () => ['sparx.science.lessons.v1.Role', Role, 'ROLE_'],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetRoomTokenRequest
 */
export const GetRoomTokenRequest = new GetRoomTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomTokenResponse$Type extends MessageType<GetRoomTokenResponse> {
  constructor() {
    super('sparx.science.lessons.v1.GetRoomTokenResponse', [
      { no: 1, name: 'livekit_token', kind: 'message', T: () => LiveKitToken },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetRoomTokenResponse
 */
export const GetRoomTokenResponse = new GetRoomTokenResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.lessons.v1.Meet
 */
export const Meet = new ServiceType('sparx.science.lessons.v1.Meet', [
  {
    name: 'GetRoomToken',
    options: {
      'sparx.api.auth': {
        action: 'createtoken',
        resource: 'teaching:meeting',
        domain: 'sw',
      },
    },
    I: GetRoomTokenRequest,
    O: GetRoomTokenResponse,
  },
]);
