// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/independentlearning.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { IndependentLearning } from './independentlearning';
import type { ListIndependentLearningSummariesResponse } from './independentlearning';
import type { ListIndependentLearningSummariesRequest } from './independentlearning';
import type { CreateIndependentLearningPackageResponse } from './independentlearning';
import type { CreateIndependentLearningPackageRequest } from './independentlearning';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetIndependentLearningStateResponse } from './independentlearning';
import type { GetIndependentLearningStateRequest } from './independentlearning';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.IndependentLearning
 */
export interface IIndependentLearningClient {
  /**
   * @generated from protobuf rpc: GetIndependentLearningState(sparx.science.packages.v1.GetIndependentLearningStateRequest) returns (sparx.science.packages.v1.GetIndependentLearningStateResponse);
   */
  getIndependentLearningState(
    input: GetIndependentLearningStateRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetIndependentLearningStateRequest,
    GetIndependentLearningStateResponse
  >;
  /**
   * @generated from protobuf rpc: CreateIndependentLearningPackage(sparx.science.packages.v1.CreateIndependentLearningPackageRequest) returns (sparx.science.packages.v1.CreateIndependentLearningPackageResponse);
   */
  createIndependentLearningPackage(
    input: CreateIndependentLearningPackageRequest,
    options?: RpcOptions
  ): UnaryCall<
    CreateIndependentLearningPackageRequest,
    CreateIndependentLearningPackageResponse
  >;
  /**
   * @generated from protobuf rpc: ListIndependentLearningSummaries(sparx.science.packages.v1.ListIndependentLearningSummariesRequest) returns (sparx.science.packages.v1.ListIndependentLearningSummariesResponse);
   */
  listIndependentLearningSummaries(
    input: ListIndependentLearningSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListIndependentLearningSummariesRequest,
    ListIndependentLearningSummariesResponse
  >;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.IndependentLearning
 */
export class IndependentLearningClient
  implements IIndependentLearningClient, ServiceInfo
{
  typeName = IndependentLearning.typeName;
  methods = IndependentLearning.methods;
  options = IndependentLearning.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetIndependentLearningState(sparx.science.packages.v1.GetIndependentLearningStateRequest) returns (sparx.science.packages.v1.GetIndependentLearningStateResponse);
   */
  getIndependentLearningState(
    input: GetIndependentLearningStateRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetIndependentLearningStateRequest,
    GetIndependentLearningStateResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetIndependentLearningStateRequest,
      GetIndependentLearningStateResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: CreateIndependentLearningPackage(sparx.science.packages.v1.CreateIndependentLearningPackageRequest) returns (sparx.science.packages.v1.CreateIndependentLearningPackageResponse);
   */
  createIndependentLearningPackage(
    input: CreateIndependentLearningPackageRequest,
    options?: RpcOptions
  ): UnaryCall<
    CreateIndependentLearningPackageRequest,
    CreateIndependentLearningPackageResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateIndependentLearningPackageRequest,
      CreateIndependentLearningPackageResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListIndependentLearningSummaries(sparx.science.packages.v1.ListIndependentLearningSummariesRequest) returns (sparx.science.packages.v1.ListIndependentLearningSummariesResponse);
   */
  listIndependentLearningSummaries(
    input: ListIndependentLearningSummariesRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListIndependentLearningSummariesRequest,
    ListIndependentLearningSummariesResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListIndependentLearningSummariesRequest,
      ListIndependentLearningSummariesResponse
    >('unary', this._transport, method, opt, input);
  }
}
