// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/schools/v1/school.proto" (package "sparx.science.schools.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Assignment } from '../../packages/v1/planner';
import { GroupSettings } from '../settings/v1/settings';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { StudentSettings } from '../settings/v1/settings';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * StudentScienceState contains student state for the science product.
 *
 * @generated from protobuf message sparx.science.schools.v1.StudentScienceState
 */
export interface StudentScienceState {
  /**
   * Name of the student this state apply to. In the format: `students/{student_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Timestamp that the student last logged into the science platform.
   * If the value is nil then the student has never logged in.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login_timestamp = 2;
   */
  lastLoginTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.ListStudentSettingsRequest
 */
export interface ListStudentSettingsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.ListStudentSettingsResponse
 */
export interface ListStudentSettingsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.StudentSettings students = 1;
   */
  students: StudentSettings[];
  /**
   * @generated from protobuf field: repeated sparx.science.schools.v1.StudentScienceState student_states = 3;
   */
  studentStates: StudentScienceState[];
  /**
   * @generated from protobuf field: sparx.science.schools.settings.v1.StudentSettings default_settings = 2;
   */
  defaultSettings?: StudentSettings;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.UpdateStudentSettingsRequest
 */
export interface UpdateStudentSettingsRequest {
  /**
   * School to update the student settings in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The student settings to update. The `name` field is required for
   * all settings.
   *
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.StudentSettings settings = 2;
   */
  settings: StudentSettings[];
  /**
   * Required. The field mask to specify the fields to be updated.
   * If omitted the fields will default to level, course, tier, homework_length
   * for backwards compatibility.
   * The fields internal and name cannot be updated.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 3;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.UpdateStudentSettingsResponse
 */
export interface UpdateStudentSettingsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.StudentSettings updated_settings = 1;
   */
  updatedSettings: StudentSettings[];
}
/**
 * @generated from protobuf message sparx.science.schools.v1.ListGroupSettingsRequest
 */
export interface ListGroupSettingsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.ListGroupSettingsResponse
 */
export interface ListGroupSettingsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.GroupSettings groups = 1;
   */
  groups: GroupSettings[];
  /**
   * @generated from protobuf field: sparx.science.schools.settings.v1.GroupSettings default_settings = 2;
   */
  defaultSettings?: GroupSettings;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.UpdateGroupSettingsRequest
 */
export interface UpdateGroupSettingsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.GroupSettings settings = 2;
   */
  settings: GroupSettings[];
  /**
   * @generated from protobuf field: bool preview = 3;
   */
  preview: boolean;
  /**
   * Use the draft version of the SOL to update the group. Will be ignored if preview
   * is false.
   *
   * @generated from protobuf field: bool use_draft_scheme = 4;
   */
  useDraftScheme: boolean;
  /**
   * Will force the SOL to update even if the id of the sol has not changed.
   *
   * @generated from protobuf field: bool force_update_scheme = 5;
   */
  forceUpdateScheme: boolean;
}
/**
 * @generated from protobuf message sparx.science.schools.v1.UpdateGroupSettingsResponse
 */
export interface UpdateGroupSettingsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.schools.settings.v1.GroupSettings updated_settings = 1;
   */
  updatedSettings: GroupSettings[];
  /**
   * @generated from protobuf field: sparx.science.schools.v1.AssignmentUpdateResult assignment_changes = 2;
   */
  assignmentChanges?: AssignmentUpdateResult;
  /**
   * group names where their next homework to be set will fail to generate due to no infocus or consolidation topics
   * Only output when generating the preview
   *
   * @generated from protobuf field: repeated string next_homework_has_no_topics = 3;
   */
  nextHomeworkHasNoTopics: string[];
}
/**
 * @generated from protobuf message sparx.science.schools.v1.AssignmentUpdateResult
 */
export interface AssignmentUpdateResult {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.Assignment updated = 1;
   */
  updated: Assignment[];
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.Assignment ignored = 2;
   */
  ignored: Assignment[];
  /**
   * @generated from protobuf field: repeated string deleted_names = 3;
   */
  deletedNames: string[];
  /**
   * @generated from protobuf field: repeated string due_date_adjusted_names = 4;
   */
  dueDateAdjustedNames: string[];
  /**
   * @generated from protobuf field: repeated string launch_immediately_names = 5;
   */
  launchImmediatelyNames: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentScienceState$Type extends MessageType<StudentScienceState> {
  constructor() {
    super('sparx.science.schools.v1.StudentScienceState', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'last_login_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.StudentScienceState
 */
export const StudentScienceState = new StudentScienceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentSettingsRequest$Type extends MessageType<ListStudentSettingsRequest> {
  constructor() {
    super('sparx.science.schools.v1.ListStudentSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.ListStudentSettingsRequest
 */
export const ListStudentSettingsRequest = new ListStudentSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentSettingsResponse$Type extends MessageType<ListStudentSettingsResponse> {
  constructor() {
    super('sparx.science.schools.v1.ListStudentSettingsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentSettings,
      },
      {
        no: 3,
        name: 'student_states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentScienceState,
      },
      {
        no: 2,
        name: 'default_settings',
        kind: 'message',
        T: () => StudentSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.ListStudentSettingsResponse
 */
export const ListStudentSettingsResponse =
  new ListStudentSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentSettingsRequest$Type extends MessageType<UpdateStudentSettingsRequest> {
  constructor() {
    super('sparx.science.schools.v1.UpdateStudentSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'settings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentSettings,
      },
      { no: 3, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.UpdateStudentSettingsRequest
 */
export const UpdateStudentSettingsRequest =
  new UpdateStudentSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentSettingsResponse$Type extends MessageType<UpdateStudentSettingsResponse> {
  constructor() {
    super('sparx.science.schools.v1.UpdateStudentSettingsResponse', [
      {
        no: 1,
        name: 'updated_settings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.UpdateStudentSettingsResponse
 */
export const UpdateStudentSettingsResponse =
  new UpdateStudentSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupSettingsRequest$Type extends MessageType<ListGroupSettingsRequest> {
  constructor() {
    super('sparx.science.schools.v1.ListGroupSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.ListGroupSettingsRequest
 */
export const ListGroupSettingsRequest = new ListGroupSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupSettingsResponse$Type extends MessageType<ListGroupSettingsResponse> {
  constructor() {
    super('sparx.science.schools.v1.ListGroupSettingsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSettings,
      },
      {
        no: 2,
        name: 'default_settings',
        kind: 'message',
        T: () => GroupSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.ListGroupSettingsResponse
 */
export const ListGroupSettingsResponse = new ListGroupSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGroupSettingsRequest$Type extends MessageType<UpdateGroupSettingsRequest> {
  constructor() {
    super('sparx.science.schools.v1.UpdateGroupSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'settings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSettings,
      },
      { no: 3, name: 'preview', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'use_draft_scheme',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'force_update_scheme',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.UpdateGroupSettingsRequest
 */
export const UpdateGroupSettingsRequest = new UpdateGroupSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGroupSettingsResponse$Type extends MessageType<UpdateGroupSettingsResponse> {
  constructor() {
    super('sparx.science.schools.v1.UpdateGroupSettingsResponse', [
      {
        no: 1,
        name: 'updated_settings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSettings,
      },
      {
        no: 2,
        name: 'assignment_changes',
        kind: 'message',
        T: () => AssignmentUpdateResult,
      },
      {
        no: 3,
        name: 'next_homework_has_no_topics',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.UpdateGroupSettingsResponse
 */
export const UpdateGroupSettingsResponse =
  new UpdateGroupSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignmentUpdateResult$Type extends MessageType<AssignmentUpdateResult> {
  constructor() {
    super('sparx.science.schools.v1.AssignmentUpdateResult', [
      {
        no: 1,
        name: 'updated',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment,
      },
      {
        no: 2,
        name: 'ignored',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment,
      },
      {
        no: 3,
        name: 'deleted_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'due_date_adjusted_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'launch_immediately_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.v1.AssignmentUpdateResult
 */
export const AssignmentUpdateResult = new AssignmentUpdateResult$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.schools.v1.Schools
 */
export const Schools = new ServiceType('sparx.science.schools.v1.Schools', [
  {
    name: 'ListStudentSettings',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListStudentSettingsRequest,
    O: ListStudentSettingsResponse,
  },
  {
    name: 'UpdateStudentSettings',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: UpdateStudentSettingsRequest,
    O: UpdateStudentSettingsResponse,
  },
  {
    name: 'ListGroupSettings',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:studentgroup',
        domain: '{school_name}',
      },
    },
    I: ListGroupSettingsRequest,
    O: ListGroupSettingsResponse,
  },
  {
    name: 'UpdateGroupSettings',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:studentgroup',
        domain: '{school_name}',
      },
    },
    I: UpdateGroupSettingsRequest,
    O: UpdateGroupSettingsResponse,
  },
]);
