// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/schools/v1/school.proto" (package "sparx.science.schools.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Schools } from './school';
import type { UpdateGroupSettingsResponse } from './school';
import type { UpdateGroupSettingsRequest } from './school';
import type { ListGroupSettingsResponse } from './school';
import type { ListGroupSettingsRequest } from './school';
import type { UpdateStudentSettingsResponse } from './school';
import type { UpdateStudentSettingsRequest } from './school';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListStudentSettingsResponse } from './school';
import type { ListStudentSettingsRequest } from './school';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.schools.v1.Schools
 */
export interface ISchoolsClient {
  /**
   * @generated from protobuf rpc: ListStudentSettings(sparx.science.schools.v1.ListStudentSettingsRequest) returns (sparx.science.schools.v1.ListStudentSettingsResponse);
   */
  listStudentSettings(
    input: ListStudentSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<ListStudentSettingsRequest, ListStudentSettingsResponse>;
  /**
   * @generated from protobuf rpc: UpdateStudentSettings(sparx.science.schools.v1.UpdateStudentSettingsRequest) returns (sparx.science.schools.v1.UpdateStudentSettingsResponse);
   */
  updateStudentSettings(
    input: UpdateStudentSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStudentSettingsRequest, UpdateStudentSettingsResponse>;
  /**
   * @generated from protobuf rpc: ListGroupSettings(sparx.science.schools.v1.ListGroupSettingsRequest) returns (sparx.science.schools.v1.ListGroupSettingsResponse);
   */
  listGroupSettings(
    input: ListGroupSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<ListGroupSettingsRequest, ListGroupSettingsResponse>;
  /**
   * @generated from protobuf rpc: UpdateGroupSettings(sparx.science.schools.v1.UpdateGroupSettingsRequest) returns (sparx.science.schools.v1.UpdateGroupSettingsResponse);
   */
  updateGroupSettings(
    input: UpdateGroupSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateGroupSettingsRequest, UpdateGroupSettingsResponse>;
}
/**
 * @generated from protobuf service sparx.science.schools.v1.Schools
 */
export class SchoolsClient implements ISchoolsClient, ServiceInfo {
  typeName = Schools.typeName;
  methods = Schools.methods;
  options = Schools.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListStudentSettings(sparx.science.schools.v1.ListStudentSettingsRequest) returns (sparx.science.schools.v1.ListStudentSettingsResponse);
   */
  listStudentSettings(
    input: ListStudentSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<ListStudentSettingsRequest, ListStudentSettingsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListStudentSettingsRequest,
      ListStudentSettingsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateStudentSettings(sparx.science.schools.v1.UpdateStudentSettingsRequest) returns (sparx.science.schools.v1.UpdateStudentSettingsResponse);
   */
  updateStudentSettings(
    input: UpdateStudentSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStudentSettingsRequest, UpdateStudentSettingsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentSettingsRequest,
      UpdateStudentSettingsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListGroupSettings(sparx.science.schools.v1.ListGroupSettingsRequest) returns (sparx.science.schools.v1.ListGroupSettingsResponse);
   */
  listGroupSettings(
    input: ListGroupSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<ListGroupSettingsRequest, ListGroupSettingsResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListGroupSettingsRequest, ListGroupSettingsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateGroupSettings(sparx.science.schools.v1.UpdateGroupSettingsRequest) returns (sparx.science.schools.v1.UpdateGroupSettingsResponse);
   */
  updateGroupSettings(
    input: UpdateGroupSettingsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateGroupSettingsRequest, UpdateGroupSettingsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateGroupSettingsRequest,
      UpdateGroupSettingsResponse
    >('unary', this._transport, method, opt, input);
  }
}
