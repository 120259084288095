import { Image, ImageProps } from '@chakra-ui/react';
import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { useCurriculum } from 'api/content';
import { Options } from 'api/school';
import Biology from 'app/images/Biology.svg';
import Chemistry from 'app/images/Chemistry.svg';
import Combined from 'app/images/combined.png';
import Physics from 'app/images/Physics.svg';
import { useMemo } from 'react';

export const PhysicsIcon = (props: ImageProps) => (
  <SubjectIcon src={Physics} alt={'Physics'} {...props} />
);
export const ChemistryIcon = (props: ImageProps) => (
  <SubjectIcon src={Chemistry} alt={'Chemistry'} {...props} />
);
export const BiologyIcon = (props: ImageProps) => (
  <SubjectIcon src={Biology} alt={'Biology'} {...props} />
);
export const CombinedIcon = (props: ImageProps) => (
  <SubjectIcon src={Combined} alt={'Science'} {...props} />
);

export const SubjectIcon = (props: ImageProps) => (
  <Image height="22px" width="22px" display="inline" {...props} />
);

export const subjects = [
  { name: 'All subjects', key: 'combined', enum: Subject.SUBJECT_UNDEFINED },
  {
    name: 'Biology',
    key: 'biology',
    enum: Subject.BIOLOGY,
    icon: <BiologyIcon />,
    iconComponent: BiologyIcon,
  },
  {
    name: 'Chemistry',
    key: 'chemistry',
    enum: Subject.CHEMISTRY,
    icon: <ChemistryIcon />,
    iconComponent: ChemistryIcon,
  },
  {
    name: 'Physics',
    key: 'physics',
    enum: Subject.PHYSICS,
    icon: <PhysicsIcon />,
    iconComponent: PhysicsIcon,
  },
];

export const SubjectToName: Record<Subject, string> = {
  [Subject.SUBJECT_UNDEFINED]: '',
  [Subject.BIOLOGY]: 'Biology',
  [Subject.CHEMISTRY]: 'Chemistry',
  [Subject.PHYSICS]: 'Physics',
};

export const findSubject = (subject: string | Subject) =>
  typeof subject === 'string'
    ? subjects.find(s => s.key === (subject || 'combined'))
    : subjects.find(s => s.enum === subject);

export const useResourceSubject = (resourceName: string, options: Options) => {
  const resource = resourceName.split('/');
  const curriculumID = resource[1];

  const { data: curriculum } = useCurriculum(`curriculums/${curriculumID}`, {
    ...options,
    enabled: !!curriculumID,
  });

  // Get the icon to display by looking at what strand the package was for
  return useMemo(() => {
    let subject = '';
    if (curriculum) {
      if (resource[2] === 'strands') {
        const strandID = resource[3];
        const strandName = `curriculums/${curriculumID}/strands/${strandID}`;
        const strand = curriculum.strandSummaries.find(s => s.strand?.name === strandName);
        subject = strand?.strand?.displayName.toLowerCase() || '';
      } else if (resource[2] === 'topics') {
        const topicID = resource[3];
        const topicName = `curriculums/${curriculumID}/topics/${topicID}`;
        const strand = curriculum.strandSummaries.find(s =>
          s.substrandSummaries.some(ss => ss.substrand?.topicNames.includes(topicName)),
        );
        subject = strand?.strand?.displayName.toLowerCase() || '';
      }
    }
    return findSubject(subject);
  }, [curriculum, curriculumID, resource]);
};

// If all tasks have a subject set then a map of subject to tasks will be returned
// otherwise null is returned
export const TasksBySubject = (tasks: Task[]) => {
  const subjectTaskMap = new Map<Subject, Task[]>();
  for (const task of tasks) {
    if (task.subject === Subject.SUBJECT_UNDEFINED) {
      return null;
    }
    const subjectTasks = subjectTaskMap.get(task.subject) || [];
    subjectTasks.push(task);

    subjectTaskMap.set(task.subject, subjectTasks);
  }

  return subjectTaskMap;
};
