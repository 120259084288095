import { Box, Image, Text } from '@chakra-ui/react';
import logo from 'components/header/sparx_logo.svg';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import React from 'react';

export const KioskContainer = ({
  children,
  footerContent,
  onMouseMove,
}: {
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  onMouseMove?: () => void;
}) => (
  <Box
    height="100%"
    width="100%"
    display="flex"
    position="absolute"
    alignItems="center"
    justifyContent="center"
    bgGradient="linear(to-r, #f27a2c, #e78e4c, #f7b74b)"
    onMouseMove={onMouseMove}
  >
    {[1, 2, 3].map(i => (
      <motion.div
        key={i}
        style={{
          width: '200vw',
          height: '200vw',
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '50%',
          position: 'absolute',
          bottom: '-100vw',
          left: '-100vw',
          transform: 'scale(0)',
          opacity: 1,
        }}
        animate={{ transform: 'scale(1)', opacity: 0 }}
        transition={{ repeat: Infinity, duration: 15, delay: -(i / 3) * 15 }}
      />
    ))}
    <Image src={logo} alt="Sparx Teaching" height="40px" position="absolute" bottom={5} left={7} />
    <Box position="absolute" bottom={5} right={7} textAlign="right" fontSize="sm">
      {footerContent}
      <Text mt={1}>{import.meta.env.VITE_RELEASE_VERSION || 'Unknown'}</Text>
    </Box>
    <Box position="absolute" right={7} top={5} fontSize="5xl" fontWeight="bold" color="white">
      <Clock />
    </Box>
    {children}
  </Box>
);

const Clock = () => {
  const [time, setTime] = React.useState(new Date());
  React.useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  return <Text>{format(time, 'HH:mm')}</Text>;
};
