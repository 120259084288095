import { GetCurrentSessionResponse } from '@sparx/api/apis/sparx/science/sessions/v1/session';
import { useSession } from '@sparx/science/api/sessions';
import { LargeLoading } from '@sparx/science/components/loading/LargeLoading';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LandingView = () => {
  const { data: user } = useSession();
  const navigate = useNavigate();

  // As soon as we have a user we navigate to the relevant page for
  // the user type.
  useEffect(() => {
    if (user) {
      const landingPage = getUserLandingPage(user);
      navigate(landingPage, { replace: true });
    }
  }, [user, navigate]);

  return <LargeLoading />;
};

export const getUserLandingPage = (user: GetCurrentSessionResponse | undefined) =>
  user?.userType === 'staff' || user?.userType === 'sparx' ? '/teacher' : '/join';
