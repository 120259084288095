// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "teacherportal/schoolman/smmsg/schoolman.proto" (package "schoolman", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { AttainmentLevel } from '../../../apis/sparx/personalisation/attainmentlevel/v1/attainmentlevel';
import { BoolValue } from '../../../google/protobuf/wrappers';
import { AssessmentResult } from '../../../dataanalysis/datamsg/schooldata';
import { Timestamp } from '../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message schoolman.HealthCheckRequest
 */
export interface HealthCheckRequest {}
/**
 * @generated from protobuf message schoolman.HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * @generated from protobuf field: string message = 1;
   */
  message: string;
}
/**
 * @generated from protobuf message schoolman.OrgMutation
 */
export interface OrgMutation {
  /**
   * The mutation type (insert, update or delete)
   *
   * @generated from protobuf field: schoolman.OrgMutation.Type type = 1;
   */
  type: OrgMutation_Type;
  /**
   * @generated from protobuf oneof: entity
   */
  entity:
    | {
        oneofKind: 'student';
        /**
         * Student to be mutated. Students can be inserted, updated, upserted or
         * hard deleted. When mutating a student, a userman identity will be created
         * for them (unless their password is empty). If the student already has a
         * userman identity, it will be updated. If an error occurs with the userman
         * interaction, the transaction will only fail if there have been no
         * previous successful userman interactions in the transaction. Permissions
         * required:
         *     INSERT/UPSERT: 'write' to 'tp:student' in 'schools/{SchoolID}'
         *                    'write' to 'tp:studentpersonaldetails' in
         *                    'schools/{SchoolID}'
         *     UPDATE: 'write' to 'tp:student' in 'schools/{SchoolID}'
         *     additionally when updating name, date of birth or gender:
         *             'write' to 'tp:studentpersonaldetails' in
         *             'schools/{SchoolID}'
         *     HARD DELETE: 'delete' to 'tp:student' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.Student student = 2;
         */
        student: Student;
      }
    | {
        oneofKind: 'group';
        /**
         * StudentGroup to be mutated. StudentGroups can be inserted, updated and
         * upserted. Permissions required:
         *     INSERT and UPSERT: 'insert' to 'tp:studentgroup' in
         *     'schools/{SchoolID}' UPDATE and UPSERT: 'update' to 'tp:studentgroup'
         *     in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.StudentGroup group = 3;
         */
        group: StudentGroup;
      }
    | {
        oneofKind: 'staff';
        /**
         * Staff to be mutated. Staff can be inserted, updated and upserted.
         * Permissions required:
         *     'write' to 'tp:staff' in 'schools/{SchoolID}'
         *
         * @deprecated
         * @generated from protobuf field: schoolman.Staff staff = 5 [deprecated = true];
         */
        staff: Staff;
      }
    | {
        oneofKind: 'studentClassMembership';
        /**
         * StudentClassMembership to be mutated. StudentClassMemberships can be
         * upserted and deleted. A student cannot be member of more than one
         * StudentGroup of each type (apart from the MANUAL type). Permissions
         * required:
         *     'write' to 'tp:student' in 'schools/{SchoolID}'
         *     'write' to 'tp:studentgroup' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.StudentClassMembership studentClassMembership = 6;
         */
        studentClassMembership: StudentClassMembership;
      }
    | {
        oneofKind: 'staffClassMembership';
        /**
         * StaffClassMembership to be mutated. StaffClassMemberships can be
         * inserted, updated, upserted and deleted. Permissions required:
         *     'write' to 'tp:staff' in 'schools/{SchoolID}'
         *     'write' to 'tp:studentgroup' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.StaffClassMembership staffClassMembership = 7;
         */
        staffClassMembership: StaffClassMembership;
      }
    | {
        oneofKind: 'schoolYear';
        /**
         * SchoolYear to be mutated. SchoolYears can be inserted, updated or
         * upserted. Permissions required:
         *     'write' to 'tp:school' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.SchoolYear schoolYear = 8;
         */
        schoolYear: SchoolYear;
      }
    | {
        oneofKind: 'parent';
        /**
         * Parent to be mutated. Parents can be inserted, updated, upserted and
         * deleted. Permissions required:
         *     'write' to 'tp:parent' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.Parent parent = 9;
         */
        parent: Parent;
      }
    | {
        oneofKind: 'studentParent';
        /**
         * StudentParent to be mutated. StudentParents can be inserted, updated,
         * upserted and deleted.
         *
         * @generated from protobuf field: schoolman.StudentParent studentParent = 10;
         */
        studentParent: StudentParent;
      }
    | {
        oneofKind: 'gxodChangedRows';
        /**
         * GXODChangedRows to be mutated. GXODChangedRows can only be upserted.
         * Permissions required:
         *     'write' to 'tp:mischangedrows' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.GXODChangedRows gxodChangedRows = 11;
         */
        gxodChangedRows: GXODChangedRows;
      }
    | {
        oneofKind: 'schoolYearPeriod';
        /**
         * SchoolYearPeriod to be mutated. SchoolYearPeriods can be inserted,
         * updated, upserted or deleted. Permissions required:
         *     'write' to 'tp:school' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.SchoolYearPeriod schoolYearPeriod = 13;
         */
        schoolYearPeriod: SchoolYearPeriod;
      }
    | {
        oneofKind: 'misTeachingGroup';
        /**
         * MISTeachingGroup to be mutated. MISTeachingGroups can be upserted,
         * updated and deleted. Permissions required:
         *     'write' to 'tp:misteachinggroup' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.MISTeachingGroup misTeachingGroup = 14;
         */
        misTeachingGroup: MISTeachingGroup;
      }
    | {
        oneofKind: 'yearGroup';
        /**
         * YearGroup to be mutated. YearGroups can be inserted, updated and
         * upserted. Permissions required:
         *     'write' to 'tp:school' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.YearGroup yearGroup = 15;
         */
        yearGroup: YearGroup;
      }
    | {
        oneofKind: 'studentXP';
        /**
         * StudentXP to be mutated. StudentXP can only be hard deleted
         * Permissions required:
         *     'delete' to 'tp:student' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.StudentXP studentXP = 16;
         */
        studentXP: StudentXP;
      }
    | {
        oneofKind: 'studentExternalID';
        /**
         * StudentExternalID to be mutated. StudentExternalID can be upserted or
         * deleted. Permissions required:
         *      'write' to 'tp:student' in 'schools/{StudentExternalID.SchoolID}'
         *
         * @generated from protobuf field: schoolman.StudentExternalID studentExternalID = 17;
         */
        studentExternalID: StudentExternalID;
      }
    | {
        oneofKind: 'schoolWondeSubject';
        /**
         * SchoolWondeSubject to be mutated. SchoolWondeSubject can be inserted or
         * deleted. Permissions required:
         *      'write' to 'tp:school' in 'schools/{SchoolID}'
         *
         * @generated from protobuf field: schoolman.SchoolWondeSubject schoolWondeSubject = 18;
         */
        schoolWondeSubject: SchoolWondeSubject;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf enum schoolman.OrgMutation.Type
 */
export enum OrgMutation_Type {
  /**
   * Insert a new entity
   *
   * @generated from protobuf enum value: INSERT = 0;
   */
  INSERT = 0,
  /**
   * Update an existing entity
   *
   * @generated from protobuf enum value: UPDATE = 1;
   */
  UPDATE = 1,
  /**
   * Delete an existing entity (typically a soft delete / expire)
   *
   * @generated from protobuf enum value: DELETE = 2;
   */
  DELETE = 2,
  /**
   * Insert or update an entity
   *
   * @generated from protobuf enum value: UPSERT = 3;
   */
  UPSERT = 3,
  /**
   * Hard delete an entity
   *
   * @generated from protobuf enum value: HARD_DELETE = 4;
   */
  HARD_DELETE = 4,
}
/**
 * @generated from protobuf message schoolman.OrgMutationResult
 */
export interface OrgMutationResult {
  /**
   * If the corresponding `OrgMutation` was of type `INSERT`, then
   * this is the id of the created entity.
   *
   * @generated from protobuf field: string entityId = 1;
   */
  entityId: string;
}
/**
 * @generated from protobuf message schoolman.CommitOrgChangesRequest
 */
export interface CommitOrgChangesRequest {
  /**
   * The mutations to be performed in one transaction
   *
   * @generated from protobuf field: repeated schoolman.OrgMutation mutations = 1;
   */
  mutations: OrgMutation[];
}
/**
 * @generated from protobuf message schoolman.CommitOrgChangesResponse
 */
export interface CommitOrgChangesResponse {
  /**
   * The results of the mutations. This list has the same length as
   * the `mutations` list in the `CommitOrgChangesRequest` message
   * this message is a response for.
   *
   * @generated from protobuf field: repeated schoolman.OrgMutationResult results = 1;
   */
  results: OrgMutationResult[];
  /**
   * The results of any additional mutations triggered during this commit
   *
   * @generated from protobuf field: repeated schoolman.OrgMutationResult additionalResults = 2;
   */
  additionalResults: OrgMutationResult[];
}
/**
 * @generated from protobuf message schoolman.Student
 */
export interface Student {
  /**
   * One of studentID or externalID must be provided on OrgMutation
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string externalID = 2;
   */
  externalID: string;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string firstName = 4;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 5;
   */
  lastName: string;
  /**
   * @generated from protobuf field: schoolman.Gender gender = 6;
   */
  gender: Gender;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 7;
   */
  dateOfBirth?: Timestamp;
  /**
   * @generated from protobuf field: string username = 8;
   */
  username: string;
  /**
   * @generated from protobuf field: string password = 9;
   */
  password: string;
  /**
   * @generated from protobuf field: schoolman.Student.HomeworkLength homeworkLength = 10;
   */
  homeworkLength: Student_HomeworkLength;
  /**
   * The date the student became inactive.
   * If not set, the defaulting behavior for the inactiveDate depends on the
   * mutation type.
   * For an INSERT the inactiveDate is set to the end of the current school
   * year. For an UPSERT, an InvalidArgument error will be thrown. For an
   * UPDATE, the inactiveDate will be unchanged.
   *
   * @generated from protobuf field: google.protobuf.Timestamp inactiveDate = 11;
   */
  inactiveDate?: Timestamp;
  /**
   * The type of the student. Can be set to TEMPLATE on an INSERT mutation to
   * create a template student. Only the STUDENT or CLASSROOM_GUEST values are
   * allowed for all other mutations. This field cannot currently be updated
   * once set.
   *
   * @generated from protobuf field: schoolman.Student.Type type = 12;
   */
  type: Student_Type;
  /**
   * List of student groups the student is in (read only).
   *
   * @generated from protobuf field: repeated string studentGroupID = 13;
   */
  studentGroupID: string[];
  /**
   * List of parents of the student (read only).
   *
   * @generated from protobuf field: repeated schoolman.Parent parents = 14;
   */
  parents: Parent[];
  /**
   * The students sparx code. Only visible to sparx staff members.
   * If this is set when inserting a student (non 0), it will make the student
   * code as the <SchoolURL><Code>. If inserting it must be numeric.
   *
   * @generated from protobuf field: string code = 15;
   */
  code: string;
  /**
   * The students current invite code - readonly.
   *
   * @generated from protobuf field: schoolman.InviteCode invite = 16;
   */
  invite?: InviteCode;
  /**
   * Write only field for student assessment results. Causes the assessment
   * results to be added to the student update message. The results are not
   * persisted in schoolman itself.
   *
   * @generated from protobuf field: repeated datamsg.AssessmentResult assessmentResults = 18;
   */
  assessmentResults: AssessmentResult[];
  /**
   * Write only fields for student data flags. Causes these flags to be added
   * to the student update message. The values are not persisted in schoolman
   * itself.
   *
   * @generated from protobuf field: bool fsmEligible = 19;
   */
  fsmEligible: boolean;
  /**
   * @generated from protobuf field: bool isEAL = 20;
   */
  isEAL: boolean;
  /**
   * @generated from protobuf field: bool pupilPremium = 21;
   */
  pupilPremium: boolean;
  /**
   * Write only field that indicates that the student is being mutated by an
   * MIS import. This is not persisted.
   *
   * @generated from protobuf field: bool mutationFromMISImport = 22;
   */
  mutationFromMISImport: boolean;
  /**
   * If requested this field is populated with the student's fallback external
   * ids. This field is output_only, see mutations.StudentExternalIDMutation.
   *
   * @generated from protobuf field: repeated schoolman.StudentExternalID fallbackExternalIDs = 23;
   */
  fallbackExternalIDs: StudentExternalID[];
  /**
   * Time the student last set their password (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordSetAt = 24;
   */
  passwordSetAt?: Timestamp;
  /**
   * Time the student last requested a password reset (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordResetRequestedAt = 25;
   */
  passwordResetRequestedAt?: Timestamp;
  /**
   * Time a request to reset a password was last granted by a teacher (null if
   * they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordResetGrantedAt = 26;
   */
  passwordResetGrantedAt?: Timestamp;
  /**
   * Time the student last set their password (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastLoginAt = 27;
   */
  lastLoginAt?: Timestamp;
  /**
   * A learning path spec name, in the format
   * `curriculums/{cu_id}/learningpathspecs/{lus_id}` or the string 'none'. By
   * default, we determine the difficulty of work that goes into a student's
   * homework based on our idea of their ability. This field allows the teacher
   * to override this decision for a student. The default value of '' is
   * interpreted as no update when modifying this entity.
   *
   * @generated from protobuf field: string learningPathSpecOverride = 28;
   */
  learningPathSpecOverride: string;
  /**
   * The email address for the student.
   * If this is not set for an UPDATE mutation, it will have no effect, and the
   * previous value will remain.
   *
   * @generated from protobuf field: string emailAddress = 29;
   */
  emailAddress: string;
  /**
   * Whether to clear the email address for an existing student.
   * If this is set for an UPDATE mutation and the emailAddress is set to blank,
   * we will clear the stored email address for the student, otherwise the
   * previous value will remain.
   *
   * @generated from protobuf field: bool clearEmailAddress = 30;
   */
  clearEmailAddress: boolean;
  /**
   * Whether higher only topics should be excluded from the students homework.
   * If this is unset for an UPDATE mutation, it will have no effect, and the
   * previous value will remain.
   *
   * @generated from protobuf field: google.protobuf.BoolValue excludeHigherOnlyTopics = 31;
   */
  excludeHigherOnlyTopics?: BoolValue;
  /**
   * Whether the student has the tables games timer turned off
   * If this is unset for an UPDATE mutation, it will have no effect, and the
   * previous value will remain.
   *
   * @generated from protobuf field: google.protobuf.BoolValue disableTablesGamesTimer = 32;
   */
  disableTablesGamesTimer?: BoolValue;
  /**
   * Whether times tables is enabled/disabled differently than the class level
   * setting for this student.
   * When the student changes class they should be set back to the new classes
   * setting, to do this we also store the class they are in when the override
   * is set so it can be compared against their current group. This way we don't
   * actually need to update this value when a student changes class.
   * If this is unset for an UPDATE mutation, it will have no effect and the
   * previous value will remain.
   *
   * @generated from protobuf field: schoolman.TablesTaskEnabledOverride tablesTaskEnabledOverride = 33;
   */
  tablesTaskEnabledOverride?: TablesTaskEnabledOverride;
  /**
   * Whether bookwork checks are disabled for the student
   * If this is unset for an UPDATE mutation, it will have no effect, and the
   * previous value will remain.
   *
   * @generated from protobuf field: google.protobuf.BoolValue disableBookworkChecks = 34;
   */
  disableBookworkChecks?: BoolValue;
  /**
   * Whether the student is currently blocked. A zero time or null is considered
   * to be false.
   * If this is unset for an UPDATE mutation, it will have no effect, and the
   * previous value will remain.
   *
   * @generated from protobuf field: google.protobuf.Timestamp blockedAt = 35;
   */
  blockedAt?: Timestamp;
}
/**
 * @generated from protobuf enum schoolman.Student.HomeworkLength
 */
export enum Student_HomeworkLength {
  /**
   * NULL means no change should be made to this field (differs from
   *
   * @generated from protobuf enum value: NULL = 0;
   */
  NULL = 0,
  /**
   * NONE)
   *
   * @generated from protobuf enum value: FULL = 1;
   */
  FULL = 1,
  /**
   * @generated from protobuf enum value: HALF = 2;
   */
  HALF = 2,
  /**
   * @generated from protobuf enum value: OPTIONAL = 3;
   */
  OPTIONAL = 3,
  /**
   * NONE means no homework is set for this student
   *
   * @generated from protobuf enum value: NONE = 4;
   */
  NONE = 4,
}
/**
 * @generated from protobuf enum schoolman.Student.Type
 */
export enum Student_Type {
  /**
   * @generated from protobuf enum value: STUDENT = 0;
   */
  STUDENT = 0,
  /**
   * @generated from protobuf enum value: TEMPLATE = 1;
   */
  TEMPLATE = 1,
  /**
   * @generated from protobuf enum value: GUEST = 2;
   */
  GUEST = 2,
  /**
   * @generated from protobuf enum value: CLASSROOM_GUEST = 3;
   */
  CLASSROOM_GUEST = 3,
  /**
   * @generated from protobuf enum value: RAPID_ONBOARDING_STUDENT = 4;
   */
  RAPID_ONBOARDING_STUDENT = 4,
}
/**
 * TablesTaskEnabledOverride is a student setting containing whether to enable
 * or disable times tables for a student (override them from the class default)
 * Since we don't want to have to update this whenever they change class we also
 * store the student group they are in when it is set for us to compare against
 * when using the value
 *
 * @generated from protobuf message schoolman.TablesTaskEnabledOverride
 */
export interface TablesTaskEnabledOverride {
  /**
   * Should times tables be on or off
   *
   * @generated from protobuf field: bool enabled = 1;
   */
  enabled: boolean;
  /**
   * Group the override is for, the override should only apply when they are in
   * this group
   *
   * @generated from protobuf field: string student_group_id = 2;
   */
  studentGroupId: string;
}
/**
 * StudentXP is an org mutation entity that is used to delete student xp awards
 * and levels
 *
 * @generated from protobuf message schoolman.StudentXP
 */
export interface StudentXP {
  /**
   * The id of the student for which to delete xp
   *
   * @generated from protobuf field: string StudentID = 1 [json_name = "StudentID"];
   */
  studentID: string;
  /**
   * The school id of the student, used to check permissions
   *
   * @generated from protobuf field: string SchoolID = 2 [json_name = "SchoolID"];
   */
  schoolID: string;
}
/**
 * @generated from protobuf message schoolman.StudentGroup
 */
export interface StudentGroup {
  /**
   * @generated from protobuf field: string studentGroupID = 1;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: schoolman.StudentGroupType type = 5;
   */
  type: StudentGroupType;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 6;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 7;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: bool homeworkEnabled = 8;
   */
  homeworkEnabled: boolean;
  /**
   * @generated from protobuf field: string misTeachingGroupID = 9;
   */
  misTeachingGroupID: string;
  /**
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel attainmentLevel = 10;
   */
  attainmentLevel: AttainmentLevel;
  /**
   * @generated from protobuf field: string yearGroupID = 11;
   */
  yearGroupID: string;
  /**
   * The ID of some external MIS group definition that the StudentGroup
   * was created from, and will be kept in sync with. This was introduced
   * for Wonde integration, whereas misTeachingGroup is a GXoD-specific
   * field.
   *
   * @generated from protobuf field: string externalID = 12;
   */
  externalID: string;
  /**
   * The attainment level of the class, used in classroom for
   * suggested objectives when building a lesson
   *
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel classroomAttainmentLevel = 13;
   */
  classroomAttainmentLevel: AttainmentLevel;
  /**
   * Readonly
   *
   * @deprecated
   * @generated from protobuf field: int32 yearGroup = 4 [deprecated = true];
   */
  yearGroup: number; // legacy year group of the associated year group
  /**
   * @generated from protobuf field: repeated schoolman.StaffClassMembership staff = 20;
   */
  staff: StaffClassMembership[];
  /**
   * @generated from protobuf field: schoolman.MISTeachingGroup misTeachingGroup = 21;
   */
  misTeachingGroup?: MISTeachingGroup;
  /**
   * Read only field describing when the homework enabled flag was last set to
   * true. Can be nil if homework has never been set, or where homework was
   * enabled before this field was added.
   *
   * @generated from protobuf field: google.protobuf.Timestamp homeworkEnabledAt = 22;
   */
  homeworkEnabledAt?: Timestamp;
  /**
   * A locked class cannot be interacted with in any way and has most
   * functionality disabled (e.g. homework generation).
   * Students may log in to a locked class.
   * Locked may not be set to false in a mutation (it may be unset).
   *
   * @generated from protobuf field: google.protobuf.BoolValue locked = 23;
   */
  locked?: BoolValue;
}
/**
 * @generated from protobuf message schoolman.YearGroup
 */
export interface YearGroup {
  /**
   * @generated from protobuf field: string yearGroupID = 1;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: int32 legacyYearGroup = 4;
   */
  legacyYearGroup: number;
  /**
   * @deprecated
   * @generated from protobuf field: bool lessonsEnabled = 5 [deprecated = true];
   */
  lessonsEnabled: boolean; // Deprecated: READ ONLY, replaced by product.
  /**
   * After this time, the year group should no longer be visible on TP.
   * If null, the Year Group will never expire.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiryTime = 6;
   */
  expiryTime?: Timestamp;
  /**
   * @generated from protobuf field: schoolman.YearGroup.Product product = 7;
   */
  product: YearGroup_Product;
}
/**
 * Which product is enabled for the year group
 * Should this enum be changed, please amend GetProducts() in smmsg/helpers.go
 *
 * @generated from protobuf enum schoolman.YearGroup.Product
 */
export enum YearGroup_Product {
  /**
   * @generated from protobuf enum value: NULL = 0;
   */
  NULL = 0,
  /**
   * @generated from protobuf enum value: HOMEWORK = 1;
   */
  HOMEWORK = 1,
  /**
   * @generated from protobuf enum value: LESSONS = 2;
   */
  LESSONS = 2,
  /**
   * @generated from protobuf enum value: PRIMARY = 3;
   */
  PRIMARY = 3,
  /**
   * @generated from protobuf enum value: PRIMARY_LESSONS = 4;
   */
  PRIMARY_LESSONS = 4,
}
/**
 * @generated from protobuf message schoolman.School
 */
export interface School {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string urlName = 3;
   */
  urlName: string;
  /**
   * @generated from protobuf field: repeated string games = 5;
   */
  games: string[];
  /**
   * @generated from protobuf field: string replyToAddress = 6;
   */
  replyToAddress: string;
  /**
   * Flags that provide extra states of the school
   *
   * @generated from protobuf field: repeated schoolman.SchoolFlag schoolFlags = 7;
   */
  schoolFlags: SchoolFlag[];
  /**
   * After this time no-one can log into the school.
   * If null, logins are allowed indefinitely.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiryTime = 8;
   */
  expiryTime?: Timestamp;
  /**
   * DofE number used in groupcall import on schoolboss.
   *
   * @generated from protobuf field: string establishmentID = 9;
   */
  establishmentID: string;
  /**
   * The date that the school was created. This is read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp createdDate = 10;
   */
  createdDate?: Timestamp;
  /**
   * The edubaseEstablishmentID is used to link schoolman schools to schools in
   * the select school page. It is separate from the establishmentID, which is
   * used for Groupcall imports, as Groupcall can use out of date
   * establishmentIDs. It is NULLable as the select school page does not include
   * test or demo schools. Passing an empty string here will set the
   * edubaseEstablishmentID to NULL on a create and will have no effect on an
   * update.
   *
   * @generated from protobuf field: string edubaseEstablishmentID = 11;
   */
  edubaseEstablishmentID: string;
  /**
   * The tz database time zone that the school is in.
   * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones.
   * The string must be able to be parsed by time.LoadLocation in Go to be
   * valid.
   *
   * @generated from protobuf field: string timeZone = 13;
   */
  timeZone: string;
  /**
   * The MIS status of the school. This will only be populated with FULL
   * SchoolView. Requires permission 'read' from 'schoolboss:school_details' in
   * '{Environment}' Changing this value must currently be done using the
   * schoolboss service.
   *
   * @generated from protobuf field: schoolman.School.MISStatus misStatus = 14;
   */
  misStatus: School_MISStatus;
  /**
   * The Wonde ID of the school. Empty for schools with no Wonde ID (for
   * example GXoD-provisioned schools).
   *
   * @generated from protobuf field: string wondeID = 15;
   */
  wondeID: string;
  /**
   * The Wonde regional domain for the school. This indicates where to
   * send Wonde API requests for this school, most wondewitch requests
   * would require this information.
   * See https://docs.wonde.com/docs/api/sync#domains
   *
   * @generated from protobuf field: string wondeDomain = 16;
   */
  wondeDomain: string;
  /**
   * School address.
   *
   * @generated from protobuf field: schoolman.SchoolAddress schoolAddress = 17;
   */
  schoolAddress?: SchoolAddress;
  /**
   * Time of the last successful sync.
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastSuccessfulSync = 18;
   */
  lastSuccessfulSync?: Timestamp;
  /**
   * Readonly
   *
   * @deprecated
   * @generated from protobuf field: repeated int64 yeargroups = 4 [deprecated = true];
   */
  yeargroups: number[];
  /**
   * @deprecated
   * @generated from protobuf field: repeated int64 lessonsYeargroups = 12 [deprecated = true];
   */
  lessonsYeargroups: number[];
}
/**
 * MISStatus describes the state of MIS imports for a school.
 * This is currently based on schoolboss.SchoolGXODStatus, but is intended
 * to be agnostic about the provider of MIS data.
 *
 * @generated from protobuf enum schoolman.School.MISStatus
 */
export enum School_MISStatus {
  /**
   * The unset value. This will be returned when the BASIC SchoolView is used.
   *
   * @generated from protobuf enum value: MISSTATUS_UNSPECIFIED = 0;
   */
  MISSTATUS_UNSPECIFIED = 0,
  /**
   * NO_SECRET is the state where we do not have a secret for this school
   * to enable MIS integration.
   * In this state, student data is added or edited manually.
   *
   * @generated from protobuf enum value: NO_SECRET = 1;
   */
  NO_SECRET = 1,
  /**
   * GXOD_ENABLED means student data is created and updated by importing from
   * Groupcall. In this state, student data cannot be added or edited
   * manually.
   *
   * @generated from protobuf enum value: GXOD_ENABLED = 2;
   */
  GXOD_ENABLED = 2,
  /**
   * GXOD_DISABLED means we have a secret to access student data from
   * Groupcall, but have suspended the import process. In this state, student
   * data cannot be added or edited.
   *
   * @generated from protobuf enum value: GXOD_DISABLED = 3;
   */
  GXOD_DISABLED = 3,
  /**
   * WONDE_ENABLED means the school has a Wonde ID, so the expectation
   * is for students and classes to be provided by Wonde. This does not
   * guarantee that the ID is valid or that Sparx is authorised to access
   * the school's data via Wonde, only that an ID has been set.
   * In this state, student and class data cannot be added or edited.
   *
   * @generated from protobuf enum value: WONDE_ENABLED = 4;
   */
  WONDE_ENABLED = 4,
}
/**
 * @generated from protobuf message schoolman.SchoolYear
 */
export interface SchoolYear {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: int32 year = 2;
   */
  year: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 3;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 4;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp homeworkStartDate = 5;
   */
  homeworkStartDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp homeworkEndDate = 6;
   */
  homeworkEndDate?: Timestamp;
}
/**
 * @generated from protobuf message schoolman.SchoolYearPeriod
 */
export interface SchoolYearPeriod {
  /**
   * @generated from protobuf field: string schoolYearPeriodID = 1;
   */
  schoolYearPeriodID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: int32 year = 3;
   */
  year: number;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: schoolman.SchoolYearPeriodType type = 5;
   */
  type: SchoolYearPeriodType;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 6;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 7;
   */
  endDate?: Timestamp;
}
/**
 * @generated from protobuf message schoolman.SchoolYearWeek
 */
export interface SchoolYearWeek {
  /**
   * The index of the week relative to the first week of the first term.
   *
   * @generated from protobuf field: int32 index = 1;
   */
  index: number;
  /**
   * Timestamp for the Monday of the week.
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
  /**
   * The index of the datacycle the week is in.
   *
   * @generated from protobuf field: int32 dataCycle = 3;
   */
  dataCycle: number;
  /**
   * The index of the week within the datacycle.
   *
   * @generated from protobuf field: int32 weekIndexInDataCycle = 4;
   */
  weekIndexInDataCycle: number;
  /**
   * The IDs for the SchoolYearPeriods the week is in.
   *
   * @generated from protobuf field: repeated string schoolYearPeriodIDs = 5;
   */
  schoolYearPeriodIDs: string[];
  /**
   * Whether homework could be set during this week.
   *
   * @generated from protobuf field: bool canSetHomework = 6;
   */
  canSetHomework: boolean;
}
/**
 * @deprecated
 * @generated from protobuf message schoolman.Staff
 */
export interface Staff {
  /**
   * @generated from protobuf field: string staffID = 1;
   */
  staffID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * @generated from protobuf field: string firstName = 4;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 5;
   */
  lastName: string;
  /**
   * @generated from protobuf field: string emailAddress = 6;
   */
  emailAddress: string;
  /**
   * @generated from protobuf field: schoolman.Gender gender = 7;
   */
  gender: Gender;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 8;
   */
  dateOfBirth?: Timestamp;
  /**
   * @generated from protobuf field: string username = 9;
   */
  username: string;
  /**
   * @generated from protobuf field: string password = 10;
   */
  password: string;
  /**
   * Mode that their username should be set on
   *
   * @generated from protobuf field: schoolman.UsernameMode usernameMode = 11;
   */
  usernameMode: UsernameMode;
  /**
   * @generated from protobuf field: repeated string roles = 20;
   */
  roles: string[];
  /**
   * Allows updating the username/username mode of an existing staff member
   *
   * @generated from protobuf field: bool updateUsername = 21;
   */
  updateUsername: boolean;
  /**
   * Read Only. Set by the translation layer when a v2 staff member has no product access
   *
   * @generated from protobuf field: bool denyProductAccess = 22;
   */
  denyProductAccess: boolean;
  /**
   * Request a email is sent to the user with a password reset/welcome link
   * (write only) DEPRECATED - instead use SendWelcomeEmail
   *
   * @deprecated
   * @generated from protobuf field: bool resetPassword = 100 [deprecated = true];
   */
  resetPassword: boolean;
}
/**
 * @generated from protobuf message schoolman.StudentClassMembership
 */
export interface StudentClassMembership {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: string studentID = 3;
   */
  studentID: string;
  /**
   * Used only in delete mutation - if true causes all StudentClassMemberships
   * for a student to be deleted.
   *
   * @generated from protobuf field: bool deleteAllForStudent = 4;
   */
  deleteAllForStudent: boolean;
  /**
   * Can be used instead of a studentID. For UPSERT an Unknown error will occur
   * if there is no student with this externalID. For DELETE there will be no
   * error if there is no student with this externalID (so that Groupcall can
   * optimistically delete memberships).
   *
   * @generated from protobuf field: string externalStudentID = 5;
   */
  externalStudentID: string;
}
/**
 * @generated from protobuf message schoolman.StaffClassMembership
 */
export interface StaffClassMembership {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: string staffID = 3;
   */
  staffID: string;
  /**
   * @generated from protobuf field: bool feedbackEnabled = 4;
   */
  feedbackEnabled: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 5;
   */
  startDate?: Timestamp;
}
/**
 * @generated from protobuf message schoolman.Parent
 */
export interface Parent {
  /**
   * @generated from protobuf field: string parentID = 1;
   */
  parentID: string;
  /**
   * @generated from protobuf field: string externalID = 2;
   */
  externalID: string;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * Time this parent was soft deleted from our system. If set, this parent is
   * soft deleted.
   * Note this behaviour does not match student expiration, but instead matches
   * the current standard pattern.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deleteTime = 12;
   */
  deleteTime?: Timestamp;
  /**
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * @generated from protobuf field: string firstName = 5;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 6;
   */
  lastName: string;
  /**
   * @generated from protobuf field: string emailAddress = 7;
   */
  emailAddress: string;
  /**
   * feedbackEnabled is a calculated property dictating whether we should send
   * emails to this parent
   *
   * @generated from protobuf field: bool feedbackEnabled = 9;
   */
  feedbackEnabled: boolean;
  /**
   * @deprecated
   * @generated from protobuf field: bool hasUnsubscribed = 10 [deprecated = true];
   */
  hasUnsubscribed: boolean;
  /**
   * Whether the parent has emails enabled for this student (determined by
   * context)
   *
   * @generated from protobuf field: bool studentEmailEnabled = 11;
   */
  studentEmailEnabled: boolean;
  /**
   * @generated from protobuf field: schoolman.Gender gender = 8;
   */
  gender: Gender;
  /**
   * @generated from protobuf field: schoolman.Parent.HasUnsubscribedStatus hasUnsubscribedStatus = 13;
   */
  hasUnsubscribedStatus: Parent_HasUnsubscribedStatus;
}
/**
 * whether the parent has unsubscribed from all emails
 *
 * @generated from protobuf enum schoolman.Parent.HasUnsubscribedStatus
 */
export enum Parent_HasUnsubscribedStatus {
  /**
   * NULL means no change should be made to this field
   *
   * @generated from protobuf enum value: HAS_UNSUBSCRIBED_NULL = 0;
   */
  HAS_UNSUBSCRIBED_NULL = 0,
  /**
   * @generated from protobuf enum value: HAS_UNSUBSCRIBED_FALSE = 1;
   */
  HAS_UNSUBSCRIBED_FALSE = 1,
  /**
   * @generated from protobuf enum value: HAS_UNSUBSCRIBED_TRUE = 2;
   */
  HAS_UNSUBSCRIBED_TRUE = 2,
}
/**
 * @generated from protobuf message schoolman.StudentParent
 */
export interface StudentParent {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string studentID = 2;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string externalStudentID = 3;
   */
  externalStudentID: string;
  /**
   * @generated from protobuf field: string parentID = 4;
   */
  parentID: string;
  /**
   * @generated from protobuf field: string externalParentID = 5;
   */
  externalParentID: string;
  /**
   * Can't use optional as plugins don't support it
   *
   * @generated from protobuf field: google.protobuf.BoolValue studentEmailEnabled = 7;
   */
  studentEmailEnabled?: BoolValue;
}
/**
 * @generated from protobuf message schoolman.GXODChangedRows
 */
export interface GXODChangedRows {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string changedRowsMap = 2;
   */
  changedRowsMap: string;
  /**
   * @generated from protobuf field: schoolman.GXODChangedRows.ImportStatus lastImportStatus = 3;
   */
  lastImportStatus: GXODChangedRows_ImportStatus;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastImportTimestamp = 4;
   */
  lastImportTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: string lastImportMIS = 5;
   */
  lastImportMIS: string;
  /**
   * Flag to set last_import_mis as empty string
   *
   * @generated from protobuf field: bool clearLastImportMIS = 6;
   */
  clearLastImportMIS: boolean;
}
/**
 * @generated from protobuf enum schoolman.GXODChangedRows.ImportStatus
 */
export enum GXODChangedRows_ImportStatus {
  /**
   * NULL means no change should be made to this field
   *
   * @generated from protobuf enum value: NULL = 0;
   */
  NULL = 0,
  /**
   * @generated from protobuf enum value: SUCCESS = 1;
   */
  SUCCESS = 1,
  /**
   * @generated from protobuf enum value: FAILURE = 2;
   */
  FAILURE = 2,
}
/**
 * An MISTeachingGroup is a teaching group defined by a school's MIS. It does
 * not necessarily correspond to a studentgroup in Sparx.
 *
 * @generated from protobuf message schoolman.MISTeachingGroup
 */
export interface MISTeachingGroup {
  /**
   * @generated from protobuf field: string misTeachingGroupID = 1;
   */
  misTeachingGroupID: string;
  /**
   * @generated from protobuf field: string externalID = 2;
   */
  externalID: string;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string subjectName = 5;
   */
  subjectName: string;
  /**
   * @generated from protobuf field: string staffInitials = 6;
   */
  staffInitials: string;
  /**
   * @generated from protobuf field: schoolman.MISTeachingGroup.StudentImportStatus studentImportStatus = 7;
   */
  studentImportStatus: MISTeachingGroup_StudentImportStatus;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp expiryDate = 8;
   */
  expiryDate?: Timestamp;
  /**
   * Write only. The externalIDs of the student members of the teaching group.
   * Note that there may be externalIDs in here which do not correspond to
   * students in schoolman.
   *
   * @generated from protobuf field: repeated string studentExternalIDs = 9;
   */
  studentExternalIDs: string[];
  /**
   * @generated from protobuf field: schoolman.MISTeachingGroup.MISTeachingGroupType type = 10;
   */
  type: MISTeachingGroup_MISTeachingGroupType;
}
/**
 * @generated from protobuf enum schoolman.MISTeachingGroup.StudentImportStatus
 */
export enum MISTeachingGroup_StudentImportStatus {
  /**
   * NULL means no change should be made to this field
   *
   * @generated from protobuf enum value: NULL = 0;
   */
  NULL = 0,
  /**
   * @generated from protobuf enum value: ON = 1;
   */
  ON = 1,
  /**
   * @generated from protobuf enum value: OFF = 2;
   */
  OFF = 2,
}
/**
 * @generated from protobuf enum schoolman.MISTeachingGroup.MISTeachingGroupType
 */
export enum MISTeachingGroup_MISTeachingGroupType {
  /**
   * @generated from protobuf enum value: NOCHANGE = 0;
   */
  NOCHANGE = 0,
  /**
   * @generated from protobuf enum value: TEACHING = 1;
   */
  TEACHING = 1,
  /**
   * @generated from protobuf enum value: YEAR = 2;
   */
  YEAR = 2,
}
/**
 * @deprecated
 * @generated from protobuf message schoolman.StaffRole
 */
export interface StaffRole {
  /**
   * The unique id of the role (eg 'teacher').
   *
   * @generated from protobuf field: string role = 1;
   */
  role: string;
  /**
   * The display name of the role (eg 'Teacher').
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * Request for the GetSchools method.
 *
 * @generated from protobuf message schoolman.GetSchoolsRequest
 */
export interface GetSchoolsRequest {}
/**
 * Response for the GetSchools method.
 *
 * @generated from protobuf message schoolman.GetSchoolsResponse
 */
export interface GetSchoolsResponse {}
/**
 * Request for the GetStudents method.
 *
 * @generated from protobuf message schoolman.GetStudentsRequest
 */
export interface GetStudentsRequest {
  /**
   * The school to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, there will be no
   * filtering on school, and the permissions:
   *     'read' from 'tp:student' in 'schools'
   *     'read' from 'tp:studentgroup' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * If empty, there will be no filtering on student ID. If non-empty, only
   * students with IDs in the list will be returned. If an item in the list
   * is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 2;
   */
  studentID: string[];
  /**
   * The studentgroup to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, no filtering on
   * studentgroups will occur. If no studentgroup with the ID exists, there
   * will be no error and no students will be returned. The membership and
   * studentgroup must both be active.
   *
   * @generated from protobuf field: string groupID = 3;
   */
  groupID: string;
  /**
   * The yeargroup to filter the students on. If it is < 1 no yeargroup
   * filtering will occur. The student's yeargroup is determined by their class
   * - if they do not have a class they do not have a yeargroup.
   *
   * @deprecated
   * @generated from protobuf field: int32 yeargroup = 4 [deprecated = true];
   */
  yeargroup: number;
  /**
   * Select only students who aren't in an active student group.
   *
   * @generated from protobuf field: bool nogroup = 5;
   */
  nogroup: boolean;
  /**
   * If true include a students' parents on the response. This requires the
   * extra permission:
   *     'read' from 'tp:parent' in 'schools/{GetStudentsRequest.GetSchoolID()}'
   *
   * @generated from protobuf field: bool includeParents = 6;
   */
  includeParents: boolean;
  /**
   * If true include a student's soft deleted parents on the response. This
   * field has no effect if includeParents is false.
   *
   * @generated from protobuf field: bool includeDeletedParents = 19;
   */
  includeDeletedParents: boolean;
  /**
   * Select only students who aren't in an active class.
   *
   * @generated from protobuf field: bool noclass = 7;
   */
  noclass: boolean;
  /**
   * If true include inactive students in the response.
   *
   * @generated from protobuf field: bool includeInactive = 8;
   */
  includeInactive: boolean;
  /**
   * If nil, select students at current date.  Otherwise select students from
   * given timestamp.
   *
   * @generated from protobuf field: google.protobuf.Timestamp when = 9;
   */
  when?: Timestamp;
  /**
   * If empty, there will be no filtering on student externalID. If non-empty,
   * only students with externalIDs in the list will be returned.
   *
   * @generated from protobuf field: repeated string externalID = 10;
   */
  externalID: string[];
  /**
   * If false, students not of type STUDENT (e.g. TEMPLATE, GUEST and
   * CLASSROOM_GUEST) are filtered out unless their student ID is explicitly
   * asked for.
   *
   * @generated from protobuf field: bool includeAllStudentTypes = 11;
   */
  includeAllStudentTypes: boolean;
  /**
   * The ID of the year group to filter the students on. The student's year
   * group is determined by their class - if they do not have a class,
   * they do not have a yeargroup.
   *
   * @generated from protobuf field: string yearGroupID = 12;
   */
  yearGroupID: string;
  /**
   * If true the response will include secondary external ids for each student
   * (where we have these). These are intended as a fallback should the primary
   * external id provide insufficient to match against incoming MIS data.
   *
   * @generated from protobuf field: bool includeFallbackExternalIDs = 13;
   */
  includeFallbackExternalIDs: boolean;
  /**
   * When set, the response will include all students who were expired before
   * this date.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expired_before = 14;
   */
  expiredBefore?: Timestamp;
  /**
   * When set, the group memberships will not be fetched for the returned
   * students.
   *
   * @generated from protobuf field: bool ignoreGroupMemberships = 15;
   */
  ignoreGroupMemberships: boolean;
  /**
   * When set inactive students of type 'CLASSROOM_GUEST' will be included in
   * the response. This is a special case for classroom as guests expire when
   * lesson ends, but we will still want to display details about the guest
   * student.
   *
   * @generated from protobuf field: bool includeInactiveClassroomGuests = 16;
   */
  includeInactiveClassroomGuests: boolean;
  /**
   * The email address to filter the students on. If set, schoolID id required.
   * There should only be a single result for a schoolID/emailAddress
   * combination
   *
   * @generated from protobuf field: string emailAddress = 17;
   */
  emailAddress: string;
  /**
   * When set, do not fetch schools from school store to check if their
   * DeleteTimes are earlier than student expiry times. This optimisation
   * should only be used when the caller is not planning on reading the
   * ExpiryTime of the returned students because it could be incorrect if
   * their school's DeleteTime is earlier.
   *
   * @generated from protobuf field: bool ignoreSchoolExpiry = 18;
   */
  ignoreSchoolExpiry: boolean;
}
/**
 * Response for the GetStudents method.
 *
 * @generated from protobuf message schoolman.GetStudentsResponse
 */
export interface GetStudentsResponse {
  /**
   * The students who match the query. The StudentID, SchoolID, FirstName,
   * LastName, Gender, DateOfBirth, Username, HomeworkLength, Invite Information
   * and ExternalID are returned for every student. The students' names are
   * anonymised if the caller does not have the permission:
   *      'read' from 'tp:sparxusernames' in 'tpcommon'
   * The students' codes are returned if the caller has the permission:
   *      'read' from 'tp:sparxusercodes' in 'tpcommon'
   * The student groups which the student is a current member of are also
   * returned. If includeParents is true the parents that the student is linked
   * to are returned.
   *
   * @generated from protobuf field: repeated schoolman.Student students = 1;
   */
  students: Student[];
}
/**
 * Request for the GetParents method.
 *
 * @generated from protobuf message schoolman.GetParentsRequest
 */
export interface GetParentsRequest {
  /**
   * The school in which to search for parents in.
   * If it is empty or not a valid UUID a FailedPrecondition error will be
   * returned.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * If true, parents that are soft deleted will also be returned.
   *
   * @generated from protobuf field: bool includeDeleted = 2;
   */
  includeDeleted: boolean;
  /**
   * When set, the response will only include parents who were deleted before
   * this date.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deleted_before = 3;
   */
  deletedBefore?: Timestamp;
}
/**
 * Response for the GetParents method.
 *
 * @generated from protobuf message schoolman.GetParentsResponse
 */
export interface GetParentsResponse {
  /**
   * The ParentID, ExternalID, FirstName, LastName and EmailAddress of the
   * parents in the requested school.
   *
   * @generated from protobuf field: repeated schoolman.Parent parents = 1;
   */
  parents: Parent[];
}
/**
 * Request for the GetStudentParents method.
 *
 * @generated from protobuf message schoolman.GetStudentParentsRequest
 */
export interface GetStudentParentsRequest {
  /**
   * The school in which to search for parents in.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The students for which to get parents.
   *
   * @generated from protobuf field: repeated string student_ids = 2;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message schoolman.ParentList
 */
export interface ParentList {
  /**
   * @generated from protobuf field: repeated schoolman.Parent parent = 1;
   */
  parent: Parent[];
}
/**
 * Response for the GetStudentParents method.
 *
 * @generated from protobuf message schoolman.GetStudentParentsResponse
 */
export interface GetStudentParentsResponse {
  /**
   * The EmailAddress of each student's parents.
   *
   * @generated from protobuf field: map<string, schoolman.ParentList> parent_map = 1;
   */
  parentMap: {
    [key: string]: ParentList;
  };
}
/**
 * Request for the SearchStudents method.
 *
 * @generated from protobuf message schoolman.SearchStudentsRequest
 */
export interface SearchStudentsRequest {
  /**
   * The school in which to search for students in. If it is not a valid UUID an
   * Unknown error will be returned. If the school does not exist, there will
   * not be an error but no students will be returned.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * A string to trigram match with the students' first and last names. If the
   * string is <= 2 characters long then no students will be returned. The query
   * is case insensitive.
   *
   * @generated from protobuf field: string query = 2;
   */
  query: string;
}
/**
 * Response for the SearchStudents method.
 *
 * @generated from protobuf message schoolman.SearchStudentsResponse
 */
export interface SearchStudentsResponse {
  /**
   * The students who have a trigram match with the query string ordered in
   * decreasing similarity to the query string. Similarity is determined by the
   * amount of trigrams shared between the query string and the student's first
   * and last names. Only some of the fields of the Student object are returned
   * (StudentID, FirstName, LastName, StudentGroupID). The students' names are
   * anonymised if the caller does not have the permission:
   *      'read' from 'tp:sparxusernames' in 'tpcommon'
   *
   * @generated from protobuf field: repeated schoolman.Student students = 1;
   */
  students: Student[];
}
/**
 * Request for the GetStaff method.
 *
 * @deprecated
 * @generated from protobuf message schoolman.GetStaffRequest
 */
export interface GetStaffRequest {
  /**
   * Required.
   * The school in which to get staff in. If it is not a valid UUID an Unknown
   * error will be returned. If the school does not exist, there will not be an
   * error but no staff will be returned.
   * Note: This used to be optional, but it has been made required to aid in
   * staffstore migration work.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * If empty, there will be no filtering on staff ID. If non-empty, only
   * staff with IDs in the list will be returned. If an item in the list
   * is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string staffID = 2;
   */
  staffID: string[];
  /**
   * If empty, there will be no filtering on email address. If non-empty,
   * only staff with the provided email address will be returned.
   * Also, if non-empty schoolID is required. There should only be a single
   * result for a schoolID/emailAddress combination.
   *
   * @generated from protobuf field: string emailAddress = 3;
   */
  emailAddress: string;
}
/**
 * Response for the GetStaff method.
 *
 * @deprecated
 * @generated from protobuf message schoolman.GetStaffResponse
 */
export interface GetStaffResponse {
  /**
   * The staff who match the query. The StaffID, SchoolID, FirstName, LastName,
   * Title, EmailAddress, Username, Gender and Roles are returned for every
   * staff member.
   *
   * @generated from protobuf field: repeated schoolman.Staff staff = 1;
   */
  staff: Staff[];
}
/**
 * Request for the GetStaffRoles method.
 *
 * @deprecated
 * @generated from protobuf message schoolman.GetStaffRolesRequest
 */
export interface GetStaffRolesRequest {}
/**
 * Response for the GetStaffRoles method.
 *
 * @deprecated
 * @generated from protobuf message schoolman.GetStaffRolesResponse
 */
export interface GetStaffRolesResponse {
  /**
   * All the fields of each staff role.
   *
   * @generated from protobuf field: repeated schoolman.StaffRole roles = 1;
   */
  roles: StaffRole[];
}
/**
 * Request for the GetStudentGroups method.
 *
 * @generated from protobuf message schoolman.GetStudentGroupsRequest
 */
export interface GetStudentGroupsRequest {
  /**
   * The school in which to get studentGroups from. If it is not a valid UUID an
   * Unknown error will be returned. If the school does not exist, there will
   * not be an error but no students will be returned. If the value is empty,
   * there will be no filtering on school, and the and the permission:
   *     'read' from 'tp:studentgroup' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * If empty, there will be no filtering on student group IDs. If non-empty,
   * only student groups with IDs in the list will be returned. If an item in
   * the list is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 2;
   */
  studentGroupID: string[];
  /**
   * If empty, there will be no filtering on student IDs. If non-empty, only
   * student groups which have at least one of the students in the list as an
   * active member will be returned. If an item in the list is not a valid UUID,
   * an Unknown error will be returned. If non-empty, the permission:
   *      'read' from 'tp:student' in
   *      'schools/{GetStudentGroupsRequest.SchoolID}'
   * will be required.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * If empty, there will be no filtering on staffID. If non-empty, only
   * student groups which have the staff member with the passed ID as a member
   * will be returned. If the staffID is not a valid UUID, an Unknown error will
   * be returned. If the staff member does not exist there will be no error but
   * an empty list will be returned. If non-empty, the permission:
   *      'read' from 'tp:staff' in 'schools/{GetStudentGroupsRequest.SchoolID}'
   * will be required.
   *
   * @generated from protobuf field: string staffID = 4;
   */
  staffID: string;
  /**
   * If to include inactive student groups in the response.
   *
   * @generated from protobuf field: bool includeInactive = 5;
   */
  includeInactive: boolean;
  /**
   * If to include the IDs of a student group's staff in the response.
   *
   * @generated from protobuf field: bool includeStaff = 7;
   */
  includeStaff: boolean;
  /**
   * If nil, select students at current date.  Otherwise select students from
   * given timestamp.
   *
   * @generated from protobuf field: google.protobuf.Timestamp when = 8;
   */
  when?: Timestamp;
  /**
   * If to include the full MISTeachingGroup linked to the studentgroup.
   *
   * @generated from protobuf field: bool includeMISTeachingGroup = 9;
   */
  includeMISTeachingGroup: boolean;
}
/**
 * Response for the GetStudentGroups method.
 *
 * @generated from protobuf message schoolman.GetStudentGroupsResponse
 */
export interface GetStudentGroupsResponse {
  /**
   * The student groups who match the query. The StudentGroupId, SchoolID, Name,
   * YearGroup, Type, StartDate, EndDate and HomeworkEnabled flag are
   * returned for ever student group. The StaffMembership objects for staff who
   * are members of each student group are optionally included.
   *
   * @generated from protobuf field: repeated schoolman.StudentGroup groups = 1;
   */
  groups: StudentGroup[];
}
/**
 * Request for the GetSchoolYear method.
 *
 * @generated from protobuf message schoolman.GetSchoolYearRequest
 */
export interface GetSchoolYearRequest {
  /**
   * The school in which the SchoolYear is for. If it is not a valid UUID an
   * Unknown error will be returned. If the school does not exist, a NotFound
   * error will be returned.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * No longer has any effect.
   *
   * @deprecated
   * @generated from protobuf field: bool current = 2 [deprecated = true];
   */
  current: boolean;
  /**
   * The year of the school year to fetch. If it is zero, the current school
   * year will be fetched. If no school year with the year exists, a NotFound
   * error will be returned.
   *
   * @generated from protobuf field: int32 year = 3;
   */
  year: number;
  /**
   * Whether to calculate and return data about the weeks in the school year.
   *
   * @generated from protobuf field: bool includeWeeks = 4;
   */
  includeWeeks: boolean;
}
/**
 * Response for the GetSchoolYear method.
 *
 * @generated from protobuf message schoolman.GetSchoolYearResponse
 */
export interface GetSchoolYearResponse {
  /**
   * General details about the school of the school year.
   *
   * @generated from protobuf field: schoolman.School school = 1;
   */
  school?: School;
  /**
   * The SchoolID, Year, StartDate, EndDate and HomeworkStartDate of the school
   * year.
   *
   * @generated from protobuf field: schoolman.SchoolYear year = 2;
   */
  year?: SchoolYear;
  /**
   * The SchoolYearPeriodID, SchoolID, Year, Name, Type, StartDate and EndDate
   * of all the periods in the SchoolYear. The periods are ordered by start
   * date.
   *
   * @generated from protobuf field: repeated schoolman.SchoolYearPeriod periods = 3;
   */
  periods: SchoolYearPeriod[];
  /**
   * Data about each week between the StartDate and EndDate of the SchoolYear.
   *
   * @generated from protobuf field: repeated schoolman.SchoolYearWeek weeks = 4;
   */
  weeks: SchoolYearWeek[];
}
/**
 * Request for the GetSchoolYears method.
 *
 * @generated from protobuf message schoolman.GetSchoolYearsRequest
 */
export interface GetSchoolYearsRequest {
  /**
   * The school for which the SchoolYear and SchoolYearPeriods are for.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Whether to calculate and return data about the weeks in the school year.
   *
   * @generated from protobuf field: bool includeWeeks = 2;
   */
  includeWeeks: boolean;
}
/**
 * Response for the GetSchoolYears method.
 *
 * @generated from protobuf message schoolman.GetSchoolYearsResponse
 */
export interface GetSchoolYearsResponse {
  /**
   * List of all the SchoolYear and SchoolYearPeriods for a school.
   *
   * @generated from protobuf field: repeated schoolman.GetSchoolYearsResponse.Year years = 1;
   */
  years: GetSchoolYearsResponse_Year[];
}
/**
 * Grouping of a school year and its periods.
 *
 * @generated from protobuf message schoolman.GetSchoolYearsResponse.Year
 */
export interface GetSchoolYearsResponse_Year {
  /**
   * The school year
   *
   * @generated from protobuf field: schoolman.SchoolYear year = 1;
   */
  year?: SchoolYear;
  /**
   * The periods for the related school year.
   *
   * @generated from protobuf field: repeated schoolman.SchoolYearPeriod periods = 2;
   */
  periods: SchoolYearPeriod[];
  /**
   * Data about each week between the StartDate and EndDate of the SchoolYear.
   *
   * @generated from protobuf field: repeated schoolman.SchoolYearWeek weeks = 3;
   */
  weeks: SchoolYearWeek[];
}
/**
 * Request for the GetGXODChangedRows method.
 *
 * @generated from protobuf message schoolman.GetGXODChangedRowsRequest
 */
export interface GetGXODChangedRowsRequest {
  /**
   * The school in which to get GXODChangeRows in. If it is not a valid UUID an
   * Unknown error will be returned. If the school does not exist or exists but
   * has no GXODChangedRows set, there will not be an error and a default
   * GXODChangedRows will be returned.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
}
/**
 * Response for the GetGXODChangedRows method.
 *
 * @generated from protobuf message schoolman.GetGXODChangedRowsResponse
 */
export interface GetGXODChangedRowsResponse {
  /**
   * All the fields of the GXODChanged Rows for a school, or a default
   * GXODChangedRows if the school does not exist or does not have one set. The
   * default is:
   *      SchoolID: {GetGXODChangedRowsRequest.SchoolID}
   *      ChangedRowsMap: "{}"
   *      LastImportStatus: SUCCESS
   *      LastImportTimestamp: null
   *
   * @generated from protobuf field: schoolman.GXODChangedRows gxodChangedRows = 1;
   */
  gxodChangedRows?: GXODChangedRows;
}
/**
 * Request for the GetMISTeachingGroups method.
 *
 * @generated from protobuf message schoolman.GetMISTeachingGroupsRequest
 */
export interface GetMISTeachingGroupsRequest {
  /**
   * The school in which to get MISTeachingGroups from. If it is not a valid
   * UUID an Internal error will be returned. If it is empty there will be no
   * filtering on schoolID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Whether to only get MISTeachingGroups for which StudentImports is set as
   * on.
   *
   * @generated from protobuf field: bool onlyStudentImportsOn = 2;
   */
  onlyStudentImportsOn: boolean;
  /**
   * Whether to include expired MISTeachingGroups.
   *
   * @generated from protobuf field: bool includeExpired = 3;
   */
  includeExpired: boolean;
  /**
   * The MIS teaching groups IDs to get MISTeachingGroups for. If it is empty
   * there will be no filtering on misTeachingGroupID.
   *
   * @generated from protobuf field: repeated string misTeachingGroupID = 4;
   */
  misTeachingGroupID: string[];
  /**
   * Whether to include the student memberships of the MISTeachingGroups in the
   * response.
   *
   * @generated from protobuf field: bool includeMemberships = 5;
   */
  includeMemberships: boolean;
  /**
   * Whether to only include teaching groups who have at least one student
   * member.
   *
   * @generated from protobuf field: bool onlyIncludeWithMembers = 6;
   */
  onlyIncludeWithMembers: boolean;
  /**
   * Whether to include year groups as well as teaching groups
   *
   * @generated from protobuf field: bool includeYearGroups = 7;
   */
  includeYearGroups: boolean;
}
/**
 * Response for the GetMISTeachingGroups method.
 *
 * @generated from protobuf message schoolman.GetMISTeachingGroupsResponse
 */
export interface GetMISTeachingGroupsResponse {
  /**
   * @generated from protobuf field: repeated schoolman.MISTeachingGroup misTeachingGroups = 1;
   */
  misTeachingGroups: MISTeachingGroup[];
  /**
   * @generated from protobuf field: repeated schoolman.GetMISTeachingGroupsResponse.MISTeachingGroupStudentMemberships memberships = 2;
   */
  memberships: GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships[];
}
/**
 * @generated from protobuf message schoolman.GetMISTeachingGroupsResponse.MISTeachingGroupStudentMemberships
 */
export interface GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships {
  /**
   * @generated from protobuf field: string misTeachingGroupID = 1;
   */
  misTeachingGroupID: string;
  /**
   * @generated from protobuf field: repeated schoolman.Student students = 2;
   */
  students: Student[];
}
/**
 * @generated from protobuf message schoolman.FieldError
 */
export interface FieldError {
  /**
   * @generated from protobuf field: string field = 1;
   */
  field: string;
  /**
   * @generated from protobuf field: string error = 2;
   */
  error: string;
  /**
   * @generated from protobuf field: string param = 3;
   */
  param: string;
}
/**
 * @generated from protobuf message schoolman.ValidationError
 */
export interface ValidationError {
  /**
   * @generated from protobuf field: repeated schoolman.FieldError errors = 1;
   */
  errors: FieldError[];
}
/**
 * Details about a sparx staff member. This data is fetched from userman in an
 * AuthSparxUser method call.
 *
 * @generated from protobuf message schoolman.SparxStaff
 */
export interface SparxStaff {
  /**
   * @generated from protobuf field: string userID = 1;
   */
  userID: string;
  /**
   * @generated from protobuf field: string firstName = 2;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string lastName = 3;
   */
  lastName: string;
  /**
   * @generated from protobuf field: string email = 4;
   */
  email: string;
}
/**
 * Request for the AuthSparxUser method.
 *
 * @generated from protobuf message schoolman.AuthSparxUserRequest
 */
export interface AuthSparxUserRequest {
  /**
   * The provider to look for users from. If it is not a valid UUID an Internal
   * error will be returned. If the there is no userman User with the passed
   * providerID and userID a NotFound error will be returned.
   *
   * @generated from protobuf field: string providerID = 1;
   */
  providerID: string;
  /**
   * The userman user whose corresponding schoolman users we want to find.
   * If it is not a valid UUID an Internal error
   * will be returned. If the there is no userman User with the passed
   * providerID and userID a NotFound error will be returned.
   *
   * @generated from protobuf field: string userID = 2;
   */
  userID: string;
  /**
   * The school of the schoolman users we want to find.
   * If it is empty schoolman users from any school will be returned.
   * If it is not a valid UUID an Internal error
   * will be returned. If the there is no school with the passed ID there will
   * be a PermissionDenied error.
   *
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * Whether to include inactive (expired) students in the response.
   * If true then the response will include students who are inactive.
   * This is used in ssoProvider in order to distinguish between non-existant
   * and inactive students so we can provide a more useful error to users
   *
   * @generated from protobuf field: bool includeInactiveStudents = 4;
   */
  includeInactiveStudents: boolean;
}
/**
 * @generated from protobuf message schoolman.AuthSparxUserResponse
 */
export interface AuthSparxUserResponse {
  /**
   * The students who's corresponding userman user has the passed userID and
   * providerID. Only active students will be returned. The StudentID,
   * ExternalID, SchoolID, FirstName, LastName, DateOfBirth, Username, Type and
   * InactiveDate of each student are returned.
   *
   * @generated from protobuf field: repeated schoolman.Student students = 1;
   */
  students: Student[];
  /**
   * The staff who's corresponding userman user has the passed userID and
   * providerID. Only active students will be returned. The StaffID, Roles,
   * SchoolID, Title FirstName, LastName, DateOfBirth and Type of each staff
   * member are returned.
   *
   * @generated from protobuf field: repeated schoolman.Staff staff = 2;
   */
  staff: Staff[];
  /**
   * The Sparx Staff who's corresponding userman user has the passed userID and
   * providerID. Sparx Staff are userman users with a specific email suffix
   * who's email has been verified.
   *
   * @generated from protobuf field: repeated schoolman.SparxStaff sparx = 3;
   */
  sparx: SparxStaff[];
}
/**
 * Request for GetStudentXP method.
 *
 * @generated from protobuf message schoolman.GetStudentXPRequest
 */
export interface GetStudentXPRequest {
  /**
   * The school to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, there will be no
   * filtering on school, and the permission:
   *     'read' from 'tp:student' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The studentgroup to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, no filtering on
   * studentgroups will occur. The membership and
   * studentgroup must both be active. If a studentgroup is specified the
   * permission:
   *      'read' from 'tp:studentgroup' in
   *      'schools/{GetStudentXPRequest.SchoolID}'
   * will be required.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * If empty, there will be no filtering on student ID. If non-empty, only
   * students with IDs in the list will be returned. If an item in the list
   * is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * A list of times to get student XP totals at. If the list is empty the
   * response will be empty. For each value in the list, a StudentXPAtTime will
   * added to the response for each student, containing the students total
   * XP at that time.
   *
   * @generated from protobuf field: repeated google.protobuf.Timestamp atTime = 4;
   */
  atTime: Timestamp[];
}
/**
 * @generated from protobuf message schoolman.StudentXPAtTime
 */
export interface StudentXPAtTime {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp when = 2;
   */
  when?: Timestamp;
  /**
   * @generated from protobuf field: int64 amount = 3;
   */
  amount: number;
}
/**
 * @generated from protobuf message schoolman.GetStudentXPResponse
 */
export interface GetStudentXPResponse {
  /**
   * For every active student that passes through the filters in the request,
   * a StudentXPAtTime value will be included for every atTime requested.
   *
   * @generated from protobuf field: repeated schoolman.StudentXPAtTime xp = 1;
   */
  xp: StudentXPAtTime[];
}
/**
 * Request for the GetStudentXPIncFromSource method.
 *
 * @deprecated
 * @generated from protobuf message schoolman.GetStudentXPIncFromSourceRequest
 */
export interface GetStudentXPIncFromSourceRequest {
  /**
   * The school to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, there will be no
   * filtering on school, and the permission:
   *     'read' from 'tp:student' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The studentgroup to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, no filtering on
   * studentgroups will occur. The membership and
   * studentgroup must both be active. If a studentgroup is specified the
   * permission:
   *      'read' from 'tp:studentgroup' in
   *      'schools/{GetStudentXPIncFromSourceRequest.SchoolID}'
   * will be required.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * If empty, there will be no filtering on student ID. If non-empty, only
   * students with IDs in the list will be returned. If an item in the list
   * is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * Only XP updates that occurred after this time will count towards the total
   * in the response. If it is zero all XP updates are included.
   *
   * @generated from protobuf field: google.protobuf.Timestamp since = 4;
   */
  since?: Timestamp;
  /**
   * If this list is non-empty, only XP updates that are from one of the sources
   * are included in the total in the response.
   *
   * @generated from protobuf field: repeated string sources = 5;
   */
  sources: string[];
}
/**
 * @generated from protobuf message schoolman.StudentXPInc
 */
export interface StudentXPInc {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: int64 amount = 2;
   */
  amount: number;
}
/**
 * @deprecated
 * @generated from protobuf message schoolman.GetStudentXPIncFromSourceResponse
 */
export interface GetStudentXPIncFromSourceResponse {
  /**
   * One of these will be returned for every active student that passes through
   * the filters in the request who has received an XP update since the
   * requested time from one of the requested sources. If a student has received
   * no update since the requested time from one of the requested sources there
   * will be no response for them.
   *
   * @generated from protobuf field: repeated schoolman.StudentXPInc xp = 1;
   */
  xp: StudentXPInc[];
}
/**
 * Request for the GetStudentsXPLevels method.
 *
 * @generated from protobuf message schoolman.GetStudentXPLevelsRequest
 */
export interface GetStudentXPLevelsRequest {
  /**
   * The school to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, there will be no
   * filtering on school, and the permission:
   *     'read' from 'tp:student' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The studentgroup to filter the students on. If it is not a valid UUID, an
   * Unknown error will be returned. If it is empty, no filtering on
   * studentgroups will occur. The membership and
   * studentgroup must both be active for the student to be returned.
   *  If a studentgroup is specified the permission:
   *      'read' from 'tp:studentgroup' in
   *      'schools/{GetStudentXPLevelsRequest.SchoolID}'
   * will be required.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * If empty, there will be no filtering on student ID. If non-empty, only
   * students with IDs in the list will be returned. If an item in the list
   * is not a valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * If nil, all level gains will be returned. If not nil, only level gains
   * that occur since this time will be returned.
   *
   * @generated from protobuf field: google.protobuf.Timestamp levelledSince = 4;
   */
  levelledSince?: Timestamp;
}
/**
 * @generated from protobuf message schoolman.StudentLevelGain
 */
export interface StudentLevelGain {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: int32 level = 2;
   */
  level: number;
  /**
   * @generated from protobuf field: int64 xpThreshold = 3;
   */
  xpThreshold: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp when = 4;
   */
  when?: Timestamp;
}
/**
 * @generated from protobuf message schoolman.GetStudentXPLevelsResponse
 */
export interface GetStudentXPLevelsResponse {
  /**
   * For every active student that passes through the
   * filters in the request, one of these will be returned for every level gain
   * that has occurred since the levelledSince request parameter.
   *
   * @generated from protobuf field: repeated schoolman.StudentLevelGain levels = 1;
   */
  levels: StudentLevelGain[];
}
/**
 * @generated from protobuf message schoolman.SendWelcomeEmailRequest
 */
export interface SendWelcomeEmailRequest {
  /**
   * The UUID of the school of the staff member. An Internal error will be
   * returned if the school does not exist or if it is not a valid UUID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The UUID of the staff member. An Internal error will be returned
   * if the staff member does not exist at the school or if it is not a valid
   * UUID.
   *
   * @generated from protobuf field: string staffID = 2;
   */
  staffID: string;
  /**
   * NOT a resetLink but a link back to Teacher Portal. The reset link is
   * now determined from userman.
   *
   * @generated from protobuf field: string resetLink = 3;
   */
  resetLink: string;
  /**
   * The URL that the user is returned too after resetting their password.
   * If the string is empty then it will default to the teacher login
   * for that school.
   *
   * @generated from protobuf field: string returnLink = 4;
   */
  returnLink: string;
}
/**
 * @generated from protobuf message schoolman.SendWelcomeEmailResponse
 */
export interface SendWelcomeEmailResponse {}
/**
 * @generated from protobuf message schoolman.GetStaffPasswordResetLinkRequest
 */
export interface GetStaffPasswordResetLinkRequest {
  /**
   * The UUID of the school of the staff member. An Internal error will be
   * returned if the school does not exist or if it is not a valid UUID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The UUID of the staff member. An Internal error will be returned
   * if the staff member does not exist at the school or if it is not a valid
   * UUID.
   *
   * @generated from protobuf field: string staffID = 2;
   */
  staffID: string;
  /**
   * The URL that the user is returned too after resetting their password.
   * If the string is empty then it will default to the teacher login
   * for that school.
   *
   * @generated from protobuf field: string returnLink = 3;
   */
  returnLink: string;
}
/**
 * @generated from protobuf message schoolman.GetStaffPasswordResetLinkResponse
 */
export interface GetStaffPasswordResetLinkResponse {
  /**
   * The reset password link
   *
   * @generated from protobuf field: string link = 1;
   */
  link: string;
}
/**
 * @generated from protobuf message schoolman.InviteCode
 */
export interface InviteCode {
  /**
   * ID of the student the code is for.
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * This students invite code.
   *
   * @generated from protobuf field: string code = 2;
   */
  code: string;
  /**
   * The time that the invite code expires.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry = 3;
   */
  expiry?: Timestamp;
  /**
   * The number of attempts to activate using this code
   *
   * @generated from protobuf field: int32 attempts = 4;
   */
  attempts: number;
}
/**
 * @generated from protobuf message schoolman.SetInviteCodeRequest
 */
export interface SetInviteCodeRequest {
  /**
   * School of all the students in the invite code requests.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Array of InviteCodes to set. If the message contains a code, then that code
   * is used otherwise a new random one is generated.
   *
   * @generated from protobuf field: repeated schoolman.InviteCode invites = 2;
   */
  invites: InviteCode[];
}
/**
 * @generated from protobuf message schoolman.SetInviteCodeResponse
 */
export interface SetInviteCodeResponse {
  /**
   * Output from generating or setting the invite codes.
   *
   * @generated from protobuf field: repeated schoolman.InviteCode invites = 1;
   */
  invites: InviteCode[];
}
/**
 * Request a new password be created for a student using an invite code. NOTE:
 * This message is populated directly through tpserver, so new fields will be
 * settable over an api call. Therefore care should be taken when adding new
 * fields.
 *
 * @generated from protobuf message schoolman.GetStudentPasswordRequest
 */
export interface GetStudentPasswordRequest {
  /**
   * ID of the school the user is in.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Username of the student. Does not need to include the @school suffix.
   *
   * @generated from protobuf field: string username = 2;
   */
  username: string;
  /**
   * Requested student's invite code.
   *
   * @generated from protobuf field: string inviteCode = 3;
   */
  inviteCode: string;
  /**
   * The student's date of birth.
   *
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 4;
   */
  dateOfBirth?: Timestamp;
  /**
   * The student's first name.
   *
   * @generated from protobuf field: string firstName = 5;
   */
  firstName: string;
  /**
   * The student's last name.
   *
   * @generated from protobuf field: string lastName = 6;
   */
  lastName: string;
}
/**
 * The response from a GetStudentPasswordRequest. NOTE: this message is directly
 * returned to the user through tpserver once the student has authenticated in
 * the request. Therefore care should be taken when adding new fields.
 *
 * @generated from protobuf message schoolman.GetStudentPasswordResponse
 */
export interface GetStudentPasswordResponse {
  /**
   * The new password for the student.
   *
   * @generated from protobuf field: string password = 1;
   */
  password: string;
  /**
   * First name of the student the password is for.
   *
   * @generated from protobuf field: string firstName = 2;
   */
  firstName: string;
  /**
   * Last name of the student the password is for.
   *
   * @generated from protobuf field: string lastName = 3;
   */
  lastName: string;
  /**
   * Username of the student the password is for.
   *
   * @generated from protobuf field: string username = 4;
   */
  username: string;
}
/**
 * @generated from protobuf message schoolman.GetYearGroupsRequest
 */
export interface GetYearGroupsRequest {
  /**
   * The school ID to get the year group for.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Whether to include expired yeargroups.
   *
   * @generated from protobuf field: bool includeExpired = 2;
   */
  includeExpired: boolean;
  /**
   * If empty, there will be no filtering on YEAR_GROUP_V2 year group IDs.
   * If non-empty, only year groups with IDs in the list will be returned.
   *
   * @generated from protobuf field: repeated string yearGroupIDs = 3;
   */
  yearGroupIDs: string[];
}
/**
 * @generated from protobuf message schoolman.GetYearGroupsResponse
 */
export interface GetYearGroupsResponse {
  /**
   * The year groups for the given school ID.
   *
   * @generated from protobuf field: repeated schoolman.YearGroup yearGroups = 1;
   */
  yearGroups: YearGroup[];
}
/**
 * @generated from protobuf message schoolman.GetUserIDForStudentIDRequest
 */
export interface GetUserIDForStudentIDRequest {
  /**
   * @generated from protobuf field: string provider_id = 1;
   */
  providerId: string;
  /**
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
}
/**
 * @generated from protobuf message schoolman.GetUserIDForStudentIDResponse
 */
export interface GetUserIDForStudentIDResponse {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
}
/**
 * @generated from protobuf message schoolman.StudentDetails
 */
export interface StudentDetails {
  /**
   * ID of the student which matches the school and username
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * School the student is in
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * Students username, which may not be the same as the request if fuzzy
   * matching was applied.
   *
   * @generated from protobuf field: string username = 3;
   */
  username: string;
  /**
   * Password the student must have supplied to login
   *
   * @deprecated
   * @generated from protobuf field: string password = 4 [deprecated = true];
   */
  password: string;
  /**
   * First name of the student
   *
   * @generated from protobuf field: string firstName = 5;
   */
  firstName: string;
  /**
   * Last name of the student
   *
   * @generated from protobuf field: string lastName = 6;
   */
  lastName: string;
  /**
   * Type of student this user should login as
   *
   * @generated from protobuf field: schoolman.StudentDetails.StudentType type = 7;
   */
  type: StudentDetails_StudentType;
  /**
   * The timestamp at which the student should not be able to login after.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry = 8;
   */
  expiry?: Timestamp;
  /**
   * Whether lessons is enabled for the particular student based on flags
   * and if they are part of a lessons enabled yeargroup
   *
   * @generated from protobuf field: bool lessonsEnabled = 9;
   */
  lessonsEnabled: boolean;
  /**
   * Whether this student is in a primary yeargroup
   *
   * @generated from protobuf field: bool isPrimaryStudent = 12;
   */
  isPrimaryStudent: boolean;
  /**
   * TotalXP describes the total amount of XP earned to date.
   *
   * @generated from protobuf field: int32 totalXP = 10;
   */
  totalXP: number;
  /**
   * LevelGains contains the date of each increment
   *
   * @generated from protobuf field: repeated google.protobuf.Timestamp levelGains = 11;
   */
  levelGains: Timestamp[];
  /**
   * Whether the student has the tables games timer disabled
   *
   * @generated from protobuf field: bool disableTablesGamesTimer = 13;
   */
  disableTablesGamesTimer: boolean;
  /**
   * Whether bookwork checks are disabled for the student
   *
   * @generated from protobuf field: bool disableBookworkChecks = 14;
   */
  disableBookworkChecks: boolean;
  /**
   * Whether the student is currently blocked. A zero time or null is considered
   * to be false.
   *
   * @generated from protobuf field: google.protobuf.Timestamp blockedAt = 15;
   */
  blockedAt?: Timestamp;
}
/**
 * Type of student this user should login as
 *
 * @generated from protobuf enum schoolman.StudentDetails.StudentType
 */
export enum StudentDetails_StudentType {
  /**
   * @generated from protobuf enum value: STUDENT = 0;
   */
  STUDENT = 0,
  /**
   * @generated from protobuf enum value: TEMPLATE = 1;
   */
  TEMPLATE = 1,
  /**
   * @generated from protobuf enum value: GUEST = 2;
   */
  GUEST = 2,
  /**
   * @generated from protobuf enum value: CLASSROOM_GUEST = 3;
   */
  CLASSROOM_GUEST = 3,
  /**
   * @generated from protobuf enum value: RAPID_ONBOARDING_STUDENT = 4;
   */
  RAPID_ONBOARDING_STUDENT = 4,
}
/**
 * @generated from protobuf message schoolman.GetStudentDetailsRequest
 */
export interface GetStudentDetailsRequest {
  /**
   * The school the student is trying to login to
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf oneof: identifier
   */
  identifier:
    | {
        oneofKind: 'username';
        /**
         * The username the student is trying to login as
         *
         * @generated from protobuf field: string username = 2;
         */
        username: string;
      }
    | {
        oneofKind: 'studentID';
        /**
         * The studentID of the student
         *
         * @generated from protobuf field: string studentID = 3;
         */
        studentID: string;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * Populate XP fields in response
   *
   * @generated from protobuf field: bool includeXP = 4;
   */
  includeXP: boolean;
}
/**
 * @generated from protobuf message schoolman.GetStudentDetailsResponse
 */
export interface GetStudentDetailsResponse {
  /**
   * @generated from protobuf field: schoolman.StudentDetails student = 1;
   */
  student?: StudentDetails;
  /**
   * Whether the student is in an active class
   *
   * @generated from protobuf field: bool canLogin = 2;
   */
  canLogin: boolean;
}
/**
 * @generated from protobuf message schoolman.CreateGuestStudentRequest
 */
export interface CreateGuestStudentRequest {
  /**
   * The school the student is trying to login to
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The student id of the template
   *
   * @generated from protobuf field: string templateID = 2;
   */
  templateID: string;
}
/**
 * @generated from protobuf message schoolman.CreateGuestStudentResponse
 */
export interface CreateGuestStudentResponse {
  /**
   * @generated from protobuf field: schoolman.StudentDetails student = 1;
   */
  student?: StudentDetails;
}
/**
 * @generated from protobuf message schoolman.GetStudentParentEmailStatusRequest
 */
export interface GetStudentParentEmailStatusRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string parentID = 2;
   */
  parentID: string;
}
/**
 * @generated from protobuf message schoolman.StudentParentEmailStatus
 */
export interface StudentParentEmailStatus {
  /**
   * @generated from protobuf field: bool subscribed = 1;
   */
  subscribed: boolean;
}
/**
 * @generated from protobuf message schoolman.SetStudentParentEmailStatusRequest
 */
export interface SetStudentParentEmailStatusRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string parentID = 2;
   */
  parentID: string;
  /**
   * @generated from protobuf field: bool setSubscribedAs = 3;
   */
  setSubscribedAs: boolean;
}
/**
 * StudentExternalID describes student identifiers produced externally to Sparx.
 * These ids are secondary, serving a fallback mechanism to resolve
 * inconsistency with the student's primary external id (which exists on the
 * Student message).
 *
 * @generated from protobuf message schoolman.StudentExternalID
 */
export interface StudentExternalID {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * StudentPrimaryExternalID describes the primary external id of the student.
   * The allows us to upsert fallback external ids without knowing the
   * student's Sparx user id.
   *
   * @generated from protobuf field: string studentPrimaryExternalID = 5;
   */
  studentPrimaryExternalID: string;
  /**
   * Type describes the kind of external id being communicated.
   * Unspecified value is not actually a permissible option here.
   *
   * @generated from protobuf field: schoolman.StudentExternalIDType type = 3;
   */
  type: StudentExternalIDType;
  /**
   * Value contains the external id itself.
   *
   * @generated from protobuf field: string value = 4;
   */
  value: string;
}
/**
 * @generated from protobuf message schoolman.CleanupStudentXPRequest
 */
export interface CleanupStudentXPRequest {}
/**
 * @generated from protobuf message schoolman.CleanupStudentXPResponse
 */
export interface CleanupStudentXPResponse {}
/**
 * @generated from protobuf message schoolman.SchoolAddress
 */
export interface SchoolAddress {
  /**
   * The schools street.
   *
   * @generated from protobuf field: string street = 1;
   */
  street: string;
  /**
   * The schools locality.
   *
   * @generated from protobuf field: string locality = 2;
   */
  locality: string;
  /**
   * The schools town.
   *
   * @generated from protobuf field: string town = 3;
   */
  town: string;
  /**
   * The schools region.
   *
   * @generated from protobuf field: string region = 4;
   */
  region: string;
  /**
   * The schools postcode.
   *
   * @generated from protobuf field: string postcode = 5;
   */
  postcode: string;
  /**
   * The schools country.
   *
   * @generated from protobuf field: string country = 6;
   */
  country: string;
}
/**
 * @generated from protobuf message schoolman.SchoolWondeSubject
 */
export interface SchoolWondeSubject {
  /**
   * @generated from protobuf field: string schoolWondeSubjectID = 1;
   */
  schoolWondeSubjectID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string wondeSubjectName = 3;
   */
  wondeSubjectName: string;
}
/**
 * @generated from protobuf message schoolman.ListSchoolWondeSubjectsRequest
 */
export interface ListSchoolWondeSubjectsRequest {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
}
/**
 * @generated from protobuf message schoolman.ListSchoolWondeSubjectsResponse
 */
export interface ListSchoolWondeSubjectsResponse {
  /**
   * @generated from protobuf field: repeated schoolman.SchoolWondeSubject schoolWondeSubjects = 1;
   */
  schoolWondeSubjects: SchoolWondeSubject[];
}
/**
 * GetStudentResetPasswordRequest - you must provide either firstName, lastName,
 * dateOfBirth and schoolID or alternatively, just a studentID
 *
 * @generated from protobuf message schoolman.GetStudentResetPasswordRequest
 */
export interface GetStudentResetPasswordRequest {
  /**
   * Student's first name (case not important)
   *
   * @generated from protobuf field: string firstName = 1;
   */
  firstName: string;
  /**
   * Student's last name (case not important)
   *
   * @generated from protobuf field: string lastName = 2;
   */
  lastName: string;
  /**
   * Student's date of birth
   *
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 3;
   */
  dateOfBirth?: Timestamp;
  /**
   * School id
   *
   * @generated from protobuf field: string schoolID = 4;
   */
  schoolID: string;
  /**
   * Student id - if this is set, then fields 1-4 are not required
   *
   * @generated from protobuf field: string studentID = 5;
   */
  studentID: string;
}
/**
 * @generated from protobuf message schoolman.GetStudentResetPasswordResponse
 */
export interface GetStudentResetPasswordResponse {
  /**
   * Details of a student's password reset status. If there was not a matching
   * student, this will be nil
   *
   * @generated from protobuf field: schoolman.StudentResetPassword studentResetPassword = 1;
   */
  studentResetPassword?: StudentResetPassword;
}
/**
 * StudentResetPassword gives details of a student's password reset status, used
 * during the password reset process
 *
 * @generated from protobuf message schoolman.StudentResetPassword
 */
export interface StudentResetPassword {
  /**
   * The student ID of the student
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * The student's username
   *
   * @generated from protobuf field: string username = 2;
   */
  username: string;
  /**
   * Time a password was last set for the student - if never set, will be the
   * default/initial value
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordSetAt = 3;
   */
  passwordSetAt?: Timestamp;
  /**
   * Time the student last requested a password reset - if never requested, will
   * be the default/initial value Will be cleared after the reset has been done
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordResetRequestedAt = 4;
   */
  passwordResetRequestedAt?: Timestamp;
  /**
   * Time a password reset was granted (by a teacher) - if never granted, will
   * be the default/initial value Will be cleared after the reset has been done
   *
   * @generated from protobuf field: google.protobuf.Timestamp passwordResetGrantedAt = 5;
   */
  passwordResetGrantedAt?: Timestamp;
  /**
   * Time of the last successful login for the student - will by default if
   * never logged in
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastLoginAt = 6;
   */
  lastLoginAt?: Timestamp;
  /**
   * Number of invite attempts using the previous password handout method -
   * allows us to determine whether they had a password before the new system
   * was turned on
   *
   * @deprecated
   * @generated from protobuf field: int32 invite_attempts = 7 [deprecated = true];
   */
  inviteAttempts: number; // This was used to deal with legacy students, but
  // database has now been fixed

  /**
   * The student's email address
   *
   * @generated from protobuf field: string email_address = 8;
   */
  emailAddress: string;
}
/**
 * @generated from protobuf message schoolman.SetStudentPasswordRequest
 */
export interface SetStudentPasswordRequest {
  /**
   * Student's first name (case not important)
   *
   * @generated from protobuf field: string firstName = 1;
   */
  firstName: string;
  /**
   * Student's last name (case not important)
   *
   * @generated from protobuf field: string lastName = 2;
   */
  lastName: string;
  /**
   * Student's date of birth
   *
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 3;
   */
  dateOfBirth?: Timestamp;
  /**
   * Student's school's school ID
   *
   * @generated from protobuf field: string schoolID = 4;
   */
  schoolID: string;
  /**
   * The new password to set for the student
   *
   * @generated from protobuf field: string password = 5;
   */
  password: string;
}
/**
 * SetStudentPasswordResponse indicates that a student's password has been reset
 *
 * @generated from protobuf message schoolman.SetStudentPasswordResponse
 */
export interface SetStudentPasswordResponse {}
/**
 * @generated from protobuf message schoolman.RequestPasswordResetRequest
 */
export interface RequestPasswordResetRequest {
  /**
   * Student's first name (case not important)
   *
   * @generated from protobuf field: string firstName = 1;
   */
  firstName: string;
  /**
   * Student's last name (case not important)
   *
   * @generated from protobuf field: string lastName = 2;
   */
  lastName: string;
  /**
   * Student's date of birth
   *
   * @generated from protobuf field: google.protobuf.Timestamp dateOfBirth = 3;
   */
  dateOfBirth?: Timestamp;
  /**
   * Student's school's school ID
   *
   * @generated from protobuf field: string schoolID = 4;
   */
  schoolID: string;
}
/**
 * SetStudentPasswordResponse indicates that a reset has been requested
 *
 * @generated from protobuf message schoolman.RequestPasswordResetResponse
 */
export interface RequestPasswordResetResponse {}
/**
 * Request for a teacher to grant a password reset request for the student with
 * the given student id
 *
 * @generated from protobuf message schoolman.GrantPasswordResetRequest
 */
export interface GrantPasswordResetRequest {
  /**
   * @deprecated
   * @generated from protobuf field: string studentID = 1 [deprecated = true];
   */
  studentID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: repeated string studentIDs = 3;
   */
  studentIDs: string[];
}
/**
 * Response from a teacher granting a password reset request for a student,
 * meaning that the request has been granted
 *
 * @generated from protobuf message schoolman.GrantPasswordResetResponse
 */
export interface GrantPasswordResetResponse {
  /**
   * Details of a student's password reset status, after the request was granted
   *
   * @deprecated
   * @generated from protobuf field: schoolman.StudentResetPassword studentResetPassword = 1 [deprecated = true];
   */
  studentResetPassword?: StudentResetPassword;
  /**
   * @generated from protobuf field: repeated schoolman.StudentResetPassword studentResetPasswords = 2;
   */
  studentResetPasswords: StudentResetPassword[];
}
/**
 * Request to set the last login at for a student
 *
 * @generated from protobuf message schoolman.SetStudentLastLoginAtRequest
 */
export interface SetStudentLastLoginAtRequest {
  /**
   * The student ID of the student
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * School ID for the student
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
}
/**
 * SetStudentLastLoginAtResponse indicates that a last login at has been set
 *
 * @generated from protobuf message schoolman.SetStudentLastLoginAtResponse
 */
export interface SetStudentLastLoginAtResponse {}
/**
 * @generated from protobuf message schoolman.SetSchoolYearGroupsRequest
 */
export interface SetSchoolYearGroupsRequest {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: repeated schoolman.YearGroup yearGroups = 2;
   */
  yearGroups: YearGroup[];
}
/**
 * @generated from protobuf message schoolman.SetSchoolYearGroupsResponse
 */
export interface SetSchoolYearGroupsResponse {
  /**
   * The results of the mutations. This list has the same length as
   * the `mutations` list in the `CommitOrgChangesRequest` message
   * this message is a response for.
   *
   * @generated from protobuf field: repeated schoolman.OrgMutationResult results = 1;
   */
  results: OrgMutationResult[];
  /**
   * The results of any additional mutations triggered during this commit
   *
   * @generated from protobuf field: repeated schoolman.OrgMutationResult additionalResults = 2;
   */
  additionalResults: OrgMutationResult[];
}
/**
 * @generated from protobuf message schoolman.StudentRecentGroup
 */
export interface StudentRecentGroup {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
}
/**
 * @generated from protobuf message schoolman.GetStudentsMostRecentGroupRequest
 */
export interface GetStudentsMostRecentGroupRequest {
  /**
   * The student ID of the student
   *
   * @generated from protobuf field: repeated string studentIDs = 1;
   */
  studentIDs: string[];
  /**
   * The school ID for the student
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * The start of the date range
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 3;
   */
  startDate?: Timestamp;
  /**
   * The end of the date range
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 4;
   */
  endDate?: Timestamp;
  /**
   * Yeargroups to exclude. Any groups in these yeargroups will be ignored
   * when determining the most recent joined group.
   * Added due to IM-69 where some groups were set up before rollover and
   * persisted over rollover, resulting in the current group also appearing
   * as the previous group.
   *
   * @generated from protobuf field: repeated string excludeYearGroupIDs = 5;
   */
  excludeYearGroupIDs: string[];
}
/**
 * @generated from protobuf message schoolman.GetStudentsMostRecentGroupResponse
 */
export interface GetStudentsMostRecentGroupResponse {
  /**
   * The students and their most recent groups
   *
   * @generated from protobuf field: repeated schoolman.StudentRecentGroup studentsRecentGroup = 1;
   */
  studentsRecentGroup: StudentRecentGroup[];
}
/**
 * @generated from protobuf message schoolman.CreateSparxUserIdentityRequest
 */
export interface CreateSparxUserIdentityRequest {
  /**
   * The sparx user identity to create
   *
   * @generated from protobuf field: schoolman.SparxUserIdentity sparx_user_identity = 1;
   */
  sparxUserIdentity?: SparxUserIdentity;
}
/**
 * @generated from protobuf message schoolman.CreateSparxUserIdentityResponse
 */
export interface CreateSparxUserIdentityResponse {
  /**
   * The sparx user identity that was created
   *
   * @generated from protobuf field: schoolman.SparxUserIdentity sparx_user_identity = 1;
   */
  sparxUserIdentity?: SparxUserIdentity;
}
/**
 * @generated from protobuf message schoolman.SparxUserIdentity
 */
export interface SparxUserIdentity {
  /**
   * Name of the identity provider
   *
   * @generated from protobuf field: string id_provider = 1;
   */
  idProvider: string;
  /**
   * This is a unique ID for the user. For SSO providers this should be
   * the unique subject from the claims returned by the identity provider.
   * For userman this is just the user id.
   *
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
  /**
   * Sparx user ID that should be linked to the subject
   *
   * @generated from protobuf field: string sparx_user_id = 3;
   */
  sparxUserId: string;
}
/**
 * @generated from protobuf message schoolman.ListSparxUserIdentitiesRequest
 */
export interface ListSparxUserIdentitiesRequest {
  /**
   * Name of the identity provider
   *
   * @generated from protobuf field: string id_provider = 1;
   */
  idProvider: string;
  /**
   * The Sparx user id of the user we want to check
   *
   * @generated from protobuf field: string sparx_user_id = 2;
   */
  sparxUserId: string;
  /**
   * The ID of the user we want to check. E.g. the userman ID if the provider is
   * us or the SSO ID if the provider is Google/Microsoft.
   * Mutually exclusive with sparx_user_id.
   *
   * @generated from protobuf field: string user_id = 3;
   */
  userId: string;
}
/**
 * @generated from protobuf message schoolman.ListSparxUserIdentitiesResponse
 */
export interface ListSparxUserIdentitiesResponse {
  /**
   * The identities found for the request
   *
   * @generated from protobuf field: repeated schoolman.SparxUserIdentity sparx_user_identities = 1;
   */
  sparxUserIdentities: SparxUserIdentity[];
}
/**
 * @generated from protobuf message schoolman.DeleteSparxUserIdentityRequest
 */
export interface DeleteSparxUserIdentityRequest {
  /**
   * Name of the identity provider to unlink from this user
   *
   * @generated from protobuf field: string id_provider = 1;
   */
  idProvider: string;
  /**
   * The Sparx user id of the user we want to unlink an identity from
   *
   * @generated from protobuf field: string sparx_user_id = 2;
   */
  sparxUserId: string;
}
/**
 * @generated from protobuf message schoolman.DeleteSparxUserIdentityResponse
 */
export interface DeleteSparxUserIdentityResponse {}
/**
 * @generated from protobuf message schoolman.BlockUserRequest
 */
export interface BlockUserRequest {
  /**
   * The Sparx user ID to be blocked
   *
   * @generated from protobuf field: string sparxuser_id = 1;
   */
  sparxuserId: string;
  /**
   * Boolean to indicate if the user should be blocked
   *
   * @generated from protobuf field: bool blocked = 2;
   */
  blocked: boolean;
  /**
   * The school ID for the user
   *
   * @generated from protobuf field: string school_id = 3;
   */
  schoolId: string;
}
/**
 * @generated from protobuf enum schoolman.Gender
 */
export enum Gender {
  /**
   * NONE is reserved as a 'do not change' request
   *
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: NEUTRAL = 1;
   */
  NEUTRAL = 1,
  /**
   * @generated from protobuf enum value: MALE = 2;
   */
  MALE = 2,
  /**
   * @generated from protobuf enum value: FEMALE = 3;
   */
  FEMALE = 3,
  /**
   * @generated from protobuf enum value: OTHER = 4;
   */
  OTHER = 4,
  /**
   * @generated from protobuf enum value: UNKNOWN = 5;
   */
  UNKNOWN = 5,
}
/**
 * @generated from protobuf enum schoolman.UsernameMode
 */
export enum UsernameMode {
  /**
   * @generated from protobuf enum value: USERNAME_MODE_UNDEFINED = 0;
   */
  UNDEFINED = 0,
  /**
   * Username will be created in the form `username@school`.
   *
   * @generated from protobuf enum value: USERNAME_MODE_SCHOOL_SUFFIX = 1;
   */
  SCHOOL_SUFFIX = 1,
  /**
   * Username will be created in the form `username` and the username
   * must be an email address.
   *
   * @generated from protobuf enum value: USERNAME_MODE_EMAIL = 2;
   */
  EMAIL = 2,
}
/**
 * @generated from protobuf enum schoolman.StudentGroupType
 */
export enum StudentGroupType {
  /**
   * @generated from protobuf enum value: CLASS = 0;
   */
  CLASS = 0,
  /**
   * @generated from protobuf enum value: TUTORGROUP = 1;
   */
  TUTORGROUP = 1,
  /**
   * @generated from protobuf enum value: MANUAL = 2;
   */
  MANUAL = 2,
  /**
   * @generated from protobuf enum value: GUEST = 3;
   */
  GUEST = 3,
  /**
   * We should separate these out into a 'subject' for a class and then
   * prevent students from being in multiple CLASS for the same subject.
   * As we plan on refactoring this service in the future, I have left
   * this as a separate group type, although it is not scalable for multiple
   * subjects.
   *
   * @generated from protobuf enum value: CLASS_ENGLISH = 4;
   */
  CLASS_ENGLISH = 4,
  /**
   * @generated from protobuf enum value: CLASS_SCIENCE = 5;
   */
  CLASS_SCIENCE = 5,
}
/**
 * @generated from protobuf enum schoolman.SchoolFlag
 */
export enum SchoolFlag {
  /**
   * Make the default NULL
   *
   * @generated from protobuf enum value: NULL = 0;
   */
  NULL = 0,
  /**
   * State
   *
   * The school is being prepared and is not ready to use
   *
   * @generated from protobuf enum value: NOT_READY = 1;
   */
  NOT_READY = 1,
  /**
   * The school has failed to be generated
   *
   * @generated from protobuf enum value: SETUP_FAILED = 2;
   */
  SETUP_FAILED = 2,
  /**
   * Functionality
   *
   * Homework is enabled for the school
   *
   * @generated from protobuf enum value: HOMEWORK = 10;
   */
  HOMEWORK = 10,
  /**
   * Lessons are enabled for the school
   *
   * @generated from protobuf enum value: LESSONS = 11;
   */
  LESSONS = 11,
  /**
   * Sparx Reader is enabled for the school
   *
   * @generated from protobuf enum value: READER = 13;
   */
  READER = 13,
  /**
   * Type
   *
   * The school is a test school
   *
   * @generated from protobuf enum value: TEST = 20;
   */
  TEST = 20,
  /**
   * The school is a demo school
   *
   * @generated from protobuf enum value: DEMO = 21;
   */
  DEMO = 21,
  /**
   * @generated from protobuf enum value: PARTNER = 22;
   */
  PARTNER = 22,
  /**
   * @generated from protobuf enum value: PILOT = 23;
   */
  PILOT = 23,
  /**
   * @generated from protobuf enum value: SOLO = 24;
   */
  SOLO = 24,
  /**
   * Primary school
   *
   * @generated from protobuf enum value: PRIMARY = 25;
   */
  PRIMARY = 25,
  /**
   * The school is trialling Sparx
   *
   * @generated from protobuf enum value: TRIAL = 26;
   */
  TRIAL = 26,
  /**
   * School for TP load testing.  It is not meant to have
   *
   * @generated from protobuf enum value: TP_LOAD_TESTING = 28;
   */
  TP_LOAD_TESTING = 28,
  /**
   * Features
   *
   * Whether the school is a special internal school
   *
   * @generated from protobuf enum value: TEMPLATE_SOL_EDITOR = 34;
   */
  TEMPLATE_SOL_EDITOR = 34,
  /**
   * that can edit template SOLs
   *
   * School has access to v1 of student insights
   *
   * @generated from protobuf enum value: HOMEWORK_INSIGHTS_V1 = 38;
   */
  HOMEWORK_INSIGHTS_V1 = 38,
  /**
   * Prevent parent emails from being sent
   *
   * @generated from protobuf enum value: DISABLE_PARENT_EMAILS = 40;
   */
  DISABLE_PARENT_EMAILS = 40,
  /**
   * Temporary flag to determine whether a school can select non-Sparx
   *
   * @generated from protobuf enum value: CLASSROOM_FLEXIBLE_SOLS = 41;
   */
  CLASSROOM_FLEXIBLE_SOLS = 41,
  /**
   * SOLs for classes in lessons yeargroups - TODO: feature_flag:
   * classroom-flexible-sols
   *
   * Wonde schools only: whether to take legal rather than preferred
   *
   * @generated from protobuf enum value: USE_LEGAL_STUDENT_NAMES = 42;
   */
  USE_LEGAL_STUDENT_NAMES = 42,
  /**
   * student names when syncing with the MIS.
   *
   * Wonde schools only: whether to automatically
   *
   * @generated from protobuf enum value: WONDE_AUTO_SYNC_ENABLED = 43;
   */
  WONDE_AUTO_SYNC_ENABLED = 43,
  /**
   * sync the school data with Wonde.
   *
   *  Wonde schools only: use "code" instead of
   *
   * @generated from protobuf enum value: WONDE_USE_CLASS_CODE = 44;
   */
  WONDE_USE_CLASS_CODE = 44,
}
/**
 * @generated from protobuf enum schoolman.SchoolYearPeriodType
 */
export enum SchoolYearPeriodType {
  /**
   * @generated from protobuf enum value: TERM = 0;
   */
  TERM = 0,
  /**
   * @generated from protobuf enum value: HOLIDAY = 1;
   */
  HOLIDAY = 1,
  /**
   * @generated from protobuf enum value: DATACYCLE = 2;
   */
  DATACYCLE = 2,
}
/**
 * StudentExternalIDType describes the kind of external id being communicated.
 *
 * @generated from protobuf enum schoolman.StudentExternalIDType
 */
export enum StudentExternalIDType {
  /**
   * @generated from protobuf enum value: SXIDTYPE_UNSPECIFIED = 0;
   */
  SXIDTYPE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SXIDTYPE_UPN = 1;
   */
  SXIDTYPE_UPN = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
  constructor() {
    super('schoolman.HealthCheckRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType<HealthCheckResponse> {
  constructor() {
    super('schoolman.HealthCheckResponse', [
      { no: 1, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrgMutation$Type extends MessageType<OrgMutation> {
  constructor() {
    super('schoolman.OrgMutation', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.OrgMutation.Type', OrgMutation_Type],
      },
      {
        no: 2,
        name: 'student',
        kind: 'message',
        oneof: 'entity',
        T: () => Student,
      },
      {
        no: 3,
        name: 'group',
        kind: 'message',
        oneof: 'entity',
        T: () => StudentGroup,
      },
      {
        no: 5,
        name: 'staff',
        kind: 'message',
        oneof: 'entity',
        T: () => Staff,
      },
      {
        no: 6,
        name: 'studentClassMembership',
        kind: 'message',
        oneof: 'entity',
        T: () => StudentClassMembership,
      },
      {
        no: 7,
        name: 'staffClassMembership',
        kind: 'message',
        oneof: 'entity',
        T: () => StaffClassMembership,
      },
      {
        no: 8,
        name: 'schoolYear',
        kind: 'message',
        oneof: 'entity',
        T: () => SchoolYear,
      },
      {
        no: 9,
        name: 'parent',
        kind: 'message',
        oneof: 'entity',
        T: () => Parent,
      },
      {
        no: 10,
        name: 'studentParent',
        kind: 'message',
        oneof: 'entity',
        T: () => StudentParent,
      },
      {
        no: 11,
        name: 'gxodChangedRows',
        kind: 'message',
        oneof: 'entity',
        T: () => GXODChangedRows,
      },
      {
        no: 13,
        name: 'schoolYearPeriod',
        kind: 'message',
        oneof: 'entity',
        T: () => SchoolYearPeriod,
      },
      {
        no: 14,
        name: 'misTeachingGroup',
        kind: 'message',
        oneof: 'entity',
        T: () => MISTeachingGroup,
      },
      {
        no: 15,
        name: 'yearGroup',
        kind: 'message',
        oneof: 'entity',
        T: () => YearGroup,
      },
      {
        no: 16,
        name: 'studentXP',
        kind: 'message',
        oneof: 'entity',
        T: () => StudentXP,
      },
      {
        no: 17,
        name: 'studentExternalID',
        kind: 'message',
        oneof: 'entity',
        T: () => StudentExternalID,
      },
      {
        no: 18,
        name: 'schoolWondeSubject',
        kind: 'message',
        oneof: 'entity',
        T: () => SchoolWondeSubject,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.OrgMutation
 */
export const OrgMutation = new OrgMutation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrgMutationResult$Type extends MessageType<OrgMutationResult> {
  constructor() {
    super('schoolman.OrgMutationResult', [
      { no: 1, name: 'entityId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.OrgMutationResult
 */
export const OrgMutationResult = new OrgMutationResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommitOrgChangesRequest$Type extends MessageType<CommitOrgChangesRequest> {
  constructor() {
    super('schoolman.CommitOrgChangesRequest', [
      {
        no: 1,
        name: 'mutations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OrgMutation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CommitOrgChangesRequest
 */
export const CommitOrgChangesRequest = new CommitOrgChangesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommitOrgChangesResponse$Type extends MessageType<CommitOrgChangesResponse> {
  constructor() {
    super('schoolman.CommitOrgChangesResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OrgMutationResult,
      },
      {
        no: 2,
        name: 'additionalResults',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OrgMutationResult,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CommitOrgChangesResponse
 */
export const CommitOrgChangesResponse = new CommitOrgChangesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Student$Type extends MessageType<Student> {
  constructor() {
    super('schoolman.Student', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'externalID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'gender',
        kind: 'enum',
        T: () => ['schoolman.Gender', Gender],
      },
      { no: 7, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 10,
        name: 'homeworkLength',
        kind: 'enum',
        T: () => ['schoolman.Student.HomeworkLength', Student_HomeworkLength],
      },
      { no: 11, name: 'inactiveDate', kind: 'message', T: () => Timestamp },
      {
        no: 12,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.Student.Type', Student_Type],
      },
      {
        no: 13,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: 'parents',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Parent,
      },
      { no: 15, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 16, name: 'invite', kind: 'message', T: () => InviteCode },
      {
        no: 18,
        name: 'assessmentResults',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentResult,
      },
      { no: 19, name: 'fsmEligible', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 20, name: 'isEAL', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 21,
        name: 'pupilPremium',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 22,
        name: 'mutationFromMISImport',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 23,
        name: 'fallbackExternalIDs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentExternalID,
      },
      { no: 24, name: 'passwordSetAt', kind: 'message', T: () => Timestamp },
      {
        no: 25,
        name: 'passwordResetRequestedAt',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 26,
        name: 'passwordResetGrantedAt',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 27, name: 'lastLoginAt', kind: 'message', T: () => Timestamp },
      {
        no: 28,
        name: 'learningPathSpecOverride',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 29,
        name: 'emailAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 30,
        name: 'clearEmailAddress',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 31,
        name: 'excludeHigherOnlyTopics',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 32,
        name: 'disableTablesGamesTimer',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 33,
        name: 'tablesTaskEnabledOverride',
        kind: 'message',
        T: () => TablesTaskEnabledOverride,
      },
      {
        no: 34,
        name: 'disableBookworkChecks',
        kind: 'message',
        T: () => BoolValue,
      },
      { no: 35, name: 'blockedAt', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.Student
 */
export const Student = new Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TablesTaskEnabledOverride$Type extends MessageType<TablesTaskEnabledOverride> {
  constructor() {
    super('schoolman.TablesTaskEnabledOverride', [
      { no: 1, name: 'enabled', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 2,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.TablesTaskEnabledOverride
 */
export const TablesTaskEnabledOverride = new TablesTaskEnabledOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXP$Type extends MessageType<StudentXP> {
  constructor() {
    super('schoolman.StudentXP', [
      {
        no: 1,
        name: 'StudentID',
        kind: 'scalar',
        jsonName: 'StudentID',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'SchoolID',
        kind: 'scalar',
        jsonName: 'SchoolID',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentXP
 */
export const StudentXP = new StudentXP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentGroup$Type extends MessageType<StudentGroup> {
  constructor() {
    super('schoolman.StudentGroup', [
      {
        no: 1,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.StudentGroupType', StudentGroupType],
      },
      { no: 6, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'endDate', kind: 'message', T: () => Timestamp },
      {
        no: 8,
        name: 'homeworkEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'misTeachingGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'attainmentLevel',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 11,
        name: 'yearGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'externalID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'classroomAttainmentLevel',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      { no: 4, name: 'yearGroup', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 20,
        name: 'staff',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffClassMembership,
      },
      {
        no: 21,
        name: 'misTeachingGroup',
        kind: 'message',
        T: () => MISTeachingGroup,
      },
      {
        no: 22,
        name: 'homeworkEnabledAt',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 23, name: 'locked', kind: 'message', T: () => BoolValue },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentGroup
 */
export const StudentGroup = new StudentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class YearGroup$Type extends MessageType<YearGroup> {
  constructor() {
    super('schoolman.YearGroup', [
      {
        no: 1,
        name: 'yearGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'legacyYearGroup',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'lessonsEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 6, name: 'expiryTime', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'product',
        kind: 'enum',
        T: () => ['schoolman.YearGroup.Product', YearGroup_Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.YearGroup
 */
export const YearGroup = new YearGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School$Type extends MessageType<School> {
  constructor() {
    super('schoolman.School', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'urlName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'games',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'replyToAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'schoolFlags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['schoolman.SchoolFlag', SchoolFlag],
      },
      { no: 8, name: 'expiryTime', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'establishmentID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 10, name: 'createdDate', kind: 'message', T: () => Timestamp },
      {
        no: 11,
        name: 'edubaseEstablishmentID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 13, name: 'timeZone', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 14,
        name: 'misStatus',
        kind: 'enum',
        T: () => ['schoolman.School.MISStatus', School_MISStatus],
      },
      { no: 15, name: 'wondeID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 16,
        name: 'wondeDomain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'schoolAddress',
        kind: 'message',
        T: () => SchoolAddress,
      },
      {
        no: 18,
        name: 'lastSuccessfulSync',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 4,
        name: 'yeargroups',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 12,
        name: 'lessonsYeargroups',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.School
 */
export const School = new School$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYear$Type extends MessageType<SchoolYear> {
  constructor() {
    super('schoolman.SchoolYear', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'homeworkStartDate', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'homeworkEndDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SchoolYear
 */
export const SchoolYear = new SchoolYear$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYearPeriod$Type extends MessageType<SchoolYearPeriod> {
  constructor() {
    super('schoolman.SchoolYearPeriod', [
      {
        no: 1,
        name: 'schoolYearPeriodID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.SchoolYearPeriodType', SchoolYearPeriodType],
      },
      { no: 6, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'endDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SchoolYearPeriod
 */
export const SchoolYearPeriod = new SchoolYearPeriod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYearWeek$Type extends MessageType<SchoolYearWeek> {
  constructor() {
    super('schoolman.SchoolYearWeek', [
      { no: 1, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'dataCycle', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'weekIndexInDataCycle',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'schoolYearPeriodIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'canSetHomework',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SchoolYearWeek
 */
export const SchoolYearWeek = new SchoolYearWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Staff$Type extends MessageType<Staff> {
  constructor() {
    super('schoolman.Staff', [
      { no: 1, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'emailAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'gender',
        kind: 'enum',
        T: () => ['schoolman.Gender', Gender],
      },
      { no: 8, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 10, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 11,
        name: 'usernameMode',
        kind: 'enum',
        T: () => ['schoolman.UsernameMode', UsernameMode, 'USERNAME_MODE_'],
      },
      {
        no: 20,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 21,
        name: 'updateUsername',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 22,
        name: 'denyProductAccess',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 100,
        name: 'resetPassword',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.Staff
 */
export const Staff = new Staff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentClassMembership$Type extends MessageType<StudentClassMembership> {
  constructor() {
    super('schoolman.StudentClassMembership', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'deleteAllForStudent',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'externalStudentID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentClassMembership
 */
export const StudentClassMembership = new StudentClassMembership$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffClassMembership$Type extends MessageType<StaffClassMembership> {
  constructor() {
    super('schoolman.StaffClassMembership', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'feedbackEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 5, name: 'startDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StaffClassMembership
 */
export const StaffClassMembership = new StaffClassMembership$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Parent$Type extends MessageType<Parent> {
  constructor() {
    super('schoolman.Parent', [
      { no: 1, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'externalID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 12, name: 'deleteTime', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'emailAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'feedbackEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'hasUnsubscribed',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 11,
        name: 'studentEmailEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 8,
        name: 'gender',
        kind: 'enum',
        T: () => ['schoolman.Gender', Gender],
      },
      {
        no: 13,
        name: 'hasUnsubscribedStatus',
        kind: 'enum',
        T: () => [
          'schoolman.Parent.HasUnsubscribedStatus',
          Parent_HasUnsubscribedStatus,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.Parent
 */
export const Parent = new Parent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentParent$Type extends MessageType<StudentParent> {
  constructor() {
    super('schoolman.StudentParent', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'externalStudentID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'externalParentID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'studentEmailEnabled',
        kind: 'message',
        T: () => BoolValue,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentParent
 */
export const StudentParent = new StudentParent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GXODChangedRows$Type extends MessageType<GXODChangedRows> {
  constructor() {
    super('schoolman.GXODChangedRows', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'changedRowsMap',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'lastImportStatus',
        kind: 'enum',
        T: () => [
          'schoolman.GXODChangedRows.ImportStatus',
          GXODChangedRows_ImportStatus,
        ],
      },
      {
        no: 4,
        name: 'lastImportTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 5,
        name: 'lastImportMIS',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'clearLastImportMIS',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GXODChangedRows
 */
export const GXODChangedRows = new GXODChangedRows$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MISTeachingGroup$Type extends MessageType<MISTeachingGroup> {
  constructor() {
    super('schoolman.MISTeachingGroup', [
      {
        no: 1,
        name: 'misTeachingGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'externalID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'subjectName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'staffInitials',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'studentImportStatus',
        kind: 'enum',
        T: () => [
          'schoolman.MISTeachingGroup.StudentImportStatus',
          MISTeachingGroup_StudentImportStatus,
        ],
      },
      { no: 8, name: 'expiryDate', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'studentExternalIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'type',
        kind: 'enum',
        T: () => [
          'schoolman.MISTeachingGroup.MISTeachingGroupType',
          MISTeachingGroup_MISTeachingGroupType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.MISTeachingGroup
 */
export const MISTeachingGroup = new MISTeachingGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffRole$Type extends MessageType<StaffRole> {
  constructor() {
    super('schoolman.StaffRole', [
      { no: 1, name: 'role', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.StaffRole
 */
export const StaffRole = new StaffRole$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolsRequest$Type extends MessageType<GetSchoolsRequest> {
  constructor() {
    super('schoolman.GetSchoolsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolsRequest
 */
export const GetSchoolsRequest = new GetSchoolsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolsResponse$Type extends MessageType<GetSchoolsResponse> {
  constructor() {
    super('schoolman.GetSchoolsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolsResponse
 */
export const GetSchoolsResponse = new GetSchoolsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentsRequest$Type extends MessageType<GetStudentsRequest> {
  constructor() {
    super('schoolman.GetStudentsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'yeargroup', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'nogroup', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 6,
        name: 'includeParents',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 19,
        name: 'includeDeletedParents',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 7, name: 'noclass', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'includeInactive',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 9, name: 'when', kind: 'message', T: () => Timestamp },
      {
        no: 10,
        name: 'externalID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'includeAllStudentTypes',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'yearGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'includeFallbackExternalIDs',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 14, name: 'expired_before', kind: 'message', T: () => Timestamp },
      {
        no: 15,
        name: 'ignoreGroupMemberships',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 16,
        name: 'includeInactiveClassroomGuests',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 17,
        name: 'emailAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'ignoreSchoolExpiry',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentsRequest
 */
export const GetStudentsRequest = new GetStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentsResponse$Type extends MessageType<GetStudentsResponse> {
  constructor() {
    super('schoolman.GetStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentsResponse
 */
export const GetStudentsResponse = new GetStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParentsRequest$Type extends MessageType<GetParentsRequest> {
  constructor() {
    super('schoolman.GetParentsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'includeDeleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 3, name: 'deleted_before', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetParentsRequest
 */
export const GetParentsRequest = new GetParentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParentsResponse$Type extends MessageType<GetParentsResponse> {
  constructor() {
    super('schoolman.GetParentsResponse', [
      {
        no: 1,
        name: 'parents',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Parent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetParentsResponse
 */
export const GetParentsResponse = new GetParentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentParentsRequest$Type extends MessageType<GetStudentParentsRequest> {
  constructor() {
    super('schoolman.GetStudentParentsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentParentsRequest
 */
export const GetStudentParentsRequest = new GetStudentParentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentList$Type extends MessageType<ParentList> {
  constructor() {
    super('schoolman.ParentList', [
      {
        no: 1,
        name: 'parent',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Parent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ParentList
 */
export const ParentList = new ParentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentParentsResponse$Type extends MessageType<GetStudentParentsResponse> {
  constructor() {
    super('schoolman.GetStudentParentsResponse', [
      {
        no: 1,
        name: 'parent_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => ParentList },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentParentsResponse
 */
export const GetStudentParentsResponse = new GetStudentParentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsRequest$Type extends MessageType<SearchStudentsRequest> {
  constructor() {
    super('schoolman.SearchStudentsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'query', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SearchStudentsRequest
 */
export const SearchStudentsRequest = new SearchStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsResponse$Type extends MessageType<SearchStudentsResponse> {
  constructor() {
    super('schoolman.SearchStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SearchStudentsResponse
 */
export const SearchStudentsResponse = new SearchStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRequest$Type extends MessageType<GetStaffRequest> {
  constructor() {
    super('schoolman.GetStaffRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'staffID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'emailAddress',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStaffRequest
 */
export const GetStaffRequest = new GetStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffResponse$Type extends MessageType<GetStaffResponse> {
  constructor() {
    super('schoolman.GetStaffResponse', [
      {
        no: 1,
        name: 'staff',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Staff,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStaffResponse
 */
export const GetStaffResponse = new GetStaffResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRolesRequest$Type extends MessageType<GetStaffRolesRequest> {
  constructor() {
    super('schoolman.GetStaffRolesRequest', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStaffRolesRequest
 */
export const GetStaffRolesRequest = new GetStaffRolesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffRolesResponse$Type extends MessageType<GetStaffRolesResponse> {
  constructor() {
    super('schoolman.GetStaffRolesResponse', [
      {
        no: 1,
        name: 'roles',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffRole,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStaffRolesResponse
 */
export const GetStaffRolesResponse = new GetStaffRolesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupsRequest$Type extends MessageType<GetStudentGroupsRequest> {
  constructor() {
    super('schoolman.GetStudentGroupsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'includeInactive',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 7, name: 'includeStaff', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'when', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'includeMISTeachingGroup',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentGroupsRequest
 */
export const GetStudentGroupsRequest = new GetStudentGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupsResponse$Type extends MessageType<GetStudentGroupsResponse> {
  constructor() {
    super('schoolman.GetStudentGroupsResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentGroupsResponse
 */
export const GetStudentGroupsResponse = new GetStudentGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearRequest$Type extends MessageType<GetSchoolYearRequest> {
  constructor() {
    super('schoolman.GetSchoolYearRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'current', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'includeWeeks', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolYearRequest
 */
export const GetSchoolYearRequest = new GetSchoolYearRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearResponse$Type extends MessageType<GetSchoolYearResponse> {
  constructor() {
    super('schoolman.GetSchoolYearResponse', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
      { no: 2, name: 'year', kind: 'message', T: () => SchoolYear },
      {
        no: 3,
        name: 'periods',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYearPeriod,
      },
      {
        no: 4,
        name: 'weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYearWeek,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolYearResponse
 */
export const GetSchoolYearResponse = new GetSchoolYearResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearsRequest$Type extends MessageType<GetSchoolYearsRequest> {
  constructor() {
    super('schoolman.GetSchoolYearsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'includeWeeks', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolYearsRequest
 */
export const GetSchoolYearsRequest = new GetSchoolYearsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearsResponse$Type extends MessageType<GetSchoolYearsResponse> {
  constructor() {
    super('schoolman.GetSchoolYearsResponse', [
      {
        no: 1,
        name: 'years',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetSchoolYearsResponse_Year,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolYearsResponse
 */
export const GetSchoolYearsResponse = new GetSchoolYearsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolYearsResponse_Year$Type extends MessageType<GetSchoolYearsResponse_Year> {
  constructor() {
    super('schoolman.GetSchoolYearsResponse.Year', [
      { no: 1, name: 'year', kind: 'message', T: () => SchoolYear },
      {
        no: 2,
        name: 'periods',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYearPeriod,
      },
      {
        no: 3,
        name: 'weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolYearWeek,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetSchoolYearsResponse.Year
 */
export const GetSchoolYearsResponse_Year =
  new GetSchoolYearsResponse_Year$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGXODChangedRowsRequest$Type extends MessageType<GetGXODChangedRowsRequest> {
  constructor() {
    super('schoolman.GetGXODChangedRowsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetGXODChangedRowsRequest
 */
export const GetGXODChangedRowsRequest = new GetGXODChangedRowsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGXODChangedRowsResponse$Type extends MessageType<GetGXODChangedRowsResponse> {
  constructor() {
    super('schoolman.GetGXODChangedRowsResponse', [
      {
        no: 1,
        name: 'gxodChangedRows',
        kind: 'message',
        T: () => GXODChangedRows,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetGXODChangedRowsResponse
 */
export const GetGXODChangedRowsResponse = new GetGXODChangedRowsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMISTeachingGroupsRequest$Type extends MessageType<GetMISTeachingGroupsRequest> {
  constructor() {
    super('schoolman.GetMISTeachingGroupsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'onlyStudentImportsOn',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'includeExpired',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'misTeachingGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'includeMemberships',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'onlyIncludeWithMembers',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'includeYearGroups',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetMISTeachingGroupsRequest
 */
export const GetMISTeachingGroupsRequest =
  new GetMISTeachingGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMISTeachingGroupsResponse$Type extends MessageType<GetMISTeachingGroupsResponse> {
  constructor() {
    super('schoolman.GetMISTeachingGroupsResponse', [
      {
        no: 1,
        name: 'misTeachingGroups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MISTeachingGroup,
      },
      {
        no: 2,
        name: 'memberships',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () =>
          GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetMISTeachingGroupsResponse
 */
export const GetMISTeachingGroupsResponse =
  new GetMISTeachingGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships$Type extends MessageType<GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships> {
  constructor() {
    super(
      'schoolman.GetMISTeachingGroupsResponse.MISTeachingGroupStudentMemberships',
      [
        {
          no: 1,
          name: 'misTeachingGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'students',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Student,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetMISTeachingGroupsResponse.MISTeachingGroupStudentMemberships
 */
export const GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships =
  new GetMISTeachingGroupsResponse_MISTeachingGroupStudentMemberships$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldError$Type extends MessageType<FieldError> {
  constructor() {
    super('schoolman.FieldError', [
      { no: 1, name: 'field', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'error', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'param', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.FieldError
 */
export const FieldError = new FieldError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidationError$Type extends MessageType<ValidationError> {
  constructor() {
    super('schoolman.ValidationError', [
      {
        no: 1,
        name: 'errors',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FieldError,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ValidationError
 */
export const ValidationError = new ValidationError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxStaff$Type extends MessageType<SparxStaff> {
  constructor() {
    super('schoolman.SparxStaff', [
      { no: 1, name: 'userID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SparxStaff
 */
export const SparxStaff = new SparxStaff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthSparxUserRequest$Type extends MessageType<AuthSparxUserRequest> {
  constructor() {
    super('schoolman.AuthSparxUserRequest', [
      { no: 1, name: 'providerID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'userID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'includeInactiveStudents',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.AuthSparxUserRequest
 */
export const AuthSparxUserRequest = new AuthSparxUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthSparxUserResponse$Type extends MessageType<AuthSparxUserResponse> {
  constructor() {
    super('schoolman.AuthSparxUserResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
      {
        no: 2,
        name: 'staff',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Staff,
      },
      {
        no: 3,
        name: 'sparx',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SparxStaff,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.AuthSparxUserResponse
 */
export const AuthSparxUserResponse = new AuthSparxUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPRequest$Type extends MessageType<GetStudentXPRequest> {
  constructor() {
    super('schoolman.GetStudentXPRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'atTime',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentXPRequest
 */
export const GetStudentXPRequest = new GetStudentXPRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPAtTime$Type extends MessageType<StudentXPAtTime> {
  constructor() {
    super('schoolman.StudentXPAtTime', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'when', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'amount',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentXPAtTime
 */
export const StudentXPAtTime = new StudentXPAtTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPResponse$Type extends MessageType<GetStudentXPResponse> {
  constructor() {
    super('schoolman.GetStudentXPResponse', [
      {
        no: 1,
        name: 'xp',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentXPAtTime,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentXPResponse
 */
export const GetStudentXPResponse = new GetStudentXPResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPIncFromSourceRequest$Type extends MessageType<GetStudentXPIncFromSourceRequest> {
  constructor() {
    super('schoolman.GetStudentXPIncFromSourceRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'since', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'sources',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStudentXPIncFromSourceRequest
 */
export const GetStudentXPIncFromSourceRequest =
  new GetStudentXPIncFromSourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPInc$Type extends MessageType<StudentXPInc> {
  constructor() {
    super('schoolman.StudentXPInc', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'amount',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentXPInc
 */
export const StudentXPInc = new StudentXPInc$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPIncFromSourceResponse$Type extends MessageType<GetStudentXPIncFromSourceResponse> {
  constructor() {
    super('schoolman.GetStudentXPIncFromSourceResponse', [
      {
        no: 1,
        name: 'xp',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentXPInc,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message schoolman.GetStudentXPIncFromSourceResponse
 */
export const GetStudentXPIncFromSourceResponse =
  new GetStudentXPIncFromSourceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPLevelsRequest$Type extends MessageType<GetStudentXPLevelsRequest> {
  constructor() {
    super('schoolman.GetStudentXPLevelsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'levelledSince', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentXPLevelsRequest
 */
export const GetStudentXPLevelsRequest = new GetStudentXPLevelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentLevelGain$Type extends MessageType<StudentLevelGain> {
  constructor() {
    super('schoolman.StudentLevelGain', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'xpThreshold',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 4, name: 'when', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentLevelGain
 */
export const StudentLevelGain = new StudentLevelGain$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPLevelsResponse$Type extends MessageType<GetStudentXPLevelsResponse> {
  constructor() {
    super('schoolman.GetStudentXPLevelsResponse', [
      {
        no: 1,
        name: 'levels',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentLevelGain,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentXPLevelsResponse
 */
export const GetStudentXPLevelsResponse = new GetStudentXPLevelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendWelcomeEmailRequest$Type extends MessageType<SendWelcomeEmailRequest> {
  constructor() {
    super('schoolman.SendWelcomeEmailRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'resetLink', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'returnLink', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SendWelcomeEmailRequest
 */
export const SendWelcomeEmailRequest = new SendWelcomeEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendWelcomeEmailResponse$Type extends MessageType<SendWelcomeEmailResponse> {
  constructor() {
    super('schoolman.SendWelcomeEmailResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SendWelcomeEmailResponse
 */
export const SendWelcomeEmailResponse = new SendWelcomeEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffPasswordResetLinkRequest$Type extends MessageType<GetStaffPasswordResetLinkRequest> {
  constructor() {
    super('schoolman.GetStaffPasswordResetLinkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'returnLink', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStaffPasswordResetLinkRequest
 */
export const GetStaffPasswordResetLinkRequest =
  new GetStaffPasswordResetLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffPasswordResetLinkResponse$Type extends MessageType<GetStaffPasswordResetLinkResponse> {
  constructor() {
    super('schoolman.GetStaffPasswordResetLinkResponse', [
      { no: 1, name: 'link', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStaffPasswordResetLinkResponse
 */
export const GetStaffPasswordResetLinkResponse =
  new GetStaffPasswordResetLinkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteCode$Type extends MessageType<InviteCode> {
  constructor() {
    super('schoolman.InviteCode', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'expiry', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'attempts', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.InviteCode
 */
export const InviteCode = new InviteCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetInviteCodeRequest$Type extends MessageType<SetInviteCodeRequest> {
  constructor() {
    super('schoolman.SetInviteCodeRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'invites',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => InviteCode,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetInviteCodeRequest
 */
export const SetInviteCodeRequest = new SetInviteCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetInviteCodeResponse$Type extends MessageType<SetInviteCodeResponse> {
  constructor() {
    super('schoolman.SetInviteCodeResponse', [
      {
        no: 1,
        name: 'invites',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => InviteCode,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetInviteCodeResponse
 */
export const SetInviteCodeResponse = new SetInviteCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentPasswordRequest$Type extends MessageType<GetStudentPasswordRequest> {
  constructor() {
    super('schoolman.GetStudentPasswordRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'inviteCode', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentPasswordRequest
 */
export const GetStudentPasswordRequest = new GetStudentPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentPasswordResponse$Type extends MessageType<GetStudentPasswordResponse> {
  constructor() {
    super('schoolman.GetStudentPasswordResponse', [
      { no: 1, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentPasswordResponse
 */
export const GetStudentPasswordResponse = new GetStudentPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYearGroupsRequest$Type extends MessageType<GetYearGroupsRequest> {
  constructor() {
    super('schoolman.GetYearGroupsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'includeExpired',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'yearGroupIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetYearGroupsRequest
 */
export const GetYearGroupsRequest = new GetYearGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYearGroupsResponse$Type extends MessageType<GetYearGroupsResponse> {
  constructor() {
    super('schoolman.GetYearGroupsResponse', [
      {
        no: 1,
        name: 'yearGroups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => YearGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetYearGroupsResponse
 */
export const GetYearGroupsResponse = new GetYearGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIDForStudentIDRequest$Type extends MessageType<GetUserIDForStudentIDRequest> {
  constructor() {
    super('schoolman.GetUserIDForStudentIDRequest', [
      {
        no: 1,
        name: 'provider_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetUserIDForStudentIDRequest
 */
export const GetUserIDForStudentIDRequest =
  new GetUserIDForStudentIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIDForStudentIDResponse$Type extends MessageType<GetUserIDForStudentIDResponse> {
  constructor() {
    super('schoolman.GetUserIDForStudentIDResponse', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetUserIDForStudentIDResponse
 */
export const GetUserIDForStudentIDResponse =
  new GetUserIDForStudentIDResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentDetails$Type extends MessageType<StudentDetails> {
  constructor() {
    super('schoolman.StudentDetails', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'type',
        kind: 'enum',
        T: () => [
          'schoolman.StudentDetails.StudentType',
          StudentDetails_StudentType,
        ],
      },
      { no: 8, name: 'expiry', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'lessonsEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'isPrimaryStudent',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 10, name: 'totalXP', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 11,
        name: 'levelGains',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Timestamp,
      },
      {
        no: 13,
        name: 'disableTablesGamesTimer',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 14,
        name: 'disableBookworkChecks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 15, name: 'blockedAt', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentDetails
 */
export const StudentDetails = new StudentDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentDetailsRequest$Type extends MessageType<GetStudentDetailsRequest> {
  constructor() {
    super('schoolman.GetStudentDetailsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'username',
        kind: 'scalar',
        oneof: 'identifier',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        oneof: 'identifier',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'includeXP', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentDetailsRequest
 */
export const GetStudentDetailsRequest = new GetStudentDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentDetailsResponse$Type extends MessageType<GetStudentDetailsResponse> {
  constructor() {
    super('schoolman.GetStudentDetailsResponse', [
      { no: 1, name: 'student', kind: 'message', T: () => StudentDetails },
      { no: 2, name: 'canLogin', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentDetailsResponse
 */
export const GetStudentDetailsResponse = new GetStudentDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateGuestStudentRequest$Type extends MessageType<CreateGuestStudentRequest> {
  constructor() {
    super('schoolman.CreateGuestStudentRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'templateID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CreateGuestStudentRequest
 */
export const CreateGuestStudentRequest = new CreateGuestStudentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateGuestStudentResponse$Type extends MessageType<CreateGuestStudentResponse> {
  constructor() {
    super('schoolman.CreateGuestStudentResponse', [
      { no: 1, name: 'student', kind: 'message', T: () => StudentDetails },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CreateGuestStudentResponse
 */
export const CreateGuestStudentResponse = new CreateGuestStudentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentParentEmailStatusRequest$Type extends MessageType<GetStudentParentEmailStatusRequest> {
  constructor() {
    super('schoolman.GetStudentParentEmailStatusRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentParentEmailStatusRequest
 */
export const GetStudentParentEmailStatusRequest =
  new GetStudentParentEmailStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentParentEmailStatus$Type extends MessageType<StudentParentEmailStatus> {
  constructor() {
    super('schoolman.StudentParentEmailStatus', [
      { no: 1, name: 'subscribed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentParentEmailStatus
 */
export const StudentParentEmailStatus = new StudentParentEmailStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStudentParentEmailStatusRequest$Type extends MessageType<SetStudentParentEmailStatusRequest> {
  constructor() {
    super('schoolman.SetStudentParentEmailStatusRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'setSubscribedAs',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetStudentParentEmailStatusRequest
 */
export const SetStudentParentEmailStatusRequest =
  new SetStudentParentEmailStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentExternalID$Type extends MessageType<StudentExternalID> {
  constructor() {
    super('schoolman.StudentExternalID', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'studentPrimaryExternalID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.StudentExternalIDType', StudentExternalIDType],
      },
      { no: 4, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentExternalID
 */
export const StudentExternalID = new StudentExternalID$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanupStudentXPRequest$Type extends MessageType<CleanupStudentXPRequest> {
  constructor() {
    super('schoolman.CleanupStudentXPRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CleanupStudentXPRequest
 */
export const CleanupStudentXPRequest = new CleanupStudentXPRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanupStudentXPResponse$Type extends MessageType<CleanupStudentXPResponse> {
  constructor() {
    super('schoolman.CleanupStudentXPResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CleanupStudentXPResponse
 */
export const CleanupStudentXPResponse = new CleanupStudentXPResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolAddress$Type extends MessageType<SchoolAddress> {
  constructor() {
    super('schoolman.SchoolAddress', [
      { no: 1, name: 'street', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'locality', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'town', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'region', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'postcode', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'country', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SchoolAddress
 */
export const SchoolAddress = new SchoolAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolWondeSubject$Type extends MessageType<SchoolWondeSubject> {
  constructor() {
    super('schoolman.SchoolWondeSubject', [
      {
        no: 1,
        name: 'schoolWondeSubjectID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'wondeSubjectName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SchoolWondeSubject
 */
export const SchoolWondeSubject = new SchoolWondeSubject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolWondeSubjectsRequest$Type extends MessageType<ListSchoolWondeSubjectsRequest> {
  constructor() {
    super('schoolman.ListSchoolWondeSubjectsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ListSchoolWondeSubjectsRequest
 */
export const ListSchoolWondeSubjectsRequest =
  new ListSchoolWondeSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolWondeSubjectsResponse$Type extends MessageType<ListSchoolWondeSubjectsResponse> {
  constructor() {
    super('schoolman.ListSchoolWondeSubjectsResponse', [
      {
        no: 1,
        name: 'schoolWondeSubjects',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolWondeSubject,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ListSchoolWondeSubjectsResponse
 */
export const ListSchoolWondeSubjectsResponse =
  new ListSchoolWondeSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentResetPasswordRequest$Type extends MessageType<GetStudentResetPasswordRequest> {
  constructor() {
    super('schoolman.GetStudentResetPasswordRequest', [
      { no: 1, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentResetPasswordRequest
 */
export const GetStudentResetPasswordRequest =
  new GetStudentResetPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentResetPasswordResponse$Type extends MessageType<GetStudentResetPasswordResponse> {
  constructor() {
    super('schoolman.GetStudentResetPasswordResponse', [
      {
        no: 1,
        name: 'studentResetPassword',
        kind: 'message',
        T: () => StudentResetPassword,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentResetPasswordResponse
 */
export const GetStudentResetPasswordResponse =
  new GetStudentResetPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentResetPassword$Type extends MessageType<StudentResetPassword> {
  constructor() {
    super('schoolman.StudentResetPassword', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'passwordSetAt', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'passwordResetRequestedAt',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 5,
        name: 'passwordResetGrantedAt',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 6, name: 'lastLoginAt', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'invite_attempts',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentResetPassword
 */
export const StudentResetPassword = new StudentResetPassword$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStudentPasswordRequest$Type extends MessageType<SetStudentPasswordRequest> {
  constructor() {
    super('schoolman.SetStudentPasswordRequest', [
      { no: 1, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetStudentPasswordRequest
 */
export const SetStudentPasswordRequest = new SetStudentPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStudentPasswordResponse$Type extends MessageType<SetStudentPasswordResponse> {
  constructor() {
    super('schoolman.SetStudentPasswordResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetStudentPasswordResponse
 */
export const SetStudentPasswordResponse = new SetStudentPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestPasswordResetRequest$Type extends MessageType<RequestPasswordResetRequest> {
  constructor() {
    super('schoolman.RequestPasswordResetRequest', [
      { no: 1, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'dateOfBirth', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.RequestPasswordResetRequest
 */
export const RequestPasswordResetRequest =
  new RequestPasswordResetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestPasswordResetResponse$Type extends MessageType<RequestPasswordResetResponse> {
  constructor() {
    super('schoolman.RequestPasswordResetResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.RequestPasswordResetResponse
 */
export const RequestPasswordResetResponse =
  new RequestPasswordResetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantPasswordResetRequest$Type extends MessageType<GrantPasswordResetRequest> {
  constructor() {
    super('schoolman.GrantPasswordResetRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GrantPasswordResetRequest
 */
export const GrantPasswordResetRequest = new GrantPasswordResetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantPasswordResetResponse$Type extends MessageType<GrantPasswordResetResponse> {
  constructor() {
    super('schoolman.GrantPasswordResetResponse', [
      {
        no: 1,
        name: 'studentResetPassword',
        kind: 'message',
        T: () => StudentResetPassword,
      },
      {
        no: 2,
        name: 'studentResetPasswords',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentResetPassword,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GrantPasswordResetResponse
 */
export const GrantPasswordResetResponse = new GrantPasswordResetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStudentLastLoginAtRequest$Type extends MessageType<SetStudentLastLoginAtRequest> {
  constructor() {
    super('schoolman.SetStudentLastLoginAtRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetStudentLastLoginAtRequest
 */
export const SetStudentLastLoginAtRequest =
  new SetStudentLastLoginAtRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStudentLastLoginAtResponse$Type extends MessageType<SetStudentLastLoginAtResponse> {
  constructor() {
    super('schoolman.SetStudentLastLoginAtResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetStudentLastLoginAtResponse
 */
export const SetStudentLastLoginAtResponse =
  new SetStudentLastLoginAtResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSchoolYearGroupsRequest$Type extends MessageType<SetSchoolYearGroupsRequest> {
  constructor() {
    super('schoolman.SetSchoolYearGroupsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'yearGroups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => YearGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetSchoolYearGroupsRequest
 */
export const SetSchoolYearGroupsRequest = new SetSchoolYearGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSchoolYearGroupsResponse$Type extends MessageType<SetSchoolYearGroupsResponse> {
  constructor() {
    super('schoolman.SetSchoolYearGroupsResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OrgMutationResult,
      },
      {
        no: 2,
        name: 'additionalResults',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OrgMutationResult,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SetSchoolYearGroupsResponse
 */
export const SetSchoolYearGroupsResponse =
  new SetSchoolYearGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentRecentGroup$Type extends MessageType<StudentRecentGroup> {
  constructor() {
    super('schoolman.StudentRecentGroup', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.StudentRecentGroup
 */
export const StudentRecentGroup = new StudentRecentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentsMostRecentGroupRequest$Type extends MessageType<GetStudentsMostRecentGroupRequest> {
  constructor() {
    super('schoolman.GetStudentsMostRecentGroupRequest', [
      {
        no: 1,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'endDate', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'excludeYearGroupIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentsMostRecentGroupRequest
 */
export const GetStudentsMostRecentGroupRequest =
  new GetStudentsMostRecentGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentsMostRecentGroupResponse$Type extends MessageType<GetStudentsMostRecentGroupResponse> {
  constructor() {
    super('schoolman.GetStudentsMostRecentGroupResponse', [
      {
        no: 1,
        name: 'studentsRecentGroup',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentRecentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.GetStudentsMostRecentGroupResponse
 */
export const GetStudentsMostRecentGroupResponse =
  new GetStudentsMostRecentGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSparxUserIdentityRequest$Type extends MessageType<CreateSparxUserIdentityRequest> {
  constructor() {
    super('schoolman.CreateSparxUserIdentityRequest', [
      {
        no: 1,
        name: 'sparx_user_identity',
        kind: 'message',
        T: () => SparxUserIdentity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CreateSparxUserIdentityRequest
 */
export const CreateSparxUserIdentityRequest =
  new CreateSparxUserIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSparxUserIdentityResponse$Type extends MessageType<CreateSparxUserIdentityResponse> {
  constructor() {
    super('schoolman.CreateSparxUserIdentityResponse', [
      {
        no: 1,
        name: 'sparx_user_identity',
        kind: 'message',
        T: () => SparxUserIdentity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.CreateSparxUserIdentityResponse
 */
export const CreateSparxUserIdentityResponse =
  new CreateSparxUserIdentityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxUserIdentity$Type extends MessageType<SparxUserIdentity> {
  constructor() {
    super('schoolman.SparxUserIdentity', [
      {
        no: 1,
        name: 'id_provider',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'sparx_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.SparxUserIdentity
 */
export const SparxUserIdentity = new SparxUserIdentity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSparxUserIdentitiesRequest$Type extends MessageType<ListSparxUserIdentitiesRequest> {
  constructor() {
    super('schoolman.ListSparxUserIdentitiesRequest', [
      {
        no: 1,
        name: 'id_provider',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'sparx_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ListSparxUserIdentitiesRequest
 */
export const ListSparxUserIdentitiesRequest =
  new ListSparxUserIdentitiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSparxUserIdentitiesResponse$Type extends MessageType<ListSparxUserIdentitiesResponse> {
  constructor() {
    super('schoolman.ListSparxUserIdentitiesResponse', [
      {
        no: 1,
        name: 'sparx_user_identities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SparxUserIdentity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.ListSparxUserIdentitiesResponse
 */
export const ListSparxUserIdentitiesResponse =
  new ListSparxUserIdentitiesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSparxUserIdentityRequest$Type extends MessageType<DeleteSparxUserIdentityRequest> {
  constructor() {
    super('schoolman.DeleteSparxUserIdentityRequest', [
      {
        no: 1,
        name: 'id_provider',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'sparx_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.DeleteSparxUserIdentityRequest
 */
export const DeleteSparxUserIdentityRequest =
  new DeleteSparxUserIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSparxUserIdentityResponse$Type extends MessageType<DeleteSparxUserIdentityResponse> {
  constructor() {
    super('schoolman.DeleteSparxUserIdentityResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.DeleteSparxUserIdentityResponse
 */
export const DeleteSparxUserIdentityResponse =
  new DeleteSparxUserIdentityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlockUserRequest$Type extends MessageType<BlockUserRequest> {
  constructor() {
    super('schoolman.BlockUserRequest', [
      {
        no: 1,
        name: 'sparxuser_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'blocked', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message schoolman.BlockUserRequest
 */
export const BlockUserRequest = new BlockUserRequest$Type();
/**
 * @generated ServiceType for protobuf service schoolman.SchoolMan
 */
export const SchoolMan = new ServiceType('schoolman.SchoolMan', [
  {
    name: 'LiveCheck',
    options: { 'google.api.http': { get: '/healthz/live' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'ReadyCheck',
    options: { 'google.api.http': { get: '/healthz/ready' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'CommitOrgChanges',
    options: {
      'google.api.http': { post: '/schoolman/v1/org_changes', body: '*' },
    },
    I: CommitOrgChangesRequest,
    O: CommitOrgChangesResponse,
  },
  {
    name: 'GetSchools',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/schools',
        body: '*',
        additionalBindings: [{ get: '/schoolman/v1/school' }],
      },
    },
    I: GetSchoolsRequest,
    O: GetSchoolsResponse,
  },
  {
    name: 'GetStudents',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/students',
        body: '*',
        additionalBindings: [{ get: '/schoolman/v1/student' }],
      },
    },
    I: GetStudentsRequest,
    O: GetStudentsResponse,
  },
  {
    name: 'GetParents',
    options: {
      'google.api.http': { post: '/schoolman/v1/parents', body: '*' },
    },
    I: GetParentsRequest,
    O: GetParentsResponse,
  },
  {
    name: 'GetStudentParents',
    options: {
      'google.api.http': { post: '/schoolman/v1/student_parents', body: '*' },
    },
    I: GetStudentParentsRequest,
    O: GetStudentParentsResponse,
  },
  {
    name: 'SearchStudents',
    options: {},
    I: SearchStudentsRequest,
    O: SearchStudentsResponse,
  },
  {
    name: 'GetStaff',
    options: { 'google.api.http': { post: '/schoolman/v1/staff', body: '*' } },
    I: GetStaffRequest,
    O: GetStaffResponse,
  },
  {
    name: 'GetStaffRoles',
    options: {},
    I: GetStaffRolesRequest,
    O: GetStaffRolesResponse,
  },
  {
    name: 'GetStudentGroups',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/student_groups',
        body: '*',
        additionalBindings: [{ get: '/schoolman/v1/studentgroup' }],
      },
    },
    I: GetStudentGroupsRequest,
    O: GetStudentGroupsResponse,
  },
  {
    name: 'GetSchoolYear',
    options: {
      'google.api.http': { post: '/schoolman/v1/school_year', body: '*' },
    },
    I: GetSchoolYearRequest,
    O: GetSchoolYearResponse,
  },
  {
    name: 'GetSchoolYears',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/school_years',
        body: '*',
        additionalBindings: [{ get: '/schoolman/v1/schoolyear' }],
      },
    },
    I: GetSchoolYearsRequest,
    O: GetSchoolYearsResponse,
  },
  {
    name: 'GetGXODChangedRows',
    options: {
      'google.api.http': { post: '/schoolman/v1/gxod_changed_rows', body: '*' },
    },
    I: GetGXODChangedRowsRequest,
    O: GetGXODChangedRowsResponse,
  },
  {
    name: 'GetMISTeachingGroups',
    options: {},
    I: GetMISTeachingGroupsRequest,
    O: GetMISTeachingGroupsResponse,
  },
  {
    name: 'AuthSparxUser',
    options: {},
    I: AuthSparxUserRequest,
    O: AuthSparxUserResponse,
  },
  {
    name: 'GetStudentXP',
    options: {},
    I: GetStudentXPRequest,
    O: GetStudentXPResponse,
  },
  {
    name: 'GetStudentXPIncFromSource',
    options: {},
    I: GetStudentXPIncFromSourceRequest,
    O: GetStudentXPIncFromSourceResponse,
  },
  {
    name: 'GetStudentXPLevels',
    options: {},
    I: GetStudentXPLevelsRequest,
    O: GetStudentXPLevelsResponse,
  },
  {
    name: 'SendWelcomeEmail',
    options: {},
    I: SendWelcomeEmailRequest,
    O: SendWelcomeEmailResponse,
  },
  {
    name: 'GetStaffPasswordResetLink',
    options: {},
    I: GetStaffPasswordResetLinkRequest,
    O: GetStaffPasswordResetLinkResponse,
  },
  {
    name: 'SetInviteCode',
    options: {
      'google.api.http': { post: '/schoolman/v1/set_invite_code', body: '*' },
    },
    I: SetInviteCodeRequest,
    O: SetInviteCodeResponse,
  },
  {
    name: 'GetStudentPassword',
    options: {},
    I: GetStudentPasswordRequest,
    O: GetStudentPasswordResponse,
  },
  {
    name: 'GetYearGroups',
    options: { 'google.api.http': { get: '/schoolman/v1/get_year_groups' } },
    I: GetYearGroupsRequest,
    O: GetYearGroupsResponse,
  },
  {
    name: 'GetUserIDForStudentID',
    options: {},
    I: GetUserIDForStudentIDRequest,
    O: GetUserIDForStudentIDResponse,
  },
  {
    name: 'GetStudentDetails',
    options: {},
    I: GetStudentDetailsRequest,
    O: GetStudentDetailsResponse,
  },
  {
    name: 'CreateGuestStudent',
    options: {},
    I: CreateGuestStudentRequest,
    O: CreateGuestStudentResponse,
  },
  {
    name: 'GetStudentParentEmailStatus',
    options: {},
    I: GetStudentParentEmailStatusRequest,
    O: StudentParentEmailStatus,
  },
  {
    name: 'SetStudentParentEmailStatus',
    options: {},
    I: SetStudentParentEmailStatusRequest,
    O: StudentParentEmailStatus,
  },
  {
    name: 'CleanupStudentXP',
    options: {
      'google.api.http': { post: '/schoolman/v1/studentxp:cleanup', body: '*' },
    },
    I: CleanupStudentXPRequest,
    O: CleanupStudentXPResponse,
  },
  {
    name: 'ListSchoolWondeSubjects',
    options: {},
    I: ListSchoolWondeSubjectsRequest,
    O: ListSchoolWondeSubjectsResponse,
  },
  {
    name: 'GetStudentResetPassword',
    options: {},
    I: GetStudentResetPasswordRequest,
    O: GetStudentResetPasswordResponse,
  },
  {
    name: 'SetStudentPassword',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/set_student_password',
        body: '*',
      },
    },
    I: SetStudentPasswordRequest,
    O: SetStudentPasswordResponse,
  },
  {
    name: 'RequestPasswordReset',
    options: {},
    I: RequestPasswordResetRequest,
    O: RequestPasswordResetResponse,
  },
  {
    name: 'GrantPasswordReset',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/grant_password_reset',
        body: '*',
      },
    },
    I: GrantPasswordResetRequest,
    O: GrantPasswordResetResponse,
  },
  {
    name: 'SetStudentLastLoginAt',
    options: {},
    I: SetStudentLastLoginAtRequest,
    O: SetStudentLastLoginAtResponse,
  },
  {
    name: 'SetSchoolYearGroups',
    options: {
      'google.api.http': {
        post: '/schoolman/v1/set_school_year_groups',
        body: '*',
      },
    },
    I: SetSchoolYearGroupsRequest,
    O: SetSchoolYearGroupsResponse,
  },
  {
    name: 'GetStudentsMostRecentGroup',
    options: {
      'google.api.http': { get: '/schoolman/v1/get_most_recent_group' },
    },
    I: GetStudentsMostRecentGroupRequest,
    O: GetStudentsMostRecentGroupResponse,
  },
  { name: 'BlockUser', options: {}, I: BlockUserRequest, O: Empty },
]);
/**
 * @generated ServiceType for protobuf service schoolman.Identity
 */
export const Identity = new ServiceType('schoolman.Identity', [
  {
    name: 'CreateSparxUserIdentity',
    options: {},
    I: CreateSparxUserIdentityRequest,
    O: CreateSparxUserIdentityResponse,
  },
  {
    name: 'ListSparxUserIdentities',
    options: {},
    I: ListSparxUserIdentitiesRequest,
    O: ListSparxUserIdentitiesResponse,
  },
  {
    name: 'DeleteSparxUserIdentity',
    options: {},
    I: DeleteSparxUserIdentityRequest,
    O: DeleteSparxUserIdentityResponse,
  },
]);
