// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/interaction/feedback/v1/feedback.proto" (package "sparx.interaction.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Product } from '../../../types/product';
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitContentFeedbackRequest
 */
export interface SubmitContentFeedbackRequest {
  /**
   * A user entered comment containing the feedback. May be blank.
   *
   * @generated from protobuf field: string comment = 1;
   */
  comment: string;
  /**
   * The context of the page in teacher portal the user is on.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.TeacherPortalContext tp = 2;
   */
  tp?: TeacherPortalContext;
  /**
   * The content the user is giving feedback on, when known.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.ContentContext content = 3;
   */
  content?: ContentContext;
  /**
   * If the feedback is given in context of a homework, this describes that
   * homework.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.HomeworkContext homework = 4;
   */
  homework?: HomeworkContext;
  /**
   * The product the feedback was given in. If UNKNOWN assume Maths.
   *
   * @generated from protobuf field: sparx.types.Product product = 5;
   */
  product: Product;
  /**
   * If the feedback is given in context of a science homework, this describes that homework
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.ScienceAssignmentContext science_assignment = 6;
   */
  scienceAssignment?: ScienceAssignmentContext;
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitContentFeedbackResponse
 */
export interface SubmitContentFeedbackResponse {}
/**
 * TeacherPortalContext describes information about the page in teacherportal
 * the user is on when submitting the feedback.
 * N.B. Information about the submitter is explicitly excluded from this API, as
 * it is read from their authorization instead.
 *
 * @generated from protobuf message sparx.interaction.feedback.v1.TeacherPortalContext
 */
export interface TeacherPortalContext {
  /**
   * The friendly name of the page from where the feedback request was made.
   *
   * @generated from protobuf field: string page_name = 1;
   */
  pageName: string;
  /**
   * The URL of the page from where the feedback request was made.
   *
   * @generated from protobuf field: string page_url = 2;
   */
  pageUrl: string;
  /**
   * An identifier for the button or action the user triggered this feedback
   * request with.
   *
   * @generated from protobuf field: string trigger_name = 3;
   */
  triggerName: string;
  /**
   * The client application version.
   *
   * @generated from protobuf field: string application_version = 4;
   */
  applicationVersion: string;
  /**
   * The resource name for the school.
   *
   * @generated from protobuf field: string school_name = 5;
   */
  schoolName: string;
}
/**
 * ContentContext describes information about the content being given feedback
 * on.
 *
 * @generated from protobuf message sparx.interaction.feedback.v1.ContentContext
 */
export interface ContentContext {
  /**
   * Optional. The relevant curriculum resource name. Should not be supplied if
   * topic or learning path name is supplied.
   *
   * @generated from protobuf field: string curriculum_name = 1;
   */
  curriculumName: string;
  /**
   * Optional. The relevant topic resource name. Should not be supplied if
   * learning path name is supplied.
   *
   * @generated from protobuf field: string topic_name = 2;
   */
  topicName: string;
  /**
   * Optional. The relevant learning path resource name.
   *
   * @generated from protobuf field: string learning_path_name = 3;
   */
  learningPathName: string;
  /**
   * Optional. The relevant learning unit (objective) resource name.
   *
   * @generated from protobuf field: string learning_unit_name = 4;
   */
  learningUnitName: string;
  /**
   * Optional. The relevant skill resource name. Should not be supplied if
   * question name is supplied.
   *
   * @generated from protobuf field: string skill_name = 5;
   */
  skillName: string;
  /**
   * Optional. The relevant question resource name.
   *
   * @generated from protobuf field: string question_name = 6;
   */
  questionName: string;
}
/**
 * HomeworkContext describes information about the current homework being given
 * feedback on.
 *
 * @generated from protobuf message sparx.interaction.feedback.v1.HomeworkContext
 */
export interface HomeworkContext {
  /**
   * The id of the relevant homework
   *
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
  /**
   * The id of the relevant package. Should not be supplied if task item name
   * is supplied.
   *
   * @generated from protobuf field: string package_id = 2;
   */
  packageId: string;
  /**
   * The id of the relevant student
   *
   * @generated from protobuf field: string student_id = 3;
   */
  studentId: string;
  /**
   * The activity index of the relevant answer. Deprecated because it's not
   * unique enough.
   *
   * @deprecated
   * @generated from protobuf field: int64 activity_index = 4 [deprecated = true];
   */
  activityIndex: number;
  /**
   * The activity id of the relevant answer.
   *
   * @generated from protobuf field: string activity_id = 5;
   */
  activityId: string;
}
/**
 * ScienceAssignmentContext describes information about the current assignment being
 * given feedback on.
 *
 * @generated from protobuf message sparx.interaction.feedback.v1.ScienceAssignmentContext
 */
export interface ScienceAssignmentContext {
  /**
   * The assignment ID
   *
   * @generated from protobuf field: string assignment_id = 1;
   */
  assignmentId: string;
  /**
   * The task item resource name
   *
   * @generated from protobuf field: string task_item_name = 2;
   */
  taskItemName: string;
  /**
   * The task title
   *
   * @generated from protobuf field: string task_title = 3;
   */
  taskTitle: string;
  /**
   * The user Id of the package
   *
   * @generated from protobuf field: string user_id = 4;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitUserRatingFeedbackRequest
 */
export interface SubmitUserRatingFeedbackRequest {
  /**
   * The event or interaction the user is rating
   *
   * @generated from protobuf field: string event = 1;
   */
  event: string;
  /**
   * The users rating for feedback.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.Rating rating = 2;
   */
  rating?: Rating;
  /**
   * Additional context from the event or interaction
   *
   * @generated from protobuf field: map<string, string> labels = 3;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * A user entered comment containing the feedback. May be blank.
   *
   * @generated from protobuf field: string comment = 4;
   */
  comment: string;
  /**
   * The context of the page in teacher portal the user is on.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.TeacherPortalContext tp = 5;
   */
  tp?: TeacherPortalContext;
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitUserRatingFeedbackResponse
 */
export interface SubmitUserRatingFeedbackResponse {}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.Rating
 */
export interface Rating {
  /**
   * The rating given by the user.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.OpinionRating opinion = 1;
   */
  opinion: OpinionRating;
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitGeneralFeedbackRequest
 */
export interface SubmitGeneralFeedbackRequest {
  /**
   * Feedback entered by the user
   *
   * @generated from protobuf field: string comment = 1;
   */
  comment: string;
  /**
   * The context of the page in teacher portal the user is on.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.TeacherPortalContext tp = 2;
   */
  tp?: TeacherPortalContext;
  /**
   * Additional context from the event or interaction
   *
   * @generated from protobuf field: map<string, string> labels = 3;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitGeneralFeedbackResponse
 */
export interface SubmitGeneralFeedbackResponse {}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackRequest
 */
export interface SubmitUserSentimentFeedbackRequest {
  /**
   * The users sentiment for feedback.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.SentimentRating sentiment = 1;
   */
  sentiment: SentimentRating;
  /**
   * A user entered comment containing the feedback. May be blank.
   *
   * @generated from protobuf field: string comment = 2;
   */
  comment: string;
  /**
   * The context of the page in teacher portal the user is on.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.TeacherPortalContext tp = 3;
   */
  tp?: TeacherPortalContext;
  /**
   * Additional context from the event or interaction
   *
   * @generated from protobuf field: map<string, string> labels = 4;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackResponse
 */
export interface SubmitUserSentimentFeedbackResponse {}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest
 */
export interface SubmitStudentSentimentFeedbackRequest {
  /**
   * The users sentiment for feedback.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.SentimentRating sentiment = 1;
   */
  sentiment: SentimentRating;
  /**
   * A user entered comment containing the feedback.
   *
   * @generated from protobuf field: string comment = 2;
   */
  comment: string;
  /**
   * The context of the page in teacher portal the user is on, if the feedback is from teacher portal
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.SparxWebContext sw = 3;
   */
  sw?: SparxWebContext;
  /**
   * Additional context from the event or interaction
   *
   * @generated from protobuf field: map<string, string> labels = 4;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * The product the feedback was given in. If UNKNOWN assume Maths.
   *
   * @generated from protobuf field: sparx.types.Product product = 5;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse
 */
export interface SubmitStudentSentimentFeedbackResponse {}
/**
 * SparxWebContext describes information about the state of sparxweb when the feedback is sent.
 * N.B. Information about the submitter is explicitly excluded from this API, as
 * it is read from their authorization instead.
 *
 * @generated from protobuf message sparx.interaction.feedback.v1.SparxWebContext
 */
export interface SparxWebContext {
  /**
   * The client application version.
   *
   * @generated from protobuf field: string application_version = 1;
   */
  applicationVersion: string;
  /**
   * The resource name for the school.
   *
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
  /**
   * Type of feedback given
   *
   * @generated from protobuf field: string feedback_type = 3;
   */
  feedbackType: string;
  /**
   * Optional. The URL of the page from where the feedback request was made.
   *
   * @generated from protobuf field: string page_url = 4;
   */
  pageUrl: string;
}
/**
 * @generated from protobuf enum sparx.interaction.feedback.v1.OpinionRating
 */
export enum OpinionRating {
  /**
   * @generated from protobuf enum value: OPINION_RATING_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: OPINION_RATING_DISLIKE = 1;
   */
  DISLIKE = 1,
  /**
   * @generated from protobuf enum value: OPINION_RATING_LIKE = 2;
   */
  LIKE = 2,
}
/**
 * @generated from protobuf enum sparx.interaction.feedback.v1.SentimentRating
 */
export enum SentimentRating {
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_HATE = 1;
   */
  HATE = 1,
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_DISLIKE = 2;
   */
  DISLIKE = 2,
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_NEUTRAL = 3;
   */
  NEUTRAL = 3,
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_LIKE = 4;
   */
  LIKE = 4,
  /**
   * @generated from protobuf enum value: SENTIMENT_RATING_LOVE = 5;
   */
  LOVE = 5,
}
// @generated message type with reflection information, may provide speed optimized methods
class SubmitContentFeedbackRequest$Type extends MessageType<SubmitContentFeedbackRequest> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitContentFeedbackRequest', [
      { no: 1, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'tp', kind: 'message', T: () => TeacherPortalContext },
      { no: 3, name: 'content', kind: 'message', T: () => ContentContext },
      { no: 4, name: 'homework', kind: 'message', T: () => HomeworkContext },
      {
        no: 5,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
      {
        no: 6,
        name: 'science_assignment',
        kind: 'message',
        T: () => ScienceAssignmentContext,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitContentFeedbackRequest
 */
export const SubmitContentFeedbackRequest =
  new SubmitContentFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitContentFeedbackResponse$Type extends MessageType<SubmitContentFeedbackResponse> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitContentFeedbackResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitContentFeedbackResponse
 */
export const SubmitContentFeedbackResponse =
  new SubmitContentFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeacherPortalContext$Type extends MessageType<TeacherPortalContext> {
  constructor() {
    super('sparx.interaction.feedback.v1.TeacherPortalContext', [
      { no: 1, name: 'page_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'trigger_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'application_version',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.TeacherPortalContext
 */
export const TeacherPortalContext = new TeacherPortalContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentContext$Type extends MessageType<ContentContext> {
  constructor() {
    super('sparx.interaction.feedback.v1.ContentContext', [
      {
        no: 1,
        name: 'curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'learning_path_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'learning_unit_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.ContentContext
 */
export const ContentContext = new ContentContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkContext$Type extends MessageType<HomeworkContext> {
  constructor() {
    super('sparx.interaction.feedback.v1.HomeworkContext', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'activity_index',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.HomeworkContext
 */
export const HomeworkContext = new HomeworkContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScienceAssignmentContext$Type extends MessageType<ScienceAssignmentContext> {
  constructor() {
    super('sparx.interaction.feedback.v1.ScienceAssignmentContext', [
      {
        no: 1,
        name: 'assignment_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'task_title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.ScienceAssignmentContext
 */
export const ScienceAssignmentContext = new ScienceAssignmentContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserRatingFeedbackRequest$Type extends MessageType<SubmitUserRatingFeedbackRequest> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitUserRatingFeedbackRequest', [
      { no: 1, name: 'event', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'rating', kind: 'message', T: () => Rating },
      {
        no: 3,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 4, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'tp', kind: 'message', T: () => TeacherPortalContext },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitUserRatingFeedbackRequest
 */
export const SubmitUserRatingFeedbackRequest =
  new SubmitUserRatingFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserRatingFeedbackResponse$Type extends MessageType<SubmitUserRatingFeedbackResponse> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitUserRatingFeedbackResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitUserRatingFeedbackResponse
 */
export const SubmitUserRatingFeedbackResponse =
  new SubmitUserRatingFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rating$Type extends MessageType<Rating> {
  constructor() {
    super('sparx.interaction.feedback.v1.Rating', [
      {
        no: 1,
        name: 'opinion',
        kind: 'enum',
        T: () => [
          'sparx.interaction.feedback.v1.OpinionRating',
          OpinionRating,
          'OPINION_RATING_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.Rating
 */
export const Rating = new Rating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitGeneralFeedbackRequest$Type extends MessageType<SubmitGeneralFeedbackRequest> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitGeneralFeedbackRequest', [
      { no: 1, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'tp', kind: 'message', T: () => TeacherPortalContext },
      {
        no: 3,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitGeneralFeedbackRequest
 */
export const SubmitGeneralFeedbackRequest =
  new SubmitGeneralFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitGeneralFeedbackResponse$Type extends MessageType<SubmitGeneralFeedbackResponse> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitGeneralFeedbackResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitGeneralFeedbackResponse
 */
export const SubmitGeneralFeedbackResponse =
  new SubmitGeneralFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserSentimentFeedbackRequest$Type extends MessageType<SubmitUserSentimentFeedbackRequest> {
  constructor() {
    super('sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackRequest', [
      {
        no: 1,
        name: 'sentiment',
        kind: 'enum',
        T: () => [
          'sparx.interaction.feedback.v1.SentimentRating',
          SentimentRating,
          'SENTIMENT_RATING_',
        ],
      },
      { no: 2, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'tp', kind: 'message', T: () => TeacherPortalContext },
      {
        no: 4,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackRequest
 */
export const SubmitUserSentimentFeedbackRequest =
  new SubmitUserSentimentFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserSentimentFeedbackResponse$Type extends MessageType<SubmitUserSentimentFeedbackResponse> {
  constructor() {
    super(
      'sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackResponse',
      []
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitUserSentimentFeedbackResponse
 */
export const SubmitUserSentimentFeedbackResponse =
  new SubmitUserSentimentFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitStudentSentimentFeedbackRequest$Type extends MessageType<SubmitStudentSentimentFeedbackRequest> {
  constructor() {
    super(
      'sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest',
      [
        {
          no: 1,
          name: 'sentiment',
          kind: 'enum',
          T: () => [
            'sparx.interaction.feedback.v1.SentimentRating',
            SentimentRating,
            'SENTIMENT_RATING_',
          ],
        },
        { no: 2, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: 'sw', kind: 'message', T: () => SparxWebContext },
        {
          no: 4,
          name: 'labels',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
        {
          no: 5,
          name: 'product',
          kind: 'enum',
          T: () => ['sparx.types.Product', Product],
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest
 */
export const SubmitStudentSentimentFeedbackRequest =
  new SubmitStudentSentimentFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitStudentSentimentFeedbackResponse$Type extends MessageType<SubmitStudentSentimentFeedbackResponse> {
  constructor() {
    super(
      'sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse',
      []
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse
 */
export const SubmitStudentSentimentFeedbackResponse =
  new SubmitStudentSentimentFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxWebContext$Type extends MessageType<SparxWebContext> {
  constructor() {
    super('sparx.interaction.feedback.v1.SparxWebContext', [
      {
        no: 1,
        name: 'application_version',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'feedback_type',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'page_url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.interaction.feedback.v1.SparxWebContext
 */
export const SparxWebContext = new SparxWebContext$Type();
/**
 * @generated ServiceType for protobuf service sparx.interaction.feedback.v1.TeacherFeedbackService
 */
export const TeacherFeedbackService = new ServiceType(
  'sparx.interaction.feedback.v1.TeacherFeedbackService',
  [
    {
      name: 'SubmitContentFeedback',
      options: {
        'google.api.http': { post: '/v1/teacher/content', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'feedback',
          domain: 'feedback/teacher',
        },
      },
      I: SubmitContentFeedbackRequest,
      O: SubmitContentFeedbackResponse,
    },
    {
      name: 'SubmitUserRatingFeedback',
      options: {
        'google.api.http': { post: '/v1/teacher/rating', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'feedback',
          domain: 'feedback/teacher',
        },
      },
      I: SubmitUserRatingFeedbackRequest,
      O: SubmitUserRatingFeedbackResponse,
    },
    {
      name: 'SubmitGeneralFeedback',
      options: {
        'google.api.http': { post: '/v1/teacher/general', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'feedback',
          domain: 'feedback/teacher',
        },
      },
      I: SubmitGeneralFeedbackRequest,
      O: SubmitGeneralFeedbackResponse,
    },
    {
      name: 'SubmitUserSentimentFeedback',
      options: {
        'google.api.http': { post: '/v1/teacher/sentiment', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'feedback',
          domain: 'feedback/teacher',
        },
      },
      I: SubmitUserSentimentFeedbackRequest,
      O: SubmitUserSentimentFeedbackResponse,
    },
  ]
);
/**
 * @generated ServiceType for protobuf service sparx.interaction.feedback.v1.StudentFeedbackService
 */
export const StudentFeedbackService = new ServiceType(
  'sparx.interaction.feedback.v1.StudentFeedbackService',
  [
    {
      name: 'SubmitStudentSentimentFeedback',
      options: {
        'google.api.http': { post: '/v1/student/general', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'feedback',
          domain: 'feedback/student',
        },
      },
      I: SubmitStudentSentimentFeedbackRequest,
      O: SubmitStudentSentimentFeedbackResponse,
    },
  ]
);
