import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, useRef } from 'react';

import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps } from '../question/SparxQuestionContext';
import { ICardElement } from '../question/types';
import { useEnterInputBehaviour } from '../utils/use-enter-input-behaviour';
import { LayoutElements } from './LayoutElement';

interface CardContentProps
  extends LayoutElementProps<ICardElement>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  innerRef?: (ref: HTMLElement | null) => void;
  readOnly?: boolean;
  locked?: boolean;
  isHidden?: boolean;
  draggable?: boolean;
  selected?: boolean;
  inSlot?: boolean;
}

export const CardContent = (props: CardContentProps) => {
  const {
    element,
    innerRef,
    readOnly,
    locked,
    selected,
    inSlot,
    isHidden: _,
    ...validPropsForDiv
  } = props;

  const cardContentRef = useRef<HTMLDivElement | null>(null);
  useEnterInputBehaviour(cardContentRef.current, { nextInputAction: 'focus', waitForAnim: true });

  return (
    <div
      {...validPropsForDiv}
      ref={ref => {
        innerRef?.(ref);
        cardContentRef.current = ref;
      }}
      className={classNames(styles.CardContent, {
        [accessibilityStyles.FocusTarget]: !inSlot,
        [styles.CardContentReadonly]: readOnly,
        [styles.CardContentLocked]: locked || props.isHidden,
        [styles.CardContentHidden]: props.isHidden,
        [styles.CardContentDraggable]: props.draggable,
        [styles.CardContentClickable]: !props.draggable,
        [styles.CardContentSelected]: selected,
      })}
      tabIndex={0}
      data-scale-target="card-content"
    >
      <LayoutElements element={element} parent={cardContentRef.current || undefined} />
      {props.children}
    </div>
  );
};
