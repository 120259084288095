import { IconButton } from '@chakra-ui/react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useElementSize from 'utils/hooks/measuredsize';

type NavigationStyle = 'default' | 'hidden' | 'sidebar-hidden';

type BackLink = string | { onClick: () => void };

interface NavigationControlContext {
  backLink?: BackLink;
  setBackLink: (link: BackLink | undefined) => void;
}

const NavigationControlContext = React.createContext<NavigationControlContext>({
  setBackLink: () => undefined,
});

export const NavigationControlProvider = ({ children }: PropsWithChildren) => {
  const [backLink, setBackLink] = useState<BackLink | undefined>();
  return (
    <NavigationControlContext.Provider
      value={{ backLink, setBackLink: val => setBackLink(() => val) }}
    >
      {children}
    </NavigationControlContext.Provider>
  );
};

export const useBackLink = (link: BackLink | undefined) => {
  const { setBackLink } = React.useContext(NavigationControlContext);
  return useEffect(() => {
    if (link) {
      setBackLink(link);
      return () => setBackLink(undefined);
    }
  }, [setBackLink, link]);
};

// TODO: not the best place to define this!
const navigationStyles: [string, NavigationStyle][] = [
  ['^/teacher/insights/display', 'hidden'],
  ['^/teacher/insights/print', 'hidden'],
  ['^/teacher/lessons/.+/tt/', 'hidden'],
  ['^/teacher/lessons/.+/whiteboards', 'hidden'],
  ['^/teacher/lessons/(?!archive).+', 'sidebar-hidden'],
  ['^/teacher/lessons/archive/.+', 'sidebar-hidden'],
];

export const useNavigationStyle = (): NavigationStyle => {
  const location = useLocation();
  return useMemo(() => {
    for (const [reg, style] of navigationStyles) {
      if (location.pathname.match(reg)) {
        return style;
      }
    }
    return 'default';
  }, [location.pathname]);
};

export const BackLink = () => {
  const { backLink } = React.useContext(NavigationControlContext);

  const [container, size] = useElementSize();
  const width = size?.width ? size.width : 50;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {backLink && (
        <motion.div
          initial={{ opacity: 0, marginLeft: -width }}
          animate={{ opacity: 1, marginLeft: 0 }}
          exit={{ opacity: 0, marginLeft: -width }}
          transition={{ type: 'spring', bounce: 0 }}
          ref={container}
        >
          <IconButton
            {...(typeof backLink === 'object'
              ? backLink
              : {
                  as: Link,
                  to: backLink,
                })}
            ml={3}
            aria-label="Back"
            colorScheme="blackAlpha"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            borderWidth={2}
            borderColor="blackAlpha.200"
            size={['sm', 'md']}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
