import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleKeyPress } from '@sparx/react-utils/keyboard';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { TextFieldWarning } from '../components/TextFieldWarning';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { INumberFieldElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { pasteHandler } from '../utils/paste';
import { useEnterInputBehaviour } from '../utils/use-enter-input-behaviour';

export const NumberFieldElement = ({ element }: LayoutElementProps<INumberFieldElement>) => {
  const context = useSparxQuestionContext();
  const [focussed, setFocussed] = useState(false);

  const open = context.openElementRef === element.ref;

  const state = context.input.number_fields?.[element.ref];
  const value = state?.value || '';

  const { show: showCorrect, correct } = isGapCorrect(
    element.ref,
    context.gapEvaluations,
    context.questionMarkingMode,
  );

  const [warning, setWarning] = useState(Warning.None);

  // work out warning
  useEffect(() => {
    if (!open) {
      setWarning(Warning.None);
    }
    if (!value.match(/^-?([0-9]\d*(\.\d+|\.)?)?$/)) {
      setWarning(Warning.NotNumeric);
      return;
    }
    setWarning(Warning.None);
  }, [value, open]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEnterInputBehaviour(inputRef.current, {
    nextInputAction: 'focus',
    waitForAnim: true,
    disabled: !open,
  });

  const handleClick = () => {
    if (!context.isSingleNumericInput) {
      context.setOpenElementRef(context.openElementRef === element.ref ? '' : element.ref);
    }
  };

  // shrink the font-size when input becomes long. Also used to change padding
  // so the input box doesn't change height as we change font size.
  const fontSizeMultiplier = useMemo(() => {
    if (value.length <= 6 || !inputRef.current) {
      return 1;
    }
    return 12 / (value.length + 6);
  }, [value.length]);

  return (
    <div className={styles.TextFieldWrapper} data-number-field={element.ref}>
      <div className={styles.TextFieldComponent}>
        {/* this input is controlled entirely from the keypad component. This seems questionable */}
        <input
          style={{
            fontSize: `${fontSizeMultiplier * 100}%`,
            padding: `${0.5 / fontSizeMultiplier}em 0`,
          }}
          className={classNames(
            styles.TextField,
            styles.TextFieldNumeric,
            open && styles.TextFieldNumericSelected,
            focussed && styles.TextFieldFocussed,
          )}
          placeholder="Enter number"
          value={value}
          readOnly
          disabled={context.readOnly}
          onMouseDown={handleClick}
          onKeyUp={handleKeyPress({ Enter: () => handleClick() })}
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
          ref={inputRef}
          type="text"
          inputMode="decimal"
          pattern="^-?([0-9]\d*(\.\d+|\.)?)?$"
          data-ref={element.ref}
          {...pasteHandler(element, 'numeric', context)}
        />
        {showCorrect && <CorrectIcon correct={correct} />}
      </div>
      <TextFieldWarning visible={warning !== Warning.None && !showCorrect}>
        <FontAwesomeIcon icon={faWarning} />
        {getWarningText(warning)}
      </TextFieldWarning>
    </div>
  );
};

enum Warning {
  NotNumeric,
  None,
}

const getWarningText = (warning: Warning): string => {
  switch (warning) {
    case Warning.NotNumeric:
      return 'Please enter a numeric answer';
    default:
      return '';
  }
};
