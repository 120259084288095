// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/misintegration/wondesync/v1/wondesync.proto" (package "sparx.wondesync.v1.wondesync", syntax proto3),// tslint:disable
// @ts-nocheck
import { WondeSyncInternal } from './wondesync';
import type { GetSyncPlanResponse } from './wondesync';
import type { GetSyncPlanRequest } from './wondesync';
import type { ExpireSyncPlansResponse } from './wondesync';
import type { ExpireSyncPlansRequest } from './wondesync';
import type { UpdateStatusResponse } from './wondesync';
import type { UpdateStatusRequest } from './wondesync';
import type { AutoSyncAllSchoolsResponse } from './wondesync';
import type { AutoSyncAllSchoolsRequest } from './wondesync';
import type { AutoSyncSchoolResponse } from './wondesync';
import type { AutoSyncSchoolRequest } from './wondesync';
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { WondeSync } from './wondesync';
import type { ApplySyncPlanResponse } from './wondesync';
import type { ApplySyncPlanRequest } from './wondesync';
import type { CreateSyncPlanResponse } from './wondesync';
import type { CreateSyncPlanRequest } from './wondesync';
import type { ParentContactsSettingsPreviewResponse } from './wondesync';
import type { ParentContactsSettingsPreviewRequest } from './wondesync';
import type { SetSchoolWondeSubjectsResponse } from './wondesync';
import type { SetSchoolWondeSubjectsRequest } from './wondesync';
import type { ListSchoolWondeSubjectsResponse } from './wondesync';
import type { ListSchoolWondeSubjectsRequest } from './wondesync';
import type { PreviewSyncSchoolV2Response } from './wondesync';
import type { IsSyncInProgressResponse } from './wondesync';
import type { IsSyncInProgressRequest } from './wondesync';
import type { SyncSchoolResponse } from './wondesync';
import type { SyncSchoolRequest } from './wondesync';
import type { GetClassesResponse } from './wondesync';
import type { GetClassesRequest } from './wondesync';
import type { ListClassesResponse } from './wondesync';
import type { ListClassesRequest } from './wondesync';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListSubjectsResponse } from './wondesync';
import type { ListSubjectsRequest } from './wondesync';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.wondesync.v1.wondesync.WondeSync
 */
export interface IWondeSyncClient {
  /**
   * List a Sparx school's MIS subjects according to Wonde.
   *
   * @generated from protobuf rpc: ListSubjects(sparx.wondesync.v1.wondesync.ListSubjectsRequest) returns (sparx.wondesync.v1.wondesync.ListSubjectsResponse);
   */
  listSubjects(
    input: ListSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<ListSubjectsRequest, ListSubjectsResponse>;
  /**
   * List a Sparx school's MIS classes according to Wonde.
   * This includes students in each class, but not their contacts' details.
   *
   * @generated from protobuf rpc: ListClasses(sparx.wondesync.v1.wondesync.ListClassesRequest) returns (sparx.wondesync.v1.wondesync.ListClassesResponse);
   */
  listClasses(
    input: ListClassesRequest,
    options?: RpcOptions
  ): UnaryCall<ListClassesRequest, ListClassesResponse>;
  /**
   * @generated from protobuf rpc: GetClasses(sparx.wondesync.v1.wondesync.GetClassesRequest) returns (sparx.wondesync.v1.wondesync.GetClassesResponse);
   */
  getClasses(
    input: GetClassesRequest,
    options?: RpcOptions
  ): UnaryCall<GetClassesRequest, GetClassesResponse>;
  /**
   * Sync a Sparx school.
   *
   * @generated from protobuf rpc: SyncSchool(sparx.wondesync.v1.wondesync.SyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.SyncSchoolResponse);
   */
  syncSchool(
    input: SyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<SyncSchoolRequest, SyncSchoolResponse>;
  /**
   * IsSyncInProgress describes if the given Sparx school has a sync currently
   * in progress.
   *
   * @generated from protobuf rpc: IsSyncInProgress(sparx.wondesync.v1.wondesync.IsSyncInProgressRequest) returns (sparx.wondesync.v1.wondesync.IsSyncInProgressResponse);
   */
  isSyncInProgress(
    input: IsSyncInProgressRequest,
    options?: RpcOptions
  ): UnaryCall<IsSyncInProgressRequest, IsSyncInProgressResponse>;
  /**
   * @generated from protobuf rpc: PreviewSyncSchoolV2(sparx.wondesync.v1.wondesync.SyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.PreviewSyncSchoolV2Response);
   */
  previewSyncSchoolV2(
    input: SyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<SyncSchoolRequest, PreviewSyncSchoolV2Response>;
  /**
   * List a Sparx school's MIS subjects for which classes are imported.
   *
   * @generated from protobuf rpc: ListSchoolWondeSubjects(sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsRequest) returns (sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsResponse);
   */
  listSchoolWondeSubjects(
    input: ListSchoolWondeSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchoolWondeSubjectsRequest, ListSchoolWondeSubjectsResponse>;
  /**
   * Set a Sparx school's MIS subjects for which classes are imported.
   *
   * @generated from protobuf rpc: SetSchoolWondeSubjects(sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsRequest) returns (sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsResponse);
   */
  setSchoolWondeSubjects(
    input: SetSchoolWondeSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<SetSchoolWondeSubjectsRequest, SetSchoolWondeSubjectsResponse>;
  /**
   * @generated from protobuf rpc: ParentContactsSettingsPreview(sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewRequest) returns (sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse);
   */
  parentContactsSettingsPreview(
    input: ParentContactsSettingsPreviewRequest,
    options?: RpcOptions
  ): UnaryCall<
    ParentContactsSettingsPreviewRequest,
    ParentContactsSettingsPreviewResponse
  >;
  // The following endpoints implement syncing for individual products. This means that syncing one
  // product should make any changes to entities for other products.

  /**
   * Creates a complete sync plan containing all of the actions required to bring Sparx inline with
   * the schools MIS.
   *
   * @generated from protobuf rpc: CreateSyncPlan(sparx.wondesync.v1.wondesync.CreateSyncPlanRequest) returns (sparx.wondesync.v1.wondesync.CreateSyncPlanResponse);
   */
  createSyncPlan(
    input: CreateSyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSyncPlanRequest, CreateSyncPlanResponse>;
  /**
   * Loads and applies a previously created sync plan.
   *
   * @generated from protobuf rpc: ApplySyncPlan(sparx.wondesync.v1.wondesync.ApplySyncPlanRequest) returns (sparx.wondesync.v1.wondesync.ApplySyncPlanResponse);
   */
  applySyncPlan(
    input: ApplySyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<ApplySyncPlanRequest, ApplySyncPlanResponse>;
}
/**
 * @generated from protobuf service sparx.wondesync.v1.wondesync.WondeSync
 */
export class WondeSyncClient implements IWondeSyncClient, ServiceInfo {
  typeName = WondeSync.typeName;
  methods = WondeSync.methods;
  options = WondeSync.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * List a Sparx school's MIS subjects according to Wonde.
   *
   * @generated from protobuf rpc: ListSubjects(sparx.wondesync.v1.wondesync.ListSubjectsRequest) returns (sparx.wondesync.v1.wondesync.ListSubjectsResponse);
   */
  listSubjects(
    input: ListSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<ListSubjectsRequest, ListSubjectsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSubjectsRequest, ListSubjectsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * List a Sparx school's MIS classes according to Wonde.
   * This includes students in each class, but not their contacts' details.
   *
   * @generated from protobuf rpc: ListClasses(sparx.wondesync.v1.wondesync.ListClassesRequest) returns (sparx.wondesync.v1.wondesync.ListClassesResponse);
   */
  listClasses(
    input: ListClassesRequest,
    options?: RpcOptions
  ): UnaryCall<ListClassesRequest, ListClassesResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListClassesRequest, ListClassesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetClasses(sparx.wondesync.v1.wondesync.GetClassesRequest) returns (sparx.wondesync.v1.wondesync.GetClassesResponse);
   */
  getClasses(
    input: GetClassesRequest,
    options?: RpcOptions
  ): UnaryCall<GetClassesRequest, GetClassesResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetClassesRequest, GetClassesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Sync a Sparx school.
   *
   * @generated from protobuf rpc: SyncSchool(sparx.wondesync.v1.wondesync.SyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.SyncSchoolResponse);
   */
  syncSchool(
    input: SyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<SyncSchoolRequest, SyncSchoolResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SyncSchoolRequest, SyncSchoolResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * IsSyncInProgress describes if the given Sparx school has a sync currently
   * in progress.
   *
   * @generated from protobuf rpc: IsSyncInProgress(sparx.wondesync.v1.wondesync.IsSyncInProgressRequest) returns (sparx.wondesync.v1.wondesync.IsSyncInProgressResponse);
   */
  isSyncInProgress(
    input: IsSyncInProgressRequest,
    options?: RpcOptions
  ): UnaryCall<IsSyncInProgressRequest, IsSyncInProgressResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<IsSyncInProgressRequest, IsSyncInProgressResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: PreviewSyncSchoolV2(sparx.wondesync.v1.wondesync.SyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.PreviewSyncSchoolV2Response);
   */
  previewSyncSchoolV2(
    input: SyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<SyncSchoolRequest, PreviewSyncSchoolV2Response> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SyncSchoolRequest, PreviewSyncSchoolV2Response>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * List a Sparx school's MIS subjects for which classes are imported.
   *
   * @generated from protobuf rpc: ListSchoolWondeSubjects(sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsRequest) returns (sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsResponse);
   */
  listSchoolWondeSubjects(
    input: ListSchoolWondeSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListSchoolWondeSubjectsRequest,
    ListSchoolWondeSubjectsResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSchoolWondeSubjectsRequest,
      ListSchoolWondeSubjectsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Set a Sparx school's MIS subjects for which classes are imported.
   *
   * @generated from protobuf rpc: SetSchoolWondeSubjects(sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsRequest) returns (sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsResponse);
   */
  setSchoolWondeSubjects(
    input: SetSchoolWondeSubjectsRequest,
    options?: RpcOptions
  ): UnaryCall<SetSchoolWondeSubjectsRequest, SetSchoolWondeSubjectsResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetSchoolWondeSubjectsRequest,
      SetSchoolWondeSubjectsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ParentContactsSettingsPreview(sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewRequest) returns (sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse);
   */
  parentContactsSettingsPreview(
    input: ParentContactsSettingsPreviewRequest,
    options?: RpcOptions
  ): UnaryCall<
    ParentContactsSettingsPreviewRequest,
    ParentContactsSettingsPreviewResponse
  > {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ParentContactsSettingsPreviewRequest,
      ParentContactsSettingsPreviewResponse
    >('unary', this._transport, method, opt, input);
  }
  // The following endpoints implement syncing for individual products. This means that syncing one
  // product should make any changes to entities for other products.

  /**
   * Creates a complete sync plan containing all of the actions required to bring Sparx inline with
   * the schools MIS.
   *
   * @generated from protobuf rpc: CreateSyncPlan(sparx.wondesync.v1.wondesync.CreateSyncPlanRequest) returns (sparx.wondesync.v1.wondesync.CreateSyncPlanResponse);
   */
  createSyncPlan(
    input: CreateSyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSyncPlanRequest, CreateSyncPlanResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSyncPlanRequest, CreateSyncPlanResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Loads and applies a previously created sync plan.
   *
   * @generated from protobuf rpc: ApplySyncPlan(sparx.wondesync.v1.wondesync.ApplySyncPlanRequest) returns (sparx.wondesync.v1.wondesync.ApplySyncPlanResponse);
   */
  applySyncPlan(
    input: ApplySyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<ApplySyncPlanRequest, ApplySyncPlanResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ApplySyncPlanRequest, ApplySyncPlanResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
/**
 * Service for endpoints we don't want to publicly expose (no auth)
 *
 * @generated from protobuf service sparx.wondesync.v1.wondesync.WondeSyncInternal
 */
export interface IWondeSyncInternalClient {
  /**
   * (Auto) Sync the specified school
   *
   * @generated from protobuf rpc: AutoSyncSchool(sparx.wondesync.v1.wondesync.AutoSyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.AutoSyncSchoolResponse);
   */
  autoSyncSchool(
    input: AutoSyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<AutoSyncSchoolRequest, AutoSyncSchoolResponse>;
  /**
   * (Auto) Sync all Sparx schools that require syncing
   *
   * @generated from protobuf rpc: AutoSyncAllSchools(sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsRequest) returns (sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsResponse);
   */
  autoSyncAllSchools(
    input: AutoSyncAllSchoolsRequest,
    options?: RpcOptions
  ): UnaryCall<AutoSyncAllSchoolsRequest, AutoSyncAllSchoolsResponse>;
  /**
   * UpdateStatus fetches status (pending, approved, etc) from Wonde, writes to
   * schoolstore
   *
   * @generated from protobuf rpc: UpdateStatus(sparx.wondesync.v1.wondesync.UpdateStatusRequest) returns (sparx.wondesync.v1.wondesync.UpdateStatusResponse);
   */
  updateStatus(
    input: UpdateStatusRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStatusRequest, UpdateStatusResponse>;
  /**
   * ExpireSyncPlans deletes all multi-product sync plans that are older than 30 days to keep the database size down.
   *
   * @generated from protobuf rpc: ExpireSyncPlans(sparx.wondesync.v1.wondesync.ExpireSyncPlansRequest) returns (sparx.wondesync.v1.wondesync.ExpireSyncPlansResponse);
   */
  expireSyncPlans(
    input: ExpireSyncPlansRequest,
    options?: RpcOptions
  ): UnaryCall<ExpireSyncPlansRequest, ExpireSyncPlansResponse>;
  /**
   * GetSyncPlan fetches a sync plan by name
   *
   * @generated from protobuf rpc: GetSyncPlan(sparx.wondesync.v1.wondesync.GetSyncPlanRequest) returns (sparx.wondesync.v1.wondesync.GetSyncPlanResponse);
   */
  getSyncPlan(
    input: GetSyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<GetSyncPlanRequest, GetSyncPlanResponse>;
}
/**
 * Service for endpoints we don't want to publicly expose (no auth)
 *
 * @generated from protobuf service sparx.wondesync.v1.wondesync.WondeSyncInternal
 */
export class WondeSyncInternalClient
  implements IWondeSyncInternalClient, ServiceInfo
{
  typeName = WondeSyncInternal.typeName;
  methods = WondeSyncInternal.methods;
  options = WondeSyncInternal.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * (Auto) Sync the specified school
   *
   * @generated from protobuf rpc: AutoSyncSchool(sparx.wondesync.v1.wondesync.AutoSyncSchoolRequest) returns (sparx.wondesync.v1.wondesync.AutoSyncSchoolResponse);
   */
  autoSyncSchool(
    input: AutoSyncSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<AutoSyncSchoolRequest, AutoSyncSchoolResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AutoSyncSchoolRequest, AutoSyncSchoolResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * (Auto) Sync all Sparx schools that require syncing
   *
   * @generated from protobuf rpc: AutoSyncAllSchools(sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsRequest) returns (sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsResponse);
   */
  autoSyncAllSchools(
    input: AutoSyncAllSchoolsRequest,
    options?: RpcOptions
  ): UnaryCall<AutoSyncAllSchoolsRequest, AutoSyncAllSchoolsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      AutoSyncAllSchoolsRequest,
      AutoSyncAllSchoolsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * UpdateStatus fetches status (pending, approved, etc) from Wonde, writes to
   * schoolstore
   *
   * @generated from protobuf rpc: UpdateStatus(sparx.wondesync.v1.wondesync.UpdateStatusRequest) returns (sparx.wondesync.v1.wondesync.UpdateStatusResponse);
   */
  updateStatus(
    input: UpdateStatusRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateStatusRequest, UpdateStatusResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateStatusRequest, UpdateStatusResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * ExpireSyncPlans deletes all multi-product sync plans that are older than 30 days to keep the database size down.
   *
   * @generated from protobuf rpc: ExpireSyncPlans(sparx.wondesync.v1.wondesync.ExpireSyncPlansRequest) returns (sparx.wondesync.v1.wondesync.ExpireSyncPlansResponse);
   */
  expireSyncPlans(
    input: ExpireSyncPlansRequest,
    options?: RpcOptions
  ): UnaryCall<ExpireSyncPlansRequest, ExpireSyncPlansResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ExpireSyncPlansRequest, ExpireSyncPlansResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * GetSyncPlan fetches a sync plan by name
   *
   * @generated from protobuf rpc: GetSyncPlan(sparx.wondesync.v1.wondesync.GetSyncPlanRequest) returns (sparx.wondesync.v1.wondesync.GetSyncPlanResponse);
   */
  getSyncPlan(
    input: GetSyncPlanRequest,
    options?: RpcOptions
  ): UnaryCall<GetSyncPlanRequest, GetSyncPlanResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSyncPlanRequest, GetSyncPlanResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
