import { Box, Flex, Heading, IconButton, Spacer } from '@chakra-ui/react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

interface PageTitleProps {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  pageTitleOverride?: string;
  backButton?: string | (() => void);
}

export const PageTitle = ({
  title,
  subtitle,
  backButton,
  pageTitleOverride,
  children,
}: PropsWithChildren<PageTitleProps>) => {
  const pageTitle = pageTitleOverride || (typeof title === 'string' ? title : '');

  return (
    <Box display="flex" alignItems="center" pb={[2, 4]}>
      {pageTitle && <Title title={pageTitle} />}
      {backButton && <BackButton link={backButton} />}
      <Flex direction="column" pb={2} pt={2} pr={2}>
        <Heading fontSize={['xl', '2xl']} color="blue.900">
          {title}
        </Heading>
        {subtitle && (
          <Heading fontSize={['sm', 'md']} color="gray.500" pt={1}>
            {subtitle}
          </Heading>
        )}
      </Flex>
      <Spacer />
      {children}
    </Box>
  );
};

export const Title = ({ title }: { title?: string }) => {
  const productName = import.meta.env.VITE_SPARX_PRODUCT_DISPLAY_NAME || 'Sparx Science';
  return (
    <Helmet>
      <title>{title ? `${title} - ${productName}` : productName}</title>
    </Helmet>
  );
};

export const BackButton = ({ link }: { link?: string | (() => void) }) => (
  <IconButton
    mr={4}
    aria-label="Back"
    colorScheme="gray"
    icon={<FontAwesomeIcon icon={faChevronLeft} />}
    borderWidth={2}
    borderColor="blackAlpha.200"
    as={typeof link === 'string' ? Link : undefined}
    to={typeof link === 'string' ? link : ''}
    onClick={typeof link === 'function' ? link : undefined}
  />
);
