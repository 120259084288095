import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  DeviceList,
  KioskState,
  UpdateKioskStateRequest,
} from '@sparx/api/apis/sparx/teaching/kiosk/v1/kiosk';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { kioskClient } from 'api/service';
import { useEffect } from 'react';

export const useKioskStatePusher = (devices: { [k: string]: DeviceList }, logout: () => void) => {
  const state: Partial<Exclude<KioskState, 'lastSeen'>> = {
    lastSeen: Timestamp.now(),
    devices,
  };

  const { refetch } = useQuery({
    queryKey: ['kiosk', 'state'],
    queryFn: async () =>
      kioskClient.updateKioskState(
        UpdateKioskStateRequest.create({
          state: {
            ...state,
            lastSeen: Timestamp.now(),
          },
        }),
      ),
    onError: err => {
      // If the kiosk is not found, log out the user
      if (err instanceof RpcError && err.code === 'NOT_FOUND') {
        logout();
      }
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    refetchInterval: 15000,
    cacheTime: 0,
  });

  // Refetch when the number of devices changes
  const deviceCount = Object.values(devices).reduce((acc, d) => acc + d.devices.length, 0);
  useEffect(() => {
    console.log('device count changed', deviceCount);
    refetch();
  }, [refetch, deviceCount]);
};
