import { Text } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ensureTokenFetcherStarted, tokenFetcher } from '@sparx/science/api';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { KioskActive } from 'views/kioskview/KioskActive';
import { KioskContainer } from 'views/kioskview/KioskContainer';
import { KioskRegister } from 'views/kioskview/KioskRegister';

export const KioskView = () => {
  const [subject, setSubject] = useState('');
  const { isLoading, isError } = useQuery({
    queryKey: ['kiosk', 'token'],
    queryFn: () => tokenFetcher(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    // If the first token request succeeds, ensure we're refreshing now
    onSuccess: async data => {
      ensureTokenFetcherStarted();

      const token = await data.text();
      try {
        const s = JSON.parse(atob(token.split('.')[1]))?.sub;
        setSubject(s);
      } catch (e) {
        console.error('Failed to parse token', e);
      }
    },
  });

  if (isLoading) {
    return (
      <KioskContainer>
        <Text fontSize="100px" color="white">
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </Text>
      </KioskContainer>
    );
  }
  if (isError) {
    return <KioskRegister />;
  }
  return <KioskActive subject={subject} />;
};
