import { Stack } from '../components/Stack';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps } from '../question/SparxQuestionContext';
import { IPartGroupElement } from '../question/types';
import { LayoutElements } from './LayoutElement';

export const PartGroupElement = ({ element }: LayoutElementProps<IPartGroupElement>) => {
  // Don't display the part if there is no content.
  if (!element.content || element.content.length === 0) {
    return null;
  }

  return (
    <div className={styles.PartGroup} data-part-name={element.prefix}>
      <div className={styles.PartGroupPrefix} style={{ width: `${element.depth * 1.8}em` }}>
        {element.prefix}
      </div>
      <Stack dir="vertical">
        <LayoutElements element={element} />
      </Stack>
    </div>
  );
};
