import { faCheck, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from '../question/SparxQuestion.module.css';

interface CorrectIconProps {
  correct?: boolean;
  inline?: boolean;
  className?: string;
  markingError?: boolean;
}

export const CorrectIcon = ({ correct, inline, className, markingError }: CorrectIconProps) => (
  <div
    className={classNames(
      className,
      !inline && styles.CorrectIconFloat,
      markingError ? styles.MarkingErrorIcon : correct ? styles.CorrectIcon : styles.IncorrectIcon,
    )}
  >
    <FontAwesomeIcon
      icon={markingError ? faMinus : correct ? faCheck : faTimes}
      fixedWidth={true}
    />
  </div>
);
