// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/homeworkman/v1/homeworkman.proto" (package "sparx.homeworkman.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { DateTime } from '../../../../google/type/datetime';
import { Interval } from '../../../../google/type/interval';
import { Int32Value } from '../../../../google/protobuf/wrappers';
import { Completion } from '../../packages/v1/spxpkg';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.homeworkman.v1.HealthCheckRequest
 */
export interface HealthCheckRequest {}
/**
 * @generated from protobuf message sparx.homeworkman.v1.HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * @generated from protobuf field: string message = 1;
   */
  message: string;
}
/**
 *
 * Data about a set homework.
 *
 * @generated from protobuf message sparx.homeworkman.v1.Homework
 */
export interface Homework {
  /**
   * ID of the homework
   *
   * @generated from protobuf field: string homeworkID = 1;
   */
  homeworkID: string;
  /**
   * Student group the homework was set for
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * School the homework belongs to
   *
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * Date the homework was started
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 4;
   */
  startDate?: Timestamp;
  /**
   * Date the homework was due in
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 5;
   */
  endDate?: Timestamp;
  /**
   * Date the homework expires. A Student cannot work on the homework after
   * this date.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiryDate = 8;
   */
  expiryDate?: Timestamp;
  /**
   * The length of the homework definition for this homework, read only.
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanDefinitionLength length = 7;
   */
  length: PlanDefinitionLength;
  /**
   * The timestamp when this homework was deleted. If not set, this homework has
   * not been deleted
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTimestamp = 9;
   */
  deletedTimestamp?: Timestamp;
  /**
   * The timestamp of when this homework is due (in UTC)
   *
   * @generated from protobuf field: google.protobuf.Timestamp dueTimestamp = 10;
   */
  dueTimestamp?: Timestamp;
}
/**
 *
 * Data about a students progress through a homework package.
 *
 * @generated from protobuf message sparx.homeworkman.v1.Package
 */
export interface Package {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Type of package. Can be homework, optional-homework and targets
   *
   * @generated from protobuf field: string packageType = 2;
   */
  packageType: string;
  /**
   * ID of the student this package is for
   *
   * @generated from protobuf field: string studentID = 3;
   */
  studentID: string;
  /**
   * ID of the homework this package is for
   *
   * @generated from protobuf field: string homeworkID = 4;
   */
  homeworkID: string;
  /**
   * School this homework belongs to
   *
   * @generated from protobuf field: string schoolID = 5;
   */
  schoolID: string;
  /**
   * Number of task items set in the package
   *
   * @generated from protobuf field: int32 numTaskItems = 6;
   */
  numTaskItems: number;
  /**
   * Number of task items the student has completed in the package
   *
   * @generated from protobuf field: int32 numTaskItemsComplete = 7;
   */
  numTaskItemsComplete: number;
  /**
   * Number of task items the student has got wrong in the package
   *
   * @generated from protobuf field: int32 numTaskItemsWrong = 8;
   */
  numTaskItemsWrong: number;
  /**
   * Number of task items the student has got wrong and had watched the video
   * for in the package
   *
   * @generated from protobuf field: int32 numTaskItemsVideoWrong = 9;
   */
  numTaskItemsVideoWrong: number;
  /**
   * Number of tasks in the package
   *
   * @generated from protobuf field: int32 numTasks = 10;
   */
  numTasks: number;
  /**
   * Number of completed tasks in the package
   *
   * @generated from protobuf field: int32 numTasksComplete = 11;
   */
  numTasksComplete: number;
  /**
   * Number of tasks in progress in the package
   *
   * @generated from protobuf field: int32 numTasksInProgress = 12;
   */
  numTasksInProgress: number;
  /**
   * Number of question attempts while completing the package
   *
   * @generated from protobuf field: int32 numAttempts = 13;
   */
  numAttempts: number;
  /**
   * Flag if the homework had ever been complete
   *
   * @generated from protobuf field: bool hasBeenComplete = 14;
   */
  hasBeenComplete: boolean;
  /**
   * The revision of the package. The number will increase whenever there has
   * been a change in the package information
   *
   * @generated from protobuf field: int32 revision = 15;
   */
  revision: number;
  /**
   * Timestamp of when the homework was first completed
   *
   * @generated from protobuf field: google.protobuf.Timestamp completionDate = 16;
   */
  completionDate?: Timestamp;
  /**
   * The number of seconds spent on this package
   *
   * @generated from protobuf field: double timeTaken = 17;
   */
  timeTaken: number;
  /**
   * The state of the package
   *
   * @generated from protobuf field: string packageState = 18;
   */
  packageState: string;
  /**
   * Time spent doing question (truncated for each activity)
   *
   * @generated from protobuf field: double truncatedWorkTime = 19;
   */
  truncatedWorkTime: number;
  /**
   * Time spent watching videos (truncated for each activity)
   *
   * @generated from protobuf field: double truncatedWatchTime = 20;
   */
  truncatedWatchTime: number;
  /**
   * Current status of the package
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PackageStatus status = 21;
   */
  status: PackageStatus;
  /**
   * The Time at which the Package was last updated
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdateTime = 22;
   */
  lastUpdateTime?: Timestamp;
  /**
   * Information about size and progress of the package. The progess field
   * will contain a map of CompletionType (e.g. Complete = "C", SeekHelp = "SH")
   * to numeric value of that CompletionType. The package is complete if the
   * Complete progress value is at least equal to size.
   *
   * @generated from protobuf field: sparx.packages.v1.Completion completion = 23;
   */
  completion?: Completion;
  /**
   * Generic labels which can be added to the package
   *
   * @generated from protobuf field: map<string, string> labels = 24;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 *
 * Data about a link between a homework and a student with a homework status.
 *
 * @generated from protobuf message sparx.homeworkman.v1.StudentHomework
 */
export interface StudentHomework {
  /**
   * ID of the student
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * ID of the school
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * ID of the homework
   *
   * @generated from protobuf field: string homeworkID = 3;
   */
  homeworkID: string;
  /**
   * Status of the student in the homework
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkStatus status = 4;
   */
  status: HomeworkStatus;
  /**
   * Time the homework for this specific student was deleted (converted to optional) if it was.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTimestamp = 5;
   */
  deletedTimestamp?: Timestamp;
}
/**
 *
 * Grouped package data by student and homework, with the current homework
 * status.
 *
 * @generated from protobuf message sparx.homeworkman.v1.HomeworkSummary
 */
export interface HomeworkSummary {
  /**
   * ID of the student
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * ID of the school
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * ID of the homework
   *
   * @generated from protobuf field: string homeworkID = 3;
   */
  homeworkID: string;
  /**
   * Status of the student in the homework
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkStatus status = 4;
   */
  status: HomeworkStatus;
  /**
   * Information for the compulsory package for this homework
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Package compulsory = 5;
   */
  compulsory?: Package;
  /**
   * Information for the optional package for this homework
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Package optional = 6;
   */
  optional?: Package;
  /**
   * Information for the targets package for this homework
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Package targets = 7;
   */
  targets?: Package;
  /**
   * Time the homework was deleted (converted to optional).
   * This field will be set if the homework was made optional for the whole class or the individual student.
   * See deletedForStudentTimestamp if you need to specifically know whether the homework was deleted for this student.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTimestamp = 8;
   */
  deletedTimestamp?: Timestamp;
  /**
   * Time the homework was deleted (converted to optional) for this student.
   * Unlike deletedTimestamp, this is only set if the homework was made optional for the student specifically.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedForStudentTimestamp = 9;
   */
  deletedForStudentTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.HomeworkStat
 */
export interface HomeworkStat {
  /**
   * ID of the homework
   *
   * @generated from protobuf field: string homeworkID = 1;
   */
  homeworkID: string;
  /**
   * Package type for the homework
   *
   * @generated from protobuf field: string packageType = 2;
   */
  packageType: string;
  /**
   * StartDate of the homework
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 3;
   */
  startDate?: Timestamp;
  /**
   * Total number of students in the homework package
   *
   * @generated from protobuf field: int32 total = 4;
   */
  total: number;
  /**
   * Total number of completed homeworks in this homework package
   *
   * @generated from protobuf field: int32 complete = 5;
   */
  complete: number;
  /**
   * Number of completed homeworks before the end date of the homework
   *
   * @generated from protobuf field: int32 completeHandin = 6;
   */
  completeHandin: number;
  /**
   * Total number of started packages in this homework
   *
   * @generated from protobuf field: int32 started = 7;
   */
  started: number;
}
/**
 * Query on homework objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworksRequest
 */
export interface GetHomeworksRequest {
  /**
   * School the homework is in. If it is not a valid UUID an Unknown error
   * will be returned.  If the value is empty, there will be no filtering on
   * school, and the and the permission:
   *     'read' from 'tp:homeworks' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of homework ID's to get homeworks for. If empty there will be
   * no filtering on homework ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string homeworkID = 2;
   */
  homeworkID: string[];
  /**
   * Filter homeworks assigned to one of a list of studentgroups.
   * If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 3;
   */
  studentGroupID: string[];
  /**
   * List of student IDs to get homeworks for.
   * If empty there will be
   * no filtering on student ID. If one of the student IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 8;
   */
  studentID: string[];
  /**
   * Filter homeworks based on the time they started, ended, or expired.
   * If one of the values is nil there will be no filtering on it.
   * Inclusive
   *
   * @generated from protobuf field: google.protobuf.Timestamp startsAfter = 4;
   */
  startsAfter?: Timestamp;
  /**
   * Exclusive
   *
   * @generated from protobuf field: google.protobuf.Timestamp startsBefore = 5;
   */
  startsBefore?: Timestamp;
  /**
   * Inclusive
   *
   * @generated from protobuf field: google.protobuf.Timestamp endsAfter = 6;
   */
  endsAfter?: Timestamp;
  /**
   * Exclusive
   *
   * @generated from protobuf field: google.protobuf.Timestamp endsBefore = 7;
   */
  endsBefore?: Timestamp;
  /**
   * Inclusive
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiresAfter = 9;
   */
  expiresAfter?: Timestamp;
}
/**
 * Response containing homework objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworksResponse
 */
export interface GetHomeworksResponse {
  /**
   * List of homework objects filtered by the request.
   * HomeworkID, StudentGroupId, SchoolID, StartDate,
   * EndDate and Length are included.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
}
/**
 * Delete an already set homework
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.DeleteHomeworkRequest
 */
export interface DeleteHomeworkRequest {
  /**
   * ID of the school the student group is in. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * ID of the homework to mark as deleted.
   *
   * @generated from protobuf field: string homeworkID = 3;
   */
  homeworkID: string;
}
/**
 * Query on package objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetPackagesRequest
 */
export interface GetPackagesRequest {
  /**
   * School the package is in. If it is not a valid UUID an Unknown error
   * will be returned.  If the value is empty, there will be no filtering on
   * school, and the and the permission:
   *     'read' from 'tp:homework' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of homework IDs to get packages for. If empty there will be
   * no filtering on homework ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string homeworkID = 2;
   */
  homeworkID: string[];
  /**
   * List of student IDs to get packages for. If empty there will be
   * no filtering on student ID. If one of the student IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * Only packages with homeworks with studentgroups in this list will
   * be returned. If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 4;
   */
  studentGroupID: string[];
  /**
   * / List of package IDs to get packages for. If empty there will be
   * no filtering on package ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string packageID = 5;
   */
  packageID: string[];
}
/**
 * Response containing package objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetPackagesResponse
 */
export interface GetPackagesResponse {
  /**
   * List of package objects filtered by the request. The status field is
   * not set.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 1;
   */
  packages: Package[];
}
/**
 * Query the homework student objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkStudentsRequest
 */
export interface GetHomeworkStudentsRequest {
  /**
   * School the homework is in. If it is not a valid UUID an Unknown error
   * will be returned.  If the value is empty, there will be no filtering on
   * school, and the and the permission:
   *     'read' from 'tp:homework' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of homework IDs to get homeworks students for. If empty there will be
   * no filtering on homework ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string homeworkID = 2;
   */
  homeworkID: string[];
  /**
   * List of student IDs to get homeworks students for.
   * If empty there will be
   * no filtering on student ID. If one of the student IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * List of studentgroups for get student homeworks for.
   * If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 4;
   */
  studentGroupID: string[];
}
/**
 * Response containing student homework objects
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkStudentsResponse
 */
export interface GetHomeworkStudentsResponse {
  /**
   * List of student homework objects filtered by the request.
   * The StudentID, SchoolID, HomeworkID and Status fields are set.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.StudentHomework studentHomeworks = 1;
   */
  studentHomeworks: StudentHomework[];
}
/**
 * Query homework summaries
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkSummariesRequest
 */
export interface GetHomeworkSummariesRequest {
  /**
   * School the homework is in. If it is not a valid UUID an Unknown error
   * will be returned.  If the value is empty, there will be no filtering on
   * school, and the and the permission:
   *     'read' from 'tp:homework' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of homework IDs to get summaries for. If empty there will be
   * no filtering on homework ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string homeworkID = 2;
   */
  homeworkID: string[];
  /**
   * List of student IDs to get summaries for. If empty there will be
   * no filtering on student ID. If one of the student IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * List of studentgroups for get summaries for. If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 4;
   */
  studentGroupID: string[];
  /**
   * Whether to include compulsory homeworks that failed to generate.
   *
   * @generated from protobuf field: bool includeGenerationFailed = 5;
   */
  includeGenerationFailed: boolean;
}
/**
 * Response containing homework summary messages
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkSummariesResponse
 */
export interface GetHomeworkSummariesResponse {
  /**
   * List of homework summary messages filtered by the request. A homework
   * package is placed in Compulsory if it has PackageType Homework,
   * Optional if it has package type Optional and Targets if it
   * has package type Targets. Packages with PackageState GenerationFailed
   * are not returned.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.HomeworkSummary summaries = 1;
   */
  summaries: HomeworkSummary[];
}
/**
 * Request a list of homework stats
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkStatsRequest
 */
export interface GetHomeworkStatsRequest {
  /**
   * School the homework is in. If it is not a valid UUID an Unknown error
   * will be returned.  If the value is empty, there will be no filtering on
   * school, and the and the permission:
   *     'read' from 'tp:homework' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of homework IDs to get stats for. If empty there will be
   * no filtering on homework ID. If one of the homework IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string homeworkID = 2;
   */
  homeworkID: string[];
  /**
   * List of student IDs to get stats for. If empty there will be
   * no filtering on student ID. If one of the student IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentID = 3;
   */
  studentID: string[];
  /**
   * List of studentgroups for get stats for. If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Unknown error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 4;
   */
  studentGroupID: string[];
}
/**
 * Response containing list of homework stats
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkStatsResponse
 */
export interface GetHomeworkStatsResponse {
  /**
   * The homework packages that pass through the filters are grouped
   * by homeworkID and PackageType. Each package in a group will then
   * be for a different student. A HomeworkStat value is then returned for
   * each group of packages.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.HomeworkStat stats = 1;
   */
  stats: HomeworkStat[];
}
/**
 * Request the creation of a homework
 *
 * @generated from protobuf message sparx.homeworkman.v1.CreateHomeworkRequest
 */
export interface CreateHomeworkRequest {
  /**
   * The homework to create
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Homework homework = 1;
   */
  homework?: Homework;
  /**
   * List of students to assign this homework to
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.CreateHomeworkRequest.StudentHomeworkImport students = 2;
   */
  students: CreateHomeworkRequest_StudentHomeworkImport[];
  /**
   * The packages to create for this homework. These will be created with the
   * PLACEHOLDER state.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 3;
   */
  packages: Package[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.CreateHomeworkRequest.StudentHomeworkImport
 */
export interface CreateHomeworkRequest_StudentHomeworkImport {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * Status is no longer used.
   *
   * @deprecated
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkStatus status = 2 [deprecated = true];
   */
  status: HomeworkStatus;
}
/**
 * Response for creation of a homework.
 *
 * @generated from protobuf message sparx.homeworkman.v1.CreateHomeworkResponse
 */
export interface CreateHomeworkResponse {}
/**
 * Data about the position of a SOWItem in a homework plan.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.PlanItem
 */
export interface PlanItem {
  /**
   * ID of the plan item. Will match the id of the corresponding SOWItem
   *
   * @generated from protobuf field: string planItemID = 1;
   */
  planItemID: string;
  /**
   * ID of the definition the plan item is in if of type DONE
   *
   * @generated from protobuf field: string definitionID = 2;
   */
  definitionID: string;
  /**
   * The type of plan item
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanType type = 5;
   */
  type: PlanType;
  /**
   * Whether the plan item has been marked as skipped
   *
   * @generated from protobuf field: bool skipped = 6;
   */
  skipped: boolean;
  /**
   * ID of the lesson group that the PlanItem corresponds to
   *
   * @generated from protobuf field: string lessonGroupID = 7;
   */
  lessonGroupID: string;
  /**
   * ID of the curriculum topic that the PlanItem corresponds to
   *
   * @generated from protobuf field: string curriculumTopicID = 8;
   */
  curriculumTopicID: string;
  /**
   * DataCycle of the plan item (1 based index)
   *
   * @generated from protobuf field: int32 dataCycle = 9;
   */
  dataCycle: number;
  /**
   * Week index within the data cycle of the plan item (1 based index)
   *
   * @generated from protobuf field: int32 weekIndexInDataCycle = 10;
   */
  weekIndexInDataCycle: number;
  /**
   * Whether the plan item has been marked as removed from future consolidation
   *
   * @generated from protobuf field: bool removedFromConsolidation = 11;
   */
  removedFromConsolidation: boolean;
}
/**
 * Data about a curriculum topic in a scheme of work.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.SOWItem
 */
export interface SOWItem {
  /**
   * ID of the SOWItem.
   *
   * @generated from protobuf field: string sowItemID = 1;
   */
  sowItemID: string;
  /**
   * Name of the item
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * Relative weight of the item (>=0)
   *
   * @deprecated
   * @generated from protobuf field: int32 weight = 3 [deprecated = true];
   */
  weight: number;
  /**
   * Optional is always false
   *
   * @deprecated
   * @generated from protobuf field: bool optional = 4 [deprecated = true];
   */
  optional: boolean;
  /**
   * @generated from protobuf field: int32 defaultIndex = 5;
   */
  defaultIndex: number;
  /**
   * @deprecated
   * @generated from protobuf field: bool hasApp = 6 [deprecated = true];
   */
  hasApp: boolean;
  /**
   * @deprecated
   * @generated from protobuf field: bool hasNonApp = 7 [deprecated = true];
   */
  hasNonApp: boolean;
  /**
   * ID of the scheme of work this item is in
   *
   * @generated from protobuf field: string sowID = 8;
   */
  sowID: string;
  /**
   * ID of the lesson group that the SOWItem corresponds to
   *
   * @deprecated
   * @generated from protobuf field: string lessonGroupID = 9 [deprecated = true];
   */
  lessonGroupID: string;
  /**
   * ID of the curriculum topic that the SOWItem corresponds to
   *
   * @generated from protobuf field: string curriculumTopicID = 10;
   */
  curriculumTopicID: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.HomeworkPlan
 */
export interface HomeworkPlan {
  /**
   * ID of the student group
   *
   * @generated from protobuf field: string studentGroupID = 1;
   */
  studentGroupID: string;
  /**
   * ID of the school
   *
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * Default weight of homework definitions for this plan. Currently always 3.
   *
   * @deprecated
   * @generated from protobuf field: int32 defaultWeight = 5 [deprecated = true];
   */
  defaultWeight: number;
  /**
   * Default set day homeworks will be created for
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Weekday defaultSetDay = 6;
   */
  defaultSetDay: Weekday;
  /**
   * Default due day homeworks will be created for
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Weekday defaultDueDay = 7;
   */
  defaultDueDay: Weekday;
  /**
   * ID of the scheme of work
   *
   * @generated from protobuf field: string sowID = 9;
   */
  sowID: string;
  /**
   * The start date of the homework plan. Only used when creating a
   * plan to back generate homework. If this is not provided then the
   * start date will be the time the plan is created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 10;
   */
  startDate?: Timestamp;
  /**
   * The type of the plan
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkPlanType planType = 12;
   */
  planType: HomeworkPlanType;
  /**
   * The hours component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeHours = 13;
   */
  setTimeHours: number;
  /**
   * The minutes component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeMinutes = 14;
   */
  setTimeMinutes: number;
  /**
   * The timestamp of the next homework to be generated for this group (Read
   * only)
   *
   * @generated from protobuf field: google.protobuf.Timestamp nextGenerationTime = 15;
   */
  nextGenerationTime?: Timestamp;
  /**
   * The length in minutes of the compulsory packages for homeworks on this
   * plan. A value of 0 means no compulsory packages will be generated. In the
   * case of a new homework plan, if no value is provided, a default of 60
   * minutes will be used. In the case of updating an existing homework plan, if
   * no value is provided, no change will be made.
   *
   * @generated from protobuf field: google.protobuf.Int32Value compulsoryMinutes = 16;
   */
  compulsoryMinutes?: Int32Value;
  /**
   * The hours component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeHours = 17;
   */
  dueTimeHours: number;
  /**
   * The minutes component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeMinutes = 18;
   */
  dueTimeMinutes: number;
  /**
   * If true, a times tables task should be added to homeworks on this plan
   *
   * @generated from protobuf field: bool hasTablesTask = 19;
   */
  hasTablesTask: boolean;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlansRequest
 */
export interface GetHomeworkPlansRequest {
  /**
   * ID of the school the homework plan is in. If it is not a valid UUID an
   * Internal error will be returned.  If the value is empty, there will be no
   * filtering on school, and the and the permission:
   *     'read' from 'tp:homeworkplan' in 'schools'
   * will be required.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * List of studentgroup IDs for get plans for. If empty there will be
   * no filtering on studentGroup ID. If one of the studentGroup IDs is not a
   * valid UUID, an Internal error will be returned.
   *
   * @generated from protobuf field: repeated string studentGroupID = 2;
   */
  studentGroupID: string[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlansResponse
 */
export interface GetHomeworkPlansResponse {
  /**
   * @generated from protobuf field: repeated sparx.homeworkman.v1.HomeworkPlan plans = 1;
   */
  plans: HomeworkPlan[];
}
/**
 * Data about a homework definition in a homework plan.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.PlanDefinition
 */
export interface PlanDefinition {
  /**
   * ID of the plan definition
   *
   * @generated from protobuf field: string definitionID = 1;
   */
  definitionID: string;
  /**
   * Date the homework starts
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
  /**
   * Date the homework ends
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 3;
   */
  endDate?: Timestamp;
  /**
   * Week index the homework is set in. This is not used when editing a homework
   * definition but is set based on the term data when retrieving the
   * definitions
   *
   * @generated from protobuf field: int32 week = 4;
   */
  week: number;
  /**
   * Max weight of SOW items which should be included in the homework
   *
   * @deprecated
   * @generated from protobuf field: int32 weight = 5 [deprecated = true];
   */
  weight: number;
  /**
   * The type of the definition
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanType type = 6;
   */
  type: PlanType;
  /**
   * The length of the definition
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanDefinitionLength length = 7;
   */
  length: PlanDefinitionLength;
  /**
   * DataCycle of the definition (1 based index)
   *
   * @generated from protobuf field: int32 dataCycle = 8;
   */
  dataCycle: number;
  /**
   * Week index within the data cycle of the definition (1 based index)
   *
   * @generated from protobuf field: int32 weekIndexInDataCycle = 9;
   */
  weekIndexInDataCycle: number;
  /**
   * Date the homework was deleted, if set
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTimestamp = 10;
   */
  deletedTimestamp?: Timestamp;
}
/**
 * Request homework definitions with a specific start date.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsRequest
 */
export interface GetHomeworkDefinitionsRequest {
  /**
   * Start date for the homework definition
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 1;
   */
  startDate?: Timestamp;
  /**
   * Whether the response should include homework definitions that are in the
   * future and haven't been created yet (i.e. definition type is DEFAULT).
   * NOTE: The upcoming homework definition for each homework plan will
   * always be created if it doesn't exist so you don't need to set
   * this flag to get the homework currently being planned.
   *
   * @generated from protobuf field: bool includeNotPlanned = 2;
   */
  includeNotPlanned: boolean;
}
/**
 * Response containing PlanDefinitions along with the school, group and
 * SOWItems that they correspond to.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsResponse
 */
export interface GetHomeworkDefinitionsResponse {
  /**
   * List of homework definitions
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.GetHomeworkDefinitionsResponse.HomeworkDefinition definitions = 1;
   */
  definitions: GetHomeworkDefinitionsResponse_HomeworkDefinition[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsResponse.HomeworkDefinition
 */
export interface GetHomeworkDefinitionsResponse_HomeworkDefinition {
  /**
   * ID of the school the student group is in
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * The homework definition
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanDefinition definition = 3;
   */
  definition?: PlanDefinition;
  /**
   * The scheme of work
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.SOWItem sowItems = 4;
   */
  sowItems: SOWItem[];
  /**
   * The plan items in the definition
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.PlanItem planItems = 5;
   */
  planItems: PlanItem[];
}
/**
 * Request a homework plan for a student group
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlanRequest
 */
export interface GetHomeworkPlanRequest {
  /**
   * ID of the school the student group is in
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * Whether to also return the SOW
   *
   * @generated from protobuf field: bool includeSOW = 3;
   */
  includeSOW: boolean;
  /**
   * Whether to also return the homework definitions
   *
   * @generated from protobuf field: bool includeDefinitions = 4;
   */
  includeDefinitions: boolean;
  /**
   * IM69 - Whether to include the homework definitions from previous years
   * (for cases where the class has been reused over multiple years)
   *
   * @generated from protobuf field: bool iM69IncludePreviousYearDefinitions = 5;
   */
  iM69IncludePreviousYearDefinitions: boolean;
}
/**
 * Response containing the homework plan
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlanResponse
 */
export interface GetHomeworkPlanResponse {
  /**
   * Ordered list of plan items for the homework plan
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.PlanItem planItems = 1;
   */
  planItems: PlanItem[];
  /**
   * List of SOW items with data about the plan items
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.SOWItem sowItems = 2;
   */
  sowItems: SOWItem[];
  /**
   * List of homework definitions for the plan
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.PlanDefinition definitions = 3;
   */
  definitions: PlanDefinition[];
  /**
   * Type of homework plan
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkPlanVersion version = 4;
   */
  version: HomeworkPlanVersion;
  /**
   * ID of the plans scheme of work
   *
   * @generated from protobuf field: string sowID = 5;
   */
  sowID: string;
  /**
   * The type of content the SOW unit content IDs link to
   *
   * @generated from protobuf field: sparx.homeworkman.v1.ContentType sowContentType = 6;
   */
  sowContentType: ContentType;
  /**
   * The timestamp that the next ungenerated definition should be generated
   * at. The timezone of the school is considered when deriving this.
   *
   * @generated from protobuf field: google.protobuf.Timestamp nextGenerationTime = 12;
   */
  nextGenerationTime?: Timestamp;
  /**
   * The type of the plan
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkPlanType planType = 13;
   */
  planType: HomeworkPlanType;
  /**
   * The hours component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeHours = 14;
   */
  setTimeHours: number;
  /**
   * The minutes component of the homework set time (in the school tz)
   *
   * @generated from protobuf field: int32 setTimeMinutes = 15;
   */
  setTimeMinutes: number;
  /**
   * The resource name of the most common curriculum of the items in the
   * plan. If there are no items this will be the base curriculum of the
   * scheme of learning. If there is no scheme and no items, this will be
   * empty. In case of a tie, this could return any one of the most common
   * names.
   *
   * @generated from protobuf field: string curriculumName = 16;
   */
  curriculumName: string;
  /**
   * The length in minutes of the compulsory packages for homeworks on this
   * plan. A value of 0 means no compulsory packages will be generated.
   *
   * @generated from protobuf field: int32 compulsoryMinutes = 17;
   */
  compulsoryMinutes: number;
  /**
   * The hours component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeHours = 18;
   */
  dueTimeHours: number;
  /**
   * The minutes component of the homework due time (in the school tz)
   *
   * @generated from protobuf field: int32 dueTimeMinutes = 19;
   */
  dueTimeMinutes: number;
  /**
   * If true, a times tables task should be added to homeworks on this plan
   *
   * @generated from protobuf field: bool hasTablesTask = 20;
   */
  hasTablesTask: boolean;
}
/**
 * Request an action on a homework plan
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.DoHomeworkPlanActionRequest
 */
export interface DoHomeworkPlanActionRequest {
  /**
   * ID of the school the student group is in. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * ID of the plan item to modify.
   * Not used with the ADD_NEW_ITEM or REMOVE_DEF_ITEMS_FROM_CONSOLIDATION
   * actions.
   *
   * @generated from protobuf field: string planItemID = 3;
   */
  planItemID: string;
  /**
   * Action to perform on the homework plan
   *
   * @generated from protobuf field: sparx.homeworkman.v1.HomeworkPlanAction action = 4;
   */
  action: HomeworkPlanAction;
  /**
   * The datacycle to move the item to or to toggle homework state in. Only
   * used with MOVE_ITEM, ADD_NEW_ITEM and TOGGLE_HOMEWORK_OFF actions.
   *
   * @generated from protobuf field: int32 dataCycle = 5;
   */
  dataCycle: number;
  /**
   * The week index in the datacycle to move the item to or to toggle
   * homework state of. Only used with MOVE_ITEM, ADD_NEW_ITEM and
   * TOGGLE_HOMEWORK_OFF actions.
   *
   * @generated from protobuf field: int32 weekIndexInDataCycle = 6;
   */
  weekIndexInDataCycle: number;
  /**
   * ID of the curriculum topic to add.
   * Only used with ADD_NEW_ITEM action.
   *
   * @generated from protobuf field: string curriculumTopicID = 7;
   */
  curriculumTopicID: string;
  /**
   * The direction to move the weeks.
   * Only used with the MOVE_WEEKS action.
   *
   * @generated from protobuf field: sparx.homeworkman.v1.MoveWeeksDirection moveWeeksDirection = 8;
   */
  moveWeeksDirection: MoveWeeksDirection;
  /**
   * The ID of a definition. This is used only with action
   * REMOVE_DEF_ITEMS_FROM_CONSOLIDATION - removing all topics from a
   * definition.
   *
   * @generated from protobuf field: string definitionID = 9;
   */
  definitionID: string;
}
/**
 * Request some changes to the homework definitions for a plan
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.CommitDefinitionChangesRequest
 */
export interface CommitDefinitionChangesRequest {
  /**
   * ID of the school the student group is in. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * PlanDefinition to mutate
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PlanDefinition mutation = 3;
   */
  mutation?: PlanDefinition;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.CreateHomeworkPlanResponse
 */
export interface CreateHomeworkPlanResponse {}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.IncludeTopicsInNextHomeworkRequest
 */
export interface IncludeTopicsInNextHomeworkRequest {
  /**
   * ID of the school the student group is in. The field is required and must
   * be a uuid.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group. The field is required and must be a uuid.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * ID for the request, used for idempotency:
   * Multiple requests with the same requestID and curriculumTopicIDs will
   * succeed but only add each topic to the next homework once.
   *
   * @generated from protobuf field: string requestID = 3;
   */
  requestID: string;
  /**
   * IDs of the curriculum topics to add. Cannot be empty and all IDs must
   * be uuids.
   *
   * @generated from protobuf field: repeated string curriculumTopicIDs = 7;
   */
  curriculumTopicIDs: string[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.IncludeTopicsInNextHomeworkResponse
 */
export interface IncludeTopicsInNextHomeworkResponse {
  /**
   * The datacycle the topics have been included in.
   *
   * @generated from protobuf field: int32 dataCycle = 1;
   */
  dataCycle: number;
  /**
   * The week index in the datacycle the items have been included in.
   *
   * @generated from protobuf field: int32 weekIndexInDataCycle = 2;
   */
  weekIndexInDataCycle: number;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.DeleteStudentDataRequest
 */
export interface DeleteStudentDataRequest {
  /**
   * The school that the students are in.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The IDs of the students whose data we want to be deleted. If a student
   * is not in the school then their data will not be deleted.
   *
   * @generated from protobuf field: repeated string studentIDs = 2;
   */
  studentIDs: string[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.DeleteStudentDataResponse
 */
export interface DeleteStudentDataResponse {}
/**
 * @generated from protobuf message sparx.homeworkman.v1.StudentHomeworkComment
 */
export interface StudentHomeworkComment {
  /**
   * @generated from protobuf field: string homeworkID = 1;
   */
  homeworkID: string;
  /**
   * @generated from protobuf field: string studentID = 2;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string staffID = 3;
   */
  staffID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp createdTimestamp = 4;
   */
  createdTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updatedTimestamp = 5;
   */
  updatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: string comment = 6;
   */
  comment: string;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetStudentHomeworkCommentsRequest
 */
export interface GetStudentHomeworkCommentsRequest {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string homeworkID = 2;
   */
  homeworkID: string;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetStudentHomeworkCommentsResponse
 */
export interface GetStudentHomeworkCommentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.homeworkman.v1.StudentHomeworkComment comments = 1;
   */
  comments: StudentHomeworkComment[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.UpsertStudentHomeworkCommentRequest
 */
export interface UpsertStudentHomeworkCommentRequest {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string homeworkID = 2;
   */
  homeworkID: string;
  /**
   * @generated from protobuf field: string studentID = 3;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string staffID = 4;
   */
  staffID: string;
  /**
   * @generated from protobuf field: string comment = 5;
   */
  comment: string;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.UpsertStudentHomeworkCommentResponse
 */
export interface UpsertStudentHomeworkCommentResponse {
  /**
   * @generated from protobuf field: sparx.homeworkman.v1.StudentHomeworkComment comment = 1;
   */
  comment?: StudentHomeworkComment;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.ResetPlansRequest
 */
export interface ResetPlansRequest {
  /**
   * The school that the student groups are in.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The student groups to have their plans reset.
   *
   * @generated from protobuf field: repeated string studentGroupIDs = 2;
   */
  studentGroupIDs: string[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.ResetPlansResponse
 */
export interface ResetPlansResponse {}
/**
 * @generated from protobuf message sparx.homeworkman.v1.StudentHomeworkCompletionStreak
 */
export interface StudentHomeworkCompletionStreak {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * The count of fully complete homeworks since their last incomplete
   * homework.
   *
   * @generated from protobuf field: int32 completeHomeworkStreak = 2;
   */
  completeHomeworkStreak: number;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetStudentCompletionStreakRequest
 */
export interface GetStudentCompletionStreakRequest {
  /**
   * The school ID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The students to calculate streaks for.
   *
   * @generated from protobuf field: repeated string studentIDs = 2;
   */
  studentIDs: string[];
  /**
   * The time at which to calculate the streaks. Any homeworks with end_date
   * on or before this time will be considered for the streak.
   *
   * @generated from protobuf field: google.protobuf.Timestamp at_time = 3;
   */
  atTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetStudentCompletionStreakResponse
 */
export interface GetStudentCompletionStreakResponse {
  /**
   * @generated from protobuf field: repeated sparx.homeworkman.v1.StudentHomeworkCompletionStreak completionStreaks = 1;
   */
  completionStreaks: StudentHomeworkCompletionStreak[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.StudentHasPreviousHomework
 */
export interface StudentHasPreviousHomework {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: bool hasPreviousHomework = 2;
   */
  hasPreviousHomework: boolean;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetHasPreviousHomeworkRequest
 */
export interface GetHasPreviousHomeworkRequest {
  /**
   * The school ID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The students to fetch previous homework info for.
   *
   * @generated from protobuf field: repeated string studentIDs = 2;
   */
  studentIDs: string[];
  /**
   * The date range to check packages were set during. Leave empty for all
   * time
   *
   * @generated from protobuf field: google.type.Interval dateRange = 3;
   */
  dateRange?: Interval;
  /**
   * Include homeworks which were marked as deleted by the teacher
   *
   * @generated from protobuf field: bool includeDeleted = 4;
   */
  includeDeleted: boolean;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetHasPreviousHomeworkResponse
 */
export interface GetHasPreviousHomeworkResponse {
  /**
   * @generated from protobuf field: repeated sparx.homeworkman.v1.StudentHasPreviousHomework students = 1;
   */
  students: StudentHasPreviousHomework[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetMostRecentlyDueHomeworkRequest
 */
export interface GetMostRecentlyDueHomeworkRequest {
  /**
   * School ID that the student group belongs to.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * Student Group ID to find previous homework for.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * Only include homeworks before this time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp dueBeforeTime = 3;
   */
  dueBeforeTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.GetMostRecentlyDueHomeworkResponse
 */
export interface GetMostRecentlyDueHomeworkResponse {
  /**
   * The homework that was found.
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Homework homework = 1;
   */
  homework?: Homework;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.CreatePackageRequest
 */
export interface CreatePackageRequest {
  /**
   * The only fields that should be populated in this package are:
   * - schoolID
   * - homeworkID
   * - studentID
   * - packageType
   *
   * @generated from protobuf field: sparx.homeworkman.v1.Package homework_package = 2;
   */
  homeworkPackage?: Package;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.BatchCreatePackagesRequest
 */
export interface BatchCreatePackagesRequest {
  /**
   * The package requests to create.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.CreatePackageRequest requests = 2;
   */
  requests: CreatePackageRequest[];
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.BatchCreatePackagesResponse
 */
export interface BatchCreatePackagesResponse {
  /**
   * The created packages.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 1;
   */
  packages: Package[];
}
/**
 * Request to acquire a launch lock on a plan. This marks the plan as being
 * launched, and other attempts to launch it within the following 2 minutes will
 * not be able to acquire the lock
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlanLaunchLockRequest
 */
export interface GetHomeworkPlanLaunchLockRequest {
  /**
   * ID of the school the student group is in
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the student group
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
}
/**
 * Response to a request to acquire a launch lock on a plan
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetHomeworkPlanLaunchLockResponse
 */
export interface GetHomeworkPlanLaunchLockResponse {
  /**
   * Whether the lock was acquired
   *
   * @generated from protobuf field: bool lock_acquired = 1;
   */
  lockAcquired: boolean;
}
/**
 * The request to generate a homework for a group of students in a student
 * group. The definition and packages must already exist in the db.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GenerateHomeworkRequest
 */
export interface GenerateHomeworkRequest {
  /**
   * The school ID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The student group ID.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * The ID of the homework definition being generated.
   *
   * @generated from protobuf field: string definitionID = 3;
   */
  definitionID: string;
  /**
   * These packages should already exist in the PENDING state before calling
   * this endpoint.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 4;
   */
  packages: Package[];
  /**
   * The in-focus items in the defintion being generated. These will be added
   * to the homework_item table if not already present.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.PlanItem inFocusItems = 5;
   */
  inFocusItems: PlanItem[];
  /**
   * The timestamp of when this homework is due (in UTC)
   *
   * @generated from protobuf field: google.protobuf.Timestamp dueTimestamp = 6;
   */
  dueTimestamp?: Timestamp;
}
/**
 * The request message for deleting a PlanDefinition.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.DeletePlanDefinitionRequest
 */
export interface DeletePlanDefinitionRequest {
  /**
   * The school ID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * ID of the plan definition
   *
   * @generated from protobuf field: string definitionID = 2;
   */
  definitionID: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.SetLastGenerationDateRequest
 */
export interface SetLastGenerationDateRequest {
  /**
   * The school ID.
   *
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * The student group ID.
   *
   * @generated from protobuf field: string studentGroupID = 2;
   */
  studentGroupID: string;
  /**
   * The new value for the last_generation_date. This must be after the
   * plan's current last_generation_date.
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastGenerationDate = 3;
   */
  lastGenerationDate?: Timestamp;
}
/**
 * Request message for GetNextGenerationTimes.
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetNextGenerationTimesRequest
 */
export interface GetNextGenerationTimesRequest {
  /**
   * Optional. Client provided value for current time. Defaults to server's
   * current time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp now = 1;
   */
  now?: Timestamp;
  /**
   * A page token from a previous GetNextGenerationTimesResponse
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * Information about the next generation time for a homework plan
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.NextGenerationTime
 */
export interface NextGenerationTime {
  /**
   * The school id
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The student group id
   *
   * @generated from protobuf field: string student_group_id = 2;
   */
  studentGroupId: string;
  /**
   * The candidate next time for generation. Does not mean homework will
   * actually be generated at this time (e.g. school holidays).
   * This value is timezone-less, and so needs to be interpreted in
   * the school's timezone
   *
   * @generated from protobuf field: google.type.DateTime next_generation_time = 3;
   */
  nextGenerationTime?: DateTime;
  /**
   * The time, in UTC, that this group last had homework generated at.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_generation_time = 4;
   */
  lastGenerationTime?: Timestamp;
}
/**
 * Response message for GetNextGenerationTimes
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.GetNextGenerationTimesResponse
 */
export interface GetNextGenerationTimesResponse {
  /**
   * Next generation times
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.NextGenerationTime results = 1;
   */
  results: NextGenerationTime[];
  /**
   * A page token to get the next set or results from the server
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.homeworkman.v1.RemovedConsolidationTopic
 */
export interface RemovedConsolidationTopic {
  /**
   * The resource name of the topic.
   *
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * When the topic was marked to be removed from consolidation
   *
   * @generated from protobuf field: google.protobuf.Timestamp removed_timestamp = 2;
   */
  removedTimestamp?: Timestamp;
}
/**
 * Request message for GetRemovedConsolidationTopics
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetRemovedConsolidationTopicsRequest
 */
export interface GetRemovedConsolidationTopicsRequest {
  /**
   * The student group id
   *
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
}
/**
 * Response message for GetRemovedConsolidationTopics
 *
 * @generated from protobuf message sparx.homeworkman.v1.GetRemovedConsolidationTopicsResponse
 */
export interface GetRemovedConsolidationTopicsResponse {
  /**
   * The topics set to be removed from consolidation
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.RemovedConsolidationTopic removed_topics = 1;
   */
  removedTopics: RemovedConsolidationTopic[];
}
/**
 * Request message for AddRemovedConsolidationTopicsRequest
 *
 * @generated from protobuf message sparx.homeworkman.v1.AddRemovedConsolidationTopicsRequest
 */
export interface AddRemovedConsolidationTopicsRequest {
  /**
   * The student group these topics should be removed for
   *
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * The resource names of topics to remove
   *
   * @generated from protobuf field: repeated string topic_names = 2;
   */
  topicNames: string[];
  /**
   * custom_removed_timestamp is the timestamp to use when marking the topic as
   * removed. If unspecified the current time will be used.
   *
   * @generated from protobuf field: google.protobuf.Timestamp custom_removed_timestamp = 3;
   */
  customRemovedTimestamp?: Timestamp;
}
/**
 * TODO - IM-69 - remove after incident resolved
 *
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsRequest
 */
export interface FixHomeworkPlansWithOutdatedSOLsRequest {
  /**
   * @generated from protobuf field: repeated string school_names = 1;
   */
  schoolNames: string[];
  /**
   * @generated from protobuf field: bool do_it = 2;
   */
  doIt: boolean;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.FixedPlanForSchool
 */
export interface FixedPlanForSchool {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string studentgroup_id = 2;
   */
  studentgroupId: string;
  /**
   * @generated from protobuf field: string sow_id = 3;
   */
  sowId: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsResponse
 */
export interface FixHomeworkPlansWithOutdatedSOLsResponse {
  /**
   * @generated from protobuf field: repeated sparx.homeworkman.v1.FixedPlanForSchool fixed = 1;
   */
  fixed: FixedPlanForSchool[];
}
/**
 *
 * Different statuses of a package
 *
 * @generated from protobuf enum sparx.homeworkman.v1.PackageStatus
 */
export enum PackageStatus {
  /**
   * Created in TP but should not appear in sparxweb
   *
   * @generated from protobuf enum value: PENDING = 0;
   */
  PENDING = 0,
  /**
   * Created in TP and placeholder should appear in sparxweb
   *
   * @generated from protobuf enum value: PLACEHOLDER = 1;
   */
  PLACEHOLDER = 1,
  /**
   * Generation has been requested
   *
   * @generated from protobuf enum value: REQUESTED = 2;
   */
  REQUESTED = 2,
  /**
   * The package is generated but may not be ready in sparxweb
   *
   * @generated from protobuf enum value: GENERATED = 3;
   */
  GENERATED = 3,
  /**
   * The package is ready to deliver in sparxweb
   *
   * @generated from protobuf enum value: READY = 4;
   */
  READY = 4,
  /**
   * The package failed to generate
   *
   * @generated from protobuf enum value: GENERATION_FAILED = 5;
   */
  GENERATION_FAILED = 5,
}
/**
 *
 * The status of a student in a homework.
 *
 * @generated from protobuf enum sparx.homeworkman.v1.HomeworkStatus
 */
export enum HomeworkStatus {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: DETENTION = 1;
   */
  DETENTION = 1,
  /**
   * @generated from protobuf enum value: EXTENSION = 2;
   */
  EXTENSION = 2,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.ContentType
 */
export enum ContentType {
  /**
   * @generated from protobuf enum value: NULL_CONTENT_TYPE = 0;
   */
  NULL_CONTENT_TYPE = 0,
  /**
   * SOWs with lesson group based items are no longer supported.
   *
   * @deprecated
   * @generated from protobuf enum value: LESSON_GROUPS = 1 [deprecated = true];
   */
  LESSON_GROUPS = 1,
  /**
   * @generated from protobuf enum value: TOPICS = 2;
   */
  TOPICS = 2,
  /**
   * TODO: feature_flag: primaryTables
   *
   * @generated from protobuf enum value: PRIMARY_TABLES = 100;
   */
  PRIMARY_TABLES = 100,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.PlanType
 */
export enum PlanType {
  /**
   * @generated from protobuf enum value: DONE = 0;
   */
  DONE = 0,
  /**
   * @generated from protobuf enum value: PLANNED = 1;
   */
  PLANNED = 1,
  /**
   * @generated from protobuf enum value: DEFAULT = 2;
   */
  DEFAULT = 2,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.Weekday
 */
export enum Weekday {
  /**
   * @generated from protobuf enum value: SUNDAY = 0;
   */
  SUNDAY = 0,
  /**
   * @generated from protobuf enum value: MONDAY = 1;
   */
  MONDAY = 1,
  /**
   * @generated from protobuf enum value: TUESDAY = 2;
   */
  TUESDAY = 2,
  /**
   * @generated from protobuf enum value: WEDNESDAY = 3;
   */
  WEDNESDAY = 3,
  /**
   * @generated from protobuf enum value: THURSDAY = 4;
   */
  THURSDAY = 4,
  /**
   * @generated from protobuf enum value: FRIDAY = 5;
   */
  FRIDAY = 5,
  /**
   * @generated from protobuf enum value: SATURDAY = 6;
   */
  SATURDAY = 6,
  /**
   * @generated from protobuf enum value: NULL = 100;
   */
  NULL = 100,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.HomeworkPlanType
 */
export enum HomeworkPlanType {
  /**
   * Not set to a valid value
   *
   * @generated from protobuf enum value: HPT_UNDEFINED = 0;
   */
  HPT_UNDEFINED = 0,
  /**
   * Type of homework plan based on a scheme of learning
   *
   * @generated from protobuf enum value: HPT_SOL = 1;
   */
  HPT_SOL = 1,
  /**
   * Type of homework plan for primary times tables
   *
   * @generated from protobuf enum value: HPT_PTT = 2;
   */
  HPT_PTT = 2,
  /**
   * Type of homework plan for lesson driven homework
   *
   * @generated from protobuf enum value: HPT_LESSON_DRIVEN_HOMEWORK = 3;
   */
  HPT_LESSON_DRIVEN_HOMEWORK = 3,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.PlanDefinitionLength
 */
export enum PlanDefinitionLength {
  /**
   * @generated from protobuf enum value: FULL = 0;
   */
  FULL = 0,
  /**
   * @generated from protobuf enum value: HALF = 1;
   */
  HALF = 1,
  /**
   * @generated from protobuf enum value: OPTIONAL = 2;
   */
  OPTIONAL = 2,
  /**
   * Special case for get homework
   *
   * @generated from protobuf enum value: UNKNOWN = 100;
   */
  UNKNOWN = 100,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.HomeworkPlanVersion
 */
export enum HomeworkPlanVersion {
  /**
   * Default represents the normal homework plan version
   *
   * @generated from protobuf enum value: NORMAL = 0;
   */
  NORMAL = 0,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.HomeworkPlanAction
 */
export enum HomeworkPlanAction {
  /**
   * Has no effect
   *
   * @deprecated
   * @generated from protobuf enum value: ADD_ITEM = 0 [deprecated = true];
   */
  ADD_ITEM = 0,
  /**
   * Skip over the item so it is not set in homework
   *
   * @generated from protobuf enum value: SKIP_ITEM = 1;
   */
  SKIP_ITEM = 1,
  /**
   * Has no effect
   *
   * @deprecated
   * @generated from protobuf enum value: RETURN_ITEM = 2 [deprecated = true];
   */
  RETURN_ITEM = 2,
  /**
   * Move the item to a specific datacyle and week index in datacycle.
   *
   * @generated from protobuf enum value: MOVE_ITEM = 3;
   */
  MOVE_ITEM = 3,
  /**
   * Add a new item to a specific datacyle and week index in datacycle.
   *
   * @generated from protobuf enum value: ADD_NEW_ITEM = 4;
   */
  ADD_NEW_ITEM = 4,
  /**
   * Moves all items from a specified week onwards either forwards or backwards
   * by one week.
   *
   * @generated from protobuf enum value: MOVE_WEEKS = 5;
   */
  MOVE_WEEKS = 5,
  /**
   * Remove an item from consolidation
   *
   * @generated from protobuf enum value: REMOVE_ITEM_FROM_CONSOLIDATION = 6;
   */
  REMOVE_ITEM_FROM_CONSOLIDATION = 6,
  /**
   * Include an item in consolidation
   *
   * @generated from protobuf enum value: INCLUDE_ITEM_IN_CONSOLIDATION = 7;
   */
  INCLUDE_ITEM_IN_CONSOLIDATION = 7,
  /**
   * Toggles whether homework should be off for a specific datacycle and week
   * index in datacycle. This would make more sense as a definition muatation
   * but weeks with no homework don't have definitions so no definition can
   * be mutated to turn homework on.
   *
   * @generated from protobuf enum value: TOGGLE_HOMEWORK_OFF = 8;
   */
  TOGGLE_HOMEWORK_OFF = 8,
  /**
   * Removes all items in a definition from consolidation
   *
   * @generated from protobuf enum value: REMOVE_DEF_ITEMS_FROM_CONSOLIDATION = 9;
   */
  REMOVE_DEF_ITEMS_FROM_CONSOLIDATION = 9,
}
/**
 * @generated from protobuf enum sparx.homeworkman.v1.MoveWeeksDirection
 */
export enum MoveWeeksDirection {
  /**
   * Backwards moves all the plan items from the chosen week and beyond back by
   * one week (move up in planner).
   *
   * @generated from protobuf enum value: BACKWARDS = 0;
   */
  BACKWARDS = 0,
  /**
   * Forwards moves all the plan items from the chosen week and beyond forwards
   * by one week (move down in planner).
   *
   * @generated from protobuf enum value: FORWARDS = 1;
   */
  FORWARDS = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
  constructor() {
    super('sparx.homeworkman.v1.HealthCheckRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType<HealthCheckResponse> {
  constructor() {
    super('sparx.homeworkman.v1.HealthCheckResponse', [
      { no: 1, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Homework$Type extends MessageType<Homework> {
  constructor() {
    super('sparx.homeworkman.v1.Homework', [
      { no: 1, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'expiryDate', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'length',
        kind: 'enum',
        T: () => [
          'sparx.homeworkman.v1.PlanDefinitionLength',
          PlanDefinitionLength,
        ],
      },
      { no: 9, name: 'deletedTimestamp', kind: 'message', T: () => Timestamp },
      { no: 10, name: 'dueTimestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.Homework
 */
export const Homework = new Homework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package$Type extends MessageType<Package> {
  constructor() {
    super('sparx.homeworkman.v1.Package', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'packageType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'numTaskItems',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'numTaskItemsComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'numTaskItemsWrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'numTaskItemsVideoWrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 10, name: 'numTasks', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 11,
        name: 'numTasksComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'numTasksInProgress',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 13,
        name: 'numAttempts',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 14,
        name: 'hasBeenComplete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 15, name: 'revision', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 16, name: 'completionDate', kind: 'message', T: () => Timestamp },
      { no: 17, name: 'timeTaken', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      {
        no: 18,
        name: 'packageState',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: 'truncatedWorkTime',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 20,
        name: 'truncatedWatchTime',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 21,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.PackageStatus', PackageStatus],
      },
      { no: 22, name: 'lastUpdateTime', kind: 'message', T: () => Timestamp },
      { no: 23, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 24,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.Package
 */
export const Package = new Package$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomework$Type extends MessageType<StudentHomework> {
  constructor() {
    super('sparx.homeworkman.v1.StudentHomework', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkStatus', HomeworkStatus],
      },
      { no: 5, name: 'deletedTimestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.StudentHomework
 */
export const StudentHomework = new StudentHomework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkSummary$Type extends MessageType<HomeworkSummary> {
  constructor() {
    super('sparx.homeworkman.v1.HomeworkSummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkStatus', HomeworkStatus],
      },
      { no: 5, name: 'compulsory', kind: 'message', T: () => Package },
      { no: 6, name: 'optional', kind: 'message', T: () => Package },
      { no: 7, name: 'targets', kind: 'message', T: () => Package },
      { no: 8, name: 'deletedTimestamp', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'deletedForStudentTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.HomeworkSummary
 */
export const HomeworkSummary = new HomeworkSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkStat$Type extends MessageType<HomeworkStat> {
  constructor() {
    super('sparx.homeworkman.v1.HomeworkStat', [
      { no: 1, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'packageType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'total', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'complete', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'completeHandin',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'started', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.HomeworkStat
 */
export const HomeworkStat = new HomeworkStat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksRequest$Type extends MessageType<GetHomeworksRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworksRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'startsAfter', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'startsBefore', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'endsAfter', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'endsBefore', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'expiresAfter', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworksRequest
 */
export const GetHomeworksRequest = new GetHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksResponse$Type extends MessageType<GetHomeworksResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworksResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworksResponse
 */
export const GetHomeworksResponse = new GetHomeworksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteHomeworkRequest$Type extends MessageType<DeleteHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.DeleteHomeworkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.DeleteHomeworkRequest
 */
export const DeleteHomeworkRequest = new DeleteHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagesRequest$Type extends MessageType<GetPackagesRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetPackagesRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'packageID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetPackagesRequest
 */
export const GetPackagesRequest = new GetPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagesResponse$Type extends MessageType<GetPackagesResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetPackagesResponse
 */
export const GetPackagesResponse = new GetPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkStudentsRequest$Type extends MessageType<GetHomeworkStudentsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkStudentsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkStudentsRequest
 */
export const GetHomeworkStudentsRequest = new GetHomeworkStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkStudentsResponse$Type extends MessageType<GetHomeworkStudentsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkStudentsResponse', [
      {
        no: 1,
        name: 'studentHomeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHomework,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkStudentsResponse
 */
export const GetHomeworkStudentsResponse =
  new GetHomeworkStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkSummariesRequest$Type extends MessageType<GetHomeworkSummariesRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkSummariesRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'includeGenerationFailed',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkSummariesRequest
 */
export const GetHomeworkSummariesRequest =
  new GetHomeworkSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkSummariesResponse$Type extends MessageType<GetHomeworkSummariesResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkSummariesResponse', [
      {
        no: 1,
        name: 'summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkSummariesResponse
 */
export const GetHomeworkSummariesResponse =
  new GetHomeworkSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkStatsRequest$Type extends MessageType<GetHomeworkStatsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkStatsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkStatsRequest
 */
export const GetHomeworkStatsRequest = new GetHomeworkStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkStatsResponse$Type extends MessageType<GetHomeworkStatsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkStatsResponse', [
      {
        no: 1,
        name: 'stats',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkStat,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkStatsResponse
 */
export const GetHomeworkStatsResponse = new GetHomeworkStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkRequest$Type extends MessageType<CreateHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.CreateHomeworkRequest', [
      { no: 1, name: 'homework', kind: 'message', T: () => Homework },
      {
        no: 2,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreateHomeworkRequest_StudentHomeworkImport,
      },
      {
        no: 3,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CreateHomeworkRequest
 */
export const CreateHomeworkRequest = new CreateHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkRequest_StudentHomeworkImport$Type extends MessageType<CreateHomeworkRequest_StudentHomeworkImport> {
  constructor() {
    super('sparx.homeworkman.v1.CreateHomeworkRequest.StudentHomeworkImport', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkStatus', HomeworkStatus],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CreateHomeworkRequest.StudentHomeworkImport
 */
export const CreateHomeworkRequest_StudentHomeworkImport =
  new CreateHomeworkRequest_StudentHomeworkImport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkResponse$Type extends MessageType<CreateHomeworkResponse> {
  constructor() {
    super('sparx.homeworkman.v1.CreateHomeworkResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CreateHomeworkResponse
 */
export const CreateHomeworkResponse = new CreateHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlanItem$Type extends MessageType<PlanItem> {
  constructor() {
    super('sparx.homeworkman.v1.PlanItem', [
      { no: 1, name: 'planItemID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'definitionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.PlanType', PlanType],
      },
      { no: 6, name: 'skipped', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: 'lessonGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'curriculumTopicID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 9, name: 'dataCycle', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 10,
        name: 'weekIndexInDataCycle',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'removedFromConsolidation',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.PlanItem
 */
export const PlanItem = new PlanItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SOWItem$Type extends MessageType<SOWItem> {
  constructor() {
    super('sparx.homeworkman.v1.SOWItem', [
      { no: 1, name: 'sowItemID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'weight', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'optional', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'defaultIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 6, name: 'hasApp', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'hasNonApp', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'sowID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'lessonGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'curriculumTopicID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.SOWItem
 */
export const SOWItem = new SOWItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPlan$Type extends MessageType<HomeworkPlan> {
  constructor() {
    super('sparx.homeworkman.v1.HomeworkPlan', [
      {
        no: 1,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'defaultWeight',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'defaultSetDay',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.Weekday', Weekday],
      },
      {
        no: 7,
        name: 'defaultDueDay',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.Weekday', Weekday],
      },
      { no: 9, name: 'sowID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 10, name: 'startDate', kind: 'message', T: () => Timestamp },
      {
        no: 12,
        name: 'planType',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkPlanType', HomeworkPlanType],
      },
      {
        no: 13,
        name: 'setTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 14,
        name: 'setTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 15,
        name: 'nextGenerationTime',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 16,
        name: 'compulsoryMinutes',
        kind: 'message',
        T: () => Int32Value,
      },
      {
        no: 17,
        name: 'dueTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 18,
        name: 'dueTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 19,
        name: 'hasTablesTask',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.HomeworkPlan
 */
export const HomeworkPlan = new HomeworkPlan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlansRequest$Type extends MessageType<GetHomeworkPlansRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlansRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlansRequest
 */
export const GetHomeworkPlansRequest = new GetHomeworkPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlansResponse$Type extends MessageType<GetHomeworkPlansResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlansResponse', [
      {
        no: 1,
        name: 'plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPlan,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlansResponse
 */
export const GetHomeworkPlansResponse = new GetHomeworkPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlanDefinition$Type extends MessageType<PlanDefinition> {
  constructor() {
    super('sparx.homeworkman.v1.PlanDefinition', [
      {
        no: 1,
        name: 'definitionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'weight', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.PlanType', PlanType],
      },
      {
        no: 7,
        name: 'length',
        kind: 'enum',
        T: () => [
          'sparx.homeworkman.v1.PlanDefinitionLength',
          PlanDefinitionLength,
        ],
      },
      { no: 8, name: 'dataCycle', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 9,
        name: 'weekIndexInDataCycle',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 10, name: 'deletedTimestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.PlanDefinition
 */
export const PlanDefinition = new PlanDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkDefinitionsRequest$Type extends MessageType<GetHomeworkDefinitionsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkDefinitionsRequest', [
      { no: 1, name: 'startDate', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'includeNotPlanned',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsRequest
 */
export const GetHomeworkDefinitionsRequest =
  new GetHomeworkDefinitionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkDefinitionsResponse$Type extends MessageType<GetHomeworkDefinitionsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkDefinitionsResponse', [
      {
        no: 1,
        name: 'definitions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetHomeworkDefinitionsResponse_HomeworkDefinition,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsResponse
 */
export const GetHomeworkDefinitionsResponse =
  new GetHomeworkDefinitionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkDefinitionsResponse_HomeworkDefinition$Type extends MessageType<GetHomeworkDefinitionsResponse_HomeworkDefinition> {
  constructor() {
    super(
      'sparx.homeworkman.v1.GetHomeworkDefinitionsResponse.HomeworkDefinition',
      [
        { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'studentGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: 'definition', kind: 'message', T: () => PlanDefinition },
        {
          no: 4,
          name: 'sowItems',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SOWItem,
        },
        {
          no: 5,
          name: 'planItems',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PlanItem,
        },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkDefinitionsResponse.HomeworkDefinition
 */
export const GetHomeworkDefinitionsResponse_HomeworkDefinition =
  new GetHomeworkDefinitionsResponse_HomeworkDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanRequest$Type extends MessageType<GetHomeworkPlanRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlanRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'includeSOW', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'includeDefinitions',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'iM69IncludePreviousYearDefinitions',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlanRequest
 */
export const GetHomeworkPlanRequest = new GetHomeworkPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanResponse$Type extends MessageType<GetHomeworkPlanResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlanResponse', [
      {
        no: 1,
        name: 'planItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PlanItem,
      },
      {
        no: 2,
        name: 'sowItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SOWItem,
      },
      {
        no: 3,
        name: 'definitions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PlanDefinition,
      },
      {
        no: 4,
        name: 'version',
        kind: 'enum',
        T: () => [
          'sparx.homeworkman.v1.HomeworkPlanVersion',
          HomeworkPlanVersion,
        ],
      },
      { no: 5, name: 'sowID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'sowContentType',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.ContentType', ContentType],
      },
      {
        no: 12,
        name: 'nextGenerationTime',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 13,
        name: 'planType',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.HomeworkPlanType', HomeworkPlanType],
      },
      {
        no: 14,
        name: 'setTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 15,
        name: 'setTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 16,
        name: 'curriculumName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'compulsoryMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 18,
        name: 'dueTimeHours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 19,
        name: 'dueTimeMinutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 20,
        name: 'hasTablesTask',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlanResponse
 */
export const GetHomeworkPlanResponse = new GetHomeworkPlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoHomeworkPlanActionRequest$Type extends MessageType<DoHomeworkPlanActionRequest> {
  constructor() {
    super('sparx.homeworkman.v1.DoHomeworkPlanActionRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'planItemID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'action',
        kind: 'enum',
        T: () => [
          'sparx.homeworkman.v1.HomeworkPlanAction',
          HomeworkPlanAction,
        ],
      },
      { no: 5, name: 'dataCycle', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'weekIndexInDataCycle',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'curriculumTopicID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'moveWeeksDirection',
        kind: 'enum',
        T: () => [
          'sparx.homeworkman.v1.MoveWeeksDirection',
          MoveWeeksDirection,
        ],
      },
      {
        no: 9,
        name: 'definitionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.DoHomeworkPlanActionRequest
 */
export const DoHomeworkPlanActionRequest =
  new DoHomeworkPlanActionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommitDefinitionChangesRequest$Type extends MessageType<CommitDefinitionChangesRequest> {
  constructor() {
    super('sparx.homeworkman.v1.CommitDefinitionChangesRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'mutation', kind: 'message', T: () => PlanDefinition },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CommitDefinitionChangesRequest
 */
export const CommitDefinitionChangesRequest =
  new CommitDefinitionChangesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHomeworkPlanResponse$Type extends MessageType<CreateHomeworkPlanResponse> {
  constructor() {
    super('sparx.homeworkman.v1.CreateHomeworkPlanResponse', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CreateHomeworkPlanResponse
 */
export const CreateHomeworkPlanResponse = new CreateHomeworkPlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncludeTopicsInNextHomeworkRequest$Type extends MessageType<IncludeTopicsInNextHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.IncludeTopicsInNextHomeworkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'requestID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'curriculumTopicIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.IncludeTopicsInNextHomeworkRequest
 */
export const IncludeTopicsInNextHomeworkRequest =
  new IncludeTopicsInNextHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncludeTopicsInNextHomeworkResponse$Type extends MessageType<IncludeTopicsInNextHomeworkResponse> {
  constructor() {
    super('sparx.homeworkman.v1.IncludeTopicsInNextHomeworkResponse', [
      { no: 1, name: 'dataCycle', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'weekIndexInDataCycle',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.IncludeTopicsInNextHomeworkResponse
 */
export const IncludeTopicsInNextHomeworkResponse =
  new IncludeTopicsInNextHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteStudentDataRequest$Type extends MessageType<DeleteStudentDataRequest> {
  constructor() {
    super('sparx.homeworkman.v1.DeleteStudentDataRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.DeleteStudentDataRequest
 */
export const DeleteStudentDataRequest = new DeleteStudentDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteStudentDataResponse$Type extends MessageType<DeleteStudentDataResponse> {
  constructor() {
    super('sparx.homeworkman.v1.DeleteStudentDataResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.DeleteStudentDataResponse
 */
export const DeleteStudentDataResponse = new DeleteStudentDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomeworkComment$Type extends MessageType<StudentHomeworkComment> {
  constructor() {
    super('sparx.homeworkman.v1.StudentHomeworkComment', [
      { no: 1, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'createdTimestamp', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'updatedTimestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.StudentHomeworkComment
 */
export const StudentHomeworkComment = new StudentHomeworkComment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkCommentsRequest$Type extends MessageType<GetStudentHomeworkCommentsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetStudentHomeworkCommentsRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetStudentHomeworkCommentsRequest
 */
export const GetStudentHomeworkCommentsRequest =
  new GetStudentHomeworkCommentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentHomeworkCommentsResponse$Type extends MessageType<GetStudentHomeworkCommentsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetStudentHomeworkCommentsResponse', [
      {
        no: 1,
        name: 'comments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHomeworkComment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetStudentHomeworkCommentsResponse
 */
export const GetStudentHomeworkCommentsResponse =
  new GetStudentHomeworkCommentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertStudentHomeworkCommentRequest$Type extends MessageType<UpsertStudentHomeworkCommentRequest> {
  constructor() {
    super('sparx.homeworkman.v1.UpsertStudentHomeworkCommentRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'staffID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.UpsertStudentHomeworkCommentRequest
 */
export const UpsertStudentHomeworkCommentRequest =
  new UpsertStudentHomeworkCommentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertStudentHomeworkCommentResponse$Type extends MessageType<UpsertStudentHomeworkCommentResponse> {
  constructor() {
    super('sparx.homeworkman.v1.UpsertStudentHomeworkCommentResponse', [
      {
        no: 1,
        name: 'comment',
        kind: 'message',
        T: () => StudentHomeworkComment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.UpsertStudentHomeworkCommentResponse
 */
export const UpsertStudentHomeworkCommentResponse =
  new UpsertStudentHomeworkCommentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPlansRequest$Type extends MessageType<ResetPlansRequest> {
  constructor() {
    super('sparx.homeworkman.v1.ResetPlansRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.ResetPlansRequest
 */
export const ResetPlansRequest = new ResetPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPlansResponse$Type extends MessageType<ResetPlansResponse> {
  constructor() {
    super('sparx.homeworkman.v1.ResetPlansResponse', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.ResetPlansResponse
 */
export const ResetPlansResponse = new ResetPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomeworkCompletionStreak$Type extends MessageType<StudentHomeworkCompletionStreak> {
  constructor() {
    super('sparx.homeworkman.v1.StudentHomeworkCompletionStreak', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'completeHomeworkStreak',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.StudentHomeworkCompletionStreak
 */
export const StudentHomeworkCompletionStreak =
  new StudentHomeworkCompletionStreak$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentCompletionStreakRequest$Type extends MessageType<GetStudentCompletionStreakRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetStudentCompletionStreakRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'at_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetStudentCompletionStreakRequest
 */
export const GetStudentCompletionStreakRequest =
  new GetStudentCompletionStreakRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentCompletionStreakResponse$Type extends MessageType<GetStudentCompletionStreakResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetStudentCompletionStreakResponse', [
      {
        no: 1,
        name: 'completionStreaks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHomeworkCompletionStreak,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetStudentCompletionStreakResponse
 */
export const GetStudentCompletionStreakResponse =
  new GetStudentCompletionStreakResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHasPreviousHomework$Type extends MessageType<StudentHasPreviousHomework> {
  constructor() {
    super('sparx.homeworkman.v1.StudentHasPreviousHomework', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'hasPreviousHomework',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.StudentHasPreviousHomework
 */
export const StudentHasPreviousHomework = new StudentHasPreviousHomework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHasPreviousHomeworkRequest$Type extends MessageType<GetHasPreviousHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHasPreviousHomeworkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'dateRange', kind: 'message', T: () => Interval },
      {
        no: 4,
        name: 'includeDeleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHasPreviousHomeworkRequest
 */
export const GetHasPreviousHomeworkRequest =
  new GetHasPreviousHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHasPreviousHomeworkResponse$Type extends MessageType<GetHasPreviousHomeworkResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHasPreviousHomeworkResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHasPreviousHomework,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHasPreviousHomeworkResponse
 */
export const GetHasPreviousHomeworkResponse =
  new GetHasPreviousHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMostRecentlyDueHomeworkRequest$Type extends MessageType<GetMostRecentlyDueHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetMostRecentlyDueHomeworkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'dueBeforeTime', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetMostRecentlyDueHomeworkRequest
 */
export const GetMostRecentlyDueHomeworkRequest =
  new GetMostRecentlyDueHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMostRecentlyDueHomeworkResponse$Type extends MessageType<GetMostRecentlyDueHomeworkResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetMostRecentlyDueHomeworkResponse', [
      { no: 1, name: 'homework', kind: 'message', T: () => Homework },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetMostRecentlyDueHomeworkResponse
 */
export const GetMostRecentlyDueHomeworkResponse =
  new GetMostRecentlyDueHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePackageRequest$Type extends MessageType<CreatePackageRequest> {
  constructor() {
    super('sparx.homeworkman.v1.CreatePackageRequest', [
      { no: 2, name: 'homework_package', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.CreatePackageRequest
 */
export const CreatePackageRequest = new CreatePackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreatePackagesRequest$Type extends MessageType<BatchCreatePackagesRequest> {
  constructor() {
    super('sparx.homeworkman.v1.BatchCreatePackagesRequest', [
      {
        no: 2,
        name: 'requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreatePackageRequest,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.BatchCreatePackagesRequest
 */
export const BatchCreatePackagesRequest = new BatchCreatePackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreatePackagesResponse$Type extends MessageType<BatchCreatePackagesResponse> {
  constructor() {
    super('sparx.homeworkman.v1.BatchCreatePackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.BatchCreatePackagesResponse
 */
export const BatchCreatePackagesResponse =
  new BatchCreatePackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanLaunchLockRequest$Type extends MessageType<GetHomeworkPlanLaunchLockRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlanLaunchLockRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlanLaunchLockRequest
 */
export const GetHomeworkPlanLaunchLockRequest =
  new GetHomeworkPlanLaunchLockRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanLaunchLockResponse$Type extends MessageType<GetHomeworkPlanLaunchLockResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetHomeworkPlanLaunchLockResponse', [
      {
        no: 1,
        name: 'lock_acquired',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetHomeworkPlanLaunchLockResponse
 */
export const GetHomeworkPlanLaunchLockResponse =
  new GetHomeworkPlanLaunchLockResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateHomeworkRequest$Type extends MessageType<GenerateHomeworkRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GenerateHomeworkRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'definitionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      {
        no: 5,
        name: 'inFocusItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PlanItem,
      },
      { no: 6, name: 'dueTimestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GenerateHomeworkRequest
 */
export const GenerateHomeworkRequest = new GenerateHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePlanDefinitionRequest$Type extends MessageType<DeletePlanDefinitionRequest> {
  constructor() {
    super('sparx.homeworkman.v1.DeletePlanDefinitionRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'definitionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.DeletePlanDefinitionRequest
 */
export const DeletePlanDefinitionRequest =
  new DeletePlanDefinitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLastGenerationDateRequest$Type extends MessageType<SetLastGenerationDateRequest> {
  constructor() {
    super('sparx.homeworkman.v1.SetLastGenerationDateRequest', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'lastGenerationDate',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.SetLastGenerationDateRequest
 */
export const SetLastGenerationDateRequest =
  new SetLastGenerationDateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNextGenerationTimesRequest$Type extends MessageType<GetNextGenerationTimesRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetNextGenerationTimesRequest', [
      { no: 1, name: 'now', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetNextGenerationTimesRequest
 */
export const GetNextGenerationTimesRequest =
  new GetNextGenerationTimesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NextGenerationTime$Type extends MessageType<NextGenerationTime> {
  constructor() {
    super('sparx.homeworkman.v1.NextGenerationTime', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'next_generation_time',
        kind: 'message',
        T: () => DateTime,
      },
      {
        no: 4,
        name: 'last_generation_time',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.NextGenerationTime
 */
export const NextGenerationTime = new NextGenerationTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNextGenerationTimesResponse$Type extends MessageType<GetNextGenerationTimesResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetNextGenerationTimesResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NextGenerationTime,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetNextGenerationTimesResponse
 */
export const GetNextGenerationTimesResponse =
  new GetNextGenerationTimesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemovedConsolidationTopic$Type extends MessageType<RemovedConsolidationTopic> {
  constructor() {
    super('sparx.homeworkman.v1.RemovedConsolidationTopic', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'removed_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.RemovedConsolidationTopic
 */
export const RemovedConsolidationTopic = new RemovedConsolidationTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRemovedConsolidationTopicsRequest$Type extends MessageType<GetRemovedConsolidationTopicsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.GetRemovedConsolidationTopicsRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetRemovedConsolidationTopicsRequest
 */
export const GetRemovedConsolidationTopicsRequest =
  new GetRemovedConsolidationTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRemovedConsolidationTopicsResponse$Type extends MessageType<GetRemovedConsolidationTopicsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.GetRemovedConsolidationTopicsResponse', [
      {
        no: 1,
        name: 'removed_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RemovedConsolidationTopic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.GetRemovedConsolidationTopicsResponse
 */
export const GetRemovedConsolidationTopicsResponse =
  new GetRemovedConsolidationTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddRemovedConsolidationTopicsRequest$Type extends MessageType<AddRemovedConsolidationTopicsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.AddRemovedConsolidationTopicsRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'topic_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'custom_removed_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.homeworkman.v1.AddRemovedConsolidationTopicsRequest
 */
export const AddRemovedConsolidationTopicsRequest =
  new AddRemovedConsolidationTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FixHomeworkPlansWithOutdatedSOLsRequest$Type extends MessageType<FixHomeworkPlansWithOutdatedSOLsRequest> {
  constructor() {
    super('sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsRequest', [
      {
        no: 1,
        name: 'school_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'do_it', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsRequest
 */
export const FixHomeworkPlansWithOutdatedSOLsRequest =
  new FixHomeworkPlansWithOutdatedSOLsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FixedPlanForSchool$Type extends MessageType<FixedPlanForSchool> {
  constructor() {
    super('sparx.homeworkman.v1.FixedPlanForSchool', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'studentgroup_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'sow_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.FixedPlanForSchool
 */
export const FixedPlanForSchool = new FixedPlanForSchool$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FixHomeworkPlansWithOutdatedSOLsResponse$Type extends MessageType<FixHomeworkPlansWithOutdatedSOLsResponse> {
  constructor() {
    super('sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsResponse', [
      {
        no: 1,
        name: 'fixed',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FixedPlanForSchool,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.homeworkman.v1.FixHomeworkPlansWithOutdatedSOLsResponse
 */
export const FixHomeworkPlansWithOutdatedSOLsResponse =
  new FixHomeworkPlansWithOutdatedSOLsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.homeworkman.v1.HomeworkMan
 */
export const HomeworkMan = new ServiceType('sparx.homeworkman.v1.HomeworkMan', [
  {
    name: 'LiveCheck',
    options: { 'google.api.http': { get: '/healthz/live' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'ReadyCheck',
    options: { 'google.api.http': { get: '/healthz/ready' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'GetHomeworks',
    options: {},
    I: GetHomeworksRequest,
    O: GetHomeworksResponse,
  },
  {
    name: 'DeleteHomework',
    options: {},
    I: DeleteHomeworkRequest,
    O: GetHomeworkPlanResponse,
  },
  {
    name: 'GetPackages',
    options: {},
    I: GetPackagesRequest,
    O: GetPackagesResponse,
  },
  {
    name: 'GetHomeworkStudents',
    options: {},
    I: GetHomeworkStudentsRequest,
    O: GetHomeworkStudentsResponse,
  },
  {
    name: 'GetHomeworkSummaries',
    options: {},
    I: GetHomeworkSummariesRequest,
    O: GetHomeworkSummariesResponse,
  },
  {
    name: 'GetHomeworkStats',
    options: {},
    I: GetHomeworkStatsRequest,
    O: GetHomeworkStatsResponse,
  },
  {
    name: 'CreateHomework',
    options: {
      'google.api.http': { post: '/homeworkman/v1/create_homework', body: '*' },
    },
    I: CreateHomeworkRequest,
    O: CreateHomeworkResponse,
  },
  {
    name: 'GetHomeworkPlan',
    options: {},
    I: GetHomeworkPlanRequest,
    O: GetHomeworkPlanResponse,
  },
  {
    name: 'GetHomeworkPlans',
    options: {},
    I: GetHomeworkPlansRequest,
    O: GetHomeworkPlansResponse,
  },
  {
    name: 'GetHomeworkDefinitions',
    options: {
      'google.api.http': { get: '/homeworkman/v1/get_homework_definitions' },
    },
    I: GetHomeworkDefinitionsRequest,
    O: GetHomeworkDefinitionsResponse,
  },
  {
    name: 'DoHomeworkPlanAction',
    options: {},
    I: DoHomeworkPlanActionRequest,
    O: GetHomeworkPlanResponse,
  },
  {
    name: 'CommitDefinitionChanges',
    options: {},
    I: CommitDefinitionChangesRequest,
    O: GetHomeworkPlanResponse,
  },
  {
    name: 'CreateHomeworkPlan',
    options: {
      'google.api.http': { post: '/homeworkman/v1/create_plan', body: '*' },
    },
    I: HomeworkPlan,
    O: CreateHomeworkPlanResponse,
  },
  {
    name: 'IncludeTopicsInNextHomework',
    options: {},
    I: IncludeTopicsInNextHomeworkRequest,
    O: IncludeTopicsInNextHomeworkResponse,
  },
  {
    name: 'DeleteStudentData',
    options: {},
    I: DeleteStudentDataRequest,
    O: DeleteStudentDataResponse,
  },
  {
    name: 'GetStudentHomeworkComments',
    options: {},
    I: GetStudentHomeworkCommentsRequest,
    O: GetStudentHomeworkCommentsResponse,
  },
  {
    name: 'UpsertStudentHomeworkComment',
    options: {
      'google.api.http': {
        post: '/homeworkman/v1/upsert_homework_comment',
        body: '*',
      },
    },
    I: UpsertStudentHomeworkCommentRequest,
    O: UpsertStudentHomeworkCommentResponse,
  },
  {
    name: 'ResetPlans',
    options: {},
    I: ResetPlansRequest,
    O: ResetPlansResponse,
  },
  {
    name: 'BatchCreatePackages',
    options: {},
    I: BatchCreatePackagesRequest,
    O: BatchCreatePackagesResponse,
  },
  {
    name: 'GetStudentCompletionStreak',
    options: {},
    I: GetStudentCompletionStreakRequest,
    O: GetStudentCompletionStreakResponse,
  },
  {
    name: 'GetHasPreviousHomework',
    options: {},
    I: GetHasPreviousHomeworkRequest,
    O: GetHasPreviousHomeworkResponse,
  },
  {
    name: 'GetMostRecentlyDueHomework',
    options: {},
    I: GetMostRecentlyDueHomeworkRequest,
    O: GetMostRecentlyDueHomeworkResponse,
  },
  {
    name: 'GetHomeworkPlanLaunchLock',
    options: {},
    I: GetHomeworkPlanLaunchLockRequest,
    O: GetHomeworkPlanLaunchLockResponse,
  },
  {
    name: 'GenerateHomework',
    options: {},
    I: GenerateHomeworkRequest,
    O: Empty,
  },
  {
    name: 'DeletePlanDefinition',
    options: {},
    I: DeletePlanDefinitionRequest,
    O: Empty,
  },
  {
    name: 'SetLastGenerationDate',
    options: {},
    I: SetLastGenerationDateRequest,
    O: Empty,
  },
  {
    name: 'GetNextGenerationTimes',
    options: {},
    I: GetNextGenerationTimesRequest,
    O: GetNextGenerationTimesResponse,
  },
  {
    name: 'GetRemovedConsolidationTopics',
    options: {},
    I: GetRemovedConsolidationTopicsRequest,
    O: GetRemovedConsolidationTopicsResponse,
  },
  {
    name: 'AddRemovedConsolidationTopics',
    options: {},
    I: AddRemovedConsolidationTopicsRequest,
    O: Empty,
  },
]);
/**
 * @generated ServiceType for protobuf service sparx.homeworkman.v1.HomeworkManMaintenance
 */
export const HomeworkManMaintenance = new ServiceType(
  'sparx.homeworkman.v1.HomeworkManMaintenance',
  [
    {
      name: 'FixHomeworkPlansWithOutdatedSOLs',
      options: {},
      I: FixHomeworkPlansWithOutdatedSOLsRequest,
      O: FixHomeworkPlansWithOutdatedSOLsResponse,
    },
  ]
);
/**
 * @generated ServiceType for protobuf service sparx.homeworkman.v1.LegacyHomeworkManWrapper
 */
export const LegacyHomeworkManWrapper = new ServiceType(
  'sparx.homeworkman.v1.LegacyHomeworkManWrapper',
  [
    {
      name: 'GetHomeworkDefinitions',
      options: {},
      I: GetHomeworkDefinitionsRequest,
      O: GetHomeworkDefinitionsResponse,
    },
  ]
);
