import { Box, Button, ButtonGroup, Checkbox, Text, VStack } from '@chakra-ui/react';
import { SubstrandSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { useTopicWithStrandsLookup } from 'api/content';
import { TopicPicker } from 'components/TopicPicker';
import React, { useState } from 'react';
import { preprocessTopicDescription } from 'utils/content';
import { useSessionStorage } from 'utils/hooks/localstorage';
import { ActivityPreparationProps } from 'views/lessons/createactivity/activitytypes';
import { initialLevelOptions } from 'views/students/StudentListView';

export const CreateScienceContentActivity = ({
  doSubmit,
  getFooter,
  setTaskName,
}: ActivityPreparationProps) => {
  const subject = 'subjects/science';
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedLevel, setSelectedLevel] = useSessionStorage(
    `sci/lessons/content/${subject}/level`,
    '1',
  );
  const [defaultCurriculum, setDefaultCurriculum] = useSessionStorage(
    `sci/lessons/content/${subject}/curriculum`,
    '',
  );
  const [includeHigher, setIncludeHigher] = useSessionStorage(
    `sci/lessons/content/${subject}/higher`,
    '0',
  );
  const [includeSeparate, setIncludeSeparate] = useSessionStorage(
    `sci/lessons/content/${subject}/separate`,
    '0',
  );

  const onSubmit = async (keepAdding?: boolean) => {
    if (selectedTopic) {
      doSubmit(
        {
          content: {
            oneofKind: 'topic',
            topic: {
              topicName: selectedTopic,
              level: selectedLevel || '1',
              variant: '',
              includeHigher: includeHigher === '1',
              includeSeparate: includeSeparate === '1',
            },
          },
        },
        keepAdding,
      );
      setSelectedTopic('');
    }
  };

  const topicLookup = useTopicWithStrandsLookup({ suspense: true, subject });
  const [topics, setTopics] = React.useState<string[]>([]);
  const onSelectSubstrand = (substrand: SubstrandSummary | undefined) => {
    setSelectedTopic('');
    setTopics(substrand?.substrand?.topicNames || []);
    const curriculumId = substrand?.substrand?.name.split('/')[1] || '';
    setDefaultCurriculum(curriculumId ? `curriculums/${curriculumId}` : '');
    setTaskName('', true);
  };

  return (
    <>
      <Box px={3}>
        <Box mx={3} p={2} bg="gray.100" borderRadius="md">
          <ButtonGroup isAttached variant="outline" display="flex" width="100%">
            <Button
              flex={1}
              bg={selectedLevel === 'all' ? 'blue.800' : 'white'}
              color={selectedLevel === 'all' ? 'white' : 'gray.900'}
              onClick={() => setSelectedLevel('all')}
              _hover={{ bg: selectedLevel === 'all' ? undefined : 'gray.100' }}
            >
              All Levels
            </Button>
            {initialLevelOptions.map(level => (
              <Button
                key={level}
                flex={1}
                bg={selectedLevel === level ? 'blue.800' : 'white'}
                color={selectedLevel === level ? 'white' : 'gray.900'}
                onClick={() => setSelectedLevel(level)}
                _hover={{ bg: selectedLevel === level ? undefined : 'gray.100' }}
              >
                Level {level}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box mx={3} mt={3}>
          <Checkbox
            isChecked={includeHigher === '1'}
            onChange={e => setIncludeHigher(e.target.checked ? '1' : '0')}
          >
            Include higher only questions
          </Checkbox>
          <Checkbox
            ml={4}
            isChecked={includeSeparate === '1'}
            onChange={e => setIncludeSeparate(e.target.checked ? '1' : '0')}
          >
            Include separate only questions
          </Checkbox>
        </Box>
        <TopicPicker
          defaultCurriculumName={defaultCurriculum || ''}
          onSelectSubstrand={onSelectSubstrand}
          height="40vh"
          subject={subject}
        >
          <VStack spacing={1} alignItems="stretch" px={4}>
            {topics.map(topicName => {
              const topic = topicLookup[topicName];
              if (!topic) {
                return null;
              }

              const isSelected = selectedTopic === topic.topic.name;
              return (
                <Button
                  key={topicName}
                  onClick={() => {
                    setSelectedTopic(isSelected ? '' : topic.topic.name);
                    setTaskName(topic.topic.displayName, true);
                  }}
                  bg={isSelected ? 'blue.800' : 'gray.50'}
                  color={isSelected ? 'white' : 'gray.800'}
                  cursor="pointer"
                  _hover={{ bg: isSelected ? undefined : 'gray.100' }}
                  px={4}
                  py={2}
                  borderRadius="md"
                  borderWidth={1}
                  borderColor="gray.200"
                  alignItems="flex-start"
                  flexDirection="column"
                  flex={1}
                  fontWeight="normal"
                  isDisabled={topic.skillCount === 0}
                  whiteSpace="normal"
                  textAlign="left"
                >
                  <Text fontWeight="bold">{topic.topic.displayName}</Text>
                  <Text fontSize="sm" color={isSelected ? 'gray.400' : 'gray.500'}>
                    {preprocessTopicDescription(topic.topic.description)}
                  </Text>
                </Button>
              );
            })}
          </VStack>
        </TopicPicker>
      </Box>
      {getFooter(selectedTopic ? onSubmit : undefined, undefined, true)}
    </>
  );
};
