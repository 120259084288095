// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/sessions/v1/session.proto" (package "sparx.science.sessions.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Struct } from '../../../../../google/protobuf/struct';
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetCurrentSessionRequest
 */
export interface GetCurrentSessionRequest {}
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetCurrentSessionResponse
 */
export interface GetCurrentSessionResponse {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string sparx_user_id = 7;
   */
  sparxUserId: string;
  /**
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string given_name = 3;
   */
  givenName: string;
  /**
   * @generated from protobuf field: string family_name = 4;
   */
  familyName: string;
  /**
   * @generated from protobuf field: string user_type = 5;
   */
  userType: string;
  /**
   * @generated from protobuf field: google.protobuf.Struct feature_flags = 6;
   */
  featureFlags?: Struct;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp first_login = 8;
   */
  firstLogin?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetFirebaseTokenRequest
 */
export interface GetFirebaseTokenRequest {}
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetFirebaseTokenResponse
 */
export interface GetFirebaseTokenResponse {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
}
/**
 * @generated from protobuf message sparx.science.sessions.v1.LogSearchQueryRequest
 */
export interface LogSearchQueryRequest {
  /**
   * Name of the school the user is in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The query the user searched for.
   *
   * @generated from protobuf field: string search_query = 2;
   */
  searchQuery: string;
  /**
   * List of results that were returned to the user.
   *
   * @generated from protobuf field: repeated sparx.science.sessions.v1.LogSearchQueryRequest.Result results = 3;
   */
  results: LogSearchQueryRequest_Result[];
  /**
   * The title of the result that was selected (if any).
   *
   * @generated from protobuf field: string selected_result_title = 4;
   */
  selectedResultTitle: string;
  /**
   * The URL of the result that was selected (if any).
   *
   * @generated from protobuf field: string selected_result_url = 5;
   */
  selectedResultUrl: string;
}
/**
 * Result that was returned.
 *
 * @generated from protobuf message sparx.science.sessions.v1.LogSearchQueryRequest.Result
 */
export interface LogSearchQueryRequest_Result {
  /**
   * Title of the result that was returned.
   *
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * URL of the result that was returned.
   *
   * @generated from protobuf field: string url = 2;
   */
  url: string;
}
/**
 * @generated from protobuf message sparx.science.sessions.v1.LogSearchQueryResponse
 */
export interface LogSearchQueryResponse {}
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetUserFlagsRequest
 */
export interface GetUserFlagsRequest {}
/**
 * @generated from protobuf message sparx.science.sessions.v1.GetUserFlagsResponse
 */
export interface GetUserFlagsResponse {
  /**
   * @generated from protobuf field: map<string, string> user_flags = 1;
   */
  userFlags: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.sessions.v1.UpdateUserFlagsRequest
 */
export interface UpdateUserFlagsRequest {
  /**
   * The flags to create or update, existing flags not included in the map will not be changed.
   *
   * @generated from protobuf field: map<string, string> user_flags = 1;
   */
  userFlags: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.sessions.v1.UpdateUserFlagsResponse
 */
export interface UpdateUserFlagsResponse {
  /**
   * All user flags in their newly updated state
   *
   * @generated from protobuf field: map<string, string> user_flags = 1;
   */
  userFlags: {
    [key: string]: string;
  };
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentSessionRequest$Type extends MessageType<GetCurrentSessionRequest> {
  constructor() {
    super('sparx.science.sessions.v1.GetCurrentSessionRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetCurrentSessionRequest
 */
export const GetCurrentSessionRequest = new GetCurrentSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentSessionResponse$Type extends MessageType<GetCurrentSessionResponse> {
  constructor() {
    super('sparx.science.sessions.v1.GetCurrentSessionResponse', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'sparx_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'user_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'feature_flags', kind: 'message', T: () => Struct },
      { no: 8, name: 'first_login', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetCurrentSessionResponse
 */
export const GetCurrentSessionResponse = new GetCurrentSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFirebaseTokenRequest$Type extends MessageType<GetFirebaseTokenRequest> {
  constructor() {
    super('sparx.science.sessions.v1.GetFirebaseTokenRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetFirebaseTokenRequest
 */
export const GetFirebaseTokenRequest = new GetFirebaseTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFirebaseTokenResponse$Type extends MessageType<GetFirebaseTokenResponse> {
  constructor() {
    super('sparx.science.sessions.v1.GetFirebaseTokenResponse', [
      { no: 1, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetFirebaseTokenResponse
 */
export const GetFirebaseTokenResponse = new GetFirebaseTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogSearchQueryRequest$Type extends MessageType<LogSearchQueryRequest> {
  constructor() {
    super('sparx.science.sessions.v1.LogSearchQueryRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'search_query',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LogSearchQueryRequest_Result,
      },
      {
        no: 4,
        name: 'selected_result_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'selected_result_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.LogSearchQueryRequest
 */
export const LogSearchQueryRequest = new LogSearchQueryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogSearchQueryRequest_Result$Type extends MessageType<LogSearchQueryRequest_Result> {
  constructor() {
    super('sparx.science.sessions.v1.LogSearchQueryRequest.Result', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.LogSearchQueryRequest.Result
 */
export const LogSearchQueryRequest_Result =
  new LogSearchQueryRequest_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogSearchQueryResponse$Type extends MessageType<LogSearchQueryResponse> {
  constructor() {
    super('sparx.science.sessions.v1.LogSearchQueryResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.LogSearchQueryResponse
 */
export const LogSearchQueryResponse = new LogSearchQueryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserFlagsRequest$Type extends MessageType<GetUserFlagsRequest> {
  constructor() {
    super('sparx.science.sessions.v1.GetUserFlagsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetUserFlagsRequest
 */
export const GetUserFlagsRequest = new GetUserFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserFlagsResponse$Type extends MessageType<GetUserFlagsResponse> {
  constructor() {
    super('sparx.science.sessions.v1.GetUserFlagsResponse', [
      {
        no: 1,
        name: 'user_flags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.GetUserFlagsResponse
 */
export const GetUserFlagsResponse = new GetUserFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserFlagsRequest$Type extends MessageType<UpdateUserFlagsRequest> {
  constructor() {
    super('sparx.science.sessions.v1.UpdateUserFlagsRequest', [
      {
        no: 1,
        name: 'user_flags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.UpdateUserFlagsRequest
 */
export const UpdateUserFlagsRequest = new UpdateUserFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserFlagsResponse$Type extends MessageType<UpdateUserFlagsResponse> {
  constructor() {
    super('sparx.science.sessions.v1.UpdateUserFlagsResponse', [
      {
        no: 1,
        name: 'user_flags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.sessions.v1.UpdateUserFlagsResponse
 */
export const UpdateUserFlagsResponse = new UpdateUserFlagsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.sessions.v1.Sessions
 */
export const Sessions = new ServiceType('sparx.science.sessions.v1.Sessions', [
  {
    name: 'GetCurrentSession',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: GetCurrentSessionRequest,
    O: GetCurrentSessionResponse,
  },
  {
    name: 'GetFirebaseToken',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: GetFirebaseTokenRequest,
    O: GetFirebaseTokenResponse,
  },
  {
    name: 'LogSearchQuery',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: LogSearchQueryRequest,
    O: LogSearchQueryResponse,
  },
  {
    name: 'GetUserFlags',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: GetUserFlagsRequest,
    O: GetUserFlagsResponse,
  },
  {
    name: 'UpdateUserFlags',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: UpdateUserFlagsRequest,
    O: UpdateUserFlagsResponse,
  },
]);
