// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/personalisation/attainmentlevel/v1/attainmentlevel.proto" (package "sparx.personalisation.attainmentlevel.v1", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * Attainment levels are selected by a teacher to indicate what ability level is expected for a student or class
 *
 * @generated from protobuf enum sparx.personalisation.attainmentlevel.v1.AttainmentLevel
 */
export enum AttainmentLevel {
  /**
   * DO_NOT_CHANGE means do not change in an update mutation
   *
   * @generated from protobuf enum value: DO_NOT_CHANGE = 0;
   */
  DO_NOT_CHANGE = 0,
  /**
   * @generated from protobuf enum value: A = 1;
   */
  A = 1,
  /**
   * @generated from protobuf enum value: B = 2;
   */
  B = 2,
  /**
   * @generated from protobuf enum value: C = 3;
   */
  C = 3,
  /**
   * @generated from protobuf enum value: D = 4;
   */
  D = 4,
  /**
   * @generated from protobuf enum value: F = 5;
   */
  F = 5,
  /**
   * @generated from protobuf enum value: F_PLUS = 6;
   */
  F_PLUS = 6,
  /**
   * @generated from protobuf enum value: FH = 7;
   */
  FH = 7,
  /**
   * @generated from protobuf enum value: H = 8;
   */
  H = 8,
  /**
   * @generated from protobuf enum value: H_PLUS = 9;
   */
  H_PLUS = 9,
  /**
   * @generated from protobuf enum value: SCORE_80_90 = 10;
   */
  SCORE_80_90 = 10,
  /**
   * @generated from protobuf enum value: SCORE_90_100 = 11;
   */
  SCORE_90_100 = 11,
  /**
   * @generated from protobuf enum value: SCORE_100_110 = 12;
   */
  SCORE_100_110 = 12,
  /**
   * @generated from protobuf enum value: SCORE_110_120 = 13;
   */
  SCORE_110_120 = 13,
  /**
   * @generated from protobuf enum value: LEVEL_1 = 14;
   */
  LEVEL_1 = 14,
  /**
   * @generated from protobuf enum value: LEVEL_2 = 15;
   */
  LEVEL_2 = 15,
  /**
   * @generated from protobuf enum value: LEVEL_3 = 16;
   */
  LEVEL_3 = 16,
  /**
   * @generated from protobuf enum value: LEVEL_4 = 17;
   */
  LEVEL_4 = 17,
  /**
   * @generated from protobuf enum value: LEVEL_5 = 18;
   */
  LEVEL_5 = 18,
  /**
   * @generated from protobuf enum value: DIAGNOSTIC_HOMEWORK = 19;
   */
  DIAGNOSTIC_HOMEWORK = 19,
  /**
   * UNSET means that no attainment level has been defined for this class
   *
   * @generated from protobuf enum value: UNSET = 100;
   */
  UNSET = 100,
}
