// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/activity.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Activities } from './activity';
import type { SetTaskItemsCompleteResponse } from './activity';
import type { SetTaskItemsCompleteRequest } from './activity';
import type { ListActivityFeedResponse } from './activity';
import type { ListActivityFeedRequest } from './activity';
import type { SetActivityEvaluationResponse } from './activity';
import type { SetActivityEvaluationRequest } from './activity';
import type { ListActivitiesResponse } from './activity';
import type { ListActivitiesRequest } from './activity';
import type { GetTetheringActivityResponse } from './activity';
import type { GetTetheringActivityRequest } from './activity';
import type { GetPackageAnswerHistoryResponse } from './activity';
import type { GetPackageAnswerHistoryRequest } from './activity';
import type { ActivityActionResponse } from './activity';
import type { ActivityActionRequest } from './activity';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetActivityResponse } from './activity';
import type { GetActivityRequest } from './activity';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.Activities
 */
export interface IActivitiesClient {
  /**
   * @generated from protobuf rpc: GetActivity(sparx.science.packages.v1.GetActivityRequest) returns (sparx.science.packages.v1.GetActivityResponse);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions
  ): UnaryCall<GetActivityRequest, GetActivityResponse>;
  /**
   * @generated from protobuf rpc: ActivityAction(sparx.science.packages.v1.ActivityActionRequest) returns (sparx.science.packages.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityActionRequest,
    options?: RpcOptions
  ): UnaryCall<ActivityActionRequest, ActivityActionResponse>;
  /**
   * @generated from protobuf rpc: GetPackageAnswerHistory(sparx.science.packages.v1.GetPackageAnswerHistoryRequest) returns (sparx.science.packages.v1.GetPackageAnswerHistoryResponse);
   */
  getPackageAnswerHistory(
    input: GetPackageAnswerHistoryRequest,
    options?: RpcOptions
  ): UnaryCall<GetPackageAnswerHistoryRequest, GetPackageAnswerHistoryResponse>;
  /**
   * @generated from protobuf rpc: GetTetheringActivity(sparx.science.packages.v1.GetTetheringActivityRequest) returns (sparx.science.packages.v1.GetTetheringActivityResponse);
   */
  getTetheringActivity(
    input: GetTetheringActivityRequest,
    options?: RpcOptions
  ): UnaryCall<GetTetheringActivityRequest, GetTetheringActivityResponse>;
  /**
   * @generated from protobuf rpc: ListActivities(sparx.science.packages.v1.ListActivitiesRequest) returns (sparx.science.packages.v1.ListActivitiesResponse);
   */
  listActivities(
    input: ListActivitiesRequest,
    options?: RpcOptions
  ): UnaryCall<ListActivitiesRequest, ListActivitiesResponse>;
  /**
   * @generated from protobuf rpc: SetActivityEvaluation(sparx.science.packages.v1.SetActivityEvaluationRequest) returns (sparx.science.packages.v1.SetActivityEvaluationResponse);
   */
  setActivityEvaluation(
    input: SetActivityEvaluationRequest,
    options?: RpcOptions
  ): UnaryCall<SetActivityEvaluationRequest, SetActivityEvaluationResponse>;
  /**
   * @generated from protobuf rpc: ListActivityFeed(sparx.science.packages.v1.ListActivityFeedRequest) returns (sparx.science.packages.v1.ListActivityFeedResponse);
   */
  listActivityFeed(
    input: ListActivityFeedRequest,
    options?: RpcOptions
  ): UnaryCall<ListActivityFeedRequest, ListActivityFeedResponse>;
  /**
   * @generated from protobuf rpc: SetTaskItemsComplete(sparx.science.packages.v1.SetTaskItemsCompleteRequest) returns (sparx.science.packages.v1.SetTaskItemsCompleteResponse);
   */
  setTaskItemsComplete(
    input: SetTaskItemsCompleteRequest,
    options?: RpcOptions
  ): UnaryCall<SetTaskItemsCompleteRequest, SetTaskItemsCompleteResponse>;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.Activities
 */
export class ActivitiesClient implements IActivitiesClient, ServiceInfo {
  typeName = Activities.typeName;
  methods = Activities.methods;
  options = Activities.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetActivity(sparx.science.packages.v1.GetActivityRequest) returns (sparx.science.packages.v1.GetActivityResponse);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions
  ): UnaryCall<GetActivityRequest, GetActivityResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivityRequest, GetActivityResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ActivityAction(sparx.science.packages.v1.ActivityActionRequest) returns (sparx.science.packages.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityActionRequest,
    options?: RpcOptions
  ): UnaryCall<ActivityActionRequest, ActivityActionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ActivityActionRequest, ActivityActionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetPackageAnswerHistory(sparx.science.packages.v1.GetPackageAnswerHistoryRequest) returns (sparx.science.packages.v1.GetPackageAnswerHistoryResponse);
   */
  getPackageAnswerHistory(
    input: GetPackageAnswerHistoryRequest,
    options?: RpcOptions
  ): UnaryCall<
    GetPackageAnswerHistoryRequest,
    GetPackageAnswerHistoryResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetPackageAnswerHistoryRequest,
      GetPackageAnswerHistoryResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetTetheringActivity(sparx.science.packages.v1.GetTetheringActivityRequest) returns (sparx.science.packages.v1.GetTetheringActivityResponse);
   */
  getTetheringActivity(
    input: GetTetheringActivityRequest,
    options?: RpcOptions
  ): UnaryCall<GetTetheringActivityRequest, GetTetheringActivityResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetTetheringActivityRequest,
      GetTetheringActivityResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListActivities(sparx.science.packages.v1.ListActivitiesRequest) returns (sparx.science.packages.v1.ListActivitiesResponse);
   */
  listActivities(
    input: ListActivitiesRequest,
    options?: RpcOptions
  ): UnaryCall<ListActivitiesRequest, ListActivitiesResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListActivitiesRequest, ListActivitiesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: SetActivityEvaluation(sparx.science.packages.v1.SetActivityEvaluationRequest) returns (sparx.science.packages.v1.SetActivityEvaluationResponse);
   */
  setActivityEvaluation(
    input: SetActivityEvaluationRequest,
    options?: RpcOptions
  ): UnaryCall<SetActivityEvaluationRequest, SetActivityEvaluationResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetActivityEvaluationRequest,
      SetActivityEvaluationResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListActivityFeed(sparx.science.packages.v1.ListActivityFeedRequest) returns (sparx.science.packages.v1.ListActivityFeedResponse);
   */
  listActivityFeed(
    input: ListActivityFeedRequest,
    options?: RpcOptions
  ): UnaryCall<ListActivityFeedRequest, ListActivityFeedResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListActivityFeedRequest, ListActivityFeedResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: SetTaskItemsComplete(sparx.science.packages.v1.SetTaskItemsCompleteRequest) returns (sparx.science.packages.v1.SetTaskItemsCompleteResponse);
   */
  setTaskItemsComplete(
    input: SetTaskItemsCompleteRequest,
    options?: RpcOptions
  ): UnaryCall<SetTaskItemsCompleteRequest, SetTaskItemsCompleteResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetTaskItemsCompleteRequest,
      SetTaskItemsCompleteResponse
    >('unary', this._transport, method, opt, input);
  }
}
