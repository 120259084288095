// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/staff/schoolstaff/v2/schoolstaff.proto" (package "sparx.school.staff.schoolstaff.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolStaffService } from './schoolstaff';
import type { SendWelcomeEmailResponse } from './schoolstaff';
import type { SendWelcomeEmailRequest } from './schoolstaff';
import type { UnsuppressStaffMemberEmailResponse } from './schoolstaff';
import type { UnsuppressStaffMemberEmailRequest } from './schoolstaff';
import type { DeleteSchoolStaffMemberRequest } from './schoolstaff';
import type { UpdateSchoolStaffMemberRequest } from './schoolstaff';
import type { CreateSchoolStaffMemberRequest } from './schoolstaff';
import type { ListSchoolStaffTrainingProgressResponse } from './schoolstaff';
import type { ListSchoolStaffTrainingProgressRequest } from './schoolstaff';
import type { ListSchoolStaffMembersResponse } from './schoolstaff';
import type { ListSchoolStaffMembersRequest } from './schoolstaff';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SchoolStaffMember } from './schoolstaff';
import type { GetSchoolStaffMemberRequest } from './schoolstaff';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.school.staff.schoolstaff.v2.SchoolStaffService
 */
export interface ISchoolStaffServiceClient {
  /**
   * @generated from protobuf rpc: GetSchoolStaffMember(sparx.school.staff.schoolstaff.v2.GetSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  getSchoolStaffMember(
    input: GetSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolStaffMemberRequest, SchoolStaffMember>;
  /**
   * @generated from protobuf rpc: ListSchoolStaffMembers(sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersRequest) returns (sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersResponse);
   */
  listSchoolStaffMembers(
    input: ListSchoolStaffMembersRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchoolStaffMembersRequest, ListSchoolStaffMembersResponse>;
  /**
   * ListSchoolStaffTrainingProgress gets training progress for all staff
   * members in the specified school.
   *
   * @generated from protobuf rpc: ListSchoolStaffTrainingProgress(sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressRequest) returns (sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressResponse);
   */
  listSchoolStaffTrainingProgress(
    input: ListSchoolStaffTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListSchoolStaffTrainingProgressRequest,
    ListSchoolStaffTrainingProgressResponse
  >;
  /**
   * Creates a new staff member in this school. If an email address is supplied
   * that matches an existing user's email in another school, an ALREADY_EXISTS
   * error will be returned, with the details of the matching user in the error
   * data.
   * The request can be resubmitted (as a create) with the existing user's name
   * set.
   *
   * @generated from protobuf rpc: CreateSchoolStaffMember(sparx.school.staff.schoolstaff.v2.CreateSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  createSchoolStaffMember(
    input: CreateSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchoolStaffMemberRequest, SchoolStaffMember>;
  /**
   * @generated from protobuf rpc: UpdateSchoolStaffMember(sparx.school.staff.schoolstaff.v2.UpdateSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  updateSchoolStaffMember(
    input: UpdateSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchoolStaffMemberRequest, SchoolStaffMember>;
  /**
   * @generated from protobuf rpc: DeleteSchoolStaffMember(sparx.school.staff.schoolstaff.v2.DeleteSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  deleteSchoolStaffMember(
    input: DeleteSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchoolStaffMemberRequest, SchoolStaffMember>;
  /**
   * @generated from protobuf rpc: UnsuppressStaffMemberEmail(sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailRequest) returns (sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailResponse);
   */
  unsuppressStaffMemberEmail(
    input: UnsuppressStaffMemberEmailRequest,
    options?: RpcOptions
  ): UnaryCall<
    UnsuppressStaffMemberEmailRequest,
    UnsuppressStaffMemberEmailResponse
  >;
  /**
   * SendWelcomeEmail manually sends a welcome email. Welcome emails are
   * automatically sent as part of other processes
   *
   * @generated from protobuf rpc: SendWelcomeEmail(sparx.school.staff.schoolstaff.v2.SendWelcomeEmailRequest) returns (sparx.school.staff.schoolstaff.v2.SendWelcomeEmailResponse);
   */
  sendWelcomeEmail(
    input: SendWelcomeEmailRequest,
    options?: RpcOptions
  ): UnaryCall<SendWelcomeEmailRequest, SendWelcomeEmailResponse>;
}
/**
 * @generated from protobuf service sparx.school.staff.schoolstaff.v2.SchoolStaffService
 */
export class SchoolStaffServiceClient
  implements ISchoolStaffServiceClient, ServiceInfo
{
  typeName = SchoolStaffService.typeName;
  methods = SchoolStaffService.methods;
  options = SchoolStaffService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSchoolStaffMember(sparx.school.staff.schoolstaff.v2.GetSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  getSchoolStaffMember(
    input: GetSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolStaffMemberRequest, SchoolStaffMember> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolStaffMemberRequest, SchoolStaffMember>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: ListSchoolStaffMembers(sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersRequest) returns (sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersResponse);
   */
  listSchoolStaffMembers(
    input: ListSchoolStaffMembersRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchoolStaffMembersRequest, ListSchoolStaffMembersResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSchoolStaffMembersRequest,
      ListSchoolStaffMembersResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListSchoolStaffTrainingProgress gets training progress for all staff
   * members in the specified school.
   *
   * @generated from protobuf rpc: ListSchoolStaffTrainingProgress(sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressRequest) returns (sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressResponse);
   */
  listSchoolStaffTrainingProgress(
    input: ListSchoolStaffTrainingProgressRequest,
    options?: RpcOptions
  ): UnaryCall<
    ListSchoolStaffTrainingProgressRequest,
    ListSchoolStaffTrainingProgressResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSchoolStaffTrainingProgressRequest,
      ListSchoolStaffTrainingProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Creates a new staff member in this school. If an email address is supplied
   * that matches an existing user's email in another school, an ALREADY_EXISTS
   * error will be returned, with the details of the matching user in the error
   * data.
   * The request can be resubmitted (as a create) with the existing user's name
   * set.
   *
   * @generated from protobuf rpc: CreateSchoolStaffMember(sparx.school.staff.schoolstaff.v2.CreateSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  createSchoolStaffMember(
    input: CreateSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchoolStaffMemberRequest, SchoolStaffMember> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSchoolStaffMemberRequest, SchoolStaffMember>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateSchoolStaffMember(sparx.school.staff.schoolstaff.v2.UpdateSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  updateSchoolStaffMember(
    input: UpdateSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchoolStaffMemberRequest, SchoolStaffMember> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolStaffMemberRequest, SchoolStaffMember>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: DeleteSchoolStaffMember(sparx.school.staff.schoolstaff.v2.DeleteSchoolStaffMemberRequest) returns (sparx.school.staff.schoolstaff.v2.SchoolStaffMember);
   */
  deleteSchoolStaffMember(
    input: DeleteSchoolStaffMemberRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchoolStaffMemberRequest, SchoolStaffMember> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchoolStaffMemberRequest, SchoolStaffMember>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UnsuppressStaffMemberEmail(sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailRequest) returns (sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailResponse);
   */
  unsuppressStaffMemberEmail(
    input: UnsuppressStaffMemberEmailRequest,
    options?: RpcOptions
  ): UnaryCall<
    UnsuppressStaffMemberEmailRequest,
    UnsuppressStaffMemberEmailResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UnsuppressStaffMemberEmailRequest,
      UnsuppressStaffMemberEmailResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * SendWelcomeEmail manually sends a welcome email. Welcome emails are
   * automatically sent as part of other processes
   *
   * @generated from protobuf rpc: SendWelcomeEmail(sparx.school.staff.schoolstaff.v2.SendWelcomeEmailRequest) returns (sparx.school.staff.schoolstaff.v2.SendWelcomeEmailResponse);
   */
  sendWelcomeEmail(
    input: SendWelcomeEmailRequest,
    options?: RpcOptions
  ): UnaryCall<SendWelcomeEmailRequest, SendWelcomeEmailResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SendWelcomeEmailRequest, SendWelcomeEmailResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
