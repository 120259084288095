import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { faCirclePause } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LessonActivityLockState,
  LessonState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'api/client';
import { useCurrentLesson, useWatchLesson } from 'api/lessons';
import { getPackageFromQueryClient, usePackages } from 'api/packages';
import { useSession } from 'api/sessions';
import { isComplete } from 'components/CompletionBadge';
import { LargeLoadingWithText } from 'components/loading/LargeLoading';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { LessonPackageList } from 'views/lessondelivery/LessonPackageList';
import { LessonDeliveryPage, useLessonStatePusher } from 'views/lessondelivery/lessonStatePusher';
import { LessonTaskDelivery } from 'views/lessondelivery/LessonTaskDelivery';
import { PopoverActivity } from 'views/lessondelivery/PopoverActivity';
import { SetToActivity } from 'views/lessondelivery/SetToActivity';
import { isStudentPaused } from 'views/lessons/utils';

interface LessonDeliveryContextValues {
  lessonName: string;
  navigate: (page: LessonDeliveryPage) => void;
}

const LessonDeliveryContext = createContext<LessonDeliveryContextValues>({
  lessonName: '',
  navigate: () => undefined,
});

export const useLessonDeliveryContext = () => useContext(LessonDeliveryContext);

export const LessonDeliveryView = () => {
  const [page, setPage] = useState<LessonDeliveryPage>({ page: 'landing' });

  const { data: currentLesson } = useCurrentLesson();
  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(
    currentLesson?.lessonName,
    currentLesson?.sessionId,
  );
  // Pushes the current student state to the server when it changes
  useLessonStatePusher(currentLesson, page);

  const { data: user } = useSession();
  const paused =
    isStudentPaused(lessonState, user?.userId) && !location.pathname.startsWith('/teacher');

  if (isLoadingLesson) {
    return <LargeLoadingWithText>Joining lesson...</LargeLoadingWithText>;
  }
  if (!lessonState) {
    return <Text>Lesson not found</Text>;
  }

  return <LessonDelivery lessonState={lessonState} page={page} setPage={setPage} paused={paused} />;
};

export const LessonDelivery = ({
  lessonState,
  page,
  setPage,
  paused,
  children,
}: PropsWithChildren<{
  lessonState: LessonState;
  page: LessonDeliveryPage;
  setPage: (page: LessonDeliveryPage) => void;
  paused?: boolean;
}>) => {
  const { data: user } = useSession();

  const { data: currentLesson } = useCurrentLesson();

  let content = <></>;
  let currentActivityId = '';
  switch (page.page) {
    case 'landing':
      content = <LessonPackageList />;
      break;
    case 'setto':
      content = <SetToActivity lessonName={lessonState?.lessonName} activityId={page.activityId} />;
      break;
    case 'task':
      currentActivityId = page.lessonActivityID;
      content = (
        <LessonTaskDelivery
          lessonActivityID={page.lessonActivityID}
          packageID={page.packageID}
          taskID={page.taskID}
          page={page.taskItem}
          isForced={page.lessonActivityID === lessonState?.setLessonActivityId}
        />
      );
      break;
  }

  // Locking out of locked activities
  const currentActivity = lessonState?.activities.find(
    l => l.lessonActivityId === currentActivityId,
  );
  useEffect(() => {
    if (currentActivity?.lockState === LessonActivityLockState.LOCK_STATE_LOCKED) {
      setPage({ page: 'landing' });
    }
  }, [currentActivity, setPage]);

  // Forced activity behaviour
  const [lastDefaultActivity, setLastDefaultActivity] = useState('');
  const { data: packages } = usePackages({ suspense: false });
  useEffect(() => {
    const userSetActivityId = lessonState?.studentSetLessonActivityIds[user?.userId || ''];
    const setActivityId = userSetActivityId || lessonState?.setLessonActivityId;

    if (lastDefaultActivity === setActivityId) return; // do nothing - already set
    setLastDefaultActivity(setActivityId || '');

    const nextActivity = lessonState?.activities.find(
      // TODO(dothack): fix hack - the userSetActivityId can contain trailing .'s which can be ignored
      //  but are there to denote when the user has been reset to an activity.
      l => l.lessonActivityId === setActivityId?.replace(/\./g, ''),
    );
    if (nextActivity && nextActivity.lockState !== LessonActivityLockState.LOCK_STATE_LOCKED) {
      const pkg = packages?.find(
        p => p.assignment?.lessonActivityId === nextActivity.lessonActivityId,
      );
      if (nextActivity.lessonActivityId !== currentActivity?.lessonActivityId) {
        // Go to this activity if it's not completed
        if (!pkg || !isComplete(pkg.state?.completion)) {
          setPage({ page: 'setto', activityId: nextActivity.lessonActivityId });
        }
      } else if (pkg && page.page === 'task') {
        // If they are currently on this activity - check if they are on the first incomplete task
        // in it, otherwise set to it
        const firstIncompleteTask = getFirstIncompleteTaskFromPackageIfLoaded(pkg);
        if (page.taskID != firstIncompleteTask?.name.split('/')[3]) {
          setPage({ page: 'setto', activityId: nextActivity.lessonActivityId });
        }
      }
    }
  }, [
    user,
    page,
    setPage,
    currentActivity,
    lessonState,
    packages,
    lastDefaultActivity,
    setLastDefaultActivity,
  ]);

  // Forced navigate behaviour
  const { data: forcedTaskItem } = useQuery({
    queryKey: ['lesson', currentLesson?.lessonName, 'forced'],
    queryFn: () => new Promise<string>(() => undefined), // never resolve
    refetchInterval: false,
    enabled: true,
  });
  useEffect(() => {
    if (forcedTaskItem) {
      queryClient.setQueryData(['lesson', lessonState?.lessonName, 'forced'], () => '');

      const { packageName, packageID, taskID, taskItem } = splitTaskName(forcedTaskItem);
      if (!packageName || !taskID || taskItem === undefined) {
        return; // do nothing - can't parse forcedtask key
      }

      // Find the package that matches the forced task
      const pkg = packages?.find(p => p.name === packageName);
      const lessonActivityID = pkg?.assignment?.lessonActivityId;
      if (!lessonActivityID) {
        return; // do nothing - package is not associated with a lesson activity
      }

      const activity = lessonState?.activities.find(a => a.lessonActivityId === lessonActivityID);
      if (!activity || activity.lockState === LessonActivityLockState.LOCK_STATE_LOCKED) {
        return; // no activity or is locked - do not switch
      }
      if (
        lessonState?.setLessonActivityId &&
        lessonState.setLessonActivityId !== lessonActivityID
      ) {
        return; // locked into a different activity
      }

      setPage({
        page: 'task',
        lessonActivityID,
        packageID,
        taskID,
        taskItem,
      });
    }
  }, [setPage, forcedTaskItem, packages, lessonState]);

  // Pause view
  let view = <SuspenseRoute>{content}</SuspenseRoute>;
  if (paused) {
    view = (
      <Center flexDirection="column" py={7} height="100%">
        <Text fontSize="100px" color="blue.600" mb={2}>
          <FontAwesomeIcon icon={faCirclePause} />
        </Text>
        <Text fontSize="lg" color="gray.600">
          Your teacher has paused the lesson.
        </Text>
      </Center>
    );
  }

  return (
    <LessonDeliveryContext.Provider
      value={{ lessonName: lessonState?.lessonName || '', navigate: setPage }}
    >
      <Flex id="main-flex" position="absolute" inset="0 0 0 0" flexDirection="column">
        <Box position="relative" flex={1}>
          {view}
        </Box>
        {children}
      </Flex>
      {lessonState?.setEphemeralLessonActivityId && (
        <PopoverActivity lessonActivityId={lessonState.setEphemeralLessonActivityId} />
      )}
    </LessonDeliveryContext.Provider>
  );
};

const splitTaskName = (taskName: string) => {
  const packageParts = taskName.split('/tasks/');
  const packageName = packageParts[0];
  const packageID = packageParts[0]?.split('/')[1];
  const taskParts = packageParts?.[1]?.split('/items/');
  const taskID = taskParts?.[0];
  const taskItem = taskParts[1] ? parseInt(taskParts[1]) : undefined;

  return { packageName, packageID, taskID, taskItem };
};

const getFirstIncompleteTaskFromPackageIfLoaded = (pkg: Package) => {
  const fullData = getPackageFromQueryClient(pkg.name.split('/')[1]);
  for (const task of fullData?.package?.contents?.tasks || []) {
    if (!isComplete(task.state?.completion)) {
      return task;
    }
  }
  return undefined;
};
