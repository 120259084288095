import { faPause, faPlay, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IMediaInputElement } from '../question/types';
import { useAssetUrl } from '../utils/asset';
import styles from './MediaInputElement.module.css';

export const MediaInputElement = ({ element }: LayoutElementProps<IMediaInputElement>) => {
  const { sendAction, input } = useSparxQuestionContext();
  const ref = useRef<HTMLVideoElement>(null);

  const { url } = useAssetUrl(element.asset_id);

  const urls = { videoUrl: url, subtitleUrl: url + '/subtitles' };

  // Note: only supports VTTCue at the moment
  const [currentCue, setCurrentCue] = useState<VTTCue | undefined>(undefined);

  const [started, setStarted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [reachedTime, setReachedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const refCopy = ref.current;
      const timeCallback = () => {
        setMaxTime(mt => Math.max(mt, refCopy?.duration || mt));
        setCurrentTime(refCopy?.currentTime || 0);
        setReachedTime(mt => Math.max(mt, refCopy?.currentTime || mt));

        if (refCopy?.ended) {
          sendAction({ action: 'media_complete', ref: element.ref, value: element.asset_id });
        }
      };
      const playCallback = () => setPlaying(!refCopy?.paused || false);

      refCopy.addEventListener('play', playCallback);
      refCopy.addEventListener('pause', playCallback);
      refCopy.addEventListener('timeupdate', timeCallback);

      return () => {
        refCopy?.removeEventListener('play', playCallback);
        refCopy?.removeEventListener('pause', playCallback);
        refCopy?.removeEventListener('timeupdate', timeCallback);
      };
    }
  }, [ref, setPlaying, setMaxTime, setReachedTime, setCurrentTime, element, sendAction]);

  const play = () => {
    if (ref.current) {
      if (playing) ref.current.pause();
      else ref.current.play();
      setStarted(true);
    }
  };

  const rewind = () => {
    if (ref.current) {
      ref.current.currentTime = ref.current.currentTime - 10;
    }
  };

  const completed = Boolean(input.media_fields?.[element.ref]?.value);

  const subtitleTrack = (
    <track label="English" kind="subtitles" srcLang="en" src={urls.subtitleUrl} default={true} />
  );

  return (
    <div
      className={classNames(styles.Container, {
        [styles.ContainerComplete]: completed,
      })}
    >
      <div className={styles.Controls}>
        <button className={styles.PlayButton} onClick={play}>
          <FontAwesomeIcon icon={playing ? faPause : faPlay} />
        </button>
        <button className={styles.RewindButton} onClick={rewind} disabled={maxTime === 0}>
          <FontAwesomeIcon icon={faRotateLeft} />
        </button>
        <div className={styles.Track}>
          <div
            className={styles.TrackReached}
            style={{ width: `${(reachedTime / (maxTime || 1)) * 100}%` }}
          />
          <div
            className={styles.TrackCurrent}
            style={{ width: `${(currentTime / (maxTime || 1)) * 100}%` }}
          />
        </div>
      </div>

      <video
        crossOrigin="anonymous"
        ref={ref}
        src={urls.videoUrl}
        controls={false}
        preload="auto"
        onLoadedMetadata={e => {
          // Setup the tracks
          const track = e.currentTarget?.textTracks[0];
          if (!track) return;

          track.oncuechange = function () {
            const cues = [...(this.activeCues || [])].filter(c => c instanceof VTTCue) as VTTCue[];
            setCurrentCue(cues[0]); // TODO: what if is more than one cue?
          };
        }}
        onSeeking={() => {
          // This is sneaky but prevents seeking if they show the video and controls
          if (ref.current) {
            const diff = ref.current.currentTime - currentTime;
            if (diff > 0.01) {
              ref.current.currentTime = currentTime;
            }
          }
        }}
        style={{ display: 'none' }}
      >
        {subtitleTrack}
      </video>

      {subtitleTrack && (
        <div className={styles.Subtitles}>
          <AnimatePresence mode="popLayout">
            {started && currentCue ? (
              <SubtitleLine key={currentCue.startTime}>{currentCue.text}</SubtitleLine>
            ) : (
              <SubtitleLine key="empty">
                <i style={{ color: '#777777' }}>
                  {completed
                    ? 'Press the play button to replay the audio clip'
                    : 'Press the play button to listen to the audio clip'}
                </i>
              </SubtitleLine>
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

const SubtitleLine = React.forwardRef(
  ({ children }: PropsWithChildren, ref: React.ForwardedRef<HTMLDivElement>) => (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, translateY: 10 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: -10 }}
    >
      {children}
    </motion.div>
  ),
);
