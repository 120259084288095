import React, { Box, HStack, Text } from '@chakra-ui/react';
import {
  isTrackReference,
  ParticipantClickEvent,
  TrackReferenceOrPlaceholder,
} from '@livekit/components-core';
import {
  AudioTrack,
  ConnectionQualityIndicator,
  TrackMutedIndicator,
  useEnsureTrackRef,
  useFeatureContext,
  useParticipantTile,
  VideoTrack,
} from '@livekit/components-react';
import { Track } from 'livekit-client';

export interface ParticipantTileProps {
  /** The track reference to display. */
  trackRef?: TrackReferenceOrPlaceholder;
  disableSpeakingIndicator?: boolean;
  onParticipantClick?: (event: ParticipantClickEvent) => void;

  mutedIdentities?: Record<string, boolean>;
}

export function ObserverParticipant({
  trackRef,
  onParticipantClick,
  disableSpeakingIndicator,
  mutedIdentities,
}: ParticipantTileProps) {
  const trackReference = useEnsureTrackRef(trackRef);

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    htmlProps: {},
    disableSpeakingIndicator,
    onParticipantClick,
    trackRef: trackReference,
  });

  const autoManageSubscription = useFeatureContext()?.autoSubscription;
  const clientMuted = mutedIdentities?.[trackReference.participant?.identity];

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
      bg="gray.900"
      transition="outline 0.2s"
      outline={clientMuted ? '2px solid var(--chakra-colors-red-700)' : '0px solid transparent'}
      cursor="pointer"
      {...elementProps}
    >
      {isTrackReference(trackReference) &&
      (trackReference.publication?.kind === 'video' ||
        trackReference.source === Track.Source.Camera ||
        trackReference.source === Track.Source.ScreenShare) ? (
        <VideoTrack
          trackRef={trackReference}
          manageSubscription={autoManageSubscription}
          height="100%"
          width="100%"
          style={{}}
        />
      ) : (
        isTrackReference(trackReference) && <AudioTrack trackRef={trackReference} />
      )}
      {clientMuted && (
        <Box
          position="absolute"
          top={2}
          left={2}
          bg="blackAlpha.700"
          px={2}
          py={1}
          borderRadius="md"
          fontSize="sm"
          color="white"
        >
          Muted
        </Box>
      )}
      {/*<div className="lk-participant-placeholder">PLACEHOLDER</div>*/}
      <Box
        position="absolute"
        bottom={2}
        left={2}
        right={2}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box bg="blackAlpha.700" px={2} py={1} borderRadius="md" fontSize="sm" color="white">
          {trackReference.source === Track.Source.Camera ? (
            <HStack spacing={1}>
              <TrackMutedIndicator
                trackRef={{
                  participant: trackReference.participant,
                  source: Track.Source.Microphone,
                }}
                show={'muted'}
              />
              <Text>
                {trackReference?.participant?.identity} ({trackReference?.publication?.trackName})
              </Text>
            </HStack>
          ) : (
            <>{trackReference?.participant?.identity} (screen)</>
          )}
        </Box>
        <ConnectionQualityIndicator className="lk-participant-metadata-item" />
      </Box>
    </Box>
  );
}
