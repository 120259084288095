import { useMediaQuery } from 'react-responsive';
/**
 * Breakpoints from base.css
 */
type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const useBreakpoint = (breakpoint: Breakpoint) => {
  let query = '';
  switch (breakpoint) {
    case 'xxs':
      query = '(max-width: 240px)';
      break;
    case 'xs':
      query = '(max-width: 360px)';
      break;
    case 'sm':
      query = '(max-width: 480px)';
      break;
    case 'md':
      query = '(max-width: 768px)';
      break;
    case 'lg':
      query = '(max-width: 1024px)';
      break;
    case 'xl':
      query = '(max-width: 1440px)';
      break;
    case 'xxl':
      query = '(max-width: 1920px)';
      break;
  }

  return useMediaQuery({ query });
};

export const useVerticalBreakpoint = (breakpoint: Breakpoint) => {
  let query = '';
  switch (breakpoint) {
    case 'xxs':
      query = '';
      break;
    case 'xs':
      query = '';
      break;
    case 'sm':
      query = '';
      break;
    case 'md':
      query = '(max-height: 700px)';
      break;
    case 'lg':
      query = '(max-height: 800px)';
      break;
    case 'xl':
      query = '';
      break;
    case 'xxl':
      query = '';
      break;
  }

  return useMediaQuery({ query });
};
