import { Alert, AlertIcon, AlertProps, Box, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const Warning = ({
  title,
  children,
  ...props
}: PropsWithChildren<Omit<AlertProps, 'title'> & { title?: string | React.ReactNode }>) => (
  <Alert px={3} py={2} mt={1} borderRadius="md" {...props}>
    <AlertIcon />
    <Box width="100%">
      {title && <Text>{title}</Text>}
      {children}
    </Box>
  </Alert>
);
