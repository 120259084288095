// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/misintegration/wondewitch/v1/wondewitch.proto" (package "sparx.wondewitch.v1.wondewitch", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Any } from '../../../../../google/protobuf/any';
import { StringValue } from '../../../../../google/protobuf/wrappers';
import { Int32Value } from '../../../../../google/protobuf/wrappers';
import { BoolValue } from '../../../../../google/protobuf/wrappers';
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolMetaRequest
 */
export interface GetSchoolMetaRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 2;
   */
  wondeDomain: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 3;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolMetaResponse
 */
export interface GetSchoolMetaResponse {
  /**
   * If the school is online.
   *
   * @generated from protobuf field: bool online = 1;
   */
  online: boolean;
  /**
   * If the school has approved access to application.
   *
   * @generated from protobuf field: bool approved = 2;
   */
  approved: boolean;
  /**
   * If the school has been audited for data completeness.
   *
   * @generated from protobuf field: bool audited = 3;
   */
  audited: boolean;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsRequest
 */
export interface GetSchoolPermissionsRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 2;
   */
  wondeDomain: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 3;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsResponse
 */
export interface GetSchoolPermissionsResponse {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.WondeSchoolPermission permissions = 1;
   */
  permissions: WondeSchoolPermission[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.WondeSchoolPermission
 */
export interface WondeSchoolPermission {
  /**
   * Wonde ID for the permission, eg "students.read"
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string;
  /**
   * Wonde ID for parent permission
   *
   * @generated from protobuf field: string parent = 4;
   */
  parent: string;
  /**
   * @generated from protobuf field: bool optional = 5;
   */
  optional: boolean;
  /**
   * @generated from protobuf field: bool approved = 6;
   */
  approved: boolean;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListSubjectsRequest
 */
export interface ListSubjectsRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 2;
   */
  wondeDomain: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 3;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Subject
 */
export interface Subject {
  /**
   * The ID of the object.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * The subject ID in the MIS.
   *
   * @generated from protobuf field: string mis_id = 2;
   */
  misId: string;
  /**
   * The subject’s name.
   *
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * Short identifier for the subject.
   *
   * @generated from protobuf field: string code = 4;
   */
  code: string;
  /**
   * Is the subject active.
   *
   * @generated from protobuf field: bool active = 5;
   */
  active: boolean;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListSubjectsResponse
 */
export interface ListSubjectsResponse {
  /**
   * Unordered list of Subjects.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Subject subjects = 1;
   */
  subjects: Subject[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest
 */
export interface RequestAccessToSchoolRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * List of Access Request contacts. This is optional.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest.Contact contacts = 2;
   */
  contacts: RequestAccessToSchoolRequest_Contact[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest.Contact
 */
export interface RequestAccessToSchoolRequest_Contact {
  /**
   * First name of contact. Required.
   *
   * @generated from protobuf field: string first_name = 1;
   */
  firstName: string;
  /**
   * Last name of contact. Required.
   *
   * @generated from protobuf field: string last_name = 2;
   */
  lastName: string;
  /**
   * Phone number of contact.
   *
   * @generated from protobuf field: string phone_number = 3;
   */
  phoneNumber: string;
  /**
   * Email address of contact.
   *
   * @generated from protobuf field: string email_address = 4;
   */
  emailAddress: string;
  /**
   * Notes for the contact.
   *
   * @generated from protobuf field: string notes = 5;
   */
  notes: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolResponse
 */
export interface RequestAccessToSchoolResponse {
  /**
   * Access request was successfully received.
   *
   * @generated from protobuf field: bool success = 1;
   */
  success: boolean;
  /**
   * State of the access request.
   *
   * @generated from protobuf field: string state = 2;
   */
  state: string;
  /**
   * Message regarding the request.
   *
   * @generated from protobuf field: string message = 3;
   */
  message: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListClassesRequest
 */
export interface ListClassesRequest {
  /**
   * The Wonde ID for the school. Required.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 3;
   */
  wondeDomain: string;
  /**
   * The Wonde ID for the subject. (if field omitted on the request all classes for a school will be fetched)
   *
   * @generated from protobuf field: string subject_wonde_id = 2;
   */
  subjectWondeId: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 4;
   */
  authority: WondeAuthority;
  /**
   * Skip fetching & returning students if we don't need them.
   *
   * @generated from protobuf field: bool no_include_students = 5;
   */
  noIncludeStudents: boolean;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Class
 */
export interface Class {
  /**
   * The ID of the object.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * Class name.
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * Class code.
   *
   * @generated from protobuf field: string code = 3;
   */
  code: string;
  /**
   * Class description.
   *
   * @generated from protobuf field: string description = 4;
   */
  description: string;
  /**
   * The subject ID for the class.
   *
   * @generated from protobuf field: string subject_id = 5;
   */
  subjectId: string;
  /**
   * The class is an alternative to another class.
   *
   * @generated from protobuf field: bool alternative = 6;
   */
  alternative: boolean;
  /**
   * The students attached to the classroom.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Student students = 7;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListClassesResponse
 */
export interface ListClassesResponse {
  /**
   * Unordered list of Classes.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Class classes = 1;
   */
  classes: Class[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesRequest
 */
export interface ListStudentsInWondeClassesRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 3;
   */
  wondeDomain: string;
  /**
   * List of Wonde class IDs.
   *
   * @generated from protobuf field: repeated string class_wonde_ids = 2;
   */
  classWondeIds: string[];
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 4;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesResponse
 */
export interface ListStudentsInWondeClassesResponse {
  /**
   * Collection of students in classes. Keyed by the classes Wonde ID.
   *
   * @generated from protobuf field: map<string, sparx.wondewitch.v1.wondewitch.Students> classes = 1;
   */
  classes: {
    [key: string]: Students;
  };
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsRequest
 */
export interface ListStudentsRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 2;
   */
  wondeDomain: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 3;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsResponse
 */
export interface ListStudentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Students
 */
export interface Students {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Student
 */
export interface Student {
  /**
   * Wonde ID for the student.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * UPI.
   *
   * @generated from protobuf field: string upi = 2;
   */
  upi: string;
  /**
   * MIS ID for the student.
   *
   * @generated from protobuf field: string mis_id = 3;
   */
  misId: string;
  /**
   * 'Preferred' surname.
   *
   * @generated from protobuf field: string surname = 4;
   */
  surname: string;
  /**
   * 'Preferred' first name.
   *
   * @generated from protobuf field: string forename = 5;
   */
  forename: string;
  /**
   * Middle names.
   *
   * @generated from protobuf field: string middle_names = 6;
   */
  middleNames: string;
  /**
   * Legal surname.
   *
   * @generated from protobuf field: string legal_surname = 7;
   */
  legalSurname: string;
  /**
   * Legal first name.
   *
   * @generated from protobuf field: string legal_forename = 8;
   */
  legalForename: string;
  /**
   * Gender.
   *
   * @generated from protobuf field: string gender = 9;
   */
  gender: string;
  /**
   * Initials.
   *
   * @generated from protobuf field: string initials = 10;
   */
  initials: string;
  /**
   * Date of birth.
   *
   * @generated from protobuf field: string date_of_birth = 11;
   */
  dateOfBirth: string;
  /**
   * Student contacts.
   * This is populated for each Student returned by ListStudentsInWondeClasses and ListStudents,
   * but is not populated for Students returned by ListClasses.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Contact contacts = 12;
   */
  contacts: Contact[];
  /**
   * Student education details (e.g UPN).
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.EducationDetails education_details = 13;
   */
  educationDetails?: EducationDetails;
  /**
   * Wonde ID for classes this student is a member of.
   * This is populated for each Student returned by ListStudentsInWondeClasses and ListStudents,
   * but is not populated for Students returned by ListClasses.
   *
   * @generated from protobuf field: repeated string classes = 14;
   */
  classes: string[];
  /**
   * Wonde ID for the registration group this student is a member of.
   * This is required for syncing primary schools, where students are typically
   * not sorted into classes.
   * This is populated for each Student returned by ListStudentsInWondeClasses and ListStudents,
   * but is not populated for Students returned by ListClasses.
   *
   * @generated from protobuf field: string registrationGroup = 15;
   */
  registrationGroup: string;
  /**
   * The contact details, specifically for the student. Look at Contacts
   * for details of parents and other related contacts.
   * This is populated for each Student returned by ListStudentsInWondeClasses and ListStudents,
   * but is not populated for Students returned by ListClasses.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails contact_details = 16;
   */
  contactDetails?: ContactDetails;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails
 */
export interface ContactDetails {
  /**
   * Phone numbers for the contact.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails.Phones phones = 1;
   */
  phones?: ContactDetails_Phones;
  /**
   * Email addresses for the contact.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails.Emails emails = 2;
   */
  emails?: ContactDetails_Emails;
  /**
   * Addresses for the contact.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails.Addresses addresses = 3;
   */
  addresses?: ContactDetails_Addresses;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Phones
 */
export interface ContactDetails_Phones {
  /**
   * Phone number.
   *
   * @generated from protobuf field: string phone = 1;
   */
  phone: string;
  /**
   * Primary phone number.
   *
   * @generated from protobuf field: string primary = 2;
   */
  primary: string;
  /**
   * Home phone number.
   *
   * @generated from protobuf field: string home = 3;
   */
  home: string;
  /**
   * Work phone number.
   *
   * @generated from protobuf field: string work = 4;
   */
  work: string;
  /**
   * Mobile phone number.
   *
   * @generated from protobuf field: string mobile = 5;
   */
  mobile: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Emails
 */
export interface ContactDetails_Emails {
  /**
   * Email address.
   *
   * @generated from protobuf field: string email = 1;
   */
  email: string;
  /**
   * Primary email address.
   *
   * @generated from protobuf field: string primary = 2;
   */
  primary: string;
  /**
   * Home email address.
   *
   * @generated from protobuf field: string home = 3;
   */
  home: string;
  /**
   * Work email address.
   *
   * @generated from protobuf field: string work = 4;
   */
  work: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Addresses
 */
export interface ContactDetails_Addresses {
  /**
   * Home address.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails.Address home = 1;
   */
  home?: ContactDetails_Address;
  /**
   * Work address.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails.Address work = 2;
   */
  work?: ContactDetails_Address;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Address
 */
export interface ContactDetails_Address {
  /**
   * House number.
   *
   * @generated from protobuf field: string house_number = 1;
   */
  houseNumber: string;
  /**
   * House name.
   *
   * @generated from protobuf field: string house_name = 2;
   */
  houseName: string;
  /**
   * Apartment.
   *
   * @generated from protobuf field: string apartment = 3;
   */
  apartment: string;
  /**
   * Street.
   *
   * @generated from protobuf field: string street = 4;
   */
  street: string;
  /**
   * District (e.g Kingston).
   *
   * @generated from protobuf field: string district = 5;
   */
  district: string;
  /**
   * Town.
   *
   * @generated from protobuf field: string town = 6;
   */
  town: string;
  /**
   * County (e.g Berkshire).
   *
   * @generated from protobuf field: string county = 7;
   */
  county: string;
  /**
   * Country (e.g United Kingdom).
   *
   * @generated from protobuf field: string country = 8;
   */
  country: string;
  /**
   * Postcode.
   *
   * @generated from protobuf field: string postcode = 9;
   */
  postcode: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Contact
 */
export interface Contact {
  /**
   * Wonde ID for the contact.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * UPI.
   *
   * @generated from protobuf field: string upi = 2;
   */
  upi: string;
  /**
   * Contacts MIS ID.
   *
   * @generated from protobuf field: string mis_id = 3;
   */
  misId: string;
  /**
   * 'Preferred' surname.
   *
   * @generated from protobuf field: string surname = 4;
   */
  surname: string;
  /**
   * 'Preferred' first name.
   *
   * @generated from protobuf field: string forename = 5;
   */
  forename: string;
  /**
   * Middle names.
   *
   * @generated from protobuf field: string middle_names = 6;
   */
  middleNames: string;
  /**
   * Legal surname.
   *
   * @generated from protobuf field: string legal_surname = 7;
   */
  legalSurname: string;
  /**
   * Legal first name.
   *
   * @generated from protobuf field: string legal_forename = 8;
   */
  legalForename: string;
  /**
   * Gender.
   *
   * @generated from protobuf field: string gender = 9;
   */
  gender: string;
  /**
   * Initials.
   *
   * @generated from protobuf field: string initials = 10;
   */
  initials: string;
  /**
   * Contact Details for the contact.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ContactDetails contact_details = 11;
   */
  contactDetails?: ContactDetails;
  /**
   * Relationship information for the contact. For more detail, see:
   * https://docs.wonde.com/docs/api/sync#relationship-object
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.Contact.Relationship relationship = 12;
   */
  relationship?: Contact_Relationship;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.Contact.Relationship
 */
export interface Contact_Relationship {
  /**
   * If the contact is responsible for paying bills
   *
   * @generated from protobuf field: google.protobuf.BoolValue bill_payer = 1;
   */
  billPayer?: BoolValue;
  /**
   * If the contact shares the same address as the student
   *
   * @generated from protobuf field: google.protobuf.BoolValue lives_with_pupil = 2;
   */
  livesWithPupil?: BoolValue;
  /**
   * If the contact accepts bills through email
   *
   * @generated from protobuf field: google.protobuf.BoolValue email_bills = 3;
   */
  emailBills?: BoolValue;
  /**
   * If the contact receives copies of the bill
   *
   * @generated from protobuf field: google.protobuf.BoolValue copy_bills = 4;
   */
  copyBills?: BoolValue;
  /**
   * If a court order is in place restricting access between the contact and student
   *
   * @generated from protobuf field: google.protobuf.BoolValue court_order = 5;
   */
  courtOrder?: BoolValue;
  /**
   * If the contact does not live at the same address as the child,
   * has parental responsibility and they wish to receive reports
   *
   * @generated from protobuf field: google.protobuf.BoolValue pupil_report = 6;
   */
  pupilReport?: BoolValue;
  /**
   * If the contact has parental responsibility
   *
   * @generated from protobuf field: google.protobuf.BoolValue parental_responsibility = 7;
   */
  parentalResponsibility?: BoolValue;
  /**
   * If the contact does not live at the same address as the child,
   * has parental responsibility and they wish to receive correspondence
   *
   * @generated from protobuf field: google.protobuf.BoolValue correspondence = 8;
   */
  correspondence?: BoolValue;
  /**
   * If the contact allows correspondence by email
   *
   * @generated from protobuf field: google.protobuf.BoolValue email_notification = 9;
   */
  emailNotification?: BoolValue;
  /**
   * If the contact allows correspondence by SMS
   *
   * @generated from protobuf field: google.protobuf.BoolValue sms_notification = 10;
   */
  smsNotification?: BoolValue;
  /**
   * If the contact allows correspondence by mail
   *
   * @generated from protobuf field: google.protobuf.BoolValue mail_notification = 11;
   */
  mailNotification?: BoolValue;
  /**
   * If the contact allows correspondence by mail push notification
   *
   * @generated from protobuf field: google.protobuf.BoolValue push_notification = 12;
   */
  pushNotification?: BoolValue;
  /**
   * If the contact uses the SIMS InTouch communication tool
   *
   * @generated from protobuf field: google.protobuf.BoolValue in_touch_communication = 13;
   */
  inTouchCommunication?: BoolValue;
  /**
   * The order in which to contact
   *
   * @generated from protobuf field: google.protobuf.Int32Value priority = 14;
   */
  priority?: Int32Value;
  /**
   * The relationship between contact and student
   *
   * @generated from protobuf field: google.protobuf.StringValue relationship = 15;
   */
  relationship?: StringValue;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.EducationDetails
 */
export interface EducationDetails {
  /**
   * The student admission number is an auto incrementing ID given to students in SIMS.
   *
   * @generated from protobuf field: string admission_number = 1;
   */
  admissionNumber: string;
  /**
   * Local authority unique pupil number.
   *
   * @generated from protobuf field: string local_upn = 2;
   */
  localUpn: string;
  /**
   * Unique pupil number.
   *
   * @generated from protobuf field: string upn = 3;
   */
  upn: string;
  /**
   * Previous unique pupil number.
   *
   * @generated from protobuf field: string former_upn = 4;
   */
  formerUpn: string;
  /**
   * Unique learner number.
   *
   * @generated from protobuf field: string learner_number = 5;
   */
  learnerNumber: string;
  /**
   * Current National Curriculum year.
   *
   * @generated from protobuf field: string current_nc_year = 6;
   */
  currentNcYear: string;
  /**
   * Is the student part time.
   *
   * @generated from protobuf field: bool part_time = 7;
   */
  partTime: boolean;
  /**
   * Student’s admission date.
   *
   * @generated from protobuf field: string admission_date = 8;
   */
  admissionDate: string;
  /**
   * Student’s leaving date.
   *
   * @generated from protobuf field: string leaving_date = 9;
   */
  leavingDate: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListSchoolsRequest
 */
export interface ListSchoolsRequest {
  /**
   * Filter returned list to only the schools with these ids
   *
   * @generated from protobuf field: repeated string school_wonde_ids = 1;
   */
  schoolWondeIds: string[];
  /**
   * Filter returned list to only schools with the specified authorisation status
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.ListSchoolsRequest.AuthStatus status = 2;
   */
  status: ListSchoolsRequest_AuthStatus;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 3;
   */
  authority: WondeAuthority;
}
/**
 * Status of our access to school's data via Wonde
 *
 * @generated from protobuf enum sparx.wondewitch.v1.wondewitch.ListSchoolsRequest.AuthStatus
 */
export enum ListSchoolsRequest_AuthStatus {
  /**
   * Not specified
   *
   * @generated from protobuf enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Schools approved (although perhaps not yet audited)
   *
   * @generated from protobuf enum value: APPROVED = 1;
   */
  APPROVED = 1,
  /**
   * Schools that have a pending access request
   *
   * @generated from protobuf enum value: PENDING = 2;
   */
  PENDING = 2,
  /**
   * Schools that have passed Wonde's data audit
   *
   * @generated from protobuf enum value: AUDITED = 3;
   */
  AUDITED = 3,
  /**
   * Schools that have declined access
   *
   * @generated from protobuf enum value: DECLINED = 4;
   */
  DECLINED = 4,
  /**
   * Schools that have revoked access
   *
   * @generated from protobuf enum value: REVOKED = 5;
   */
  REVOKED = 5,
  /**
   * All schools
   *
   * @generated from protobuf enum value: ALL = 6;
   */
  ALL = 6,
  /**
   * Schools have not responded to Wonde for 24 hours
   *
   * @generated from protobuf enum value: OFFLINE = 7;
   */
  OFFLINE = 7,
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListSchoolsResponse
 */
export interface ListSchoolsResponse {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.School schools = 1;
   */
  schools: School[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School
 */
export interface School {
  /**
   * The ID of the object.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * Name of school.
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * Establishment number.
   *
   * @generated from protobuf field: string establishment_number = 3;
   */
  establishmentNumber: string;
  /**
   * URN.
   *
   * @generated from protobuf field: int64 urn = 4;
   */
  urn: number;
  /**
   * Phase of education (e.g primary).
   *
   * @generated from protobuf field: string phase_of_education = 5;
   */
  phaseOfEducation: string;
  /**
   * Local authority code.
   *
   * @generated from protobuf field: string la_code = 6;
   */
  laCode: string;
  /**
   * Timezone.
   *
   * @generated from protobuf field: string timezone = 7;
   */
  timezone: string;
  /**
   * MIS the school uses.
   *
   * @generated from protobuf field: string mis = 8;
   */
  mis: string;
  /**
   * Address.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School.Address address = 9;
   */
  address?: School_Address;
  /**
   * Extended details
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School.Extended extended = 10;
   */
  extended?: School_Extended;
  /**
   * Region.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School.Region region = 11;
   */
  region?: School_Region;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School.Address
 */
export interface School_Address {
  /**
   * Address line 1.
   *
   * @generated from protobuf field: string address_line_1 = 1;
   */
  addressLine1: string;
  /**
   * Address line 2.
   *
   * @generated from protobuf field: string address_line_2 = 2;
   */
  addressLine2: string;
  /**
   * Town.
   *
   * @generated from protobuf field: string address_town = 3;
   */
  addressTown: string;
  /**
   * Postcode.
   *
   * @generated from protobuf field: string address_postcode = 4;
   */
  addressPostcode: string;
  /**
   * Country.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School.Address.AddressCountry address_country = 5;
   */
  addressCountry?: School_Address_AddressCountry;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School.Address.AddressCountry
 */
export interface School_Address_AddressCountry {
  /**
   * Country code (e.g GBR).
   *
   * @generated from protobuf field: string code = 1;
   */
  code: string;
  /**
   * Country name (e.g United Kingdom).
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School.Extended
 */
export interface School_Extended {
  /**
   * Allows MIS writeback.
   *
   * @generated from protobuf field: bool allows_writeback = 1;
   */
  allowsWriteback: boolean;
  /**
   * Has timetables.
   *
   * @generated from protobuf field: bool has_timetables = 2;
   */
  hasTimetables: boolean;
  /**
   * Has lesson attendance.
   *
   * @generated from protobuf field: bool has_lesson_attendance = 3;
   */
  hasLessonAttendance: boolean;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School.Region
 */
export interface School_Region {
  /**
   * Region code (e.g GBR).
   *
   * @generated from protobuf field: string code = 1;
   */
  code: string;
  /**
   * Domain.
   *
   * @generated from protobuf field: string domain = 2;
   */
  domain: string;
  /**
   * Schools Wonde URL.
   *
   * @generated from protobuf field: string school_url = 3;
   */
  schoolUrl: string;
  /**
   * Identifiers.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School.Region.Identifiers identifiers = 4;
   */
  identifiers?: School_Region_Identifiers;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.School.Region.Identifiers
 */
export interface School_Region_Identifiers {
  /**
   * Local authority code.
   *
   * @generated from protobuf field: string la_code = 1;
   */
  laCode: string;
  /**
   * Establishment number.
   *
   * @generated from protobuf field: string establishment_number = 2;
   */
  establishmentNumber: string;
  /**
   * URN.
   *
   * @generated from protobuf field: int64 urn = 3;
   */
  urn: number;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolRequest
 */
export interface GetSchoolRequest {
  /**
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 2;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolResponse
 */
export interface GetSchoolResponse {
  /**
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.School school = 1;
   */
  school?: School;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentDemographicsRequest
 */
export interface ListStudentDemographicsRequest {
  /**
   * The Wonde ID for the school.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 3;
   */
  wondeDomain: string;
  /**
   * List of Wonde student IDs.
   *
   * @generated from protobuf field: repeated string student_wonde_ids = 2;
   */
  studentWondeIds: string[];
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 4;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.ListStudentDemographicsResponse
 */
export interface ListStudentDemographicsResponse {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.StudentDemographics student_demographics = 1;
   */
  studentDemographics: StudentDemographics[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.StudentDemographics
 */
export interface StudentDemographics {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * Will be of the type:
   * school.student.demographic.v1.DemographicData
   *
   * @generated from protobuf field: google.protobuf.Any demographic_data = 2;
   */
  demographicData?: Any;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetClassRequest
 */
export interface GetClassRequest {
  /**
   * The Wonde ID for the school. Required.
   *
   * @generated from protobuf field: string school_wonde_id = 2;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 3;
   */
  wondeDomain: string;
  /**
   * The WondeID for the Class. Required.
   *
   * @generated from protobuf field: string class_wonde_id = 1;
   */
  classWondeId: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 4;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetClassResponse
 */
export interface GetClassResponse {
  /**
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.Class class = 1;
   */
  class?: Class;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetClassByNameRequest
 */
export interface GetClassByNameRequest {
  /**
   * The Wonde ID for the school. Required.
   *
   * @generated from protobuf field: string school_wonde_id = 1;
   */
  schoolWondeId: string;
  /**
   * The Wonde regional domain required to access the Wonde API for this school.
   * The whole domain is expected here, without trailing slash. For instance the
   * default domain (used if this field is omitted on the request) is "api.wonde.com".
   *
   * @generated from protobuf field: string wonde_domain = 2;
   */
  wondeDomain: string;
  /**
   * The Class name to search for i.e. '7x/Ma1'. Required.
   *
   * @generated from protobuf field: string class_name = 3;
   */
  className: string;
  /**
   * Which authority to adopt in making this request to Wonde. Defaults to
   * WONDE_AUTHORITY_SPARX if unspecified.
   *
   * @generated from protobuf field: sparx.wondewitch.v1.wondewitch.WondeAuthority authority = 4;
   */
  authority: WondeAuthority;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.GetClassByNameResponse
 */
export interface GetClassByNameResponse {
  /**
   * Unordered list of Classes.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Class classes = 1;
   */
  classes: Class[];
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.LookupWondeIdRequest
 */
export interface LookupWondeIdRequest {
  /**
   * A URN to match with Wonde. Either this, or postcode, or both must be provided.
   *
   * @generated from protobuf field: string urn = 1;
   */
  urn: string;
  /**
   * A postcode or partial postcode to match with Wonde. Either this, or URN, or both must be provided.
   *
   * @generated from protobuf field: string postcode = 2;
   */
  postcode: string;
}
/**
 * @generated from protobuf message sparx.wondewitch.v1.wondewitch.LookupWondeIdResponse
 */
export interface LookupWondeIdResponse {
  /**
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.School schools = 1;
   */
  schools: School[];
}
/**
 * @generated from protobuf enum sparx.wondewitch.v1.wondewitch.WondeAuthority
 */
export enum WondeAuthority {
  /**
   * @generated from protobuf enum value: WONDE_AUTHORITY_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: WONDE_AUTHORITY_SPARX = 1;
   */
  SPARX = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolMetaRequest$Type extends MessageType<GetSchoolMetaRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolMetaRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolMetaRequest
 */
export const GetSchoolMetaRequest = new GetSchoolMetaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolMetaResponse$Type extends MessageType<GetSchoolMetaResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolMetaResponse', [
      { no: 1, name: 'online', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'approved', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'audited', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolMetaResponse
 */
export const GetSchoolMetaResponse = new GetSchoolMetaResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolPermissionsRequest$Type extends MessageType<GetSchoolPermissionsRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsRequest
 */
export const GetSchoolPermissionsRequest =
  new GetSchoolPermissionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolPermissionsResponse$Type extends MessageType<GetSchoolPermissionsResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsResponse', [
      {
        no: 1,
        name: 'permissions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => WondeSchoolPermission,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolPermissionsResponse
 */
export const GetSchoolPermissionsResponse =
  new GetSchoolPermissionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WondeSchoolPermission$Type extends MessageType<WondeSchoolPermission> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.WondeSchoolPermission', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'optional', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'approved', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.WondeSchoolPermission
 */
export const WondeSchoolPermission = new WondeSchoolPermission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubjectsRequest$Type extends MessageType<ListSubjectsRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListSubjectsRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListSubjectsRequest
 */
export const ListSubjectsRequest = new ListSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subject$Type extends MessageType<Subject> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Subject', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'mis_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Subject
 */
export const Subject = new Subject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubjectsResponse$Type extends MessageType<ListSubjectsResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListSubjectsResponse', [
      {
        no: 1,
        name: 'subjects',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Subject,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListSubjectsResponse
 */
export const ListSubjectsResponse = new ListSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestAccessToSchoolRequest$Type extends MessageType<RequestAccessToSchoolRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'contacts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RequestAccessToSchoolRequest_Contact,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest
 */
export const RequestAccessToSchoolRequest =
  new RequestAccessToSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestAccessToSchoolRequest_Contact$Type extends MessageType<RequestAccessToSchoolRequest_Contact> {
  constructor() {
    super(
      'sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest.Contact',
      [
        {
          no: 1,
          name: 'first_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'last_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: 'phone_number',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: 'email_address',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 5, name: 'notes', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      ]
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolRequest.Contact
 */
export const RequestAccessToSchoolRequest_Contact =
  new RequestAccessToSchoolRequest_Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestAccessToSchoolResponse$Type extends MessageType<RequestAccessToSchoolResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolResponse', [
      { no: 1, name: 'success', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'state', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.RequestAccessToSchoolResponse
 */
export const RequestAccessToSchoolResponse =
  new RequestAccessToSchoolResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassesRequest$Type extends MessageType<ListClassesRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListClassesRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'subject_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
      {
        no: 5,
        name: 'no_include_students',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListClassesRequest
 */
export const ListClassesRequest = new ListClassesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Class$Type extends MessageType<Class> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Class', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'subject_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'alternative', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Class
 */
export const Class = new Class$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassesResponse$Type extends MessageType<ListClassesResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListClassesResponse', [
      {
        no: 1,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Class,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListClassesResponse
 */
export const ListClassesResponse = new ListClassesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsInWondeClassesRequest$Type extends MessageType<ListStudentsInWondeClassesRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'class_wonde_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesRequest
 */
export const ListStudentsInWondeClassesRequest =
  new ListStudentsInWondeClassesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsInWondeClassesResponse$Type extends MessageType<ListStudentsInWondeClassesResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesResponse', [
      {
        no: 1,
        name: 'classes',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => Students },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsInWondeClassesResponse
 */
export const ListStudentsInWondeClassesResponse =
  new ListStudentsInWondeClassesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsRequest$Type extends MessageType<ListStudentsRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentsRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsRequest
 */
export const ListStudentsRequest = new ListStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsResponse$Type extends MessageType<ListStudentsResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentsResponse
 */
export const ListStudentsResponse = new ListStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Students$Type extends MessageType<Students> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Students', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Students
 */
export const Students = new Students$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Student$Type extends MessageType<Student> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Student', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'upi', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'mis_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'surname', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'forename', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'middle_names',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'legal_surname',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'legal_forename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 9, name: 'gender', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 10, name: 'initials', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 11,
        name: 'date_of_birth',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'contacts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Contact,
      },
      {
        no: 13,
        name: 'education_details',
        kind: 'message',
        T: () => EducationDetails,
      },
      {
        no: 14,
        name: 'classes',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: 'registrationGroup',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'contact_details',
        kind: 'message',
        T: () => ContactDetails,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Student
 */
export const Student = new Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactDetails$Type extends MessageType<ContactDetails> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ContactDetails', [
      {
        no: 1,
        name: 'phones',
        kind: 'message',
        T: () => ContactDetails_Phones,
      },
      {
        no: 2,
        name: 'emails',
        kind: 'message',
        T: () => ContactDetails_Emails,
      },
      {
        no: 3,
        name: 'addresses',
        kind: 'message',
        T: () => ContactDetails_Addresses,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails
 */
export const ContactDetails = new ContactDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactDetails_Phones$Type extends MessageType<ContactDetails_Phones> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ContactDetails.Phones', [
      { no: 1, name: 'phone', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'primary', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'home', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'work', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'mobile', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Phones
 */
export const ContactDetails_Phones = new ContactDetails_Phones$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactDetails_Emails$Type extends MessageType<ContactDetails_Emails> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ContactDetails.Emails', [
      { no: 1, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'primary', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'home', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'work', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Emails
 */
export const ContactDetails_Emails = new ContactDetails_Emails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactDetails_Addresses$Type extends MessageType<ContactDetails_Addresses> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ContactDetails.Addresses', [
      { no: 1, name: 'home', kind: 'message', T: () => ContactDetails_Address },
      { no: 2, name: 'work', kind: 'message', T: () => ContactDetails_Address },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Addresses
 */
export const ContactDetails_Addresses = new ContactDetails_Addresses$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactDetails_Address$Type extends MessageType<ContactDetails_Address> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ContactDetails.Address', [
      {
        no: 1,
        name: 'house_number',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'house_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'apartment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'street', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'district', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'town', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'county', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'country', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'postcode', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ContactDetails.Address
 */
export const ContactDetails_Address = new ContactDetails_Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contact$Type extends MessageType<Contact> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Contact', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'upi', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'mis_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'surname', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'forename', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'middle_names',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'legal_surname',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'legal_forename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 9, name: 'gender', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 10, name: 'initials', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 11,
        name: 'contact_details',
        kind: 'message',
        T: () => ContactDetails,
      },
      {
        no: 12,
        name: 'relationship',
        kind: 'message',
        T: () => Contact_Relationship,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Contact
 */
export const Contact = new Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contact_Relationship$Type extends MessageType<Contact_Relationship> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.Contact.Relationship', [
      { no: 1, name: 'bill_payer', kind: 'message', T: () => BoolValue },
      { no: 2, name: 'lives_with_pupil', kind: 'message', T: () => BoolValue },
      { no: 3, name: 'email_bills', kind: 'message', T: () => BoolValue },
      { no: 4, name: 'copy_bills', kind: 'message', T: () => BoolValue },
      { no: 5, name: 'court_order', kind: 'message', T: () => BoolValue },
      { no: 6, name: 'pupil_report', kind: 'message', T: () => BoolValue },
      {
        no: 7,
        name: 'parental_responsibility',
        kind: 'message',
        T: () => BoolValue,
      },
      { no: 8, name: 'correspondence', kind: 'message', T: () => BoolValue },
      {
        no: 9,
        name: 'email_notification',
        kind: 'message',
        T: () => BoolValue,
      },
      { no: 10, name: 'sms_notification', kind: 'message', T: () => BoolValue },
      {
        no: 11,
        name: 'mail_notification',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 12,
        name: 'push_notification',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 13,
        name: 'in_touch_communication',
        kind: 'message',
        T: () => BoolValue,
      },
      { no: 14, name: 'priority', kind: 'message', T: () => Int32Value },
      { no: 15, name: 'relationship', kind: 'message', T: () => StringValue },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.Contact.Relationship
 */
export const Contact_Relationship = new Contact_Relationship$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EducationDetails$Type extends MessageType<EducationDetails> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.EducationDetails', [
      {
        no: 1,
        name: 'admission_number',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'local_upn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'upn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'former_upn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'learner_number',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'current_nc_year',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'part_time', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'admission_date',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'leaving_date',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.EducationDetails
 */
export const EducationDetails = new EducationDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolsRequest$Type extends MessageType<ListSchoolsRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListSchoolsRequest', [
      {
        no: 1,
        name: 'school_wonde_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.ListSchoolsRequest.AuthStatus',
          ListSchoolsRequest_AuthStatus,
        ],
      },
      {
        no: 3,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListSchoolsRequest
 */
export const ListSchoolsRequest = new ListSchoolsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolsResponse$Type extends MessageType<ListSchoolsResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListSchoolsResponse', [
      {
        no: 1,
        name: 'schools',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => School,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListSchoolsResponse
 */
export const ListSchoolsResponse = new ListSchoolsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School$Type extends MessageType<School> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'establishment_number',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'urn',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'phase_of_education',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'la_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'timezone', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 8, name: 'mis', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'address', kind: 'message', T: () => School_Address },
      { no: 10, name: 'extended', kind: 'message', T: () => School_Extended },
      { no: 11, name: 'region', kind: 'message', T: () => School_Region },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School
 */
export const School = new School$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_Address$Type extends MessageType<School_Address> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School.Address', [
      {
        no: 1,
        name: 'address_line_1',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'address_line_2',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'address_town',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'address_postcode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'address_country',
        kind: 'message',
        T: () => School_Address_AddressCountry,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School.Address
 */
export const School_Address = new School_Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_Address_AddressCountry$Type extends MessageType<School_Address_AddressCountry> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School.Address.AddressCountry', [
      { no: 1, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School.Address.AddressCountry
 */
export const School_Address_AddressCountry =
  new School_Address_AddressCountry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_Extended$Type extends MessageType<School_Extended> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School.Extended', [
      {
        no: 1,
        name: 'allows_writeback',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'has_timetables',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'has_lesson_attendance',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School.Extended
 */
export const School_Extended = new School_Extended$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_Region$Type extends MessageType<School_Region> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School.Region', [
      { no: 1, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'domain', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'school_url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'identifiers',
        kind: 'message',
        T: () => School_Region_Identifiers,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School.Region
 */
export const School_Region = new School_Region$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_Region_Identifiers$Type extends MessageType<School_Region_Identifiers> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.School.Region.Identifiers', [
      { no: 1, name: 'la_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'establishment_number',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'urn',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.School.Region.Identifiers
 */
export const School_Region_Identifiers = new School_Region_Identifiers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolRequest$Type extends MessageType<GetSchoolRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolRequest
 */
export const GetSchoolRequest = new GetSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolResponse$Type extends MessageType<GetSchoolResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetSchoolResponse', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetSchoolResponse
 */
export const GetSchoolResponse = new GetSchoolResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentDemographicsRequest$Type extends MessageType<ListStudentDemographicsRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentDemographicsRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_wonde_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentDemographicsRequest
 */
export const ListStudentDemographicsRequest =
  new ListStudentDemographicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentDemographicsResponse$Type extends MessageType<ListStudentDemographicsResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.ListStudentDemographicsResponse', [
      {
        no: 1,
        name: 'student_demographics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentDemographics,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.ListStudentDemographicsResponse
 */
export const ListStudentDemographicsResponse =
  new ListStudentDemographicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentDemographics$Type extends MessageType<StudentDemographics> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.StudentDemographics', [
      {
        no: 1,
        name: 'student_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'demographic_data', kind: 'message', T: () => Any },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.StudentDemographics
 */
export const StudentDemographics = new StudentDemographics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassRequest$Type extends MessageType<GetClassRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetClassRequest', [
      {
        no: 2,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 1,
        name: 'class_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetClassRequest
 */
export const GetClassRequest = new GetClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassResponse$Type extends MessageType<GetClassResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetClassResponse', [
      { no: 1, name: 'class', kind: 'message', T: () => Class },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetClassResponse
 */
export const GetClassResponse = new GetClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassByNameRequest$Type extends MessageType<GetClassByNameRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetClassByNameRequest', [
      {
        no: 1,
        name: 'school_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'wonde_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'class_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'authority',
        kind: 'enum',
        T: () => [
          'sparx.wondewitch.v1.wondewitch.WondeAuthority',
          WondeAuthority,
          'WONDE_AUTHORITY_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetClassByNameRequest
 */
export const GetClassByNameRequest = new GetClassByNameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassByNameResponse$Type extends MessageType<GetClassByNameResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.GetClassByNameResponse', [
      {
        no: 1,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Class,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.GetClassByNameResponse
 */
export const GetClassByNameResponse = new GetClassByNameResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LookupWondeIdRequest$Type extends MessageType<LookupWondeIdRequest> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.LookupWondeIdRequest', [
      { no: 1, name: 'urn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'postcode', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.LookupWondeIdRequest
 */
export const LookupWondeIdRequest = new LookupWondeIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LookupWondeIdResponse$Type extends MessageType<LookupWondeIdResponse> {
  constructor() {
    super('sparx.wondewitch.v1.wondewitch.LookupWondeIdResponse', [
      {
        no: 1,
        name: 'schools',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => School,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondewitch.v1.wondewitch.LookupWondeIdResponse
 */
export const LookupWondeIdResponse = new LookupWondeIdResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.wondewitch.v1.wondewitch.WondeWitch
 */
export const WondeWitch = new ServiceType(
  'sparx.wondewitch.v1.wondewitch.WondeWitch',
  [
    {
      name: 'GetSchoolMeta',
      options: {},
      I: GetSchoolMetaRequest,
      O: GetSchoolMetaResponse,
    },
    {
      name: 'GetSchoolPermissions',
      options: {},
      I: GetSchoolPermissionsRequest,
      O: GetSchoolPermissionsResponse,
    },
    {
      name: 'ListSubjects',
      options: {},
      I: ListSubjectsRequest,
      O: ListSubjectsResponse,
    },
    {
      name: 'RequestAccessToSchool',
      options: {},
      I: RequestAccessToSchoolRequest,
      O: RequestAccessToSchoolResponse,
    },
    {
      name: 'ListClasses',
      options: {},
      I: ListClassesRequest,
      O: ListClassesResponse,
    },
    {
      name: 'ListStudentsInWondeClasses',
      options: {},
      I: ListStudentsInWondeClassesRequest,
      O: ListStudentsInWondeClassesResponse,
    },
    {
      name: 'ListStudents',
      options: {},
      I: ListStudentsRequest,
      O: ListStudentsResponse,
    },
    {
      name: 'ListStudentDemographics',
      options: {},
      I: ListStudentDemographicsRequest,
      O: ListStudentDemographicsResponse,
    },
    {
      name: 'ListSchools',
      options: {},
      I: ListSchoolsRequest,
      O: ListSchoolsResponse,
    },
    {
      name: 'GetSchool',
      options: {},
      I: GetSchoolRequest,
      O: GetSchoolResponse,
    },
    { name: 'GetClass', options: {}, I: GetClassRequest, O: GetClassResponse },
    {
      name: 'GetClassByName',
      options: {},
      I: GetClassByNameRequest,
      O: GetClassByNameResponse,
    },
    {
      name: 'LookupWondeId',
      options: {},
      I: LookupWondeIdRequest,
      O: LookupWondeIdResponse,
    },
  ]
);
