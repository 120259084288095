import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { SkillFlag } from '@sparx/api/apis/sparx/content/v2/skill';

export const CurriculumAQAGCSE = 'f6210966-59c6-11ed-a793-e3fe3c591fc5';
export const CurriculumEdexcelGCSE = 'ae347040-56cc-11ed-b9b3-833a700b6b27';
export const CurriculumKS3 = '7bad86c6-9afa-11ed-a0de-7b9493235937';

// Returns whether a skill is higher-only or separate-only
// This is a copy of the go implementation in science/server/pkg/launcher/fetcher.go
// Any changes should also be made there.
export const isSkillHigherOrSeparateOnly = (
  curriculumID: string,
  flags: SkillFlag[],
  tags: string[],
) => {
  let [isHigherOnly, isSeparateOnly] = [false, false];
  let [higherTag, separateTag] = ['XXXX_NO_TAG_FOR_CURRICULUM', 'XXXX_NO_TAG_FOR_CURRICULUM'];

  switch (curriculumID) {
    case CurriculumEdexcelGCSE:
      higherTag = 'edexcel-higher-only';
      separateTag = 'edexcel-separate-only';
      break;
    case CurriculumAQAGCSE:
      higherTag = 'aqa-higher-only';
      separateTag = 'aqa-separate-only';
      break;
  }

  for (const flag of flags) {
    switch (flag) {
      case SkillFlag.SKILLFLAG_HIGHER:
        isHigherOnly = true;
        break;
      case SkillFlag.SKILLFLAG_SEPARATE_SCIENCE:
        isSeparateOnly = true;
        break;
    }
  }

  for (const tag of tags) {
    switch (tag) {
      case higherTag:
      case 'higher':
      case 'higher-only':
        isHigherOnly = true;
        break;
      case separateTag:
      case 'separate_science':
      case 'separate-only':
        isSeparateOnly = true;
    }
  }

  return { isHigherOnly, isSeparateOnly };
};

export const preprocessTopicDescription = (description: string | undefined) =>
  description && description !== 'New Topic' ? description?.replace(/\\n/g, '\n') : '';

export const isTopicHigherOnly = (topic: Topic) => topic.tags.includes('gcse-higher-only');
export const isTopicSeparateOnly = (topic: Topic) => topic.tags.includes('gcse-separate-only');
