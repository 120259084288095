import { Box, Button, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { useMediaDeviceSelect } from '@livekit/components-react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { authApiURL } from '@sparx/science/api';
import {
  ControlledInputSelect,
  MultiTrackSelect,
  MutedButton,
  ParticipantConnectionQuality,
} from 'components/stream/StreamComponents';
import React, { useEffect } from 'react';
import { KioskContainer } from 'views/kioskview/KioskContainer';
import { useWatchKioskSettings } from 'views/kioskview/kioskSettingsWatch';
import { useKioskStatePusher } from 'views/kioskview/kioskStatePusher';
import { useMovedMouse } from 'views/kioskview/useMovedMouse';
import { KioskClassView } from 'views/streamerview/StreamerView';
import { SelfWebcamTrack } from 'views/streamerview/subviews/ClassView';

export const KioskActive = ({ subject }: { subject: string }) => {
  const logout = () =>
    fetch(authApiURL + '/oauth2/logout', {
      credentials: 'include',
    }).then(() => {
      location.reload();
    });

  const { data } = useWatchKioskSettings(err => {
    if (err instanceof RpcError && err.code === 'NOT_FOUND') {
      logout();
    }
  });

  const { devices: outputDevices } = useMediaDeviceSelect({ kind: 'audiooutput' });
  const { devices: inputDevices } = useMediaDeviceSelect({ kind: 'audioinput' });
  const { devices: videoDevices } = useMediaDeviceSelect({ kind: 'videoinput' });

  useKioskStatePusher(
    {
      audiooutput: { devices: outputDevices.map(d => ({ label: d.label, deviceId: d.deviceId })) },
      audioinput: { devices: inputDevices.map(d => ({ label: d.label, deviceId: d.deviceId })) },
      videoinput: { devices: videoDevices.map(d => ({ label: d.label, deviceId: d.deviceId })) },
    },
    logout,
  );

  const { movedRecently, onMouseMove } = useMovedMouse(5000);

  // Ensure we reload every hour at max (with randomness) but not in lessons
  const dontReload = Boolean(data?.settings?.lessonId);
  useEffect(() => {
    if (!dontReload) {
      const timeout = setTimeout(() => {
        location.reload();
      }, 1000 * 60 * (60 * (Math.random() / 4 + 0.75)));
      return () => clearTimeout(timeout);
    }
  }, [dontReload]);

  if (data?.settings?.lessonId) {
    const schoolID = subject.split('/')[1];
    return (
      <KioskClassView
        lessonID={data.settings.lessonId}
        schoolID={schoolID}
        key={data.settings.lessonId}
      >
        <Box position="absolute" top={2} right={2} fontSize="xs" zIndex={10} color="whiteAlpha.500">
          {subject}
        </Box>
        <Box
          bg="gray.800"
          transition="all 0.2s ease-in-out"
          position="relative"
          height={data.settings.showPreview ? 16 : 0}
        >
          <HStack spacing={3} p={3}>
            <ParticipantConnectionQuality identity="teacher" />
            <Spacer />
            <ControlledInputSelect
              kind="audiooutput"
              value={data.settings.selectedDevices['audiooutput']}
            />
            <ControlledInputSelect
              kind="audioinput"
              value={data.settings.selectedDevices['audioinput']}
            />
            <MultiTrackSelect
              name="webcam"
              fixedVideo={data.settings.selectedDevices['videoinput']}
            />
            <MutedButton />
          </HStack>
          <SelfWebcamTrack visible={data.settings.showPreview} />
        </Box>
      </KioskClassView>
    );
  }

  return (
    <KioskContainer
      onMouseMove={onMouseMove}
      footerContent={
        <VStack alignItems="flex-end" spacing={5}>
          <Button
            size="sm"
            colorScheme="blackAlpha"
            onClick={() => logout()}
            opacity={movedRecently ? 1 : 0}
          >
            Logout
          </Button>
          <Text>
            {data?.settings?.kioskTitle && (
              <>
                <Text as="strong">{data.settings.kioskTitle}</Text> -{' '}
              </>
            )}
            {subject?.split('/')[2]?.slice(0, 8)}
          </Text>
        </VStack>
      }
    ></KioskContainer>
  );
};
