import { faCheck, faMinus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { TextDiffComparison, TextDiffDetail } from '../components/TextFieldDiffElements';
import styles from '../question/SparxQuestion.module.css';
import {
  GapEvaluation,
  LayoutElementProps,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { ITextFieldElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { pasteHandler } from '../utils/paste';
import { useEnterInputBehaviour } from '../utils/use-enter-input-behaviour';
import { MarkdownNode } from './TextElement';

export const TextFieldElement = ({ element }: LayoutElementProps<ITextFieldElement>) => {
  const context = useSparxQuestionContext();
  const value = context.input.text_fields?.[element.ref]?.value;
  const useTextArea = element.text_area;
  const showDiff = Boolean(element.diff);

  // If diff is present use max of 500 or diff length * 2.
  const maxLength = Math.max(500, (element.diff || '').length * 2);

  const {
    show: showCorrect,
    correct,
    correction,
    evaluation,
    hasDetail,
  } = isGapCorrect(element.ref, context.gapEvaluations, context.questionMarkingMode);

  const inputRef = React.useRef<HTMLElement | null>(null);
  useEnterInputBehaviour(inputRef.current, { nextInputAction: 'open' });

  const inputProps = {
    className: classNames(
      styles.TextField,
      styles.TextFieldText,
      !value && !context.readOnly && styles.TextFieldInvalid,
      context.insightsMode === 'presentation' && styles.TextFieldInsightsPresentation,
      showDiff && styles.TextFieldDiff,
    ),
    placeholder: context.insightsMode === 'print' ? '' : 'Enter answer...',
    value: value || '',
    readOnly: context.readOnly,
    ref: (el: HTMLElement | null) => {
      inputRef.current = el;
    },
    ['data-ref']: element.ref,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      context.sendAction({
        action: 'set_text',
        ref: element.ref,
        value: e.target.value,
      }),
    ...pasteHandler(element, useTextArea ? 'textarea' : 'text', context),
  };

  return (
    <div className={useTextArea ? styles.TextAreaContainer : undefined}>
      <div className={classNames(styles.TextFieldWrapper, useTextArea && styles.TextAreaWrapper)}>
        {showDiff && <TextDiffComparison element={element} value={value} inputRef={inputRef} />}
        <div className={styles.TextFieldComponent}>
          {useTextArea ? (
            <textarea
              {...inputProps}
              // Prevent spell checking in diff mode
              spellCheck={!showDiff}
              autoCapitalize={showDiff ? 'off' : undefined}
              autoCorrect={showDiff ? 'off' : undefined}
              data-gramm={showDiff ? 'false' : undefined} // Prevent grammarly in diff mode
              // End diff settings
              maxLength={maxLength}
              rows={context.insightsMode === 'presentation' ? 1 : undefined}
              onKeyDown={e => {
                // Don't submit unless ctrl is pressed
                if (e.key === 'Enter') {
                  if (!e.ctrlKey && !e.metaKey) {
                    e.stopPropagation();
                  } else {
                    e.currentTarget?.blur();
                  }
                }
              }}
            />
          ) : (
            <input {...inputProps} />
          )}
          {(!useTextArea || showDiff) && showCorrect && <CorrectIcon correct={correct} />}
        </div>
        {showCorrect && correction && (
          <div className={styles.TextFieldWrapperCorrection}>
            <FontAwesomeIcon icon={faCheck} />
            {correction}
          </div>
        )}
      </div>
      {!showDiff && showCorrect && hasDetail && <MarkingDetail evaluation={evaluation} />}
      {showDiff && showCorrect && hasDetail && (
        <TextDiffDetail
          evaluation={evaluation}
          originalValue={element.diff || ''}
          value={value || ''}
        />
      )}
    </div>
  );
};

const MarkingDetail = ({ evaluation }: { evaluation: GapEvaluation }) => {
  if (evaluation?.additionalData?.['aimarked/feedbackVersion'] === '2') {
    return <MarkingDetailV2 evaluation={evaluation} />;
  }

  const marksAwarded = evaluation?.detail?.reduce((acc, cur) => acc + cur.awardedMarks, 0) || 0;
  const marksTotal = evaluation?.detail?.reduce((acc, cur) => acc + cur.availableMarks, 0) || 0;

  const errorMarking = evaluation?.detail?.some(d => d.errorMarking);

  return (
    <>
      <div className={styles.MarkingResults}>
        {errorMarking ? (
          <div
            className={classNames(styles.MarkingResultDetail, styles.MarkingResultErrorContainer)}
          >
            <CorrectIcon markingError={true} inline={true} />
            <div className={styles.MarkingResultError}>
              Sorry - we weren't able to mark your answer this time. <br /> Don't worry, we've
              marked it complete so you can continue.
            </div>
          </div>
        ) : (
          <>
            <div className={styles.MarkingResultSummary}>
              {marksAwarded} / {marksTotal} mark{marksAwarded !== 1 && 's'}
            </div>
            {evaluation?.detail?.map((d, i) => (
              <div key={i} className={styles.MarkingResultDetail}>
                <CorrectIcon correct={d.awardedMarks >= d.availableMarks} inline={true} />
                <div className={styles.MarkingResultFeedback}>{d.feedback}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

const MarkingDetailV2 = ({ evaluation }: { evaluation: GapEvaluation }) => {
  if (evaluation?.detail?.some(d => d.errorMarking)) {
    return (
      <div className={styles.MarkingResultsV2}>
        <div className={classNames(styles.MarkingResultDetail, styles.MarkingResultErrorContainer)}>
          <CorrectIcon markingError={true} inline={true} />
          <div className={styles.MarkingResultError}>
            Sorry - we weren't able to mark your answer this time. <br /> Don't worry, we've marked
            it complete so you can continue.
          </div>
        </div>
      </div>
    );
  }

  const marksAwarded = evaluation?.detail?.reduce((acc, cur) => acc + cur.awardedMarks, 0) || 0;
  const marksTotal = evaluation?.detail?.reduce((acc, cur) => acc + cur.availableMarks, 0) || 0;

  const correctMarks = evaluation?.detail?.filter(d => d.awardedMarks >= d.availableMarks);
  const incorrectMarks = evaluation?.detail?.filter(d => d.awardedMarks < d.availableMarks);

  const modelAnswer = evaluation?.additionalData?.['aimarked/modelAnswer'];

  const correct = marksAwarded >= marksTotal;

  return (
    <div className={styles.MarkingResultsV2}>
      <div
        className={classNames(styles.MarkingResultSummary, {
          [styles.Correct]: correct,
        })}
      >
        {correct && <FontAwesomeIcon icon={faCheck} fixedWidth={true} className={styles.Icon} />}{' '}
        {marksAwarded} out of {marksTotal} mark{marksTotal !== 1 && 's'}
      </div>
      {correctMarks && correctMarks.length > 0 && (
        <div className={styles.MarkingResultDetail}>
          <h3>Marks given for:</h3>
          {correctMarks.map((m, i) => (
            <div key={i} className={styles.MarkingResultFeedback}>
              <div className={styles.Icon}>
                <CorrectIcon correct={true} inline={true} />
              </div>
              <MarkdownNode>{m.feedback}</MarkdownNode>
            </div>
          ))}
        </div>
      )}
      {incorrectMarks && incorrectMarks.length > 0 && (
        <div className={styles.MarkingResultDetail}>
          <h3>How to improve:</h3>
          {incorrectMarks.map((m, i) => (
            <div key={i} className={styles.MarkingResultFeedback}>
              <div className={styles.Icon}>
                <div className={styles.IncorrectIcon}>
                  <FontAwesomeIcon icon={faMinus} fixedWidth={true} />
                </div>
              </div>
              <MarkdownNode>{m.feedback}</MarkdownNode>
            </div>
          ))}
        </div>
      )}
      {modelAnswer && (
        <div className={styles.MarkingResultDetail}>
          <h3>Model answer:</h3>
          <div className={styles.MarkingResultFeedback}>
            <div className={styles.Icon}>
              <div className={styles.ModelAnswerIcon}>
                <FontAwesomeIcon icon={faStar} fixedWidth={true} />
              </div>
            </div>
            <MarkdownNode>{modelAnswer}</MarkdownNode>
          </div>
        </div>
      )}
    </div>
  );
};
