import { Box, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { faChevronRight, faLock, faSpinner, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LessonActivity,
  LessonActivityLockState,
  LessonState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useMutation } from '@tanstack/react-query';
import { queryClient, useQueryState } from 'api/client';
import { useCurrentLesson, useWatchLesson } from 'api/lessons';
import { getPackageQuery, useLessonPackage, usePackage } from 'api/packages';
import { CompletionBadge, HandinStatus, StatusBadge } from 'components/CompletionBadge';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';
import { useLessonDeliveryContext } from 'views/lessondelivery/LessonDeliveryView';

export const LessonPackageList = () => {
  const { data: currentLesson } = useCurrentLesson();
  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(
    currentLesson?.lessonName,
    currentLesson?.sessionId,
  );

  return (
    <PageContainer>
      <PageTitle title="Lesson" />
      {isLoadingLesson || !lessonState ? <LargeLoading /> : <LessonContent state={lessonState} />}
    </PageContainer>
  );
};

interface LessonContentProps {
  state: LessonState;
}

const LessonContent = ({ state }: LessonContentProps) => {
  const activities = state.activities.filter(a => !a.ephemeral);
  return (
    <>
      {activities.length === 0 && <Text>Waiting for teacher to set work...</Text>}
      <VStack spacing={4} align="stretch">
        <AnimatePresence initial={false}>
          {activities.map(activity => (
            <LessonActivityRow
              key={activity.lessonActivityId}
              lessonName={state.lessonName}
              activity={activity}
            />
          ))}
        </AnimatePresence>
      </VStack>
    </>
  );
};

interface LessonActivityRowProps {
  lessonName: string;
  activity: LessonActivity;
}

const LessonActivityRow = ({ lessonName, activity }: LessonActivityRowProps) => {
  const isLocked = activity.lockState === LessonActivityLockState.LOCK_STATE_LOCKED;

  const { pkg, mutateAsync: getLessonPackage } = useLessonPackage(
    lessonName,
    activity.lessonActivityId,
    {
      suspense: true,
    },
  );
  const packageId = pkg?.name.split('/')[1];

  const taskListRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useQueryState(`lessonactivity-open-${activity.lessonActivityId}`, false);
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      const pkg = await getLessonPackage();
      if (pkg) {
        const packageId = pkg.name.split('/')[1];
        const fullData = await queryClient.fetchQuery(getPackageQuery(packageId));

        const tasks = fullData.package?.contents?.tasks || [];
        if (tasks.length === 1) {
          // Go straight to that task
          const taskParts = tasks[0].name.split('/');
          ldc.navigate({
            page: 'task',
            lessonActivityID: activity.lessonActivityId,
            taskID: taskParts[3],
            packageID: taskParts[1],
          });
        } else {
          setOpen(true);
          if (taskListRef) {
            setTimeout(() => {
              taskListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 400);
          }
        }
      }
    },
  });

  const ldc = useLessonDeliveryContext();
  const clickRow = () => {
    if (isLocked || isLoading) return;
    if (open) setOpen(false);
    else mutateAsync();
  };

  return (
    <Box boxShadow="md" borderRadius="md" overflow="hidden">
      <Box
        as={motion.div}
        initial={{ transform: 'translateY(-20px)', opacity: 0 }}
        animate={{ transform: 'translateY(0)', opacity: 1 }}
        p={[3, 4]}
        bg={isLocked ? 'gray.100' : 'white'}
        color={isLocked ? 'gray.400' : 'gray.900'}
        pointerEvents={isLoading || isLocked ? 'none' : undefined}
        onClick={clickRow}
        _hover={{
          cursor: 'pointer',
          bg: 'gray.50',
        }}
      >
        <HStack spacing={4}>
          <Flex
            w={10}
            h={10}
            bg={isLocked ? 'red.100' : 'blue.50'}
            color={isLocked ? 'red.300' : 'blue.200'}
            alignItems="center"
            justifyContent="center"
            borderRadius="lg"
            fontSize="lg"
          >
            {isLoading ? (
              <FontAwesomeIcon fixedWidth={true} spin={true} icon={faSpinner} />
            ) : (
              <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
            )}
          </Flex>
          <Box pl={1}>
            <Text fontSize={['md', 'lg']} fontWeight="bold">
              {activity.displayName}
            </Text>
          </Box>
          <Spacer />
          <CompletionBadge
            completion={pkg?.state?.completion}
            due={pkg?.endTimestamp}
            hideIf={[HandinStatus.NotStarted]}
          />
          <StatusBadge completion={pkg?.state?.completion} due={pkg?.endTimestamp} />
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faChevronRight}
            rotation={open ? 90 : undefined}
            style={{ transition: 'transform 0.1s ease-in-out' }}
          />
        </HStack>
      </Box>
      <AnimatePresence initial={!open}>
        {open && activity.lockState !== LessonActivityLockState.LOCK_STATE_LOCKED && packageId && (
          <Box
            as={motion.div}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            ref={taskListRef}
          >
            <TaskList lessonActivityID={activity.lessonActivityId} packageID={packageId} />
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

const TaskList = ({
  lessonActivityID,
  packageID,
}: {
  lessonActivityID: string;
  packageID: string;
}) => {
  const ldc = useLessonDeliveryContext();
  const { data } = usePackage(packageID, { suspense: true });

  return (
    <VStack spacing={0} align="stretch">
      {data?.package?.contents?.tasks.map((task, i) => {
        const taskParts = task.name.split('/');
        return (
          <Box
            borderTop="1px solid rgba(0, 0, 0, 0.05)"
            key={task.name}
            p={4}
            bg="white"
            onClick={() =>
              ldc.navigate({
                page: 'task',
                lessonActivityID,
                taskID: taskParts[3],
                packageID: taskParts[1],
              })
            }
            _hover={{
              cursor: 'pointer',
              bg: 'gray.50',
            }}
          >
            <HStack spacing={4}>
              <Text fontSize="lg" pl={2}>
                {i + 1}. {task.title}
              </Text>
              <Spacer />
              <CompletionBadge completion={task.state?.completion} />
              <FontAwesomeIcon fixedWidth={true} icon={faChevronRight} />
            </HStack>
          </Box>
        );
      })}
    </VStack>
  );
};
