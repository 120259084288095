import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

interface SimpleAlertProps {
  children: (onOpen: () => void) => React.ReactNode;
  header: string;
  body?: string | React.ReactNode;
  confirmText?: string;
  onConfirm: () => Promise<any>;
  skip?: boolean;
}

export const SimpleAlert = ({
  children,
  header,
  body,
  confirmText,
  onConfirm,
  skip,
}: SimpleAlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: onConfirm,
  });
  const confirm = () => mutateAsync().then(onClose);

  const onClick = () => {
    if (skip) {
      onConfirm();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="xl">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
            <AlertDialogBody>{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button colorScheme="buttonTeal" onClick={confirm} ml={3} isLoading={isLoading}>
                {confirmText || 'Confirm'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {children(onClick)}
    </>
  );
};
