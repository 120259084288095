import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { SkillActivity_State } from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useActivity, useLessonPackage, usePackage } from 'api/packages';
import { LargeLoading } from 'components/loading/LargeLoading';
import React, { useEffect } from 'react';
import { useLessonDeliveryContext } from 'views/lessondelivery/LessonDeliveryView';
import { ActivityDeliveryWithUnload } from 'views/task/ActivityDelivery';

export const PopoverActivity = ({ lessonActivityId }: { lessonActivityId: string }) => {
  // TODO: at the moment we only support the first task/item in the activity
  const taskIndex = 0;
  const taskItemIndex = 1;

  const { lessonName } = useLessonDeliveryContext();
  const { pkg, mutateAsync, isLoading } = useLessonPackage(lessonName, lessonActivityId, {
    suspense: false,
  });

  // Ensure that the package is loaded
  useEffect(() => {
    !pkg && !isLoading && mutateAsync();
  }, [pkg, isLoading, mutateAsync]);

  const { data: pkgData } = usePackage(pkg?.name.split('/')[1] || '');
  const task = pkgData?.package?.contents?.tasks?.[taskIndex];
  const taskItem = task?.contents?.taskItems?.[taskItemIndex - 1];
  if (!taskItem) {
    return null;
  }

  return <PopoverActivityModal taskItem={taskItem} />;
};

const PopoverActivityModal = ({ taskItem }: { taskItem: TaskItem }) => {
  const onClose = () => undefined;

  const { data: activity } = useActivity(taskItem.name);
  const isOpen =
    Boolean(activity) && activity?.state?.skillActivity?.state !== SkillActivity_State.RESULTS;

  return (
    <Modal isOpen={isOpen} isCentered={true} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          {activity && taskItem ? (
            <ActivityDeliveryWithUnload
              activity={activity}
              taskItem={taskItem}
              canSkip={false}
              onFinish={() => undefined} // No-op
              onContinue={() => undefined} // No-op
              hidePeriodicTable={true}
            />
          ) : (
            <LargeLoading />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
