import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { faCirclePause } from '@fortawesome/free-regular-svg-icons';
import { faEraser, faRotateRight, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAssetUrlFromBucket, uploadWhiteboardImage } from '@sparx/science/app/storage';
import { useWindowDimensions } from '@sparx/science/utils/hooks/window';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';

export const Whiteboard = ({
  sessionId,
  backgroundAsset,
  paused,
  setLastWhiteboardImage,
}: {
  sessionId: string;
  backgroundAsset?: string;
  paused?: boolean;

  setLastWhiteboardImage: (value: string) => void;
}) => {
  const { width, height } = useWindowDimensions();
  const ref = useRef<CanvasDraw>(null);

  const { data: backgroundImage } = useQuery({
    queryKey: ['whiteboards', 'background', backgroundAsset],
    queryFn: async () => {
      if (backgroundAsset?.startsWith('schools/')) {
        return await getAssetUrlFromBucket(backgroundAsset);
      }
      return backgroundAsset ? `https://assets.sparxhomework.uk/${backgroundAsset}` : undefined;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  // Canvas capturing is in useQuery as to:
  // a) prevent sending more often than refetchInterval
  // b) wait until the sending has finished before sending a new one
  const [isStale, setIsStale] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  useQuery({
    queryKey: ['whiteboard-push'],
    queryFn: async () => {
      setIsStale(false);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const canvas = ref.current?.canvas?.drawing as HTMLCanvasElement | undefined;
      const grid = backgroundImage
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (ref.current?.canvas?.grid as HTMLCanvasElement | undefined)
        : undefined;
      if (canvas) {
        const data = cropCanvasToDataURL(canvas, grid);
        if (data) {
          const img = await uploadWhiteboardImage(sessionId, data);
          if (img) {
            setLastWhiteboardImage(img);
          }
        }
      }
      return 1;
    },
    enabled: isStale && !loadingImage,
    refetchInterval: 500,
  });

  const [rotateDevice, setRotateDevice] = useState(false);
  useEffect(() => {
    if (ref.current && backgroundImage) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grid = ref.current.canvas?.grid as HTMLCanvasElement | undefined;
      if (!grid) return;
      const ctx = grid.getContext('2d');
      if (!ctx) return;

      // Draw the image to the canvas
      const image = new Image();
      // Prevent canvas from being tainted by cross-origin request
      image.crossOrigin = 'anonymous';
      // Draw when image has loaded
      image.onload = () => {
        drawImageScaled(image, ctx);
        setLoadingImage(false);
      };
      image.src = backgroundImage;
      setLoadingImage(true);
    }

    const shouldRotate = Boolean(backgroundImage) && width * 1.2 < height;
    setRotateDevice(shouldRotate);
  }, [backgroundImage, ref, width, height, setRotateDevice, setLoadingImage]);

  return (
    <Box position="fixed" inset="0 0 0 0" zIndex={100}>
      <CanvasDraw
        ref={ref}
        style={{
          position: 'absolute',
          inset: '0 0 0 0',
          zIndex: 10,
        }}
        canvasWidth={width}
        canvasHeight={height}
        brushRadius={5}
        lazyRadius={0}
        hideGrid={Boolean(backgroundImage)}
        disabled={paused}
        onChange={() => setIsStale(true)}
      />
      <HStack
        spacing={5}
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={4}
        zIndex={11}
        justifyContent="center"
      >
        {paused ? (
          <>
            <Text fontSize="40px" color="blue.600">
              <FontAwesomeIcon icon={faCirclePause} />
            </Text>
            <Text fontSize="lg" color="gray.600" p={4} bg="whiteAlpha.800" borderRadius={10}>
              Your teacher has paused the lesson
            </Text>
          </>
        ) : (
          <>
            <IconButton
              colorScheme="buttonTeal"
              aria-label="Undo"
              size="lg"
              onClick={() => ref.current?.undo()}
              icon={<FontAwesomeIcon icon={faUndo} />}
            />
            <IconButton
              colorScheme="red"
              aria-label="Clear"
              size="lg"
              onClick={() => ref.current?.clear()}
              icon={<FontAwesomeIcon icon={faEraser} />}
            />
          </>
        )}
        {rotateDevice && (
          <Flex bg="red.50" alignItems="center" py={2} px={3} borderRadius="lg">
            <Text mr={2} fontSize="lg" color="red.600">
              <FontAwesomeIcon icon={faRotateRight} />
            </Text>
            <Text>Please rotate your device</Text>
          </Flex>
        )}
      </HStack>
    </Box>
  );
};

// https://stackoverflow.com/a/23105310
function drawImageScaled(img: HTMLImageElement, ctx: CanvasRenderingContext2D) {
  const canvas = ctx.canvas;
  const hRatio = canvas.width / img.width;
  const vRatio = canvas.height / img.height;
  const ratio = Math.min(hRatio, vRatio);
  const centerShift_x = (canvas.width - img.width * ratio) / 2;
  const centerShift_y = (canvas.height - img.height * ratio) / 2;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    centerShift_x,
    centerShift_y,
    img.width * ratio,
    img.height * ratio,
  );
}

const filteredUndefined = (...vals: (number | undefined)[]) =>
  vals.filter(v => v !== undefined).map(v => v || 0);

function cropCanvasToDataURL(dwCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined) {
  const drawScanner = getScanners(dwCanvas);
  const backgroundScanner = getScanners(bgCanvas);

  const cropL = Math.min(
    ...filteredUndefined(backgroundScanner.scanX(true), drawScanner.scanX(true)),
  );
  const cropR = Math.max(
    ...filteredUndefined(backgroundScanner.scanX(false), drawScanner.scanX(false)),
  );
  const cropT = Math.min(
    ...filteredUndefined(backgroundScanner.scanY(true), drawScanner.scanY(true)),
  );
  const cropB = Math.max(
    ...filteredUndefined(backgroundScanner.scanY(false), drawScanner.scanY(false)),
  );

  const cropWidth = cropR - cropL;
  const cropHeight = cropB - cropT;

  if (cropWidth <= 0 || cropHeight <= 0) return undefined;

  const canvas2 = document.createElement('canvas');
  canvas2.setAttribute('width', `${cropWidth}`);
  canvas2.setAttribute('height', `${cropHeight}`);
  const context2 = canvas2.getContext('2d');
  if (!context2) {
    throw new Error('Could not get canvas context');
  }
  if (bgCanvas) {
    context2.drawImage(bgCanvas, cropL, cropT, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
  }
  context2.drawImage(dwCanvas, cropL, cropT, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
  return canvas2.toDataURL();
}

const getScanners = (canvas: HTMLCanvasElement | undefined) => {
  // Return dummy functions if canvas is not available
  if (!canvas) return { scanX: () => undefined, scanY: () => undefined };

  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Could not get canvas context');
  }

  const imageData = context.getImageData(0, 0, imgWidth, imgHeight);
  const data = imageData.data;
  const getRBG = (x: number, y: number) => {
    const offset = imgWidth * y + x;
    return {
      r: data[offset * 4],
      g: data[offset * 4 + 1],
      b: data[offset * 4 + 2],
      opacity: data[offset * 4 + 3],
    };
  };

  const isTransparentOrWhite = (rgb: { r: number; g: number; b: number; opacity: number }) =>
    rgb.opacity < 10 || (rgb.r > 253 && rgb.g > 253 && rgb.b > 253);

  const scanX = function (fromLeft: boolean) {
    const offset = fromLeft ? 1 : -1;

    // loop through each column
    for (let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? x < imgWidth : x > -1; x += offset) {
      // loop through each row
      for (let y = 0; y < imgHeight; y++) {
        const rgb = getRBG(x, y);
        if (!isTransparentOrWhite(rgb)) {
          if (fromLeft) {
            return x;
          } else {
            return Math.min(x + 1, imgWidth);
          }
        }
      }
    }
    return 0; // all image is white
  };

  const scanY = function (fromTop: boolean) {
    const offset = fromTop ? 1 : -1;

    // loop through each column
    for (let y = fromTop ? 0 : imgHeight - 1; fromTop ? y < imgHeight : y > -1; y += offset) {
      // loop through each row
      for (let x = 0; x < imgWidth; x++) {
        const rgb = getRBG(x, y);
        if (!isTransparentOrWhite(rgb)) {
          if (fromTop) {
            return y;
          } else {
            return Math.min(y + 1, imgHeight);
          }
        }
      }
    }
    return 0; // all image is white
  };

  return { scanX, scanY };
};

export default Whiteboard;
