import { Box, Button, Grid, HStack, Spacer, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faDesktop,
  faExternalLink,
  faMicrophoneAlt,
  faMicrophoneSlash,
  faPhone,
  faStopwatch,
  faUsersRectangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTrackToggle } from '@livekit/components-react';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { LessonTimer } from 'components/stream/LessonTimer';
import { RoomAudioRendererWithMutedIdentities } from 'components/stream/RoomAudioRendererWithMutedIdentities';
import {
  DefaultInputSelect,
  DisconnectButton,
  LocalScreenShareTrack,
  LocalTrackByName,
  MultiTrackSelect,
  MutedButton,
  ParticipantConnectionQuality,
  RemoteScreenShareTrack,
  TrackByName,
  useIsSelfMuted,
} from 'components/stream/StreamComponents';
import { LayoutControl, streamColours } from 'components/stream/StreamLayouts';
import { StudentAudioControl } from 'components/stream/StudentAudioControl';
import { WhiteboardTrackCapture } from 'components/stream/WhiteboardTrackCapture';
import { Track } from 'livekit-client';
import React, { useState } from 'react';
import { openOneToOneView, openWhiteboardsView } from 'views/streamerview/utils';
import { useUpdateConferenceState } from 'views/streamerview/utils';

export const TeacherView = ({ lessonState }: { lessonState: LessonState }) => {
  const muted = useIsSelfMuted();

  const { mutate: update, isLoading: isUpdating } = useUpdateConferenceState(lessonState);
  const setView = (view: string) => update({ view });

  // Force mute the parent context if we have a student selected
  const whiteboardState = lessonState.conferenceState?.whiteboardState;
  const showWhiteboard = Boolean(whiteboardState?.whiteboardActivityId);

  const selectedStudentId = lessonState.conferenceState?.studentCall?.studentName?.split('/')[1];
  const [showTimer, setShowTimer] = useState(false);

  const isSnooping =
    (lessonState.conferenceState?.studentAudioState?.studentNames?.length || 0) > 0;
  const [showSnoop, setShowSnoop] = useState(isSnooping);
  const [muteClass, setMuteClass] = useState(false);

  return (
    <Box position="absolute" inset="0 0 0 0" bg="gray.900" display="flex" flexDirection="column">
      <RoomAudioRendererWithMutedIdentities
        // Mute all student mic audio tracks here, we manage them separately
        filter={identity => !identity.startsWith('studentmic-')}
        identityMute={{
          // Mute the class view if in one-to-one or the class is manually muted
          class: Boolean(selectedStudentId) || muteClass,
        }}
      />
      <Grid gridTemplateColumns="1fr 480px" flex={1} overflowY="auto">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          overflowY="auto"
          position="relative"
          __css={{
            '> video': {
              width: '100%',
              maxHeight: '100%',
            },
            boxShadow: muteClass ? '0 0 0 2px red inset' : '',
          }}
        >
          {muted && (
            <Box
              color="red"
              opacity="0.1"
              position="absolute"
              inset="0 0 0 0"
              fontSize="25vw"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon icon={faMicrophoneSlash} />
            </Box>
          )}
          <TrackByName name="webcam" identity="class" />
          <Box top={2} right={2} position="absolute">
            <Button
              onClick={() => setMuteClass(!muteClass)}
              size="xs"
              colorScheme={muteClass ? 'red' : 'white'}
            >
              {muteClass ? 'Unmute' : 'Mute'} class
            </Button>
          </Box>
        </Box>
        <VStack spacing={2} alignItems="flex-start" p={3} height="100%" overflowY="auto">
          <Box display="flex" alignItems="center" width="100%" mt={-3}>
            <TopButton
              active={showTimer}
              onClick={() => setShowTimer(!showTimer)}
              title="Timer"
              icon={faStopwatch}
            />
            <TopButton
              active={Boolean(selectedStudentId)}
              external={true}
              title={'Open 1:1'}
              onClick={() => openOneToOneView(lessonState)}
              icon={faPhone}
            />
            <TopButton
              onClick={() => openWhiteboardsView(lessonState)}
              external={true}
              title="Whiteboards"
              active={showWhiteboard}
              icon={faUsersRectangle}
            />
            <TopButton
              onClick={() => setShowSnoop(!showSnoop)}
              title="Student Microphones"
              active={showSnoop || isSnooping}
              icon={faMicrophoneAlt}
            />
            <Box flex={1} />
            <MutedButton />
          </Box>
          {showTimer && (
            <Box width="100%" bg="whiteAlpha.200" borderRadius="md" p={3}>
              <Text mb={2} color="white" fontSize="small" fontWeight="bold">
                Timer:
              </Text>
              <LessonTimer lessonState={lessonState} />
            </Box>
          )}
          {showSnoop && <StudentAudioControl lessonState={lessonState} />}
          <RemoteScreenShareTrack identity="class" />
          <LayoutControl
            setView={setView}
            current={lessonState.conferenceState?.view}
            isUpdating={isUpdating}
          />
          <VideoStreamControl name="webcam" initialMinimised={true} />
          <VideoStreamControl name="visualiser">
            <WhiteboardTrackCapture name="visualiser" lessonState={lessonState} />
          </VideoStreamControl>
          <ScreenShareControl name="screen" initialMinimised={true} />
        </VStack>
      </Grid>
      <Box bg="white" position="relative">
        <HStack spacing={3} p={3} bg="gray.800">
          <DisconnectButton />
          <Spacer />
          <ParticipantConnectionQuality identity="class" />
          <DefaultInputSelect kind="audiooutput" />
          <DefaultInputSelect kind="audioinput" />
          <MutedButton />
        </HStack>
      </Box>
    </Box>
  );
};

const useMinimiseButton = (initialMinimised?: boolean) => {
  const [minimizedPreviews, setMinimizedPreviews] = useState(initialMinimised || false);
  const button = (
    <Box
      ml={2}
      bg="whiteAlpha.100"
      display="inline-block"
      w={5}
      h={5}
      textAlign="center"
      borderRadius="sm"
      cursor="pointer"
      onClick={() => setMinimizedPreviews(!minimizedPreviews)}
      float="right"
    >
      {minimizedPreviews ? '+' : '-'}
    </Box>
  );
  return [minimizedPreviews, button];
};

interface VideoStreamControlProps {
  name: string;
  initialMinimised?: boolean;
}

const VideoStreamControl = ({
  name,
  initialMinimised,
  children,
}: PropsWithChildren<VideoStreamControlProps>) => (
  <MinimisedStreamControlWrapper
    name={name}
    initialMinimised={initialMinimised}
    control={<MultiTrackSelect name={name} />}
  >
    <LocalTrackByName name={name} />
    {children}
  </MinimisedStreamControlWrapper>
);

const MinimisedStreamControlWrapper = ({
  name,
  initialMinimised,
  children,
  control,
}: VideoStreamControlProps & {
  children: React.ReactNode;
  control: React.ReactNode;
}) => {
  const [minimised, minimisedButton] = useMinimiseButton(initialMinimised);
  return (
    <>
      <Text color="white" fontSize="small" fontWeight="bold" width="100%">
        <Box as="span" bg={streamColours[name]} w={2} h={2} display="inline-block" mr={3} />
        <Text as="span" textTransform="capitalize">
          {name}
        </Text>
        :{minimisedButton}
      </Text>
      <Stack direction={minimised ? 'row' : 'column'} alignItems="center" width="100%">
        <Box maxWidth={minimised ? '100px' : '100%'} position="relative">
          {children}
        </Box>
        <Stack
          direction={minimised ? 'column' : 'row'}
          spacing={2}
          flex={1}
          alignItems="flex-start"
        >
          {control}
        </Stack>
      </Stack>
    </>
  );
};

const ScreenShareControl = ({ name, initialMinimised }: VideoStreamControlProps) => {
  const { buttonProps, enabled } = useTrackToggle({
    source: Track.Source.ScreenShare,
  });

  const control = (
    <Button
      size="sm"
      {...buttonProps}
      colorScheme={enabled ? 'green' : undefined}
      leftIcon={<FontAwesomeIcon icon={faDesktop} />}
    >
      {enabled ? 'Stop' : 'Start'} sharing
    </Button>
  );

  return (
    <MinimisedStreamControlWrapper
      name={name}
      initialMinimised={initialMinimised}
      control={control}
    >
      <LocalScreenShareTrack />
    </MinimisedStreamControlWrapper>
  );
};

const TopButton = ({
  title,
  icon,
  active,
  external,
  onClick,
}: {
  title: string;
  icon: IconDefinition;
  active?: boolean;
  external?: boolean;
  onClick?: () => void;
}) => (
  <Tooltip label={title} hasArrow={true} placement="bottom" px={3} py={2}>
    <Box
      onClick={onClick}
      bgGradient="linear(to-b, transparent, whiteAlpha.300)"
      color="white"
      boxShadow={active ? '0 0 0 50px rgba(150, 0, 0, 0.25) inset' : ''}
      border="1px solid rgba(255, 255, 255, 0.3)"
      borderTop="none"
      __css={{
        ':not(:first-of-type)': {
          borderLeft: 'none',
        },
      }}
      minWidth={16}
      height={14}
      fontSize={20}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      _hover={{
        bgGradient: 'linear(to-b, whiteAlpha.100, whiteAlpha.400)',
      }}
    >
      {external && (
        <Box position="absolute" right={0.5} top={0.5} fontSize="xs" opacity={0.5}>
          <FontAwesomeIcon icon={faExternalLink} />
        </Box>
      )}
      <FontAwesomeIcon icon={icon} />
    </Box>
  </Tooltip>
);
