import * as React from 'react';

export type StateHook<T> = [T, React.Dispatch<React.SetStateAction<T>>];

/**
 * Browser storage returns a `string` or `null` as the value of a given key.
 * A result of `null` indicates the key isn't set; setting to `null` unsets the key.
 */
export type StorageItem = string | null;

/**
 * A generic hook for the persistence of string user preferences in tp2 using local or session storage
 * - see {@link useSessionStorage} and {@link useLocalStorage}
 */
const makeUseStorage =
  (storage: Storage) =>
  (prefKey: string, defaultValue?: string): StateHook<StorageItem> => {
    const [pref, setPref] = React.useState<StorageItem>(
      storage.getItem(prefKey) || defaultValue || null,
    );

    React.useEffect(() => {
      if (pref) {
        storage.setItem(prefKey, pref);
      } else {
        storage.removeItem(prefKey);
      }
    }, [pref, prefKey]);

    return [pref, setPref];
  };

/**
 * Hook for the persistence of string user preferences in tp2 using local storage
 * @param prefKey The key to use for storing the preference
 * @returns The value of the preference and a setter for updating it.
 */
export const useLocalStorage = makeUseStorage(localStorage);

/**
 * Hook for the persistence of string user preferences in tp2 using session storage
 * @param prefKey The key to use for storing the preference
 * @returns The value of the preference and a setter for updating it.
 */
export const useSessionStorage = makeUseStorage(sessionStorage);
