import { Box, Center, Text, useToken } from '@chakra-ui/react';
import { useSparxTheme } from 'components/theme/SparxTheme';
import { getTime } from 'date-fns';
import React, { PropsWithChildren, useState } from 'react';

import styles from './LargeLoading.module.css';

export interface LargeLoadingProps {
  color?: string;
  size?: number;
}

export const LargeLoading = (props: LargeLoadingProps) => {
  const SparxTheme = useSparxTheme();
  if (SparxTheme?.loadingComponent) {
    return <SparxTheme.loadingComponent {...props} />;
  }
  return <ScienceLoadingSpinner {...props} />;
};

const ScienceLoadingSpinner = ({ color = 'cyan.100', size = 200 }: LargeLoadingProps) => {
  const backgroundColour = useToken('colors', color);
  const atomColours = useToken('colors', [color, color, color]);
  const [timeOffset] = useState((getTime(new Date()) % 1000000) / 1000);

  const path = <use stroke={backgroundColour} strokeWidth="4" xlinkHref="#ring" />;
  const circle = (i: number, start: number) => (
    <circle cx="0" cy="0" r="6" fill={atomColours[i]}>
      <animateMotion dur="1.5s" repeatCount="indefinite" begin={`${start - timeOffset}s`}>
        <mpath xlinkHref="#ring"></mpath>
      </animateMotion>
    </circle>
  );

  return (
    <Box display="flex" justifyContent="center">
      <svg width={size} height={size} viewBox="0 0 100 100" className={styles.LoadingContainer}>
        <defs>
          <path id="ring" d="M50 15A15 35 0 0 1 50 85A15 35 0 0 1 50 15" fill="none" />
        </defs>
        <g className={styles.Path1}>{path}</g>
        <g className={styles.Path2} transform="rotate(60 50 50)">
          {path}
        </g>
        <g className={styles.Path3} transform="rotate(120 50 50)">
          {path}
        </g>
        <g>{circle(0, 0)}</g>
        <g transform="rotate(60 50 50)">{circle(1, -0.25)}</g>
        <g transform="rotate(120 50 50)">{circle(2, -0.5)}</g>
      </svg>
    </Box>
  );
};

export const LargeLoadingWithText = ({ children }: PropsWithChildren) => (
  <Center flexDirection="column" py={10}>
    <LargeLoading />
    <Text fontSize="lg" color="gray.600">
      {children}
    </Text>
  </Center>
);
