import React from '@chakra-ui/react';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  useLocalParticipant,
  useTracks,
} from '@livekit/components-react';
import { LiveKitToken, Role } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import { ObserverParticipant } from 'components/stream/ObserverParticipant';
import { Prejoin } from 'components/stream/Prejoin';
import { RoomAudioRendererWithMutedIdentities } from 'components/stream/RoomAudioRendererWithMutedIdentities';
import { Track } from 'livekit-client';
import { PropsWithChildren, useEffect, useState } from 'react';

export const ObserverView = ({ token }: { token: LiveKitToken }) => {
  const [joined, setJoined] = useState(false);

  return (
    <LiveKitRoom
      video={false}
      audio={false}
      token={token.token}
      serverUrl={token.serverUrl}
      connect={joined}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      {!joined ? (
        <Prejoin role={Role.OBSERVER} onJoin={() => setJoined(true)} />
      ) : (
        <WithLivekitStyles>
          <MyVideoConference />
          <ControlBar
            controls={{
              microphone: false,
              camera: false,
              screenShare: false,
            }}
          />
        </WithLivekitStyles>
      )}
    </LiveKitRoom>
  );
};

export const WithLivekitStyles = ({ children }: PropsWithChildren) => {
  // Only import on this page
  useEffect(() => {
    import('@livekit/components-styles');
  }, []);

  return <>{children}</>;
};

const MyVideoConference = () => {
  const [mutedIdentities, setMutedIdentities] = useState<Record<string, boolean>>({});
  const toggleIdentityMute = (identity: string) =>
    setMutedIdentities(prev => ({ ...prev, [identity]: !prev[identity] }));

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );

  // Filter out self tracks
  const self = useLocalParticipant();
  const filteredTracks = tracks.filter(
    t => t.participant.identity !== self.localParticipant.identity,
  );

  return (
    <>
      <RoomAudioRendererWithMutedIdentities identityMute={mutedIdentities} />
      <GridLayout
        tracks={filteredTracks}
        style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}
      >
        <ObserverParticipant
          mutedIdentities={mutedIdentities}
          onParticipantClick={e => toggleIdentityMute(e.participant.identity)}
        />
      </GridLayout>
    </>
  );
};
