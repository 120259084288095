import {
  ICardInput,
  IChoiceGroupInput,
  IChoiceInput,
  IInput,
  ISlotGroupInput,
  ISlotInput,
  ISteps,
} from './types';

export const checkQuestion = (question: ISteps) => question.every(step => checkInput(step.input));

export const checkInput = (step: IInput = {}) =>
  checkChoiceGroups(step) &&
  checkNumberFields(step) &&
  checkTextFields(step) &&
  checkExpressionFields(step) &&
  checkSlotGroups(step) &&
  checkUploadFields(step) &&
  checkMediaFields(step);

const checkChoiceGroups = (input: IInput) =>
  Object.values(input.choice_groups || {}).every(group =>
    checkChoiceGroup(group, input.choices || {}),
  );

export const hasInput = (input: IInput) =>
  Boolean(
    Object.entries(input || {}).find(
      ([key, value]) => key === 'styles' || Object.keys(value || {}).length > 0,
    ),
  );

const checkChoiceGroup = (group: IChoiceGroupInput, choices: Record<string, IChoiceInput>) => {
  const minChoices = parseInt(group.min_choices.toString());
  const maxChoices = parseInt(group.max_choices.toString());
  const selectedCount = group.choice_refs.filter(ref => choices[ref]?.selected).length;
  return selectedCount >= minChoices && selectedCount <= maxChoices;
};

export const isValidNumber = (value: string) => {
  return value && /^([-+])?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value);
};

const checkNumberFields = (input: IInput) =>
  Object.values(input.number_fields || {}).every(field => isValidNumber(field.value || ''));

const checkTextFields = (input: IInput) =>
  Object.values(input.text_fields || {}).every(field => field.value?.trim());

const isExpressionValid = (value: string) => value.trim().length > 0;

const checkExpressionFields = (input: IInput) =>
  Object.values(input.expression_fields || {}).every(field => isExpressionValid(field.value || ''));

const checkSlotGroups = (input: IInput) =>
  Object.values(input.slot_groups || {}).every(group => checkSlotGroup(group, input.slots || {}));

const checkSlotGroup = (group: ISlotGroupInput, slots: Record<string, ISlotInput>) =>
  group.slot_refs.every(ref => slots[ref].card_ref);

const checkUploadFields = (input: IInput) =>
  Object.values(input.upload_fields || {}).every(field => field?.file);

const checkMediaFields = (input: IInput) =>
  Object.values(input.media_fields || {}).every(field => field?.value);

//

type IAnswer = { text?: string; card_ref?: string; image?: string; file?: string };
type IAnswers = Record<string, IAnswer>;

export const buildStepAnswer = (input: IInput): IAnswers => {
  const answers: IAnswers = {};
  buildNumberFieldAnswers(input, answers);
  buildTextFieldAnswers(input, answers);
  buildExpressionFieldAnswers(input, answers);
  buildChoiceAnswers(input, answers);
  buildSlotAnswers(input, answers);
  buildMediaFieldAnswers(input, answers);
  return answers;
};

export const answersToAnswerComponents = (answers: IAnswers): Record<string, string> =>
  Object.entries(answers).reduce(
    (p, [k, v]) => ({
      ...p,
      [k]: v.card_ref || v.text || v.image || v.file || '',
    }),
    {},
  );

const buildNumberFieldAnswers = (input: IInput, answers: IAnswers) =>
  Object.entries(input.number_fields || {}).forEach(([key, value]) => {
    answers[key] = { text: value.value };
  });

const buildTextFieldAnswers = (input: IInput, answers: IAnswers) =>
  Object.entries(input.text_fields || {}).forEach(([key, value]) => {
    answers[key] = { text: value.value?.trim() };
  });

const buildExpressionFieldAnswers = (input: IInput, answers: IAnswers) =>
  Object.entries(input.expression_fields || {}).forEach(([key, value]) => {
    answers[key] = { text: value.value };
  });

const buildChoiceAnswers = (input: IInput, answers: IAnswers) => {
  Object.entries(input.choices || {}).forEach(([key, value]) => {
    if (!value.selected) {
      return;
    }
    answers[key] = fillAnswerContent(value);
  });
};

const buildSlotAnswers = (input: IInput, answers: IAnswers) => {
  const cards = input.cards || {};
  Object.entries(input.slots || {}).forEach(([key, value]) => {
    const cardRef = value.card_ref;
    if (cardRef) {
      answers[key] = fillAnswerContent(cards[cardRef], { card_ref: cardRef });
    }
  });
};

const buildMediaFieldAnswers = (input: IInput, answers: IAnswers) =>
  Object.entries(input.media_fields || {}).forEach(([key, value]) => {
    answers[key] = { text: value.value?.trim() };
  });

export const getAnswerFiles = (input: IInput) =>
  Object.entries(input.upload_fields || {}).filter(([_, v]) => Boolean(v.file));

function fillAnswerContent(value: ICardInput | IChoiceInput, answerData: IAnswer = {}) {
  if (!answerData) {
    answerData = {};
  }
  const firstContent = value.content[0];
  switch (firstContent?.element) {
    case 'text':
      answerData.text = firstContent.text;
      break;
    case 'image':
      answerData.image = firstContent.src;
      break;
  }
  return answerData;
}

export const rehydrateStepAnswer = (input: IInput, answers: Record<string, string>) =>
  Object.entries(answers).forEach(([key, answer]) => {
    if (input.number_fields?.[key]) {
      input.number_fields[key].value = answer;
    }
    if (input.text_fields?.[key]) {
      input.text_fields[key].value = answer;
    }
    if (input.expression_fields?.[key]) {
      input.expression_fields[key].value = answer;
    }
    if (input.choices?.[key]) {
      input.choices[key].selected = true;
    }
    if (input.slots?.[key]) {
      input.slots[key].card_ref = answer;
      if (!input.cards) {
        input.cards = {};
      }
      if (!input.cards[answer]) {
        input.cards[answer] = { content: [] };
      }
      input.cards[answer].slot_ref = key;
    }
    if (input.upload_fields?.[key]) {
      input.upload_fields[key].uploadedAssetName = answer;
    }
    if (input.media_fields?.[key]) {
      input.media_fields[key].value = answer;
    }
  });

type InputEntryCounts = Record<Exclude<keyof IInput, 'styles'>, number>;

export const inputEntryCounts = (input: IInput): InputEntryCounts => ({
  cards: Object.entries(input.cards || {}).length,
  choices: Object.entries(input.choices || {}).length,
  slots: Object.entries(input.slots || {}).length,
  number_fields: Object.entries(input.number_fields || {}).length,
  text_fields: Object.entries(input.text_fields || {}).length,
  expression_fields: Object.entries(input.expression_fields || {}).length,
  card_groups: Object.entries(input.card_groups || {}).length,
  slot_groups: Object.entries(input.slot_groups || {}).length,
  choice_groups: Object.entries(input.choice_groups || {}).length,
  upload_fields: Object.entries(input.upload_fields || {}).length,
  media_fields: Object.entries(input.media_fields || {}).length,
});

export const countNonMediaEntries = (counts: InputEntryCounts) =>
  Object.entries(counts).reduce((p, [k, v]) => (k === 'media_fields' ? p : p + v), 0);
