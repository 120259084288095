import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { ICardElement } from '../question/types';
import { CardContent } from './CardContent';

interface InlineCardElementProps extends LayoutElementProps<ICardElement> {
  onSelect: () => void;
  containerSlotRef?: string;
}

export const InlineCardElement = ({
  element,
  onSelect,
  containerSlotRef,
}: InlineCardElementProps) => {
  const context = useSparxQuestionContext();

  const slotRef = context.input.cards?.[element.ref]?.slot_ref;
  const selectedOther = Boolean(slotRef) && containerSlotRef !== slotRef;
  const selectedThis = Boolean(slotRef) && containerSlotRef === slotRef;

  return (
    <CardContent
      readOnly={context.readOnly}
      locked={selectedOther}
      selected={selectedThis}
      element={element}
      onClick={selectedOther ? () => ({}) : onSelect}
      onKeyUp={event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSelect();
        }
      }}
    />
  );
};
