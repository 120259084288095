import { Box, Button, ButtonGroup, Text } from '@chakra-ui/react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Activity,
  GetPackageAnswerHistoryResponse_ActivityWithAnswer,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useMutation } from '@tanstack/react-query';
import { activitiesClient } from 'api';
import { getSchoolID } from 'api/sessions';
import React from 'react';

export const showTeacherMarking = (
  act: GetPackageAnswerHistoryResponse_ActivityWithAnswer,
  index: number,
) =>
  index === 0 &&
  act.activity?.state?.skillActivity?.evaluation?.completed &&
  act.activity?.annotations?.['requires_marking'] === 'true';

interface TeacherActivityMarkProps {
  activity: Activity;
  studentId: string;
  onSave: () => Promise<any>;
}

export const TeacherActivityMark = ({ activity, studentId, onSave }: TeacherActivityMarkProps) => {
  const status =
    activity.state?.skillActivity?.evaluation?.status ||
    TaskItem_Status.TASK_ITEM_STATUS_UNSPECIFIED;

  const { mutate, isLoading, variables } = useMutation({
    mutationFn: async (status: TaskItem_Status) => {
      const resp = await activitiesClient.setActivityEvaluation({
        activityName: activity.name,
        schoolName: `schools/${await getSchoolID()}`,
        studentName: `students/${studentId}`,
        status,
      }).response;
      await onSave();
      return resp;
    },
  });

  return (
    <Box p={4} m={2} position="sticky" bottom={0} zIndex={5} bg="gray.100" borderRadius="md">
      <Text mb={3} fontWeight="bold" color="gray.600">
        Is this answer correct?
      </Text>
      <ButtonGroup isAttached variant="outline" display="flex" width="100%">
        <Button
          onClick={() => mutate(TaskItem_Status.CORRECT)}
          isLoading={variables === TaskItem_Status.CORRECT && isLoading}
          isDisabled={isLoading}
          leftIcon={<FontAwesomeIcon icon={faCheck} />}
          colorScheme="green"
          variant={status === TaskItem_Status.CORRECT ? 'solid' : 'outline'}
          flex={1}
        >
          Correct
        </Button>
        <Button
          onClick={() => mutate(TaskItem_Status.INCORRECT)}
          isLoading={variables === TaskItem_Status.INCORRECT && isLoading}
          isDisabled={isLoading}
          leftIcon={<FontAwesomeIcon icon={faTimes} />}
          colorScheme="red"
          variant={status === TaskItem_Status.INCORRECT ? 'solid' : 'outline'}
          flex={1}
        >
          Wrong
        </Button>
      </ButtonGroup>
    </Box>
  );
};
