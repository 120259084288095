import React, { Box, Button, Text, VStack } from '@chakra-ui/react';
import { LiveKitRoom } from '@livekit/components-react';
import { LiveKitToken, Role } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import { useWatchLesson } from '@sparx/science/api/lessons';
import { getSchoolID } from '@sparx/science/api/sessions';
import { LargeLoadingWithText } from '@sparx/science/components/loading/LargeLoading';
import { useQuery } from '@tanstack/react-query';
import { meetClient } from 'api/service';
import { Prejoin } from 'components/stream/Prejoin';
import { PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClassView } from 'views/streamerview/subviews/ClassView';
import { ObserverView } from 'views/streamerview/subviews/ObserverView';
import { OneToOneObserverView } from 'views/streamerview/subviews/OneToOneObserverView';
import { TeacherView } from 'views/streamerview/subviews/TeacherView';

const modeToRole: Record<string, Role> = {
  teacher: Role.TEACHER,
  class: Role.CLASS,
  observer: Role.OBSERVER,
  onetoone: Role.OBSERVER,
};

export const StreamerView = ({ mode }: { mode: 'teacher' | 'class' | 'observer' | 'onetoone' }) => {
  const { lessonID = '' } = useParams();
  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(lessonID);

  const [joined, setJoined] = useState(false);
  const [disconnected, setDisconnected] = useState(false);

  const role = modeToRole[mode];
  const { data: { livekitToken } = {}, isLoading } = useQuery({
    queryKey: ['roomToken', lessonID],
    queryFn: async () =>
      meetClient.getRoomToken({
        schoolName: `schools/${await getSchoolID()}`,
        collection: lessonID,
        role,
      }).response,
  });
  if (isLoading || isLoadingLesson || !lessonState) {
    return <LargeLoadingWithText>Joining lesson...</LargeLoadingWithText>;
  }
  if (!livekitToken) {
    return <div>Failed to get token</div>;
  }
  if (mode === 'onetoone') {
    return <OneToOneObserverView token={livekitToken} lessonState={lessonState} />;
  }
  if (role === Role.OBSERVER) {
    return <ObserverView token={livekitToken} />;
  }

  if (disconnected) {
    return (
      <Box
        bgGradient="linear(to-tr, teal.700, teal.800)"
        height="100vh"
        display="flex"
        flexDirection="column"
        boxShadow="elevationHigh"
        justifyContent="flex-start"
      >
        <VStack
          alignItems="center"
          p={10}
          gap={4}
          borderRadius="md"
          margin="auto"
          bg="rgba(255, 255, 255, 0.9)"
        >
          <Text>Disconnected from lesson</Text>
          <Button onClick={() => location.reload()} colorScheme="blue">
            Reconnect
          </Button>
        </VStack>
      </Box>
    );
  }

  return (
    <RoomView token={livekitToken} connect={joined} onDisconnect={() => setDisconnected(true)}>
      {!joined ? (
        <Prejoin role={role} onJoin={() => setJoined(true)} />
      ) : role === Role.TEACHER ? (
        <TeacherView lessonState={lessonState} />
      ) : role === Role.CLASS ? (
        <ClassView lessonState={lessonState} />
      ) : null}
    </RoomView>
  );
};

export const KioskClassView = ({
  lessonID,
  schoolID,
  children,
}: PropsWithChildren<{ lessonID: string; schoolID: string }>) => {
  const { data: lessonState, isLoading: isLoadingLesson } = useWatchLesson(lessonID, '', schoolID);

  const role = Role.CLASS;
  const { data: { livekitToken } = {}, isLoading } = useQuery({
    queryKey: ['roomToken', lessonID],
    queryFn: async () =>
      meetClient.getRoomToken({
        schoolName: `schools/${schoolID}`,
        collection: lessonID,
        role,
      }).response,
  });
  if (isLoading || isLoadingLesson || !lessonState) {
    return <LargeLoadingWithText>Joining lesson...</LargeLoadingWithText>;
  }
  if (!livekitToken) {
    return <div>Failed to get token</div>;
  }

  return (
    <RoomView token={livekitToken} connect={true}>
      <ClassView lessonState={lessonState} controls={children} />
    </RoomView>
  );
};

interface RoomViewProps extends PropsWithChildren {
  token: LiveKitToken;
  connect: boolean;
  onDisconnect?: () => void;
}

const RoomView = ({ token, connect, children, onDisconnect }: RoomViewProps) => (
  <LiveKitRoom
    video={false}
    audio={true}
    token={token.token}
    serverUrl={token.serverUrl}
    connect={connect}
    onDisconnected={onDisconnect}
  >
    {children}
  </LiveKitRoom>
);

export default StreamerView;
