import { SuspenseRoute } from '@sparx/science/components/suspenseroute/SuspenseRoute';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { KioskView } from 'views/kioskview/KioskView';
import { LandingView } from 'views/landingview/LandingView';
import { LessonDeliveryView } from 'views/lessondeliveryview/LessonDeliveryView';
import { StudentView } from 'views/studentview/StudentView';

import { Content } from './Content';

const TeacherView = React.lazy(() => import('views/teacherview/TeacherView'));

const sus = (element: React.ReactNode) => <SuspenseRoute>{element}</SuspenseRoute>;

// TODO: add createSentryBrowserRouter?
export const router = createBrowserRouter([
  {
    element: <Content />,
    children: [
      { path: '/', element: <LandingView /> },
      { path: '/join', element: sus(<StudentView />) },
      { path: '/lesson', element: <LessonDeliveryView /> },
      { path: '/teacher/*', element: sus(<TeacherView />) },
    ],
  },
  {
    path: '/standalone',
    element: <KioskView />,
  },
]);
