import { Spinner, useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { sessionsClient } from 'api';
import { useCurrentLesson, useIsInLesson, useWatchLesson } from 'api/lessons';
import { useSession } from 'api/sessions';
import React, { useState } from 'react';
import { useLocalStorage } from 'utils/hooks/localstorage';
import { useLongPress } from 'utils/hooks/longpress';
import { useInterval } from 'utils/interval';

export const useDebugInfo = () => {
  const toast = useToast();
  return useLongPress(() => {
    if (!toast.isActive('debug')) {
      toast({
        description: <DebugInfo />,
        status: 'info',
        position: 'bottom-left',
        isClosable: true,
        duration: null,
        id: 'debug',
      });
    }
  }, 4000);
};

const DebugInfo = () => {
  const inLesson = useIsInLesson();
  const { data: user } = useSession();

  return (
    <div style={{ userSelect: 'text' }}>
      <p>Debug information:</p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>R: </strong>
        {import.meta.env.VITE_RELEASE_VERSION || 'unknown'}
      </p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>U: </strong>
        {user?.userId}
      </p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>S: </strong>
        {user?.schoolId}
      </p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>N: </strong>
        <NetworkDebug />
      </p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>L: </strong>
        {inLesson ? <LessonDebug /> : 'N'}
      </p>
      <p style={{ fontFamily: 'monospace' }}>
        <strong>A: </strong>
        <StreamAutoAbortControl />
      </p>
    </div>
  );
};

const NetworkDebug = () => {
  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ['session', 'debug'],
    queryFn: async () => {
      const start = performance.now();
      const req = await sessionsClient.getCurrentSession({});
      const duration = performance.now() - start;
      return `${duration.toFixed(2)}ms / ${req.status.code}`;
    },
    retry: false,
    staleTime: Infinity,
  });

  if (isLoading) return <Spinner size="sm" />;
  if (error) return <>Error: {error}</>;
  return (
    <span onClick={() => refetch()}>
      {data} {isFetching && <Spinner size="sm" />}
    </span>
  );
};

const LessonDebug = () => {
  const { data: currentLesson } = useCurrentLesson();
  const { dataUpdatedAt } = useWatchLesson(currentLesson?.lessonName, currentLesson?.sessionId);

  const [lastUpdated, setLastUpdated] = useState(0);
  useInterval(() => {
    const delay = (new Date().getTime() - new Date(dataUpdatedAt).getTime()) / 1000;
    setLastUpdated(delay);
  }, 400);

  return (
    <span>
      {new Date(dataUpdatedAt).toISOString()} ({lastUpdated.toFixed(1)}s)
    </span>
  );
};

const StreamAutoAbortControl = () => {
  const [useAutoAbort, setUseAutoAbort] = useLocalStorage(`sci/lessons/reset`, 'true');
  const enabled = useAutoAbort === 'true';
  return (
    <span onClick={() => setUseAutoAbort(enabled ? 'false' : 'true')}>
      {useAutoAbort === 'true' ? 'Enabled' : 'Disabled'}
    </span>
  );
};
