import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';

import { useSlideyGroupContext } from '../question/SlideyContext';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { ICardElement } from '../question/types';
import { CardContent } from './CardContent';

const makeCardElementWrapperID = (cardRef: string) => `return/${cardRef}`;

export const CardElementWrapper = ({ element }: LayoutElementProps<ICardElement>) => {
  const context = useSparxQuestionContext();
  const { selectedCardRef, setSelectedCardRef } = useSlideyGroupContext();
  const { isOver, setNodeRef } = useDroppable({
    id: makeCardElementWrapperID(element.ref),
    disabled: context.readOnly,
  });

  const slotRef = context.input.cards?.[element.ref]?.slot_ref;

  // handle click to return a selected card to its wrapper
  const handleClick = () => {
    if (selectedCardRef !== undefined) {
      context.sendAction({
        action: 'drop_card',
        cardRef: selectedCardRef,
        slotRef: makeCardElementWrapperID(element.ref),
      });
      setSelectedCardRef(undefined);
    }
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.CardSlot, accessibilityStyles.FocusTarget, {
        [styles.CardSlotActive]: isOver,
        [styles.CardSlotClickable]: selectedCardRef !== undefined,
      })}
      onClick={handleClick}
    >
      <CardElement element={element} key={element.ref} fake={Boolean(slotRef)} />
    </div>
  );
};

interface CardElementProps extends LayoutElementProps<ICardElement> {
  children?: React.ReactNode;
  locked?: boolean;
  fake?: boolean;
}

export const CardElement: FC<CardElementProps> = ({ children, element, locked, fake }) => {
  const { readOnly, dragInProgress, input, sendAction } = useSparxQuestionContext();
  const { scale, selectedCardRef, setSelectedCardRef } = useSlideyGroupContext();
  const selected = selectedCardRef === element.ref;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: element.ref + (fake ? '-inslot' : ''),
    disabled: locked || readOnly,
  });

  // if the slidey has been scaled to fit on the screen, we need to reverse the scaling on the
  // transform for the card when dragged so that it follows the mouse correctly
  if (transform !== null) {
    transform.x = transform.x / scale;
    transform.y = transform.y / scale;
  }

  // track whether the card is being dragged
  const [isBeingDragged, setIsBeingDragged] = React.useState(false);
  React.useEffect(() => {
    if (
      !isBeingDragged &&
      transform !== null &&
      Math.abs(transform.x) + Math.abs(transform.y) > 1
    ) {
      setIsBeingDragged(true);
      setSelectedCardRef(undefined);
    }
  }, [transform, isBeingDragged, setSelectedCardRef]);
  React.useEffect(() => {
    if (!dragInProgress) {
      setIsBeingDragged(false);
    }
  }, [dragInProgress]);

  // handle click to select / swap if not being dragged
  const onMouseUp = () => {
    if (isBeingDragged) {
      // ignore if this card is being dragged
      return;
    }
    if (selectedCardRef === undefined) {
      // select this card if no other card is selected
      setSelectedCardRef(element.ref);
      return;
    }
    if (selectedCardRef === element.ref) {
      // clear selection if this card is selected
      setSelectedCardRef(undefined);
      return;
    }

    // if this card is in a slot then the selected card has been dropped on that slot
    for (const [slotRef, slot] of Object.entries(input.slots || {})) {
      if (slot.card_ref === element.ref) {
        sendAction({
          action: 'drop_card',
          cardRef: selectedCardRef,
          slotRef: slotRef,
        });
        setSelectedCardRef(undefined);
        return;
      }
    }

    // otherwise if the selected card is in a slot then return it
    for (const slot of Object.values(input.slots || {})) {
      if (slot.card_ref === selectedCardRef) {
        sendAction({
          action: 'drop_card',
          cardRef: selectedCardRef,
          slotRef: makeCardElementWrapperID(selectedCardRef),
        });
        setSelectedCardRef(undefined);
        return;
      }
    }

    // otherwise just select this card
    setSelectedCardRef(element.ref);
  };

  return (
    <CardContent
      element={element}
      innerRef={setNodeRef}
      style={{ transform: CSS.Translate.toString(transform) }}
      locked={locked}
      isHidden={fake}
      draggable={true}
      readOnly={readOnly}
      selected={selected}
      {...listeners}
      {...attributes}
      onMouseUp={onMouseUp}
    >
      {children}
    </CardContent>
  );
};
