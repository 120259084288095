import './index.css';
import './nodeRemoveFix';

import { ColorModeScript } from '@chakra-ui/react';
import { App } from 'app/App';
import { theme } from 'app/theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
// ResizeObserver (for old Safari)
import ResizeObserverPolyfill from 'resize-observer-polyfill';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

declare global {
  interface Window {
    TEACHING_SCHOOL_ID?: string;
    TEACHING_USER_ID?: string;
    SPARX_USER_ID?: string;

    settings?: {
      ENV_NAME?: 'dev' | 'test2' | 'test1' | 'prod';
      API_URL?: string;
      TEACHING_API_URL?: string;
      AUTH_API_URL?: string;
      SCHOOL_SELECT_URL?: string;
      TP_STUDENT_API_URL?: string;
      TP_GROUPS_API_URL?: string;
      TP_STAFF_API_URL?: string;
      CONTENT_API_URL?: string;
      SCHOOLS_API_URL?: string;
      CALENDAR_API_URL?: string;
      PLANNING_API_URL?: string;
      SCHOOL_STAFF_API_URL?: string;
      SCHOOL_STATUS_API_URL?: string;
      TRAINING_PROGRESS_API_URL?: string;
      CLIENT_ANALYTICS_URL?: string;
      FEEDBACK_API_URL?: string;
    };
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.Fragment>,
);
