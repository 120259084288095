import { JoinState, LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { LessonConferenceState } from '@sparx/api/apis/sparx/science/lessons/v1/teamteaching';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import {
  handsUpSort,
  useStudentLessonStates,
  useUpdateLessonState,
} from '@sparx/science/api/lessons';
import { useStudents } from '@sparx/science/api/school';
import { useMemo } from 'react';

export const useSortedStudentsAndStates = (lessonState: LessonState, sortByHandsUp?: boolean) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { data: studentStates = [] } = useStudentLessonStates(lessonID);

  const { data: students = [] } = useStudents({ suspense: false });
  const studentLookup = useMemo(() => {
    const lookup: Dictionary<string, Student> = {};
    for (const student of students) {
      lookup[student.studentId] = student;
    }
    return lookup;
  }, [students]);

  const studentList = useMemo(
    () =>
      studentStates
        .filter(s => s.joinState === JoinState.STUDENT_STATE_JOINED)
        .sort((a, b) => {
          if (sortByHandsUp) {
            // Sort by hands up initially
            const handsUp = handsUpSort(a, b);
            if (handsUp) return handsUp;
          }

          // Fallback to first name sort
          const studentA = studentLookup[a.studentId] || { givenName: '', familyName: '' };
          const studentB = studentLookup[b.studentId] || { givenName: '', familyName: '' };
          return (
            studentA.givenName.localeCompare(studentB.givenName) ||
            studentA.familyName.localeCompare(studentB.familyName) ||
            a.studentId.localeCompare(b.studentId) // fallback
          );
        }),
    [sortByHandsUp, studentStates, studentLookup],
  );

  return { studentList, studentLookup };
};

export const useUpdateConferenceState = (lessonState: LessonState) => {
  const lessonID = lessonState.lessonName.split('/')[3];
  const { mutateAsync, isLoading } = useUpdateLessonState(lessonID);
  return {
    isLoading,
    mutate: (patch: Partial<LessonConferenceState>) =>
      mutateAsync({
        conferenceState: {
          ...LessonConferenceState.create(lessonState.conferenceState || {}),
          ...patch,
        },
      }),
  };
};

export const openWhiteboardsView = (lessonState: LessonState) =>
  window.open(
    `/teacher/lessons/${lessonState.lessonName.split('/')[3]}/whiteboards`,
    'whiteboards',
    'popup=yes',
  );

export const openOneToOneView = (lessonState: LessonState, studentID?: string) =>
  window.open(
    `/teacher/lessons/${lessonState.lessonName?.split('/')[3]}/tt/v3/onetoone${
      studentID ? `?student=${studentID}` : ''
    }`,
    'onetone',
    'popup=yes',
  );
