import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Role } from '@sparx/api/apis/sparx/science/lessons/v1/meet';
import {
  StudentCallLayout,
  StudentCallTrack,
} from '@sparx/api/apis/sparx/science/lessons/v1/teamteaching';
import { getSchoolID } from '@sparx/science/api/sessions';
import {
  HandsUpIconWithNumber,
  LastProgressedTimer,
} from '@sparx/science/views/lessons/StudentActivity';
import { meetClient } from 'api/service';
import { TrackByName } from 'components/stream/StreamComponents';
import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSortedStudentsAndStates, useUpdateConferenceState } from 'views/streamerview/utils';

export const OneToOnePanel = ({ lessonState }: { lessonState: LessonState }) => {
  const { studentLookup, studentList } = useSortedStudentsAndStates(lessonState, true);
  const { mutate: update, isLoading: isUpdating } = useUpdateConferenceState(lessonState);

  const selectedStudentId = lessonState.conferenceState?.studentCall?.studentName?.split('/')[1];
  const selectedLayout = lessonState.conferenceState?.studentCall?.layout;
  const selectedTrack = lessonState.conferenceState?.studentCall?.visibleTrack;

  const setStudent = useCallback(
    async (studentId: string) => {
      const lessonID = lessonState.lessonName.split('/')[3];
      if (studentId) {
        const { livekitToken } = await meetClient.getRoomToken({
          schoolName: `schools/${await getSchoolID()}`,
          collection: lessonID,
          role: Role.STUDENT,
        }).response;

        update({
          studentCall: {
            studentName: 'students/' + studentId,
            livekitToken,
            layout: StudentCallLayout.SIDEBAR,
            visibleTrack: StudentCallTrack.WEBCAM,
          },
        });
      } else {
        update({ studentCall: undefined });
      }
    },
    [lessonState, update],
  );

  const setLayout = (layout: StudentCallLayout) => {
    if (lessonState.conferenceState?.studentCall?.studentName) {
      update({
        studentCall: {
          ...lessonState.conferenceState.studentCall,
          layout,
        },
      });
    }
  };

  const setTrack = (track: StudentCallTrack) => {
    if (lessonState.conferenceState?.studentCall?.studentName) {
      update({
        studentCall: {
          ...lessonState.conferenceState.studentCall,
          visibleTrack: track,
        },
      });
    }
  };

  const selectedStudent = studentList.find(s => s.studentId === selectedStudentId);

  // Ensure that the 1:1 is ended when the window is closed
  useEffect(() => {
    if (selectedStudentId) {
      const handler = () => setStudent('');
      window.addEventListener('beforeunload', handler);
      return () => window.removeEventListener('beforeunload', handler);
    }
  }, [selectedStudentId, setStudent]);

  // Behaviour to set an active 1:1 call from a URL parameter
  const [searchParams, setSearchParams] = useSearchParams();
  const requestStudentId = searchParams.get('student');
  useEffect(() => {
    if (requestStudentId) {
      setStudent(requestStudentId); // start 1:1 with this student
      setSearchParams(new URLSearchParams()); // clear
    }
  }, [setStudent, requestStudentId, setSearchParams]);

  return (
    <Flex
      flexDirection="column"
      bg="gray.800"
      height="100%"
      css={{ '& video': { borderRadius: 5 } }}
    >
      {selectedStudent && (
        <Flex
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          zIndex={10}
          py={2}
          px={4}
          bg="blackAlpha.800"
        >
          <Text fontWeight="bold" color="white" fontSize="md" py={0.5}>
            {studentLookup[selectedStudent.studentId]?.givenName}{' '}
            {studentLookup[selectedStudent.studentId]?.familyName}
          </Text>
          <Box ml={4} px={2}>
            <LastProgressedTimer
              studentState={selectedStudent}
              lessonState={lessonState}
              extra={active => !active && <InactiveStudentWarning />}
            />
          </Box>
        </Flex>
      )}
      <Flex flex={1}>
        <Box
          flex={1}
          css={{ '& video': { position: 'absolute', width: '100%', height: '100%' } }}
          position="relative"
        >
          {selectedStudent && <TrackByName name="webcam" identity="student" />}
        </Box>
      </Flex>
      <Flex p={3} bg="gray.600">
        <ButtonGroup flex={1}>
          <Menu>
            <MenuButton size="sm" as={Button} isLoading={isUpdating}>
              {selectedStudentId
                ? `${studentLookup[selectedStudentId]?.givenName} ${studentLookup[selectedStudentId]?.familyName}`
                : 'Select student'}
            </MenuButton>
            <MenuList maxHeight="80vh" overflowY="auto">
              {studentList.map(s => {
                const student = studentLookup[s.studentId];
                return (
                  <MenuItem key={s.studentId} onClick={() => setStudent(s.studentId)}>
                    {student?.givenName} {student?.familyName}
                    <Spacer />
                    {s.handUpTime && <HandsUpIconWithNumber index={s.handUpIndex} />}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
          {selectedStudent && (
            <Button size="sm" onClick={() => setStudent('')} colorScheme="orange">
              Stop current 1:1
            </Button>
          )}
        </ButtonGroup>

        <ButtonGroup isDisabled={!selectedStudentId} size="sm" flex={1} justifyContent="center">
          <Button
            colorScheme={selectedTrack !== StudentCallTrack.VISUALISER ? 'blue' : 'white'}
            onClick={() => setTrack(StudentCallTrack.WEBCAM)}
            isLoading={isUpdating}
          >
            Webcam
          </Button>
          <Button
            colorScheme={selectedTrack === StudentCallTrack.VISUALISER ? 'blue' : 'white'}
            onClick={() => setTrack(StudentCallTrack.VISUALISER)}
            isLoading={isUpdating}
          >
            Visualiser
          </Button>
        </ButtonGroup>

        <ButtonGroup
          flex={1}
          isDisabled={!selectedStudentId}
          size="sm"
          colorScheme="whiteAlpha"
          justifyContent="flex-end"
        >
          <Button
            variant={selectedLayout === StudentCallLayout.SIDEBAR ? 'solid' : 'ghost'}
            onClick={() => setLayout(StudentCallLayout.SIDEBAR)}
            isLoading={isUpdating}
          >
            Sidebar
          </Button>
          <Button
            variant={selectedLayout === StudentCallLayout.FULLSCREEN ? 'solid' : 'ghost'}
            onClick={() => setLayout(StudentCallLayout.FULLSCREEN)}
            isLoading={isUpdating}
          >
            Fullscreen
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

const InactiveStudentWarning = () => (
  <Box color="white" borderRadius="sm" bg="red.500" px={2} py={0.5} fontSize="sm">
    <FontAwesomeIcon icon={faWarning} />
    <Text ml={2} as="span">
      Student is not active
    </Text>
  </Box>
);
