import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import styles from '../question/SparxQuestion.module.css';
import {
  LayoutElementProps,
  usePredictableShuffleContent,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { ICardInput, ISlotElement } from '../question/types';
import { useClickAwayListener } from '../utils/clickaway';
import {
  useGetBaseOffset,
  useKeepElementWithinElement,
} from '../utils/useKeepElementWithinElement';
import { InlineCardElement } from './InlineCardElement';

interface InlineSlotOptionsProps extends LayoutElementProps<ISlotElement> {
  vertical?: boolean;
  setVerticalSlotOptions?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export const InlineSlotOptions = ({
  vertical = false,
  element,
  answerPartIndex,
  parent,
  setVerticalSlotOptions,
}: InlineSlotOptionsProps) => {
  const { setOpenElementRef, openElementRef, scale, questionElement, input, sendAction } =
    useSparxQuestionContext();

  const closeOptions = useCallback(() => {
    // only close the options if this element is the currently open element.
    // This avoids a race condition where, after selecting a new element, that
    // element's options are closed.
    setOpenElementRef(c => (c === element.ref ? '' : c));
  }, [element.ref, setOpenElementRef]);
  useClickAwayListener(
    closeOptions,
    openElementRef !== '',
    ev => {
      if (!(ev.target instanceof Element)) {
        return true;
      }
      if (ev.target.closest(`[data-slot="${element.ref}"]`)) {
        return false;
      }
      if (ev.target.closest(`[data-slot-options="${element.ref}"]`)) {
        return false;
      }
      return true;
    },
    'mousedown',
  );

  const cardGroup = useMemo(
    () => input.card_groups?.[element.accept || ''],
    [element.accept, input.card_groups],
  );
  const cards = useMemo(() => {
    const cardRefs = cardGroup?.card_refs || [];
    const cardsWithRef: { card: ICardInput; ref: string }[] = [];
    for (const ref of cardRefs) {
      const card = input?.cards?.[ref];
      if (card) cardsWithRef.push({ card, ref });
    }
    return cardsWithRef;
  }, [cardGroup?.card_refs, input?.cards]);
  // Ensure they are shuffled if they need to be
  const inlineCards = usePredictableShuffleContent(
    cards,
    Boolean(cardGroup?.shuffle),
    answerPartIndex?.toString(),
  );

  const slotCardRef = input.slots?.[element.ref]?.card_ref;

  const slotOptionsRef = useRef<HTMLElement | null>(null);

  const getSlotDomElement = useCallback(
    (ref: string) => document.querySelector(`[data-slot="${ref}"]`),
    [],
  );
  const textFieldRef = useRef<Element | null>(getSlotDomElement(element.ref));

  // re-find the dom element whenever the element ref changes
  useEffect(() => {
    textFieldRef.current = getSlotDomElement(element.ref);
  }, [element.ref, getSlotDomElement]);

  const { offset, recalculate } = useKeepElementWithinElement(
    slotOptionsRef.current,
    questionElement,
    useGetBaseOffset(textFieldRef.current, slotOptionsRef.current, parent),
    scale,
    vertical,
    parent,
  );

  // recalculate the offset whenever the selected input changes
  useEffect(() => {
    recalculate();
  }, [recalculate, element.ref]);

  return (
    <div
      className={classNames(styles.InlineSlotOptions, {
        [styles.InlineSlotOptionsVertical]: vertical,
      })}
      data-slot-options={element.ref}
      ref={el => {
        setVerticalSlotOptions?.(el);
        slotOptionsRef.current = el;
      }}
      style={{ left: offset }}
    >
      {inlineCards.map(card => (
        <InlineCardElement
          key={card.ref}
          element={{
            element: 'card',
            content: card.card.content,
            ref: card.ref,
          }}
          containerSlotRef={element.ref}
          onSelect={() => {
            setOpenElementRef('');
            sendAction({
              action: 'drop_card',
              cardRef: card.ref,
              slotRef: slotCardRef === card.ref ? '' : element.ref,
            });
          }}
        />
      ))}
    </div>
  );
};
