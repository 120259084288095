import {
  Box,
  Button,
  IconButton,
  Spinner,
  SystemStyleObject,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useState } from 'react';

export interface Layout {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  opacity?: number;
}

export type StreamType = 'webcam' | 'visualiser' | 'screen';

export interface CombinedLayout {
  name: string;
  streams: Partial<Record<StreamType, Layout>> & Record<'webcam', Layout>;
}

export const layouts: Record<string, CombinedLayout> & Record<'default', CombinedLayout> = {
  'teacher-corner': {
    name: 'Visualiser focus',
    streams: {
      visualiser: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
  'teacher-corner-screen': {
    name: 'Screen focus',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
  'teacher-focus': {
    name: 'Teacher focus',
    streams: {
      visualiser: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
      webcam: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 1,
      },
    },
  },
  'stream-only': {
    name: 'Visualiser only',
    streams: {
      visualiser: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
        opacity: 0,
      },
    },
  },
  'screen-only': {
    name: 'Screen only',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
        opacity: 0,
      },
    },
  },
  'teacher-only': {
    name: 'Webcam only',
    streams: {
      visualiser: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 1,
        opacity: 0,
      },
      webcam: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        zIndex: 2,
      },
    },
  },
  default: {
    name: 'Default',
    streams: {
      visualiser: {
        x: 0,
        y: 0,
        width: 75,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 0,
        width: 25,
        height: 100,
        zIndex: 2,
      },
    },
  },
  fiftyfifty: {
    name: '50/50',
    streams: {
      visualiser: {
        x: 0,
        y: 0,
        width: 50,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 50,
        y: 0,
        width: 50,
        height: 100,
        zIndex: 2,
      },
    },
  },

  share5050: {
    name: 'Share 50 / Vis 50',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 50,
        height: 100,
        zIndex: 1,
      },
      visualiser: {
        x: 50,
        y: 0,
        width: 50,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
  share7030: {
    name: 'Share 70 / Vis 30',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 70,
        height: 100,
        zIndex: 1,
      },
      visualiser: {
        x: 70,
        y: 0,
        width: 30,
        height: 100,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
  visV5050: {
    name: 'Vis 50 / Share 50 (Vertical)',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 100,
        height: 50,
        zIndex: 1,
      },
      visualiser: {
        x: 0,
        y: 50,
        width: 100,
        height: 50,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
  visV7030: {
    name: 'Vis 70 / Share 30 (Vertical)',
    streams: {
      screen: {
        x: 0,
        y: 0,
        width: 100,
        height: 70,
        zIndex: 1,
      },
      visualiser: {
        x: 0,
        y: 70,
        width: 100,
        height: 30,
        zIndex: 1,
      },
      webcam: {
        x: 75,
        y: 75,
        width: 25,
        height: 25,
        zIndex: 2,
      },
    },
  },
};

export const viewOptions = [
  'default',
  'teacher-only',
  'stream-only',
  'teacher-corner',
  'screen-only',
  'teacher-corner-screen',
  'teacher-focus',
  'fiftyfifty',
  'share5050',
  'share7030',
  'visV5050',
  'visV7030',
];

export const streamColours: Record<string, string> = {
  visualiser: 'teal.500',
  webcam: 'orange.500',
  screen: 'purple.200',
};

export const LayoutBox = ({
  children,
  layout,
  style,
}: PropsWithChildren<{ layout: Layout; style?: SystemStyleObject }>) => (
  <Box
    position="absolute"
    width={`${layout.width}%`}
    height={`${layout.height}%`}
    left={`${layout.x}%`}
    top={`${layout.y}%`}
    zIndex={layout.zIndex}
    opacity={layout.opacity ?? 1}
    transition="all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)"
    display="flex"
    alignItems="center"
    justifyContent="center"
    boxShadow="lg"
    padding="2px"
    __css={{
      '> video': {
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 10,
        border: '1px solid rgba(255, 255, 255, 0.25)',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        maxWidth: '100%',
        maxHeight: '100%',
        // aspectRatio: '16 / 9',
        ...style,
      },
    }}
  >
    {children}
  </Box>
);

export const LayoutControl = ({
  setView,
  isUpdating,
  current,
}: {
  setView: (val: string) => void;
  isUpdating?: boolean;
  current?: string;
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Text color="white" fontSize="small">
        <b>Current view:</b> {layouts[current || 'default']?.name}
        {isUpdating && <Spinner size="xs" ml={2} />}
      </Text>
      <Box alignItems="center" display="flex" flexWrap="wrap">
        {viewOptions.map((typ, i) => (
          <Tooltip
            label={layouts[typ]?.name || ''}
            key={typ}
            hasArrow={true}
            placement="top"
            fontSize="md"
            p={2}
            textAlign="center"
            lineHeight="1.3em"
          >
            <Button
              display={showMore || typ === current || i < 6 ? 'block' : 'none'}
              flex="0 0 auto"
              onClick={() => setView(typ)}
              colorScheme="gray"
              variant="link"
              borderWidth="2px"
              borderRadius="sm"
              borderColor={typ === current ? 'white' : 'transparent'}
              p={0}
              mr={2}
              size="sm"
            >
              <Box position="relative" w={10} h={7} bg="blue" overflow="hidden">
                {Object.entries(layouts[typ]?.streams).map(([strmType, layout]) => (
                  <Box
                    key={strmType}
                    position="absolute"
                    left={`${layout.x}%`}
                    top={`${layout.y}%`}
                    w={`${layout.width}%`}
                    h={`${layout.height}%`}
                    zIndex={layout.zIndex}
                    opacity={layout.opacity ?? 1}
                    bg={streamColours[strmType]}
                  />
                ))}
              </Box>
            </Button>
          </Tooltip>
        ))}
        <Tooltip
          label={showMore ? 'Hide more' : 'Show more'}
          hasArrow={true}
          placement="top"
          fontSize="md"
          p={2}
          textAlign="center"
          lineHeight="1.3em"
        >
          <IconButton
            aria-label={showMore ? 'Hide more' : 'Show more'}
            size="xs"
            onClick={() => setShowMore(!showMore)}
            colorScheme="blackAlpha"
          >
            <FontAwesomeIcon icon={showMore ? faChevronLeft : faChevronRight} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};
