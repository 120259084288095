// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/sessions/v1/session.proto" (package "sparx.science.sessions.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Sessions } from './session';
import type { UpdateUserFlagsResponse } from './session';
import type { UpdateUserFlagsRequest } from './session';
import type { GetUserFlagsResponse } from './session';
import type { GetUserFlagsRequest } from './session';
import type { LogSearchQueryResponse } from './session';
import type { LogSearchQueryRequest } from './session';
import type { GetFirebaseTokenResponse } from './session';
import type { GetFirebaseTokenRequest } from './session';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetCurrentSessionResponse } from './session';
import type { GetCurrentSessionRequest } from './session';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.sessions.v1.Sessions
 */
export interface ISessionsClient {
  /**
   * @generated from protobuf rpc: GetCurrentSession(sparx.science.sessions.v1.GetCurrentSessionRequest) returns (sparx.science.sessions.v1.GetCurrentSessionResponse);
   */
  getCurrentSession(
    input: GetCurrentSessionRequest,
    options?: RpcOptions
  ): UnaryCall<GetCurrentSessionRequest, GetCurrentSessionResponse>;
  /**
   * @generated from protobuf rpc: GetFirebaseToken(sparx.science.sessions.v1.GetFirebaseTokenRequest) returns (sparx.science.sessions.v1.GetFirebaseTokenResponse);
   */
  getFirebaseToken(
    input: GetFirebaseTokenRequest,
    options?: RpcOptions
  ): UnaryCall<GetFirebaseTokenRequest, GetFirebaseTokenResponse>;
  /**
   * @generated from protobuf rpc: LogSearchQuery(sparx.science.sessions.v1.LogSearchQueryRequest) returns (sparx.science.sessions.v1.LogSearchQueryResponse);
   */
  logSearchQuery(
    input: LogSearchQueryRequest,
    options?: RpcOptions
  ): UnaryCall<LogSearchQueryRequest, LogSearchQueryResponse>;
  /**
   * @generated from protobuf rpc: GetUserFlags(sparx.science.sessions.v1.GetUserFlagsRequest) returns (sparx.science.sessions.v1.GetUserFlagsResponse);
   */
  getUserFlags(
    input: GetUserFlagsRequest,
    options?: RpcOptions
  ): UnaryCall<GetUserFlagsRequest, GetUserFlagsResponse>;
  /**
   * @generated from protobuf rpc: UpdateUserFlags(sparx.science.sessions.v1.UpdateUserFlagsRequest) returns (sparx.science.sessions.v1.UpdateUserFlagsResponse);
   */
  updateUserFlags(
    input: UpdateUserFlagsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateUserFlagsRequest, UpdateUserFlagsResponse>;
}
/**
 * @generated from protobuf service sparx.science.sessions.v1.Sessions
 */
export class SessionsClient implements ISessionsClient, ServiceInfo {
  typeName = Sessions.typeName;
  methods = Sessions.methods;
  options = Sessions.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetCurrentSession(sparx.science.sessions.v1.GetCurrentSessionRequest) returns (sparx.science.sessions.v1.GetCurrentSessionResponse);
   */
  getCurrentSession(
    input: GetCurrentSessionRequest,
    options?: RpcOptions
  ): UnaryCall<GetCurrentSessionRequest, GetCurrentSessionResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetCurrentSessionRequest, GetCurrentSessionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetFirebaseToken(sparx.science.sessions.v1.GetFirebaseTokenRequest) returns (sparx.science.sessions.v1.GetFirebaseTokenResponse);
   */
  getFirebaseToken(
    input: GetFirebaseTokenRequest,
    options?: RpcOptions
  ): UnaryCall<GetFirebaseTokenRequest, GetFirebaseTokenResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetFirebaseTokenRequest, GetFirebaseTokenResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: LogSearchQuery(sparx.science.sessions.v1.LogSearchQueryRequest) returns (sparx.science.sessions.v1.LogSearchQueryResponse);
   */
  logSearchQuery(
    input: LogSearchQueryRequest,
    options?: RpcOptions
  ): UnaryCall<LogSearchQueryRequest, LogSearchQueryResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<LogSearchQueryRequest, LogSearchQueryResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: GetUserFlags(sparx.science.sessions.v1.GetUserFlagsRequest) returns (sparx.science.sessions.v1.GetUserFlagsResponse);
   */
  getUserFlags(
    input: GetUserFlagsRequest,
    options?: RpcOptions
  ): UnaryCall<GetUserFlagsRequest, GetUserFlagsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetUserFlagsRequest, GetUserFlagsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UpdateUserFlags(sparx.science.sessions.v1.UpdateUserFlagsRequest) returns (sparx.science.sessions.v1.UpdateUserFlagsResponse);
   */
  updateUserFlags(
    input: UpdateUserFlagsRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateUserFlagsRequest, UpdateUserFlagsResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateUserFlagsRequest, UpdateUserFlagsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
