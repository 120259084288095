import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  Select,
  SelectProps,
  Spacer,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurriculums } from 'api/content';
import { useClientEvent } from 'components/ClientEventProvider';
import debounce from 'lodash.debounce';
import React, { useMemo } from 'react';

import { TopicSearchContextProvider, useTopicSearchContext } from './Context';

export interface FilterFields {
  curriculum: string;
  search: string;
}

interface TopicSearchProps {
  filter: FilterFields;
  setFilter: (filter: Partial<FilterFields>) => void;
  hideLabel?: boolean;
  mustSelect?: boolean;
  hideSearch?: boolean;
  hideCurriculum?: boolean;
  subject?: string;
}

export const TopicSearch = (props: TopicSearchProps) => {
  const { filter, setFilter, mustSelect, hideSearch, hideCurriculum } = props;

  return (
    <TopicSearchContextProvider
      value={{
        filter,
        setFilter,
      }}
    >
      <Flex mb={3} mt={1} flexDirection={mustSelect ? 'row-reverse' : 'row'}>
        {(!mustSelect || filter.curriculum) && !hideSearch && <TopicSearchInput />}
        {!hideCurriculum && (
          <>
            <Spacer />
            <TopicSearchCurriculum {...props} />
          </>
        )}
      </Flex>
    </TopicSearchContextProvider>
  );
};

export const TopicSearchInput = ({
  placeholder,
  ...props
}: InputGroupProps & { placeholder?: string }) => {
  const { filter, setFilter } = useTopicSearchContext();

  // Debounced event so we don't send every key stroke
  const { sendEvent } = useClientEvent();
  const sendSearchEvent = useMemo(
    () =>
      debounce(
        (search: string) => sendEvent({ category: 'topic_search', action: 'search' }, { search }),
        300,
      ),
    [sendEvent],
  );
  const onChangeSearch = (search: string) => {
    setFilter({ search });
    sendSearchEvent(search);
  };

  return (
    <InputGroup width="350px" {...props}>
      <InputRightElement
        pointerEvents={!filter.search ? 'none' : undefined}
        onClick={filter.search ? () => onChangeSearch('') : undefined}
        _hover={filter.search ? { opacity: 0.75 } : undefined}
        cursor="pointer"
      >
        {!filter.search ? (
          <FontAwesomeIcon icon={faSearch} />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} />
        )}
      </InputRightElement>
      <Input
        type="text"
        placeholder={placeholder || 'Search topics...'}
        variant="filled"
        value={filter.search}
        onChange={e => onChangeSearch(e.target.value)}
      />
    </InputGroup>
  );
};

type TopicSearchCurriculumProps = {
  selectProps?: SelectProps;
  textProps?: TextProps;
} & Omit<TopicSearchProps, 'filter' | 'setFilter'>;

export const TopicSearchCurriculum = ({
  hideLabel,
  hideSearch,
  mustSelect,
  subject,
  selectProps,
  textProps,
}: TopicSearchCurriculumProps) => {
  const { data: curriculums } = useCurriculums({ suspense: false, subject });

  const { filter, setFilter } = useTopicSearchContext();

  const { sendEvent } = useClientEvent();
  const onChangeCurriculum = (curriculum: string) => {
    setFilter({ curriculum });
    sendEvent(
      { category: 'topic_search', action: 'curriculum' },
      { curriculum: curriculum || 'all' },
    );
  };

  return (
    <HStack spacing={2}>
      {!hideLabel && (
        <Text fontSize="sm" color="gray.500" textAlign="right" {...textProps}>
          {hideSearch ? 'Select a' : 'Search in'} curriculum:
        </Text>
      )}
      <Select
        width="250px"
        onChange={e => onChangeCurriculum(e.target.value)}
        value={filter.curriculum}
        backgroundColor="white"
        {...selectProps}
      >
        {!mustSelect ? (
          <option value="">All curriculums</option>
        ) : (
          <option value="" disabled={true}>
            Select a curriculum
          </option>
        )}
        {curriculums?.map(curr => (
          <option value={curr.curriculum.name} key={curr.curriculum.name}>
            {curr.curriculum.displayName}
          </option>
        ))}
      </Select>
    </HStack>
  );
};
