import { sessionsClient } from 'api';
import { getSchoolID } from 'api/sessions';
import { getApp } from 'firebase/app';
import { getAuth, inMemoryPersistence, signInWithCustomToken, UserCredential } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { v4 as uuid } from 'uuid';

/**
 * Uploads a file to the firebase storage bucket. Will check for auth
 * to scienceserver and to firebase.
 */
export const uploadFile = async (file: File | string) => {
  const storage = getFirebaseStorage();
  const firebaseUser = await getAuthedFirebase();

  // Create the asset reference using the response
  const schoolId = await getSchoolID();
  const filename = `schools/${schoolId}/assets/${uuid()}`;
  const fileRef = ref(storage, filename);

  // Upload the file to the bucket
  if (file instanceof File) {
    await uploadBytes(fileRef, file, {
      customMetadata: {
        // This is required for uploading and will be checked.
        user_id: firebaseUser.user.uid,
      },
    });
  } else {
    await uploadString(fileRef, file, 'data_url', {
      customMetadata: {
        // This is required for uploading and will be checked.
        user_id: firebaseUser.user.uid,
      },
    });
  }

  return filename;
};

export const uploadWhiteboardImage = async (whiteboardSessionId: string, data: string) => {
  const storage = getFirebaseStorage();
  const firebaseUser = await getAuthedFirebase();

  // Create the asset reference using the response
  const schoolId = await getSchoolID();
  const userId = firebaseUser.user.uid.replace('/', '=');
  const assetId = new Date().getTime();
  const filename = `schools/${schoolId}/whiteboards/${whiteboardSessionId}/${userId}/${assetId}`;
  const fileRef = ref(storage, filename);

  // Upload the file to the bucket
  await uploadString(fileRef, data, 'data_url', {
    customMetadata: {
      // This is required for uploading and will be checked.
      user_id: firebaseUser.user.uid,
    },
  });
  return filename;
};

/**
 * Gets the download url for an asset in the bucket.
 * @param asset string in the format of "schools/{schoolId}/assets/{assetId}"
 */
export const getAssetUrlFromBucket = async (asset: string) => {
  await getAuthedFirebase();
  const storage = getFirebaseStorage();
  const assetRef = ref(storage, asset);
  return await getDownloadURL(assetRef);
};

let authPromise: Promise<UserCredential> | undefined = undefined;
export const getAuthedFirebase = async () => {
  if (!authPromise) {
    authPromise = (async () => {
      const { token } = await sessionsClient.getFirebaseToken({}).response;

      // Sign into firebase with token from our service
      const auth = getAuth();
      await auth.setPersistence(inMemoryPersistence); // do not persist authed user
      return await signInWithCustomToken(auth, token);
    })();
  }
  return authPromise;
};

export const getFirebaseStorage = () => {
  const app = getApp();
  return getStorage(app);
};
