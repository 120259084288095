import { MeetClient } from '@sparx/api/apis/sparx/science/lessons/v1/meet.client';
import { KioskClient } from '@sparx/api/apis/sparx/teaching/kiosk/v1/kiosk.client';
import { getTransport } from '@sparx/science/api';

export const teachingApiURL =
  import.meta.env.VITE_TEACHING_API_URL || window.settings?.TEACHING_API_URL;

export const meetClient = new MeetClient(getTransport(teachingApiURL));
export const kioskClient = new KioskClient(getTransport(teachingApiURL));
export const kioskClientNoAuth = new KioskClient(
  getTransport(teachingApiURL, {
    noAuthInterceptor: true,
  }),
);
