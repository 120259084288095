// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schools.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolsService } from './schools';
import type { UndeleteSchoolRequest } from './schools';
import type { DeleteSchoolRequest } from './schools';
import type { UpdateSchoolRequest } from './schools';
import type { CreateSchoolRequest } from './schools';
import type { ListSchoolsResponse } from './schools';
import type { ListSchoolsRequest } from './schools';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { School } from './schools';
import type { GetSchoolRequest } from './schools';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * SchoolsService stores schools.
 *
 * @generated from protobuf service sparx.school.v2.SchoolsService
 */
export interface ISchoolsServiceClient {
  /**
   * Gets a school.
   *
   * @generated from protobuf rpc: GetSchool(sparx.school.v2.GetSchoolRequest) returns (sparx.school.v2.School);
   */
  getSchool(
    input: GetSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolRequest, School>;
  /**
   * Lists schools.
   *
   * @generated from protobuf rpc: ListSchools(sparx.school.v2.ListSchoolsRequest) returns (sparx.school.v2.ListSchoolsResponse);
   */
  listSchools(
    input: ListSchoolsRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchoolsRequest, ListSchoolsResponse>;
  /**
   * Creates a new school.
   *
   * @generated from protobuf rpc: CreateSchool(sparx.school.v2.CreateSchoolRequest) returns (sparx.school.v2.School);
   */
  createSchool(
    input: CreateSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchoolRequest, School>;
  /**
   * Updates an existing school.
   *
   * @generated from protobuf rpc: UpdateSchool(sparx.school.v2.UpdateSchoolRequest) returns (sparx.school.v2.School);
   */
  updateSchool(
    input: UpdateSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchoolRequest, School>;
  /**
   * Soft deletes an existing school.
   *
   * @generated from protobuf rpc: DeleteSchool(sparx.school.v2.DeleteSchoolRequest) returns (sparx.school.v2.School);
   */
  deleteSchool(
    input: DeleteSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchoolRequest, School>;
  /**
   * @generated from protobuf rpc: UndeleteSchool(sparx.school.v2.UndeleteSchoolRequest) returns (sparx.school.v2.School);
   */
  undeleteSchool(
    input: UndeleteSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<UndeleteSchoolRequest, School>;
}
/**
 * SchoolsService stores schools.
 *
 * @generated from protobuf service sparx.school.v2.SchoolsService
 */
export class SchoolsServiceClient
  implements ISchoolsServiceClient, ServiceInfo
{
  typeName = SchoolsService.typeName;
  methods = SchoolsService.methods;
  options = SchoolsService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Gets a school.
   *
   * @generated from protobuf rpc: GetSchool(sparx.school.v2.GetSchoolRequest) returns (sparx.school.v2.School);
   */
  getSchool(
    input: GetSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<GetSchoolRequest, School> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Lists schools.
   *
   * @generated from protobuf rpc: ListSchools(sparx.school.v2.ListSchoolsRequest) returns (sparx.school.v2.ListSchoolsResponse);
   */
  listSchools(
    input: ListSchoolsRequest,
    options?: RpcOptions
  ): UnaryCall<ListSchoolsRequest, ListSchoolsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSchoolsRequest, ListSchoolsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Creates a new school.
   *
   * @generated from protobuf rpc: CreateSchool(sparx.school.v2.CreateSchoolRequest) returns (sparx.school.v2.School);
   */
  createSchool(
    input: CreateSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<CreateSchoolRequest, School> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Updates an existing school.
   *
   * @generated from protobuf rpc: UpdateSchool(sparx.school.v2.UpdateSchoolRequest) returns (sparx.school.v2.School);
   */
  updateSchool(
    input: UpdateSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<UpdateSchoolRequest, School> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * Soft deletes an existing school.
   *
   * @generated from protobuf rpc: DeleteSchool(sparx.school.v2.DeleteSchoolRequest) returns (sparx.school.v2.School);
   */
  deleteSchool(
    input: DeleteSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<DeleteSchoolRequest, School> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
  /**
   * @generated from protobuf rpc: UndeleteSchool(sparx.school.v2.UndeleteSchoolRequest) returns (sparx.school.v2.School);
   */
  undeleteSchool(
    input: UndeleteSchoolRequest,
    options?: RpcOptions
  ): UnaryCall<UndeleteSchoolRequest, School> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UndeleteSchoolRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input
    );
  }
}
