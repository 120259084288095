import { faNotdef, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAssetUrlFromBucket } from '@sparx/science/app/storage';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

export const FirebaseAssetPreview = ({ path }: { path?: string }) => {
  const { data, isLoading } = useQuery(
    ['firebase-asset', path],
    () => getAssetUrlFromBucket(path || ''),
    {
      enabled: Boolean(path),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  );
  if (!path) {
    return <FontAwesomeIcon icon={faNotdef} style={{ color: '#efefef' }} size="2xl" />;
  }
  if (isLoading) {
    return <FontAwesomeIcon icon={faSpinner} spin={true} style={{ color: '#efefef' }} size="2xl" />;
  }
  return <img src={data} alt="" />;
};
