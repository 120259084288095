import { Box, ChakraProvider, useToken } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { queryClient } from '@sparx/science/api/client';
import { NavigationControlProvider } from '@sparx/science/app/BackLink';
import { LargeLoadingProps } from '@sparx/science/components/loading/LargeLoading';
import { SparxThemeProvider } from '@sparx/science/components/theme/SparxTheme';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import { theme } from './theme';

export const App = () => {
  return (
    <Sentry.ErrorBoundary showDialog>
      <QueryClientProvider client={queryClient}>
        <SparxThemeProvider
          value={{
            loadingComponent: LoadingComponent,
          }}
        >
          <ChakraProvider theme={theme}>
            <NavigationControlProvider>
              <RouterProvider router={router} />
            </NavigationControlProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ChakraProvider>
        </SparxThemeProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

const LoadingComponent = ({ color = 'orange.400', size = 200 }: LargeLoadingProps) => {
  const atomColor = useToken('colors', color);

  // https://github.com/n3r4zzurr0/svg-spinners/blob/main/svg-smil/3-dots-bounce.svg?short_path=50864c0
  return (
    <Box display="flex" justifyContent="center" padding="20px">
      <svg
        width={size}
        height={size}
        style={{
          transform: 'scale(0.7)',
        }}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="12" r="2.5" fill={atomColor}>
          <animate
            id="spinner_qFRN"
            begin="0;spinner_OcgL.end+0.25s"
            attributeName="cy"
            calcMode="spline"
            dur="0.6s"
            values="12;6;12"
            keySplines=".33,.66,.66,1;.33,0,.66,.33"
          />
        </circle>
        <circle cx="12" cy="12" r="2.5" fill={atomColor}>
          <animate
            begin="spinner_qFRN.begin+0.1s"
            attributeName="cy"
            calcMode="spline"
            dur="0.6s"
            values="12;6;12"
            keySplines=".33,.66,.66,1;.33,0,.66,.33"
          />
        </circle>
        <circle cx="20" cy="12" r="2.5" fill={atomColor}>
          <animate
            id="spinner_OcgL"
            begin="spinner_qFRN.begin+0.2s"
            attributeName="cy"
            calcMode="spline"
            dur="0.6s"
            values="12;6;12"
            keySplines=".33,.66,.66,1;.33,0,.66,.33"
          />
        </circle>
      </svg>
    </Box>
  );
};
