import { findAndReplace } from 'mdast-util-find-and-replace';
import { memo } from 'react';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps } from '../question/SparxQuestionContext';
import { IElement, ITemplatedContentElement } from '../question/types';
import { LayoutElement } from './LayoutElement';
import { MarkdownNode } from './TextElement';

// Modify the default sanitize schema to allow our custom elementreference tag
const sanitizeSchema: typeof defaultSchema = {
  ...defaultSchema,
  tagNames: [...(defaultSchema.tagNames || []), 'elementreference'],
};

export const TemplatedContentElement = ({
  element,
}: LayoutElementProps<ITemplatedContentElement>) => {
  return (
    <MarkdownNodeWithTemplating
      elements={element.elements}
      children={element.text}
      className={styles.TemplatedContent}
    />
  );
};

const MarkdownNodeWithTemplating = memo(
  ({
    elements,
    children,
    className,
  }: {
    elements: Record<string, IElement>;
    children: string;
    className?: string;
  }) => (
    <MarkdownNode
      className={className}
      children={children}
      options={{
        additionalRemarkPlugins: [elementReplacer],
        // There's something not right with the types but it's actually fine and works so
        // we cast to any so it doesn't error when compiling
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additionalRehypePlugins: [rehypeRaw as any, rehypeSanitize(sanitizeSchema)],
        additionalComponents: {
          th: ({ node, children }) => {
            const contents = children && children.toString();
            return (
              <th {...node.properties}>
                {contents && <MarkdownNodeWithTemplating elements={elements} children={contents} />}
              </th>
            );
          },
          td: ({ node, children }) => {
            const contents = children && children.toString();
            return (
              <td {...node.properties}>
                {contents && <MarkdownNodeWithTemplating elements={elements} children={contents} />}
              </td>
            );
          },
          elementreference: ({ node }) => {
            const key = node.properties?.keyRef?.toString() || '';
            const el = elements[key];
            if (!el) {
              return <span>ERROR</span>;
            }
            return <LayoutElement element={el} />;
          },
        },
      }}
    />
  ),
);

const RE_ELEMENT = /\[\[([a-zA-Z0-9-]+)\]\]/g;

const elementReplacer = () => {
  const replace = (match: string) => {
    const key = match.substring(2, match.length - 2);
    return {
      type: 'text',
      data: {
        hName: 'elementreference',
        hProperties: {
          keyRef: key,
        },
      },
    };
  };

  //
  //   eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (tree: any) => {
    //   eslint-disable-next-line @typescript-eslint/no-explicit-any
    findAndReplace(tree, [[RE_ELEMENT, replace as any]]);
  };
};
